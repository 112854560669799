import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { FormContext, useForm } from 'react-hook-form';
import { decamelizeKeys } from 'humps';
import orderBy from 'lodash/orderBy';
import pickBy from 'lodash/pickBy';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { useReward } from 'react-rewards';
import { usePreviousValue } from 'beautiful-react-hooks';

import { CLIP_NAME } from 'constants/common';
import { UNIT_TYPE, SESSION_TYPE } from 'constants/courses';
import {
  ADVANCED_COURSE_OPTIONS_WHITELIST,
  PRO_ORGS,
  BASIC_UNIT_LIMIT,
} from 'constants/organisation';

import {
  Flex,
  MdIcon,
  Button,
  Divider,
  Card,
  Text,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Link,
  Spinner,
  Palette,
  Tooltip,
  Tabs,
  TabList,
  Tab,
  Collapse,
  Box,
  Stack,
  chakra,
  useTheme,
} from '@workshop/ui';

import { PERMISSION_SLUGS } from 'types/common';
import navRoutes from 'navigation/Routes';
import {
  hooks,
  capitalize,
  videoUtils,
  getParamFromUrl,
  analytics,
} from 'utils';
import { useWindowDimensions } from 'utils/hooks/useDimensions';

import {
  courseActions,
  unitActions,
  sessionActions,
  videoClipActions,
} from 'redux/actions/cms';
import { getUnitsForCourse, getCategoryOptions } from 'redux/selectors';
import { useHasAnyPermission } from 'redux/selectors/organisation';

import { PLATFORM } from 'constants/env';

import { CheckList } from 'components/SessionPlayer/CheckList';
import { EditModal } from 'components/Common/EditModal';
import { ModuleListItem } from 'components/ModulesList';
import { FormCard } from 'components/FormCard';
import { IAddItem } from 'components/ListItem/AddItem';
import {
  SectionTitle,
  FixedFooter,
  InPageNav,
  InPageNavTab,
  ConfirmModal,
  Tour,
  ProCta,
  OnboardingChecklist,
} from 'components/Common';
import {
  OverviewCard,
  OverviewTextArea,
  OverviewVideoInput,
} from 'components/OverviewCard';
import { FurtherDetailsCard } from 'components/FurtherDetailsCard';
import {
  EmailConfig,
  EmailConfigFormData,
  ModalFormData,
} from 'components/EmailConfig';
import { Draggable, IDraggableData } from 'components/Draggable';
import { AddItem } from 'components/ListItem';
import { HeaderTag } from 'components/AppHeader';
import { IDynamicTable } from 'components/Common/DynamicList';
import { DownloadsCard } from 'components/DownloadsCard';
import { ModalVideo } from 'components/ModalVideo';
import { ScreenWrapper } from 'screens/common/ScreenWrapper';

import { GlobalState } from 'types';
import { CompleteUploadChunkAction } from 'types/common';
import {
  DescriptionFormData,
  FurtherDetailsFormData,
  IClassType,
  OverviewFormData,
  ICourse,
  IUnit,
  IUnitListItem,
  ISessionListItem,
  SessionListState,
  SessionDetailState,
  IVideoClip,
  IVideoClipListItem,
  VideoClipListState,
} from 'types/cms';

import {
  formatUnitList,
  formatSession,
  formatSessionList,
  DraggableUnit,
  DraggableSession,
  getFormattedDownloadData,
} from './dataUtils';

import PublishCourseModal from './PublishCourseModal';
import ShareCourseModal from './ShareCourseModal';

const boolToString = (bool: boolean) => (bool ? 'true' : 'false');

// Routing Props
interface MatchParams {
  courseId: string;
}

// Props passed to our component from parents
interface OwnProps extends RouteComponentProps<MatchParams> {}

// Props passed to our component via redux
type PropsFromRedux = ConnectedProps<typeof connector>;

// Combined props we're passing to our component
interface Props extends OwnProps, PropsFromRedux {}

type DraggableSessionProps = {
  draggableUnit: DraggableUnit;
  sessionList: SessionListState;
  isLoading: boolean;
  isEditingDisabled: boolean;
  courseId: string;
  unitId: string;
  droppedSession?: DropResult;
  setDeleteSession: React.Dispatch<
    React.SetStateAction<{
      id: string;
      type: 'normal' | 'intro' | 'outro';
    } | null>
  >;
  builderView: 'focused' | 'outline';
};

type ModalVideoState = {
  summary?: string;
  video?: string;
  onSaveSummary?: (summary: string) => Promise<void>;
  onUpload?: (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string,
    mediaType?: 'video' | 'audio'
  ) => Promise<void>;
  onSaveClip?: (data: Partial<IVideoClip>) => Promise<void>;
  orientation?: 'portrait' | 'landscape';
  mediaType?: 'text' | 'image' | 'audio' | 'video';
  clipId?: string | null;
  unitId?: string | null;
  qrBlob?: string;
};

type UnitItemProps = {
  unit: DraggableUnit;
  isLoading: boolean;
  isLocked: boolean;
  courseId: string;
  sessionList: SessionListState;
  sessionDetails: SessionDetailState;
  videoClipList: VideoClipListState;
  isEditingDisabled: boolean;
  droppedSession?: DropResult;
  setModalVideo: React.Dispatch<React.SetStateAction<ModalVideoState | null>>;
  modalVideo: ModalVideoState | null;
  builderView: 'focused' | 'outline';
  expandedUnit: number;
  setExpandedUnit: (unitIndex: number) => void;
};

export const courseTourIds = {
  courseBuilder: 'courseBuilder',
  courseAddUnit: 'courseAddUnit',
  courseAddSession: 'courseAddSession',
  courseSummary: 'courseSummary',
  coursePublish: 'coursePublish',
  generatedCourse: 'generatedCourse',
  generatedCourseUnits: 'generatedCourseUnits',
  generatedCourseSessions: 'generatedCourseSessions',
  generatedCourseSummary: 'generatedCourseSummary',
  generatedCoursePublish: 'generatedCoursePublish',
};

const MiniMenu: React.FC<{
  menuOptions: { label: string; icon: string; onClick: () => void }[];
}> = ({ menuOptions }) => (
  <Menu>
    <Flex flexDirection="column">
      <Flex justifyContent="flex-end">
        <MenuButton
          as={Button}
          size="sm"
          variant="ghost"
          padding={1}
          minW={0}
          ml={0.5}
          mr={{ base: 0, md: 0.5 }}
          height="auto"
          icon="MoreHoriz"
          color="text.muted"
          pointerEvents="all"
          sx={{
            svg: {
              marginRight: 0,
            },
          }}
        />
      </Flex>
      <Flex
        position="relative"
        zIndex={2}
        width="250px"
        justifyContent="flex-end"
      >
        <MenuList width="250px" pointerEvents="all">
          {menuOptions.map((o) => (
            <MenuItem
              key={`menu-${o.label}`}
              icon={<MdIcon name={o.icon} />}
              onClick={(e) => {
                e.preventDefault();
                o.onClick();
              }}
            >
              {o.label}
            </MenuItem>
          ))}
        </MenuList>
      </Flex>
    </Flex>
  </Menu>
);

const DraggableSessions: React.FC<DraggableSessionProps> = ({
  draggableUnit,
  sessionList,
  isLoading,
  isEditingDisabled,
  courseId,
  unitId,
  droppedSession,
  setDeleteSession,
  builderView,
}) => {
  const dispatch = useDispatch();

  const currentSessions =
    draggableUnit.modules
      ?.map((moduleId) => sessionList[moduleId])
      .filter((m) => Boolean(m)) || [];

  // If the unit has an intro session then the handling of session
  // labels & indexes changes
  const hasIntroSession = !!Object.values(currentSessions).find(
    (session) => session.moduleType === SESSION_TYPE.intro
  );

  // Collect & format the list of session data by session type
  const { normal: normalSessions } = formatSessionList(
    currentSessions,
    hasIntroSession
  );

  const [sessions, setSessions] = useState(normalSessions);
  const [isReordering, setIsReordering] = useState(false);

  const sessionsString = JSON.stringify(normalSessions.map((i) => ({ ...i })));
  useEffect(() => {
    if (!isReordering && sessionsString) {
      setSessions(normalSessions);
    }
  }, [sessionsString, isReordering]);

  const handleSessionReorder = async (
    data: IDraggableData<DraggableSession>,
    lastDropped: DropResult,
    currentDroppableId: string
  ) => {
    const { destination, source } = lastDropped;

    if (!destination) return;

    const isDestination = currentDroppableId === destination.droppableId;
    const isSource = currentDroppableId === source.droppableId;

    let newData = data;
    const sessionId = parseInt(lastDropped.draggableId.replace(/^(.*)-/, ''));
    if (isSource && !isDestination) {
      newData = data.filter((s) => s.id !== sessionId);
    }
    if (!isSource && isDestination) {
      const draggedSession = Object.values(sessionList).find(
        (s) => s.id === sessionId
      );
      if (!draggedSession) return;
      const formattedSession = formatSession(draggedSession, hasIntroSession);
      newData = data.map((s) => ({
        ...s,
        hasChanged: true,
      }));
      newData.splice(destination.index, 0, {
        ...formattedSession,
        hasChanged: true,
        unit: parseInt(unitId),
      });
    }

    // If there is an intro session, offset by 2, not 1. `idx` is 0-based whereas
    // our session indexes are 1-based. Only normal sessions can be re-ordered which
    // is why a step with `idx` 0 will either have an index of 1 (no intro session)
    // or an index of 2 (with intro session)
    const offset = hasIntroSession ? 2 : 1;
    const newSessions = newData.map((item, idx) => {
      return {
        ...item,
        index: idx + offset,
      };
    });
    setSessions(newSessions);

    setIsReordering(true);
    await Promise.all(
      newSessions.map((item) => {
        if (item.hasChanged) {
          return dispatch(
            sessionActions.update(item.id, {
              index: item.index,
              ...(item.unit ? { unit: item.unit } : {}),
            })
          );
        }
        return;
      })
    );
    setIsReordering(false);
    analytics.track('Unit Edited');
  };
  return (
    <Draggable
      data={
        // During loading, render 2 empty items
        isLoading
          ? [
              {
                id: 0,
                title: '',
                description: '',
                image: '',
                label: '',
                index: 0,
                moduleType: '',
                isLoading: true,
                stepCount: 0,
              },
              {
                id: 1,
                title: '',
                description: '',
                image: '',
                label: '',
                index: 1,
                moduleType: '',
                isLoading: true,
                stepCount: 0,
              },
            ]
          : orderBy(sessions, 'index')
      }
      // @ts-ignore
      onDragEnd={handleSessionReorder}
      // Disable drag during loading
      dragEnabled={
        !isLoading && !isEditingDisabled && builderView === 'outline'
      }
      hasParentContext={draggableUnit.unitType === UNIT_TYPE.normal}
      droppableType="SESSION"
      lastDropped={droppedSession}
      mt={sessions.length > 0 ? 2 : 0}
      pb={2}
      hasDragHandle
    >
      {(session) => {
        return (
          <Flex
            key={`session-${session.id}`}
            flexDirection="column"
            px={{ base: 2, md: 4 }}
            mt={2}
            position="relative"
            justifyContent="center"
            role="group"
            {...(draggableUnit.index === 0 && session.index === 0
              ? {
                  'data-tour': courseTourIds.generatedCourseSessions,
                }
              : {})}
          >
            {builderView === 'focused' && session.index !== 0 && (
              <Flex
                w="100%"
                borderBottomWidth={0.5}
                borderColor="border.muted"
                mb={2}
              />
            )}
            <Flex
              {...(builderView === 'focused'
                ? {}
                : {
                    as: Card,
                    padding: 0,
                    borderRadius: { base: 'md', md: 'md' },
                  })}
            >
              <ModuleListItem
                prefix={
                  builderView === 'focused'
                    ? `Session ${session.index + 1}`
                    : undefined
                }
                title={`${session.title}`}
                description={
                  builderView === 'focused' ? session.description : undefined
                }
                imageUrl={
                  draggableUnit.unitType === UNIT_TYPE.normal
                    ? session.image
                    : ''
                }
                noImageIcon={
                  draggableUnit.unitType === UNIT_TYPE.assessment
                    ? 'Assignment'
                    : 'Pending'
                }
                showImage={
                  session.isLoading ||
                  session.moduleType === SESSION_TYPE.normal
                }
                imageSize={builderView === 'focused' ? 'lg' : 'sm'}
                linkTo={navRoutes.cms.session.path(
                  courseId,
                  session.id.toString()
                )}
                isLoading={session.isLoading}
                openBtnLabel={
                  builderView === 'focused'
                    ? session.isReady
                      ? 'Edit'
                      : 'Build'
                    : undefined
                }
                openBtnSecondary={session.isReady}
                content={
                  builderView === 'focused' ? (
                    <Flex alignItems="center" fontSize="xs" color="text.muted">
                      <Text>{Math.max(session.stepCount - 2, 0)} steps</Text>
                      <Text mx={2}>&bull;</Text>
                      {session.isReady ? (
                        <MdIcon name="Done" color="icon.success" />
                      ) : session.isReady === false ? (
                        <Flex alignItems="center">
                          <MdIcon
                            name="IncompleteCircle"
                            color="text.muted"
                            mr={1.5}
                          />
                          <Text color="text.error">Not Ready</Text>
                        </Flex>
                      ) : null}
                    </Flex>
                  ) : null
                }
              />
            </Flex>
            {builderView === 'outline' && (
              <>
                <Flex
                  position="absolute"
                  right={8}
                  zIndex={2}
                  mr={{ base: 2, md: 4 }}
                  pointerEvents="none"
                  alignItems="center"
                >
                  <MiniMenu
                    menuOptions={[
                      {
                        label: 'Delete Session',
                        icon: 'DeleteForever',
                        onClick: () =>
                          setDeleteSession({
                            id: session.id.toString(),
                            type: 'normal',
                          }),
                      },
                    ]}
                  />
                  {session.isReady ? (
                    <MdIcon name="Done" color="icon.success" mx={2} />
                  ) : session.isReady === false ? (
                    <MdIcon name="IncompleteCircle" color="text.muted" mx={2} />
                  ) : null}
                </Flex>
                <Flex
                  position="absolute"
                  width={{ base: 'image.xs', md: 'image.sm' }}
                  ml={2}
                  justifyContent="center"
                  zIndex={1}
                  opacity={0}
                  _groupHover={{ opacity: 1 }}
                  transition="opacity 0.2s"
                >
                  <Flex
                    backgroundColor="background.tint3"
                    borderRadius="sm"
                    alignItems="center"
                    p={1.5}
                    {...session.dragHandleProps}
                  >
                    <MdIcon name="Reorder" color="icon.muted" />
                  </Flex>
                </Flex>
              </>
            )}
          </Flex>
        );
      }}
    </Draggable>
  );
};

const UnitItem: React.FC<UnitItemProps> = ({
  unit,
  isLoading,
  isLocked,
  courseId,
  sessionList,
  sessionDetails,
  videoClipList,
  isEditingDisabled,
  droppedSession,
  setModalVideo,
  modalVideo,
  builderView,
  expandedUnit,
  setExpandedUnit,
}) => {
  const [editModalState, setEditModalState] = useState<
    'notes' | 'requirements' | 'resources' | null
  >(null);
  const [showDeleteUnitModal, setShowDeleteUnitModal] = useState(false);
  const [deleteSession, setDeleteSession] = useState<{
    id: string;
    type: 'normal' | 'intro' | 'outro';
  } | null>(null);

  // Initialise some local state which can be used to control the UI
  // during data update requests to the API
  const [isUpdating, setIsUpdating] = useState({
    title: false,
    furtherDetails: false,
    description: false,
    assessment: false,
    studentDownloads: false,
    teacherDownloads: false,
    requirements: false,
    addSession: false,
    removeUnit: false,
    removeSession: false,
    unitIntro: false,
    unitOutro: false,
  });

  const dispatch = useDispatch();
  const createChunkUpload = hooks.useChunkUpload(isEditingDisabled);

  const handleUnitUpdate = async (
    data: Partial<IUnit>,
    section: keyof typeof isUpdating
  ) => {
    setIsUpdating({ ...isUpdating, [section]: true });
    await dispatch(unitActions.update(parseInt(unit.id), data));
    setIsUpdating({ ...isUpdating, [section]: false });
    analytics.track('Unit Edited');
  };

  const handleCreateSession = async (data: IAddItem) => {
    const formData: Partial<ISessionListItem> = {
      title: data.inputText,
      moduleType: 'normal',
      moduleFormat: 'guided',
    };
    setIsUpdating({ ...isUpdating, addSession: true });
    await dispatch(
      sessionActions.create(parseInt(courseId), parseInt(unit.id), formData)
    );
    setIsUpdating({ ...isUpdating, addSession: false });
    analytics.track('Session Created');
  };

  const handleCreateIntroOutro = async (data: 'intro' | 'outro') => {
    const formData: Partial<ISessionListItem> = {
      title: data === 'intro' ? 'Unit Intro' : 'Unit Outro',
      moduleType: data,
      moduleFormat: 'guided',
    };
    setIsUpdating({ ...isUpdating, addSession: true });
    await dispatch(
      sessionActions.create(parseInt(courseId), parseInt(unit.id), formData)
    );
    if (data === 'intro') {
      // Get updated indexes for existing unit sessions
      await dispatch(unitActions.retrieve(parseInt(unit.id)));
    }
    setIsUpdating({ ...isUpdating, addSession: false });
    analytics.track(
      data === 'intro' ? 'Unit Intro Created' : 'Unit Outro Created'
    );
  };

  const handleDownloadsSubmit = async (
    data: IDynamicTable,
    teacherOnly: boolean
  ) => {
    const otherDownloads = unit.downloads.filter(
      (d) => d.teacherOnly === !teacherOnly
    );
    const formData: Partial<IUnit> = {
      downloads: [...getFormattedDownloadData(data), ...otherDownloads],
    };
    setIsUpdating({
      ...isUpdating,
      ...(teacherOnly
        ? { teacherDownloads: true }
        : { studentDownloads: true }),
    });
    await dispatch(unitActions.update(parseInt(unit.id), formData));
    setIsUpdating({
      ...isUpdating,
      ...(teacherOnly
        ? { teacherDownloads: false }
        : { studentDownloads: false }),
    });
    analytics.track('Unit Edited');
  };

  const handleAddMediaToClip = async (
    file: File,
    /** The ID of the clip */
    id: string,
    mediaType: 'video' | 'audio' = 'video'
  ) => {
    const chunkUpload = createChunkUpload(file.name, file.size, { id });

    const response = await chunkUpload.startUpload<CompleteUploadChunkAction>(
      file
    );

    // If chunked uploads are disabled (e.g. due to permissions) then calling
    // `startUpload` will result in a void response
    if (!response) return;

    const { payload } = response;

    // Only run the remaining code if the upload was successful. A successful
    // upload will contain the 'file' property which we then use to finalize
    // the upload process
    if (!payload || !('file' in payload)) return;

    if (mediaType === 'audio') {
      const { file: audioFile, filename } = payload;
      // The `file` included in the successful upload response gives us the full
      // path to the uploaded file. We want to add this to our video clip to 'link'
      // the uploaded file to a video clip object.
      await dispatch(
        videoClipActions.update(parseInt(id), {
          audio: audioFile,
          originalFilename: filename,
        })
      );
    } else {
      const { file: videoFile, filename } = payload;
      // The `file` included in the successful upload response gives us the full
      // path to the uploaded file. We want to add this to our video clip to 'link'
      // the uploaded file to a video clip object.
      await dispatch(
        videoClipActions.update(parseInt(id), {
          video: videoFile,
          originalFilename: filename,
        })
      );
    }

    return;
  };

  const handleSaveClip = async (id: string, data: Partial<IVideoClip>) => {
    if (data.videoBlob) {
      const videoFile = new File(
        [data.videoBlob],
        `course-${courseId}-unit-${unit.id}-${CLIP_NAME}-${id}.mp4`,
        { type: data.videoBlob.type }
      );
      return await handleAddMediaToClip(videoFile, id, 'video');
    }
    if (data.audioBlob) {
      const audioFile = new File(
        [data.audioBlob],
        `course-${courseId}-unit-${unit.id}-${CLIP_NAME}-${id}.mp3`,
        { type: data.audioBlob.type }
      );
      return await handleAddMediaToClip(audioFile, id, 'audio');
    }
    if (data.image) {
      const imageFile = data.image as File;
      const imageUrl = URL.createObjectURL(imageFile);
      const dimensions = await videoUtils.getHeightAndWidthFromImageUrl(
        imageUrl
      );
      let orientation = 'portrait';
      if (dimensions.width > dimensions.height) {
        orientation = 'landscape';
      }
      const formData = new FormData();
      formData.append('image', imageFile);
      formData.append('orientation', orientation);
      formData.append('original_filename', imageFile.name);
      return await dispatch(videoClipActions.update(parseInt(id), formData));
    }
    return await dispatch(videoClipActions.update(parseInt(id), data));
  };

  const handleAddMediaFromInput = async (
    e: React.ChangeEvent<HTMLInputElement>,
    /** The ID of the clip */
    id: string,
    mediaType: 'video' | 'audio' = 'video'
  ) => {
    e.preventDefault();

    const files = e?.target?.files;

    if (!files) return;

    const file = files[0];
    return await handleAddMediaToClip(file, id, mediaType);
  };

  const getMediaFromClip = (
    c: IVideoClipListItem,
    mediaType: 'text' | 'image' | 'audio' | 'video'
  ) => {
    if (mediaType === 'image') return c.imageMobile;
    if (mediaType === 'audio') return c.audio;
    if (mediaType === 'video') return c.video720 || c.videoHls;
    return null;
  };

  const currentSessions =
    unit.modules
      ?.map((moduleId) => sessionList[moduleId])
      .filter((m) => Boolean(m)) || [];

  const introSessionId = Object.values(currentSessions).find(
    (session) => session.moduleType === SESSION_TYPE.intro
  )?.id;
  const outroSessionId = Object.values(currentSessions).find(
    (session) => session.moduleType === SESSION_TYPE.outro
  )?.id;

  const introSession = introSessionId && sessionDetails[introSessionId];
  const outroSession = outroSessionId && sessionDetails[outroSessionId];

  const introVideo =
    introSession &&
    Object.values(videoClipList).find((v) =>
      introSession.introOutroClips?.includes(v.id)
    );
  const outroVideo =
    outroSession &&
    Object.values(videoClipList).find((v) =>
      outroSession.introOutroClips?.includes(v.id)
    );

  // TODO: Send these unit intro/outro session clips with course or units api call
  useEffect(() => {
    if (introSessionId && !introSession) {
      dispatch(sessionActions.retrieve(introSessionId));
    }
    if (outroSessionId && !outroSession) {
      dispatch(sessionActions.retrieve(outroSessionId));
    }
  }, [introSession, introSessionId, outroSession, outroSessionId]);

  const hasNormalSessions = Boolean(
    currentSessions.filter((s) => s.moduleType === SESSION_TYPE.normal).length >
      0
  );

  if (isUpdating.removeUnit) {
    return (
      <Flex
        p={6}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Spinner color="icon.error" />
        <Text color="text.error" fontSize="sm" mt={3}>
          Deleting Unit
        </Text>
      </Flex>
    );
  }

  return (
    <>
      {/* Used for in-page linking via id, e.g. /#unit-1 */}
      <Flex
        id={`unit-${unit.index + 1}`}
        sx={{
          scrollMarginTop: 100,
          scrollSnapMarginTop: 100,
        }}
      />
      <ConfirmModal
        body={
          hasNormalSessions
            ? 'All sessions within this unit must be deleted individually before this unit can be deleted.'
            : 'You are about to permanently delete this unit. This action cannot be reversed.'
        }
        btnColor={hasNormalSessions ? 'blue' : 'red'}
        btnLabel={hasNormalSessions ? '' : 'Yes, Delete'}
        title={hasNormalSessions ? 'Unit Cannot Be Deleted' : 'Are You Sure?'}
        isOpen={showDeleteUnitModal}
        isLoading={isLoading}
        onClose={() => setShowDeleteUnitModal(false)}
        onClick={async () => {
          if (!hasNormalSessions) {
            setIsUpdating({ ...isUpdating, removeUnit: true });
            await dispatch(unitActions.remove(parseInt(unit.id)));
            // Fetch units to update indexes
            await dispatch(unitActions.list(parseInt(courseId)));
            setIsUpdating({ ...isUpdating, removeUnit: false });
            analytics.track('Unit Deleted');
          }
        }}
      />
      <ConfirmModal
        body={`You are about to permanently delete this ${
          deleteSession?.type === 'intro'
            ? 'unit intro'
            : deleteSession?.type === 'outro'
            ? 'unit outro'
            : 'session'
        }. This action cannot be reversed.`}
        btnColor="red"
        btnLabel="Yes, Delete"
        title="Are You Sure?"
        isOpen={Boolean(deleteSession)}
        isLoading={isLoading}
        onClose={() => setDeleteSession(null)}
        onClick={async () => {
          if (deleteSession) {
            setIsUpdating({ ...isUpdating, removeSession: true });
            await dispatch(sessionActions.remove(parseInt(deleteSession.id)));
            // Fetch units to update indexes
            await dispatch(unitActions.list(parseInt(courseId)));
            setIsUpdating({ ...isUpdating, removeSession: false });
            analytics.track(
              deleteSession?.type === 'intro'
                ? 'Unit Intro Deleted'
                : deleteSession?.type === 'outro'
                ? 'Unit Outro Deleted'
                : 'Session Deleted'
            );
          }
        }}
      />
      <Flex
        mt={4}
        flexDirection="column"
        {...(builderView === 'focused'
          ? {
              backgroundColor: 'background.default',
              boxShadow: { base: 'none', md: 'lg' },
              transition:
                'border-radius 0.3s, padding-top 0.3s, padding-bottom 0.3s',
              ...(expandedUnit === unit.index + 1
                ? {
                    py: { base: 4, md: 6 },
                    borderRadius: { base: 'md', md: 'lg' },
                  }
                : {
                    py: { base: 3, md: 4 },
                    cursor: 'pointer',
                    onClick: () => setExpandedUnit(unit.index + 1),
                    borderRadius: 'md',
                    _hover: {
                      backgroundColor: 'background.tint1',
                    },
                  }),
            }
          : {
              backgroundColor: 'background.tint3',
              borderWidth: 1,
              borderColor: 'border.default',
              borderStyle: 'dashed',
              py: { base: 2, md: 4 },
              borderRadius: { base: 'md', md: 'lg' },
              mx: { base: 2, md: 0 },
            })}
        {...(isLocked
          ? {
              opacity: 0.5,
            }
          : {})}
      >
        {builderView === 'focused' && expandedUnit !== unit.index + 1 ? (
          <Flex alignItems="center" px={{ base: 4, md: 4 }}>
            {unit.label ? <Text>{`${unit.label}:`}&nbsp;</Text> : null}
            <Text fontWeight="semibold" flex={1}>
              {unit.title}
            </Text>
            <Text
              color="text.muted"
              fontSize="xs"
              mr={2}
              display={{ base: 'none', md: isLoading ? 'none' : 'inline' }}
            >
              Expand
            </Text>
            <MdIcon
              color="text.muted"
              name="ExpandMore"
              opacity={isLoading ? 0 : 1}
            />
          </Flex>
        ) : null}
        {/* {builderView === 'outline' ||
        (builderView === 'focused' && expandedUnit === unit.index + 1) ? ( */}
        <Flex
          flexDirection="column"
          {...(builderView === 'focused'
            ? {
                as: Collapse,
                in: expandedUnit === unit.index + 1,
              }
            : {})}
        >
          <Flex
            flexDirection="column"
            {...(builderView === 'focused'
              ? {
                  px: { base: 4, md: 6 },
                }
              : {
                  px: { base: 2, md: 4 },
                })}
          >
            {builderView === 'focused' ? (
              <Flex
                flexDirection="column"
                {...(unit.index === 0
                  ? {
                      'data-tour': courseTourIds.generatedCourseUnits,
                    }
                  : {})}
              >
                {unit.label ? <Text>{unit.label}:</Text> : null}
                <Text
                  fontSize={{ base: 'xl', md: '2xl' }}
                  fontWeight="bold"
                  lineHeight="1.2"
                  mb={4}
                >
                  {unit.title}
                </Text>
              </Flex>
            ) : (
              <Flex
                alignItems={{ base: 'none', md: 'center' }}
                flexDirection={{ base: 'column', md: 'row' }}
              >
                <Flex
                  alignItems="center"
                  mb="defaultMargin"
                  flexDirection={{ base: 'row-reverse', md: 'row' }}
                >
                  <Flex
                    backgroundColor={
                      unit.unitType === UNIT_TYPE.assessment
                        ? 'background.warning'
                        : 'background.primary'
                    }
                    borderRadius="sm"
                    flex={1}
                    {...(isLocked ? {} : unit.dragHandleProps)}
                  >
                    <Flex
                      role="group"
                      alignItems="center"
                      justifyContent="center"
                      flex={1}
                    >
                      {unit.unitType === UNIT_TYPE.normal && (
                        <Flex
                          position="absolute"
                          opacity={0}
                          _groupHover={{ opacity: isLocked ? 0 : 1 }}
                          transition="opacity 0.2s"
                        >
                          <MdIcon name="Reorder" color="icon.primary" />
                        </Flex>
                      )}
                      {Boolean(unit.label) && (
                        <Flex
                          opacity={1}
                          _groupHover={{
                            opacity:
                              unit.unitType === UNIT_TYPE.normal && !isLocked
                                ? 0
                                : 1,
                          }}
                          transition="opacity 0.2s"
                        >
                          <HeaderTag
                            title={unit.label}
                            bg={
                              unit.unitType === UNIT_TYPE.assessment
                                ? 'background.warning'
                                : 'background.primary'
                            }
                            color={
                              unit.unitType === UNIT_TYPE.assessment
                                ? 'text.warning'
                                : 'text.primary'
                            }
                          />
                        </Flex>
                      )}
                    </Flex>
                  </Flex>
                  {!isLoading && (
                    <Menu>
                      <Flex flexDirection="column">
                        <MenuButton
                          as={Button}
                          size="sm"
                          variant="ghost"
                          padding={1}
                          minW={0}
                          ml={{ base: 0, md: 0.5 }}
                          mr={0.5}
                          height="auto"
                          icon="MoreVert"
                          color="text.muted"
                          sx={{
                            svg: {
                              marginRight: 0,
                            },
                          }}
                        />
                        <Flex position="relative" zIndex={2}>
                          <MenuList>
                            <MenuItem
                              icon={<MdIcon name="DeleteForever" />}
                              onClick={() => setShowDeleteUnitModal(true)}
                            >
                              Delete Unit
                            </MenuItem>
                          </MenuList>
                        </Flex>
                      </Flex>
                    </Menu>
                  )}
                </Flex>

                <Flex
                  mb={3}
                  flex={1}
                  pointerEvents={isLocked ? 'none' : 'auto'}
                >
                  <AddItem
                    label="Unit Title"
                    value={unit.title}
                    onSave={(data) =>
                      handleUnitUpdate({ title: data.inputText }, 'title')
                    }
                    isUpdating={isUpdating.title}
                    isLoading={isLoading}
                    showInput
                    hideCard
                    fontWeight="semibold"
                  />
                </Flex>
              </Flex>
            )}

            {unit.unitType === UNIT_TYPE.assessment && (
              <Flex mb={4} pointerEvents={isLocked ? 'none' : 'auto'}>
                <AddItem
                  label="Add Description"
                  placeholder="Write a short description of this unit..."
                  onSave={(data) =>
                    handleUnitUpdate(
                      { detailedSummary: data.inputText },
                      'description'
                    )
                  }
                  isUpdating={isUpdating.description}
                  size="sm"
                  icon="Notes"
                  button
                  multiline
                  variant="dotted"
                  isLoading={isLoading}
                  showInput={Boolean(unit.description)}
                  value={unit.description}
                  flexDir="column"
                  topLabel="Description"
                />
              </Flex>
            )}

            {!isLocked && (
              <>
                <Flex
                  flexDirection={{ base: 'column', sm: 'row' }}
                  alignItems={{ base: 'normal', sm: 'center' }}
                  flexWrap="wrap"
                >
                  {PLATFORM === 'workshop' && (
                    <Button
                      variant="outline"
                      borderStyle={unit.checklist ? 'solid' : 'dashed'}
                      size="sm"
                      icon="Checklist"
                      onClick={() => setEditModalState('requirements')}
                      isLoading={isLoading}
                      mr={{ base: 0, sm: 2 }}
                      mb={2}
                    >
                      {unit.checklist ? 'Requirements' : 'Add Requirements'}
                    </Button>
                  )}

                  <Button
                    variant="outline"
                    borderStyle={unit.downloads.length > 0 ? 'solid' : 'dashed'}
                    size="sm"
                    icon="GetApp"
                    onClick={() => setEditModalState('resources')}
                    isLoading={isLoading}
                    mr={{ base: 0, sm: 2 }}
                    mb={2}
                  >
                    {unit.downloads.length > 0 ? 'Resources' : 'Add Resources'}
                  </Button>

                  {!isLoading && !(introSessionId && introVideo) ? (
                    <Button
                      size="sm"
                      icon="WavingHand"
                      variant="outline"
                      borderStyle="dashed"
                      onClick={() => handleCreateIntroOutro('intro')}
                      isLoading={isUpdating.addSession}
                      mr={{ base: 0, sm: 2 }}
                      mb={2}
                    >
                      Add Unit Intro
                    </Button>
                  ) : null}

                  {!isLoading && !(outroSessionId && outroVideo) ? (
                    <Button
                      size="sm"
                      icon="DoneOutline"
                      variant="outline"
                      borderStyle="dashed"
                      onClick={() => handleCreateIntroOutro('outro')}
                      isLoading={isUpdating.addSession}
                      mb={2}
                    >
                      Add Unit Outro
                    </Button>
                  ) : null}
                </Flex>
                <Divider mt={2} mb={2} />
                <Flex>
                  {isLoading ? null : introSessionId && introVideo ? (
                    <Flex flex={1} position="relative" alignItems="center">
                      <Flex
                        flex={1}
                        {...(builderView === 'focused'
                          ? {
                              borderBottomWidth: 0.5,
                              borderColor: 'border.muted',
                              pb: 2,
                            }
                          : {
                              as: Card,
                              padding: 0,
                              borderRadius: { base: 'md', md: 'md' },
                              mt: 2,
                            })}
                        onClick={() => {
                          setModalVideo({
                            video:
                              getMediaFromClip(
                                introVideo,
                                introVideo.clipType
                              ) || undefined,
                            summary: introVideo.summary,
                            onUpload: async (e, id, mediaType) => {
                              setModalVideo(null);
                              setIsUpdating({ ...isUpdating, unitIntro: true });
                              await handleAddMediaFromInput(
                                e,
                                introVideo.id.toString(),
                                mediaType
                              );
                              setIsUpdating({
                                ...isUpdating,
                                unitIntro: false,
                              });
                            },
                            onSaveClip: async (data) => {
                              setIsUpdating({ ...isUpdating, unitIntro: true });
                              await handleSaveClip(
                                introVideo.id.toString(),
                                data
                              );
                              setModalVideo((prevModalVideo) => ({
                                ...prevModalVideo,
                                ...(data.clipType
                                  ? {
                                      mediaType: data.clipType,
                                      video:
                                        getMediaFromClip(
                                          introVideo,
                                          data.clipType
                                        ) || undefined,
                                    }
                                  : {}),
                                ...(data.orientation
                                  ? { orientation: data.orientation }
                                  : {}),
                              }));
                              setIsUpdating({
                                ...isUpdating,
                                unitIntro: false,
                              });
                            },
                            onSaveSummary: async (summary) => {
                              setIsUpdating({ ...isUpdating, unitIntro: true });
                              await dispatch(
                                videoClipActions.update(introVideo.id, {
                                  summary,
                                })
                              );
                              setIsUpdating({
                                ...isUpdating,
                                unitIntro: false,
                              });
                              setModalVideo(null);
                            },
                            orientation: introVideo.orientation,
                            mediaType: introVideo.clipType,
                            clipId: introVideo.id.toString(),
                            qrBlob: `steppitapp://t/course/${courseId}/session/${introSessionId}/step/${introVideo.step}/bit/${introVideo.id}`,
                          });
                        }}
                      >
                        <ModuleListItem
                          title="Unit Intro"
                          showImage={false}
                          isLoading={isUpdating.unitIntro}
                        />
                      </Flex>
                      {builderView === 'outline' && (
                        <Flex
                          position="absolute"
                          right={8}
                          zIndex={2}
                          pointerEvents="none"
                          mb={0}
                          mt={2}
                        >
                          <MiniMenu
                            menuOptions={[
                              {
                                label: 'Remove Unit Intro',
                                icon: 'DeleteForever',
                                onClick: () =>
                                  setDeleteSession({
                                    id: introSessionId.toString(),
                                    type: 'intro',
                                  }),
                              },
                            ]}
                          />
                        </Flex>
                      )}
                    </Flex>
                  ) : null}
                </Flex>
              </>
            )}
          </Flex>
          {isLocked ? (
            <Flex
              px={{ base: 2, md: builderView === 'focused' ? 6 : 4 }}
              flexDirection="column"
            >
              <Text fontWeight="semibold" mb={1}>
                🔒 Unit Locked
              </Text>
              <Text>{`Upgrade to Pro to add more than ${BASIC_UNIT_LIMIT} units.`}</Text>
            </Flex>
          ) : (
            <>
              <DraggableSessions
                draggableUnit={unit}
                sessionList={sessionList}
                isLoading={isLoading || isUpdating.removeSession}
                isEditingDisabled={isEditingDisabled}
                unitId={unit.id}
                courseId={courseId}
                droppedSession={droppedSession}
                setDeleteSession={setDeleteSession}
                builderView={builderView}
              />

              <Flex
                flexDirection="column"
                px={{ base: 2, md: builderView === 'focused' ? 6 : 4 }}
              >
                <Flex
                  mt={2}
                  data-tour={courseTourIds.courseAddSession}
                  pb={0.5}
                >
                  {currentSessions.filter(
                    (s) => s.moduleType === SESSION_TYPE.normal
                  ).length >= 6 ? (
                    <Text
                      color="text.muted"
                      fontSize="sm"
                      textAlign="center"
                      width="100%"
                    >
                      Unit full (6 sessions max)
                    </Text>
                  ) : (
                    <AddItem
                      label="Add Session"
                      placeholder="What are you calling this session?"
                      onSave={handleCreateSession}
                      isUpdating={isUpdating.addSession}
                      size="sm"
                      icon="Add"
                      button
                      variant="outline"
                      isLoading={isLoading}
                      hideCard={builderView === 'focused'}
                      // aiButton={
                      //   PLATFORM === 'steppit' && !isEditingDisabled
                      //     ? {
                      //         toolSlug: 'sessions',
                      //         label: 'Generate Session Plan',
                      //         tooltip: `Plan a new session with your assistant's help`,
                      //         isLoading: false,
                      //         loadingText: '',
                      //         isOutline: false,
                      //         isDisabled: isLoading,
                      //       }
                      //     : undefined
                      // }
                    />
                  )}
                </Flex>
                <Flex>
                  {isLoading ? (
                    <Card flex={1} padding={0} borderRadius="md" mt={4}>
                      <ModuleListItem title="" showImage={false} isLoading />
                    </Card>
                  ) : outroSessionId && outroVideo ? (
                    <Flex
                      flex={1}
                      position="relative"
                      alignItems="center"
                      mt={4}
                    >
                      <Flex
                        flex={1}
                        {...(builderView === 'focused'
                          ? {
                              borderTopWidth: 0.5,
                              borderColor: 'border.muted',
                              pt: 2,
                            }
                          : {
                              as: Card,
                              padding: 0,
                              borderRadius: { base: 'md', md: 'md' },
                            })}
                      >
                        <ModuleListItem
                          title="Unit Outro"
                          showImage={false}
                          isLoading={isUpdating.unitOutro}
                          onOpen={() => {
                            setModalVideo({
                              video:
                                getMediaFromClip(
                                  outroVideo,
                                  outroVideo.clipType
                                ) || undefined,
                              summary: outroVideo.summary,
                              onUpload: async (e, id, mediaType) => {
                                setModalVideo(null);
                                setIsUpdating({
                                  ...isUpdating,
                                  unitOutro: true,
                                });
                                await handleAddMediaFromInput(
                                  e,
                                  outroVideo.id.toString(),
                                  mediaType
                                );
                                setIsUpdating({
                                  ...isUpdating,
                                  unitOutro: false,
                                });
                              },
                              onSaveClip: async (data) => {
                                setIsUpdating({
                                  ...isUpdating,
                                  unitOutro: true,
                                });
                                await handleSaveClip(
                                  outroVideo.id.toString(),
                                  data
                                );
                                setModalVideo((prevModalVideo) => ({
                                  ...prevModalVideo,
                                  ...(data.clipType
                                    ? {
                                        mediaType: data.clipType,
                                        video:
                                          getMediaFromClip(
                                            outroVideo,
                                            data.clipType
                                          ) || undefined,
                                      }
                                    : {}),
                                  ...(data.orientation
                                    ? { orientation: data.orientation }
                                    : {}),
                                }));
                                setIsUpdating({
                                  ...isUpdating,
                                  unitOutro: false,
                                });
                              },
                              onSaveSummary: async (summary) => {
                                setIsUpdating({
                                  ...isUpdating,
                                  unitOutro: true,
                                });
                                await dispatch(
                                  videoClipActions.update(outroVideo.id, {
                                    summary,
                                  })
                                );
                                setIsUpdating({
                                  ...isUpdating,
                                  unitOutro: false,
                                });
                                setModalVideo(null);
                              },
                              orientation: outroVideo.orientation,
                              mediaType: outroVideo.clipType,
                              clipId: outroVideo.id.toString(),
                              qrBlob: `steppitapp://t/course/${courseId}/session/${outroSessionId}/step/${outroVideo.step}/bit/${outroVideo.id}`,
                            });
                          }}
                        />
                      </Flex>
                      {builderView === 'outline' && (
                        <Flex
                          position="absolute"
                          right={8}
                          zIndex={2}
                          pointerEvents="none"
                          mt={0}
                        >
                          <MiniMenu
                            menuOptions={[
                              {
                                label: 'Remove Unit Outro',
                                icon: 'DeleteForever',
                                onClick: () =>
                                  setDeleteSession({
                                    id: outroSessionId.toString(),
                                    type: 'outro',
                                  }),
                              },
                            ]}
                          />
                        </Flex>
                      )}
                    </Flex>
                  ) : null}
                </Flex>
              </Flex>
            </>
          )}
        </Flex>
        {/* ) : ( */}

        {/* )} */}
      </Flex>
      <EditModal
        title={capitalize(editModalState) || ''}
        subTitle={`${unit.label}: ${unit.title}`}
        isOpen={editModalState !== null}
        onSave={() => null}
        onClose={() => setEditModalState(null)}
        saveLabel=""
        cancelLabel="Close"
        closeOnOverlayClick={false}
        onCancel={() => setEditModalState(null)}
        modalSize="4xl"
      >
        {editModalState === 'notes' ? (
          <Flex></Flex>
        ) : editModalState === 'requirements' ? (
          <>
            <FormCard
              onSave={(data) =>
                handleUnitUpdate(
                  { fullKitList: data.requirements },
                  'requirements'
                )
              }
              onCancel={() => {}}
              isDisabled={isEditingDisabled}
              isUpdating={isUpdating.requirements}
              isLoading={isLoading}
              items={[
                {
                  id: 'requirements',
                  name: 'requirements',
                  richEditor: true,
                  defaultValue: unit.checklist,
                  tooltip: 'unit_requirements',
                  label:
                    "Give your students a summary of what they'll need for this unit.",
                  labelPosition: 'top',
                },
              ]}
              hideCard
            />
          </>
        ) : editModalState === 'resources' ? (
          <>
            <Flex flexDirection="column" marginY={0} mt={-3}>
              {PLATFORM === 'workshop' && (
                <Text fontWeight="bold" mb={3}>
                  Student Resources
                </Text>
              )}
              <Text color="text.muted" mb={6}>
                Here you can provide learners with further resources such as
                articles, videos and downloadable documents.
              </Text>
              <DownloadsCard
                items={unit.downloads.filter((d) => !d.teacherOnly)}
                isUpdating={isUpdating.studentDownloads}
                isLoading={isLoading}
                isDisabled={isEditingDisabled}
                onSave={async (data) =>
                  await handleDownloadsSubmit(data, false)
                }
                helpText={
                  PLATFORM === 'workshop'
                    ? `Tip: You can provide Google Docs as downloadable PDFs by sharing the document to "anyone with the link" and replacing "/edit" with "/export?format=pdf" at the end of the URL, e.g. https://docs.google.com/document/d/[DOCUMENT_ID]/export?format=pdf`
                    : ''
                }
                hideCard
              />
            </Flex>
            {PLATFORM === 'workshop' && (
              <Flex flexDirection="column">
                <Text fontWeight="bold" mb={3}>
                  Teacher Resources
                </Text>
                <Text fontSize="sm" color="text.muted" mb={4}>
                  These resources will only be available to mentors via their
                  course preview.
                </Text>
                <DownloadsCard
                  teacherOnly
                  items={unit.downloads.filter((d) => !!d.teacherOnly)}
                  isUpdating={isUpdating.teacherDownloads}
                  isLoading={isLoading}
                  isDisabled={isEditingDisabled}
                  onSave={async (data) =>
                    await handleDownloadsSubmit(data, true)
                  }
                  hideCard
                />
              </Flex>
            )}
          </>
        ) : null}
      </EditModal>
    </>
  );
};

const CourseEditScreen: React.FC<Props> = ({
  course,
  units,
  sessionList,
  sessionDetails,
  videoClipList,
  categoryOptions,
  courseUI,
  unitUI,
  match: { params },
  currentTeam,
  currentTeamProfile,
  history,
  location,
}) => {
  const { courseId } = params;

  const dispatch = useDispatch();
  const methods = useForm();
  const {
    onOpen: onVideoOpen,
    isOpen: isVideoOpen,
    onClose: onVideoClose,
  } = useDisclosure();
  const [currentView, setCurrentView] = useState('builder');
  const [modalVideo, setModalVideo] = useState<ModalVideoState | null>(null);
  const [showPublishCourseModal, setShowPublishCourseModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [builderView, setBuilderView] = useState<'focused' | 'outline'>(
    PLATFORM === 'steppit' ? 'focused' : 'outline'
  );
  const [expandedUnit, setExpandedUnit] = useState(1);

  const generatedCourse = localStorage.getItem('generatedCourse');
  const courseIsGenerated = generatedCourse === `${courseId}`;

  const theme = useTheme();
  const windowDimensions = useWindowDimensions();
  const isMobile = windowDimensions.width < parseInt(theme.breakpoints.md, 10);

  const tourParam = courseIsGenerated
    ? 'generated'
    : getParamFromUrl(location, 't');

  const { reward, isAnimating: isRewardAnimating } = useReward(
    'publish',
    'confetti',
    {
      lifetime: 50,
      startVelocity: 15,
      colors: [
        Palette.blue['300'],
        Palette.blue['400'],
        Palette.green['300'],
        Palette.green['400'],
        Palette.orange['200'],
        Palette.red['300'],
        Palette.neutral['10'],
      ],
    }
  );

  // Initialise some local state which can be used to control the UI
  // during data update requests to the API
  const [isUpdating, setIsUpdating] = useState({
    overview: false,
    furtherDetails: false,
    description: false,
    emailConfiguration: false,
    addUnit: false,
    addAssessment: false,
    courseIntro: false,
    courseOutro: false,
    publishStatus: false,
  });

  const [droppedUnit, setDroppedUnit] = useState<DropResult | undefined>(
    undefined
  );
  const [droppedSession, setDroppedSession] = useState<DropResult | undefined>(
    undefined
  );

  // Determine whether the user has editing permissions and whether the
  // course is open for editing
  const hasEditPermissions = useHasAnyPermission([
    PERMISSION_SLUGS.can_edit_content,
  ]);

  const isEditingDisabled =
    !hasEditPermissions || (course && course.isLockedForEditing);

  // Init our chunk upload generator
  const createChunkUpload = hooks.useChunkUpload(isEditingDisabled);

  // On mount, hit the API
  const { course: courseLoading, unitList: unitListLoading } =
    hooks.useLoadingDataState(
      {
        course: {
          actions: [() => courseActions.retrieve(parseInt(courseId))],
        },
        unitList: { actions: [() => unitActions.list(parseInt(courseId))] },
      },
      [courseId]
    );

  useEffect(() => {
    if (currentView === 'builder') {
      analytics.track('Viewed Course Builder');
    }
    if (currentView === 'details') {
      analytics.track('Viewed Course Details');
    }
    if (currentView === 'todo') {
      analytics.track('Viewed Course To Do List');
    }
  }, [currentView]);

  // Collect & format the list of unit data by unit type
  const {
    normal: normalUnits,
    intro: introUnits,
    outro: outroUnits,
    assessment: assessmentUnits,
  } = formatUnitList(units);

  // A course should only have 1 (or none) intro unit and 1 (or none)
  // outro unit, so extract them
  const [introUnit] = introUnits;
  const [outroUnit] = outroUnits;

  const handleSaveOverview = async (data: Partial<OverviewFormData>) => {
    const { landscape, portrait, title, subtitle } = data;

    const formData = new FormData();

    if (landscape) {
      formData.append('image_landscape', landscape);
    }
    if (portrait) {
      formData.append('image_portrait', portrait);
    }
    if (title) {
      formData.append('title', title);
      formData.append('seo_title', title);
    }
    if (subtitle || subtitle === '') {
      formData.append('subtitle', subtitle);
    }

    setIsUpdating({ ...isUpdating, overview: true });
    const response = await dispatch(
      courseActions.update(parseInt(courseId), formData)
    );
    setIsUpdating({ ...isUpdating, overview: false });
    analytics.track('Course Details Edited');
  };

  const handleSaveFurtherDetails = async (data: FurtherDetailsFormData) => {
    const priceValue = parseInt(data.price);
    // TODO: Move price cap to constants
    const priceError = Boolean(
      !Number.isInteger(Number(priceValue)) ||
        (priceValue >= 0 && priceValue % 5 !== 0) ||
        priceValue > 1000
    );
    const formData: Partial<ICourse> = {
      ...(data.category ? { category: data.category } : {}),
      ...(data.classType ? { classType: data.classType } : {}),
      ...(data.courseDuration ? { duration: data.courseDuration } : {}),
      ...(data.accessDuration ? { accessDuration: data.accessDuration } : {}),
      ...(data.price && PLATFORM === 'steppit' && priceValue > 0 && !priceError
        ? { price: data.price }
        : {}),
      ...(data.priceTier && PLATFORM === 'steppit'
        ? { priceTier: data.priceTier }
        : {}),
    };
    setIsUpdating({ ...isUpdating, furtherDetails: true });
    await dispatch(courseActions.update(parseInt(courseId), formData));
    setIsUpdating({ ...isUpdating, furtherDetails: false });
    analytics.track('Course Details Edited');
  };

  const handleSaveDescription = async (data: Partial<DescriptionFormData>) => {
    const { description, summary } = data;
    const formData: Partial<ICourse> = {
      ...(description ? { description } : {}),
      ...(summary ? { summaryText: summary } : {}),
    };

    setIsUpdating({ ...isUpdating, description: true });
    await dispatch(courseActions.update(parseInt(courseId), formData));
    setIsUpdating({ ...isUpdating, description: false });
    analytics.track('Course Details Edited');
  };

  const handleCreateUnit = async (data: IAddItem) => {
    const formData: Partial<IUnitListItem> = {
      title: data.inputText,
      unitType: 'normal',
    };

    setIsUpdating({ ...isUpdating, addUnit: true });
    await dispatch(unitActions.create(parseInt(courseId), formData));
    setIsUpdating({ ...isUpdating, addUnit: false });
    analytics.track('Unit Created');
  };

  const handleCreateAssessment = async (data: IAddItem) => {
    const formData: Partial<IUnitListItem> = {
      title: data.inputText,
      unitType: 'assessment',
    };

    setIsUpdating({ ...isUpdating, addAssessment: true });
    await dispatch(unitActions.create(parseInt(courseId), formData));
    setIsUpdating({ ...isUpdating, addAssessment: false });
    analytics.track('Assessment Created');
  };

  const handleUnitReorder = async (data: IDraggableData<DraggableUnit>) => {
    setIsUpdating({ ...isUpdating, addUnit: true });
    await Promise.all(
      data.map((item, idx: number) => {
        if (item.hasChanged) {
          return dispatch(
            unitActions.update(parseInt(item.id), { index: idx + 2 })
          );
        }
        return;
      })
    );
    setIsUpdating({ ...isUpdating, addUnit: false });
    analytics.track('Unit Edited');
  };

  const handleCancel = () => {};

  /**
   * Handler for sending a test email of the selected type
   */
  const handleSendTestEmail = async (data: ModalFormData) => {
    const { emailType, emailAddress, ...rest } = data;

    return await dispatch(
      courseActions.sendPreviewEmail({
        emailType,
        emailAddress,
        course: course.id,
        dynamicData: rest,
      })
    );
  };

  /**
   * Handler for updating course email configurations
   */
  const handleEmailConfigurationSave = async ({
    welcomeEmailEnabled,
    automatedEmailEnabled,
    onboardingEmailEnabled,
    welcomeEmail,
  }: EmailConfigFormData) => {
    const { downloads, image, text } = welcomeEmail;

    const formData = new FormData();

    // Construct form data for submission. We use form data as our email
    // configuration can include image data
    if (image) {
      formData.append('welcome_email_image_raw', image);
    }

    if (text) {
      formData.append('welcome_email_text', text);
    }

    formData.append(
      'welcome_email_downloads',
      JSON.stringify(
        // Remove empty JSON values
        decamelizeKeys(pickBy(downloads, (value) => value && value.length))
      )
    );

    formData.append(
      'enable_automated_weekly_emails',
      boolToString(automatedEmailEnabled)
    );
    formData.append(
      'enable_pre_start_email',
      boolToString(onboardingEmailEnabled)
    );
    formData.append('enable_welcome_email', boolToString(welcomeEmailEnabled));

    setIsUpdating({ ...isUpdating, emailConfiguration: true });
    await dispatch(courseActions.update(parseInt(courseId), formData));
    setIsUpdating({ ...isUpdating, emailConfiguration: false });
    analytics.track('Course Details Edited');
  };

  const handleUploadTrailer = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    e.preventDefault();

    const files = e?.target?.files;

    if (!files) return;

    const file = files[0];
    const chunkUpload = createChunkUpload(file.name, file.size);

    const response = await chunkUpload.startUpload<CompleteUploadChunkAction>(
      file
    );

    // If chunked uploads are disabled (e.g. due to permissions) then calling
    // `startUpload` will result in a void response
    if (!response) return;

    const { payload } = response;
    // Only run the remaining code if the upload was successful. A successful
    // upload will contain the 'file' property which we then use to finalise
    // the upload process
    if (payload && 'file' in payload) {
      const { file, filename } = payload;

      // The `file` included in the successful upload response gives us the full
      // path to the uploaded file. We want to add this to our video to 'link'
      // the uploaded file to a video object.
      await dispatch(
        courseActions.update(parseInt(courseId), {
          video: file,
          originalFilename: filename,
        })
      );
      analytics.track('Trailer Upload Success');
    } else {
      analytics.track('Trailer Upload Failed');
    }
  };

  const handleUploadUnitVideo = async (
    unitId: string,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    e.preventDefault();

    const files = e?.target?.files;

    if (!files) return;

    const file = files[0];

    const chunkUpload = createChunkUpload(file.name, file.size);

    const response = await chunkUpload.startUpload<CompleteUploadChunkAction>(
      file
    );

    // If chunked uploads are disabled (e.g. due to permissions) then calling
    // `startUpload` will result in a void response
    if (!response) return;

    const { payload } = response;
    // Only run the remaining code if the upload was successful. A successful
    // upload will contain the 'file' property which we then use to finalise
    // the upload process
    if (payload && 'file' in payload) {
      const { file, filename } = payload;

      // The `file` included in the successful upload response gives us the full
      // path to the uploaded file. We want to add this to our video to 'link'
      // the uploaded file to a video object.
      await dispatch(
        unitActions.update(parseInt(unitId), {
          video: file,
          originalFilename: filename,
        })
      );
      analytics.track('Unit Edited');
    }
  };

  const handleAddMediaToUnit = async (
    file: File,
    /** The ID of the clip */
    id: string,
    mediaType: 'video' | 'audio' = 'video'
  ) => {
    const chunkUpload = createChunkUpload(file.name, file.size, { id });

    const response = await chunkUpload.startUpload<CompleteUploadChunkAction>(
      file
    );

    // If chunked uploads are disabled (e.g. due to permissions) then calling
    // `startUpload` will result in a void response
    if (!response) return;

    const { payload } = response;

    // Only run the remaining code if the upload was successful. A successful
    // upload will contain the 'file' property which we then use to finalize
    // the upload process
    if (!payload || !('file' in payload)) return;

    if (mediaType === 'audio') {
      const { file: audioFile, filename } = payload;
      // The `file` included in the successful upload response gives us the full
      // path to the uploaded file. We want to add this to our video clip to 'link'
      // the uploaded file to a video clip object.
      await dispatch(
        unitActions.update(parseInt(id), {
          audio: audioFile,
          originalFilename: filename,
        })
      );
    } else {
      const { file: videoFile, filename } = payload;
      // The `file` included in the successful upload response gives us the full
      // path to the uploaded file. We want to add this to our video clip to 'link'
      // the uploaded file to a video clip object.
      await dispatch(
        unitActions.update(parseInt(id), {
          video: videoFile,
          originalFilename: filename,
        })
      );
    }
    analytics.track('Unit Edited');

    return;
  };

  const handleSaveUnit = async (id: string, data: Partial<IVideoClip>) => {
    analytics.track('Unit Edited');
    if (data.videoBlob) {
      const videoFile = new File(
        [data.videoBlob],
        `course-${courseId}-unit-${id}.mp4`,
        { type: data.videoBlob.type }
      );
      return await handleAddMediaToUnit(videoFile, id, 'video');
    }
    if (data.audioBlob) {
      const audioFile = new File(
        [data.audioBlob],
        `course-${courseId}-unit-${id}.mp3`,
        { type: data.audioBlob.type }
      );
      return await handleAddMediaToUnit(audioFile, id, 'audio');
    }
    if (data.image) {
      const imageFile = data.image as File;
      const imageUrl = URL.createObjectURL(imageFile);
      const dimensions = await videoUtils.getHeightAndWidthFromImageUrl(
        imageUrl
      );
      let orientation = 'portrait';
      if (dimensions.width > dimensions.height) {
        orientation = 'landscape';
      }
      const formData = new FormData();
      formData.append('image', imageFile);
      formData.append('orientation', orientation);
      formData.append('original_filename', imageFile.name);
      return await dispatch(unitActions.update(parseInt(id), formData));
    }
    if (data.clipType) {
      return await dispatch(
        unitActions.update(parseInt(id), { unitClipType: data.clipType })
      );
    }
    return await dispatch(
      unitActions.update(parseInt(id), data as Partial<IUnit>)
    );
  };

  const handleAddUnitMediaFromInput = async (
    e: React.ChangeEvent<HTMLInputElement>,
    /** The ID of the clip */
    id: string,
    mediaType: 'video' | 'audio' = 'video'
  ) => {
    e.preventDefault();

    const files = e?.target?.files;

    if (!files) return;

    const file = files[0];
    return await handleAddMediaToUnit(file, id, mediaType);
  };

  const getMediaFromUnit = (
    u: DraggableUnit,
    mediaType: 'text' | 'image' | 'audio' | 'video'
  ) => {
    if (mediaType === 'image') return u.image;
    if (mediaType === 'audio') return u.audio;
    if (mediaType === 'video') return u.video;
    return null;
  };

  const classTypes: { [key in IClassType]: string } = {
    cohort: 'Cohort',
    intake: 'Intake',
    open: 'Open',
  };

  const isLoading = courseLoading || unitListLoading;

  const showAdvancedFields =
    currentTeam && ADVANCED_COURSE_OPTIONS_WHITELIST.includes(currentTeam);

  const pageTabs: InPageNavTab[] = [
    {
      slug: 'builder',
      label: 'Course Builder',
      icon: 'DashboardCustomize',
    },
    {
      slug: 'details',
      label: 'Edit Details',
      icon: 'EditNote',
    },
  ];

  const courseSessions = isLoading
    ? []
    : Object.values(sessionList).filter(
        (s) =>
          units[s.unit] &&
          units[s.unit].course === course.id &&
          (units[s.unit].unitType === UNIT_TYPE.normal ||
            units[s.unit].unitType === UNIT_TYPE.assessment) &&
          s.moduleType === SESSION_TYPE.normal
      );

  const courseIntroReady = Boolean(
    introUnit?.unitClipType
      ? introUnit?.unitClipType === 'text'
        ? !!introUnit?.description
        : introUnit?.unitClipType === 'image'
        ? !!introUnit?.image
        : introUnit?.unitClipType === 'audio'
        ? !!introUnit?.audio
        : introUnit?.unitClipType === 'video'
        ? !!introUnit?.video
        : false
      : false
  );

  const courseOutroReady = Boolean(
    outroUnit?.unitClipType
      ? outroUnit?.unitClipType === 'text'
        ? !!outroUnit?.description
        : outroUnit?.unitClipType === 'image'
        ? !!outroUnit?.image
        : outroUnit?.unitClipType === 'audio'
        ? !!outroUnit?.audio
        : outroUnit?.unitClipType === 'video'
        ? !!outroUnit?.video
        : false
      : false
  );

  const toDoList = [
    {
      id: 0,
      content: 'Give your course an intro and outro',
      slug: '0',
      onClick: () => {
        analytics.track('Course To Do List Item Opened');
        setCurrentView('builder');
      },
      totalNum: 2,
      currentNum: Number(courseIntroReady) + Number(courseOutroReady),
    },
    {
      id: 1,
      content: 'Upload a thumbnail image for your course',
      slug: '1',
      onClick: () => {
        analytics.track('Course To Do List Item Opened');
        setCurrentView('builder');
      },
      totalNum: 1,
      currentNum: Number(!!course?.imageLandscape),
    },
    {
      id: 2,
      content: 'Make sure every session is ready to go',
      slug: '2',
      onClick: () => {
        analytics.track('Course To Do List Item Opened');
        setCurrentView('builder');
      },
      totalNum: courseSessions.length,
      currentNum: courseSessions.filter((s) => s.isReady).length,
    },
  ]
    .filter((i) => i.totalNum > 0)
    .map((i) => ({
      ...i,
      isChecked: i.currentNum >= i.totalNum,
      isComplete: i.currentNum >= i.totalNum,
      label: i.content,
    }));

  const numToDo = toDoList.filter((t) => !t.isChecked).length;

  if (!isLoading && numToDo > 0 && PLATFORM === 'workshop') {
    pageTabs.push({
      slug: 'todo',
      label: 'To Do',
      icon: 'TaskAlt',
      notification: numToDo,
    });
  }

  const noToDos = isLoading ? undefined : numToDo === 0;
  const prevNoToDos = usePreviousValue(noToDos);
  useEffect(() => {
    if (!isLoading && noToDos === true && prevNoToDos === false) {
      analytics.track('Course To Do List Completed');
    }
  }, [noToDos, isLoading]);

  if (!isLoading && !course) {
    history.push(navRoutes.cms.catalogue.path());
    return null;
  }

  const isPro = Boolean(
    currentTeamProfile?.isPro || (currentTeam && PRO_ORGS.includes(currentTeam))
  );

  return (
    <>
      <ScreenWrapper>
        {course?.status === 'draft' && (
          <PublishCourseModal
            isOpen={showPublishCourseModal}
            showConfetti={showPublishCourseModal}
            onClose={() => setShowPublishCourseModal(false)}
            onPublish={async (accessChoice) => {
              if (accessChoice === 'public') {
                setIsUpdating({ ...isUpdating, publishStatus: true });
                await dispatch(
                  courseActions.update(parseInt(courseId), {
                    status: 'published',
                    isPublic: true,
                    isVisible: true,
                  })
                );
                setIsUpdating({ ...isUpdating, publishStatus: false });
                setShowShareModal(true);
              } else {
                setIsUpdating({ ...isUpdating, publishStatus: true });
                await dispatch(
                  courseActions.update(parseInt(courseId), {
                    status: 'published',
                  })
                );
                setIsUpdating({ ...isUpdating, publishStatus: false });
                history.push({
                  pathname: navRoutes.cms.classes.path(),
                  search: 'p=new',
                });
              }
              analytics.track('Course Published');
            }}
          />
        )}
        {currentTeamProfile &&
          course?.status === 'published' &&
          PLATFORM === 'steppit' && (
            <ShareCourseModal
              isOpen={showShareModal}
              onClose={() => setShowShareModal(false)}
              courseSlug={course.slug}
              channelHandle={currentTeamProfile.handle}
              isPublic={course.isPublic}
              setIsPublic={async (isPublic) => {
                setIsUpdating({ ...isUpdating, publishStatus: true });
                await dispatch(
                  courseActions.update(parseInt(courseId), {
                    isPublic,
                  })
                );
                setIsUpdating({ ...isUpdating, publishStatus: false });
                analytics.track('Course Details Edited');
              }}
              isVisible={course.isVisible}
              setIsVisible={async (isVisible) => {
                setIsUpdating({ ...isUpdating, publishStatus: true });
                await dispatch(
                  courseActions.update(parseInt(courseId), {
                    isVisible,
                  })
                );
                setIsUpdating({ ...isUpdating, publishStatus: false });
                analytics.track('Course Details Edited');
              }}
              isLoading={isUpdating.publishStatus}
            />
          )}
        <Flex flexDirection="column" flex={1} mb={24}>
          <InPageNav
            tabs={pageTabs}
            currentTab={currentView}
            initialTab="builder"
            onSwitchTab={(activeTab) => setCurrentView(activeTab)}
            navByParams
            rightElement={
              <>
                {course?.status === 'draft' ? (
                  <Flex
                    position="relative"
                    data-tour={courseTourIds.generatedCoursePublish}
                  >
                    <Button
                      colorScheme="green"
                      size="sm"
                      isDisabled={numToDo > 0 || isRewardAnimating}
                      onClick={async () => {
                        if (PLATFORM === 'workshop') {
                          setIsUpdating({ ...isUpdating, publishStatus: true });
                          await dispatch(
                            courseActions.update(parseInt(courseId), {
                              status: 'published',
                            })
                          );
                          setIsUpdating({
                            ...isUpdating,
                            publishStatus: false,
                          });
                        } else {
                          reward();
                          setTimeout(
                            () => setShowPublishCourseModal(true),
                            850
                          );
                        }
                      }}
                      isLoading={isUpdating.publishStatus}
                      id="publish"
                      data-tour={courseTourIds.coursePublish}
                    >
                      <Text>
                        Publish
                        <chakra.span display={{ base: 'none', md: 'inline' }}>
                          {' Course'}
                        </chakra.span>
                      </Text>
                    </Button>
                    {numToDo > 0 && (
                      <Tooltip
                        label="Take a look at your to-do list to see what's remaining before you can publish your course"
                        placement="left"
                      >
                        <Box
                          position="absolute"
                          top={0}
                          right={0}
                          bottom={0}
                          left={0}
                          // onClick={() => setCurrentView('todo')}
                          cursor="not-allowed"
                        />
                      </Tooltip>
                    )}
                  </Flex>
                ) : course?.status === 'published' && PLATFORM === 'steppit' ? (
                  <Button
                    colorScheme="green"
                    variant="outline"
                    size="sm"
                    icon="Share"
                    onClick={() => setShowShareModal(true)}
                    isLoading={isUpdating.publishStatus}
                  >
                    <Text>Share</Text>
                  </Button>
                ) : null}
              </>
            }
          />
          {currentView === 'builder' ? (
            <Flex
              flexDirection="column"
              flex={1}
              data-tour={courseTourIds.generatedCourse}
            >
              <Stack
                flex={1}
                direction={{ base: 'column-reverse', lg: 'row' }}
                marginBottom="defaultMargin"
              >
                <Flex flex={1}>
                  <FormContext {...methods}>
                    <OverviewCard
                      // @ts-ignore - ToDo
                      onSave={handleSaveOverview}
                      isDisabled={isEditingDisabled}
                      onCancel={handleCancel}
                      landscape={course?.imageLandscape}
                      portrait={course?.imagePortrait}
                      isUpdating={isUpdating.overview}
                      isLoading={courseLoading}
                    >
                      <OverviewTextArea
                        id="title"
                        name="title"
                        label="Course title"
                        labelPosition={isMobile ? 'top' : 'inline'}
                        isDisabled={isEditingDisabled}
                        defaultValue={course?.title}
                        isLoading={courseLoading}
                        validation={{
                          required: {
                            value: true,
                            message: 'Please enter a title.',
                          },
                        }}
                        tooltip="course_title"
                        autoResize
                        onKeyPress={(e) => {
                          // Disable 'Enter' key
                          if (e.key === 'Enter') {
                            e.preventDefault();
                          }
                        }}
                        fontWeight="semibold"
                        labelStyleProps={{ fontSize: 'sm' }}
                        aiButton={
                          PLATFORM === 'steppit' && !isEditingDisabled
                            ? {
                                toolSlug: 'courseTextBoxes',
                                toolVariant: 'courseTitle',
                                label: 'Generate Title',
                                tooltip: `Let your assistant suggest a title for you`,
                                isLoading: false,
                                loadingText: '',
                                isOutline: !!course?.title,
                                isDisabled: courseLoading,
                                context: course
                                  ? { courseId: `${course.id}` }
                                  : {},
                                onSave: async (res) => {
                                  await handleSaveOverview({
                                    title: res.courseTitle,
                                  });
                                },
                              }
                            : undefined
                        }
                      />
                      <OverviewTextArea
                        id="subtitle"
                        name="subtitle"
                        label="Subtitle"
                        labelPosition={isMobile ? 'top' : 'inline'}
                        isDisabled={isEditingDisabled}
                        defaultValue={course?.subtitle}
                        isLoading={courseLoading}
                        tooltip="course_subtitle"
                        autoResize
                        onKeyPress={(e) => {
                          // Disable 'Enter' key
                          if (e.key === 'Enter') {
                            e.preventDefault();
                          }
                        }}
                        labelStyleProps={{ fontSize: 'sm' }}
                        aiButton={
                          PLATFORM === 'steppit' && !isEditingDisabled
                            ? {
                                toolSlug: 'courseTextBoxes',
                                toolVariant: 'courseSubtitle',
                                label: 'Generate Subtitle',
                                tooltip: `Let your assistant suggest a subtitle for you`,
                                isLoading: false,
                                loadingText: '',
                                isOutline: !!course?.subtitle,
                                isDisabled: courseLoading,
                                context: course
                                  ? { courseId: `${course.id}` }
                                  : {},
                                onSave: async (res) => {
                                  await handleSaveOverview({
                                    subtitle: res.courseSubtitle,
                                  });
                                },
                              }
                            : undefined
                        }
                      />
                      {showAdvancedFields && (
                        <OverviewVideoInput
                          isDisabled={isEditingDisabled}
                          onPlay={onVideoOpen}
                          onUpload={handleUploadTrailer}
                          isPlayDisabled={!course?.video}
                          isLoading={courseLoading}
                          isUpdating={courseUI.loading}
                        />
                      )}
                    </OverviewCard>
                  </FormContext>
                </Flex>
                {!courseLoading &&
                  course &&
                  course?.status !== 'published' &&
                  PLATFORM === 'steppit' && (
                    <OnboardingChecklist
                      id={`course-${course.id}-onboarding`}
                      onboardingSteps={toDoList}
                      title="Build Your Course"
                      emoji="🚀"
                      ownerOnly={false}
                      canBeDismissed={false}
                      maxWidth={{ base: 'none', lg: '270px', '2xl': '420px' }}
                      // onComplete={onOnboardingComplete}
                    />
                  )}
              </Stack>
              <Flex
                flexDirection="column"
                flex={1}
                data-tour={courseTourIds.generatedCourseSummary}
              >
                <Flex
                  flexDirection="column"
                  flex={1}
                  data-tour={courseTourIds.courseBuilder}
                >
                  <Flex
                    flexDirection="column"
                    flex={1}
                    data-tour={courseTourIds.courseSummary}
                  >
                    <Flex alignItems="center" mb={4} flexWrap="wrap">
                      <Tabs
                        index={builderView === 'focused' ? 0 : 1}
                        onChange={(index) => {
                          setBuilderView(index === 0 ? 'focused' : 'outline');
                        }}
                        isLazy
                        variant="unstyled"
                        mt={2}
                      >
                        <TabList mx={{ base: 'defaultMargin', md: 0 }}>
                          <Tab
                            px={4}
                            py={2}
                            fontSize={{ base: 'sm', md: 'md' }}
                            mr={2}
                            borderRadius="full"
                            transition="background-color 0.3s, color 0.3s"
                            color="text.muted"
                            _hover={{
                              bg: 'background.primary',
                            }}
                            _active={{
                              bg: 'background.primaryDark',
                            }}
                            _selected={{
                              color: 'text.primaryDark',
                              bg: 'background.primaryDark',
                            }}
                          >
                            <MdIcon name="CenterFocusStrong" />
                            <Text ml={1.5} fontWeight="semibold">
                              Focused View
                            </Text>
                          </Tab>
                          <Tab
                            px={4}
                            py={2}
                            fontSize={{ base: 'sm', md: 'md' }}
                            borderRadius="full"
                            transition="background-color 0.3s, color 0.3s"
                            color="text.muted"
                            _hover={{
                              bg: 'background.primary',
                            }}
                            _active={{
                              bg: 'background.primaryDark',
                            }}
                            _selected={{
                              color: 'text.primaryDark',
                              bg: 'background.primaryDark',
                            }}
                          >
                            <MdIcon name="ViewList" />
                            <Text ml={1.5} fontWeight="semibold">
                              Outline View
                            </Text>
                          </Tab>
                        </TabList>
                      </Tabs>
                      <Flex flex={1} />
                      {normalUnits.length > 1 && builderView === 'outline' && (
                        <Menu>
                          <Flex flexDirection="column" mt={2}>
                            <MenuButton
                              as={Button}
                              size="sm"
                              variant="ghost"
                              icon="ArrowDownward"
                              width={130}
                            >
                              Jump to Unit
                            </MenuButton>
                            <Flex position="relative" zIndex={2}>
                              <MenuList minWidth={130}>
                                {orderBy(normalUnits, 'index').map((u) => (
                                  <MenuItem
                                    key={`jump-to-unit-${u.id}`}
                                    as={Link}
                                    fontSize="sm"
                                    justifyContent="center"
                                    href={`#unit-${u.index - 1}`}
                                    _hover={{ textDecoration: 'none' }}
                                  >
                                    {u.label}
                                  </MenuItem>
                                ))}
                              </MenuList>
                            </Flex>
                          </Flex>
                        </Menu>
                      )}
                    </Flex>
                    {Boolean(introUnit) ? (
                      <Card
                        padding={0}
                        borderRadius={{ base: 0, md: 'md' }}
                        onClick={() => {
                          setModalVideo({
                            video:
                              getMediaFromUnit(
                                introUnit,
                                introUnit.unitClipType
                              ) || undefined,
                            summary: introUnit.description,
                            onUpload: async (e, id, mediaType) => {
                              setModalVideo(null);
                              setIsUpdating({
                                ...isUpdating,
                                courseIntro: true,
                              });
                              await handleAddUnitMediaFromInput(
                                e,
                                introUnit.id.toString(),
                                mediaType
                              );
                              setIsUpdating({
                                ...isUpdating,
                                courseIntro: false,
                              });
                            },
                            onSaveClip: async (data) => {
                              setIsUpdating({
                                ...isUpdating,
                                courseIntro: true,
                              });
                              await handleSaveUnit(
                                introUnit.id.toString(),
                                data
                              );
                              setModalVideo((prevModalVideo) => ({
                                ...prevModalVideo,
                                ...(data.clipType
                                  ? {
                                      mediaType: data.clipType,
                                      video:
                                        getMediaFromUnit(
                                          introUnit,
                                          data.clipType
                                        ) || undefined,
                                    }
                                  : {}),
                                ...(data.orientation
                                  ? { orientation: data.orientation }
                                  : {}),
                              }));
                              setIsUpdating({
                                ...isUpdating,
                                courseIntro: false,
                              });
                            },
                            onSaveSummary: async (summary) => {
                              setIsUpdating({
                                ...isUpdating,
                                courseIntro: true,
                              });
                              await dispatch(
                                unitActions.update(parseInt(introUnit.id), {
                                  detailedSummary: summary,
                                  summary,
                                })
                              );
                              setIsUpdating({
                                ...isUpdating,
                                courseIntro: false,
                              });
                              setModalVideo(null);
                              analytics.track('Unit Edited');
                            },
                            orientation: introUnit.orientation,
                            mediaType: introUnit.unitClipType,
                            unitId: introUnit.id.toString(),
                          });
                        }}
                        position="relative"
                        alignItems="center"
                      >
                        <ModuleListItem
                          title="Course Intro"
                          showImage={false}
                          isLoading={isLoading || isUpdating.courseIntro}
                        />
                        <Flex
                          position="absolute"
                          right={10}
                          zIndex={1.5}
                          pointerEvents="none"
                          alignItems="center"
                        >
                          {courseIntroReady ? (
                            <MdIcon name="Done" color="icon.success" />
                          ) : courseIntroReady === false ? (
                            <MdIcon
                              name="IncompleteCircle"
                              color="text.muted"
                            />
                          ) : null}
                        </Flex>
                      </Card>
                    ) : null}
                    <DragDropContext
                      onDragEnd={async (result) => {
                        if (result.type === 'UNIT') {
                          setDroppedUnit(result);
                        }
                        if (result.type === 'SESSION') {
                          setDroppedSession(result);
                        }
                      }}
                    >
                      <Draggable
                        data={
                          // During loading, render 2 empty items
                          isLoading
                            ? [
                                {
                                  id: '0',
                                  title: '',
                                  label: '',
                                  index: 0,
                                  unitType: '',
                                  modules: [],
                                  downloads: [],
                                  checklist: '',
                                  description: '',
                                  video: '',
                                  dragDisabled: true,
                                  audio: '',
                                  image: '',
                                  orientation: 'portrait',
                                  unitClipType: 'video',
                                },
                                {
                                  id: '1',
                                  title: '',
                                  label: '',
                                  index: 1,
                                  unitType: '',
                                  modules: [],
                                  downloads: [],
                                  checklist: '',
                                  description: '',
                                  video: '',
                                  dragDisabled: true,
                                  audio: '',
                                  image: '',
                                  orientation: 'portrait',
                                  unitClipType: 'video',
                                },
                              ]
                            : orderBy(normalUnits, 'index')
                        }
                        // @ts-ignore
                        onDragEnd={handleUnitReorder}
                        // Disable drag during loading
                        dragEnabled={
                          !isLoading &&
                          !isEditingDisabled &&
                          builderView === 'outline'
                        }
                        hasDragHandle
                        hasParentContext
                        droppableType="UNIT"
                        lastDropped={droppedUnit}
                      >
                        {(unit) => (
                          <UnitItem
                            unit={unit}
                            isLoading={isLoading}
                            isLocked={
                              PLATFORM === 'steppit' &&
                              !isPro &&
                              unit.index >= BASIC_UNIT_LIMIT
                            }
                            courseId={courseId}
                            sessionList={sessionList}
                            sessionDetails={sessionDetails}
                            videoClipList={videoClipList}
                            isEditingDisabled={isEditingDisabled}
                            droppedSession={droppedSession}
                            setModalVideo={setModalVideo}
                            modalVideo={modalVideo}
                            builderView={builderView}
                            expandedUnit={expandedUnit}
                            setExpandedUnit={setExpandedUnit}
                          />
                        )}
                      </Draggable>
                    </DragDropContext>
                    {!isLoading && !isEditingDisabled && (
                      <>
                        {normalUnits.length < BASIC_UNIT_LIMIT || isPro ? (
                          <Flex
                            mt={4}
                            mx={{ base: 2, md: 0 }}
                            data-tour={courseTourIds.courseAddUnit}
                          >
                            <AddItem
                              label="Add Unit"
                              placeholder="What's the name of this unit?"
                              onSave={handleCreateUnit}
                              isUpdating={isUpdating.addUnit}
                              size="sm"
                              icon="AddCircleOutline"
                              button
                              variant={
                                !isLoading && normalUnits.length === 0
                                  ? 'solid'
                                  : 'dotted'
                              }
                              isLoading={isLoading}
                            />
                          </Flex>
                        ) : (
                          <Flex
                            mt={7}
                            mb={6}
                            mx={{ base: 2, md: 0 }}
                            justifyContent="center"
                          >
                            <ProCta label="Add unlimited units with" />
                          </Flex>
                        )}
                      </>
                    )}

                    {/* TODO: Assessment creation flow for platform, then remove "showAdvancedFields" */}
                    {assessmentUnits.length > 0 ? (
                      assessmentUnits.map((assessmentUnit) => (
                        <UnitItem
                          key={`assessmentUnit-${assessmentUnit.id}`}
                          unit={assessmentUnit}
                          isLoading={isLoading}
                          isLocked={PLATFORM === 'steppit' && !isPro}
                          courseId={courseId}
                          sessionList={sessionList}
                          sessionDetails={sessionDetails}
                          videoClipList={videoClipList}
                          isEditingDisabled={isEditingDisabled}
                          droppedSession={droppedSession}
                          setModalVideo={setModalVideo}
                          modalVideo={modalVideo}
                          builderView={builderView}
                          expandedUnit={expandedUnit}
                          setExpandedUnit={setExpandedUnit}
                        />
                      ))
                    ) : showAdvancedFields ? (
                      <Flex mt={4} mx={{ base: 2, md: 0 }}>
                        <AddItem
                          label="Add Assessment"
                          placeholder="What's the title of this assessment?"
                          onSave={handleCreateAssessment}
                          isUpdating={isUpdating.addAssessment}
                          size="sm"
                          icon="Assignment"
                          button
                          variant="dotted"
                          isLoading={isLoading}
                          colorScheme="orange"
                        />
                      </Flex>
                    ) : null}

                    {Boolean(outroUnit) ? (
                      <Card
                        padding={0}
                        mt={4}
                        borderRadius={{ base: 0, md: 'md' }}
                        onClick={() => {
                          setModalVideo({
                            video:
                              getMediaFromUnit(
                                outroUnit,
                                outroUnit.unitClipType
                              ) || undefined,
                            summary: outroUnit.description,
                            onUpload: async (e, id, mediaType) => {
                              setModalVideo(null);
                              setIsUpdating({
                                ...isUpdating,
                                courseOutro: true,
                              });
                              await handleAddUnitMediaFromInput(
                                e,
                                outroUnit.id.toString(),
                                mediaType
                              );
                              setIsUpdating({
                                ...isUpdating,
                                courseOutro: false,
                              });
                            },
                            onSaveClip: async (data) => {
                              setIsUpdating({
                                ...isUpdating,
                                courseOutro: true,
                              });
                              await handleSaveUnit(
                                outroUnit.id.toString(),
                                data
                              );
                              setModalVideo((prevModalVideo) => ({
                                ...prevModalVideo,
                                ...(data.clipType
                                  ? {
                                      mediaType: data.clipType,
                                      video:
                                        getMediaFromUnit(
                                          outroUnit,
                                          data.clipType
                                        ) || undefined,
                                    }
                                  : {}),
                                ...(data.orientation
                                  ? { orientation: data.orientation }
                                  : {}),
                              }));
                              setIsUpdating({
                                ...isUpdating,
                                courseOutro: false,
                              });
                            },
                            onSaveSummary: async (summary) => {
                              setIsUpdating({
                                ...isUpdating,
                                courseOutro: true,
                              });
                              await dispatch(
                                unitActions.update(parseInt(outroUnit.id), {
                                  detailedSummary: summary,
                                  summary,
                                })
                              );
                              setIsUpdating({
                                ...isUpdating,
                                courseOutro: false,
                              });
                              setModalVideo(null);
                              analytics.track('Unit Edited');
                            },
                            orientation: outroUnit.orientation,
                            mediaType: outroUnit.unitClipType,
                            unitId: outroUnit.id.toString(),
                          });
                        }}
                        position="relative"
                        alignItems="center"
                      >
                        <ModuleListItem
                          title="Course Outro"
                          showImage={false}
                          isLoading={isLoading || isUpdating.courseOutro}
                        />
                        <Flex
                          position="absolute"
                          right={10}
                          zIndex={2}
                          pointerEvents="none"
                          alignItems="center"
                        >
                          {courseOutroReady ? (
                            <MdIcon name="Done" color="icon.success" />
                          ) : courseOutroReady === false ? (
                            <MdIcon
                              name="IncompleteCircle"
                              color="text.muted"
                            />
                          ) : null}
                        </Flex>
                      </Card>
                    ) : null}
                  </Flex>
                </Flex>
              </Flex>
              {builderView === 'focused' && (
                <Flex
                  mt={6}
                  mx={{ base: 'defaultMargin', md: 0 }}
                  borderRadius="md"
                >
                  <Text fontSize="sm" color="text.muted">
                    <b>Note:</b> To edit unit titles and re-order or delete
                    content, switch over to{' '}
                    <chakra.span
                      color="text.primary"
                      cursor="pointer"
                      _hover={{ textDecoration: 'underline' }}
                      onClick={() => setBuilderView('outline')}
                      fontWeight="semibold"
                    >
                      Outline View
                    </chakra.span>
                    .
                  </Text>
                </Flex>
              )}
            </Flex>
          ) : currentView === 'details' ? (
            <Flex flexDirection="column" flex={1}>
              <Flex
                flexDirection="column"
                flex={1}
                mb={{ base: 'defaultMargin' }}
              >
                <SectionTitle title="Course Details" />
                <FurtherDetailsCard
                  isDisabled={isEditingDisabled}
                  categories={categoryOptions}
                  classTypes={classTypes}
                  onSave={handleSaveFurtherDetails}
                  onCancel={handleCancel}
                  courseDuration={course?.duration}
                  accessDuration={course?.accessDuration}
                  price={course?.price}
                  priceTier={course?.priceTier}
                  isUpdating={isUpdating.furtherDetails}
                  isLoading={courseLoading || !categoryOptions}
                  category={course?.category}
                  classType={course?.classType}
                  showAdvancedFields={Boolean(showAdvancedFields)}
                />
              </Flex>
              <Flex flexDirection="column" marginY="defaultMargin">
                <SectionTitle title="Description" />
                <FormCard
                  isDisabled={isEditingDisabled}
                  onSave={handleSaveDescription}
                  onCancel={() => {}}
                  isUpdating={isUpdating.description}
                  isLoading={courseLoading}
                  items={[
                    {
                      id: 'summary',
                      name: 'summary',
                      label: PLATFORM === 'workshop' ? 'Course Summary' : '',
                      placeholder:
                        PLATFORM === 'steppit'
                          ? 'Write a brief summary to introduce your course to prospective students...'
                          : '',
                      helpText:
                        PLATFORM === 'steppit'
                          ? "This appears at the top of your course's landing page."
                          : '',
                      errorMessage: 'Please enter a brief summary',
                      defaultValue: course?.summaryText,
                      richEditor: true,
                      labelAlign: 'flex-start' as 'flex-start',
                      tooltip: 'course_short_summary' as 'course_short_summary',
                      labelPosition: 'top' as 'top',
                      aiButton:
                        PLATFORM === 'steppit' && !isEditingDisabled
                          ? {
                              toolSlug: 'courseTextBoxes',
                              toolVariant: 'courseDescription',
                              label: 'Generate Description',
                              tooltip: `Let your assistant write a description of your course for you`,
                              isLoading: isUpdating.description,
                              loadingText: '',
                              isOutline: !!course?.summaryText,
                              isDisabled:
                                courseLoading || isUpdating.description,
                              alwaysShow: true,
                              context: course
                                ? { courseId: `${course.id}` }
                                : {},
                              onSave: async (res) => {
                                await handleSaveDescription({
                                  summary: res.courseDescription,
                                });
                              },
                            }
                          : undefined,
                    },
                    ...(PLATFORM === 'workshop'
                      ? [
                          {
                            id: 'description',
                            name: 'description',
                            label: 'Full Description',
                            placeholder: '',
                            helpText: '',
                            // placeholder:
                            //   PLATFORM === 'steppit'
                            //     ? "Describe what your course offers, who it's for and why the reader might consider it..."
                            //     : '',
                            // helpText:
                            //   PLATFORM === 'steppit'
                            //     ? "This forms the 'About' section of your course's landing page."
                            //     : '',
                            errorMessage: 'Please enter a description',
                            defaultValue: course?.description,
                            richEditor: true,
                            labelAlign: 'flex-start' as 'flex-start',
                            tooltip:
                              'course_description' as 'course_description',
                            labelPosition: 'top' as 'top',
                          },
                        ]
                      : []),
                  ]}
                />
              </Flex>
              <Flex flexDirection="column" marginY="defaultMargin">
                <SectionTitle title="Emails" />
                <EmailConfig
                  welcomeEmail={{
                    image: course?.welcomeEmailImageRaw,
                    text: course?.welcomeEmailText,
                    downloads: course?.welcomeEmailDownloads,
                    enabled: course?.enableWelcomeEmail,
                  }}
                  automatedEmailEnabled={course?.enableAutomatedWeeklyEmails}
                  onboardingEmailEnabled={course?.enablePreStartEmail}
                  // Specify which downloads are configurable on emails
                  downloadOptions={{
                    welcomePackUrl: 'Welcome Pack',
                    healthSafetyUrl: 'Health & Safety Booklet',
                    equipmentDownloadUrl: 'Equipment Download',
                  }}
                  // Build a map of unit ID-title combinations for normal units
                  unitOptions={normalUnits.reduce((acc, unit) => {
                    acc[unit.id] = unit.title;
                    return acc;
                  }, {} as { [key: string]: string })}
                  handleSendEmailSubmit={handleSendTestEmail}
                  handleSubmit={handleEmailConfigurationSave}
                  isLoading={courseUI.loading}
                  // TODO:
                  // @ts-ignore
                  isUpdating={isUpdating.emailConfiguration}
                  isDisabled={isEditingDisabled}
                  showAdvancedFields={Boolean(showAdvancedFields)}
                />
              </Flex>
            </Flex>
          ) : currentView === 'todo' ? (
            <Flex flexDirection="column">
              <Card padding={4} flexDirection="column">
                <Text mb={3} color="text.muted">
                  Here's a list of what you need to do before you can publish
                  your course:
                </Text>
                <CheckList
                  items={toDoList.sort(
                    (a, b) => Number(a.isChecked) - Number(b.isChecked)
                  )}
                  checkable={false}
                />
              </Card>
            </Flex>
          ) : null}
        </Flex>
        {/* TODO: Replace this with modal beneath */}
        {course?.video && (
          <ModalVideo
            video={course.videoHls || course.video}
            videoHq={course.video720 || course.video}
            isOpen={isVideoOpen}
            onClose={onVideoClose}
          />
        )}
        <ModalVideo
          isOpen={modalVideo !== null}
          onClose={() => setModalVideo(null)}
          {...(modalVideo
            ? {
                summary: modalVideo.summary,
                video: modalVideo.video,
                videoHq: modalVideo.video,
                onSaveSummary: modalVideo.onSaveSummary,
                onUpload: modalVideo.onUpload,
                onSaveClip: modalVideo.onSaveClip,
                orientation: modalVideo.orientation,
                mediaType: modalVideo.mediaType,
                clipId: modalVideo.clipId,
                unitId: modalVideo.unitId,
                qrBlob: modalVideo.qrBlob,
              }
            : {})}
          isEditable={true}
        />
      </ScreenWrapper>
      {isEditingDisabled && (
        <FixedFooter
          text={
            hasEditPermissions
              ? 'This course is locked for editing.'
              : 'You do not have the required permissions to make changes'
          }
        />
      )}
      {PLATFORM === 'steppit' && (
        <Tour
          showSkipButton
          hideBackButton
          scrollOffset={100}
          analyticsName="Course Builder"
          steps={
            tourParam === 'new'
              ? [
                  {
                    id: courseTourIds.courseBuilder,
                    content:
                      'This is your course builder. With courses, you can teach a wide set of skills with a series of step-by-step sessions.',
                    placement: 'center',
                    spotlightClicks: false,
                  },
                  {
                    id: courseTourIds.courseAddUnit,
                    content:
                      'You can build your course into a series of units covering different topics or projects. Go ahead and add your first unit to get started. (This can be changed later!)',
                    nextCondition:
                      normalUnits.length > 0 && !isUpdating.addUnit,
                    nextInstruction: 'Add a unit to continue',
                  },
                  {
                    id: courseTourIds.courseAddSession,
                    content:
                      'Great! You can add sessions to this unit to teach what it covers step-by-step.',
                    nextCondition:
                      normalUnits.length > 0 &&
                      normalUnits[0].modules.length > 0,
                    nextInstruction: 'Add a session to continue',
                  },
                  {
                    id: courseTourIds.courseSummary,
                    content:
                      'Looking good! You can open up this session to start teaching or you can put your course structure together by adding all of your units and sessions first.',
                    placement: 'center',
                    spotlightClicks: false,
                  },
                  {
                    id: courseTourIds.coursePublish,
                    content:
                      "When you're ready to share your course with the world, hit publish for takeoff! 🚀",
                  },
                ]
              : tourParam === 'generated'
              ? [
                  {
                    id: courseTourIds.generatedCourse,
                    content:
                      'Welcome to your course builder. With courses, you can teach a wide set of skills with a series of step-by-step sessions.',
                    placement: 'center',
                    spotlightClicks: false,
                  },
                  {
                    id: courseTourIds.generatedCourseUnits,
                    content:
                      'Your course is made up of units, which let you focus on one topic at a time. Your classes will unlock units either weekly or all at once.',
                  },
                  {
                    id: courseTourIds.generatedCourseSessions,
                    content:
                      'Each unit contains sessions, which break your content down in a way that is engaging and easy to digest.',
                  },
                  {
                    id: courseTourIds.generatedCourseSummary,
                    content:
                      "Take some time to explore your generated course and when you're ready, dive into a session to start teaching step-by-step.",
                    placement: 'center',
                    spotlightClicks: false,
                  },
                  {
                    id: courseTourIds.generatedCoursePublish,
                    content:
                      "When you're done putting your course together and ready to share it with the world, hit publish for takeoff! 🚀",
                  },
                ]
              : []
          }
        />
      )}
    </>
  );
};

const mapStateToProps = (state: GlobalState, ownProps: OwnProps) => {
  const { courseId } = ownProps.match.params;
  const {
    organisation: { currentTeam },
  } = state;

  const course = state.cms.course.course[courseId];
  const units = course
    ? getUnitsForCourse(state.cms.unit.unitList, course.id)
    : {};
  const sessionList = state.cms.session.sessionList;
  const sessionDetails = state.cms.session.session;
  const videoClipList = state.cms.videoClip.videoClipList;

  return {
    course,
    units,
    sessionList,
    sessionDetails,
    videoClipList,
    currentTeam,
    currentTeamProfile: currentTeam
      ? state.organisation.teamProfiles[currentTeam]
      : undefined,
    categoryOptions: getCategoryOptions(state),
    courseUI: state.ui.course.course,
    unitUI: state.ui.unit.unitList,
  };
};

const connector = connect(mapStateToProps);

export default connector(CourseEditScreen);
