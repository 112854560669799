import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaTiktok,
  FaTwitch,
  FaDiscord,
  FaPatreon,
  FaSlack,
  FaGoogle,
  FaMicrosoft,
  FaMailchimp,
  FaShopify,
  FaSnapchatGhost,
  FaTrello,
  FaHubspot,
  FaBuffer,
} from 'react-icons/fa';
import { MdIcon } from '@workshop/ui';

import { MessageProps } from 'screens/common/Plan/src/Message';

const SESSION_PLAN_TEMPLATE = {
  title: '',
  type: 'object',
  properties: {
    // sessionTitle: { type: 'string', title: '' },
    // sessionDescription: {
    //   type: 'string',
    //   title: '',
    // },
    sessionIntro: {
      type: 'string',
      title: 'Introduction',
    },
    steps: {
      type: 'array',
      title: 'Steps',
      items: {
        type: 'object',
        properties: {
          stepTitle: {
            type: 'string',
            title: '',
          },
          bits: {
            type: 'array',
            title: 'Bits',
            items: {
              type: 'object',
              properties: {
                bitSummary: {
                  type: 'string',
                  title: '',
                },
              },
            },
          },
        },
      },
    },
    sessionOutro: {
      type: 'string',
      title: 'Congratulations',
    },
  },
};

export const ASSISTANT_TRAITS = [
  {
    slug: 'skillset',
    label: 'Skillset',
    labelShort: 'Skillset',
    colorScheme: 'blue',
    icon: 'Psychology',
    trainingTitle: 'What’s your skillset?',
    trainingDescription:
      'Train your assistant in what you know and don’t know, and it’ll offer up content ideas suited to your skillset.',
    trainingSteps: [
      {
        index: 0,
        slug: 'strongSkills',
        type: 'textList',
        intro: 'What are your strongest skills in your area of expertise?',
        helpText:
          'Add up to 10 skills or topics that you might use in your courses.',
        placeholder: 'Name a skill of yours',
        placeholder2: 'Name another skill of yours',
        nextTrainPrompt: 'train_skillset',
      },
      // {
      //   index: 1,
      //   slug: 'weakSkills',
      //   type: 'textList',
      //   intro:
      //     'Great, now list the skills you’re less familiar with in your area of expertise.',
      //   helpText:
      //     'Add up to 10 skills or topics that you might want to avoid in your courses.',
      //   placeholder: 'Name a skill that you want to avoid',
      //   placeholder2: 'Name another skill that you want to avoid',
      // },
      {
        index: 1,
        slug: 'furtherSkills',
        type: 'vote',
        voteLowWord: 'Weak',
        voteMidWord: 'Ok',
        voteHighWord: 'Strong',
        voteItemName: 'skill',
        voteDescriptor: 'level',
        intro:
          'Highlight your level of expertise in each of the following skills or topics.',
        helpText:
          'You can keep categorising your strengths and weaknesses to teach your assistant which areas you want it focus on.',
        trainPrompt: 'train_skillset',
        repeat: true,
      },
    ],
  },
  {
    slug: 'tone',
    label: 'Tone of Voice',
    labelShort: 'Voice',
    colorScheme: 'orange',
    icon: 'RecordVoiceOver',
    trainingTitle: 'What’s your tone of voice?',
    trainingDescription:
      'Train your assistant in your tone of voice and it’ll make sure to always write in your style.',
    trainingSteps: [
      {
        index: 0,
        slug: 'generalTone',
        type: 'grid',
        intro: 'How do you generally want your tone of voice to come across?',
        helpText: '',
        gridOptions: [
          {
            slug: 'friendly',
            name: 'Friendly',
            title: 'Friendly',
            emoji: '👋',
          },
          {
            slug: 'professional',
            name: 'Professional',
            title: 'Professional',
            emoji: '📊',
          },
          {
            slug: 'caring',
            name: 'Caring',
            title: 'Caring',
            emoji: '🤝',
          },
          {
            slug: 'wise',
            name: 'Wise',
            title: 'Wise',
            emoji: '🧠',
          },
          {
            slug: 'humorous',
            name: 'Humorous',
            title: 'Humorous',
            emoji: '🎉',
          },
          {
            slug: 'authoritative',
            name: 'Authoritative',
            title: 'Authoritative',
            emoji: '🧑‍🏫',
          },
          {
            slug: 'sincere',
            name: 'Sincere',
            title: 'Sincere',
            emoji: '🙏',
          },
          {
            slug: 'expressive',
            name: 'Expressive',
            title: 'Expressive',
            emoji: '🎭',
          },
          {
            slug: 'joyful',
            name: 'Joyful',
            title: 'Joyful',
            emoji: '😃',
          },
          {
            slug: 'bold',
            name: 'Bold',
            title: 'Bold',
            emoji: '🚀',
          },
          {
            slug: 'relaxed',
            name: 'Relaxed',
            title: 'Relaxed',
            emoji: '🌴',
          },
          {
            slug: 'motivational',
            name: 'Motivational',
            title: 'Motivational',
            emoji: '💫',
          },
        ],
      },
      {
        index: 1,
        slug: 'example',
        type: 'textBox',
        intro:
          'To help your assistant understand your tone of voice, you can give it a short example of how you typically write content.',
        helpText:
          'If you have a brief example of something you’ve written to hand, paste it here to give your assistant a head start in understanding your style.',
        placeholder: '(Optional) Paste something you’ve written here…',
        canSkip: true,
        nextTrainPrompt: 'train_tone',
        nextComparison: {
          index: 1,
          left: {
            slug: 'serious',
            description:
              'never attempt to make a joke, insert humour, or approach the topic in a funny way',
          },
          right: {
            slug: 'humour',
            description:
              'try to communicate the message with deadpan humour and lightheartedness whilst still showing competence in the subject',
          },
        },
      },
      {
        index: 2,
        slug: 'preferences',
        type: 'comparison',
        comparisonWord: 'better',
        intro:
          'Great! Now let’s build up your assistant’s understanding by selecting the sentences that are closer to your style.',
        helpText:
          'Keep picking your preferences to hone your assistant’s tone of voice.',
        trainPrompt: 'train_tone',
        canSkip: true,
        repeat: true,
        comparisons: [
          {
            index: 1,
            left: {
              slug: 'serious',
              description:
                'never attempt to make a joke, insert humour, or approach the topic in a funny way',
            },
            right: {
              slug: 'humour',
              description:
                'try to communicate the message with deadpan humour and lightheartedness whilst still showing competence in the subject',
            },
          },
          {
            index: 2,
            left: {
              slug: 'formal',
              description: 'use a formal and professional tone of voice',
            },
            right: {
              slug: 'casual',
              description:
                'try to use words that are more conversational and simplify the message while still sounding professional',
            },
          },
          {
            index: 3,
            left: {
              slug: 'respectful',
              description: 'convey respect for the subject matter',
            },
            right: {
              slug: 'irreverent',
              description:
                "approach the subject matter in a nonchalant way that shows the writer doesn't take himself too seriously without sounding disrespectful",
            },
          },
          {
            index: 4,
            left: {
              slug: 'enthusiastic',
              description: 'convey enthusiasm and passion for the subject',
            },
            right: {
              slug: 'matter-of-fact',
              description:
                'show no emotion and use words that are more dry and matter-of-fact',
            },
          },
          {
            index: 5,
            left: {
              slug: 'figurative',
              description:
                'use either a simile or a metaphor that is widely used in the subject matter to convey the message',
            },
            right: {
              slug: 'literal',
              description:
                'convey the same message without using any similes or metaphors',
            },
          },
          {
            index: 6,
            left: { slug: 'singular', description: 'singular 1st person' },
            right: { slug: 'plural', description: 'plural 1st person' },
          },
          // {
          //   index: 5,
          //   left: { slug: 'flowing', description: 'flowing sentences' },
          //   right: {
          //     slug: 'snappy',
          //     description: 'short, snappy sentences',
          //   },
          // },
        ],
      },
    ],
  },
  {
    slug: 'audience',
    label: 'Target Audience',
    labelShort: 'Audience',
    colorScheme: 'green',
    icon: 'Groups',
    trainingTitle: 'Who is your target audience?',
    trainingDescription:
      'Train your assistant to understand your target audience so that it knows exactly who you’re talking to.',
    trainingSteps: [
      {
        index: 0,
        type: 'grid',
        intro: 'Who are your courses made for?',
        slug: 'audienceType',
        helpText: '',
        gridOptions: [
          {
            slug: 'individuals',
            name: 'Individuals',
            title: 'Individuals',
            description: 'For personal learning',
            emoji: '🏡',
          },
          {
            slug: 'organisations',
            name: 'Organisations',
            title: 'Organisations',
            description: 'For companies & institutions',
            emoji: '🏫',
          },
          {
            slug: 'both',
            name: 'Both',
            title: 'Both Individuals & Organisations',
            description: 'For everyone',
            emoji: '🏘️',
          },
        ],
      },
      {
        index: 1,
        type: 'grid',
        intro: 'What skill level will your audience usually have?',
        helpText: '',
        slug: 'audienceSkillLevel',
        gridOptions: [
          {
            slug: 'beginner',
            name: 'Complete beginner',
            title: 'Complete beginner',
            emoji: '😌',
          },
          {
            slug: 'intermediate',
            name: 'Moderate experience',
            title: 'Moderate experience',
            emoji: '😏',
          },
          {
            slug: 'advanced',
            name: 'Highly skilled',
            title: 'Highly skilled',
            emoji: '🤓',
          },
          {
            slug: 'mix',
            name: 'Mixed skill levels',
            title: 'Mixed skill levels',
            emoji: '🧐',
          },
        ],
      },
      {
        index: 2,
        type: 'grid',
        intro: 'What is your current reach?',
        helpText:
          'For a rough estimate, you can combine your follower counts on all social platforms.',
        slug: 'currentFollowerCount',
        gridOptions: [
          {
            slug: 'none',
            name: 'None',
            title: 'None',
            description: "I don't have an audience",
            emoji: '',
          },
          {
            slug: 'under1k',
            name: 'Small but engaged',
            title: 'Up to 1k people',
            description: '(under 1k)',
            emoji: '🌱',
          },
          {
            slug: '1kto10k',
            name: 'Growing steadily',
            title: 'Between 1k and 10k people',
            description: '(1k to 10k)',
            emoji: '🌿',
          },
          {
            slug: '10kto100k',
            name: 'Sizeable and active',
            title: 'Between 10k and 100k people',
            description: '(10k to 100k)',
            emoji: '🌴',
          },
          {
            slug: '100kto1m',
            name: 'Large and diverse',
            title: 'Between 100k and 1m people',
            description: '(100k to 1m)',
            emoji: '🌳',
          },
          {
            slug: '1mplus',
            name: 'Established and thriving',
            title: 'Over 1m people',
            description: '(1m+)',
            emoji: '🌲',
          },
        ],
      },
      {
        index: 3,
        type: 'grid',
        intro: 'What is your audience looking to achieve with your courses?',
        helpText: 'Select the option that sounds most relevant to you.',
        slug: 'audienceGoal',
        gridOptions: [
          {
            slug: 'startCareer',
            name: 'Enter a career',
            title: 'Enter a career',
            emoji: '🧑‍💼',
          },
          {
            slug: 'practicalSkills',
            name: 'Improve practical skills',
            title: 'Improve practical skills',
            emoji: '🧑‍🔧',
          },
          {
            slug: 'hobby',
            name: 'Engage with their hobby',
            title: 'Engage with their hobby',
            emoji: '🧑‍🎨',
          },
          {
            slug: 'workSkills',
            name: 'Gain further skills for work',
            title: 'Gain further skills for work',
            emoji: '🧑‍🏭',
          },
          {
            slug: 'expandEducation',
            name: 'Expand their education',
            title: 'Expand their education',
            emoji: '🧑‍🎓',
          },
          {
            slug: 'buildIncome',
            name: 'Build a source of income',
            title: 'Build a source of income',
            emoji: '🤵',
          },
          {
            slug: 'heathWellbeing',
            name: 'Improve their wellbeing',
            title: 'Improve their wellbeing',
            emoji: '🧘',
          },
          {
            slug: 'other',
            name: 'Other',
            title: '',
            emoji: '🧑‍💻',
          },
        ],
      },
      {
        index: 4,
        type: 'textBox',
        intro:
          'What are the specific challenges your audience might be struggling with, and how can your courses help?',
        helpText:
          'Help your assistant understand how your courses will help your audience overcome their problems.',
        placeholder: 'List a few challenges…',
        slug: 'audienceChallenges',
      },
      // {
      //   index: 5,
      //   type: 'vote',
      //   voteLowWord: 'No',
      //   voteMidWord: 'Ok',
      //   voteHighWord: 'Yes',
      //   intro: 'Will any of these challenges apply to your audience?',
      //   helpText:
      //     'By highlighting relevant challenges, you’re teaching your assistant how to talk to your audience.',
      //   trainPrompt: 'train_audience',
      //   repeat: true,
      // },
    ],
  },
  {
    slug: 'values',
    label: 'Values',
    labelShort: 'Values',
    colorScheme: 'red',
    icon: 'Favorite',
    trainingTitle: 'What are your values?',
    trainingDescription:
      'Train your assistant in your motivations, goals and values to make sure you’re always aligned.',
    trainingSteps: [
      {
        index: 0,
        type: 'grid',
        intro:
          'What’s the main goal you’re trying to achieve for your audience?',
        helpText: '',
        slug: 'myPrimaryGoal',
        gridOptions: [
          {
            slug: 'empower',
            name: 'Empower',
            title: 'Empower',
            description:
              'Educate and empower people to understand themselves better and achieve their full potential.',
            emoji: '💪',
          },
          {
            slug: 'inspire',
            name: 'Inspire',
            title: 'Inspire',
            description:
              'Encourage people to shift perspective and inspire them to challenge the status quo.',
            emoji: '💡',
          },
          {
            slug: 'guide',
            name: 'Guide',
            title: 'Guide',
            description:
              'Give people peace of mind by sharing the exact expertise needed for them to achieve a goal.',
            emoji: '🧭',
          },
          {
            slug: 'connect',
            name: 'Connect',
            title: 'Connect',
            description:
              'Connect with people on a deeper level and share personal experiences that help them feel less alone.',
            emoji: '🤝',
          },
          {
            slug: 'entertain',
            name: 'Entertain',
            title: 'Entertain',
            description:
              'Provide people with fun and positive experiences that will uplift their lives.',
            emoji: '🤩',
          },
        ],
      },
      {
        index: 1,
        type: 'grid',
        intro: 'Which of these is your top priority?',
        helpText: '',
        slug: 'myPrimaryCharacteristic',
        gridOptions: [
          {
            slug: 'curiosity',
            name: 'Curiosity',
            title: 'Curiosity',
            description:
              "Sparking people's interest and making them want to learn more.",
            emoji: '🤔',
          },
          {
            slug: 'expertise',
            name: 'Expertise',
            title: 'Expertise',
            description:
              'Demonstrating deep knowledge and skill in your chosen field.',
            emoji: '🧠',
          },
          {
            slug: 'motivation',
            name: 'Motivation',
            title: 'Motivation',
            description:
              'Inspiring people and helping them stay eager to achieve goals.',
            emoji: '🔥',
          },
          {
            slug: 'transformation',
            name: 'Transformation',
            title: 'Transformation',
            description:
              'Guiding personal growth and facilitating positive changes in learners.',
            emoji: '🌱',
          },
          {
            slug: 'accessibility',
            name: 'Accessibility',
            title: 'Accessibility',
            description:
              'Making your content easy for everyone to use and understand.',
            emoji: '🌐',
          },
          {
            slug: 'connection',
            name: 'Connection',
            title: 'Connection',
            description:
              'Building strong relationships and bonds with your audience.',
            emoji: '💬',
          },
          {
            slug: 'support',
            name: 'Support',
            title: 'Support',
            description:
              'Providing help and care to assist learners along the way.',
            emoji: '🤲',
          },
          {
            slug: 'engagement',
            name: 'Engagement',
            title: 'Engagement',
            description:
              'Keeping people interested and actively involving them.',
            emoji: '🎈',
          },
          {
            slug: 'innovation',
            name: 'Innovation',
            title: 'Innovation',
            description:
              'Bringing fresh and creative ideas into your content and teaching.',
            emoji: '🚀',
          },
        ],
      },
      {
        index: 2,
        type: 'grid',
        intro: 'Which of these would be your secondary priority?',
        helpText: '',
        slug: 'mySecondaryCharacteristic',
        gridOptions: [
          {
            slug: 'curiosity',
            name: 'Curiosity',
            title: 'Curiosity',
            description:
              "Sparking people's interest and making them want to learn more.",
            emoji: '🤔',
          },
          {
            slug: 'expertise',
            name: 'Expertise',
            title: 'Expertise',
            description:
              'Demonstrating deep knowledge and skill in your chosen field.',
            emoji: '🧠',
          },
          {
            slug: 'motivation',
            name: 'Motivation',
            title: 'Motivation',
            description:
              'Inspiring people and helping them stay eager to achieve goals.',
            emoji: '🔥',
          },
          {
            slug: 'transformation',
            name: 'Transformation',
            title: 'Transformation',
            description:
              'Guiding personal growth and facilitating positive changes in learners.',
            emoji: '🌱',
          },
          {
            slug: 'accessibility',
            name: 'Accessibility',
            title: 'Accessibility',
            description:
              'Making your content easy for everyone to use and understand.',
            emoji: '🌐',
          },
          {
            slug: 'connection',
            name: 'Connection',
            title: 'Connection',
            description:
              'Building strong relationships and bonds with your audience.',
            emoji: '💬',
          },
          {
            slug: 'support',
            name: 'Support',
            title: 'Support',
            description:
              'Providing help and care to assist learners along the way.',
            emoji: '🤲',
          },
          {
            slug: 'engagement',
            name: 'Engagement',
            title: 'Engagement',
            description:
              'Keeping people interested and actively involving them.',
            emoji: '🎈',
          },
          {
            slug: 'innovation',
            name: 'Innovation',
            title: 'Innovation',
            description:
              'Bringing fresh and creative ideas into your content and teaching.',
            emoji: '🚀',
          },
        ],
      },
      {
        index: 3,
        type: 'grid',
        intro: 'What is your preferred teaching style?',
        helpText: '',
        slug: 'preferredTeachingStyle',
        gridOptions: [
          {
            slug: 'lecture',
            name: 'Lecture',
            title: 'Lecture',
            description: 'Talking through topics.',
            emoji: '🗣️',
          },
          {
            slug: 'collaboration',
            name: 'Collaboration',
            title: 'Collaboration',
            description: 'Discussing and sharing ideas.',
            emoji: '💭',
          },
          {
            slug: 'handsOn',
            name: 'Hands-On',
            title: 'Hands-On',
            description: 'Guiding through activities.',
            emoji: '🙌',
          },
          {
            slug: 'problemBased',
            name: 'Problem-Based',
            title: 'Problem-Based',
            description: 'Solving real-world challenges.',
            emoji: '🧩',
          },
        ],
      },
    ],
  },
  {
    slug: 'context',
    label: 'Wider Context',
    labelShort: 'Context',
    colorScheme: 'purple',
    icon: 'Extension',
    trainingTitle: 'Where does Steppit fit for you?',
    trainingDescription:
      'Train your assistant to understand where Steppit fits within your world for tailored help and integration advice.',
    trainingSteps: [
      {
        index: 0,
        type: 'grid',
        intro: 'Is this your first time making an online course?',
        slug: 'onlineCourseExperience',
        gridOptions: [
          {
            slug: 'firstTime',
            name: 'Yes, this is my first time',
            title: 'This is my first time making an online course',
            emoji: '😊',
          },
          {
            slug: 'hasExperience',
            name: 'No, I’ve made online courses before',
            title: 'I’ve made online courses before',
            emoji: '😎',
          },
        ],
      },
      {
        index: 1,
        type: 'grid',
        intro: 'Have you ever used any of the following marketing tactics?',
        helpText: 'Select all that apply.',
        slug: 'marketingTacticsUsed',
        multiple: true,
        gridOptions: [
          {
            slug: 'seo',
            name: 'SEO',
            title: 'SEO',
            iconElement: <MdIcon name="Search" />,
          },
          {
            slug: 'blogs',
            name: 'Blogs',
            title: 'Blogs',
            iconElement: <MdIcon name="RssFeed" />,
          },
          {
            slug: 'ads',
            name: 'Paid Advertising',
            title: 'Paid Advertising',
            iconElement: <MdIcon name="AdsClick" />,
          },
          {
            slug: 'social',
            name: 'Social Media',
            title: 'Social Media',
            iconElement: <MdIcon name="Share" />,
          },
          {
            slug: 'forums',
            name: 'Forums',
            title: 'Forums',
            iconElement: <MdIcon name="Forum" />,
          },
          {
            slug: 'email',
            name: 'Email Lists',
            title: 'Email Lists',
            iconElement: <MdIcon name="Email" />,
          },
          {
            slug: 'website',
            name: 'Own Website',
            title: 'Own Website',
            iconElement: <MdIcon name="Language" />,
          },
          {
            slug: 'noneMarketing',
            name: 'None of These',
            title: 'None',
            iconElement: <MdIcon name="HighlightOff" />,
          },
        ],
      },
      {
        index: 2,
        type: 'grid',
        intro: 'Where do you currently have access to an audience?',
        helpText: 'Select all that apply.',
        slug: 'accessToAudience',
        multiple: true,
        gridOptions: [
          {
            slug: 'facebook',
            name: 'Facebook',
            title: 'Facebook',
            iconElement: <FaFacebook color="#4267B2" />,
          },
          {
            slug: 'instagram',
            name: 'Instagram',
            title: 'Instagram',
            iconElement: <FaInstagram color="#962fbf" />,
          },
          {
            slug: 'youtube',
            name: 'YouTube',
            title: 'YouTube',
            iconElement: <FaYoutube color="#FF0000" />,
          },
          {
            slug: 'twitter',
            name: 'X / Twitter',
            title: 'X (aka Twitter)',
            // iconElement: <FaTwitter color="#00acee" />,
            iconElement: (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 512 512"
              >
                <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
              </svg>
            ),
          },
          {
            slug: 'tiktok',
            name: 'TikTok',
            title: 'TikTok',
            iconElement: <FaTiktok color="#69C9D0" />,
          },
          {
            slug: 'discord',
            name: 'Discord',
            title: 'Discord',
            iconElement: <FaDiscord color="#5865F2" />,
          },
          {
            slug: 'twitch',
            name: 'Twitch',
            title: 'Twitch',
            iconElement: <FaTwitch color="#6441a5" />,
          },
          // {
          //   slug: 'snapchat',
          //   name: 'Snapchat',
          //   title: 'Snapchat',
          //   iconElement: <FaSnapchatGhost color="#FFC60B" />,
          // },
          {
            slug: 'mailing',
            name: 'Mailing List',
            title: 'Mailing List',
            iconElement: <MdIcon name="MarkEmailUnread" color="#E71841" />,
          },
          {
            slug: 'nonePlatforms',
            name: 'None of These',
            title: 'None',
            iconElement: <MdIcon name="HighlightOff" />,
          },
        ],
      },
      {
        index: 3,
        type: 'grid',
        intro: 'Do you use any of the following tools?',
        helpText: 'Select all that apply.',
        slug: 'myTools',
        multiple: true,
        gridOptions: [
          {
            slug: 'slack',
            name: 'Slack',
            title: 'Slack',
            iconElement: <FaSlack color="#36C5F0" />,
          },
          {
            slug: 'googleWorkspace',
            name: 'Google Workspace',
            title: 'Google Workspace',
            iconElement: <FaGoogle color="#4285F4" />,
          },
          {
            slug: 'microsoft365',
            name: 'Microsoft 365',
            title: 'Microsoft 365',
            iconElement: <FaMicrosoft color="#737373" />,
          },
          {
            slug: 'patreon',
            name: 'Patreon',
            title: 'Patreon',
            iconElement: <FaPatreon color="#FF424D" />,
          },
          {
            slug: 'mailchimp',
            name: 'Mailchimp',
            title: 'Mailchimp',
            iconElement: <FaMailchimp />,
          },
          {
            slug: 'shopify',
            name: 'Shopify',
            title: 'Shopify',
            iconElement: <FaShopify color="#95BF47" />,
          },
          {
            slug: 'trello',
            name: 'Trello',
            title: 'Trello',
            iconElement: <FaTrello color="#007AC0" />,
          },
          {
            slug: 'hubspot',
            name: 'HubSpot',
            title: 'HubSpot',
            iconElement: <FaHubspot color="#FF7A59" />,
          },
          // {
          //   slug: 'buffer',
          //   name: 'Buffer',
          //   title: 'Buffer',
          //   iconElement: <FaBuffer />,
          // },
          {
            slug: 'noneTools',
            name: 'None of These',
            title: 'None',
            iconElement: <MdIcon name="HighlightOff" />,
          },
        ],
      },
      {
        index: 4,
        type: 'vote',
        voteLowWord: 'Low',
        voteMidWord: 'Ok',
        voteHighWord: 'High',
        voteItemName: 'activity',
        voteDescriptor: 'confidence',
        intro: 'How high is your confidence in each of these activities?',
        slug: 'myConfidenceLevels',
        voteOptions: [
          'Filming content',
          'Promoting content',
          'Teaching people',
        ],
      },
    ],
  },
];

export type AssistantTraitSlug =
  | 'tone'
  | 'skillset'
  | 'values'
  | 'audience'
  | 'context';

const ASSISTANT_TOOLS_LIST = {
  channelPage: {
    promptType: 'channel_intro',
    credits: 10,
    section: 'marketing',
    label: 'Channel Writer',
    emoji: '📣',
    description:
      'Introduce learners to your channel with detailed copy written in your tone of voice and tailored to your target market.',
    traitTone: true,
    traitSkillset: true,
    traitValues: true,
    traitAudience: true,
    traitContext: false,
    disabled: false,
    info: 'To use this tool, hit "Generate Introduction" in the text box at the top of your channel.',
    messages: [
      {
        message: `Hey there! I'm here to help you draft the perfect introduction for your course channel. Let's dive right in, shall we?`,
        fields: [],
      },
      {
        message: `Could you share a unique aspect or feature of your content that sets you apart from others?`,
        fields: [
          {
            name: 'uniqueFeature',
            type: 'multiline',
            intro: 'What sets my content apart is...',
            placeholders: [
              'Interactive sessions',
              'Real-world applications',
              'One-on-one mentorship',
            ],
          },
        ],
      },
      {
        message: `Nice! Now, could you tell me about an accomplishment or experience you have that adds credibility to your content?`,
        fields: [
          {
            name: 'credibilityMarker',
            type: 'multiline',
            intro:
              'One accomplishment that adds credibility to my content is...',
            placeholders: [
              '5 years in the industry',
              'Professionally qualified',
              'Highly experienced',
              'Certified trainer',
            ],
          },
        ],
      },
    ] as MessageProps[],
    prefilled: [],
    responseTemplate: {
      title: '',
      type: 'object',
      properties: {
        channelIntro: {
          type: 'string',
          title: "How's this for an intro?",
        },
      },
    },
    loadingText: 'Let me work my magic on this intro...',
  },
  marketInsights: {
    promptType: '',
    credits: 0,
    section: 'marketing',
    label: 'Market Insights',
    emoji: '🧐',
    description:
      'To help you understand where your courses fit in the market and where the opportunities are, your assistant can roam the internet and sum up its findings in your weekly digest.',
    traitTone: false,
    traitSkillset: true,
    traitValues: true,
    traitAudience: true,
    traitContext: true,
    disabled: true,
    messages: [] as MessageProps[],
    prefilled: [],
    responseTemplate: {},
  },
  socialPosts: {
    promptType: '',
    credits: 0,
    section: 'marketing',
    label: 'Social Post Creator',
    emoji: '🤳',
    description:
      'By combining media from your content with relevant, thought-provoking descriptions, your assistant can mock up posts for your social profiles in no time.',
    traitTone: true,
    traitSkillset: true,
    traitValues: true,
    traitAudience: true,
    traitContext: true,
    disabled: true,
    messages: [] as MessageProps[],
    prefilled: [],
    responseTemplate: {},
  },
  performanceAnalysis: {
    promptType: '',
    credits: 0,
    section: 'marketing',
    label: 'Performance Analyst',
    emoji: '📊',
    description:
      'Your assistant analyzes sales data to help you understand what’s working best and optimize your course promotion for maximum impact.',
    traitTone: false,
    traitSkillset: false,
    traitValues: true,
    traitAudience: true,
    traitContext: true,
    disabled: true,
    messages: [] as MessageProps[],
    prefilled: [],
    responseTemplate: {},
  },
  // blogs: {
  //   section: 'marketing',  //   label: 'Blog Writer',
  //   emoji: '🧑‍💻',
  //   description:
  //     'X Extract elements of your course into engaging posts for your own website or blog, optimized for search engines and perfect for introducing courses to your audience.',
  //   traitTone: true,
  //   traitSkillset: true,
  //   traitValues: true,
  //   traitAudience: true,
  //   traitContext: true,
  //   disabled: true,
  // },
  // ads: {
  //   section: 'marketing',  //   label: 'Ad Maker',
  //   emoji: '🪧',
  //   description:
  //     "X If you're running ad campaigns for your own website, your assistant can generate beautiful assets and engaging headlines in an instant.",
  //   traitTone: true,
  //   traitSkillset: true,
  //   traitValues: true,
  //   traitAudience: true,
  //   traitContext: true,
  //   disabled: true,
  // },
  basicCourses: {
    promptType: 'basic_course_plan',
    credits: 0,
    section: 'creating',
    label: 'Basic Course Planner',
    emoji: '📋',
    description:
      'Get a free taste of what your assistant can do without any training, and answer a few quick questions to create a 1-4 week course plan in minutes.',
    traitTone: false,
    traitSkillset: false,
    traitValues: false,
    traitAudience: false,
    traitContext: false,
    disabled: false,
    free: true,
    messages: [
      {
        message: `Hi! I'm your AI assistant, here to help you plan and create your online course. I'll ask you some questions and draft a plan, suggest a price and estimate your potential earnings. Ready to get started?`,
        fields: [],
      },
      {
        message: `Great! Let's start with your course idea. What is your course about?`,
        fields: [
          {
            name: 'subjectMatter',
            type: 'multiline',
            intro: 'My course is about...',
            // placeholders: ['Cooking fish', 'Painting landscapes'],
          },
        ],
      },
      {
        message: `Now think about your audience. Who would benefit most from your online course?`,
        fields: [
          {
            name: 'targetAudience',
            type: 'multiline',
            intro: 'My course is for...',
          },
        ],
      },
      {
        message: `What new skills or knowledge will your students have when they complete your course?`,
        fields: [
          {
            name: 'skillsLearnt',
            type: 'multiline',
            intro: 'After taking my course, students will be able to...',
          },
        ],
      },
      {
        message: `Almost done! Let's just fine-tune your preferences before generating your plan and predicting your income.`,
        fields: [
          {
            name: 'numUnits',
            type: 'select',
            intro: 'The length of my course is...',
            options: {
              '2': '2 weeks',
              '3': '3 weeks',
              '4': '4 weeks',
            },
            helpText: '',
          },
          {
            name: 'toneOfVoice',
            type: 'select',
            intro: 'The tone of voice for my course is...',
            options: { casual: 'Casual', formal: 'Formal' },
          },
          {
            name: 'audienceSize',
            type: 'select',
            intro: 'My current audience is...',
            options: { small: 'Small (under 1k)', big: 'Big (over 1k)' },
          },
        ],
      },
    ] as MessageProps[],
    prefilled: [],
    responseTemplate: {},
  },
  proCourses: {
    promptType: 'pro_course_plan',
    credits: 100,
    section: 'creating',
    label: 'Pro Course Planner',
    emoji: '✨',
    description:
      'Personalize your plans with your trained assistant, create longer courses and specify exactly how you want it to work.',
    traitTone: true,
    traitSkillset: true,
    traitValues: true,
    traitAudience: true,
    traitContext: true,
    disabled: false,
    info: `To use this tool, hit "New Course" and then "Generate a Pro Plan".`,
    messages: [
      {
        message: `Hello! I'm here to help you plan out a course to your exact specifications. I'll ask you a few questions and, with all my training in mind, try to come up with something that works for you. Ready to start?`,
        fields: [],
      },
      {
        message: `Great! Let's start by understanding your course. Can you tell me what this course is about?`,
        fields: [
          {
            name: 'courseSubject',
            type: 'multiline',
            intro: 'This course is about...',
            // placeholders: ['Computer Programming', 'Yoga for Beginners'],
          },
        ],
      },
      {
        message: `Who is your target audience for this course? I'll bear in mind my training for your audience, but you can highlight a specific target for this course here.`,
        fields: [
          {
            name: 'targetAudience',
            type: 'multiline',
            intro: 'My target audience for this course is...',
          },
        ],
      },
      {
        message: `What are the primary takeaways or skills your learners will gain by the end of this course?`,
        fields: [
          {
            name: 'courseOutcome',
            type: 'multiline',
            intro: 'After completing this course, learners will be able to...',
          },
        ],
      },
      {
        message: `Ok great, are there any specific topics you definitely want to include in this course?`,
        fields: [
          {
            name: 'topicsToInclude',
            type: 'multiline',
            intro: 'I would like to include...',
            canSkip: true,
          },
        ],
      },
      {
        message: `And are there any topics that you want to avoid on this course?`,
        fields: [
          {
            name: 'topicsToExclude',
            type: 'multiline',
            intro: 'I would not like to include...',
            canSkip: true,
          },
        ],
      },
      {
        message: `Will this course focus more on practical, hands-on experiences or on theoretical learning and personal reflection?`,
        fields: [
          {
            name: 'courseType',
            type: 'select',
            intro: 'I want my course to be...',
            options: {
              practical: 'Practical and hands-on',
              theoretical: 'Theoretical and reflective',
            },
            helpText: '',
          },
        ],
      },
      {
        message: `Are there any prerequisites needed before a learner can start this course?`,
        fields: [
          {
            name: 'prerequisites',
            type: 'multiline',
            intro: 'To take this course, learners should already...',
            canSkip: true,
          },
        ],
      },
      {
        message: `How long would you expect a learner to work on this course?`,
        fields: [
          {
            name: 'hoursPerWeek',
            type: 'select',
            intro: 'A learner should spend...',
            options: {
              UpTo1: 'Up to 1 hour per week',
              '1To3': '1 - 3 hours per week',
              '3To5': '3 - 5 hours per week',
              Over5: '5+ hours per week',
            },
            helpText: 'Select a suitable range',
          },
          {
            name: 'numUnits',
            type: 'select',
            intro: 'Over the course of...',
            options: {
              '2': '2 weeks',
              '3': '3 weeks',
              '4': '4 weeks',
              '5': '5 weeks',
              '6': '6 weeks',
              '7': '7 weeks',
              '8': '8 weeks',
              '9': '9 weeks',
              '10': '10 weeks',
              '11': '11 weeks',
              '12': '12 weeks',
            },
            helpText: '',
          },
        ],
      },
      {
        message: `Fantastic! I'll combine all of this with the training you've given me to generate your plan. Is there anything else you want me to consider before I get to work?`,
        fields: [
          {
            name: 'furtherConsiderations',
            type: 'multiline',
            intro: 'Consider this...',
            canSkip: true,
          },
        ],
      },
    ] as MessageProps[],
    prefilled: ['currency', 'pricingOptions'],
    responseTemplate: {},
  },
  courseTextBoxes: {
    promptType: '',
    credits: 10,
    section: 'creating',
    label: 'Content Helper',
    emoji: '🙋',
    description:
      'If you get stuck on a text box while writing something for your course, your assistant can jump in to fill in the gap and keep you moving forward.',
    traitTone: true,
    traitSkillset: true,
    traitValues: true,
    traitAudience: true,
    traitContext: false,
    disabled: false,
    info: `To use this tool, look out for the "Generate..." button whenever you're writing in a text box.`,
    messages: [] as MessageProps[],
    prefilled: ['courseId'],
  },
  sessions: {
    promptType: 'session_plan',
    credits: 50,
    section: 'creating',
    label: 'Session Planner',
    emoji: '🤸',
    description:
      'Outline what you want learners to cover in a session and let your assistant come up with a step-by-step flow that guides them through the activity or topic at hand.',
    traitTone: true,
    traitSkillset: true,
    traitValues: true,
    traitAudience: true,
    traitContext: false,
    disabled: false,
    messages: [
      {
        message:
          "Hi there! Ready to whip up a plan for your session? Let's dive in!",
        fields: [],
      },
      {
        message:
          "First, let's consider the type of activity for your session - is it a theoretical topic or a practical 'learn by doing' sort of task?",
        fields: [
          {
            name: 'activityType',
            type: 'select',
            intro: 'My session will involve...',
            options: {
              practicalActivity: 'A practical activity',
              theoreticalTopic: 'A theoretical topic',
            },
          },
        ],
      },
      // {
      //   message: 'What type of media do you want to use in this session?',
      //   fields: [
      //     {
      //       name: 'mediaType',
      //       type: 'select',
      //       intro: 'This session will mostly include...',
      //       options: {
      //         video: 'Videos',
      //         audio: 'Audio clips',
      //         image: 'Static images',
      //         text: 'Just text',
      //       },
      //     },
      //   ],
      // },
      {
        message: 'How long should this session ideally take?',
        fields: [
          {
            name: 'sessionDuration',
            type: 'select',
            intro: 'I anticipate the session to take...',
            options: {
              '30mins': 'Up to 30 mins',
              '1hour': '30 mins to an hour',
              '1hour30mins': '1-2 hours',
              '2hours': '2+ hours',
            },
          },
        ],
      },
      {
        message:
          'What should the learner ideally know or be able to do by the end of this session?',
        fields: [
          {
            name: 'learningOutcome',
            type: 'multiline',
            intro:
              'By the end of this session, the learner should be able to...',
            // placeholders: [
            //   'Solve basic algebraic equations',
            //   'Play a C Major Scale on the piano',
            // ],
          },
        ],
      },
      {
        message: 'What absolutely needs to be included in this session?',
        fields: [
          {
            name: 'sessionInclusions',
            type: 'multiline',
            intro: 'This session must include...',
            // placeholders: ['The Pythagorean theorem', 'Timing and tempo'],
          },
        ],
      },
      {
        message:
          'Is there anything that we should avoid including in this session?',
        fields: [
          {
            name: 'sessionExclusions',
            type: 'multiline',
            intro: 'This session should definitely avoid...',
            // placeholders: [
            //   'Complex theories of relativity',
            //   'Key transitions and modulation',
            // ],
            canSkip: true,
          },
        ],
      },
      // {
      //   message:
      //     "Do you want me to stick to exactly what you've told me or can I improvise?",
      //   fields: [
      //     {
      //       name: 'improvisation',
      //       type: 'select',
      //       intro: 'I want you to...',
      //       options: {
      //         noImprovisation: "Stick to exactly what I've said",
      //         someImprovisation: 'Improvise a tiny bit',
      //         freeImprovisation: 'Feel free to improvise',
      //       },
      //     },
      //   ],
      // },
      {
        message:
          'Finally, do you have any additional requests or anything else to add regarding this session?',
        fields: [
          {
            name: 'otherRequests',
            type: 'multiline',
            intro: 'Additionally...',
            canSkip: true,
          },
        ],
      },
    ] as MessageProps[],
    responseTemplate: SESSION_PLAN_TEMPLATE,
    prefilled: ['courseId', 'sessionId'],
    loadingText: 'One session coming up! This might take a minute or two...',
  },
  // sessionTextBoxes: {
  //   section: 'creating',  //   label: 'Session Helper',
  //   emoji: '🙋',
  //   description:
  //     'If you get stuck on a text box in one of your sessions, let your assistant give you a headstart by offering up a quick suggestion.',
  //   traitTone: true,
  //   traitSkillset: false,
  //   traitValues: true,
  //   traitAudience: true,
  //   traitContext: false,
  //   disabled: false,
  // },
  clipSummaries: {
    promptType: '',
    credits: 10,
    section: 'creating',
    label: 'Clip Summarizer',
    info: `To use this tool, upload a video or audio clip, hit "Edit" on your chosen bit and then click "Summarize Video (or Audio) Clip".`,
    emoji: '🤏',
    description:
      'Say whatever you like in your video and audio clips, and let your assistant instantly summarize your words into simple sentences which are easy for learners to follow along.',
    traitTone: true,
    traitSkillset: false,
    traitValues: true,
    traitAudience: true,
    traitContext: false,
    disabled: false,
    messages: [] as MessageProps[],
    prefilled: [],
    responseTemplate: {},
  },
  audits: {
    promptType: '',
    credits: 0,
    section: 'creating',
    label: 'Course Auditor',
    emoji: '🤓',
    description:
      'To help make sure your content is accurate and robust, your assistant will regularly review your content and give detailed feedback in your weekly digest.',
    traitTone: false,
    traitSkillset: true,
    traitValues: true,
    traitAudience: true,
    traitContext: true,
    disabled: true,
    messages: [] as MessageProps[],
    prefilled: [],
    responseTemplate: {},
  },
  scripts: {
    promptType: '',
    credits: 0,
    section: 'creating',
    label: 'Script Writer',
    emoji: '✍️',
    description:
      'Say goodbye to writer’s block as your assistant helps you flesh out your ideas into full scripts, all tailored to your unique tone of voice.',
    traitTone: true,
    traitSkillset: false,
    traitValues: true,
    traitAudience: true,
    traitContext: false,
    disabled: true,
    messages: [] as MessageProps[],
    prefilled: [],
    responseTemplate: {},
  },
  guide: {
    promptType: '',
    credits: 0,
    section: 'creating',
    label: 'Real-Time Guide',
    emoji: '🗺️',
    description:
      'Wondering what to do next? Your assistant regularly analyzes your progress and is always there to offer intelligent recommendations for your next step.',
    traitTone: false,
    traitSkillset: false,
    traitValues: true,
    traitAudience: true,
    traitContext: false,
    disabled: true,
    messages: [] as MessageProps[],
    prefilled: [],
    responseTemplate: {},
  },
  quizzes: {
    promptType: '',
    credits: 0,
    section: 'creating',
    label: 'Quiz Creator',
    emoji: '🤔',
    description:
      'Give your learners instant feedback during a session with multiple-choice quizzes, automatically generated by your assistant based on the content you have uploaded.',
    traitTone: false,
    traitSkillset: false,
    traitValues: true,
    traitAudience: true,
    traitContext: true,
    disabled: true,
    messages: [] as MessageProps[],
    prefilled: [],
    responseTemplate: {},
  },
  rewards: {
    promptType: '',
    credits: 0,
    section: 'teaching',
    label: 'Learner Rewarder',
    emoji: '🏆',
    description:
      'Your assistant creates professional and customized completion certificates for learners when they complete all requirements for your courses.',
    traitTone: false,
    traitSkillset: false,
    traitValues: false,
    traitAudience: true,
    traitContext: false,
    disabled: false,
    messages: [] as MessageProps[],
    prefilled: [],
    responseTemplate: {},
  },
  schedule: {
    promptType: '',
    credits: 0,
    section: 'teaching',
    label: 'Schedule Reminder',
    emoji: '📅',
    description:
      'Stay on top of class schedules with automated reminders. Your assistant automatically sends timely notifications to learners, ensuring they never miss an important session.',
    traitTone: false,
    traitSkillset: false,
    traitValues: false,
    traitAudience: true,
    traitContext: false,
    disabled: false,
    messages: [] as MessageProps[],
    prefilled: [],
    responseTemplate: {},
  },
  feedback: {
    promptType: '',
    credits: 0,
    section: 'teaching',
    label: 'Feedback Collector',
    emoji: '💭',
    description:
      'Your assistant can gather user feedback and analyze learner behaviours to give you valuable insights into what’s working well and where some may be struggling.',
    traitTone: true,
    traitSkillset: true,
    traitValues: true,
    traitAudience: true,
    traitContext: false,
    disabled: true,
    messages: [] as MessageProps[],
    prefilled: [],
    responseTemplate: {},
  },
  chat: {
    promptType: '',
    credits: 0,
    section: 'teaching',
    label: 'Chat Assistant',
    emoji: '💬',
    description:
      'Supplement your learner support with your assistant’s real-time assistance, able to answer your learners’ questions immediately or direct them to relevant resources.',
    traitTone: true,
    traitSkillset: true,
    traitValues: true,
    traitAudience: true,
    traitContext: false,
    disabled: true,
    messages: [] as MessageProps[],
    prefilled: [],
    responseTemplate: {},
  },
  conversation: {
    promptType: '',
    credits: 0,
    section: 'teaching',
    label: 'Conversation Starter',
    emoji: '🗣️',
    description:
      'Foster lively discussions within your classes. Your assistant can suggest discussion prompts and monitor interactions, enhancing engagement and promoting social learning.',
    traitTone: true,
    traitSkillset: true,
    traitValues: true,
    traitAudience: true,
    traitContext: false,
    disabled: true,
    messages: [] as MessageProps[],
    prefilled: [],
    responseTemplate: {},
  },
  liveSessions: {
    promptType: '',
    credits: 0,
    section: 'teaching',
    label: 'Live Session Manager',
    emoji: '🎥',
    description:
      'Seamlessly conduct live sessions with your assistant’s support. It handles technical aspects like learner access and progress monitoring, ensuring a smooth real-time class experience.',
    traitTone: false,
    traitSkillset: false,
    traitValues: true,
    traitAudience: true,
    traitContext: false,
    disabled: true,
    messages: [] as MessageProps[],
    prefilled: [],
    responseTemplate: {},
  },
};

export type AssistantToolSlug = keyof typeof ASSISTANT_TOOLS_LIST;

interface BaseAssistantTool {
  promptType: string;
  credits: number;
  section: string;
  label: string;
  emoji: string;
  description: string;
  traitTone: boolean;
  traitSkillset: boolean;
  traitValues: boolean;
  traitAudience: boolean;
  traitContext: boolean;
  disabled: boolean;
  info?: string;
  free?: boolean;
  messages: MessageProps[];
  prefilled: string[];
  responseTemplate: { [key: string]: any };
  loadingText?: string;
}

export interface AssistantTool extends BaseAssistantTool {
  slug: AssistantToolSlug;
}

// @ts-ignore
export const ASSISTANT_TOOLS: Record<AssistantToolSlug, AssistantTool> =
  Object.keys(ASSISTANT_TOOLS_LIST).reduce(
    // @ts-ignore
    (acc: BaseAssistantTool, k: AssistantToolSlug) => {
      const tool = ASSISTANT_TOOLS_LIST[k];
      return { ...acc, [k]: { ...tool, slug: k } };
    },
    {}
  );

export const ASSISTANT_TOOL_VARIANTS = {
  courseTextBoxes: {
    courseTitle: {
      ...ASSISTANT_TOOLS['courseTextBoxes'],
      promptType: 'course_title',
      messages: [
        {
          message: `Hi there! I'm here to help you come up with an attention-grabbing title for your online course. To get started, can you tell me about the main topic of your course?`,
          fields: [
            {
              name: 'courseTopic',
              type: 'multiline',
              intro: 'My course is mainly about...',
            },
          ],
        },
        {
          message: `Great! Now, could you tell me about the primary objective or the major skill that learners will acquire from your course?`,
          fields: [
            {
              name: 'courseObjective',
              type: 'multiline',
              intro: 'By completing this course, learners will be able to...',
            },
          ],
        },
      ] as MessageProps[],
      responseTemplate: {
        title: '',
        type: 'object',
        properties: {
          courseTitle: {
            type: 'string',
            title: 'How about this for a title?',
          },
        },
      },
      loadingText: 'Eye-catching title incoming...',
    },
    courseSubtitle: {
      ...ASSISTANT_TOOLS['courseTextBoxes'],
      promptType: 'course_subtitle',
      messages: [
        {
          message: `Good to see you! Let's create a compelling subtitle for your online course. What are the key areas or subtopics that your course will cover?`,
          fields: [
            {
              name: 'keyAreas',
              type: 'multiline',
              intro: 'My course will cover areas like...',
            },
          ],
        },
        {
          message: `Fantastic! Now share a unique selling point (USP) – what will a learner uniquely achieve or gain from this course compared to similar courses?`,
          fields: [
            {
              name: 'uniqueSellingPoint',
              type: 'multiline',
              intro: 'What sets my course apart is...',
            },
          ],
        },
      ] as MessageProps[],
      responseTemplate: {
        title: '',
        type: 'object',
        properties: {
          courseSubtitle: {
            type: 'string',
            title: "Ok, here's what I'm thinking for your subtitle...",
          },
        },
      },
      loadingText: 'Catchy subtitle on the way...',
    },
    courseDescription: {
      ...ASSISTANT_TOOLS['courseTextBoxes'],
      promptType: 'course_description',
      messages: [
        {
          message:
            "Hello! Let's begin creating a description for your course. Could you briefly explain the primary objective of your course?",
          fields: [
            {
              name: 'courseGoal',
              type: 'multiline',
              intro: 'The primary objective of my course is...',
            },
          ],
        },

        {
          message:
            'Great! Now, could you provide an outline of the various topics that will be covered in your course?',
          fields: [
            {
              name: 'courseTopics',
              type: 'multiline',
              intro: 'In my course, we will cover topics such as...',
            },
          ],
        },
      ] as MessageProps[],
      responseTemplate: {
        title: '',
        type: 'object',
        properties: {
          courseDescription: {
            type: 'string',
            title: "How's this for a description?",
          },
        },
      },
      loadingText: 'One sec, just working on your description...',
    },
  },
};

// Keys represent the number of steps completed in training for this trait,
// while values represent the % progress of training
export const ASSISTANT_TRAINING_PROGRESS = {
  0: 0,
  1: 20,
  2: 40,
  3: 50,
  4: 60,
  5: 70,
  6: 80,
  7: 90,
  8: 100,
};
