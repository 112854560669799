import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import navRoutes from 'navigation/Routes';

import isEmail from 'validator/lib/isEmail';

import { GlobalState } from 'types';

import styled, {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  Paragraph,
  Text,
  LinkButton,
} from '@workshop/ui';

import { authActions } from 'redux/actions/common';

import { LabelInput } from 'components/Common';

interface FormData {
  email: string;
  password1: string;
  password2: string;
}

const Form = styled.form`
  width: 100%;
`;

interface PasswordResetFormProps {
  keyId?: string;
  uidb36?: string;
  onVerifiedKey?: () => void;
  onPwResetSubmitted?: (submitted: boolean) => void;
  isAuthenticated?: boolean;
}

const PasswordResetForm: React.FC<PasswordResetFormProps> = ({
  keyId,
  uidb36,
  onVerifiedKey,
  onPwResetSubmitted,
  isAuthenticated,
}) => {
  const dispatch = useDispatch();
  const [pwResetSubmitted, setPWResetSubmitted] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const isLoading =
    useSelector(({ auth }: GlobalState) => auth.loading) || isSubmitting;

  useEffect(() => {
    if (!keyId || !uidb36 || keyId === 'set-password') {
      return;
    }

    // Make a GET request to the password reset URL in order to satisfy
    // django-allauth and 'mask' the password reset key from the browser
    // by storing it in the user's session
    dispatch(authActions.password.verify(keyId, uidb36));
    onVerifiedKey && onVerifiedKey();
  }, [keyId]);

  const { errors, setValue, getValues, handleSubmit, register, watch } =
    useForm<FormData>({
      defaultValues: {},
    });

  // Register the `email` field indirectly so that we can access the `email`
  // value after the `email` text input has been unmounted/removed from the DOM
  useEffect(() => {
    // If the password reset key or uid are present then don't register the
    // email field as it's not used
    if (keyId || uidb36) return;

    register('email', {
      required: true,
      validate: (value) => isEmail(value),
    });
  }, [keyId, uidb36, register]);

  useEffect(() => {
    onPwResetSubmitted && onPwResetSubmitted(pwResetSubmitted);
  }, [pwResetSubmitted]);

  const onSubmit = handleSubmit(async ({ email, password1, password2 }) => {
    setIsSubmitting(true);

    const data = keyId ? { password1, password2 } : { email };

    const response = await dispatch(
      authActions.password.reset({
        data,
        key: keyId ? 'set-password' : undefined,
        uidb36,
      })
    );

    setIsSubmitting(false);
    !response.error && setPWResetSubmitted(true);
  });

  return (
    <Flex flex={1} width="100%">
      <Form onSubmit={onSubmit}>
        <Flex flexDirection="column" flex={1}>
          {keyId ? (
            <Flex
              flexDirection="column"
              mb={pwResetSubmitted ? 0 : 'defaultMargin'}
              mt={2}
            >
              {pwResetSubmitted ? (
                <>
                  <Text mb="defaultMargin" textAlign="center">
                    Your password has successfully been reset 🙌
                  </Text>
                  <Flex justifyContent="center" mt={2}>
                    <LinkButton
                      to={
                        isAuthenticated
                          ? navRoutes.common.home.path()
                          : navRoutes.public.login.path()
                      }
                      icon="ArrowForward"
                      iconPosition="right"
                    >
                      {isAuthenticated ? 'Done' : 'Log In Now'}
                    </LinkButton>
                  </Flex>
                </>
              ) : (
                <>
                  <LabelInput
                    id="password1"
                    name="password1"
                    label="New Password"
                    labelPosition="top"
                    error={Boolean(errors.password1)}
                    errorMessage={errors.password1?.message}
                    registerInputRef={register({
                      required: {
                        value: true,
                        message: 'Please enter a password',
                      },
                      minLength: {
                        value: 6,
                        message: 'Your password must be at least 6 characters',
                      },
                    })}
                    inputType="password"
                  />
                  <LabelInput
                    id="password2"
                    name="password2"
                    label="Confirm Password"
                    labelPosition="top"
                    error={Boolean(errors.password2)}
                    errorMessage={
                      errors.password2?.message ||
                      'Your password does not match'
                    }
                    registerInputRef={register({
                      required: {
                        value: true,
                        message: 'Please enter your password again',
                      },
                      validate: (value) => value === watch('password1'),
                    })}
                    inputType="password"
                  />
                </>
              )}
            </Flex>
          ) : (
            <Flex flexDirection="column" mb="defaultMargin">
              {pwResetSubmitted ? (
                <>
                  <Paragraph textAlign="center">
                    {`An email has been sent to ${getValues('email')}.`}
                  </Paragraph>
                  <Paragraph textAlign="center">
                    Please check your inbox and follow the link in the email to
                    reset your password
                  </Paragraph>
                </>
              ) : (
                <>
                  <Paragraph mb="defaultMargin" textAlign="center">
                    Please enter your e-mail address in order to reset your
                    password.
                  </Paragraph>
                  <FormControl isInvalid={Boolean(errors.email)}>
                    <Input
                      id="email"
                      name="email"
                      type="email"
                      placeholder="Email"
                      onChange={(e) => setValue('email', e.target.value)}
                    />
                    <FormErrorMessage>
                      Please enter a valid email address
                    </FormErrorMessage>
                  </FormControl>
                </>
              )}
            </Flex>
          )}
          {pwResetSubmitted ? null : (
            <Button
              variant="solid"
              colorScheme="blue"
              type="submit"
              textTransform="initial"
              fontSize="md"
              isDisabled={isLoading}
              isLoading={isLoading}
            >
              Submit
            </Button>
          )}
        </Flex>
      </Form>
    </Flex>
  );
};

export default PasswordResetForm;
