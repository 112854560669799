import React from 'react';
import { useHistory } from 'react-router';
import moment from 'moment';

import navRoutes from 'navigation/Routes';

import { Box, Card, useColorModeValue } from '@workshop/ui';

import { hooks, colorUtils } from 'utils';

import { Cohort, Organisation } from 'types/common';

import { ScheduleTimeline } from 'components/ScheduleTimeline';

interface Props {
  org: Organisation;
  cohorts: Cohort[];
  link: 'course' | 'checkout';
  isLoading: boolean;
  noShadow?: boolean;
  openInNew?: boolean;
}

const ScheduleWidget = ({
  org,
  cohorts,
  link,
  isLoading,
  noShadow = false,
  openInNew = false,
}: Props) => {
  const history = useHistory();
  const baseColor = org?.id
    ? colorUtils.getRandomColor(org.id, undefined, undefined, 'hex')
    : '';
  const colors = hooks.useColors(org?.brandColor || baseColor);
  const palette = useColorModeValue(colors?.light, colors?.dark);
  const oppositePalette = useColorModeValue(colors?.dark, colors?.light);

  const surface = palette?.surface;
  const borderColor = palette?.surfaceVariant;
  const dayBg = palette?.surface;
  const weekendBg = palette?.primaryContainer;

  const colorCombinations = [
    {
      bgColor: palette?.primaryContainer,
      bgAccentColor: oppositePalette?.primary,
      textColor: oppositePalette?.onPrimary,
    },
    {
      bgColor: palette?.secondaryContainer,
      bgAccentColor: oppositePalette?.secondary,
      textColor: oppositePalette?.onSecondary,
    },
    {
      bgColor: palette?.tertiaryContainer,
      bgAccentColor: oppositePalette?.tertiary,
      textColor: oppositePalette?.onTertiary,
    },
    {
      bgColor: palette?.errorContainer,
      bgAccentColor: oppositePalette?.error,
      textColor: oppositePalette?.onError,
    },
    {
      bgColor: palette?.surfaceVariant,
      bgAccentColor: oppositePalette?.outline,
      textColor: palette?.onSurfaceVariant,
    },
  ];

  const groups = isLoading
    ? []
    : cohorts
        .sort(
          (a, b) =>
            moment(a.startDate).diff(moment(b.startDate)) ||
            a.courseDetails.title.localeCompare(b.courseDetails.title)
        )
        .map((c) => {
          return {
            id: c.id,
            image: c.courseDetails.imageLandscape,
            height: 70,
          };
        });

  // Create a list of courses to be displayed across the timeline
  const courseItems = isLoading
    ? []
    : cohorts.map((c) => {
        return {
          id: c.id,
          group: c.id,
          title: c.courseDetails.title,
          subtitles: [
            `${c.duration} unit${c.duration === 1 ? '' : 's'}`,
            ...(c.accessType === 'open' && c.socialType === 'community'
              ? ['Open access']
              : []),
          ],
          start: moment(c.startDate),
          end:
            c.accessType === 'open' && c.socialType === 'community'
              ? moment(1e15)
              : moment(c.startDate).add(c.duration, 'week'),
          onPopoverOpen: () => {
            const routeLink =
              link === 'course'
                ? navRoutes.global.courseLanding.path(c.courseDetails.slug)
                : `${navRoutes.global.checkout.path(
                    c.courseDetails.slug
                  )}?sd=${moment(c.startDate).format('YYYY[-]MM[-]DD')}`;
            if (openInNew) {
              window.open(routeLink, '_blank');
            } else {
              history.push(routeLink);
            }
          },
          ...colorCombinations[c.courseDetails.id % colorCombinations.length],
        };
      });

  // Combine all dated items into one for the timeline
  const items = [...courseItems];

  return (
    <Card
      position="relative"
      flex={1}
      height="300px"
      bg={surface}
      mx={{ base: -3, md: 0 }}
      {...(noShadow
        ? {
            boxShadow: 'none',
            borderWidth: 1,
            borderColor: weekendBg,
          }
        : {})}
    >
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        overflowY="scroll"
        sx={{
          '.react-calendar-timeline .rct-vertical-lines .rct-vl': {
            background: dayBg,
            opacity: 0.2,
            borderColor: borderColor,
          },
          '.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-6, .react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-0':
            {
              background: weekendBg,
            },
        }}
      >
        <ScheduleTimeline
          isLoading={isLoading}
          groups={groups}
          // @ts-ignore
          items={items}
          noHeader
        />
      </Box>
    </Card>
  );
};

export default ScheduleWidget;
