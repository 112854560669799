import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormContext } from 'react-hook-form';
import Form from '@rjsf/chakra-ui';
import validator from '@rjsf/validator-ajv8';
import { Link } from 'react-router-dom';

import {
  AssistantResponseInputs,
  CreateAssistantResponseAction,
  AssistantEditedResponse,
} from 'types/common';
import { GlobalState } from 'types';

import navRoutes from 'navigation/Routes';

import { analytics, hooks } from 'utils';
import { useWindowDimensions } from 'utils/hooks/useDimensions';

import {
  ASSISTANT_TOOLS,
  ASSISTANT_TOOL_VARIANTS,
  ASSISTANT_TRAITS,
  AssistantToolSlug,
} from 'constants/assistant';

import { assistantActions } from 'redux/actions/common';
import { useCurrentTeamProfile } from 'redux/selectors';

import {
  Stack,
  Box,
  Text,
  MdIcon,
  Flex,
  Button,
  LinkButton,
  Tooltip,
  Spinner,
  useTheme,
  chakra,
} from '@workshop/ui';

import { StepsModal, AssistantAnimation } from 'components/Common';
import { IconTooltip } from 'components/IconTooltip';

import Message from 'screens/common/Plan/src/Message';
import {
  ArrayFieldTemplate,
  TitleFieldTemplate,
} from 'screens/common/Plan/src/CoursePlan';
import TextareaWidget from 'screens/common/Plan/src/TextareaWidget';
import SessionAIPlan from 'screens/cms/SessionEdit/src/SessionAIPlan';

interface AssistantModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave?: (editedResponse?: AssistantEditedResponse) => Promise<void>;
  toolSlug: AssistantToolSlug | null;
  toolVariant?: string;
  // Context matched up with prompt input and function requirements
  context?: {
    courseId?: string;
    sessionId?: string;
    currency?: string;
    pricingOptions?: string;
  };
  planSlug?: string;
}

const timeout = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const AssistantModal: React.FC<AssistantModalProps> = ({
  isOpen,
  onClose,
  onSave,
  toolSlug,
  toolVariant,
  context,
  planSlug,
}) => {
  const [currentMessageStep, setCurrentMessageStep] = useState(0);
  const [isGenerating, setIsGenerating] = useState(true);
  const [interruptedError, setInterruptedError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [responseSlug, setResponseSlug] = useState('');
  const [savingResponse, setSavingResponse] = useState(false);
  const [responseChanged, setResponseChanged] = useState(false);
  const [editDisabled, setEditDisabled] = useState(true);
  const [formData, setFormData] = useState<
    AssistantEditedResponse | undefined | null
  >(null);
  const [stepLoading, setStepLoading] = useState(false);
  const [appLoading, setAppLoading] = useState(false);

  const currentTeamProfile = useCurrentTeamProfile();

  const formMethods = useForm<AssistantResponseInputs>({
    mode: 'onChange',
    defaultValues: {},
  });

  const dispatch = useDispatch();
  const { handleSubmit, reset, watch } = formMethods;

  const theme = useTheme();
  const windowDimensions = useWindowDimensions();
  const isMobile = windowDimensions.width < parseInt(theme.breakpoints.md, 10);

  const history = useHistory();

  const currentRoute = hooks.useCurrentRoute();
  const isApp = currentRoute?.isApp;

  const assistantResponses = useSelector(
    (state: GlobalState) => state.assistant.responses
  );
  const assistantResponse = responseSlug
    ? assistantResponses[responseSlug]
    : null;

  const pollResponse = async () => {
    await timeout(5000);
    const assistantRes = await dispatch(
      assistantActions.retrieveResponse(responseSlug)
    );
    if (
      assistantRes?.payload &&
      'editedResponse' in assistantRes.payload &&
      assistantRes.payload.editedResponse
    ) {
      const res = assistantRes.payload.editedResponse;
      if (res.status === 202) {
        pollResponse();
      } else if (res.status !== 200) {
        analytics.track('Assistant Response Failed', {
          status: `${res.status}`,
          message: res.message || '',
        });
      }
    }
  };

  const editedResponse = assistantResponse?.editedResponse
    ? JSON.parse(JSON.stringify(assistantResponse.editedResponse))
    : undefined;
  const resInputs = assistantResponse?.inputs;
  const resStatus = editedResponse?.status;
  const resError = editedResponse?.error;
  const resErrorMessage = resError ? editedResponse?.message : '';

  const submitFormRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (planSlug) {
      const retrievePlan = async () => {
        setAppLoading(true);
        await dispatch(assistantActions.retrieveResponse(planSlug));
        setResponseSlug(planSlug);
        setAppLoading(false);
      };
      retrievePlan();
    }
  }, [planSlug]);

  useEffect(() => {
    submitFormRef.current = document.querySelector("button[type='submit']");
  }, [isGenerating]);

  useEffect(() => {
    if (resStatus === 202) {
      // Plan is still generating, so poll every 5 seconds
      setIsGenerating(true);
      pollResponse();
    }
    if (resStatus === 200 && isGenerating) {
      setFormData(editedResponse);
      if (isGenerating) {
        setIsGenerating(false);
      }
    }
  }, [resStatus]);

  useEffect(() => {
    if (!isOpen) {
      setCurrentMessageStep(0);
    }
  }, [isOpen]);

  const titleProps = {
    fontWeight: 'extrabold',
    fontSize: { base: '2xl', md: '3xl' },
    lineHeight: 1.2,
  };

  if (!toolSlug || !isOpen) {
    return null;
  }

  let tool = ASSISTANT_TOOLS[toolSlug];
  if (toolVariant) {
    // @ts-ignore
    tool = ASSISTANT_TOOL_VARIANTS[toolSlug][toolVariant];
  }

  const prefilledFields = context
    ? tool.prefilled.reduce(
        (acc, p) => ({
          ...acc,
          // @ts-ignore
          [p]: context[p],
        }),
        {} as { [key: string]: string }
      )
    : {};

  const uiSchema =
    'properties' in tool.responseTemplate
      ? // @ts-ignore
        Object.keys(tool.responseTemplate.properties).reduce(
          (acc, k) => ({
            ...acc,
            [k]: {
              'ui:widget': TextareaWidget,
              'ui:options': {
                chakra: {
                  mb: -3,
                  sx: {
                    label: {
                      // fontSize: 'sm',
                      // textAlign: 'center',
                      opacity: '1!important',
                      color: 'text.muted',
                    },
                    ...(editDisabled
                      ? {
                          '[disabled]': {
                            opacity: '1!important',
                            backgroundColor: 'background.tint3',
                            border: 'none',
                            cursor: 'auto',
                          },
                        }
                      : {}),
                  },
                },
              },
              showTypist: true,
            },
          }),
          {} as { [key: string]: any }
        )
      : {};

  const processResponse = (res: CreateAssistantResponseAction) => {
    if (res.error) {
      if (res.payload && 'normalizedErrors' in res.payload) {
        setErrorMessage(res.payload.normalizedErrors?.message as string);
      } else if (
        res.payload &&
        'name' in res.payload &&
        'message' in res.payload &&
        res.payload.name === 'RequestError' &&
        res.payload.message === 'Load failed'
      ) {
        // This error occurs on mobile web browsers when the browser
        // is closed while the response is generating
        setInterruptedError(true);
      }
    } else if (res.payload && 'slug' in res.payload) {
      if (toolSlug === 'proCourses') {
        history.push(navRoutes.global.coursePlan.path(res.payload.slug));
        return;
      } else {
        setResponseSlug(res.payload.slug);
      }
    }
  };

  const generateResponse = async () => {
    const submit = handleSubmit(async (data) => {
      setIsGenerating(true);
      setErrorMessage('');
      const response = await dispatch(
        assistantActions.createResponse({
          type: tool.promptType,
          ...(prefilledFields || {}),
          ...(resInputs ? resInputs : {}),
          ...data,
        })
      );
      if (response) {
        if (response.error) {
          reset(data);
        }
        if (toolSlug === 'proCourses' && data.email) {
          localStorage.setItem('planEmail', data.email as string);
        }
        processResponse(response);
      }
    });
    await submit();
  };

  const onComplete = async () => {
    let saveData = formData || editedResponse;
    if (toolSlug === 'sessions') {
      saveData = {
        ...saveData,
        ...(resInputs?.mediaType ? { mediaType: resInputs.mediaType } : {}),
      };
    }
    if (onSave) {
      await onSave(saveData);
    }
    onClose();
  };

  const currentMessages = tool.messages.filter(
    (m, idx) => idx <= currentMessageStep
  );

  // @ts-ignore
  const currentFields = [];
  currentMessages.forEach((m) => {
    if (m.fields) {
      m.fields.forEach((f) => {
        currentFields.push(f);
      });
    }
  });

  const currentFieldValues = watch();
  const incompleteFields = Object.keys(currentFieldValues).filter((k) => {
    // @ts-ignore
    const field = currentFields.find((f) => f.name === k);
    if (field && 'canSkip' in field && field.canSkip) {
      return false;
    }
    return !currentFieldValues[k];
  });
  const hasIncompleteFields = Boolean(incompleteFields?.length > 0);

  const totalAiCredits = currentTeamProfile
    ? currentTeamProfile.currentMonthlyAiCredits +
      currentTeamProfile.currentExtraAiCredits
    : 0;

  const hasEnoughCredits =
    tool.credits > 0 ? totalAiCredits - tool.credits >= 0 : true;

  const steps = [
    {
      label: 'Chat',
      icon: <MdIcon name="Chat" />,
      hideNextButton: currentMessageStep < tool.messages.length - 1,
      nextButtonDisabled: hasIncompleteFields,
      nextButtonText: 'Generate',
      content: (
        <Flex flexDirection="column" alignItems="center" textAlign="center">
          <Flex alignItems="center" mt={{ base: -3, md: -2 }}>
            <Text fontSize="4xl" mr={2}>
              {tool.emoji}
            </Text>
            <Text {...titleProps} mr={2}>
              {tool.label}
            </Text>
            <IconTooltip message={tool.description} />
          </Flex>
          {tool.credits > 0 && (
            <Flex
              alignItems="center"
              flexDirection={{ base: 'column', sm: 'row' }}
            >
              <Text my={0.5} fontSize="xs" color="text.muted">
                {`Cost: ${
                  tool.credits
                } AI credits • Available: ${totalAiCredits.toLocaleString()} AI credits`}
              </Text>
              <LinkButton
                size="xs"
                mt={{ base: 1, sm: -1 }}
                mb={{ base: 0, sm: -1 }}
                ml={{ base: 0, sm: 1.5 }}
                variant="outline"
                fontSize="2xs"
                to={`${navRoutes.cms.myOrganisation.path()}?tab=subscriptions&p=ai`}
              >
                Top Up
              </LinkButton>
            </Flex>
          )}
          <Box
            w="100%"
            textAlign="left"
            maxHeight={{
              base: 'calc(100vh - 19rem)',
              md: '60vh',
            }}
            transition="height 0.3s"
            overflow="scroll"
            borderTopWidth={1}
            borderBottomWidth={1}
            borderColor="border.muted"
            pb={4}
            mt={2}
          >
            <FormContext {...formMethods}>
              {currentMessages.map((s, idx) => (
                <Message
                  key={`message-${idx}`}
                  {...s}
                  isCurrent={idx === currentMessageStep}
                  isMobile={isMobile}
                  condensed
                />
              ))}
            </FormContext>
          </Box>
          {currentMessageStep < tool.messages.length - 1 && (
            <>
              <Button
                size="lg"
                mt={4}
                px={6}
                icon="ArrowForward"
                iconPosition="right"
                onClick={() => {
                  setCurrentMessageStep(currentMessageStep + 1);
                }}
                isDisabled={hasIncompleteFields || !hasEnoughCredits}
              >
                {currentMessageStep === 0
                  ? 'Start'
                  : currentMessageStep === tool.messages.length - 1
                  ? 'Generate'
                  : 'Next'}
              </Button>
              {!hasEnoughCredits && (
                <Text
                  my={6}
                  color="text.warning"
                  bg="background.warning"
                  borderRadius="md"
                  padding={3}
                >
                  Looks like you don't have enough AI credits to use this tool.
                  You can{' '}
                  <Link
                    to={`${navRoutes.cms.myOrganisation.path()}?tab=subscriptions&p=ai`}
                  >
                    <chakra.span
                      color="common.primary"
                      fontWeight="bold"
                      _hover={{ textDecoration: 'underline' }}
                    >
                      top up
                    </chakra.span>
                  </Link>
                  {' or '}
                  <Link
                    to={`${navRoutes.cms.myOrganisation.path()}?tab=subscriptions`}
                  >
                    <chakra.span
                      color="common.primary"
                      fontWeight="bold"
                      _hover={{ textDecoration: 'underline' }}
                    >
                      upgrade your plan
                    </chakra.span>
                  </Link>{' '}
                  to continue.
                </Text>
              )}
            </>
          )}
        </Flex>
      ),
    },
    {
      label: 'Result',
      icon: <MdIcon name="Done" />,
      hideNextButton: isGenerating,
      content: (
        <Flex flexDirection="column" alignItems="center" textAlign="center">
          <Flex mb={2} alignItems="center" mt={{ base: -3, md: -2 }}>
            <Text fontSize="4xl" mr={2}>
              {tool.emoji}
            </Text>
            <Text {...titleProps} mr={2}>
              {tool.label}
            </Text>
            <IconTooltip message={tool.description} />
          </Flex>
          {isGenerating ? (
            <Box
              w="100%"
              borderTopWidth={1}
              borderBottomWidth={1}
              borderColor="border.muted"
              pb={4}
              pt={4}
            >
              <Spinner mt={6} color="common.primary" />
              <Text mt={4} color="common.primary" mb={8}>
                {tool.loadingText || 'Generating...'}
              </Text>
            </Box>
          ) : toolSlug === 'sessions' ? (
            <Box w="100%" textAlign="left">
              <SessionAIPlan
                // @ts-ignore
                formData={formData}
                setFormData={setFormData}
                // @ts-ignore
                planJson={editedResponse}
              />
            </Box>
          ) : (
            <>
              <Box
                w="100%"
                textAlign="left"
                borderTopWidth={1}
                borderBottomWidth={1}
                borderColor="border.muted"
                pb={4}
                pt={4}
              >
                <Form
                  // @ts-ignore
                  schema={tool.responseTemplate}
                  formData={formData || editedResponse}
                  validator={validator}
                  disabled={editDisabled}
                  uiSchema={uiSchema}
                  onSubmit={async (data) => {
                    if (responseChanged) {
                      setSavingResponse(true);
                      setFormData(data.formData);
                      await dispatch(
                        assistantActions.updateResponse(responseSlug, {
                          editedResponse: {
                            ...data.formData,
                            status: 200,
                            error: false,
                          },
                        })
                      );
                      setSavingResponse(false);
                      setResponseChanged(false);
                    }
                    setEditDisabled(true);
                  }}
                  onChange={(data) => {
                    setFormData(data.formData);
                    if (!responseChanged) setResponseChanged(true);
                  }}
                  templates={{
                    ArrayFieldTemplate,
                    TitleFieldTemplate,
                  }}
                >
                  <Button
                    type="submit"
                    size="sm"
                    // float="right"
                    mb={1}
                    isLoading={Boolean(savingResponse)}
                    variant="outline"
                    // disabled={!responseChanged}
                    icon="Done"
                    display={editDisabled ? 'none' : 'block'}
                  >
                    Save
                  </Button>
                </Form>
                <Stack
                  display={editDisabled ? 'flex' : 'none'}
                  direction={{ base: 'column', md: 'row' }}
                  alignItems={{ base: 'flex-start', md: 'center' }}
                >
                  <Button
                    icon="Replay"
                    variant="outline"
                    size="sm"
                    onClick={() => generateResponse()}
                  >
                    Retry
                  </Button>
                  <Button
                    icon="Edit"
                    variant="outline"
                    size="sm"
                    onClick={() => setEditDisabled(false)}
                  >
                    Edit
                  </Button>
                  {/* <Button icon="AutoAwesome" variant="outline" size="sm">
                    Give Feedback & Try Again
                  </Button> */}
                </Stack>
                <Text
                  fontSize="xs"
                  color="text.muted"
                  mt={4}
                  textAlign="center"
                >
                  Suggestions are made with machine learning algorithms and do
                  not reflect anyone's views. We can't guarantee accuracy, so
                  please verify everything yourself!
                </Text>
              </Box>
            </>
          )}
        </Flex>
      ),
    },
  ];

  if (toolSlug === 'sessions' && isApp && planSlug) {
    return (
      <Box
        position="fixed"
        top={0}
        left={0}
        right={0}
        bottom={0}
        bg="background.default"
        zIndex={100000}
        py={4}
      >
        <Flex
          height="100%"
          flexDirection="column"
          alignItems="center"
          textAlign="center"
        >
          <Flex px={4} mb={2} alignItems="center">
            <AssistantAnimation boxSize={80} />
            <Text
              color="text.primary"
              textAlign="left"
              flex={1}
              px={4}
              py={3}
              marginLeft={2}
              bg="background.primary"
              borderRadius="lg"
            >
              Here's what I'm thinking for your session!
            </Text>
            {/* <Text fontSize="4xl" mr={2}>
              {tool.emoji}
            </Text>
            <Text {...titleProps} mr={2}>
              {tool.label}
            </Text> */}
          </Flex>
          {appLoading ? (
            <Spinner size="xl" my={12} color="common.primary" />
          ) : (
            <>
              <Box
                w="100%"
                textAlign="left"
                overflow="scroll"
                flex={1}
                borderTopWidth={1}
                borderBottomWidth={1}
                borderColor="border.muted"
                mb={4}
                py={4}
                px={4}
              >
                <SessionAIPlan
                  // @ts-ignore
                  formData={formData}
                  setFormData={setFormData}
                  // @ts-ignore
                  planJson={editedResponse}
                />
              </Box>
              <Box w="100%" mx={4}>
                <Button
                  w="100%"
                  maxWidth="370px"
                  mx="auto"
                  size="lg"
                  mb={4}
                  onClick={async () => {
                    setStepLoading(true);
                    try {
                      if (submitFormRef.current) {
                        await submitFormRef.current.click();
                      }
                      await onComplete();
                    } catch {
                      return 'error';
                    }
                    setStepLoading(false);
                  }}
                  isLoading={stepLoading}
                  icon="Done"
                >
                  Build Session
                </Button>
              </Box>
            </>
          )}
        </Flex>
      </Box>
    );
  }

  return (
    <StepsModal
      heading=""
      isOpen={isOpen}
      onClose={onClose}
      onCompleteStep={async (stepIndex) => {
        if (stepIndex === 0) {
          try {
            await generateResponse();
          } catch {
            return 'error';
          }
        }
        if (stepIndex === 1) {
          try {
            if (submitFormRef.current) {
              await submitFormRef.current.click();
            }
            await onComplete();
          } catch {
            return 'error';
          }
        }
      }}
      steps={isOpen ? steps : []}
      forceHorizontalSteps
      hideStepLabels
      hideAllStepLabels
      hideStepList
      bigNext
      disablePrev
      verticalCenter={false}
      header={
        <Flex
          position="absolute"
          top={{ base: -4, md: -4 }}
          right={{ base: 3, md: 3 }}
        >
          <Stack direction="row">
            {ASSISTANT_TRAITS.map((trait) => (
              <Flex
                key={`tool-${tool.slug}-trait-${trait.slug}`}
                alignItems="center"
                justifyContent="center"
                display={
                  (trait.slug === 'tone' && tool.traitTone) ||
                  (trait.slug === 'skillset' && tool.traitSkillset) ||
                  (trait.slug === 'values' && tool.traitValues) ||
                  (trait.slug === 'audience' && tool.traitAudience) ||
                  (trait.slug === 'context' && tool.traitContext)
                    ? 'flex'
                    : 'none'
                }
              >
                <Tooltip
                  label={`This tool considers your ${trait.label.toLowerCase()}`}
                >
                  <Flex
                    bg={`${trait.colorScheme}.100`}
                    alignItems="center"
                    justifyContent="center"
                    borderRadius="full"
                    boxSize={{ base: 6, md: 6 }}
                  >
                    <MdIcon
                      name={trait.icon}
                      color={`${trait.colorScheme}.500`}
                      boxSize={{ base: 4, md: 4 }}
                    />
                  </Flex>
                </Tooltip>
              </Flex>
            ))}
          </Stack>
        </Flex>
      }
      footer={
        <>
          <Flex mb={6} />
          <Flex
            position="absolute"
            left={{ base: -2, md: -8 }}
            bottom={{ base: -4, md: -8 }}
            borderWidth={{ base: 6, md: 8 }}
            borderColor="background.default"
            borderRadius="full"
          >
            <Flex m={-0.5}>
              <AssistantAnimation boxSize={isMobile ? 80 : 120} />
            </Flex>
          </Flex>
        </>
      }
    />
  );
};

export default AssistantModal;
