import { takeEvery } from 'redux-saga/effects';
import { Auth } from 'redux/actionTypes/common';

function* clearLocalStorage() {
  localStorage.removeItem('defaultTeam');
  localStorage.removeItem('userId');
}

export function* watchUserLogout() {
  yield takeEvery(Auth.DELETE_TOKEN_SUCCESS, clearLocalStorage);
}
