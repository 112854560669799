import React from 'react';
import {
  Box,
  Heading,
  Paragraph,
  chakra,
  TextProps,
  PropsOf,
  Table,
  Tr,
  Td,
  Tbody,
} from '@workshop/ui';
import { ScreenWrapper } from 'screens/common/ScreenWrapper';

import navRoutes from 'navigation/Routes';

import { PLATFORM_DISPLAY_NAME, PLATFORM_EMAIL } from 'constants/common';
import { helpUrl } from 'constants/env';

const H1 = (props: TextProps) => (
  <Heading fontSize="xl" pb={3} pt={6} {...props} />
);
const H2 = (props: TextProps) => (
  <Heading fontSize="lg" pb={3} pt={6} {...props} />
);
const H3 = (props: TextProps) => (
  <Heading fontSize="lg" pb={3} pt={6} {...props} />
);
const P = (props: TextProps) => <Paragraph pb={3} {...props} />;
const A = (props: PropsOf<typeof chakra.a>) => (
  <chakra.a color="text.primary" fontWeight="semibold" {...props} />
);
const PrivacyScreen: React.FC = () => {
  return (
    <ScreenWrapper>
      <Box listStylePosition="inside" mx={{ base: 'defaultMargin', md: 0 }}>
        <P>
          <chakra.em>Last Updated 12 January, 2023</chakra.em>
        </P>
        <H1>
          <chakra.span>Introduction and Who We Are</chakra.span>
        </H1>
        <P>
          <chakra.span>
            {`Thanks for choosing ${PLATFORM_DISPLAY_NAME}. `}We at Workshop
            Tech Solutions Ltd (“Workshop”, “We”, “Us” or “Our”) respect your
            privacy and want you to understand how we collect, use, and share
            data about you through our mobile services, web site, and any
            software provided on or in connection with Workshop services
            (collectively, the “Services”). Please read this notice carefully to
            understand what we do. If you do not understand any aspects of our
            Privacy Policy, please feel free to contact us at{' '}
            <A
              href={`mailto:privacy${PLATFORM_EMAIL}`}
            >{`privacy${PLATFORM_EMAIL}`}</A>
            . Your use of our Services is also governed by our{' '}
            <A href={navRoutes.global.terms.path()}>Terms of Use</A>.
          </chakra.span>
        </P>
        <P>
          <chakra.span>
            If you reside in the UK or the European Economic Area (“EEA”),
            Workshop is the data controller of all Personally Identifiable
            Information (as defined below) collected via the Services and of
            some Personally Identifiable Information collected from third
            parties or from tutors, mentors and partner businesses and
            organisations (collectively “Partners”), as set out in this Privacy
            Policy.
          </chakra.span>
        </P>
        <P>
          <chakra.span>
            In some situations, Workshop acts as the data processor of
            Personally Identifiable Information collected by Partners. When
            Partners are the owners of content that they upload, submit, or
            otherwise provide to the Platform (“Partner Content”), then they are
            the data controller with respect to any personal information
            included in their Partner Content. Workshop acts as a processor when
            it processes Partner Content on behalf of the Partner.
          </chakra.span>
        </P>
        <H1>
          <chakra.span>What Information this Privacy Policy Covers</chakra.span>
        </H1>
        <P>
          <chakra.span>
            This Privacy Policy covers our data collection practices and
            describes your rights to access, correct, or restrict our use of
            your personal data. It also serves to help you understand what data
            we collect with your permission, and what we do — and do not do —
            with it.
          </chakra.span>
        </P>
        <P>
          <chakra.span>
            This Privacy Policy applies to your use of Workshop Services. As
            used herein the term “use” shall mean use, access, install, sign
            into, connect with, contribute to, download, visit or browse the
            Services.
          </chakra.span>
        </P>
        <P>
          <chakra.span>
            Some of our Services functionality can be used without revealing any
            Personally Identifiable Information, though for features or services
            related to courses, Personally Identifiable Information is required.
            “Personally Identifiable Information” (i.e., information that can be
            used to identify you) can include information such as your name and
            email address, among other things. You are responsible for ensuring
            the accuracy of the Personally Identifiable Information you submit
            to Workshop. Inaccurate information may affect your ability to use
            the Services, the information you receive when using the Services,
            and our ability to contact you. For example, your email address
            should be kept current because that is one of the primary manners in
            which we communicate with you.
          </chakra.span>
        </P>
        <P>
          <chakra.span>
            Broadly speaking, there are two categories of information we
            collect: 1) information that we must have in order for you to use
            Workshop; and 2) information that we can use to provide additional
            features and improved experiences if you choose to share that
            information.&nbsp;
          </chakra.span>
        </P>
        <P>
          <chakra.span>The first category includes:</chakra.span>
        </P>
        <chakra.ul>
          <chakra.li>
            <chakra.span>
              Registration information, including your name, email, and other
              information you provide when you sign up for Workshop.
            </chakra.span>
          </chakra.li>
          <chakra.li>
            <chakra.span>
              The courses you sign up to and your interactions with the
              Services. This allows us to make recommendations to you and
              suggest relevant content.
            </chakra.span>
          </chakra.li>
          <chakra.li>
            <chakra.span>
              Technical information necessary to operate Workshop. This includes
              the type of browser and device you use.
            </chakra.span>
          </chakra.li>
        </chakra.ul>
        <P>
          <chakra.span>
            The second category includes information that enables us to offer
            you additional features. We never receive any of the following
            information unless you expressly choose to share it with us. It’s as
            simple as that. Here are some examples:
          </chakra.span>
        </P>
        <chakra.ul>
          <chakra.li>
            <chakra.span>
              Your photos: We will only access images that you specifically
              choose to share, and we will never scan or import your photo
              library or camera roll. This allows you to choose individual
              pictures to change your profile picture or upload as a challenge
              submission. You can stop sharing photos and revoke access at any
              time.
            </chakra.span>
          </chakra.li>
        </chakra.ul>
        <P>
          <chakra.span>
            The distinction between these two categories is important.
            Information in the first category is information you must provide in
            order to use Workshop. When you agree to our Privacy Policy, you
            give us the right to collect this information and use it for the
            purposes described. Information in the second category is
            information we will only collect if you explicitly give us
            permission to do so in the future. For this second category of
            information, we will ask for your permission before first accessing
            it, we will describe how we will use it if you give us permission,
            we will only use the information for the purpose we described, and
            you will always have the ability to change your mind and revoke that
            permission. Acceptance of our Privacy Policy does not mean you have
            granted us permission to access or use information in the second
            category; we’re just explaining to you that one day we might ask you
            for that permission.
          </chakra.span>
        </P>
        <P>
          <chakra.b>
            We will be clear with you about how and when we might share
            information.
          </chakra.b>
        </P>
        <P>
          <chakra.span>
            In some situations, we will share certain information with third
            parties. For example, like most services, if you sign up for
            Workshop through a third party like Facebook or a mobile provider,
            we share some information with them and they share some information
            with us in order to enable your account. For the purposes of course
            delivery, we also share some information about you with our Partners
            (see ‘Sharing Your Data’ below).
          </chakra.span>
        </P>
        <P>
          <chakra.span>
            We hope this helps you to understand our privacy commitments to you.
            Now, the details await you below, but please remember that this
            Introduction is part of the Privacy Policy itself — it’s our promise
            to you. If you ever have any questions or concerns, then please let
            us know at{' '}
          </chakra.span>
          <chakra.strong>
            <A
              href={`mailto:privacy${PLATFORM_EMAIL}`}
            >{`privacy${PLATFORM_EMAIL}`}</A>
          </chakra.strong>
          <chakra.span>.</chakra.span>
        </P>
        <H1>
          <chakra.span>What data we collect</chakra.span>
        </H1>
        <P>
          <chakra.b>
            We collect certain data from you directly, like information you
            enter yourself, data about your participation in courses, and data
            from third-party platforms you connect with Workshop (such as
            Facebook). We also collect some data automatically, like information
            about your device and what parts of the Services you interact with
            or spend time using.
          </chakra.b>
        </P>
        <H2>
          <chakra.span>Data you provide to us</chakra.span>
        </H2>
        <P>
          <chakra.span>
            When you create an account and use the Services, including through a
            third-party platform or from a Partner, or fill in a form on our
            websites, we collect any data you provide directly, including:
          </chakra.span>
        </P>
        <Table mb={6}>
          <Tbody>
            <Tr>
              <Td>
                <chakra.strong>Account Data</chakra.strong>
              </Td>
              <Td>
                <chakra.span>
                  In order to use certain features (such as purchasing a
                  course), you need to create a user account. When you create or
                  update your account, we collect and store the data you
                  provide, including Personally Identifiable Information such as
                  your name and email address as well as your password. We also
                  assign you a unique identifying number. We may use the
                  Personally Identifiable Information that you provide when
                  responding to your questions, or to send you updates about new
                  courses offered by Workshop, and to send you email messages
                  about maintenance or updates to the Services.
                </chakra.span>
              </Td>
            </Tr>
            <Tr>
              <Td>
                <chakra.strong>Profile Data</chakra.strong>
              </Td>
              <Td>
                <chakra.span>
                  You can also choose to provide profile information such as
                  your country of residence or preferred currency. Your Profile
                  Data is private to you.
                </chakra.span>
              </Td>
            </Tr>
            <Tr>
              <Td>
                <chakra.strong>Device Data</chakra.strong>
              </Td>
              <Td>
                <P>
                  <chakra.span>
                    We may provide features that rely on the use of additional
                    information on your mobile device or require access to
                    certain services on your mobile device that will enhance
                    your Workshop experience but are not required to use the
                    Services. (In other words, information that falls in the
                    second category described in the Introduction to this
                    Policy.)
                  </chakra.span>
                </P>
                <P>&nbsp;</P>
                <P>
                  <chakra.span>
                    For example, we might allow you to upload photos to your
                    profile, connect with friends, or let you use voice commands
                    to control the Services. Granting us access does not mean
                    you are granting us unlimited access to that information or
                    that we will access specific information without your
                    permission. You do not have to give us such permission in
                    order to use Workshop, and acceptance of this Privacy Policy
                    does not mean you have granted us permission to access this
                    information.
                  </chakra.span>
                  <chakra.span>
                    <chakra.br />{' '}
                  </chakra.span>
                  <chakra.span>
                    <chakra.br />{' '}
                  </chakra.span>
                  <chakra.span>In particular:</chakra.span>
                </P>
                <chakra.ul>
                  <chakra.li>
                    <chakra.span>
                      Photos and Camera: We will not access your photos or
                      camera without first getting your explicit permission and
                      we will never scan or import your photo library or camera
                      roll. If you give us permission to access photos or your
                      camera, we will only use images that you specifically
                      choose to share with us. (You may use our application to
                      select the photo or photos you choose to share, but we
                      will never import the photos you review except those you
                      explicitly share.)
                    </chakra.span>
                  </chakra.li>
                </chakra.ul>
              </Td>
            </Tr>
            <Tr>
              <Td>
                <chakra.strong>Shared Content</chakra.strong>
              </Td>
              <Td>
                <chakra.span>
                  Parts of the Services let you interact with other users or
                  share content publicly, including by posting questions and/or
                  answers on a course page, sending messages to students or
                  mentors, or posting photos or other work you upload. Such
                  shared content may be publicly viewable by others depending on
                  where it is posted. You should not include any Personally
                  Identifiable Information or other information of a personal or
                  sensitive nature, whether relating to you or another person.
                </chakra.span>
              </Td>
            </Tr>
            <Tr>
              <Td>
                <chakra.strong>Workshop Data</chakra.strong>
              </Td>
              <Td>
                <chakra.span>
                  When you purchase and take part in courses, we collect certain
                  data including which courses and assessments you’ve started
                  and completed; your exchanges with mentors and other students;
                  and questions and answers related to particular courses.
                </chakra.span>
              </Td>
            </Tr>
            <Tr>
              <Td>
                <chakra.strong>Payment Data</chakra.strong>
              </Td>
              <Td>
                <chakra.span>
                  If you make purchases, we collect certain data as necessary to
                  process your order. You must provide certain payment and
                  billing data directly to our payment processing partners,
                  including your name, credit/debit card information and billing
                  address. For security, Workshop does not collect or store
                  sensitive cardholder data, such as full credit card numbers or
                  card authentication data.
                </chakra.span>
              </Td>
            </Tr>
            <Tr>
              <Td>
                <chakra.strong>
                  Data About Your Accounts on Other Services
                </chakra.strong>
              </Td>
              <Td>
                <P>
                  <chakra.span>
                    We may obtain certain information, including Personally
                    Identifiable Information, through your social media or other
                    online accounts if they are connected to your Workshop
                    account. If you login to Workshop via Facebook or another
                    third-party platform or service, we ask for your permission
                    to access certain information about that other account. For
                    example, depending on the platform or service we may collect
                    your name, profile picture, account ID number, and login
                    email address. We do not collect information on your gender
                    or a list of friends and contacts.
                  </chakra.span>
                </P>
                <P>&nbsp;</P>
                <P>
                  <chakra.span>
                    Those platforms and services make information available to
                    us through their APIs. The information we receive depends on
                    what information you (via your privacy settings) or the
                    platform or service decide to give us.
                  </chakra.span>
                </P>
                <P>&nbsp;</P>
                <P>
                  <chakra.span>
                    If you access or use our Services through a third-party
                    platform or service, or click on any third-party links, the
                    collection, use, and sharing of your data will also be
                    subject to the privacy policies and other agreements of that
                    third party.
                  </chakra.span>
                </P>
              </Td>
            </Tr>
            <Tr>
              <Td>
                <chakra.strong>Promotions, Contests, and Surveys</chakra.strong>
              </Td>
              <Td>
                <chakra.span>
                  We may invite you to complete a survey or participate in a
                  promotion (such as a contest, sweepstake, or challenge),
                  either through the Services or a third-party platform. If you
                  participate we will collect Personally Identifiable
                  Information and store the data you provide as part of the
                  participating, such as your name, email address, date of
                  birth, or phone number. That data is subject to this Privacy
                  Policy unless otherwise stated in the official rules of the
                  promotion or in another privacy policy. The data collected
                  will be used to administer the promotion or survey, including
                  for notifying winners and distributing rewards. To receive a
                  reward, you may be required to allow us to post some of your
                  information publicly (like on a winner’s page). Where we use a
                  third-party platform to administer a survey or promotion, the
                  third party’s privacy policy will apply.
                </chakra.span>
              </Td>
            </Tr>
            <Tr>
              <Td>
                <chakra.strong>Communications and Support</chakra.strong>
              </Td>
              <Td>
                <chakra.span>
                  If you contact us or our Partners for support or to report a
                  problem or concern (regardless of whether you have created an
                  account), we collect and store your contact information,
                  messages, and other data about you such as your name, email
                  address, location, operating system, IP address, and any other
                  data you provide or that we collect through automated means
                  (covered below). We use this data to respond to you and
                  research your question or concern, in accordance with this
                  Privacy Policy.
                </chakra.span>
              </Td>
            </Tr>
            <Tr>
              <Td>
                <chakra.strong>Website Forms and Lead Forms</chakra.strong>
              </Td>
              <Td>
                <chakra.span>
                  We may use forms on our websites or on third-party platforms
                  to request Personally Identifiable Information, such as your
                  name, company and email address, in order to enter into a
                  conversation about Workshop services, to access resources, to
                  join a mailing list, or to provide Services or additional
                  information. We will process and store this information
                  according to this Privacy Policy.
                </chakra.span>
              </Td>
            </Tr>
          </Tbody>
        </Table>
        <P>
          <chakra.span>
            The data listed above is stored by us and associated with your
            account.
          </chakra.span>
        </P>
        <H2>
          <chakra.span>Data we collect through automated means</chakra.span>
        </H2>
        <P>
          <chakra.span>
            When you access the Services (including browsing courses), we
            collect certain data by automated means, including:
          </chakra.span>
        </P>
        <Table mb={6}>
          <Tbody>
            <Tr>
              <Td>
                <chakra.strong>System Data</chakra.strong>
              </Td>
              <Td>
                <chakra.span>
                  Technical data about your computer or device, like your IP
                  address, device type, operating system type and version,
                  unique device identifiers, network connection type (e.g.,
                  WiFi, 3G, LTE) and provider, network and device performance,
                  browser type, language, and other systems data, and platform
                  types.
                </chakra.span>
              </Td>
            </Tr>
            <Tr>
              <Td>
                <chakra.strong>Usage Data</chakra.strong>
              </Td>
              <Td>
                <chakra.span>
                  Usage statistic about your interactions with the Services,
                  including courses accessed, interaction with courses, time
                  spent on pages or the Services, pages visited, features used,
                  your search queries, click data, date and time and other data
                  regarding your use of the Services
                </chakra.span>
              </Td>
            </Tr>
            <Tr>
              <Td>
                <chakra.strong>Approximate Geographic Data</chakra.strong>
              </Td>
              <Td>
                <chakra.span>
                  An approximate geographic location, including information such
                  as country, city, and geographic coordinates, calculated based
                  on your IP address.
                </chakra.span>
              </Td>
            </Tr>
          </Tbody>
        </Table>
        <P>
          <chakra.span>
            The data listed above is collected through the user or server log
            files and tracking technologies, as detailed in the{' '}
          </chakra.span>
          <chakra.i>
            <chakra.span>Cookies and Data Collection Tools</chakra.span>
          </chakra.i>
          <chakra.span>
            {' '}
            section below. It is stored by us and associated with your account.
          </chakra.span>
        </P>
        <H1>
          <chakra.span>How We Get Data About You</chakra.span>
        </H1>
        <P>
          <chakra.b>
            We use tools like cookies, web beacons, analytics services, and
            advertising providers to gather the data listed above. Some of these
            tools offer you the ability to opt out of data collection.
          </chakra.b>
        </P>
        <H2>
          <chakra.span>Cookies and data collection tools</chakra.span>
        </H2>
        <P>
          <chakra.span>
            As detailed in our Cookie Policy, Workshop, our Partners and service
            providers acting on our behalf (like Google Analytics and
            third-party advertisers) may use server log files and automated data
            collection tools like cookies, tags, scripts, customised links,
            device or browser fingerprints, and web beacons (together, “Data
            Collection Tools”) when you access and use the Services. These Data
            Collection Tools automatically track and collect certain System Data
            and Usage Data when you use the Services. In some cases, we tie data
            gathered through those Data Collection Tools to other data that we
            collect as described in this Privacy Policy.
          </chakra.span>
          <chakra.span>
            <chakra.br />{' '}
          </chakra.span>
          <chakra.span>
            <chakra.br />{' '}
          </chakra.span>
          <chakra.span>
            We use cookies (small files that websites send to your device to
            uniquely identify your browser or device or to store data in your
            browser) for things like analysing your use of the Services,
            personalising your experience, making it easier to log into the
            Services, and recognising you when you return. We use web beacons
            (small objects that allow us to measure the actions of visitors and
            users using the Services) for things like identifying whether a page
            was visited, identifying whether an email was opened, and
            advertising more efficiently by excluding current users from certain
            promotional messages or identifying the source of a new mobile app
            download.
          </chakra.span>
        </P>
        <P>
          <chakra.span>
            Workshop uses the following types of cookies:
          </chakra.span>
        </P>
        <chakra.ul>
          <chakra.li>
            <chakra.strong>Preferences:</chakra.strong>
            <chakra.span>
              {' '}
              cookies that remember data about your browser and preferred
              settings that affect the appearance and behaviour of the Services
              (like whether you have dismissed an announcement)
            </chakra.span>
          </chakra.li>
        </chakra.ul>
        <chakra.ul>
          <chakra.li>
            <chakra.strong>Security:</chakra.strong>
            <chakra.span>
              {' '}
              cookies used to enable you to log in and access the Services;
              protect against fraudulent logins; and help detect and prevent
              abuse or unauthenticated use of your account.
            </chakra.span>
          </chakra.li>
        </chakra.ul>
        <chakra.ul>
          <chakra.li>
            <chakra.strong>Functional:</chakra.strong>
            <chakra.span>
              {' '}
              cookies that store functional settings (like the volume you set
              for video playback).
            </chakra.span>
          </chakra.li>
        </chakra.ul>
        <chakra.ul>
          <chakra.li>
            <chakra.strong>Session State:</chakra.strong>
            <chakra.span>
              {' '}
              cookies that track your interactions with the Services to help us
              improve the Services and your browsing experience, remember your
              login details, and enabled processing of your course purchases.
              These are strictly necessary for the Services to work properly. If
              these are disabled then certain functionalities will break or
              become unavailable.
            </chakra.span>
          </chakra.li>
        </chakra.ul>
        <P>
          <chakra.span>
            You can set your web browser to alert you about attempts to place
            cookies on your computer, limit the types of cookies you allow, or
            refuse cookies altogether. If you do, you may not be able to use
            some or all features of the Services, and your experience may be
            different or less functional.
          </chakra.span>
          <chakra.span>
            <chakra.br />{' '}
          </chakra.span>
          <chakra.span>
            <chakra.br />{' '}
          </chakra.span>
          <chakra.span>
            Some of the third-party partners who provide certain features on our
            site may also use Local Storage Objects (also known as flash cookies
            or LSOs) to collect and store data.
          </chakra.span>
        </P>
        <H2>
          <chakra.span>Analytics</chakra.span>
        </H2>
        <P>
          <chakra.span>
            We use third-party browser and mobile analytics services, such as
            Google Analytics and Firebase, on the Services. These services use
            Data Collection Tools to help us analyze your use of the Services,
            including information such as the third-party website you arrive
            from, how often you visit, events within the Services, usage and
            performance data, and where the application was downloaded from. We
            use this data to improve the Services, better understand how the
            Services perform on different devices, and provide information that
            may be of interest to you.
          </chakra.span>
        </P>
        <H2>
          <chakra.span>Online Advertising</chakra.span>
        </H2>
        <P>
          <chakra.span>
            We use third-party advertising services like Facebook, Google’s ad
            services, and other ad networks and ad servers to deliver
            advertising about our Services on other websites and applications
            you use. The ads may be based on things we know about you, like your
            Usage Data and System Data, and things that these ad service
            providers know about you based on their tracking data. The ads can
            be based on your recent activity or activity over time and across
            other sites and services, and may be tailored to your interests.
          </chakra.span>
          <chakra.span>
            <chakra.br />{' '}
          </chakra.span>
          <chakra.span>
            <chakra.br />{' '}
          </chakra.span>
          <chakra.span>
            Depending on the types of advertising services we use, they may
            place cookies or other tracking technologies on your computer,
            phone, or other device to collect data about your use of our
            Services, and may access those tracking technologies in order to
            serve these tailored advertisements to you. To help deliver tailored
            advertising, we may provide these service providers with an
            anonymised identifier representing you (in a non-human-readable
            form) and content that you share publicly on the Services.
          </chakra.span>
          <chakra.span>
            <chakra.br />{' '}
          </chakra.span>
          <chakra.span>
            <chakra.br />{' '}
          </chakra.span>
          <chakra.span>
            When using mobile applications you may also receive tailored in-app
            advertisements. Apple iOS, and Android each provide their own
            instructions on how to control in-app tailored advertising. For
            other devices and operating systems, you should review your privacy
            settings or contact your platform operator.
          </chakra.span>
        </P>
        <H1>
          <chakra.span>What We Do With Your Data</chakra.span>
        </H1>
        <P>
          <chakra.b>
            We use your data to provide our Services, communicate with you,
            troubleshoot issues, secure against fraud and abuse, improve and
            update our Services, analyse how people use our Services, serve
            personalised advertising and content, and as required by law or
            necessary for safety and integrity.
          </chakra.b>
        </P>
        <P>
          <chakra.span>
            In addition to some of the specific uses of information we describe
            in this Privacy Policy, we may use information that we collect to:
          </chakra.span>
        </P>
        <chakra.ul>
          <chakra.li>
            <chakra.span>
              help you efficiently access your information after you sign in
            </chakra.span>
          </chakra.li>
          <chakra.li>
            <chakra.span>
              remember information so you will not have to re-enter it during
              your visit or the next time you visit the Services;
            </chakra.span>
          </chakra.li>
          <chakra.li>
            <chakra.span>
              provide personalised content and information to you and others,
              which could include online ads or other forms of marketing
            </chakra.span>
          </chakra.li>
          <chakra.li>
            <chakra.span>
              provide, improve, test, and monitor the effectiveness of our
              Services
            </chakra.span>
          </chakra.li>
          <chakra.li>
            <chakra.span>
              develop and test new products and features
            </chakra.span>
          </chakra.li>
          <chakra.li>
            <chakra.span>
              to communicate with you for Service-related or research purposes
              including via emails, notifications, text messages, or other
              messages, which you agree to receive
            </chakra.span>
          </chakra.li>
          <chakra.li>
            <chakra.span>
              monitor metrics such as total number of visitors, traffic, and
              demographic patterns
            </chakra.span>
          </chakra.li>
          <chakra.li>
            <chakra.span>diagnose or fix technology problems</chakra.span>
          </chakra.li>
          <chakra.li>
            <chakra.span>
              as otherwise stated in this Privacy Policy.
            </chakra.span>
          </chakra.li>
        </chakra.ul>
        <H1>
          <chakra.span>Sharing your data</chakra.span>
        </H1>
        <P>
          <chakra.b>
            We share certain data about you with Partners, course providers,
            mentors, other students, companies performing services for us, our
            business partners, analytics and data enrichment providers, your
            social media providers, companies helping us run promotions and
            surveys, and advertising companies who help us promote our Services.
            We may share data in other ways if it is aggregated or anonymised or
            if we get your consent. We will only share the minimum data required
            for any purpose. For example, although we will share your name with
            your course mentor, we will not share your date of birth because it
            is not required to deliver course content.
          </chakra.b>
        </P>
        <H2>
          <chakra.span>Sharing by you</chakra.span>
        </H2>
        <H3>
          <chakra.span>General</chakra.span>
        </H3>
        <P>
          <chakra.span>
            The Workshop Services are a collaborative learning service that
            offers many ways to find, learn, enjoy and share content. We
            encourage you to take advantage of these social features with others
            on the Services.
          </chakra.span>
        </P>
        <chakra.ul>
          <chakra.li>
            <chakra.span>
              Any information or content that you voluntarily disclose for
              posting to the Services, such as User Content, becomes available
              to the public, as controlled by any applicable privacy settings
              that you set. Once you have shared User Content or made it public,
              that User Content may be re-shared by others.
            </chakra.span>
          </chakra.li>
          <chakra.li>
            <chakra.span>
              If you remove information that you posted to the Services, copies
              may remain viewable in cached and archived pages of the Services,
              or if other Users or third parties using the Workshop API have
              copied or saved that information.
            </chakra.span>
          </chakra.li>
        </chakra.ul>
        <H3>
          <chakra.span>Third party applications</chakra.span>
        </H3>
        <chakra.ul>
          <chakra.li>
            <chakra.span>
              Workshop enables you to share particular items of content that you
              have created or completed, on an individual basis, or to Third
              Party Applications, other Workshop users, and others. Please note
              that if you share content to a Third Party Application or a third
              party service, it may be viewable by others within the Workshop
              Services.
            </chakra.span>
          </chakra.li>
          <chakra.li>
            <chakra.span>
              You understand and agree that a Third Party Application’s use of
              information collected from you (or as authorised by you) is
              governed by the Third Party Application’s privacy policies and
              your settings on the relevant service, and Workshop’s use of such
              information is governed by this Privacy Policy.
            </chakra.span>
          </chakra.li>
        </chakra.ul>
        <H2>
          <chakra.span>Sharing by Workshop</chakra.span>
        </H2>
        <P>
          <chakra.span>
            We may share your data with third parties under the following
            circumstances or as otherwise described in this Privacy Policy:
          </chakra.span>
        </P>
        <H3>
          <chakra.span>
            With course delivery Partners and your mentor
          </chakra.span>
        </H3>
        <P>
          <chakra.span>
            We actively share aggregated data with our course providers as part
            of our service to them, such as which courses our users have
            purchased or interacted with. This data may include things like
            location, device, the site that brought them to Workshop, but it
            will not contain any Personally Identifiable Information.&nbsp;
          </chakra.span>
        </P>
        <P>
          <chakra.span>
            Additionally, when you join a course, we securely provide the course
            delivery Partner access to some limited personal information about
            you to enable the Partner to provide their services to you (in most
            situations this will be sharing your name and occasionally your
            email address with your course mentor). Workshop and Partners may
            each use this data for their own business purposes, at all times
            subject to the terms of this Privacy Policy and our Terms of Use.
            The Partner and Workshop each act as an independent controller with
            respect to their particular use of this data. Partners acknowledge
            and agree that they are responsible for complying with all
            applicable laws and regulations related to the protection of
            personal information for which they are controllers.
          </chakra.span>
        </P>
        <P>
          <chakra.span>
            If your personal information has been collected by a Partner, please
            contact the Partner directly and view their privacy policy if you
            have questions about how the Partner uses your personal information.
          </chakra.span>
        </P>
        <P>
          <chakra.span>
            Further than this, we will only share your Personally Identifiable
            Information with Partners with your permission or if you request it.
          </chakra.span>
        </P>
        <P>
          <chakra.span></chakra.span>
        </P>
        <H3>
          <chakra.span>With other students and mentors</chakra.span>
        </H3>
        <P>
          <chakra.span>
            Depending on your settings, your shared content and profile data may
            be publicly viewable to other students and mentors on your course.
            If you choose to use the social features, engage in class discussion
            or ask a question to a mentor, your information (including your
            name) may also be publicly viewable by other users depending on your
            settings.
          </chakra.span>
        </P>
        <H3>
          <chakra.span>
            With service providers, contractors, and agents
          </chakra.span>
        </H3>
        <P>
          <chakra.span>
            We may share your data with third-party companies who perform
            services on our behalf, such as payment processing, data analysis,
            marketing and advertising services (including retargeted advertising
            and customer management databases), email and hosting services, and
            customer services and support. These service providers may access
            your Personally Identifiable Information and are required to use it
            solely as we direct, to provide our requested service.
          </chakra.span>
        </P>
        <H3>
          <chakra.span>With analytics services</chakra.span>
        </H3>
        <P>
          <chakra.span>
            As part of our use of third-party analytics tools, such as Google
            Analytics, we share certain contact information, Account Data,
            System Data, Usage Data, or anonymised data as required. Anonymised
            data means data which does not contain any Personally Identifiable
            Information, replacing this with an anonymous token or ID. This
            allows these providers to provide analytics services.
          </chakra.span>
        </P>
        <H3>
          <chakra.span>To power social media features</chakra.span>
        </H3>
        <P>
          <chakra.span>
            The social media features in the Services (like the Facebook Share
            button) may allow the third-party social media provider to collect
            data such as your IP address and which page of the Services you’re
            visiting, and to set a cookie to enable the feature. Your
            interactions with these features are governed by the third-party
            company’s privacy policy.
          </chakra.span>
        </P>
        <H3>
          <chakra.span>For promotions and surveys</chakra.span>
        </H3>
        <P>
          <chakra.span>
            We may share your data as necessary to administer, market, or
            sponsor promotions and surveys you choose to participate in, as
            required by applicable law (like to provide a winners list or make
            required filings), or in accordance with the rules of the promotion
            or survey.
          </chakra.span>
        </P>
        <H3>
          <chakra.span>For security and legal compliance</chakra.span>
        </H3>
        <P>
          <chakra.span>
            We may disclose your data to third parties if we (in our sole
            discretion) have a good faith belief that the disclosure is:
          </chakra.span>
        </P>
        <chakra.ul>
          <chakra.li>
            <chakra.span>Permitted or required by law</chakra.span>
          </chakra.li>
          <chakra.li>
            <chakra.span>
              Requested as part of a judicial, governmental, or legal inquiry,
              order, or proceeding
            </chakra.span>
          </chakra.li>
          <chakra.li>
            <chakra.span>
              Reasonably necessary to enforce our Terms of Use, and Privacy
              Policy
            </chakra.span>
          </chakra.li>
          <chakra.li>
            <chakra.span>
              Required to detect, prevent, or address fraud, abuse, misuse,
              potential violations of law (or rule or regulation), or security,
              or customer service, or technical issues; or
            </chakra.span>
          </chakra.li>
          <chakra.li>
            <chakra.span>
              Reasonably necessary in our discretion to protect against imminent
              harm to the rights, property, or safety of Workshop, our users,
              employees, members of the public, or our Services.
            </chakra.span>
          </chakra.li>
        </chakra.ul>
        <P>
          <chakra.span>
            We may also disclose data about you to our auditors and legal
            advisors in order to assess our disclosure obligations and rights
            under this Privacy Policy.
          </chakra.span>
        </P>
        <H3>
          <chakra.span>During a change in control</chakra.span>
        </H3>
        <P>
          <chakra.span>
            If Workshop undergoes a business transaction like a merger,
            acquisition, corporate divestiture, or dissolution (including
            bankruptcy), or a sale of all or some of its assets, we may share,
            disclose, or transfer all of your data to the successor organisation
            during such transition or in contemplation of a transition
            (including during due diligence). If, in such instances, there are
            any changes in the terms of this policy, then these will be
            communicated publicly.
          </chakra.span>
        </P>
        <H3>
          <chakra.span>After aggregation/anonymisation</chakra.span>
        </H3>
        <P>
          <chakra.span>
            We can disclose or use aggregate or anonymised data for any purpose.
          </chakra.span>
        </P>
        <H3>
          <chakra.span>With your permission</chakra.span>
        </H3>
        <P>
          <chakra.span>
            With your consent, we may share data to third parties outside the
            scope of this Privacy Policy. This is unlikely to happen and you’ll
            be contacted directly in this case.
          </chakra.span>
        </P>
        <H1>
          <chakra.span>Retention</chakra.span>
        </H1>
        <P>
          <chakra.span>
            If you reside or are located in the UK or the EEA, we keep your
            Personally Identifiable Information for no longer than necessary for
            the purposes for which the Personally Identifiable Information is
            processed. The length of time we retain Personally Identifiable
            Information for depends on the purposes for which we collect and use
            it and/or as required to comply with applicable laws and to
            establish, exercise or defend our legal rights.
          </chakra.span>
        </P>
        <H1>
          <chakra.span>Security</chakra.span>
        </H1>
        <P>
          <chakra.b>
            We use appropriate security based on the type and sensitivity of
            data being stored. As with any internet-enabled system, there is
            always a risk of unauthorised access, so it’s important to protect
            your password and contact us if you suspect any unauthorised access
            to your account.
          </chakra.b>
        </P>
        <P>
          <chakra.span>
            Workshop takes appropriate security measures to protect against
            unauthorised access, alteration, disclosure, or destruction of your
            personal data that we collect and store. These measures vary based
            on the type and sensitivity of the data. Unfortunately, however, no
            system can be 100% secured, so we cannot guarantee that
            communications between you and Workshop, the Services, or any
            information provided to us in connection with the data we collect
            through the Services will be free from unauthorised access by third
            parties. Your password is an important part of our security system,
            and it is your responsibility to protect it. You should not share
            your password with any third party, and if you believe your password
            or account has been compromised, you should change it immediately
            and contact{' '}
          </chakra.span>
          <A href={`mailto:support${PLATFORM_EMAIL}`}>
            <chakra.span>
              <chakra.strong>{`support${PLATFORM_EMAIL}`}</chakra.strong>
            </chakra.span>
          </A>
          <chakra.span>
            &nbsp;with any concerns. If you believe Workshop has been subject to
            a data breach, report it to{' '}
          </chakra.span>
          <chakra.strong>
            <A
              href={`mailto:support${PLATFORM_EMAIL}`}
            >{`support${PLATFORM_EMAIL}`}</A>
          </chakra.strong>
          <chakra.span>.</chakra.span>
        </P>
        <H1>
          <chakra.span>Your preferences and rights</chakra.span>
        </H1>
        <P>
          <chakra.b>
            You have certain rights around the use of your data, including the
            ability to opt out of promotional emails, cookies, and collection of
            your data by certain analytics providers. You can update or
            terminate your account from within our Services, and can also
            contact us for individual rights requests about your personal data.
            Parents who believe we’ve unintentionally collected personal data
            about their underage child should contact us for help deleting that
            information.
          </chakra.b>
        </P>
        <P>
          <chakra.b>
            If you have any questions about your data, our use of it, or your
            rights, contact us at{' '}
            <A
              href={`mailto:privacy${PLATFORM_EMAIL}`}
            >{`privacy${PLATFORM_EMAIL}`}</A>
            .
          </chakra.b>
        </P>
        <H2>
          <chakra.span>Your choices about the use of your data</chakra.span>
        </H2>
        <P>
          <chakra.span>
            You can choose not to provide certain data to us, but you may not be
            able to use certain features of the Services.
          </chakra.span>
        </P>
        <H3>
          <chakra.span>Opt-out from Promotional Emails</chakra.span>
        </H3>
        <P>
          <chakra.span>
            To stop receiving promotional communications from us, you can opt
            out by using the unsubscribe mechanism in the promotional
            communication you receive. Note that regardless of your email
            preference settings, we will send you transactional and relationship
            messages regarding the Services, which may include administrative
            confirmations, order confirmations, important updates about the
            Services, and notices about our policies.
          </chakra.span>
        </P>
        <H3>
          <chakra.span>Opt-out from Cookies/Web Beacons</chakra.span>
        </H3>
        <P>
          <chakra.span>
            The browser or device you use may allow you to control cookies and
            other types of local data storage. Your device may also allow you to
            control whether location or other data is collected and shared.
          </chakra.span>
        </P>
        <P>
          <chakra.span>
            We will only use cookies with your permission. If you would like to
            opt-out from allowing us to use cookies:
          </chakra.span>
        </P>
        <chakra.ul>
          <chakra.li>
            <chakra.span>
              Toggle the “Allow Cookies” switch in your{' '}
              <A href={navRoutes.common.settings.path()}>settings</A>
            </chakra.span>
          </chakra.li>
        </chakra.ul>
        <H3>
          <chakra.span>Third-Party Tools/Analytics on the Website</chakra.span>
        </H3>
        <P>
          <chakra.span>
            To get information and control cookies used for tailored advertising
            from participating companies, see the consumer opt-out pages for the{' '}
          </chakra.span>
          <A href="https://www.networkadvertising.org/choices">
            Network Advertising Initiative
          </A>
          <chakra.span>
            &nbsp;and{' '}
            <A href="http://www.aboutads.info/choices/">
              Digital Advertising Alliance
            </A>
            , or if you’re located in the European Union, visit the{' '}
            <A href="http://www.youronlinechoices.eu">Your Online Choices</A>
            &nbsp;site. To opt out of Google’s display advertising or customise
            Google Display Network ads, visit the{' '}
            <A href="https://www.google.com/settings/ads">
              Google Ads Settings page
            </A>
          </chakra.span>
          <chakra.span>.</chakra.span>
        </P>
        <P>
          <chakra.span>
            To opt out of allowing Google Analytics to use your data for
            analytics, see the{' '}
            <A href="https://tools.google.com/dlpage/gaoptout">
              Google Analytics Opt-out Browser Add-on
            </A>
          </chakra.span>
          <chakra.span>.</chakra.span>
        </P>
        <H2>
          <chakra.span>
            Accessing, updating, and deleting your personally identifiable
            information
          </chakra.span>
        </H2>
        <P>
          <chakra.span>
            If you reside or are located in theUK or the EEA, you have the right
            to request that we:
          </chakra.span>
        </P>
        <chakra.ul>
          <chakra.li>
            <chakra.span>
              provide access to any Personally Identifiable Information we hold
              about you;
            </chakra.span>
          </chakra.li>
          <chakra.li>
            <chakra.span>
              prevent the processing of your Personally Identifiable Information
              for direct-marketing purposes;
            </chakra.span>
          </chakra.li>
          <chakra.li>
            <chakra.span>
              update any Personally Identifiable Information which is out of
              date or incorrect;
            </chakra.span>
          </chakra.li>
          <chakra.li>
            <chakra.span>
              delete any Personally Identifiable Information which we are
              holding about you;
            </chakra.span>
          </chakra.li>
          <chakra.li>
            <chakra.span>
              restrict the way that we process your Personally Identifiable
              Information;
            </chakra.span>
          </chakra.li>
          <chakra.li>
            <chakra.span>
              provide your Personally Identifiable Information to a third party
              provider of services; or
            </chakra.span>
          </chakra.li>
          <chakra.li>
            <chakra.span>
              provide you with a copy of any Personally Identifiable Information
              which we hold about you.
            </chakra.span>
          </chakra.li>
        </chakra.ul>
        <P>
          <chakra.span>
            You can access and update your personal data that Workshop collects
            and maintains as follows:
          </chakra.span>
        </P>
        <chakra.ul>
          <chakra.li>
            <chakra.span>
              To update data you provide directly, log in to your account and
              update your account at any time.
            </chakra.span>
          </chakra.li>
          <chakra.li>
            <chakra.span>To terminate your account:</chakra.span>
          </chakra.li>
          <chakra.ul>
            <chakra.li>
              <chakra.span>
                Visit your{' '}
                <A href={navRoutes.common.settings.path()}>settings page</A>
                &nbsp;and follow the instructions for deleting your account.
              </chakra.span>
            </chakra.li>
            <chakra.li>
              <chakra.span>
                {`If you have any issues terminating your account, email us at
                support${PLATFORM_EMAIL} or via our `}
                <A href={helpUrl}>Support Center</A>
              </chakra.span>
              <chakra.span>.</chakra.span>
            </chakra.li>
            <chakra.li>
              <chakra.span>
                Please note: even after your account is terminated, some or all
                of your data may still be visible to others, including without
                limitation any data that has been (a) copied, stored, or
                disseminated by other users (including in course comments); (b)
                shared or disseminated by you or others (including in your
                shared content); or (c) posted to a third-party platform. Even
                after your account is terminated, we retain your data for as
                long as we have a legitimate purpose to do so (and in accordance
                with applicable law), including to assist with legal
                obligations, resolve disputes, and enforce our agreements. We
                may retain and disclose such data pursuant to this Privacy
                Policy after your account has been terminated.
              </chakra.span>
            </chakra.li>
          </chakra.ul>
          <chakra.li>
            <chakra.span>
              To request access, correct, or delete your personal data,
              email&nbsp;
              <A href={`mailto:privacy${PLATFORM_EMAIL}`}>
                <chakra.span>{`privacy${PLATFORM_EMAIL}`}</chakra.span>
              </A>
            </chakra.span>
            <chakra.span>
              &nbsp;or write to us at Workshop Tech Solutions Ltd, 15 Dock
              Street, London, Greater London, E1 8JN. Please allow up to 30 days
              for a response. For your protection, we may require that the
              request be sent through the email address associated with your
              account, and we may need to verify your identity before
              implementing your request. Please note that we retain certain data
              where we have a lawful basis to do so, including for mandatory
              record-keeping and to complete transactions.
            </chakra.span>
          </chakra.li>
        </chakra.ul>
        <H2>
          <chakra.span>Our policy concerning children</chakra.span>
        </H2>
        <P>
          <chakra.span>
            We recognise the privacy interests of children and encourage parents
            and guardians to take an active role in their children’s online
            activities and interests. Children under 13 (or under 16 in the UK
            or the European Economic Area except for those over 13 who have
            parental consent to undertake courses specifically labelled for
            teenagers) should not use the Services. If we learn that we’ve
            collected personal data from a child under those ages, we will take
            reasonable steps to delete it.
          </chakra.span>
          <chakra.span>
            <chakra.br />{' '}
          </chakra.span>
          <chakra.span>
            <chakra.br />{' '}
          </chakra.span>
          <chakra.span>
            Parents who believe that Workshop may have collected personal data
            from a child under those ages can submit a request that it be
            removed to&nbsp;
            <A href={`mailto:privacy${PLATFORM_EMAIL}`}>
              <chakra.span>{`privacy${PLATFORM_EMAIL}`}</chakra.span>
            </A>
          </chakra.span>
          <chakra.span>.</chakra.span>
        </P>
        <H1>
          <chakra.span>Storage and processing</chakra.span>
        </H1>
        <chakra.ul>
          <chakra.li>
            <chakra.span>
              Your information collected through the Services may be stored and
              processed in the United Kingdom or any other country in which
              Workshop or its Service Providers maintain facilities.
            </chakra.span>
          </chakra.li>
          <chakra.li>
            <chakra.span>
              Workshop or its Service Providers may transfer information that we
              collect about you, including personal information across borders
              and from your country or jurisdiction to other countries or
              jurisdictions around the world. Information collected within the
              UK or the EEA may, for example, be transferred to and processed by
              third parties identified above, located in a country outside of
              these areas of jurisdiction, where you may have fewer legal rights
              in relation to your information. Please note that we may transfer
              information, including personal information, to a country and
              jurisdiction that does not have the same data protection laws as
              your jurisdiction.
            </chakra.span>
          </chakra.li>
          <chakra.li>
            <chakra.span>
              By registering for and using the Services you consent to the
              transfer of information to the United Kingdom or to any other
              country in which Workshop, or its Service Providers maintain
              facilities and the use and disclosure of information about you as
              described in this Privacy Policy.
            </chakra.span>
          </chakra.li>
        </chakra.ul>
        <H1>
          <chakra.span>Changes to the Privacy Policy</chakra.span>
        </H1>
        <P>
          <chakra.b>
            When we make a material change to this policy, we’ll notify users
            via email, in-product notice, or another mechanism required by law.
            Changes become effective the day they’re posted. Please contact us
            via email or postal mail with any questions, concerns, or disputes.
          </chakra.b>
        </P>
        <P>
          <chakra.span>
            From time to time, we may update this Privacy Policy. If we make any
            material change to it, we will notify you via email, through a
            notification posted on the Services, or as required by applicable
            law. We will also include a summary of the key changes. Unless
            stated otherwise, modifications will become effective on the day
            they are posted.
          </chakra.span>
        </P>
        <P>
          <chakra.span>
            As permitted by applicable law, if you continue to use the Services
            after the effective date of any change, then your access and/or use
            will be deemed an acceptance of (and agreement to follow and be
            bound by) the revised Privacy Policy. The revised Privacy Policy
            supersedes all previous Privacy Policies.
          </chakra.span>
        </P>
        <H1>
          <chakra.span>Cookie Policy</chakra.span>
        </H1>
        <P>
          <chakra.span>
            <A href={navRoutes.global.cookies.path()}>
              <chakra.span>Click here</chakra.span>
            </A>{' '}
            to read our cookie policy.
          </chakra.span>
        </P>
      </Box>
    </ScreenWrapper>
  );
};

export default PrivacyScreen;
