import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  useCurrentTeam,
  useCurrentTeamProfile,
  useHasRole,
  useUserProfile,
} from 'redux/selectors';
import { useHistory, useLocation } from 'react-router-dom';

import { Box, Card, Text, Button, LinkButton, MdIcon } from '@workshop/ui';

import { PLATFORM_DISPLAY_NAME } from 'constants/common';
import { getParamFromUrl, analytics } from 'utils';
import navRoutes from 'navigation/Routes';

import { organisationActions } from 'redux/actions/common';

import { ConfirmModal } from 'components/Common';
import { Loading } from 'components/Loading';

interface StripeConnectCardProps {}

const StripeConnectCard: React.FC<StripeConnectCardProps> = (props) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const currentTeam = useCurrentTeam();
  const org = useCurrentTeamProfile();
  const location = useLocation();
  const history = useHistory();
  const userProfile = useUserProfile();
  const isOwner = useHasRole('owner');

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const startParam = getParamFromUrl(location, 'start');

  const needsStripeAccount = org && !org.stripeAccountId;
  const needsToConnectStripeAccount =
    org && org.stripeAccountId && !org.stripeAccountActive;
  const hasActiveStripeAccount =
    org && org.stripeAccountId && org.stripeAccountActive;

  const hasCountry = !!userProfile.country;
  const hasDob = !!userProfile.dateOfBirth;

  const connectStripe = async (businessType?: 'individual' | 'company') => {
    setIsLoading(true);
    const res = await dispatch(
      organisationActions.connectStripe({
        businessType: businessType ? businessType : 'individual',
      })
    );
    if (!res?.error) {
      // @ts-ignore
      if (res?.payload?.redirect) {
        // @ts-ignore
        return window.open(res.payload.redirect, '_self');
      }
      // @ts-ignore
      if (res?.payload?.connected && currentTeam) {
        analytics.track('Payment Setup Completed');
        await dispatch(
          organisationActions.fetchOrganisationProfile(currentTeam)
        );
      }
    }
    setIsLoading(false);
  };

  const openDashboard = async () => {
    setIsLoading(true);
    const res = await dispatch(organisationActions.connectStripe({}));
    setIsLoading(false);
    if (!res?.error) {
      // @ts-ignore
      if (res?.payload?.redirect) {
        return window.open(
          // @ts-ignore
          res.payload.redirect,
          '_blank',
          'noopener,noreferrer'
        );
      }
      window.open(
        'https://dashboard.stripe.com/balance',
        '_blank',
        'noopener,noreferrer'
      );
    }
  };

  const userIsInBrazil = userProfile.country === 'BR';

  return (
    <>
      <ConfirmModal
        body="If you have a registered business and want your channel to be connected to your existing business bank account, select 'Connect my business', otherwise to connect to your personal bank account, select 'Connect as an individual'."
        helpText="Make sure to select the right option for you – this choice can't be reversed."
        btnColor="blue"
        btnLabel="Connect my business"
        btnColor2="blue"
        btnLabel2="Connect as an individual"
        title="Are you connecting as a registered business or as an individual?"
        isOpen={showConfirmModal}
        isLoading={false}
        onClose={() => setShowConfirmModal(false)}
        onClick={() => {
          setShowConfirmModal(false);
          connectStripe('company');
        }}
        onClick2={() => {
          setShowConfirmModal(false);
          connectStripe('individual');
        }}
        stackColumn
      />

      <Card mb={4} padding={12} textAlign="center">
        <Box maxW="650px" mx="auto">
          {hasActiveStripeAccount ? (
            <Box>
              <Text mb={6}>
                Your channel is successfully connected to Stripe.
              </Text>

              {startParam === 'true' && (
                <Box mb={4}>
                  <Button
                    onClick={() =>
                      history.push({
                        pathname: navRoutes.cms.classes.path(),
                        search: 'p=new',
                      })
                    }
                    icon="GroupAdd"
                  >
                    Start Selling Classes
                  </Button>
                </Box>
              )}

              <Box>
                <Button
                  variant="outline"
                  onClick={openDashboard}
                  icon="Payments"
                  rightIcon={<MdIcon name="OpenInNew" fontSize="xs" />}
                  colorScheme="green"
                  isLoading={isLoading}
                >
                  See Payments in Stripe
                </Button>
              </Box>
            </Box>
          ) : isOwner && needsToConnectStripeAccount && !userIsInBrazil ? (
            <Box>
              <Text mb={6}>
                {`Finish connecting your
            channel to Stripe to start selling classes on ${PLATFORM_DISPLAY_NAME}.`}
              </Text>
              <Button onClick={connectStripe} icon="Link" isLoading={isLoading}>
                Complete Connection
              </Button>
            </Box>
          ) : isOwner && needsStripeAccount && !userIsInBrazil ? (
            <Box>
              {hasCountry && hasDob ? (
                <>
                  <Text mb={6}>
                    {`We partner with Stripe to process payments. Connect your
            channel to Stripe below to start selling classes on ${PLATFORM_DISPLAY_NAME}.`}
                  </Text>
                  <Button
                    onClick={() => {
                      analytics.track('Payment Setup Started');
                      setShowConfirmModal(true);
                    }}
                    icon="AddLink"
                    isLoading={isLoading}
                  >
                    Connect to Stripe
                  </Button>
                </>
              ) : (
                <>
                  <Text mb={4}>
                    We partner with Stripe to process payments. To start selling
                    classes, you can connect your channel to Stripe.
                  </Text>
                  <Text mb={6} color="text.error">
                    {`To continue, please set your ${
                      !hasCountry && !hasDob
                        ? 'country and date of birth'
                        : !hasCountry
                        ? 'country'
                        : 'date of birth'
                    } in your profile settings.`}
                  </Text>
                  <LinkButton
                    to={navRoutes.common.settings.path()}
                    icon="Settings"
                    isLoading={isLoading}
                  >
                    Open Settings
                  </LinkButton>
                </>
              )}
            </Box>
          ) : userIsInBrazil ? (
            <Box color="text.muted">
              <Text mb={6}>
                In accordance with Steppit's regulatory and compliance policies,
                channels based in Brazil are unfortunately not authorized to
                sell classes on the platform due to local legal and operational
                restrictions.
              </Text>
              <Text>
                We recommend that Brazil-based channels upgrade to Pro to run
                private classes with manual enrolments, rather than selling
                directly on Steppit.
              </Text>
              <Text>
                If your channel is not based in Brazil, please email
                support@steppit.com for assistance.
              </Text>
            </Box>
          ) : !isOwner ? (
            <Text color="text.muted">
              We partner with Stripe to process payments. To start selling
              classes, your channel owner needs to visit this page and connect
              the channel to Stripe.
            </Text>
          ) : (
            <Loading />
          )}
        </Box>
      </Card>
    </>
  );
};

export default StripeConnectCard;
