// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
  ASSISTANT_TRAITS,
  ASSISTANT_TRAINING_PROGRESS,
  AssistantTraitSlug,
} from 'constants/assistant';

import { capitalize } from 'utils';
import { useWindowDimensions } from 'utils/hooks/useDimensions';

import { assistantActions, organisationActions } from 'redux/actions/common';
import { useCurrentTeam, useCurrentTeamProfile } from 'redux/selectors';

import {
  Box,
  Flex,
  Text,
  MdIcon,
  CircularProgress,
  Stack,
  Button,
  RadioGroup,
  Radio,
  Collapse,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
  Skeleton,
  chakra,
  useTheme,
  useColorModeValue,
} from '@workshop/ui';
import {
  StepsModal,
  ConfirmModal,
  LabelInput,
  LabelTextArea,
  ButtonGrid,
} from 'components/Common';

interface AssistantTrainingProps {
  trait: AssistantTraitSlug;
  baseTrainingComplete?: boolean;
  setBaseTrainingComplete?: (c: boolean) => void;
  onResetTraining?: () => void;
  onClickNextTrait?: () => void;
}

interface AssistantTrainingPopupProps {
  trait: AssistantTraitSlug | null;
  isOpen: boolean;
  onClose: () => void;
}

const ItemInput = ({
  id,
  index,
  onRemove,
  onChange,
  value,
  placeholder,
}: {
  id: number;
  index: number;
  onRemove: (id: number) => void;
  onChange: (title: string) => void;
  value: string;
  placeholder?: string;
}) => {
  return (
    <Flex w="100%" alignItems="center">
      <LabelInput
        id={`plan-step-${id}`}
        flex={1}
        label=""
        onChange={(e) => onChange(e.target.value)}
        value={value}
        placeholder={placeholder}
        noMargin
      />
      {index > 0 && (
        <Button
          icon="Remove"
          colorScheme="red"
          variant="outline"
          size="sm"
          w={8}
          ml={3}
          onClick={() => onRemove(id)}
        />
      )}
    </Flex>
  );
};

const ItemInputList = ({
  placeholder,
  placeholder2,
  onUpdate,
  slug,
}: {
  placeholder?: string;
  placeholder2?: string;
  onUpdate: (data: string[]) => void;
  slug: string;
}) => {
  const initialItem = {
    id: 0,
    value: '',
  };
  const [items, setItems] = useState([initialItem]);

  useEffect(() => {
    onUpdate(items.filter((i) => !!i.value).map((i) => i.value));
  }, [JSON.stringify(items)]);

  useEffect(() => {
    setItems([initialItem]);
  }, [slug]);

  return (
    <Stack w="100%">
      {items
        .sort((a, b) => a.id - b.id)
        .map((item, idx) => (
          <ItemInput
            key={`${slug}-item-input-${item.id}`}
            id={item.id}
            value={item.value}
            index={idx}
            onRemove={() =>
              setItems((prevItems) => [
                ...prevItems.filter((i) => i.id !== item.id),
              ])
            }
            onChange={(value) => {
              setItems((prevItems) => {
                const newItems = [
                  ...prevItems.filter((i) => i.id !== item.id),
                  { ...item, value },
                ];
                const maxId = Math.max(...prevItems.map((s) => s.id));
                if (item.id === maxId && prevItems.length < 10) {
                  if (value) {
                    return [...newItems, { ...initialItem, id: maxId + 1 }];
                  }
                }
                return newItems;
              });
            }}
            placeholder={idx === 0 ? placeholder : placeholder2 || placeholder}
          />
        ))}
    </Stack>
  );
};

const VoteItem = ({
  colorScheme,
  value,
  itemName,
  voteName,
  upVoteLabel,
  downVoteLabel,
  midVoteLabel,
  onUpdate,
  isLoading,
}: {
  colorScheme: string;
  value: string;
  itemName: string;
  voteName: string;
  upVoteLabel: string;
  downVoteLabel: string;
  midVoteLabel: string;
  onUpdate: (data: { [key: string]: string }) => void;
  isLoading?: boolean;
}) => {
  const [vote, setVote] = useState<string | null>(null);
  useEffect(() => {
    if (vote) {
      onUpdate({ [itemName]: value, [voteName]: vote });
    }
  }, [vote]);
  return (
    <Flex
      alignItems={{ base: 'normal', md: 'center' }}
      flexDirection={{ base: 'column', md: 'row' }}
    >
      <Flex
        flex={1}
        bg={
          vote === downVoteLabel
            ? 'background.error'
            : vote === midVoteLabel
            ? 'background.tint2'
            : vote === upVoteLabel
            ? 'background.success'
            : `${colorScheme}.50`
        }
        color={
          vote === downVoteLabel
            ? 'text.error'
            : vote === midVoteLabel
            ? 'text.muted'
            : vote === upVoteLabel
            ? 'text.success'
            : `${colorScheme}.700`
        }
        borderRadius="md"
        p={3}
        mr={{ base: 0, md: 4 }}
        mb={{ base: 3, md: 0 }}
      >
        <Skeleton w="100%" isLoaded={!isLoading}>
          {isLoading && <Flex w="100%" />}
          <Text>{value}</Text>
        </Skeleton>
      </Flex>
      <RadioGroup onChange={setVote}>
        <Stack
          spacing={4}
          direction="row"
          textAlign="center"
          mb={{ base: 3, md: 0 }}
        >
          <Radio
            value={downVoteLabel}
            flexDirection={{ base: 'column', sm: 'row', md: 'column' }}
            spacing={{ base: 0, sm: 2, md: 0 }}
            flex={1}
            colorScheme="red"
            isDisabled={isLoading}
          >
            <Text
              color={isLoading ? 'text.muted' : 'text.error'}
              fontSize="sm"
              mt={{ base: 1, sm: 0, md: 1 }}
            >
              {capitalize(downVoteLabel)}
            </Text>
          </Radio>
          <Radio
            value={midVoteLabel}
            flexDirection={{ base: 'column', sm: 'row', md: 'column' }}
            spacing={{ base: 0, sm: 2, md: 0 }}
            flex={1}
            colorScheme="gray"
            isDisabled={isLoading}
          >
            <Text
              color="text.muted"
              fontSize="sm"
              mt={{ base: 1, sm: 0, md: 1 }}
            >
              {capitalize(midVoteLabel)}
            </Text>
          </Radio>
          <Radio
            value={upVoteLabel}
            flexDirection={{ base: 'column', sm: 'row', md: 'column' }}
            spacing={{ base: 0, sm: 2, md: 0 }}
            flex={1}
            colorScheme="green"
            isDisabled={isLoading}
          >
            <Text
              color={isLoading ? 'text.muted' : 'text.success'}
              fontSize="sm"
              mt={{ base: 1, sm: 0, md: 1 }}
            >
              {capitalize(upVoteLabel)}
            </Text>
          </Radio>
        </Stack>
      </RadioGroup>
    </Flex>
  );
};

const GridTraining = ({
  options,
  multiple,
  onUpdate,
  isLoading,
  currentStepIndex,
}: {
  options: { slug: any; description: string }[];
  multiple?: boolean;
  onUpdate: (selections: {}[]) => void;
  isLoading: boolean;
  currentStepIndex: number;
}) => {
  const [selection, setSelection] = useState([]);
  useEffect(() => {
    onUpdate(
      selection.map((s) => {
        const selectedOption = options.find((o) => o.slug === s);
        if (selectedOption) {
          return {
            slug: selectedOption.slug,
            description: `${selectedOption.title}${
              selectedOption.description
                ? ` - ${selectedOption.description}`
                : ''
            }`,
          };
        }
      })
    );
  }, [JSON.stringify(selection)]);
  useEffect(() => {
    setSelection([]);
  }, [currentStepIndex]);
  return (
    <Skeleton mt={4} w="100%" isLoaded={!isLoading}>
      <ButtonGrid
        templateColumns="repeat(6, 1fr)"
        uniform
        condensed
        items={
          options?.map((i) => ({
            ...i,
            onClick: () => {
              if (multiple && !i.slug?.includes('none')) {
                if (selection.includes(i.slug)) {
                  setSelection(selection.filter((s) => s !== i.slug));
                } else {
                  setSelection([
                    ...selection.filter((s) => !s.includes('none')),
                    i.slug,
                  ]);
                }
              } else {
                setSelection([i.slug]);
              }
            },
            isActive: selection.includes(i.slug),
          })) || []
        }
      />
    </Skeleton>
  );
};

const ComparisonTraining = ({
  leftExample,
  rightExample,
  leftDescriptor,
  rightDescriptor,
  leftSlug,
  rightSlug,
  colorScheme,
  comparisonWord,
  onUpdate,
  isLoading,
}: {
  leftExample: string;
  rightExample: string;
  leftDescriptor: string;
  rightDescriptor: string;
  leftSlug: string;
  rightSlug: string;
  colorScheme: string;
  comparisonWord: string;
  onUpdate: (value: string) => void;
  isLoading: boolean;
}) => {
  const [sliderValue, setSliderValue] = useState(3);
  useEffect(() => {
    let value =
      sliderValue === 1
        ? `[{ toneSlug: "${leftSlug}", toneDescription: "${leftDescriptor}", scoreOutOf10: 10 }, { toneSlug: "${rightSlug}", toneDescription: "${rightDescriptor}", scoreOutOf10: 0 }]`
        : sliderValue === 2
        ? `[{ toneSlug: "${leftSlug}", toneDescription: "${leftDescriptor}", scoreOutOf10: 7 }, { toneSlug: "${rightSlug}", toneDescription: "${rightDescriptor}", scoreOutOf10: 3 }]`
        : sliderValue === 4
        ? `[{ toneSlug: "${leftSlug}", toneDescription: "${leftDescriptor}", scoreOutOf10: 3 }, { toneSlug: "${rightSlug}", toneDescription: "${rightDescriptor}", scoreOutOf10: 7 }]`
        : sliderValue === 5
        ? `[{ toneSlug: "${leftSlug}", toneDescription: "${leftDescriptor}", scoreOutOf10: 0 }, { toneSlug: "${rightSlug}", toneDescription: "${rightDescriptor}", scoreOutOf10: 10 }]`
        : `[{ toneSlug: "${leftSlug}", toneDescription: "${leftDescriptor}", scoreOutOf10: 5 }, { toneSlug: "${rightSlug}", toneDescription: "${rightDescriptor}", scoreOutOf10: 5 }]`;
    onUpdate(value);
  }, [sliderValue]);
  useEffect(() => {
    setSliderValue(3);
  }, [leftDescriptor]);
  const sliderLabelStyles = {
    mt: 4,
    width: { base: '34%', md: '15%' },
    ml: { base: '-17%', md: '-7.5%' },
  };
  const innerlabelStyles = {
    fontSize: 'xs',
  };
  const aColorScheme = 'blue';
  const bColorScheme = 'red';
  const sliderColor = isLoading
    ? 'gray.300'
    : sliderValue === 4
    ? `${bColorScheme}.50`
    : sliderValue === 5
    ? `${bColorScheme}.50`
    : `background.tint2`;
  const sliderTrackColor = isLoading
    ? 'gray.300'
    : sliderValue === 1
    ? `${aColorScheme}.50`
    : sliderValue === 2
    ? `${aColorScheme}.50`
    : `background.tint2`;

  const sliderThumbColor =
    sliderValue === 1
      ? `${aColorScheme}.400`
      : sliderValue === 2
      ? `${aColorScheme}.200`
      : sliderValue === 4
      ? `${bColorScheme}.200`
      : sliderValue === 5
      ? `${bColorScheme}.400`
      : `neutral.400`;
  return (
    <>
      <Stack direction={{ base: 'column', md: 'row' }} spacing={4}>
        <Flex
          bg={`${aColorScheme}.50`}
          color={`${aColorScheme}.700`}
          borderRadius="md"
          p={4}
          position="relative"
          flex={1}
        >
          <Flex
            position="absolute"
            bg={`${aColorScheme}.100`}
            borderRadius="full"
            alignItems="center"
            justifyContent="center"
            boxSize={6}
            top={-2}
            left={-2}
            fontWeight="bold"
            fontSize="sm"
          >
            <Text>A</Text>
          </Flex>
          <Skeleton w="100%" isLoaded={!isLoading}>
            <Text minH={isLoading ? '50px' : 0}>{leftExample}</Text>
          </Skeleton>
        </Flex>
        <Flex
          bg={`${bColorScheme}.50`}
          color={`${bColorScheme}.700`}
          borderRadius="md"
          p={4}
          position="relative"
          flex={1}
        >
          <Flex
            position="absolute"
            bg={`${bColorScheme}.100`}
            borderRadius="full"
            alignItems="center"
            justifyContent="center"
            boxSize={6}
            top={-2}
            left={-2}
            fontWeight="bold"
            fontSize="sm"
          >
            <Text>B</Text>
          </Flex>
          <Skeleton w="100%" isLoaded={!isLoading}>
            <Text minH={isLoading ? '50px' : 0}>{rightExample}</Text>
          </Skeleton>
        </Flex>
      </Stack>
      <Slider
        w={{ base: '80%', md: '90%' }}
        mx="auto"
        mt={4}
        mb={10}
        value={sliderValue}
        min={1}
        max={5}
        step={1}
        onChange={(v) => setSliderValue(v)}
        isDisabled={isLoading}
      >
        <SliderMark value={1} {...sliderLabelStyles}>
          <Text textAlign="center" {...innerlabelStyles}>
            <chakra.span fontWeight="bold" color={`${aColorScheme}.600`}>
              A
            </chakra.span>
            {` is much ${comparisonWord}`}
          </Text>
        </SliderMark>
        <SliderMark value={2} {...sliderLabelStyles}>
          <Text
            textAlign="center"
            {...innerlabelStyles}
            display={{ base: 'none', md: 'block' }}
          >
            <chakra.span fontWeight="bold" color={`${aColorScheme}.600`}>
              A
            </chakra.span>
            {` is a bit ${comparisonWord}`}
          </Text>
          <Text textAlign="center" display={{ base: 'block', md: 'none' }}>
            •
          </Text>
        </SliderMark>
        <SliderMark value={3} {...sliderLabelStyles}>
          <Text textAlign="center" {...innerlabelStyles}>
            No preference
          </Text>
        </SliderMark>
        <SliderMark value={4} {...sliderLabelStyles}>
          <Text
            textAlign="center"
            {...innerlabelStyles}
            display={{ base: 'none', md: 'block' }}
          >
            <chakra.span fontWeight="bold" color={`${bColorScheme}.600`}>
              B
            </chakra.span>
            {` is a bit ${comparisonWord}`}
          </Text>
          <Text textAlign="center" display={{ base: 'block', md: 'none' }}>
            •
          </Text>
        </SliderMark>
        <SliderMark value={5} {...sliderLabelStyles}>
          <Text textAlign="center" {...innerlabelStyles}>
            <chakra.span fontWeight="bold" color={`${bColorScheme}.600`}>
              B
            </chakra.span>
            {` is much ${comparisonWord}`}
          </Text>
        </SliderMark>
        <SliderTrack
          backgroundColor={sliderTrackColor}
          transition="backgroundColor 0.3s"
        >
          <Box position="relative" right={10} />
          <SliderFilledTrack
            backgroundColor={sliderColor}
            transition="backgroundColor 0.3s"
          />
        </SliderTrack>
        <SliderThumb
          boxSize={6}
          bg="background.default"
          borderWidth={2}
          borderColor={sliderThumbColor}
        />
      </Slider>
    </>
  );
};

const AssistantTraining: React.FC<AssistantTrainingProps> = ({
  trait,
  baseTrainingComplete,
  setBaseTrainingComplete,
  onResetTraining,
  onClickNextTrait,
}) => {
  const assistantTrait = ASSISTANT_TRAITS.find((t) => t.slug === trait);
  const trainingSteps = assistantTrait?.trainingSteps || [];
  const stepsRepeat = !!trainingSteps.find((s) => s.repeat);
  const maxSteps = stepsRepeat
    ? Object.keys(ASSISTANT_TRAINING_PROGRESS).length
    : trainingSteps.length;

  const channel = useCurrentTeamProfile();
  let progress = 0;
  let savedTrainedData = [];
  let trainingCount = 0;
  let stepNumber = 0;
  if (channel?.traits && trait in channel.traits) {
    trainingCount = channel.traits[trait].count;
    progress =
      maxSteps === Object.keys(ASSISTANT_TRAINING_PROGRESS).length
        ? ASSISTANT_TRAINING_PROGRESS[trainingCount] >= 0
          ? ASSISTANT_TRAINING_PROGRESS[trainingCount]
          : 100
        : Math.min((trainingCount / maxSteps) * 100, 100);
    savedTrainedData = channel.traits[trait].training;

    stepNumber = Math.min(trainingCount, trainingSteps.length - 1);
  }

  const [activeStep, setActiveStep] = useState(stepNumber);
  const [stepLoading, setStepLoading] = useState(false);
  const [trainingData, setTrainingData] = useState<any>([]);
  const [trainedData, setTrainedData] = useState(savedTrainedData || {});
  const [errorMessage, setErrorMessage] = useState('');
  const [showResetConfirmModal, setShowResetConfirmModal] = useState(false);
  const [stepIncomplete, setStepIncomplete] = useState(true);
  const dispatch = useDispatch();

  const theme = useTheme();
  const windowDimensions = useWindowDimensions();
  const isMobile = windowDimensions.width < parseInt(theme.breakpoints.md, 10);

  const textColor = useColorModeValue(
    `${assistantTrait?.colorScheme}.550`,
    `${assistantTrait?.colorScheme}.300`
  );

  useEffect(() => {
    setActiveStep(0);
    setTrainingData([]);
    setTrainedData({});
    setStepIncomplete(true);
  }, [trait]);

  useEffect(() => {
    if (
      activeStep >= Math.min(trainingSteps.length - 1, 2) &&
      !baseTrainingComplete &&
      setBaseTrainingComplete
    ) {
      setBaseTrainingComplete(true);
    }
    if (
      activeStep < Math.min(trainingSteps.length - 1, 2) &&
      baseTrainingComplete &&
      setBaseTrainingComplete
    ) {
      setBaseTrainingComplete(false);
    }
  }, [activeStep, baseTrainingComplete]);

  const currentTrainingStep =
    trainingSteps.find((s) => s.index === activeStep) ||
    trainingSteps.find((s) => s.index === 0);

  useEffect(() => {
    if (
      trainingData.length === 0 &&
      trainingCount < maxSteps - 1 &&
      !stepLoading
    ) {
      if (currentTrainingStep?.trainPrompt === 'train_skillset') {
        loadSkillset();
      }
      if (currentTrainingStep?.trainPrompt === 'train_tone') {
        loadTone();
      }
    }
  }, [trainingData.length, currentTrainingStep?.trainPrompt, stepLoading]);

  useEffect(() => {
    if (channel && channel.traits && channel.traits[trait]) {
      if (
        'training' in channel.traits[trait] &&
        channel.traits[trait].training
      ) {
        const tData = channel.traits[trait].training;
        if (
          tData &&
          !tData[`step${trainingCount}`] &&
          trainingCount < maxSteps - 1
        ) {
          setTrainedData({
            ...tData,
            [`step${trainingCount}`]: {
              index: trainingCount,
              title: currentTrainingStep?.slug || '',
              value: '',
            },
          });
        } else {
          setTrainedData(tData);
        }
      }

      if ('count' in channel.traits[trait] && channel.traits[trait].count) {
        setActiveStep(
          Math.min(channel.traits[trait].count, trainingSteps.length - 1)
        );
      }
    }
  }, [JSON.stringify(channel?.traits), trait]);

  if (!assistantTrait || !channel) return null;

  const {
    slug,
    label,
    labelShort,
    colorScheme,
    icon,
    trainingTitle,
    trainingDescription,
  } = assistantTrait;

  if (!currentTrainingStep) return null;

  let currentComparison = null;
  let nextComparison = null;
  if ('comparisons' in currentTrainingStep) {
    currentComparison = currentTrainingStep.comparisons.find(
      (c) => c.index === trainingCount - 1
    );
    nextComparison = currentTrainingStep.comparisons.find(
      (c) => c.index === trainingCount
    );
  } else if ('nextComparison' in currentTrainingStep) {
    nextComparison = currentTrainingStep.nextComparison;
  }
  let gridOptions = [];
  if ('gridOptions' in currentTrainingStep) {
    gridOptions = currentTrainingStep.gridOptions;
    // Remove option on this specific step
    if (
      currentTrainingStep.slug === 'mySecondaryCharacteristic' &&
      trainedData
    ) {
      const optionToRemove = Object.values(trainedData).find(
        (d) => d.title === 'myPrimaryCharacteristic'
      )?.value;
      if (optionToRemove && optionToRemove[0]) {
        gridOptions = gridOptions.filter(
          (o) => o.slug !== optionToRemove[0].slug
        );
      }
    }
  }

  const titleProps = {
    fontWeight: 'extrabold',
    fontSize: { base: '3xl', md: '4xl' },
    lineHeight: 1.2,
  };

  const handleError = (res) => {
    if (res.payload && 'normalizedErrors' in res.payload) {
      if (!res.payload.normalizedErrors?.code) {
        setErrorMessage(res.payload.normalizedErrors?.message as string);
      }
    } else if (
      res.payload &&
      'name' in res.payload &&
      'message' in res.payload &&
      res.payload.name === 'RequestError' &&
      res.payload.message === 'Load failed'
    ) {
      // This error occurs on mobile web browsers when the browser
      // is closed while the response is generating
      setErrorMessage('Oops! Something went wrong, please try again.');
    }
  };

  const loadSkillsetTraining = async (generateNew: boolean = true) => {
    let skillset = [];
    Object.values(trainedData).forEach((step) => {
      skillset = [
        ...skillset,
        ...(step.value && Array.isArray(step.value)
          ? step.value.map((i) => {
              if (
                step.title === 'strongSkills' ||
                step.title === 'weakSkills'
              ) {
                return {
                  skill: i,
                  level: step.title.replace('Skills', ''),
                };
              } else {
                return i;
              }
            })
          : []),
      ];
    });
    if (generateNew) {
      const res = await dispatch(
        assistantActions.createResponse({
          type: 'train_skillset',
          skillset: JSON.stringify(skillset),
          wait: true,
        })
      );
      if (res.error) {
        handleError(res);
      } else if (res.payload && 'editedResponse' in res.payload) {
        const skillsetResponse = res.payload.editedResponse.skillset;
        const skillsList = Object.values(skillsetResponse).map((s) => s.skill);
        setTrainingData(skillsList.slice(0, 5));
      }
    }
    return skillset;
  };

  const loadSkillset = async () => {
    setStepLoading(true);
    await loadSkillsetTraining();
    setStepLoading(false);
  };

  const loadToneTraining = async (
    generateNew: boolean = true,
    comparison?: any
  ) => {
    let tonePreferences = [];
    if (comparison) {
      Object.values(trainedData).forEach((step) => {
        if (step) {
          tonePreferences = [
            ...tonePreferences,
            ...(step.title === 'generalTone'
              ? [
                  {
                    generalTone:
                      step.value &&
                      Array.isArray(step.value) &&
                      step.value.length > 0
                        ? step.value[0].description
                        : step.value,
                  },
                ]
              : step.title === 'example'
              ? [
                  {
                    exampleText: step.value,
                  },
                ]
              : [
                  {
                    preference: step.value,
                  },
                ]),
          ];
        }
      });
      if (generateNew) {
        const res = await dispatch(
          assistantActions.createResponse({
            type: 'train_tone',
            // toneSummary: JSON.stringify(tonePreferences),
            leftTone: comparison.left.description,
            rightTone: comparison.right.description,
            wait: true,
          })
        );
        if (res.error) {
          handleError(res);
        } else if (res.payload && 'editedResponse' in res.payload) {
          const leftExample = res.payload.editedResponse.leftExample;
          const rightExample = res.payload.editedResponse.rightExample;
          setTrainingData([{ leftExample, rightExample }]);
        }
      }
    }

    return tonePreferences;
  };

  const loadTone = async () => {
    setStepLoading(true);
    await loadToneTraining(true, currentComparison);
    setStepLoading(false);
  };

  const resetTraining = async () => {
    setActiveStep(0);
    setTrainedData({});
    setTrainingData([]);
    setStepIncomplete(true);
    setStepLoading(true);
    await dispatch(
      organisationActions.updateOrganisationTraining(channel.team, {
        // @ts-ignore
        traits: JSON.stringify({
          [trait]: {
            training: {},
            condensed: [],
          },
        }),
      })
    );
    setStepLoading(false);
    onResetTraining && onResetTraining();
  };

  const updateTraining = async (condensedTrait: any[]) => {
    return await dispatch(
      organisationActions.updateOrganisationTraining(channel.team, {
        // @ts-ignore
        traits: JSON.stringify({
          [trait]: {
            training: trainedData,
            ...(condensedTrait && condensedTrait.length > 0
              ? { condensed: condensedTrait }
              : {}),
            // TODO: initialised?
          },
        }),
      })
    );
  };

  const completeStep = async (generateNew: boolean) => {
    let condensedTrait = [];
    const nextStep = Math.min(activeStep + 1, trainingSteps.length - 1);
    setStepLoading(true);

    if (
      currentTrainingStep.trainPrompt === 'train_skillset' ||
      currentTrainingStep.nextTrainPrompt === 'train_skillset'
    ) {
      const skillset = await loadSkillsetTraining(generateNew);
      condensedTrait = skillset;
    } else if (
      currentTrainingStep.trainPrompt === 'train_tone' ||
      currentTrainingStep.nextTrainPrompt === 'train_tone'
    ) {
      const tone = await loadToneTraining(generateNew, nextComparison);
      condensedTrait = tone;
    } else {
      Object.values(trainedData).forEach((step) => {
        if (step && step.title && step.value) {
          condensedTrait = [
            ...condensedTrait,
            ...[
              {
                [step.title]:
                  currentTrainingStep.type === 'grid' &&
                  step.value &&
                  Array.isArray(step.value)
                    ? step.value.length > 1
                      ? step.value.map((v) => v.description)
                      : step.value.length === 1
                      ? step.value[0].description
                      : step.value
                    : step.value,
              },
            ],
          ];
        }
      });
    }

    if (generateNew) {
      setActiveStep(nextStep);
    }
    if (generateNew || trainedData[`step${trainingCount}`]?.value) {
      await updateTraining(condensedTrait);
    }
    setStepLoading(false);
    setStepIncomplete(true);
  };

  const voteOptions = currentTrainingStep?.voteOptions || trainingData;

  return (
    <>
      <Flex
        flexDirection="column"
        alignItems="center"
        textAlign="center"
        position="relative"
        sx={{ '.chakra-collapse': { width: '100%' } }}
      >
        {Boolean(progress > 0) && (
          <Button
            icon="RestartAlt"
            size="xs"
            secondary
            position="absolute"
            top={0}
            left={0}
            onClick={() => setShowResetConfirmModal(true)}
          >
            {`Reset ${labelShort}`}
          </Button>
        )}
        <Flex
          position="relative"
          // boxSize="image.lg"
          alignItems="center"
          justifyContent="center"
          zIndex={1}
          mt={2}
          mb={4}
        >
          <CircularProgress
            value={progress}
            // @ts-ignore
            size={16}
            thickness="8px"
            color={`${colorScheme}.400`}
            trackColor="background.tint2"
            capIsRound
            sx={{
              '& > div:first-child': {
                transitionProperty: 'width',
              },
            }}
          />

          <Flex
            position="absolute"
            bg={`${colorScheme}.100`}
            alignItems="center"
            justifyContent="center"
            borderRadius="full"
            boxSize={10}
          >
            <MdIcon name={icon} color={`${colorScheme}.500`} boxSize={6} />
          </Flex>
        </Flex>

        <Text {...titleProps} mb={2}>
          {trainingTitle}
        </Text>

        <Text color="text.muted" fontSize="lg" whiteSpace="break-spaces">
          {trainingDescription}
        </Text>

        <Collapse in={progress < 100}>
          <Flex
            borderWidth={2}
            borderColor="border.muted"
            p={4}
            borderRadius="md"
            flexDirection="column"
            textAlign="left"
            mt={6}
            w="100%"
            bg="background.default"
          >
            <Text color={textColor} fontWeight="semibold">
              {currentTrainingStep.intro}
            </Text>
            {currentTrainingStep.helpText ? (
              <Text color="text.muted" fontSize="sm" mt={2}>
                {currentTrainingStep.helpText}
              </Text>
            ) : null}
            {currentTrainingStep.type === 'textBox' ? (
              <Skeleton w="100%" isLoaded={!stepLoading} mt={4}>
                <LabelTextArea
                  id={`${slug}-textBox-${trainingCount}`}
                  name={`${slug}-textBox-${trainingCount}`}
                  label=""
                  placeholder={currentTrainingStep.placeholder}
                  // labelPosition="top"
                  // value={inviteMessage}
                  // onChange={(e) => setInviteMessage(e.target.value)}
                  minH="180px"
                  bg="background.default"
                  noMargin
                  maxLength={500}
                  helpText="Max. 500 characters"
                  onChange={(e) => {
                    if (e.target.value && stepIncomplete) {
                      setStepIncomplete(false);
                    } else if (!e.target.value && !stepIncomplete) {
                      setStepIncomplete(true);
                    }
                    setTrainedData({
                      ...trainedData,
                      [`step${trainingCount}`]: {
                        index: trainingCount,
                        title: currentTrainingStep.slug,
                        value: e.target.value,
                      },
                    });
                  }}
                />
              </Skeleton>
            ) : currentTrainingStep.type === 'textList' ? (
              <Flex mt={4}>
                <ItemInputList
                  slug={currentTrainingStep.slug}
                  placeholder={currentTrainingStep.placeholder}
                  placeholder2={currentTrainingStep.placeholder2}
                  onUpdate={(data) => {
                    if (data.length > 0 && stepIncomplete) {
                      setStepIncomplete(false);
                    } else if (data.length === 0 && !stepIncomplete) {
                      setStepIncomplete(true);
                    }
                    setTrainedData({
                      ...trainedData,
                      [`step${trainingCount}`]: {
                        index: trainingCount,
                        title: currentTrainingStep.slug,
                        value: data,
                      },
                    });
                  }}
                />
              </Flex>
            ) : currentTrainingStep.type === 'comparison' ? (
              <Flex flexDirection="column" mt={4}>
                <ComparisonTraining
                  leftExample={
                    trainingData.length > 0 &&
                    typeof trainingData[0] === 'object' &&
                    'leftExample' in trainingData[0] &&
                    trainingData[0].leftExample
                  }
                  rightExample={
                    trainingData.length > 0 &&
                    typeof trainingData[0] === 'object' &&
                    'rightExample' in trainingData[0] &&
                    trainingData[0].rightExample
                  }
                  leftDescriptor={
                    currentComparison ? currentComparison.left.description : ''
                  }
                  rightDescriptor={
                    currentComparison ? currentComparison.right.description : ''
                  }
                  leftSlug={
                    currentComparison ? currentComparison.left.slug : ''
                  }
                  rightSlug={
                    currentComparison ? currentComparison.right.slug : ''
                  }
                  colorScheme={colorScheme}
                  comparisonWord={currentTrainingStep.comparisonWord}
                  onUpdate={(data) => {
                    if (stepIncomplete) {
                      setStepIncomplete(false);
                    }
                    setTrainedData({
                      ...trainedData,
                      [`step${trainingCount}`]: {
                        index: trainingCount,
                        title: currentTrainingStep.slug,
                        value: data,
                      },
                    });
                  }}
                  isLoading={stepLoading}
                />
              </Flex>
            ) : currentTrainingStep.type === 'vote' ? (
              <Flex flexDirection="column" mt={4}>
                <Stack>
                  {stepLoading
                    ? ['1', '2', '3', '4', '5'].map((t) => (
                        <VoteItem
                          key={`loading-training-data-${t}`}
                          colorScheme={colorScheme}
                          value={t}
                          itemName={currentTrainingStep.voteItemName}
                          voteName={currentTrainingStep.voteDescriptor}
                          upVoteLabel={currentTrainingStep.voteHighWord}
                          downVoteLabel={currentTrainingStep.voteLowWord}
                          midVoteLabel={currentTrainingStep.voteMidWord}
                          isLoading
                        />
                      ))
                    : voteOptions.map((t) => (
                        <VoteItem
                          key={`training-data-${t}`}
                          colorScheme={colorScheme}
                          value={t}
                          itemName={currentTrainingStep.voteItemName}
                          voteName={currentTrainingStep.voteDescriptor}
                          upVoteLabel={currentTrainingStep.voteHighWord}
                          downVoteLabel={currentTrainingStep.voteLowWord}
                          midVoteLabel={currentTrainingStep.voteMidWord}
                          onUpdate={(data) => {
                            if (stepIncomplete) {
                              setStepIncomplete(false);
                            }
                            setTrainedData({
                              ...trainedData,
                              [`step${trainingCount}`]: {
                                index: trainingCount,
                                title: currentTrainingStep.slug,
                                value: [
                                  ...((Array.isArray(
                                    trainedData[`step${trainingCount}`]?.value
                                  ) &&
                                    trainedData[
                                      `step${trainingCount}`
                                    ].value.filter(
                                      (v) => v['skill'] !== data['skill']
                                    )) ||
                                    []),
                                  data,
                                ],
                              },
                            });
                          }}
                        />
                      ))}
                </Stack>
              </Flex>
            ) : currentTrainingStep.type === 'grid' ? (
              <GridTraining
                isLoading={stepLoading}
                currentStepIndex={currentTrainingStep.index}
                options={gridOptions}
                multiple={currentTrainingStep.multiple}
                onUpdate={(data) => {
                  if (data.length > 0 && stepIncomplete) {
                    setStepIncomplete(false);
                  } else if (data.length === 0 && !stepIncomplete) {
                    setStepIncomplete(true);
                  }
                  setTrainedData({
                    ...trainedData,
                    [`step${trainingCount}`]: {
                      index: trainingCount,
                      title: currentTrainingStep.slug,
                      value: data,
                    },
                  });
                }}
              />
            ) : null}
          </Flex>
          <Stack
            direction="row"
            flex={1}
            alignItems="center"
            justifyContent="center"
            mt={4}
          >
            {trainingSteps.length > 1 &&
              trainingSteps.map((s, idx) => (
                <Flex
                  key={`step-modal-indicator-${idx}`}
                  height={2}
                  width={idx === activeStep ? 8 : 2}
                  bg={
                    idx > activeStep ? 'background.tint2' : `${colorScheme}.500`
                  }
                  borderRadius="full"
                  transition="all 0.3s"
                />
              ))}
            {stepsRepeat && (
              <MdIcon
                name="ModelTraining"
                transition="color 0.3s"
                color={
                  currentTrainingStep.repeat
                    ? `${colorScheme}.500`
                    : 'background.tint2'
                }
              />
            )}
          </Stack>
        </Collapse>

        <Button
          w="100%"
          maxWidth="370px"
          mx="auto"
          mt={6}
          onClick={() => completeStep(true)}
          isLoading={stepLoading}
          isDisabled={
            progress >= 100 ||
            (stepIncomplete && !currentTrainingStep.canSkip) ||
            stepLoading
          }
          icon={
            progress >= 100
              ? 'Done'
              : baseTrainingComplete
              ? 'ModelTraining'
              : 'ArrowForward'
          }
          iconPosition={
            progress >= 100 || baseTrainingComplete ? 'left' : 'right'
          }
          size={isMobile ? 'md' : 'lg'}
          variant={
            progress >= 100 || baseTrainingComplete ? 'outline' : 'solid'
          }
        >
          {progress >= 100
            ? 'Training Complete'
            : maxSteps - trainingCount === 1
            ? 'Complete Training'
            : baseTrainingComplete
            ? 'Keep Training'
            : 'Next'}
        </Button>
        {errorMessage ? (
          <Box bg="background.error" px={4} py={2} borderRadius="md" mt={4}>
            <Text color="text.error" textAlign="center">
              {errorMessage}
            </Text>
          </Box>
        ) : null}
      </Flex>
      {onClickNextTrait && (
        <Button
          w="100%"
          maxWidth="370px"
          size={isMobile ? 'md' : 'lg'}
          icon={baseTrainingComplete ? 'ArrowForward' : undefined}
          iconPosition="right"
          mx="auto"
          variant={baseTrainingComplete ? 'solid' : 'ghost'}
          onClick={async () => {
            await completeStep(false);
            onClickNextTrait();
          }}
          isLoading={stepLoading}
          isDisabled={stepLoading}
          mt={3}
        >
          {baseTrainingComplete ? 'Next Trait' : 'Skip to next trait'}
        </Button>
      )}
      <ConfirmModal
        body={
          <>
            This will reset all of your training for{' '}
            <chakra.span fontWeight="bold">{label}</chakra.span>.
          </>
        }
        helpText="Make sure you're happy to start from scratch – this action can't be reversed."
        btnColor="blue"
        btnLabel="Yes, Reset Training"
        title="Are you sure?"
        isOpen={showResetConfirmModal}
        isLoading={false}
        onClose={() => setShowResetConfirmModal(false)}
        onClick={() => {
          setShowResetConfirmModal(false);
          resetTraining();
        }}
      />
    </>
  );
};

export const AssistantTrainingPopup: React.FC<AssistantTrainingPopupProps> = ({
  isOpen,
  onClose,
  trait,
}) => {
  const currentTeam = useCurrentTeam();
  const dispatch = useDispatch();
  useEffect(() => {
    if (currentTeam && isOpen) {
      dispatch(organisationActions.fetchOrganisationTraining(currentTeam));
    }
  }, [isOpen, currentTeam]);
  const steps = [
    {
      label: 'Traits',
      icon: <MdIcon name="TrendingUp" />,
      hideNextButton: true,
      content: (
        <>
          {trait ? (
            <AssistantTraining
              trait={trait}
              // setBaseTrainingComplete={() => setBaseTrainingComplete(true)}
              // baseTrainingComplete={baseTrainingComplete}
              // onResetTraining={() => {
              //   setBaseTrainingComplete(false);
              //   setAllTrainingComplete(false);
              // }}
            />
          ) : null}
        </>
      ),
    },
  ];
  return (
    <StepsModal
      heading=""
      isOpen={isOpen}
      onClose={onClose}
      onCompleteStep={async (stepIndex) => onClose()}
      steps={isOpen ? steps : []}
      forceHorizontalSteps
      hideStepLabels
      hideAllStepLabels
      hideStepList
      hideIndicators
      bigNext
      disablePrev
      verticalCenter={false}
      modalSize="xl"
    />
  );
};

export default AssistantTraining;
