import { RSAA, getJSON, RSAAAction } from 'redux-api-middleware';
import { camelizeKeys } from 'humps';
import get from 'lodash.get';
import { normalize, Schema } from 'normalizr';

import { Dispatch, GlobalState, ThunkAction } from 'types';
import { getCookie, setCookie } from 'utils/index';
import { errorToastMessage } from 'redux/actions/common/ui';
import { Auth } from 'redux/actionTypes/common';

import { APIErrorResponse, ToastMetaParams } from 'types/common';
import { normalizeErrors } from './normalizeErrors';
import { getToastMessage } from './getToastMessage';

export interface MetaType {
  [key: string]: any;
  // Define how to handle toast success/error messages
  // for this request
  toast?: ToastMetaParams;
  // Define whether to show a 404 page for the given pathname
  // in case this requests results in a 404 error
  pathname404?: string;
}
export interface CallAPI {
  endpoint: string;
  method?: string;
  types: string[];
  body?: Object;
  headers?: { [key: string]: string };
  credentials?: 'omit' | 'same-origin' | 'include';
  // Optional schema: used as the basis for normalization
  schema?: Schema;
  // Optional dot notation path of data to normalize: If provided,
  // will be used to set which data to normalize within our JSON.
  // By default, the JSON returned from the API is normalized at
  // its root. If a path is supplied, e.g. 'results' then 'json.results'
  // will be normalized
  path?: string;
  bailout?: Function | boolean;
  meta?: MetaType;
  fetchNextPage?: ((nextUrl: string) => Promise<any>) | null | undefined;
}

/**
 * Helper function to sit on top of `redux-api-middleware` and provide us
 * with a simple call signature when interacting with the API.
 *
 * callAPI({
 *  endpoint: 'test/',
 *  method: 'GET',
 *  types: [
 *    'GET_TEST_REQUEST',
 *    'GET_TEST_SUCCESS',
 *    'GET_TEST_FAILURE',
 *  ],
 * })
 * // => ThunkAction
 *
 *
 * callAPI({
 *  endpoint: 'test/',
 *  method: 'POST',
 *  types: [
 *    'POST_TEST_REQUEST',
 *    'POST_TEST_SUCCESS',
 *    'POST_TEST_FAILURE',
 *  ],
 *  body: decamelizeKeys(body),
 * })
 * // => ThunkAction
 *
 */
function callAPI<T>({
  endpoint,
  method = 'GET',
  types,
  body,
  headers,
  credentials = 'include',
  schema,
  path,
  bailout,
  meta,
  fetchNextPage,
}: CallAPI): ThunkAction<T> {
  // Make our actionTypes for redux-api-middleware
  const makeTypes = types
    .map((type) => {
      // If we have a request body, add it to the request
      if (type.includes('REQUEST')) {
        return {
          type,
          ...(body ? { payload: body } : {}),
          ...(meta ? { meta: { ...meta, toast: {} } } : {}),
        };
      }

      // If we have a schema, we want to normalize.
      if (type.includes('SUCCESS')) {
        const toast = getToastMessage(meta?.toast, method, true);
        return {
          type,
          payload: (action: RSAAAction, state: any, res: Response) => {
            if (
              type === Auth.FETCH_TOKEN_SUCCESS ||
              type === Auth.REFRESH_TOKEN_SUCCESS ||
              type === Auth.VERIFY_PW_RESET_KEY_SUCCESS ||
              type === Auth.RESET_PASSWORD_SUCCESS ||
              type === Auth.SIGNUP_SUCCESS
            ) {
              const csrfToken = res.headers.get('x-csrftoken');
              if (csrfToken) {
                setCookie('csrftoken', csrfToken, 364);
              }
            }
            return (
              getJSON(res)
                .then(async (res) => {
                  // Fetch the next page if api is paginated and has a next page url
                  res &&
                    res.next &&
                    fetchNextPage &&
                    // TODO: DRF returns 'next' url with incorrect protocol
                    (await fetchNextPage(
                      res.next.includes('localhost')
                        ? res.next
                        : res.next.replace('http://', 'https://')
                    ));
                  return res;
                })
                .then(camelizeKeys)
                // If a custom normalize function has been provided, use that, otherwise
                // default to using the supplied schema to normalize the JSON at the
                // top level.
                .then((json) => {
                  return schema
                    ? normalize(path ? get(json, path) : json, schema)
                    : json;
                })
            );
          },
          ...(meta ? { meta: { ...meta, toast } } : { meta: { toast } }),
        };
      }

      if (type.includes('FAILURE')) {
        const toast = getToastMessage(meta?.toast, method, false);
        return {
          type,
          payload: (action: RSAAAction, state: any, res: Response) =>
            getJSON(res).then((res: APIErrorResponse) => {
              // If there is an error present in the response, pass it through
              // our error normalizer and merge the normalized result back in
              // to the response.
              if (res.error) {
                const normalizedErrors = normalizeErrors(res.error);
                return { ...res, normalizedErrors };
              }
              return;
            }),
          ...(meta ? { meta: { ...meta, toast } } : { meta: { toast } }),
        };
      }

      return null;
    })
    .filter((a) => a);

  const finalHeaders: { [key: string]: string } = {
    'Content-Type': 'application/json',
    ...headers,
    'X-CSRFToken': getCookie('csrftoken'),
  };

  // For 'Content-Type':'multipart/form-data', the Browser need to calculate the Boundary & set the content-type itself
  // Also we do not want to stringify it as it is a FormData type
  const isFormData = body instanceof FormData;
  if (isFormData) delete finalHeaders['Content-Type'];

  return (dispatch, getState) =>
    // @ts-ignore
    dispatch({
      //@ts-ignore
      [RSAA]: {
        endpoint,
        method,
        headers: {
          ...finalHeaders,
        },
        types: makeTypes,
        credentials,
        ...(body ? { body: isFormData ? body : JSON.stringify(body) } : {}),
        bailout: bailout || false,
      },
    });
}

export default callAPI;

/**
 * Wrapper around `callAPI` to automatically add the users current `team` to
 * the URL. This should be used for all API calls which are scoped to a specific
 * team/organisation.
 */
export const callAPIWithTeam =
  <T>(params: CallAPI) =>
  async (dispatch: Dispatch, getState: () => GlobalState) => {
    const {
      organisation: { currentTeam },
    } = getState();

    if (!params.endpoint.includes('{team}')) {
      console.error('Invalid endpoint. No {team} literal found.');
    }

    // If the user does not have an associated team, display a generic error
    // message. If `callAPIWithTeam()` without a current team existing, then the
    // user has either ended up somewhere they aren't allowed, or we've tried to
    // call the API before the user's team has been loaded.
    if (!currentTeam) {
      dispatch(
        errorToastMessage('You are not authorized to perform this action')
      );
      return;
    }

    // Add the user's team to the API endpoint and then call `callAPI` to
    // execute the request.
    const parameters = {
      ...params,
      endpoint: params.endpoint.replace('{team}', `${currentTeam}`),
    };

    return dispatch(callAPI<T>(parameters));
  };
