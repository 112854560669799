import React, { useState } from 'react';
import { RxCookie } from 'react-icons/rx';
import {
  Box,
  Flex,
  Text,
  Button,
  Collapse,
  Switch,
  SimpleGrid,
  chakra,
} from '@workshop/ui';

import navRoutes from 'navigation/Routes';

import { PLATFORM_DISPLAY_NAME } from 'constants/common';

import { analytics, hooks } from 'utils';

interface CookieModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const CookieModal: React.FC<CookieModalProps> = ({ isOpen, onClose }) => {
  const [showOptions, setShowOptions] = useState(false);
  const [analyticsCookies, setAnalyticsCookies] = useState(false);
  const [marketingCookies, setMarketingCookies] = useState(false);

  const cookieChoice = localStorage.getItem('cookies');
  const currentRoute = hooks.useCurrentRoute();
  if (cookieChoice || currentRoute?.blockCookiePopup) return null;

  return (
    <Box position="fixed" bottom={0} left={0} right={0} zIndex={10000}>
      <Collapse in={isOpen}>
        <Box
          bg="background.defaultTransparent"
          backdropFilter="blur(10px)"
          borderTopWidth={1}
          p={4}
        >
          <Flex
            flexDirection={{ base: 'column', md: 'row' }}
            alignItems="center"
            textAlign={{ base: 'center', md: 'left' }}
            maxWidth="1400px"
            mx="auto"
          >
            <Flex
              color="text.muted"
              mr={{ base: 0, md: 4 }}
              mb={{ base: 4, md: 0 }}
            >
              <RxCookie size={48} />
            </Flex>
            <Box flex={1} mr={{ base: 0, md: 4 }} mb={{ base: 4, md: 0 }}>
              <Text mb={{ base: 2, md: 0 }}>
                {`${PLATFORM_DISPLAY_NAME} uses cookies to offer you a better experience.`}
              </Text>
              <Text>
                {`See our `}
                <chakra.a
                  href={navRoutes.global.cookies.path()}
                  target="_blank"
                  rel="noreferrer noopener"
                  // fontWeight="semibold"
                  // color="text.primary"
                  textDecoration="underline"
                  _focusVisible={{ outline: 'none' }}
                >
                  Cookie Policy
                </chakra.a>
                {' for details.'}
              </Text>
            </Box>
            {showOptions ? (
              <Button
                onClick={() => {
                  analytics.setOptOut(!analyticsCookies, !marketingCookies);
                  if (analyticsCookies && marketingCookies) {
                    localStorage.setItem('cookies', 'accept');
                  } else if (!analyticsCookies && !marketingCookies) {
                    localStorage.setItem('cookies', 'reject');
                  } else if (analyticsCookies && !marketingCookies) {
                    localStorage.setItem('cookies', 'analyticsOnly');
                  } else if (!analyticsCookies && marketingCookies) {
                    localStorage.setItem('cookies', 'marketingOnly');
                  }
                  onClose();
                  window.location.reload();
                }}
                width={{ base: '100%', md: 'auto' }}
                maxW="100px"
                mx="auto"
                size="sm"
              >
                Save
              </Button>
            ) : (
              <>
                <Button
                  variant="ghost"
                  colorScheme="neutral"
                  onClick={() => setShowOptions(true)}
                  mr={{ base: 0, md: 4 }}
                  mb={{ base: 2, md: 0 }}
                  size="sm"
                >
                  More Options
                </Button>
                <Button
                  variant="ghost"
                  colorScheme="neutral"
                  onClick={() => {
                    localStorage.setItem('cookies', 'reject');
                    analytics.setOptOut(true, true);
                    onClose();
                    window.location.reload();
                  }}
                  mr={{ base: 0, md: 4 }}
                  mb={{ base: 2, md: 0 }}
                  size="sm"
                >
                  Reject all
                </Button>
                <Button
                  onClick={() => {
                    localStorage.setItem('cookies', 'accept');
                    analytics.setOptOut(false, false);
                    onClose();
                    window.location.reload();
                  }}
                  width={{ base: '100%', md: 'auto' }}
                  colorScheme="green"
                  maxW="350px"
                  mx="auto"
                  icon="Done"
                  iconPosition="right"
                >
                  Accept all
                </Button>
              </>
            )}
          </Flex>
          {showOptions && (
            <SimpleGrid
              gap={3}
              columns={{ base: 1, md: 3 }}
              mt={4}
              maxWidth="1400px"
              mx="auto"
            >
              <Box
                bg="background.default"
                borderWidth={1}
                borderRadius="md"
                p={4}
              >
                <Flex alignItems="center">
                  <Text fontWeight="semibold" flex={1}>
                    Essential
                  </Text>
                  <Switch isDisabled defaultChecked />
                </Flex>
                <Text color="text.muted" fontSize="sm" mt={1}>
                  Essential for the site to function. Always On.
                </Text>
              </Box>
              {/* <Box bg="background.default" borderWidth={1} borderRadius="md" p={4}>
                <Flex alignItems="center">
                  <Text fontWeight="semibold" flex={1}>
                    Functional
                  </Text>
                  <Switch id="functionalCookies" />
                </Flex>
                <Text color="text.muted" fontSize="sm" mt={1}>
                  Used to remember preference selections and provide enhanced
                  features.
                </Text>
              </Box> */}
              <Box
                bg="background.default"
                borderWidth={1}
                borderRadius="md"
                p={4}
                onClick={() => setAnalyticsCookies(!analyticsCookies)}
              >
                <Flex alignItems="center">
                  <Text fontWeight="semibold" flex={1}>
                    Analytics
                  </Text>
                  <Switch pointerEvents="none" isChecked={analyticsCookies} />
                </Flex>
                <Text color="text.muted" fontSize="sm" mt={1}>
                  Used to measure usage and improve your experience.
                </Text>
              </Box>
              <Box
                bg="background.default"
                borderWidth={1}
                borderRadius="md"
                p={4}
                onClick={() => setMarketingCookies(!marketingCookies)}
              >
                <Flex alignItems="center">
                  <Text fontWeight="semibold" flex={1}>
                    Marketing
                  </Text>
                  <Switch pointerEvents="none" isChecked={marketingCookies} />
                </Flex>
                <Text color="text.muted" fontSize="sm" mt={1}>
                  Used for targeted advertising.
                </Text>
              </Box>
            </SimpleGrid>
          )}
        </Box>
      </Collapse>
    </Box>
  );
};

export default CookieModal;
