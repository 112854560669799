// TODO: This could be pulled in from the backend
export const COUNTRY_OPTIONS: { [key: string]: string } = {
  GB1: 'United Kingdom',
  US1: 'United States',
  AU1: 'Australia',
  CA1: 'Canada',
  '-': '-',
  AF: 'Afghanistan',
  AX: 'Aland Islands',
  AL: 'Albania',
  DZ: 'Algeria',
  AS: 'American Samoa',
  AD: 'Andorra',
  AO: 'Angola',
  AI: 'Anguilla',
  AQ: 'Antarctica',
  AG: 'Antigua and Barbuda',
  AR: 'Argentina',
  AM: 'Armenia',
  AW: 'Aruba',
  AU: 'Australia',
  AT: 'Austria',
  AZ: 'Azerbaijan',
  BS: 'Bahamas',
  BH: 'Bahrain',
  BD: 'Bangladesh',
  BB: 'Barbados',
  BY: 'Belarus',
  BE: 'Belgium',
  BZ: 'Belize',
  BJ: 'Benin',
  BM: 'Bermuda',
  BT: 'Bhutan',
  BO: 'Bolivia',
  BA: 'Bosnia and Herzegovina',
  BW: 'Botswana',
  BV: 'Bouvet Island',
  BR: 'Brazil',
  IO: 'British Indian Ocean Territory',
  BN: 'Brunei Darussalam',
  BG: 'Bulgaria',
  BF: 'Burkina Faso',
  BI: 'Burundi',
  KH: 'Cambodia',
  CM: 'Cameroon',
  CA: 'Canada',
  CV: 'Cape Verde',
  KY: 'Cayman Islands',
  CF: 'Central African Republic',
  TD: 'Chad',
  CL: 'Chile',
  CN: 'China',
  CX: 'Christmas Island',
  CC: 'Cocos (Keeling) Islands',
  CO: 'Colombia',
  KM: 'Comoros',
  CG: 'Congo',
  CD: 'Congo, The Democratic Republic of the',
  CK: 'Cook Islands',
  CR: 'Costa Rica',
  CI: "Cote D'Ivoire",
  HR: 'Croatia',
  CU: 'Cuba',
  CY: 'Cyprus',
  CZ: 'Czech Republic',
  DK: 'Denmark',
  DJ: 'Djibouti',
  DM: 'Dominica',
  DO: 'Dominican Republic',
  EC: 'Ecuador',
  EG: 'Egypt',
  SV: 'El Salvador',
  GQ: 'Equatorial Guinea',
  ER: 'Eritrea',
  EE: 'Estonia',
  ET: 'Ethiopia',
  FK: 'Falkland Islands (Malvinas)',
  FO: 'Faroe Islands',
  FJ: 'Fiji',
  FI: 'Finland',
  FR: 'France',
  GF: 'French Guiana',
  PF: 'French Polynesia',
  TF: 'French Southern Territories',
  GA: 'Gabon',
  GM: 'Gambia',
  GE: 'Georgia',
  DE: 'Germany',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GR: 'Greece',
  GL: 'Greenland',
  GD: 'Grenada',
  GP: 'Guadeloupe',
  GU: 'Guam',
  GT: 'Guatemala',
  GG: 'Guernsey',
  GN: 'Guinea',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HT: 'Haiti',
  HM: 'Heard Island and Mcdonald Islands',
  VA: 'Holy See (Vatican City State)',
  HN: 'Honduras',
  HK: 'Hong Kong',
  HU: 'Hungary',
  IS: 'Iceland',
  IN: 'India',
  ID: 'Indonesia',
  IR: 'Iran, Islamic Republic Of',
  IQ: 'Iraq',
  IE: 'Ireland',
  IM: 'Isle of Man',
  IL: 'Israel',
  IT: 'Italy',
  JM: 'Jamaica',
  JP: 'Japan',
  JE: 'Jersey',
  JO: 'Jordan',
  KZ: 'Kazakhstan',
  KE: 'Kenya',
  KI: 'Kiribati',
  KP: "Korea, Democratic People'S Republic of",
  KR: 'Korea, Republic of',
  KW: 'Kuwait',
  KG: 'Kyrgyzstan',
  LA: "Lao People'S Democratic Republic",
  LV: 'Latvia',
  LB: 'Lebanon',
  LS: 'Lesotho',
  LR: 'Liberia',
  LY: 'Libyan Arab Jamahiriya',
  LI: 'Liechtenstein',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  MO: 'Macao',
  MK: 'Macedonia, The Former Yugoslav Republic of',
  MG: 'Madagascar',
  MW: 'Malawi',
  MY: 'Malaysia',
  MV: 'Maldives',
  ML: 'Mali',
  MT: 'Malta',
  MH: 'Marshall Islands',
  MQ: 'Martinique',
  MR: 'Mauritania',
  MU: 'Mauritius',
  YT: 'Mayotte',
  MX: 'Mexico',
  FM: 'Micronesia, Federated States of',
  MD: 'Moldova, Republic of',
  MC: 'Monaco',
  MN: 'Mongolia',
  MS: 'Montserrat',
  MA: 'Morocco',
  MZ: 'Mozambique',
  MM: 'Myanmar',
  NA: 'Namibia',
  NR: 'Nauru',
  NP: 'Nepal',
  NL: 'Netherlands',
  NC: 'New Caledonia',
  NZ: 'New Zealand',
  NI: 'Nicaragua',
  NE: 'Niger',
  NG: 'Nigeria',
  NU: 'Niue',
  NF: 'Norfolk Island',
  MP: 'Northern Mariana Islands',
  NO: 'Norway',
  OM: 'Oman',
  PK: 'Pakistan',
  PW: 'Palau',
  PS: 'Palestinian Territory, Occupied',
  PA: 'Panama',
  PG: 'Papua New Guinea',
  PY: 'Paraguay',
  PE: 'Peru',
  PH: 'Philippines',
  PN: 'Pitcairn',
  PL: 'Poland',
  PT: 'Portugal',
  PR: 'Puerto Rico',
  QA: 'Qatar',
  RE: 'Reunion',
  RO: 'Romania',
  RU: 'Russian Federation',
  RW: 'RWANDA',
  SH: 'Saint Helena',
  KN: 'Saint Kitts and Nevis',
  LC: 'Saint Lucia',
  PM: 'Saint Pierre and Miquelon',
  VC: 'Saint Vincent and the Grenadines',
  WS: 'Samoa',
  SM: 'San Marino',
  ST: 'Sao Tome and Principe',
  SA: 'Saudi Arabia',
  SN: 'Senegal',
  SC: 'Seychelles',
  SL: 'Sierra Leone',
  SG: 'Singapore',
  SK: 'Slovakia',
  SI: 'Slovenia',
  SB: 'Solomon Islands',
  SO: 'Somalia',
  ZA: 'South Africa',
  GS: 'South Georgia and the South Sandwich Islands',
  ES: 'Spain',
  LK: 'Sri Lanka',
  SD: 'Sudan',
  SR: 'Suriname',
  SJ: 'Svalbard and Jan Mayen',
  SZ: 'Swaziland',
  SE: 'Sweden',
  CH: 'Switzerland',
  SY: 'Syrian Arab Republic',
  TW: 'Taiwan, Province of China',
  TJ: 'Tajikistan',
  TZ: 'Tanzania, United Republic of',
  TH: 'Thailand',
  TL: 'Timor-Leste',
  TG: 'Togo',
  TK: 'Tokelau',
  TO: 'Tonga',
  TT: 'Trinidad and Tobago',
  TN: 'Tunisia',
  TR: 'Turkey',
  TM: 'Turkmenistan',
  TC: 'Turks and Caicos Islands',
  TV: 'Tuvalu',
  UG: 'Uganda',
  UA: 'Ukraine',
  AE: 'United Arab Emirates',
  GB: 'United Kingdom',
  US: 'United States',
  UM: 'United States Minor Outlying Islands',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VU: 'Vanuatu',
  VE: 'Venezuela',
  VN: 'Viet Nam',
  VG: 'Virgin Islands, British',
  VI: 'Virgin Islands, U.S.',
  WF: 'Wallis and Futuna',
  EH: 'Western Sahara',
  YE: 'Yemen',
  ZM: 'Zambia',
  ZW: 'Zimbabwe',
} as const;

// TODO: Could be pulled in from the backend
export const COUPON_TYPES = {
  monetary: 'monetary',
  percentage: 'percentage',
  token_uni_direction: 'token_uni_direction',
  token_bi_direction: 'token_bi_direction',
  user_referral: 'user_referral',
  workshop: 'workshop',
} as const;

export const PERSONAS = {
  creator: 'Content Creator – my learners are my audience/followers',
  coach: 'Coach – my learners are my upcoming/existing clients',
  educator: 'Educator – my learners are my students at school/college',
  brand: 'Brand – my learners are my customers/product users',
  training: 'Internal Training – my learners are my employees/colleagues',
  general: 'Other',
} as const;

export const PERSONAS_EDIT = {
  general: 'General',
  creator: 'Content Creator – my learners are my audience/followers',
  coach: 'Coach – my learners are my upcoming/existing clients',
  educator: 'Educator – my learners are my students at school/college',
  brand: 'Brand – my learners are my customers/product users',
  training: 'Internal Training – my learners are my employees/colleagues',
} as const;

// Multi-currency pricing matrix - updated January 2024
export const PRICING_MATRIX = {
  1: {
    GBP: '5',
    USD: '5',
    EUR: '5',
    CAD: '10',
    AUD: '10',
    DKK: '40',
    NOK: '50',
    SEK: '50',
    HKD: '50',
    SGD: '10',
    NZD: '10',
    CZK: '100',
    HUF: '2000',
    PLN: '20',
  },
  2: {
    GBP: '10',
    USD: '10',
    EUR: '10',
    CAD: '15',
    AUD: '20',
    DKK: '80',
    NOK: '150',
    SEK: '150',
    HKD: '100',
    SGD: '15',
    NZD: '20',
    CZK: '300',
    HUF: '4000',
    PLN: '60',
  },
  3: {
    GBP: '15',
    USD: '15',
    EUR: '15',
    CAD: '25',
    AUD: '30',
    DKK: '120',
    NOK: '200',
    SEK: '200',
    HKD: '150',
    SGD: '25',
    NZD: '30',
    CZK: '400',
    HUF: '6000',
    PLN: '80',
  },
  4: {
    GBP: '20',
    USD: '20',
    EUR: '20',
    CAD: '35',
    AUD: '35',
    DKK: '160',
    NOK: '250',
    SEK: '250',
    HKD: '200',
    SGD: '35',
    NZD: '40',
    CZK: '500',
    HUF: '9000',
    PLN: '100',
  },
  5: {
    GBP: '25',
    USD: '25',
    EUR: '25',
    CAD: '40',
    AUD: '45',
    DKK: '220',
    NOK: '300',
    SEK: '300',
    HKD: '250',
    SGD: '40',
    NZD: '50',
    CZK: '700',
    HUF: '11000',
    PLN: '140',
  },
  6: {
    GBP: '30',
    USD: '35',
    EUR: '35',
    CAD: '50',
    AUD: '55',
    DKK: '260',
    NOK: '400',
    SEK: '400',
    HKD: '300',
    SGD: '50',
    NZD: '60',
    CZK: '800',
    HUF: '13000',
    PLN: '160',
  },
  7: {
    GBP: '35',
    USD: '45',
    EUR: '40',
    CAD: '60',
    AUD: '65',
    DKK: '300',
    NOK: '450',
    SEK: '450',
    HKD: '350',
    SGD: '60',
    NZD: '70',
    CZK: '900',
    HUF: '15000',
    PLN: '180',
  },
  8: {
    GBP: '40',
    USD: '50',
    EUR: '45',
    CAD: '65',
    AUD: '75',
    DKK: '340',
    NOK: '500',
    SEK: '500',
    HKD: '350',
    SGD: '65',
    NZD: '80',
    CZK: '1100',
    HUF: '17000',
    PLN: '220',
  },
  9: {
    GBP: '45',
    USD: '55',
    EUR: '55',
    CAD: '75',
    AUD: '85',
    DKK: '380',
    NOK: '550',
    SEK: '550',
    HKD: '400',
    SGD: '75',
    NZD: '90',
    CZK: '1200',
    HUF: '19000',
    PLN: '240',
  },
  10: {
    GBP: '50',
    USD: '60',
    EUR: '60',
    CAD: '80',
    AUD: '90',
    DKK: '440',
    NOK: '600',
    SEK: '600',
    HKD: '500',
    SGD: '80',
    NZD: '100',
    CZK: '1400',
    HUF: '22000',
    PLN: '280',
  },
  11: {
    GBP: '60',
    USD: '70',
    EUR: '70',
    CAD: '100',
    AUD: '110',
    DKK: '520',
    NOK: '800',
    SEK: '800',
    HKD: '600',
    SGD: '100',
    NZD: '120',
    CZK: '1600',
    HUF: '26000',
    PLN: '320',
  },
  12: {
    GBP: '70',
    USD: '90',
    EUR: '80',
    CAD: '120',
    AUD: '130',
    DKK: '600',
    NOK: '900',
    SEK: '900',
    HKD: '700',
    SGD: '120',
    NZD: '140',
    CZK: '1800',
    HUF: '30000',
    PLN: '360',
  },
  13: {
    GBP: '80',
    USD: '100',
    EUR: '90',
    CAD: '130',
    AUD: '150',
    DKK: '680',
    NOK: '1000',
    SEK: '1000',
    HKD: '700',
    SGD: '130',
    NZD: '160',
    CZK: '2200',
    HUF: '34000',
    PLN: '440',
  },
  14: {
    GBP: '90',
    USD: '110',
    EUR: '110',
    CAD: '150',
    AUD: '170',
    DKK: '760',
    NOK: '1100',
    SEK: '1100',
    HKD: '800',
    SGD: '150',
    NZD: '180',
    CZK: '2400',
    HUF: '38000',
    PLN: '480',
  },
  15: {
    GBP: '100',
    USD: '120',
    EUR: '120',
    CAD: '160',
    AUD: '180',
    DKK: '880',
    NOK: '1200',
    SEK: '1200',
    HKD: '1000',
    SGD: '160',
    NZD: '200',
    CZK: '2800',
    HUF: '44000',
    PLN: '560',
  },
  16: {
    GBP: '120',
    USD: '140',
    EUR: '140',
    CAD: '200',
    AUD: '220',
    DKK: '1040',
    NOK: '1600',
    SEK: '1600',
    HKD: '1200',
    SGD: '200',
    NZD: '240',
    CZK: '3200',
    HUF: '52000',
    PLN: '640',
  },
  17: {
    GBP: '140',
    USD: '180',
    EUR: '160',
    CAD: '240',
    AUD: '260',
    DKK: '1200',
    NOK: '1800',
    SEK: '1800',
    HKD: '1400',
    SGD: '240',
    NZD: '280',
    CZK: '3600',
    HUF: '60000',
    PLN: '720',
  },
  18: {
    GBP: '160',
    USD: '200',
    EUR: '180',
    CAD: '260',
    AUD: '300',
    DKK: '1360',
    NOK: '2000',
    SEK: '2000',
    HKD: '1400',
    SGD: '260',
    NZD: '320',
    CZK: '4400',
    HUF: '68000',
    PLN: '880',
  },
  19: {
    GBP: '180',
    USD: '220',
    EUR: '220',
    CAD: '300',
    AUD: '340',
    DKK: '1520',
    NOK: '2200',
    SEK: '2200',
    HKD: '1600',
    SGD: '300',
    NZD: '360',
    CZK: '4800',
    HUF: '76000',
    PLN: '960',
  },
  20: {
    GBP: '200',
    USD: '240',
    EUR: '240',
    CAD: '340',
    AUD: '360',
    DKK: '1680',
    NOK: '2600',
    SEK: '2600',
    HKD: '1800',
    SGD: '340',
    NZD: '400',
    CZK: '5200',
    HUF: '84000',
    PLN: '1040',
  },
  21: {
    GBP: '220',
    USD: '280',
    EUR: '260',
    CAD: '360',
    AUD: '400',
    DKK: '1840',
    NOK: '2800',
    SEK: '2800',
    HKD: '2000',
    SGD: '360',
    NZD: '440',
    CZK: '6000',
    HUF: '92000',
    PLN: '1200',
  },
  22: {
    GBP: '240',
    USD: '300',
    EUR: '280',
    CAD: '400',
    AUD: '440',
    DKK: '2000',
    NOK: '3000',
    SEK: '3000',
    HKD: '2200',
    SGD: '400',
    NZD: '480',
    CZK: '6400',
    HUF: '104000',
    PLN: '1280',
  },
  23: {
    GBP: '260',
    USD: '320',
    EUR: '300',
    CAD: '440',
    AUD: '480',
    DKK: '2160',
    NOK: '3200',
    SEK: '3400',
    HKD: '2400',
    SGD: '420',
    NZD: '520',
    CZK: '6800',
    HUF: '112000',
    PLN: '1360',
  },
  24: {
    GBP: '280',
    USD: '340',
    EUR: '320',
    CAD: '480',
    AUD: '520',
    DKK: '2320',
    NOK: '3600',
    SEK: '3600',
    HKD: '2600',
    SGD: '460',
    NZD: '560',
    CZK: '7600',
    HUF: '120000',
    PLN: '1520',
  },
  25: {
    GBP: '300',
    USD: '360',
    EUR: '360',
    CAD: '500',
    AUD: '560',
    DKK: '2560',
    NOK: '3800',
    SEK: '3800',
    HKD: '2800',
    SGD: '500',
    NZD: '600',
    CZK: '8000',
    HUF: '128000',
    PLN: '1600',
  },
  26: {
    GBP: '320',
    USD: '400',
    EUR: '380',
    CAD: '540',
    AUD: '580',
    DKK: '2720',
    NOK: '4000',
    SEK: '4000',
    HKD: '3000',
    SGD: '520',
    NZD: '640',
    CZK: '8400',
    HUF: '136000',
    PLN: '1680',
  },
  27: {
    GBP: '340',
    USD: '420',
    EUR: '400',
    CAD: '580',
    AUD: '620',
    DKK: '2880',
    NOK: '4400',
    SEK: '4400',
    HKD: '3200',
    SGD: '560',
    NZD: '680',
    CZK: '9200',
    HUF: '144000',
    PLN: '1840',
  },
  28: {
    GBP: '360',
    USD: '440',
    EUR: '420',
    CAD: '600',
    AUD: '660',
    DKK: '3040',
    NOK: '4600',
    SEK: '4600',
    HKD: '3400',
    SGD: '600',
    NZD: '720',
    CZK: '9600',
    HUF: '152000',
    PLN: '1920',
  },
  29: {
    GBP: '380',
    USD: '460',
    EUR: '440',
    CAD: '640',
    AUD: '700',
    DKK: '3200',
    NOK: '4800',
    SEK: '4800',
    HKD: '3600',
    SGD: '620',
    NZD: '760',
    CZK: '10000',
    HUF: '164000',
    PLN: '2000',
  },
  30: {
    GBP: '400',
    USD: '500',
    EUR: '460',
    CAD: '680',
    AUD: '740',
    DKK: '3360',
    NOK: '5000',
    SEK: '5200',
    HKD: '3800',
    SGD: '660',
    NZD: '800',
    CZK: '10800',
    HUF: '172000',
    PLN: '2160',
  },
  31: {
    GBP: '420',
    USD: '520',
    EUR: '500',
    CAD: '700',
    AUD: '780',
    DKK: '3520',
    NOK: '5400',
    SEK: '5400',
    HKD: '4000',
    SGD: '700',
    NZD: '840',
    CZK: '11200',
    HUF: '180000',
    PLN: '2240',
  },
  32: {
    GBP: '440',
    USD: '540',
    EUR: '520',
    CAD: '740',
    AUD: '800',
    DKK: '3680',
    NOK: '5600',
    SEK: '5600',
    HKD: '4200',
    SGD: '720',
    NZD: '880',
    CZK: '11600',
    HUF: '188000',
    PLN: '2320',
  },
  33: {
    GBP: '460',
    USD: '560',
    EUR: '540',
    CAD: '780',
    AUD: '840',
    DKK: '3840',
    NOK: '5800',
    SEK: '5800',
    HKD: '4200',
    SGD: '760',
    NZD: '920',
    CZK: '12400',
    HUF: '196000',
    PLN: '2480',
  },
  34: {
    GBP: '480',
    USD: '600',
    EUR: '560',
    CAD: '800',
    AUD: '880',
    DKK: '4000',
    NOK: '6000',
    SEK: '6200',
    HKD: '4400',
    SGD: '800',
    NZD: '960',
    CZK: '12800',
    HUF: '204000',
    PLN: '2560',
  },
  35: {
    GBP: '500',
    USD: '600',
    EUR: '600',
    CAD: '850',
    AUD: '900',
    DKK: '4200',
    NOK: '6500',
    SEK: '6500',
    HKD: '4500',
    SGD: '850',
    NZD: '1000',
    CZK: '13000',
    HUF: '210000',
    PLN: '2600',
  },
  36: {
    GBP: '550',
    USD: '700',
    EUR: '650',
    CAD: '900',
    AUD: '1000',
    DKK: '4600',
    NOK: '7000',
    SEK: '7000',
    HKD: '5000',
    SGD: '900',
    NZD: '1100',
    CZK: '15000',
    HUF: '230000',
    PLN: '3000',
  },
  37: {
    GBP: '600',
    USD: '750',
    EUR: '700',
    CAD: '1000',
    AUD: '1100',
    DKK: '5000',
    NOK: '7500',
    SEK: '7500',
    HKD: '5500',
    SGD: '1000',
    NZD: '1200',
    CZK: '16000',
    HUF: '260000',
    PLN: '3200',
  },
  38: {
    GBP: '650',
    USD: '800',
    EUR: '750',
    CAD: '1100',
    AUD: '1200',
    DKK: '5400',
    NOK: '8000',
    SEK: '8500',
    HKD: '6000',
    SGD: '1050',
    NZD: '1300',
    CZK: '17000',
    HUF: '280000',
    PLN: '3400',
  },
  39: {
    GBP: '700',
    USD: '850',
    EUR: '800',
    CAD: '1200',
    AUD: '1300',
    DKK: '5800',
    NOK: '9000',
    SEK: '9000',
    HKD: '6500',
    SGD: '1150',
    NZD: '1400',
    CZK: '19000',
    HUF: '300000',
    PLN: '3800',
  },
  40: {
    GBP: '750',
    USD: '900',
    EUR: '900',
    CAD: '1250',
    AUD: '1400',
    DKK: '6400',
    NOK: '9500',
    SEK: '9500',
    HKD: '7000',
    SGD: '1250',
    NZD: '1500',
    CZK: '20000',
    HUF: '320000',
    PLN: '4000',
  },
  41: {
    GBP: '800',
    USD: '1000',
    EUR: '950',
    CAD: '1350',
    AUD: '1450',
    DKK: '6800',
    NOK: '10000',
    SEK: '10000',
    HKD: '7500',
    SGD: '1300',
    NZD: '1600',
    CZK: '21000',
    HUF: '340000',
    PLN: '4200',
  },
  42: {
    GBP: '850',
    USD: '1050',
    EUR: '1000',
    CAD: '1450',
    AUD: '1550',
    DKK: '7200',
    NOK: '11000',
    SEK: '11000',
    HKD: '8000',
    SGD: '1400',
    NZD: '1700',
    CZK: '23000',
    HUF: '360000',
    PLN: '4600',
  },
  43: {
    GBP: '900',
    USD: '1100',
    EUR: '1050',
    CAD: '1500',
    AUD: '1650',
    DKK: '7600',
    NOK: '11500',
    SEK: '11500',
    HKD: '8500',
    SGD: '1500',
    NZD: '1800',
    CZK: '24000',
    HUF: '380000',
    PLN: '4800',
  },
  44: {
    GBP: '950',
    USD: '1150',
    EUR: '1100',
    CAD: '1600',
    AUD: '1750',
    DKK: '8000',
    NOK: '12000',
    SEK: '12000',
    HKD: '9000',
    SGD: '1550',
    NZD: '1900',
    CZK: '25000',
    HUF: '410000',
    PLN: '5000',
  },
  45: {
    GBP: '1000',
    USD: '1250',
    EUR: '1150',
    CAD: '1700',
    AUD: '1850',
    DKK: '8400',
    NOK: '12500',
    SEK: '13000',
    HKD: '9500',
    SGD: '1650',
    NZD: '2000',
    CZK: '27000',
    HUF: '430000',
    PLN: '5400',
  },
};

export const CURRENCY_SYMBOLS = {
  USD: '$',
  GBP: '£',
  EUR: '€',
  CAD: 'C$',
  AUD: 'A$',
  DKK: 'kr.',
  NOK: 'kr',
  SEK: 'kr',
  HKD: 'HK$',
  SGD: 'S$',
  NZD: 'NZ$',
  CZK: 'Kč',
  HUF: 'Ft',
  PLN: 'zł',
};

export const CURRENCY_POSITIONS = {
  USD: 'before',
  GBP: 'before',
  EUR: 'before',
  CAD: 'before',
  AUD: 'before',
  DKK: 'after',
  NOK: 'after',
  SEK: 'after',
  HKD: 'before',
  SGD: 'before',
  NZD: 'before',
  CZK: 'after',
  HUF: 'before',
  PLN: 'after',
};

export const COUNTRY_CURRENCIES = {
  AD: 'EUR',
  AE: 'AED',
  AF: 'AFN',
  AG: 'XCD',
  AI: 'XCD',
  AL: 'ALL',
  AM: 'AMD',
  AN: 'ANG',
  AO: 'AOA',
  AQ: 'USD',
  AR: 'ARS',
  AS: 'USD',
  AT: 'EUR',
  AU: 'AUD',
  AW: 'AWG',
  AX: 'EUR',
  AZ: 'AZN',
  BA: 'BAM',
  BB: 'BBD',
  BD: 'BDT',
  BE: 'EUR',
  BF: 'XOF',
  BG: 'BGN',
  BH: 'BHD',
  BI: 'BIF',
  BJ: 'XOF',
  BL: 'EUR',
  BM: 'BMD',
  BN: 'BND',
  BO: 'BOB',
  BQ: 'USD',
  BR: 'BRL',
  BS: 'BSD',
  BT: 'BTN',
  BV: 'NOK',
  BW: 'BWP',
  BY: 'BYN',
  BZ: 'BZD',
  CA: 'CAD',
  CC: 'AUD',
  CD: 'CDF',
  CF: 'XAF',
  CG: 'CDF',
  CH: 'CHF',
  CI: 'XOF',
  CK: 'NZD',
  CL: 'CLP',
  CM: 'XAF',
  CN: 'CNY',
  CO: 'COP',
  CR: 'CRC',
  CU: 'CUC',
  CV: 'CVE',
  CW: 'ANG',
  CX: 'AUD',
  CY: 'EUR',
  CZ: 'CZK',
  DE: 'EUR',
  DJ: 'DJF',
  DK: 'DKK',
  DM: 'DOP',
  DO: 'DOP',
  DZ: 'DZD',
  EC: 'USD',
  EE: 'EUR',
  EG: 'EGP',
  EH: 'MAD',
  ER: 'ERN',
  ES: 'EUR',
  ET: 'ETB',
  FI: 'EUR',
  FJ: 'FJD',
  FK: 'FKP',
  FM: 'USD',
  FO: 'DKK',
  FR: 'EUR',
  GA: 'XAF',
  GB: 'GBP',
  GD: 'XCD',
  GE: 'GEL',
  GF: 'EUR',
  GG: 'GBP',
  GH: 'GHS',
  GI: 'GIP',
  GL: 'DKK',
  GM: 'GMD',
  GN: 'GNF',
  GP: 'EUR',
  GQ: 'XAF',
  GR: 'EUR',
  GS: 'FKP',
  GT: 'GTQ',
  GU: 'USD',
  GW: 'XOF',
  GY: 'GYD',
  HK: 'HKD',
  HM: 'AUD',
  HN: 'HNL',
  HR: 'EUR',
  HT: 'HTG',
  HU: 'HUF',
  ID: 'IDR',
  IE: 'EUR',
  IL: 'ILS',
  IM: 'GBP',
  IN: 'INR',
  IO: 'USD',
  IQ: 'IQD',
  IR: 'IRR',
  IS: 'ISK',
  IT: 'EUR',
  JE: 'GBP',
  JM: 'JMD',
  JO: 'JOD',
  JP: 'JPY',
  KE: 'KES',
  KG: 'KGS',
  KH: 'KHR',
  KI: 'AUD',
  KM: 'KMF',
  KN: 'XCD',
  KP: 'KPW',
  KR: 'KRW',
  KW: 'KWD',
  KY: 'KYD',
  KZ: 'KZT',
  LA: 'LAK',
  LB: 'LBP',
  LC: 'XCD',
  LI: 'CHF',
  LK: 'LKR',
  LR: 'LRD',
  LS: 'LSL',
  LT: 'EUR',
  LU: 'EUR',
  LV: 'EUR',
  LY: 'LYD',
  MA: 'MAD',
  MC: 'EUR',
  MD: 'MDL',
  ME: 'EUR',
  MF: 'EUR',
  MG: 'MGA',
  MH: 'USD',
  MK: 'MKD',
  ML: 'XOF',
  MM: 'MMK',
  MN: 'MNT',
  MO: 'MOP',
  MP: 'USD',
  MQ: 'EUR',
  MR: 'MRU',
  MS: 'XCD',
  MT: 'EUR',
  MU: 'MUR',
  MV: 'MVR',
  MW: 'MWK',
  MX: 'MXN',
  MY: 'MYR',
  MZ: 'MZN',
  NA: 'NAD',
  NC: 'XPF',
  NE: 'NGN',
  NF: 'AUD',
  NG: 'NGN',
  NI: 'NIO',
  NL: 'EUR',
  NO: 'NOK',
  NP: 'NPR',
  NR: 'AUD',
  NU: 'NZD',
  NZ: 'NZD',
  OM: 'OMR',
  PA: 'PAB',
  PE: 'PEN',
  PF: 'XPF',
  PG: 'PGK',
  PH: 'PHP',
  PK: 'PKR',
  PL: 'PLN',
  PM: 'EUR',
  PN: 'NZD',
  PR: 'USD',
  PS: 'ILS',
  PT: 'EUR',
  PW: 'USD',
  PY: 'PYG',
  QA: 'QAR',
  RE: 'EUR',
  RO: 'RON',
  RS: 'RSD',
  RU: 'RUB',
  RW: 'RWF',
  SA: 'SAR',
  SB: 'SBD',
  SC: 'SCR',
  SD: 'SDG',
  SE: 'SEK',
  SG: 'SGD',
  SH: 'SHP',
  SI: 'EUR',
  SJ: 'NOK',
  SK: 'EUR',
  SL: 'SLL',
  SM: 'EUR',
  SN: 'XOF',
  SO: 'SOS',
  SR: 'SRD',
  SS: 'SSP',
  ST: 'STN',
  SV: 'USD',
  SX: 'ANG',
  SY: 'SYP',
  SZ: 'SZL',
  TC: 'USD',
  TD: 'XAF',
  TF: 'EUR',
  TG: 'XOF',
  TH: 'THB',
  TJ: 'TJS',
  TK: 'NZD',
  TL: 'USD',
  TM: 'TMT',
  TN: 'TND',
  TO: 'TOP',
  TR: 'TRY',
  TT: 'TTD',
  TV: 'AUD',
  TW: 'TWD',
  TZ: 'TZS',
  UA: 'UAH',
  UG: 'UGX',
  UM: 'USD',
  US: 'USD',
  UY: 'UYU',
  UZ: 'UZS',
  VA: 'EUR',
  VC: 'XCD',
  VE: 'VES',
  VG: 'USD',
  VI: 'USD',
  VN: 'VND',
  VU: 'VUV',
  WF: 'XPF',
  WS: 'USD',
  YE: 'YER',
  YT: 'EUR',
  ZA: 'ZAR',
  ZM: 'ZMW',
  ZW: 'ZWL',
};
