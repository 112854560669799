import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Box,
  BoxProps,
  Flex,
  Text,
  Stack,
  MdIcon,
  Card,
  Progress,
  CircularProgress,
  Palette,
  Collapse,
  chakra,
  useTheme,
} from '@workshop/ui';
import { useReward } from 'react-rewards';
import { useWindowDimensions } from 'utils/hooks/useDimensions';

import { useHasRole, useCurrentTeam } from 'redux/selectors';
import { useDismissedInformationCards } from 'redux/selectors/user';
import { profileActions } from 'redux/actions/common';

export type OnboardingStep = {
  slug: string;
  label: string;
  isComplete: boolean;
  currentNum?: number;
  totalNum?: number;
  onClick?: () => void;
  showClickable?: boolean;
};

interface OnboardingChecklistProps extends BoxProps {
  id: string;
  onboardingSteps: OnboardingStep[];
  title: string;
  emoji: string;
  onComplete?: () => void;
  ownerOnly?: boolean;
  canBeDismissed?: boolean;
  animateIntro?: boolean;
  alwaysFullWidth?: boolean;
}

const OnboardingChecklist: React.FC<OnboardingChecklistProps> = ({
  id,
  onboardingSteps,
  title,
  emoji,
  onComplete,
  ownerOnly = true,
  canBeDismissed = true,
  animateIntro = true,
  alwaysFullWidth,
  ...rest
}) => {
  const [isCollapsing, setIsCollapsing] = useState(animateIntro);
  const [isCollapsed, setIsCollapsed] = useState(animateIntro);

  const currentTeam = useCurrentTeam();
  const isOwner = useHasRole('owner');
  const dismissedInformationCards = useDismissedInformationCards();
  const onboardingId = `${currentTeam}-${id}`;
  const shouldShowOnboarding =
    !canBeDismissed ||
    ((!ownerOnly || isOwner) &&
      !dismissedInformationCards.includes(onboardingId));

  const theme = useTheme();
  const windowDimensions = useWindowDimensions();
  const isMobile = windowDimensions.width < parseInt(theme.breakpoints.md, 10);

  const isFullWidth = isMobile || alwaysFullWidth;

  const dispatch = useDispatch();

  const { reward } = useReward(id, 'confetti', {
    lifetime: 200,
    startVelocity: 25,
    colors: [
      Palette.blue['300'],
      Palette.blue['400'],
      Palette.green['300'],
      Palette.green['400'],
      Palette.orange['200'],
      Palette.red['300'],
      Palette.neutral['10'],
    ],
  });

  let stepsComplete = 0;
  let totalSteps = 0;
  onboardingSteps.forEach((s) => {
    if (s.currentNum !== undefined && s.totalNum !== undefined) {
      stepsComplete = stepsComplete + s.currentNum;
      totalSteps = totalSteps + s.totalNum;
    } else {
      if (s.isComplete) {
        stepsComplete = stepsComplete + 1;
      }
      totalSteps = totalSteps + 1;
    }
  });

  const stepsProgress = totalSteps > 0 ? (100 * stepsComplete) / totalSteps : 0;
  const isComplete = stepsProgress === 100;

  useEffect(() => {
    let timeout1: null | ReturnType<typeof setTimeout> = null;
    let timeout2: null | ReturnType<typeof setTimeout> = null;
    let timeout3: null | ReturnType<typeof setTimeout> = null;
    let timeout4: null | ReturnType<typeof setTimeout> = null;
    if (shouldShowOnboarding && isComplete && canBeDismissed) {
      timeout1 = setTimeout(() => {
        reward();
        timeout2 = setTimeout(() => {
          setIsCollapsing(true);
          timeout3 = setTimeout(() => {
            setIsCollapsed(true);
            timeout4 = setTimeout(() => {
              onComplete && onComplete();
              dispatch(
                profileActions.updateUserProfile(
                  {
                    dismissedInformationCards: [
                      ...dismissedInformationCards,
                      onboardingId,
                    ],
                  },
                  { toast: { success: false, error: false } }
                )
              );
            }, 500);
          }, 500);
        }, 3500);
      }, 300);
    }
    return () => {
      timeout1 && clearTimeout(timeout1);
      timeout2 && clearTimeout(timeout2);
      timeout3 && clearTimeout(timeout3);
      timeout4 && clearTimeout(timeout4);
    };
  }, [isComplete, shouldShowOnboarding, canBeDismissed]);

  useEffect(() => {
    let timeout1: null | ReturnType<typeof setTimeout> = null;
    let timeout2: null | ReturnType<typeof setTimeout> = null;
    if (shouldShowOnboarding && animateIntro) {
      timeout1 = setTimeout(() => {
        setIsCollapsed(false);
        timeout2 = setTimeout(() => {
          setIsCollapsing(false);
        }, 500);
      }, 1500);
    }
    return () => {
      timeout1 && clearTimeout(timeout1);
      timeout2 && clearTimeout(timeout2);
    };
  }, [shouldShowOnboarding, animateIntro]);

  if (!shouldShowOnboarding) return <Box id={id} display="none" />;

  const stepsMessage =
    stepsProgress === 0
      ? ''
      : stepsProgress <= 20
      ? 'Great start 👍'
      : stepsProgress <= 40
      ? 'Keep it up!'
      : stepsProgress <= 60
      ? 'Looking good 😎'
      : stepsProgress < 100
      ? 'Almost there...'
      : 'Perfect 🎉';

  const content = (
    <Box
      borderRadius={{ base: 'lg', md: 'none' }}
      overflow={isCollapsing ? 'hidden' : { base: 'hidden', md: 'visible' }}
      flex={!isFullWidth && isCollapsed ? 0 : { base: 1, md: 1.5, '2xl': 1 }}
      opacity={isCollapsing ? 0 : 1}
      transform={isCollapsing ? 'translateY(-100px)' : 'none'}
      transition="all 0.3s"
      {...rest}
    >
      <Card
        padding={4}
        paddingTop={2}
        backgroundColor={
          isComplete ? 'background.success' : 'background.default'
        }
        color={isComplete ? 'text.success' : 'text.default'}
        transition="backgroundColor 0.3s, color 0.3s"
        height={isCollapsed && !isFullWidth ? 0 : 'auto'}
        width={isCollapsed && !isFullWidth ? 0 : 'auto'}
      >
        <Box w="100%">
          <Text fontSize="4xl">{emoji}</Text>
          <Text fontWeight="bold" fontSize="lg" mb={4} transition="color 0.3">
            {title}
          </Text>
          <Stack>
            {onboardingSteps.map((s) => (
              <Flex
                key={`channel-step-${s.slug}`}
                alignItems="center"
                {...(s.onClick
                  ? {
                      onClick: s.onClick,
                      cursor: s.showClickable ? 'pointer' : 'auto',
                      _hover: {
                        opacity: s.showClickable ? 0.8 : 1,
                      },
                    }
                  : {})}
              >
                {!s.isComplete &&
                s.currentNum &&
                s.totalNum &&
                s.currentNum > 0 &&
                s.totalNum > 0 ? (
                  <CircularProgress
                    value={100 * (s.currentNum / s.totalNum)}
                    color="common.progress"
                    trackColor="background.tint2"
                    thickness="20px"
                    size={4}
                    mr={2}
                  />
                ) : (
                  <MdIcon
                    name={s.isComplete ? 'CheckCircle' : 'Circle'}
                    color={
                      s.isComplete ? 'common.progress' : 'background.tint2'
                    }
                    mr={2}
                  />
                )}

                <Flex flex={1} alignItems="center">
                  <Text
                    color={s.isComplete ? 'common.progress' : 'text.default'}
                    lineHeight="1.4"
                  >
                    <chakra.span
                      textDecoration={s.isComplete ? 'line-through' : 'none'}
                    >
                      {s.label}
                    </chakra.span>
                    {s.currentNum !== undefined && s.totalNum !== undefined ? (
                      <chakra.span
                        ml={2}
                        fontSize="xs"
                        color={s.isComplete ? 'common.progress' : 'text.muted'}
                      >{`(${s.currentNum}/${s.totalNum})`}</chakra.span>
                    ) : null}
                  </Text>
                  {s.showClickable && !s.isComplete && (
                    <MdIcon
                      name="KeyboardArrowRight"
                      color="text.default"
                      ml={2}
                      display={{ base: 'none', md: 'inline' }}
                    />
                  )}
                </Flex>
              </Flex>
            ))}
          </Stack>
          <Box mt={6}>
            <Progress
              value={stepsProgress}
              hasStripe={isComplete}
              isAnimated={true}
              borderRadius="full"
              colorScheme="green"
              mt={2}
              mb={2}
              height={2.5}
              sx={{
                '& > div:first-child': {
                  transitionProperty: 'width',
                },
              }}
            />
            <Text id={id} fontSize="xs" color="common.progress">
              {stepsMessage}
            </Text>
          </Box>
        </Box>
      </Card>
    </Box>
  );

  if (isFullWidth) {
    return (
      <Collapse
        in={!isCollapsed}
        style={{ overflow: isCollapsed ? 'hidden' : 'visible' }}
      >
        {content}
      </Collapse>
    );
  }

  return content;
};

export default OnboardingChecklist;
