import {
  TopicNotification,
  GroupNotification,
  NotificationType,
  GroupMember,
} from 'discourse-js';

import { simplifyName } from 'utils';

import { uiAction, discourseActions } from 'redux/actions/common';

import {
  Cohort,
  CohortState,
  DiscourseNotificationState,
  MessagingUIData,
} from 'types/common';

// Format a discourse username
export const formatUsername = (name: string) => {
  // jane_doe -> Jane Doe
  if (name && name.includes('_')) {
    return name
      .split('_')
      .map((i) => i.charAt(0).toUpperCase() + i.slice(1))
      .join(' ');
  }
  // rick42 -> Rick42
  if (name && isNaN(+name)) {
    return name.charAt(0).toUpperCase() + name.slice(1);
  }
  return name;
};

// Make sense of what Discourse sent us
// i.e. Get the name of the notifier if not show an icon and check if its a group notification
export const getDisplayNotifier = (
  notification: TopicNotification | GroupNotification,
  cohorts: Cohort[] = []
) => {
  const { data } = notification;

  const cohort = cohorts.find((c) => {
    return c.discourseGroupChatTopicId === notification.topicId;
  });

  if (!cohort && !('displayUsername' in data)) {
    return { displayName: '', showIcon: false, isGroupChat: false };
  }

  if (!cohort && 'displayUsername' in data) {
    return {
      displayName: data.displayUsername,
      showIcon: Boolean(data.displayUsername !== data?.originalUsername),
      isGroupChat: false,
    };
  }

  return {
    displayName:
      'displayUsername' in data ? data.displayUsername : 'New message',
    showIcon: true,
    isGroupChat: true,
  };
};

// Retrieve and format the notification name
export const getNotifierName = (
  notification: TopicNotification | GroupNotification,
  displayName: string,
  isGroupChat?: boolean
) => {
  switch (notification.notificationType) {
    case NotificationType.replied:
      return simplifyName(formatUsername(displayName));
    case NotificationType.liked:
      return simplifyName(formatUsername(displayName));
    case NotificationType.privateMessage:
      if (isGroupChat) {
        return formatUsername(displayName.replace('replies', 'messages'));
      }

      return notification.data.originalUsername !==
        notification.data.displayUsername
        ? simplifyName(formatUsername(notification.data.originalUsername))
        : formatUsername(displayName);
    case NotificationType.posted:
      return notification.data.originalUsername ===
        notification.data.displayUsername
        ? simplifyName(formatUsername(displayName))
        : formatUsername(displayName.replace('replies', 'comments'));
    default:
      return formatUsername(displayName);
  }
};

// Retrieve and format the notification text
export const getNotificationText = (
  notification: TopicNotification | GroupNotification,
  isGroupChat?: boolean
) => {
  switch (notification.notificationType) {
    case NotificationType.replied:
      return notification.data.originalUsername ===
        notification.data.displayUsername
        ? 'replied to your comment'
        : 'to your comment'; // else it's xxx replies to your comment
    case NotificationType.liked:
      return notification.postNumber === 1
        ? 'liked your post'
        : 'liked your comment';
    case NotificationType.privateMessage:
      // else it's xxx messages in your group
      if (isGroupChat) {
        return 'in your class discussion';
      }
      const { originalUsername, displayUsername } = notification.data;

      if (originalUsername === displayUsername) {
        return 'sent you a message';
      }
      return displayUsername.includes(' replies')
        ? `sent you ${displayUsername.replace(' replies', '')} messages`
        : null;
    case NotificationType.posted:
      return notification.data.originalUsername ===
        notification.data.displayUsername
        ? 'commented on your post'
        : 'on your post'; // else it's xxx comments on your post
    // @ts-ignore - wait for discourse-js update
    case NotificationType.watchingFirstPost:
      return 'uploaded a new photo';
    // @ts-ignore - wait for discourse-js update
    case NotificationType.mentioned:
      return 'mentioned you';
    default:
      // @ts-ignore - wait for discourse-js update
      return notification.fancyTitle || '';
  }
};

// Get the information we need to display the notification
export const getDisplayNotification = (
  notification: TopicNotification | GroupNotification,
  cohorts: Cohort[] = []
) => {
  const { displayName, showIcon, isGroupChat } = getDisplayNotifier(
    notification,
    cohorts
  );
  const notifierName = getNotifierName(notification, displayName, isGroupChat);
  const content = getNotificationText(notification, isGroupChat);
  return { showIcon, notifierName, content };
};

// Get unread notifications
export const getUnreadNotifications = (
  notifications: TopicNotification | GroupNotification
) => {
  return Object.values(notifications).filter(({ read }) => !read);
};

// Get unread private messages notifications
export const getPrivateMessageNotifications = (
  notifications: DiscourseNotificationState
) => {
  return Object.values(notifications).filter(
    (notification) =>
      !notification.read &&
      notification.notificationType === NotificationType.privateMessage &&
      notification.data?.originalUsername !== 'system' &&
      notification.data?.originalUsername !== 'discobot'
  );
};

// Get Topic Ids from user cohorts
export const getGroupChatTopicIds = (cohorts: CohortState) => {
  return Object.values(cohorts).map(
    (cohort) => cohort.discourseGroupChatTopicId
  );
};

export const getOpenMessageActions = ({
  notification,
  cohorts = [],
  discourseMembers = [],
}: {
  notification: TopicNotification | GroupNotification;
  cohorts: Cohort[];
  discourseMembers: GroupMember[];
}) => {
  // TODO: Find a cleaner way to open messages from the dashboard and inbox

  const markReadAction = discourseActions.markNotificationRead(notification.id);

  // See if the notification has a `topicId` which matches the group
  // ID stored in the user's cohorts. If so, we know the notification
  // is for a group chat.
  const cohort = cohorts.find(
    (c) => c.discourseGroupChatTopicId === notification.topicId
  );

  // Notification is for a group chat - open chat widget
  if (cohort && notification.topicId) {
    const privateMessagingData: MessagingUIData = {
      discourseGroupTopicId: cohort.discourseGroupChatTopicId,
      title: cohort.courseDetails.title,
      isExpanded: true,
    };
    return [markReadAction, uiAction.toggleUserMessaging(privateMessagingData)];
  }

  // If the notification is a private message, attempt to find the
  // discourse discourse member details
  //
  // TODO: Get this mapping from the Workshop cohort
  const notifier =
    notification.notificationType === NotificationType.privateMessage
      ? discourseMembers.find(
          (m) => m.username === notification.data.originalUsername
        )
      : undefined;

  // If a notifier has been found - open chat widget
  if (notifier) {
    const privateMessagingData: MessagingUIData = {
      userId: notifier.id,
      title: notifier.name,
      username: notifier.username,
      isExpanded: true,
    };
    return [markReadAction, uiAction.toggleUserMessaging(privateMessagingData)];
  }
};
