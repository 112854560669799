import React, { useEffect, useState } from 'react';
import { useForm, FormContext } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaTiktok,
  FaDiscord,
  FaPatreon,
} from 'react-icons/fa';
import isURL from 'validator/lib/isURL';

import { PLATFORM } from 'constants/env';
import { PERSONAS_EDIT } from 'constants/settings';

import { Flex, Box, MdIcon } from '@workshop/ui';

import { analytics } from 'utils';

import { Organisation, PERMISSION_SLUGS } from 'types/common';

import { useHasPermission } from 'redux/selectors/organisation';
import {
  EditCard,
  LabelInput,
  LabelWrapper,
  ImageUpload,
  LabelSelect,
} from 'components/Common';

interface Props {
  introduction?: string;
  contactEmail?: string;
  contactNumber?: string;
  logo?: string;
  logoDark?: string;
  name?: string;
  websiteUrl?: string;
  facebookUrl?: string;
  instagramUrl?: string;
  twitterUrl?: string;
  youtubeUrl?: string;
  tiktokUrl?: string;
  discordUrl?: string;
  patreonUrl?: string;
  handle?: string;
  persona?: string;
  isLoading?: boolean;
  onSubmit: (args: Partial<Organisation>) => Promise<any>;
  onCancel?: () => void;
  linksOnly?: boolean;
}

const OrganisationDetailsCard: React.FC<Props> = (props) => {
  const { isLoading = false, onSubmit } = props;

  const isEditingDisabled = !useHasPermission(
    PERMISSION_SLUGS.can_edit_organisation
  );

  const formMethods = useForm<Partial<Organisation>>({
    defaultValues: {
      introduction: props.introduction,
      contactEmail: props.contactEmail,
      contactNumber: props.contactNumber,
      logo: props.logo,
      logoDark: props.logoDark,
      name: props.name,
      websiteUrl: props.websiteUrl,
      facebookUrl: props.facebookUrl,
      instagramUrl: props.instagramUrl,
      twitterUrl: props.twitterUrl,
      youtubeUrl: props.youtubeUrl,
      tiktokUrl: props.tiktokUrl,
      discordUrl: props.discordUrl,
      patreonUrl: props.patreonUrl,
      handle: props.handle,
      persona: props.persona,
    },
  });

  const {
    register,
    handleSubmit,
    errors,
    clearError,
    setError,
    watch,
    formState: { dirty },
    getValues,
    reset,
    setValue,
    unregister,
  } = formMethods;

  const { logo, logoDark } = getValues();

  const [logoPreview, setLogoPreview] = useState<string | undefined>(
    typeof props.logo === 'string' ? props.logo : undefined
  );
  const [logoDarkPreview, setLogoDarkPreview] = useState<string | undefined>(
    typeof props.logoDark === 'string' ? props.logoDark : undefined
  );
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [selectedSocialLink, setSelectedSocialLink] = useState('');

  useEffect(() => {
    register('logo');
    register('logoDark');

    return () => {
      unregister('logo');
      unregister('logoDark');
    };
  }, [register, unregister]);

  useEffect(() => {
    // Avoiding memory leaks
    return () => {
      logoPreview && URL.revokeObjectURL(logoPreview);
    };
  }, [logoPreview]);

  useEffect(() => {
    // Avoiding memory leaks
    return () => {
      logoDarkPreview && URL.revokeObjectURL(logoDarkPreview);
    };
  }, [logoDarkPreview]);

  useEffect(() => {
    reset({
      introduction: props.introduction,
      contactEmail: props.contactEmail,
      contactNumber: props.contactNumber,
      logo: props.logo,
      logoDark: props.logoDark,
      name: props.name,
      websiteUrl: props.websiteUrl,
      facebookUrl: props.facebookUrl,
      instagramUrl: props.instagramUrl,
      twitterUrl: props.twitterUrl,
      youtubeUrl: props.youtubeUrl,
      tiktokUrl: props.tiktokUrl,
      discordUrl: props.discordUrl,
      patreonUrl: props.patreonUrl,
      handle: props.handle,
      persona: props.persona,
    });
    clearError();
  }, [
    props.introduction,
    props.contactEmail,
    props.contactEmail,
    props.contactNumber,
    props.logo,
    props.logoDark,
    props.name,
    props.websiteUrl,
    props.facebookUrl,
    props.instagramUrl,
    props.twitterUrl,
    props.youtubeUrl,
    props.tiktokUrl,
    props.discordUrl,
    props.patreonUrl,
    props.handle,
    props.persona,
  ]);

  const onSubmitData = handleSubmit(async (data) => {
    setIsSubmitting(true);
    const { logo, logoDark, ...otherData } = data;
    let newData = { ...otherData };
    if (logo && typeof logo === 'object') {
      // @ts-ignore
      newData = { ...newData, logo };
    }
    if (logoDark && typeof logoDark === 'object') {
      // @ts-ignore
      newData = { ...newData, logoDark };
    }
    const res = await onSubmit(newData);
    if (logoDark) {
      analytics.track('Channel Profile Picture Uploaded');
    }
    if (res.error) {
      if (
        res.payload?.error?.details?.length &&
        res.payload.error.details[0]?.target === 'handle'
      ) {
        setError('handle', { type: 'custom' });
      }
    } else {
      reset(data, { dirty: false });
      clearError();
    }
    setIsSubmitting(false);
    register('logo');
  });

  const onImageDrop = (inputName: string, acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    setValue(inputName, file);

    if (inputName === 'logo') {
      setLogoPreview(URL.createObjectURL(file));
    }

    if (inputName === 'logoDark') {
      setLogoDarkPreview(URL.createObjectURL(file));
    }
    onSubmitData();
  };

  const handle = watch('handle') || '';
  const handleTooShort = handle.length < 4;
  const handleIsValid = /^[a-zA-Z0-9-_]+$/.test(handle);

  const websiteUrl = watch('websiteUrl') || '';
  const websiteUrlIsValid = !websiteUrl || isURL(websiteUrl);

  const facebookUrl = watch('facebookUrl') || '';
  const instagramUrl = watch('instagramUrl') || '';
  const twitterUrl = watch('twitterUrl') || '';
  const youtubeUrl = watch('youtubeUrl') || '';
  const tiktokUrl = watch('tiktokUrl') || '';
  const discordUrl = watch('discordUrl') || '';
  const patreonUrl = watch('patreonUrl') || '';

  const socialLinks = [
    {
      slug: 'facebookUrl',
      name: 'Facebook',
      link: props.facebookUrl,
      icon: <FaFacebook />,
      color: '#4267B2',
      isValid:
        !facebookUrl ||
        /^(https?:\/\/)?((w{3}\.)?)facebook.com\/.*/i.test(facebookUrl),
    },
    {
      slug: 'instagramUrl',
      name: 'Instagram',
      link: props.instagramUrl,
      icon: <FaInstagram />,
      color: '#962fbf',
      isValid:
        !instagramUrl ||
        /^(https?:\/\/)?((w{3}\.)?)instagram.com\/.*/i.test(instagramUrl),
    },
    {
      slug: 'twitterUrl',
      name: 'Twitter',
      link: props.twitterUrl,
      icon: <FaTwitter />,
      color: '#00acee',
      isValid:
        !twitterUrl ||
        /^(https?:\/\/)?((w{3}\.)?)twitter.com\/.*/i.test(twitterUrl),
    },
    {
      slug: 'youtubeUrl',
      name: 'YouTube',
      link: props.youtubeUrl,
      icon: <FaYoutube />,
      color: '#FF0000',
      isValid:
        !youtubeUrl ||
        /^(https?:\/\/)?((w{3}\.)?)youtube.com\/.*/i.test(youtubeUrl),
    },
    {
      slug: 'tiktokUrl',
      name: 'TikTok',
      link: props.tiktokUrl,
      icon: <FaTiktok />,
      color: '#69C9D0',
      isValid:
        !tiktokUrl ||
        /^(https?:\/\/)?((w{3}\.)?)tiktok.com\/.*/i.test(tiktokUrl),
    },
    {
      slug: 'discordUrl',
      name: 'Discord',
      link: props.discordUrl,
      icon: <FaDiscord />,
      color: '#5865F2',
      isValid:
        !discordUrl ||
        /^(https?:\/\/)?((w{3}\.)?)discord.(?:com|gg)\/.*/i.test(discordUrl),
    },
    {
      slug: 'patreonUrl',
      name: 'Patreon',
      link: props.patreonUrl,
      icon: <FaPatreon />,
      color: '#FF424D',
      isValid:
        !patreonUrl ||
        /^(https?:\/\/)?((w{3}\.)?)patreon.com\/.*/i.test(patreonUrl),
    },
  ];
  const socialLinksAreValid =
    socialLinks.filter((l) => !l.isValid).length === 0;

  return (
    <EditCard
      onSave={onSubmitData}
      onCancel={() => {
        clearError();

        reset({
          introduction: props.introduction || '',
          contactEmail: props.contactEmail || '',
          contactNumber: props.contactNumber || '',
          logo: props.logo || '',
          logoDark: props.logoDark || '',
          name: props.name || '',
          websiteUrl: props.websiteUrl || '',
          handle: props.handle || '',
          persona: props.persona || 'general',
        });

        setValue('logo', props.logo || '');
        setLogoPreview(typeof props.logo === 'string' ? props.logo : '');
        props.onCancel && props.onCancel();
      }}
      isUpdating={isSubmitting}
      isDisabled={isEditingDisabled}
      saveDisabled={Boolean(
        !dirty ||
          !isEmpty(errors) ||
          !handleIsValid ||
          handleTooShort ||
          !socialLinksAreValid ||
          !websiteUrlIsValid
      )}
      showCancel={props.linksOnly}
    >
      <FormContext {...formMethods}>
        <Flex flexDirection="column">
          <Box flex={1}>
            {!props.linksOnly && (
              <>
                <LabelWrapper label="Profile Picture" labelAlign="flex-start">
                  <Flex
                    backgroundColor="background.tint1"
                    borderRadius="full"
                    borderWidth={1}
                    borderColor="border.default"
                    width="100px"
                    _hover={{ opacity: 0.8 }}
                  >
                    <ImageUpload
                      id="logoDark"
                      backgroundColor="transparent"
                      backgroundSize="cover"
                      borderRadius="50px"
                      height="100px"
                      // @ts-ignore
                      image={logoDarkPreview || logoDark || ''}
                      name="logoDark"
                      onDrop={onImageDrop}
                      width="100%"
                      isDisabled={isEditingDisabled}
                      hideText
                    />
                  </Flex>
                </LabelWrapper>
                <LabelInput
                  id="name"
                  isDisabled={isEditingDisabled}
                  name="name"
                  label="Name"
                  error={Boolean(errors.name)}
                  errorMessage="This information is required."
                  isLoading={isLoading}
                  registerInputRef={register({ required: true })}
                  fontWeight="bold"
                />
                {PLATFORM === 'steppit' && (
                  <LabelInput
                    id="handle"
                    isDisabled={isEditingDisabled}
                    name="handle"
                    label="Handle"
                    leftIcon={
                      <MdIcon name="AlternateEmail" color="text.muted" />
                    }
                    error={Boolean(
                      errors.handle || !handleIsValid || handleTooShort
                    )}
                    errorMessage={
                      handleTooShort
                        ? 'Handles must be at least 4 characters long'
                        : !handleIsValid
                        ? 'Handles may only contain letters (a-z), numbers (0-9), dashes (-) and underscores (_)'
                        : 'A channel with this handle already exists'
                    }
                    isLoading={isLoading}
                    registerInputRef={register({ required: true })}
                    maxLength={32}
                  />
                )}
              </>
            )}

            <LabelInput
              id="websiteUrl"
              isDisabled={isEditingDisabled}
              name="websiteUrl"
              label="Website"
              error={Boolean(errors.websiteUrl || !websiteUrlIsValid)}
              errorMessage={
                !websiteUrlIsValid ? 'Please enter a valid URL' : ''
              }
              isLoading={isLoading}
              registerInputRef={register()}
            />
          </Box>

          <LabelWrapper label="Social Links" labelAlign="flex-start">
            <Flex direction="row" flexWrap="wrap" my={-1}>
              {socialLinks.map((l) => (
                <Flex
                  key={`social-link-${l.slug}`}
                  boxSize={10}
                  borderRadius="full"
                  borderWidth={
                    l.slug === selectedSocialLink || !l.isValid ? 2 : 1
                  }
                  borderColor={
                    l.slug === selectedSocialLink
                      ? 'common.primary'
                      : !l.isValid
                      ? 'common.notification'
                      : 'border.muted'
                  }
                  alignItems="center"
                  justifyContent="center"
                  bg={l.link ? l.color : 'transparent'}
                  color={l.link ? '#fff' : 'text.default'}
                  cursor="pointer"
                  _hover={{ opacity: 0.8 }}
                  onClick={() =>
                    setSelectedSocialLink(
                      l.slug === selectedSocialLink ? '' : l.slug
                    )
                  }
                  position="relative"
                  overflow="hidden"
                  m={1}
                >
                  {l.slug === selectedSocialLink && (
                    <Flex
                      position="absolute"
                      top={0}
                      right={0}
                      bottom={0}
                      left={0}
                      bg={l.link ? 'rgba(0,0,0,0.2)' : 'background.tint2'}
                    />
                  )}
                  <Flex position="relative">{l.icon}</Flex>
                </Flex>
              ))}
            </Flex>
          </LabelWrapper>
          {socialLinks.map((l) => (
            <Flex
              key={`social-link-input-${l.slug}`}
              opacity={l.slug === selectedSocialLink ? 1 : 0}
              height={l.slug === selectedSocialLink ? 'auto' : '0px'}
              transition="all 0.3s"
            >
              <LabelInput
                label=" "
                id={l.slug}
                name={l.slug}
                registerInputRef={register()}
                leftIcon={<Flex color={l.color}>{l.icon}</Flex>}
                isLoading={isLoading}
                // @ts-ignore
                error={Boolean(errors[l.slug]) || !l.isValid}
                errorMessage={
                  !l.isValid ? `Please enter a valid ${l.name} link` : ''
                }
              />
            </Flex>
          ))}
          {!props.linksOnly && (
            <>
              {/* <LabelTextArea
            id="introduction"
            isDisabled={isEditingDisabled}
            name="introduction"
            label="Introduction"
            error={Boolean(errors.introduction)}
            errorMessage="This information is required."
            isLoading={isLoading}
            registerInputRef={register()}
            autoResize
          /> */}

              <LabelInput
                id="contactEmail"
                isDisabled={isEditingDisabled}
                name="contactEmail"
                label="Contact Email"
                error={Boolean(errors.contactEmail)}
                errorMessage="This information is required."
                helpText="(Optional) A public email address shown at checkout where prospective students can reach you."
                isLoading={isLoading}
                registerInputRef={register()}
              />
              <LabelSelect
                id="persona"
                name="persona"
                label="Channel Type"
                error={Boolean(errors.persona)}
                isLoading={isLoading}
                registerInputRef={register()}
                options={PERSONAS_EDIT}
                isDisabled={isEditingDisabled}
                helpText="We use this to tailor the platform and our communications to your needs."
                unsorted
              />
              {/* <LabelInput
            id="contactNumber"
            isDisabled={isEditingDisabled}
            name="contactNumber"
            label="Contact Number"
            error={Boolean(errors.contactNumber)}
            errorMessage="This information is required."
            helpText="(Optional) A public phone number shown at checkout where prospective students can reach you."
            isLoading={isLoading}
            registerInputRef={register()}
          /> */}

              {/* <LabelWrapper
            label="Logo"
            labelAlign="flex-start"
            helpText="This will be shown over images and dark backgrounds, a white PNG logo with a transparent background works well here."
          >
            <Flex
              backgroundColor="neutral.700"
              padding={2}
              borderRadius="sm"
              maxWidth="300px"
            >
              <ImageUpload
                id="logo"
                backgroundColor="transparent"
                hoverBg="neutral.600"
                borderColor="transparent"
                textColor="#fff"
                backgroundSize="contain"
                borderRadius={0}
                height="60px"
                image={logoPreview || logo || ''}
                name="logo"
                onDrop={onImageDrop}
                width="100%"
                isDisabled={isEditingDisabled}
              />
            </Flex>
          </LabelWrapper> */}
            </>
          )}
        </Flex>
      </FormContext>
    </EditCard>
  );
};

export default OrganisationDetailsCard;
