import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import { PLATFORM } from 'constants/env';
import {
  PRICING_MATRIX,
  CURRENCY_SYMBOLS,
  CURRENCY_POSITIONS,
  COUNTRY_CURRENCIES,
} from 'constants/settings';

import { hooks, courseUtils, getParamFromUrl } from 'utils';
import { useUserProfile } from 'redux/selectors';

import { EditCard, LabelInput, LabelSelect } from 'components/Common';
import {
  Box,
  Flex,
  Text,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
  chakra,
} from '@workshop/ui';

import { FurtherDetailsFormData, IClassType } from 'types/cms';

interface FurtherDetailsProps extends FurtherDetailsFormData {
  categories: { [key: string]: string };
  classTypes: { [key in IClassType]: string };
  onSave: (formData: FurtherDetailsFormData) => void;
  onCancel?: () => void;
  isDisabled?: boolean;
  isUpdating?: boolean;
  isLoading?: boolean;
  error?: boolean;
  category: number;
  classType: IClassType;
  showAdvancedFields?: boolean;
}

const noop = () => null;
const FurtherDetailsCard = ({
  categories,
  classTypes,
  onSave,
  onCancel = noop,
  courseDuration,
  accessDuration,
  price,
  priceTier,
  category,
  classType,
  isDisabled = false,
  isUpdating = false,
  isLoading = false,
  showAdvancedFields = false,
}: FurtherDetailsProps) => {
  const [visibleCurrency, setVisibleCurrency] = useState('');
  const userProfile = useUserProfile();

  const location = useLocation();
  const focusParam = getParamFromUrl(location, 'focus');

  const {
    register,
    handleSubmit,
    errors,
    clearError,
    formState,
    reset,
    watch,
  } = useForm<FurtherDetailsFormData>();

  const onSubmit = handleSubmit((formData) => {
    onSave(formData);
  });

  const defaultPriceTier = priceTier
    ? priceTier
    : price
    ? `${courseUtils.getTierFromPrice(parseInt(price))}`
    : undefined;

  useEffect(() => {
    // Reset the state as default after state change (successful update)
    reset({
      courseDuration,
      accessDuration,
      price: price ? `${parseInt(price)}` : undefined,
      priceTier: defaultPriceTier,
      category,
      classType,
    });
  }, [
    reset,
    courseDuration,
    accessDuration,
    price,
    priceTier,
    category,
    classType,
  ]);

  hooks.useDeepEqualEffect(() => {
    // `categories` might not exist when first loaded, and since our selected
    // category is derived from `categories` we must therefore reset the form
    // when `catgories` changes or is loaded
    reset({ category });
  }, [categories]);

  const priceValue = watch('price') || price ? `${parseInt(price)}` : undefined;
  const priceError = price && !priceValue;

  const categoryValue = watch('category');

  const country = userProfile?.country || 'US';
  // @ts-ignore
  const localCurrency = COUNTRY_CURRENCIES[country];
  let currency = 'USD';
  if (
    localCurrency &&
    Object.keys(CURRENCY_POSITIONS).includes(localCurrency)
  ) {
    currency = localCurrency;
  }

  if (visibleCurrency) {
    currency = visibleCurrency;
  }

  const priceTierOptions = Object.keys(PRICING_MATRIX).reduce(
    (acc, c) => ({
      ...acc,
      [c]: `${
        // @ts-ignore
        CURRENCY_POSITIONS[currency] === 'before'
          ? // @ts-ignore
            CURRENCY_SYMBOLS[currency]
          : ''
        // @ts-ignore
      }${PRICING_MATRIX[c][currency]}${
        // @ts-ignore
        CURRENCY_POSITIONS[currency] === 'after'
          ? // @ts-ignore
            ` ${CURRENCY_SYMBOLS[currency]}`
          : ''
      }`,
    }),
    {} as { [key: string]: string }
  );

  return (
    <EditCard
      onSave={onSubmit}
      onCancel={() => {
        onCancel();
        clearError();
        // TODO: Reset Select
        reset({
          courseDuration,
          accessDuration,
          price: price ? `${parseInt(price)}` : undefined,
          priceTier: defaultPriceTier,
          category,
          classType,
        });
      }}
      saveDisabled={Boolean(
        !formState.dirty ||
          errors.price ||
          errors.priceTier ||
          errors.courseDuration ||
          errors.classType ||
          priceError
      )}
      isDisabled={isDisabled}
      isUpdating={isUpdating}
      overflow="visible"
    >
      <LabelSelect
        isDisabled={isDisabled}
        id="category"
        placeholder="Select a category..."
        name="category"
        label="Category"
        error={!!errors.category}
        registerInputRef={register({
          required: true,
        })}
        defaultValue={categories[category]}
        options={categories}
        isLoading={isLoading}
        color={categoryValue ? 'text.default' : 'text.muted'}
        // tooltip="course_category"
      />
      {showAdvancedFields && (
        <>
          <Flex>
            <LabelInput
              isDisabled={isDisabled}
              id="accessDuration"
              name="accessDuration"
              label="Access Duration"
              type="number"
              error={!!errors.accessDuration}
              errorMessage="Please enter a valid number less than 52"
              registerInputRef={register()}
              defaultValue={accessDuration}
              unit="weeks"
              isLoading={isLoading}
              tooltip="course_access_duration"
              helpText="The number of weeks that students have access to the course. Enter 0 to provide lifetime access."
            />
          </Flex>
        </>
      )}
      {PLATFORM === 'steppit' ? (
        <>
          <LabelSelect
            id="priceTier"
            placeholder="-"
            name="priceTier"
            label="Price Tier"
            helpText={
              <Box position="relative">
                <Text>
                  The price of a place on this course (only applicable if you
                  plan to sell places). Pricing will adapt to the learner's
                  local currency according to Steppit's{' '}
                  <chakra.a
                    color="text.primary"
                    fontWeight="semibold"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://cdn.steppit.com/media/public/steppit/steppit-price-tier-matrix-0124.pdf"
                  >
                    Price Tier Matrix
                  </chakra.a>
                  .
                </Text>
                <Menu>
                  <MenuButton
                    as={Button}
                    size="xs"
                    secondary
                    icon="ArrowDropDown"
                    iconPosition="right"
                    mt={3}
                  >
                    {/* @ts-ignore */}
                    {`Viewing price in ${currency} (${CURRENCY_SYMBOLS[currency]})`}
                  </MenuButton>
                  <MenuList>
                    <MenuOptionGroup
                      // @ts-ignore
                      onChange={(value) => setVisibleCurrency(value)}
                      defaultValue={currency}
                      title="View price in:"
                      type="radio"
                    >
                      {Object.keys(CURRENCY_SYMBOLS).map((s) => (
                        <MenuItemOption
                          key={`currency-option-${s}`}
                          value={s}
                          // @ts-ignore
                        >{`${s} (${CURRENCY_SYMBOLS[s]})`}</MenuItemOption>
                      ))}
                    </MenuOptionGroup>
                  </MenuList>
                </Menu>
                <Text mt={4} fontStyle="italic">
                  Note: Steppit will retrieve local taxes at the point of sale
                  before assigning revenue. Tax may be added onto your course
                  price or included within the price depending on the learner's
                  location and local currency.
                </Text>
              </Box>
            }
            isLoading={isLoading}
            // @ts-ignore
            defaultValue={priceTier}
            error={Boolean(errors.priceTier)}
            errorMessage={
              priceError ? 'Your course price must be at least £5' : ''
            }
            options={priceTierOptions}
            registerInputRef={register()}
            unsorted
            autoFocus={focusParam === 'price'}
          />
        </>
      ) : (
        <LabelInput
          id="price"
          name="price"
          label="Price (£)"
          defaultValue={price}
          helpText="The price of places sold for this course"
          unit="GBP"
          isDisabled
          isLoading={isLoading}
        />
      )}
    </EditCard>
  );
};

export default FurtherDetailsCard;
