const RESOURCE_CATEGORIES = {
  basics: {
    icon: 'RocketLaunch',
    label: 'Steppit Basics',
    path: '/resources/basics',
  },
  planning: {
    icon: 'Category',
    label: 'Planning a Course',
    path: '/resources/plannning-courses',
  },
  building: {
    icon: 'BubbleChart',
    label: 'Building a Course',
    path: '/resources/building-courses',
  },
  managing: {
    icon: 'GroupWork',
    label: 'Managing Classes',
    path: '/resources/managing-classes',
  },
};

const RESOURCES_LIST = {
  trainingAssistant: {
    path: '/resources/how-to-train-your-ai-assistant/',
    label: 'Training Your Assistant',
    category: RESOURCE_CATEGORIES['planning'],
    hasSession: false,
    forTier: 1,
  },
  planCourse: {
    path: '/resources/how-to-plan-your-course-with-ai/',
    label: 'Planning Courses with AI',
    category: RESOURCE_CATEGORIES['planning'],
    hasSession: false,
    forTier: 0,
  },
  planSession: {
    path: '/resources/how-to-plan-your-first-session-ai/',
    label: 'Planning Sessions with AI',
    category: RESOURCE_CATEGORIES['planning'],
    hasSession: true,
    forTier: 0,
  },
  defineSession: {
    path: '/resources/what-is-a-session/',
    label: 'What Is a Session?',
    category: RESOURCE_CATEGORIES['basics'],
    hasSession: false,
    forTier: 0,
  },
  defineUnit: {
    path: '/resources/what-is-a-unit/',
    label: 'What Is a Unit?',
    category: RESOURCE_CATEGORIES['basics'],
    hasSession: false,
    forTier: 0,
  },
  stepByStep: {
    path: '/resources/step-by-step-teaching-explained/',
    label: 'Step By Step Explained',
    category: RESOURCE_CATEGORIES['basics'],
    hasSession: true,
    forTier: 0,
  },
  channelSetup: {
    path: '/resources/how-to-set-up-your-channel/',
    label: 'Setting Up Your Channel',
    category: RESOURCE_CATEGORIES['basics'],
    hasSession: false,
    forTier: 0,
  },
  uploadingContent: {
    path: '/resources/creating-and-uploading-content/',
    label: 'Uploading Content',
    category: RESOURCE_CATEGORIES['building'],
    hasSession: false,
    forTier: 0,
  },
  equipment: {
    path: '/resources/recommended-equipment-for-creating-your-online-course/',
    label: 'Recommended Equipment',
    category: RESOURCE_CATEGORIES['building'],
    hasSession: false,
    forTier: 0,
  },
  creatingClasses: {
    path: '/resources/how-to-set-up-classes-and-the-different-types/',
    label: 'Creating Classes',
    category: RESOURCE_CATEGORIES['managing'],
    hasSession: false,
    forTier: 0,
  },
  enrollingLearners: {
    path: '/resources/enrolling-learners-manually/',
    label: 'Enrolling Learners Manually',
    category: RESOURCE_CATEGORIES['managing'],
    hasSession: true,
    forTier: 1,
  },
  mentoringClasses: {
    path: '/resources/how-to-mentor-your-classes-with-feedback-and-chat/',
    label: 'Mentoring Classes',
    category: RESOURCE_CATEGORIES['managing'],
    hasSession: true,
    forTier: 0,
  },
  certificates: {
    path: '/resources/how-to-send-learners-course-completion-certificates/',
    label: 'Sending Certificates',
    category: RESOURCE_CATEGORIES['managing'],
    hasSession: false,
    forTier: 1,
  },
  learnerExperience: {
    path: '/resources/learner-experience/',
    label: 'The Learner Experience',
    category: RESOURCE_CATEGORIES['managing'],
    hasSession: false,
    forTier: 0,
  },
};

export type ResourceSlug = keyof typeof RESOURCES_LIST;

interface BaseResource {
  path: string;
  label: string;
  category: {
    icon: string;
    label: string;
    path: string;
  };
  hasSession: boolean;
  forTier: number;
}

export interface Resource extends BaseResource {
  slug: ResourceSlug;
}

// @ts-ignore
export const RESOURCES: Record<AssistantToolSlug, AssistantTool> = Object.keys(
  RESOURCES_LIST
).reduce(
  // @ts-ignore
  (acc: BaseResource, k: ResourceSlug) => {
    const tool = RESOURCES_LIST[k];
    return { ...acc, [k]: { ...tool, slug: k } };
  },
  {}
);
