import { callAPI, callAPIWithTeam } from 'utils';
import {
  FetchListAction,
  JoinListAction,
  UpdateListRecipientAction,
} from 'types/common';

import { MailAT } from 'redux/actionTypes/common';
import API from 'constants/api';

export const joinList = (
  // Can be list hash or course slug
  hash: string,
  data: { name?: string; email: string }
) => {
  return callAPI<JoinListAction>({
    endpoint: API.common.joinList(hash),
    method: 'POST',
    body: data,
    types: [
      MailAT.JOIN_LIST_REQUEST,
      MailAT.JOIN_LIST_SUCCESS,
      MailAT.JOIN_LIST_FAILURE,
    ],
    meta: { toast: { success: false } },
  });
};

export const fetchList =
  ({
    fetchNextPage,
    url,
    slug, // Course slug
  }: {
    fetchNextPage?: boolean;
    url?: string | undefined;
    slug: string;
  }) =>
  // @ts-ignore
  async (dispatch: Dispatch) =>
    dispatch(
      callAPIWithTeam<FetchListAction>({
        types: [
          MailAT.FETCH_LIST_REQUEST,
          MailAT.FETCH_LIST_SUCCESS,
          MailAT.FETCH_LIST_FAILURE,
        ],
        endpoint: url || API.common.fetchList(slug),
        method: 'GET',
        meta: {
          toast: {
            error: false,
          },
        },
        fetchNextPage: fetchNextPage
          ? // @ts-ignore
            async (url) => {
              return await dispatch(
                fetchList({ fetchNextPage: true, url, slug })
              );
            }
          : null,
      })
    );

export const updateListRecipient = (
  id: number,
  status: 'active' | 'inactive'
) => {
  return callAPIWithTeam<UpdateListRecipientAction>({
    endpoint: API.common.updateListRecipient(id),
    method: 'PATCH',
    body: { status },
    types: [
      MailAT.UPDATE_LIST_RECIPIENT_REQUEST,
      MailAT.UPDATE_LIST_RECIPIENT_SUCCESS,
      MailAT.UPDATE_LIST_RECIPIENT_FAILURE,
    ],
    meta: { toast: { success: false } },
  });
};
