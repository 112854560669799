import React, { useState } from 'react';
import { NAV_HEIGHT } from 'containers/AppHeader';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';

import { Box, Flex, Stack, Button } from '@workshop/ui';

interface Props {
  bg?: string;
  tabs: {
    slug: string;
    name: string;
    highlight?: 'blue' | 'green';
    linkTo?: string;
  }[];
  colors?: {
    bg: string;
    border: string;
    surface: string;
    activeBg: string;
    activeHoverBg: string;
    activeText: string;
    inactiveText: string;
    inactiveHoverText: string;
    inactiveHoverBg: string;
  };
  showTopPadding?: boolean;
  isApp?: boolean;
}

const ScrollNav: React.FC<Props> = ({
  tabs,
  colors,
  showTopPadding,
  bg = 'background.default',
  isApp,
}) => {
  const [activeTab, setActiveTab] = useState(tabs[0].slug);
  const topHeight = isApp ? 0 : NAV_HEIGHT;
  return (
    <Flex
      mb={8}
      bg={bg}
      // backgroundColor={colors ? colors.surface : 'background.tint3'}
      borderBottomWidth="1px"
      borderColor={colors ? colors.border : 'border.default'}
      position="sticky"
      top={topHeight}
      zIndex={3}
      width={{ base: '100vw', md: 'auto' }}
    >
      {/* {colors && (
        <Flex
          position="absolute"
          top={0}
          right={0}
          bottom={0}
          left={0}
          zIndex={0}
        >
          <Flex flex={1} bg={colors.bg} opacity={0.14} />
        </Flex>
      )} */}

      {showTopPadding && !isApp && (
        <Box
          position="absolute"
          top={-topHeight}
          left={{ base: 0, md: '-30px' }}
          right={{ base: 0, md: '-30px' }}
          height={topHeight + 35}
          bg={bg}
          // backgroundColor={colors ? colors.surface : 'background.tint3'}
        >
          {/* {colors && (
            <Flex
              position="absolute"
              top={0}
              right={0}
              bottom={0}
              left={0}
              zIndex={0}
            >
              <Flex flex={1} bg={colors.bg} opacity={0.14} />
            </Flex>
          )} */}
        </Box>
      )}

      <Box
        display={{ base: 'block', md: 'none' }}
        position="absolute"
        top={0}
        right={0}
        bottom={0}
        width="30px"
        bgGradient={`linear(to-r, transparent, ${bg})`}
        zIndex={1}
      />
      <Stack
        direction="row"
        position="relative"
        marginBottom="-1px"
        paddingX={{ base: 'defaultMargin', md: 0 }}
        paddingRight="30px"
        paddingTop={2}
        paddingBottom={2}
        maxWidth="100vw"
        overflowX="scroll"
        sx={{
          '-ms-overflow-style': 'none',
          'scrollbar-width': 'none',
          '::-webkit-scrollbar': { display: 'none' },
        }}
        flex={1}
      >
        {tabs.map((tab) => {
          const isActive = tab.slug === activeTab;
          const button = (
            <Button
              size="sm"
              {...(isActive
                ? {
                    variant: 'solid',
                    ...(colors
                      ? {
                          bg: colors.activeBg,
                          color: colors.activeText,
                          _hover: {
                            bg: colors.activeHoverBg,
                          },
                          _active: {
                            bg: colors.activeHoverBg,
                          },
                        }
                      : {}),
                  }
                : tab.highlight
                ? {
                    colorScheme: tab.highlight,
                  }
                : {
                    variant: 'ghost',
                    ...(colors
                      ? {
                          color: colors.inactiveText,
                          _hover: {
                            color: colors.inactiveHoverText,
                            bg: colors.inactiveHoverBg,
                          },
                          _active: {
                            color: colors.inactiveHoverText,
                            bg: colors.inactiveHoverBg,
                          },
                        }
                      : {}),
                  })}
            >
              {tab.name}
            </Button>
          );
          return (
            <>
              {tab.highlight && <Flex flex={1} />}
              {tab.linkTo ? (
                <Link to={tab.linkTo}>{button}</Link>
              ) : (
                <ScrollLink
                  to={tab.slug}
                  smooth={true}
                  spy={true}
                  onSetActive={() => {
                    setActiveTab(tab.slug);
                  }}
                  offset={-topHeight - 40}
                >
                  {button}
                </ScrollLink>
              )}
            </>
          );
        })}
      </Stack>
    </Flex>
  );
};

export default ScrollNav;
