import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { RouteComponentProps, Link } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import { GlobalState } from 'types';
import { ICourseListItem } from 'types/cms';
import { CourseDetails } from 'types/learner';

import { PLATFORM } from 'constants/env';
import { PLATFORM_DISPLAY_NAME } from 'constants/common';
import { PRO_ORGS } from 'constants/organisation';

import navRoutes from 'navigation/Routes';

import { getParamFromUrl, analytics } from 'utils';

import { courseActions as learnerCourseActions } from 'redux/actions/learner';
import { courseActions, enrolmentActions } from 'redux/actions/cms';
import { getLicensedCourses } from 'redux/selectors/course';
import { useCurrentTeamProfile } from 'redux/selectors';

import { ICoursePublishStatus } from 'constants/courses';
import { CREATE_COURSE_WHITELIST } from 'constants/organisation';

import { Text, Card, Flex, Box, Button, chakra } from '@workshop/ui';

import { CatalogueSkeleton } from 'screens/cms/CourseCatalogue/src/CatalogueSkeleton';
import { ScreenWrapper } from 'screens/common/ScreenWrapper';

import { SectionTitle, InformationCard, ButtonGrid } from 'components/Common';
import { ItemLarge } from 'components/ListItem';

import CreateCourseModal from './CreateCourseModal';
import NewSessionModal from './NewSessionModal';

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux, RouteComponentProps {}

const getBackgroundColor = (status: ICoursePublishStatus) => {
  switch (status) {
    case 'published':
      return 'background.success';

    case 'in_review':
      return 'background.warning';
  }

  return 'background.tint2';
};

const getColor = (status: ICoursePublishStatus) => {
  switch (status) {
    case 'published':
      return 'text.success';

    case 'in_review':
      return 'text.warning';
  }

  return 'text.muted';
};

const CourseCatalogue: React.FC<Props> = ({
  courses,
  licensedCourses,
  licenses,
  currentTeam,
  location,
  ui,
  history,
}) => {
  const dispatch = useDispatch();
  const [isUpdating, setIsUpdating] = useState(false);

  const popupParam = getParamFromUrl(location, 'p');

  const [isNewCourseOpen, setIsNewCourseOpen] = useState(
    popupParam === 'course'
  );
  const [isNewSessionOpen, setIsNewSessionOpen] = useState(
    popupParam === 'session'
  );

  const currentTeamProfile = useCurrentTeamProfile();
  const currentTeamOrg = currentTeamProfile?.id;
  const isPro = Boolean(
    currentTeamProfile?.isPro || (currentTeam && PRO_ORGS.includes(currentTeam))
  );

  useEffect(() => {
    const loadData = async () => {
      await dispatch(courseActions.list({ fetchNextPage: true }));
    };
    loadData();
    dispatch(enrolmentActions.listLicenses());
  }, [dispatch]);

  useEffect(() => {
    if (licenses.length > 0) {
      let coursesToFetch: string[] = [];
      licenses.forEach((l) => {
        const slugs = l.courses.map((c) => c.slug);
        coursesToFetch = [...new Set([...coursesToFetch, ...slugs])];
      });
      coursesToFetch.forEach((courseSlug) => {
        dispatch(learnerCourseActions.retrieve(courseSlug));
      });
    }
  }, [licenses.length]);

  const handleSave = async (data: Partial<ICourseListItem>) => {
    setIsUpdating(true);
    const newCourseRes = await dispatch(courseActions.create(data));
    setIsUpdating(false);
    setIsNewCourseOpen(false);
    // @ts-ignore
    if (newCourseRes?.payload?.entities?.courses) {
      const newCourse =
        // @ts-ignore
        newCourseRes.payload.entities.courses[newCourseRes.payload.result];
      if (newCourse.courseType === 'session') {
        analytics.track('Standalone Session Created');
        history.push(
          navRoutes.cms.standaloneSession.path(
            newCourse.standaloneModule.toString()
          )
        );
      } else {
        analytics.track('Course Created From Scratch');
        history.push({
          pathname: navRoutes.cms.editCourse.path(newCourse.id.toString()),
          search: 't=new',
        });
      }
    }
  };

  const isLoading = ui.courseList.loading && isEmpty(courses);

  const licensedCoursesByOrg = licensedCourses
    ? Object.values(licensedCourses).reduce(
        (
          obj: {
            [orgId: string]: {
              id: number;
              name: string;
              courses: CourseDetails[];
              contactEmail: string | null;
            };
          },
          value
        ) => {
          const key = value.organisation.id.toString();
          if (obj[key] == null) {
            obj[key] = {
              id: value.organisation.id,
              name: value.organisation.name,
              courses: [],
              contactEmail: value.organisation.contactEmail,
            };
          }

          obj[key].courses.push(value);
          return obj;
        },
        {}
      )
    : null;

  const myCourses = Object.values(courses).filter(
    (c) => c.organisation === currentTeamOrg && c.courseType !== 'session'
  );

  const mySessions = Object.values(courses).filter(
    (c) =>
      c.organisation === currentTeamOrg &&
      c.courseType === 'session' &&
      !!c.standaloneModule
  );

  const showCreateCourse =
    PLATFORM === 'steppit' ||
    (currentTeam && CREATE_COURSE_WHITELIST.includes(currentTeam));

  const showSessions = PLATFORM === 'steppit';

  return (
    <ScreenWrapper>
      {isPro && (
        <Box mx={{ base: 'defaultMargin', md: 0 }}>
          <InformationCard id="pro_content" mb={6} />
        </Box>
      )}
      {licensedCoursesByOrg && (
        <>
          <Box mx={{ base: 'defaultMargin', md: 0 }}>
            <InformationCard id="course_preview_section" mb={6} />
          </Box>
          {Object.values(licensedCoursesByOrg).map((org) => {
            return (
              <Box key={`library-org-${org.id}`} mb={4}>
                <SectionTitle title={`Courses from ${org.name}`} />
                {org.contactEmail && (
                  <Text
                    marginX={{ base: 'defaultMargin', md: 0 }}
                    color="text.muted"
                    fontSize="sm"
                    mb={4}
                  >
                    {`If you have any questions for ${org.name}, please contact `}
                    <chakra.a
                      href={`mailto:${org.contactEmail}`}
                      color="text.primary"
                      fontWeight="semibold"
                    >
                      {org.contactEmail}
                    </chakra.a>
                    {'.'}
                  </Text>
                )}
                <Flex
                  flexDirection="column"
                  position="relative"
                  mb="defaultMargin"
                >
                  {Object.values(org.courses).map((course) => {
                    return (
                      <Link
                        key={`licensed-${course.slug}`}
                        to={navRoutes.cms.previewCourse.path(course.id)}
                        style={{ display: 'block' }}
                      >
                        <Card
                          direction="column"
                          mb="defaultMargin"
                          padding={0}
                          cursor="pointer"
                          _hover={{
                            bg: 'background.tint1',
                            transform: { base: 'auto', md: 'scale(1.01)' },
                          }}
                          transition={{ base: 'none', md: 'transform 0.3s' }}
                        >
                          <ItemLarge
                            title={course.title}
                            image={course.imageLandscapeThumbnail}
                            buttonLabel="Preview Course"
                            buttonLink={navRoutes.cms.previewCourse.path(
                              course.id
                            )}
                            tag="Agreement Active"
                            tagBg="background.info"
                            tagColor="text.info"
                          />
                        </Card>
                      </Link>
                    );
                  })}
                </Flex>
              </Box>
            );
          })}
        </>
      )}

      {isLoading ? (
        <CatalogueSkeleton />
      ) : myCourses.length > 0 ? (
        <Flex direction="column" mb={8}>
          <Box>
            <Flex alignItems="center" mb={showCreateCourse ? 2 : 3}>
              <SectionTitle title="My Courses" flex={1} mb={0} />
              {showCreateCourse && (
                <Button
                  onClick={() => setIsNewCourseOpen(true)}
                  icon={PLATFORM === 'steppit' ? 'BubbleChart' : 'Class'}
                  size="sm"
                  mx={{ base: 'defaultMargin', md: 0 }}
                  variant="outline"
                >
                  New Course
                </Button>
              )}
            </Flex>
            {myCourses.map((course) => {
              return (
                <Link
                  to={navRoutes.cms.editCourse.path(course.id.toString())}
                  style={{ display: 'block' }}
                >
                  <Card
                    key={course.id}
                    direction="column"
                    mb="defaultMargin"
                    padding={0}
                    cursor="pointer"
                    _hover={{
                      bg: 'background.tint1',
                      transform: { base: 'auto', md: 'scale(1.01)' },
                    }}
                    transition={{ base: 'none', md: 'transform 0.3s' }}
                  >
                    <ItemLarge
                      key={course.id}
                      title={course.title}
                      subtitle={`${course.numUnits} units  ·  ${course.numModules} sessions`}
                      image={course.imageLandscapeThumbnail}
                      buttonLabel={
                        course.status === 'published' || PLATFORM === 'workshop'
                          ? 'Edit Course'
                          : 'Keep Building'
                      }
                      buttonLink={navRoutes.cms.editCourse.path(
                        course.id.toString()
                      )}
                      buttonSecondary={
                        course.status === 'published' || PLATFORM === 'workshop'
                      }
                      tag={
                        course.status === 'published' || PLATFORM === 'workshop'
                          ? `${course.status}${
                              course.isLockedForEditing ? ' - Locked' : ''
                            }`
                          : undefined
                      }
                      tagBg={getBackgroundColor(course.status)}
                      tagColor={getColor(course.status)}
                      progress={
                        course.status === 'published' || PLATFORM === 'workshop'
                          ? undefined
                          : course.buildProgress
                      }
                      isPublic={
                        course.status === 'published' && PLATFORM === 'steppit'
                          ? course.isPublic
                          : null
                      }
                      isVisible={
                        course.status === 'published' && PLATFORM === 'steppit'
                          ? course.isVisible
                          : null
                      }
                      imagePlaceholderIcon="BubbleChart"
                    />
                  </Card>
                </Link>
              );
            })}{' '}
          </Box>
        </Flex>
      ) : null}

      {showSessions && (
        <>
          {isLoading ? (
            <CatalogueSkeleton />
          ) : mySessions.length > 0 ? (
            <Flex direction="column" mb={12}>
              <Box>
                <Flex alignItems="center" mb={2}>
                  <SectionTitle title="My Sessions" flex={1} mb={0} />
                  <Button
                    onClick={() => setIsNewSessionOpen(true)}
                    icon="Pending"
                    size="sm"
                    mx={{ base: 'defaultMargin', md: 0 }}
                    variant="outline"
                  >
                    New Session
                  </Button>
                </Flex>
                {mySessions.map((course) => {
                  if (!course.standaloneModule) return null;
                  return (
                    <Link
                      to={navRoutes.cms.standaloneSession.path(
                        course.standaloneModule.toString()
                      )}
                      style={{ display: 'block' }}
                    >
                      <Card
                        key={course.id}
                        direction="column"
                        mb="defaultMargin"
                        padding={0}
                        cursor="pointer"
                        _hover={{
                          bg: 'background.tint1',
                          transform: { base: 'auto', md: 'scale(1.01)' },
                        }}
                        transition={{ base: 'none', md: 'transform 0.3s' }}
                      >
                        <ItemLarge
                          key={course.id}
                          title={course.title}
                          image={course.imageLandscapeThumbnail}
                          buttonLabel="Edit Session"
                          buttonLink={navRoutes.cms.standaloneSession.path(
                            course.standaloneModule.toString()
                          )}
                          tag={`${course.status}${
                            course.isLockedForEditing ? ' - Locked' : ''
                          }`}
                          tagBg={getBackgroundColor(course.status)}
                          tagColor={getColor(course.status)}
                          imagePlaceholderIcon="Pending"
                        />
                      </Card>
                    </Link>
                  );
                })}{' '}
              </Box>
            </Flex>
          ) : null}
        </>
      )}
      {showCreateCourse && showSessions && (
        <ButtonGrid
          mb={8}
          mx={{ base: 'defaultMargin', md: 0 }}
          items={[
            {
              slug: 'newSession',
              name: 'Make a Quick Session',
              description: `Create a standalone ${PLATFORM_DISPLAY_NAME} session to share online.`,
              icon: 'Pending',
              onClick: () => setIsNewSessionOpen(true),
            },
            {
              slug: 'newCourse',
              name: 'Build a Full Course',
              description: `Combine multiple sessions into a course to share online or run in private classes.`,
              icon: 'BubbleChart',
              onClick: () => setIsNewCourseOpen(true),
            },
          ]}
        />
      )}
      <CreateCourseModal
        onSave={handleSave}
        isOpen={isNewCourseOpen}
        onClose={() => setIsNewCourseOpen(false)}
        isUpdating={isUpdating}
        title="New Course"
      />
      <NewSessionModal
        onSave={async (data) => {
          await handleSave({ ...data, courseType: 'session' });
          setIsNewSessionOpen(false);
        }}
        isOpen={isNewSessionOpen}
        onClose={() => setIsNewSessionOpen(false)}
        isUpdating={isUpdating}
        title="New Session"
      />
    </ScreenWrapper>
  );
};

const mapStateToProps = (state: GlobalState) => {
  const {
    organisation: { currentTeam, teamProfiles },
    learner: {
      courses: {
        courses: { detail: courseState },
      },
    },
    cms: {
      enrolment: { license: licenseState },
    },
  } = state;

  const licensedCourses = currentTeam
    ? getLicensedCourses(
        courseState,
        licenseState,
        teamProfiles[currentTeam]?.id
      )
    : {};
  const licenses = Object.values(licenseState);
  return {
    courses: state.cms.course.courseList,
    licensedCourses,
    licenses,
    currentTeam,
    ui: state.ui.course,
  };
};

const connector = connect(mapStateToProps);

export default connector(CourseCatalogue);
