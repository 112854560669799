import React from 'react';
import { Text, Flex, Box, Card, Switch, chakra } from '@workshop/ui';

import navRoutes from 'navigation/Routes';

import { PLATFORM_DISPLAY_NAME } from 'constants/common';

import { analytics } from 'utils';

interface CookiesProps {}

const Cookies: React.FC<CookiesProps> = () => {
  const cookieChoice = localStorage.getItem('cookies');

  return (
    <>
      <Text fontSize="sm" mb={3}>
        {`${PLATFORM_DISPLAY_NAME} uses cookies to offer you a better experience. See our `}
        <chakra.a
          href={navRoutes.global.cookies.path()}
          target="_blank"
          rel="noreferrer noopener"
          fontWeight="semibold"
          color="text.primary"
          _focusVisible={{ outline: 'none' }}
        >
          Cookie Policy
        </chakra.a>
        {' for details.'}
      </Text>

      <Card padding="defaultPadding">
        <Box w="100%">
          <Flex flex={1} mb={3}>
            <Box flex={1}>
              <Text color="text.muted">Essential</Text>
              <Text fontSize="sm">
                Essential for the site to function. Always On.
              </Text>
            </Box>
            <Flex>
              <Switch isDisabled defaultChecked />
            </Flex>
          </Flex>
          <Flex flex={1} mb={3}>
            <Box flex={1}>
              <Text color="text.muted">Analytics</Text>
              <Text fontSize="sm">
                Used to measure usage and improve your experience.
              </Text>
            </Box>
            <Flex
              onClick={() => {
                if (cookieChoice === 'analyticsOnly') {
                  localStorage.setItem('cookies', 'reject');
                  analytics.setOptOut(false, false);
                } else if (cookieChoice === 'marketingOnly') {
                  localStorage.setItem('cookies', 'accept');
                  analytics.setOptOut(true, true);
                } else {
                  localStorage.setItem('cookies', 'analyticsOnly');
                  analytics.setOptOut(true, false);
                }
                window.location.reload();
              }}
            >
              <Switch
                isChecked={
                  cookieChoice === 'accept' || cookieChoice === 'analyticsOnly'
                }
              />
            </Flex>
          </Flex>
          <Flex flex={1}>
            <Box flex={1}>
              <Text color="text.muted">Marketing</Text>
              <Text fontSize="sm">Used for targeted advertising.</Text>
            </Box>
            <Flex
              onClick={() => {
                if (cookieChoice === 'marketingOnly') {
                  localStorage.setItem('cookies', 'reject');
                  analytics.setOptOut(false, false);
                } else if (cookieChoice === 'analyticsOnly') {
                  localStorage.setItem('cookies', 'accept');
                  analytics.setOptOut(true, true);
                } else {
                  localStorage.setItem('cookies', 'marketingOnly');
                  analytics.setOptOut(false, true);
                }
                window.location.reload();
              }}
            >
              <Switch
                isChecked={
                  cookieChoice === 'accept' || cookieChoice === 'marketingOnly'
                }
              />
            </Flex>
          </Flex>
        </Box>
      </Card>
    </>
  );
};

export default Cookies;
