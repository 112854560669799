import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import isEmail from 'validator/lib/isEmail';

import navRoutes from 'navigation/Routes';

import { GlobalState } from 'types';
import { SignupData } from 'types/common';

import { PLATFORM_DISPLAY_NAME } from 'constants/common';
import { PERSONAS } from 'constants/settings';

import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  Radio,
  RadioGroup,
  Checkbox,
  Text,
  Skeleton,
  VStack,
  Divider,
  Link,
  useColorModeValue,
} from '@workshop/ui';

import { authActions, uiAction } from 'redux/actions/common';
import { useAuthError } from 'redux/selectors/auth';

import FacebookButton, {
  FacebookResponse,
} from 'components/SocialAuth/FacebookButton';
import AppleButton, { AppleResponse } from 'components/SocialAuth/AppleButton';
import { LabelSelect } from 'components/Common';

interface SignupFormData extends Omit<SignupData, 'marketingConsent'> {
  marketingConsent?: number;
  persona?: string;
}

interface SignupFormProps {
  buttonLabel?: string;
  buttonIcon?: string;
  email?: string;
  isLoading?: boolean;
  onSubmit: (data: SignupData) => Promise<void>;
  hideSocialButtons?: boolean;
  forceEmail?: boolean;
  emailBold?: boolean;
  namePlaceholder?: string;
  pwPlaceholder?: string;
  bigButton?: boolean;
  withPersona?: boolean;
}

const SignupForm: React.FC<SignupFormProps> = ({
  buttonLabel = 'Sign Up',
  buttonIcon,
  email,
  isLoading: isLoadingProp = false,
  onSubmit,
  hideSocialButtons = false,
  forceEmail = true,
  emailBold = false,
  namePlaceholder = 'Full Name',
  pwPlaceholder = 'Password',
  bigButton = false,
  withPersona = false,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [facebookLoading, setFacebookLoading] = React.useState(false);
  const [appleLoading, setAppleLoading] = React.useState(false);
  const [acceptedTerms, setAcceptedTerms] = React.useState(false);

  const { register, handleSubmit, errors, setValue, triggerValidation, watch } =
    useForm<SignupFormData>({
      defaultValues: { email },
    });

  const dispatch = useDispatch();
  const { errorMessage } = useAuthError();
  const isLoading = useSelector(({ auth }: GlobalState) => auth.loading);

  const inputBg = useColorModeValue('background.default', 'background.tint3');

  const onSubmitData = handleSubmit(
    async ({
      email: formEmail,
      marketingConsent,
      name,
      password1,
      persona,
    }) => {
      setIsSubmitting(true);
      await onSubmit({
        email: formEmail || email || '',
        marketingConsent: marketingConsent === 1,
        name,
        password1,
        ...(withPersona ? { persona: persona || 'general' } : {}),
      });
      setIsSubmitting(false);
    }
  );

  useEffect(() => {
    register('marketingConsent', {
      required: { value: true, message: 'Please select one of these options' },
    });
  }, []);

  const handleFacebookLogin = async (result: FacebookResponse) => {
    if ('status' in result) {
      // TODO: Handle error - should be handled by onError
      return;
    }

    setFacebookLoading(true);
    const { accessToken } = result;
    await dispatch(authActions.socialLogin('facebook', { accessToken }));
    setFacebookLoading(false);
  };

  const handleAppleLogin = async (result: AppleResponse) => {
    if ('error' in result) {
      // TODO: Handle error - should be handled by onError
      return;
    }

    // TODO: TODO: Don't know how to handle
    if ('code' in result) return;

    setAppleLoading(true);
    const { code, id_token } = result.authorization;

    // When the user signs in via Apple for the first time their
    // user details are included in the response from Apple.
    //
    // In all subsequery requests, their details are omitted. In
    // this scenario the user will receive an error when trying
    // to login with Apple if we weren't successful in creating
    // their account the first time around
    //
    // TODO: Come up with a better way of handling the above?
    let userDetails: {
      name: { firstName: string | null; lastName: string | null };
      email: string | null;
    } = {
      name: { firstName: null, lastName: null },
      email: null,
    };

    if (result.user) {
      const { user } = result;
      userDetails = { name: user.name, email: user.email };
    }

    await dispatch(
      authActions.socialLogin('apple', {
        accessToken: code,
        idToken: id_token,
        ...userDetails,
      })
    );
    setAppleLoading(false);
  };

  const handleChange = () => {
    if (errorMessage) {
      dispatch(authActions.clearAuthError);
    }
  };

  const personaValue = watch('persona');

  return (
    <Flex flex={1} width="100%">
      <Flex flexDirection="column" flex={1}>
        {email && forceEmail ? (
          <Text
            textAlign="center"
            {...(emailBold
              ? {
                  fontSize: 'lg',
                  fontWeight: 'bold',
                  mb: 6,
                }
              : {
                  color: 'text.muted',
                  mb: 4,
                })}
          >
            {email}
          </Text>
        ) : email ? (
          <Flex flexDirection="column" mb={2} mt={2}>
            <FormControl isInvalid={Boolean(errors.email)}>
              <Input
                ref={register({
                  required: true,
                  validate: (value) => isEmail(value),
                })}
                id="email"
                name="email"
                onChange={handleChange}
                placeholder="Email"
                defaultValue={email}
                type="email"
                bg={inputBg}
                _placeholder={{
                  color: 'text.muted',
                }}
              />
              <FormErrorMessage>
                Please enter a valid email address
              </FormErrorMessage>
            </FormControl>
          </Flex>
        ) : null}
        <Skeleton isLoaded={!isLoading && !isLoadingProp}>
          <Flex flexDirection="column">
            <FormControl isInvalid={Boolean(errors.name)}>
              <Input
                ref={register({
                  required: {
                    value: true,
                    message: 'Please enter your full name',
                  },
                })}
                id="name"
                name="name"
                onChange={handleChange}
                placeholder={namePlaceholder}
                type="name"
                bg={inputBg}
                _placeholder={{
                  color: 'text.muted',
                }}
              />
              <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
            </FormControl>
          </Flex>
        </Skeleton>

        {!email ? (
          <Flex flexDirection="column" mb={2} mt={2}>
            <FormControl isInvalid={Boolean(errors.email)}>
              <Input
                ref={register({
                  required: true,
                  validate: (value) => isEmail(value),
                })}
                id="email"
                name="email"
                onChange={handleChange}
                placeholder="Email"
                type="email"
                bg={inputBg}
                _placeholder={{
                  color: 'text.muted',
                }}
              />
              <FormErrorMessage>
                Please enter a valid email address
              </FormErrorMessage>
            </FormControl>
          </Flex>
        ) : (
          <Flex mb={2} />
        )}

        <Flex flexDirection="column" mb={2}>
          <FormControl isInvalid={Boolean(errors.password1)}>
            <Input
              ref={register({
                required: {
                  value: true,
                  message: 'Please enter your password',
                },
                minLength: {
                  value: 6,
                  message: 'Your password must be at least 6 characters',
                },
              })}
              id="password1"
              name="password1"
              onChange={handleChange}
              placeholder={pwPlaceholder}
              type="password"
              bg={inputBg}
              _placeholder={{
                color: 'text.muted',
              }}
            />
            <FormErrorMessage>{errors.password1?.message}</FormErrorMessage>
          </FormControl>
        </Flex>
        {withPersona && (
          <Flex flexDirection="column" mt={3}>
            <FormControl>
              <LabelSelect
                id="persona"
                name="persona"
                label="What kind of course creator are you?"
                labelPosition="top"
                color={personaValue ? 'text.default' : 'text.placeholder'}
                isLoading={isLoading || isLoadingProp}
                registerInputRef={register()}
                options={PERSONAS}
                placeholder="Select the option that fits best..."
                isDisabled={isLoading || isLoadingProp}
                unsorted
              />
            </FormControl>
          </Flex>
        )}

        <FormControl isInvalid={Boolean(errors.marketingConsent)}>
          <RadioGroup
            id="marketingConsent"
            name="marketingConsent"
            mt={4}
            mb="defaultMargin"
            onChange={async (e) => {
              await setValue('marketingConsent', Number(e));
              // If there was previously an error with marketing consent,
              // trigger form validation now that a change has occurred.
              Boolean(errors.marketingConsent) && triggerValidation();
            }}
          >
            <Radio value="1" mb={2}>
              <Text fontSize="xs">
                {`👍 Send me discounts and updates from ${PLATFORM_DISPLAY_NAME}.`}
              </Text>
            </Radio>
            <Radio value="0">
              <Text fontSize="xs">
                {`👎 I don't want discounts and updates from ${PLATFORM_DISPLAY_NAME}.`}
              </Text>
            </Radio>
          </RadioGroup>
          <FormErrorMessage>
            {errors.marketingConsent?.message}
          </FormErrorMessage>
        </FormControl>
        <Divider my={2} />
        <Checkbox
          id="acceptedTerms"
          name="acceptedTerms"
          isChecked={acceptedTerms}
          mt={2}
          mb="defaultMargin"
          onChange={async (e) => setAcceptedTerms(e.target.checked)}
        >
          <Text fontSize="xs">
            {`I understand that by signing up, I agree to ${PLATFORM_DISPLAY_NAME}'s `}
            <Link
              color="text.info"
              href={navRoutes.global.terms.path()}
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Service
            </Link>
            {` and `}
            <Link
              color="text.info"
              href={navRoutes.global.privacy.path()}
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </Link>
            {`, and confirm my age is 18+ or 13+ with a parent or guardian's consent.`}
          </Text>
        </Checkbox>
        <FormControl isInvalid={Boolean(errorMessage)}>
          {errorMessage && (
            <Flex mb={4}>
              <FormErrorMessage>{errorMessage}</FormErrorMessage>
            </Flex>
          )}
        </FormControl>
        <Button
          isDisabled={isLoading || isSubmitting || !acceptedTerms}
          isLoading={isLoading || isSubmitting}
          mt={2}
          onClick={onSubmitData}
          textTransform="initial"
          type="submit"
          variant="solid"
          colorScheme="blue"
          width="100%"
          maxWidth="370px"
          mx="auto"
          {...(buttonIcon
            ? {
                icon: buttonIcon,
                iconPosition: 'right',
              }
            : {})}
          {...(bigButton
            ? {
                size: 'lg',
              }
            : {
                fontSize: 'md',
              })}
        >
          {buttonLabel}
        </Button>
        {!hideSocialButtons && (
          <>
            <Divider marginY="defaultMargin" />
            <VStack justifyContent="center" pb={4}>
              <FacebookButton
                isLoading={facebookLoading}
                isDisabled={facebookLoading}
                callback={handleFacebookLogin}
                onError={() =>
                  dispatch(
                    uiAction.errorToastMessage(
                      'Unable to log in. Please try again.'
                    )
                  )
                }
              />
              <AppleButton
                isLoading={appleLoading}
                isDisabled={appleLoading}
                callback={handleAppleLogin}
                onError={() =>
                  dispatch(
                    uiAction.errorToastMessage(
                      'Unable to log in. Please try again.'
                    )
                  )
                }
              />
            </VStack>
          </>
        )}
      </Flex>
    </Flex>
  );
};

export default SignupForm;
