import React, { useState, useEffect } from 'react';
import { useForm, FormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { FaGem } from 'react-icons/fa';

import { Flex, MdIcon, Text, Box, LinkButton } from '@workshop/ui';

import { SignupData, Organisation } from 'types/common';

import navRoutes from 'navigation/Routes';

import { PERSONAS } from 'constants/settings';

import { analytics } from 'utils';
import { authActions, organisationActions } from 'redux/actions/common';
import {
  useIsAuthenticated,
  useCurrentTeam,
  useIsUserLoading,
  useUser,
} from 'redux/selectors';

import {
  StepsModal,
  LabelInput,
  ImageUpload,
  ProCta,
  LabelSelect,
} from 'components/Common';
import { SignupForm } from 'screens/common/Signup/SignupForm';

interface SignupModalProps {
  isOpen: boolean;
  onClose: () => void;
  onBuild: () => void;
  context: 'build' | 'pro' | '';
}

const SignupModal: React.FC<SignupModalProps> = ({
  isOpen,
  onClose,
  onBuild,
  context,
}) => {
  const dispatch = useDispatch();
  const isLoading = useIsUserLoading();
  const currentTeam = useCurrentTeam();
  const hasAccount = useIsAuthenticated();
  const user = useUser();
  const history = useHistory();
  const location = useLocation();

  const firstName = user?.name.split(' ')[0];
  const hasChannel = hasAccount && !!currentTeam;

  const [orgProfilePic, setOrgProfilePic] = useState('');
  const [orgName, setOrgName] = useState('');
  const [orgPersona, setOrgPersona] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [triggerNextStep, setTriggerNextStep] = useState<boolean | undefined>(
    undefined
  );
  const [triggerOpenProPopup, setTriggerOpenProPopup] = useState<
    boolean | undefined
  >(undefined);

  const planEmail = localStorage.getItem('planEmail');

  const onUpgrade = () => {
    if (context === 'pro') {
      onClose();
    }
    setTriggerOpenProPopup(!triggerOpenProPopup);
  };

  useEffect(() => {
    if (hasChannel && context === 'pro') {
      onUpgrade();
    }
  }, [hasChannel, context]);

  const onSignUp = async ({
    email,
    marketingConsent,
    name,
    password1,
  }: SignupData) => {
    await dispatch(
      authActions.signupRequest({
        email,
        marketingConsent,
        name,
        password1,
      })
    ).then(({ error }) => {
      if (error) return;
      localStorage.removeItem('planEmail');
      dispatch(
        authActions.tokenRequest({ username: email, password: password1 })
      ).then(() => {
        let searchParams = new URLSearchParams();
        searchParams.set('p', context);
        history.push({
          pathname: location.pathname,
          search: searchParams.toString(),
        });
      });
      const fName = name.split(' ')[0];
      const lName =
        name.split(' ').length > 1 ? name.replace(`${fName} `, '') : '';
      analytics.identify({
        set: {
          first_name: fName,
          last_name: lName,
          email: email,
          marketing_consent: Boolean(marketingConsent),
        },
      });
      analytics.track('Signed Up', {
        first_name: fName,
        last_name: lName,
        marketing_consent: Boolean(marketingConsent),
        email: email,
        type: 'invitation',
      });
      analytics.logConversion(dispatch, 'signedUp');
      setTriggerNextStep(!triggerNextStep);
    });
  };

  const orgFormMethods = useForm<Partial<Organisation>>({
    defaultValues: {
      logoDark: orgProfilePic,
      name: orgName,
      persona: orgPersona,
    },
  });

  const {
    register: orgRegister,
    handleSubmit: handleCreateOrgSubmit,
    errors: orgErrors,
    clearError: clearOrgError,
    setValue: setOrgValue,
    reset: resetOrgForm,
  } = orgFormMethods;

  const onImageDrop = (fieldName: string, acceptedFiles: File[]) => {
    setOrgValue(fieldName, acceptedFiles[0]);
    setOrgProfilePic(URL.createObjectURL(acceptedFiles[0]));
  };

  const onCreateOrgSubmit = handleCreateOrgSubmit(async (data) => {
    clearOrgError();
    setIsSubmitting(true);
    const response = await dispatch(
      organisationActions.createOrganisation({
        name: data.name,
        persona: data.persona || 'general',
        ...(data.logoDark ? { logoDark: data.logoDark } : {}),
      })
    );
    if (response.error) {
      setIsSubmitting(false);
      return;
    }
    if (data.logoDark) {
      analytics.track('Channel Profile Picture Uploaded');
    }
    const teamsRes = await dispatch(organisationActions.fetchMyTeams());
    const teams =
      teamsRes?.payload &&
      'results' in teamsRes.payload &&
      teamsRes.payload.results;
    const newTeam =
      teams && teams.length > 0
        ? [...teams].sort((a, b) => b.team - a.team)[0].team
        : null;
    if (newTeam) {
      // @ts-ignore
      dispatch(organisationActions.setCurrentTeam(newTeam));
      localStorage.setItem('defaultTeam', newTeam.toString());
    }
    analytics.track('Channel Created', {
      ...(response.payload && 'id' in response.payload
        ? { org_id: `${response.payload.id}` }
        : {}),
    });
    analytics.logConversion(dispatch, 'createdChannel');
    setIsSubmitting(false);
    if (context === 'build') {
      onBuild();
      onClose();
    }
  });

  if (hasChannel && context === 'build') return null;

  const steps = [
    {
      label: 'Sign Up',
      icon: <MdIcon name="AccountCircle" />,
      nextButtonText: 'Sign Up',
      hideNextButton: true,
      content: (
        <Box pt={6} pb={{ base: 6, md: 0 }}>
          <Text mb={6} color="text.muted">
            {context === 'build'
              ? 'Let’s do this! Start by creating your Steppit account…'
              : 'Ok, let’s get you set up with Pro. Sign up to get started:'}
          </Text>
          <SignupForm
            buttonLabel="Sign Up"
            buttonIcon="ArrowForward"
            onSubmit={onSignUp}
            hideSocialButtons
            forceEmail={false}
            {...(planEmail
              ? {
                  email: planEmail,
                }
              : {})}
          />
          <Flex
            mt={3}
            alignItems="center"
            justifyContent="center"
            color="text.muted"
          >
            <Text mr={1} fontSize="sm">
              Already have an account?
            </Text>
            <LinkButton
              size="sm"
              variant="ghost"
              colorScheme="gray"
              to={navRoutes.public.login.path()}
            >
              Log In
            </LinkButton>
          </Flex>
        </Box>
      ),
    },
    {
      label: 'Create Channel',
      icon: <MdIcon name="AddBusiness" />,
      nextButtonText: context === 'build' ? 'Build Course' : 'Create Channel',
      nextButtonDisabled: !orgName,
      content: (
        <Box py={4}>
          <Text color="text.muted" mb={6}>
            {context === 'build'
              ? 'You’re in! Now we’ll just create a channel to host your course. What do you want to call it?'
              : 'Welcome! Now, what do you want to call your channel?'}
          </Text>
          <FormContext {...orgFormMethods}>
            <Flex
              alignItems={{ base: 'initial', sm: 'center' }}
              flexDirection={{ base: 'column', sm: 'row' }}
            >
              <Flex
                backgroundColor="background.tint1"
                borderRadius="full"
                width={{ base: '80px', md: '100px' }}
                mr={{ base: 'auto', sm: 4 }}
                ml={{ base: 'auto', sm: 0 }}
                mb={{ base: 4, sm: 0 }}
              >
                <ImageUpload
                  id="logoDark"
                  name="logoDark"
                  backgroundColor="transparent"
                  borderRadius="50px"
                  height={{ base: '80px', md: '100px' }}
                  image={orgProfilePic}
                  onDrop={onImageDrop}
                  width="100%"
                  isDisabled={isSubmitting || isLoading}
                  hideText
                />
              </Flex>
              <LabelInput
                id="name"
                name="name"
                helpText="(Don't worry, you can change this later)"
                placeholder={`${
                  firstName
                    ? firstName.charAt(firstName.length - 1) === 's'
                      ? `${firstName}'`
                      : `${firstName}'s`
                    : 'My'
                } Channel`}
                labelPosition="top"
                error={Boolean(orgErrors.name)}
                errorMessage="Please enter a name for your channel"
                registerInputRef={orgRegister({ required: true })}
                value={orgName}
                onChange={(e) => {
                  setOrgValue('name', e.target.value);
                  setOrgName(e.target.value);
                }}
                isDisabled={isSubmitting || isLoading}
              />
            </Flex>
            <Box mt={4}>
              <LabelSelect
                id="persona"
                name="persona"
                label="What kind of course creator are you?"
                labelPosition="top"
                color={orgPersona ? 'text.default' : 'text.placeholder'}
                isLoading={isLoading}
                registerInputRef={orgRegister()}
                options={PERSONAS}
                placeholder="Select the option that fits best..."
                isDisabled={isLoading || isSubmitting}
                unsorted
                onChange={(e) => {
                  setOrgValue('persona', e.target.value);
                  setOrgPersona(e.target.value);
                }}
              />
            </Box>
          </FormContext>
        </Box>
      ),
    },
  ];
  if (context === 'pro') {
    // @ts-ignore
    steps.push({
      label: 'Upgrade',
      icon: <FaGem />,
      nextButtonText: 'Upgrade to Pro',
      content: <Box />,
    });
  }

  return (
    <>
      <StepsModal
        heading={context === 'build' ? 'Build Your Course' : 'Try Pro for Free'}
        isOpen={isOpen && !hasChannel}
        onClose={onClose}
        modalSize="xl"
        onCompleteStep={async (stepIndex: number) => {
          if (stepIndex === 1) {
            try {
              await onCreateOrgSubmit();
            } catch {
              return 'error';
            }
          }
          if (stepIndex === 2) {
            onUpgrade();
          }
        }}
        disablePrev
        bigNext
        forceHorizontalSteps
        hideStepLabels
        triggerNextStep={triggerNextStep}
        minStep={hasChannel ? 2 : hasAccount ? 1 : 0}
        steps={steps}
      />
      <Box height={0}>
        <ProCta
          hideCta
          hideProIcon
          label=""
          triggerOpenPopup={triggerOpenProPopup}
        />
      </Box>
    </>
  );
};

export default SignupModal;
