import React, { useState } from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { parse } from 'node-html-parser';
import moment from 'moment';
import sanitizeHtml from 'sanitize-html';

import { Box, Card, Image, Flex, Skeleton, Text, MdIcon } from '@workshop/ui';

import { GlobalState } from 'types';
import { TopicModel } from 'models/learner';
import { discourseActions } from 'redux/actions/common';

import { discourseUrl } from 'constants/env';

import { TopicComments, ReplyBox } from 'components/TopicComments';
import { ScreenWrapper } from 'screens/common/ScreenWrapper';
import { SectionTitle, RenderHtml } from 'components/Common';
import { UserAvatar } from 'components/UserAvatar';

import { hooks, simplifyName, emojifyText } from 'utils';

// Routing Props
interface MatchParams {
  topicId: string;
}

// Props passed to our component from parents
interface OwnProps extends RouteComponentProps<MatchParams> {}

// Props passed to our component via redux
type PropsFromRedux = ConnectedProps<typeof connector>;

// Combined props we're passing to our component
interface Props extends OwnProps, PropsFromRedux {}

const ClassActivity: React.FC<Props> = ({ topicId, topics }) => {
  const dispatch = useDispatch();
  const [reply, setReply] = useState<string | null>();
  const [submittingReply, setSubmittingReply] = useState<boolean>(false);

  const { discourseDataLoading } = hooks.useLoadingDataState(
    {
      discourseDataLoading: {
        actions: [() => discourseActions.getTopic(parseInt(topicId), true)],
      },
    },
    [topicId]
  );

  const topic = topics[parseInt(topicId)] || null;
  const topicModel = new TopicModel({ topic });

  const replies = topicModel.getNestedReplies();

  const firstPost = topicModel.getFirstPost();

  const parsedFirstPost = firstPost?.cooked
    ? parse(firstPost.cooked)
    : undefined;

  const firstImage = parsedFirstPost?.querySelectorAll('img')[0];
  const firstImageUrl = firstImage?.attributes.src || '';

  // Remove the first image from the first post before rendering it
  // --> this is going to be rendered separately from the rest of the post
  firstImage && firstImage.remove();

  // Remove other unwanted nodes from the post
  parsedFirstPost
    ?.querySelectorAll('.lightbox-wrapper, br')
    .forEach((e) => e.parentNode.removeChild(e));

  const title = topicModel.getTitle();

  const [, iframeSrcRaw] = parsedFirstPost
    ? /\[iframe src=(?:"|“)(.*?)("|”)]/.exec(parsedFirstPost.toString()) ?? []
    : [];

  const [, iframeSrc] = iframeSrcRaw
    ? /<a[^>]*>(.*?)<\/a>/.exec(iframeSrcRaw) ?? [0, iframeSrcRaw]
    : [];

  const parsedFirstPostStr =
    parsedFirstPost?.toString().replace(/\[iframe[^\]]*\]/g, '') || '';

  return (
    <ScreenWrapper>
      {!topic || !firstPost || discourseDataLoading ? (
        <Skeleton isLoaded={false} w="100%">
          <Box w="100%" h="30px" />
        </Skeleton>
      ) : (
        <Flex
          width="100%"
          alignItems="center"
          mb="defaultMargin"
          paddingX={{ base: 'defaultMargin', md: 0 }}
        >
          <UserAvatar
            size="2xs"
            userId={0}
            name={firstPost.name}
            avatarPicture={`${discourseUrl}${firstPost.avatarTemplate.replace(
              '{size}',
              '240'
            )}`}
          />
          <Box marginX="defaultMargin">
            <Text fontWeight="semibold">{simplifyName(firstPost.name)}</Text>
            {topic.createdAt && (
              <Text color="text.muted" fontWeight="semibold" fontSize="sm">
                {moment(topic.createdAt).year() === moment().year()
                  ? moment(topic.createdAt).fromNow()
                  : moment(topic.createdAt).format('D MMM YYYY [at] LT')}
              </Text>
            )}
          </Box>
        </Flex>
      )}
      <Flex
        flex={1}
        flexDirection={{ base: 'column', lg: iframeSrc ? 'column' : 'row' }}
      >
        {!topic || discourseDataLoading ? (
          <Flex
            flex={0.5}
            pr={{ base: 0, lg: 2 }}
            flexDir="column"
            alignItems="center"
          >
            <Skeleton isLoaded={false} w="100%" flex={0}>
              <Box w="100%" h="350px" />
            </Skeleton>
          </Flex>
        ) : (
          <Flex
            flex={{ base: 'normal', lg: iframeSrc ? 'normal' : 0.5 }}
            pr={{ base: 0, lg: iframeSrc ? 0 : 2 }}
            flexDir="column"
          >
            {iframeSrc ? (
              <Card
                padding={0}
                position="relative"
                justifyContent="center"
                mb={4}
                borderRadius={0}
              >
                <Box
                  w="100%"
                  minH="350px"
                  position="relative"
                  bg="background.default"
                >
                  <Box w="100%" h={0} pb="56.25%">
                    <Box position="absolute" w="100%" h="100%">
                      <RenderHtml
                        iframeOnly
                        html={`<iframe src="${iframeSrc}" loading="lazy" width="100%" height="100%" title="Embedded Content" frameborder="0" allow="camera; microphone; display-capture; fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" scrolling="no"/>`}
                      />
                    </Box>
                  </Box>
                </Box>
              </Card>
            ) : firstImageUrl ? (
              <Card
                padding={0}
                position="relative"
                justifyContent="center"
                mb={4}
              >
                <Box
                  position="absolute"
                  top={0}
                  right={0}
                  bottom={0}
                  left={0}
                  backgroundImage={`url(${firstImageUrl})`}
                  backgroundSize="cover"
                  filter="blur(50px)"
                />
                <Image
                  position="relative"
                  maxW="100%"
                  maxH={{ base: 'none', lg: 'image.max' }}
                  width={{ base: '100%', lg: 'auto' }}
                  objectFit="contain"
                  src={firstImageUrl}
                />
              </Card>
            ) : null}
            <Card w="100%" flexDir="column" padding={4}>
              {title ? (
                <Text fontWeight="semibold" fontSize="lg" mb={4}>
                  {emojifyText(title)}
                </Text>
              ) : null}
              {firstPost && (
                <>
                  <Box className="first_post">
                    <RenderHtml html={emojifyText(parsedFirstPostStr)} />
                  </Box>
                  <Flex mt={2} alignItems="center" justifyContent="flex-end">
                    <Flex
                      alignItems="center"
                      cursor={!firstPost.canLike ? 'initial' : 'pointer'}
                      background={
                        !firstPost.canLike
                          ? 'none'
                          : firstPost.liked
                          ? 'background.error'
                          : 'background.primary'
                      }
                      _hover={!firstPost.canLike ? {} : { opacity: 0.75 }}
                      borderRadius="sm"
                      py={1}
                      px={2}
                    >
                      <MdIcon
                        name={firstPost.liked ? 'Favorite' : 'FavoriteBorder'}
                        color={
                          !firstPost.canLike
                            ? 'icon.disabled'
                            : firstPost.liked
                            ? 'common.notification'
                            : 'common.primary'
                        }
                        onClick={
                          !firstPost.canLike
                            ? () => null
                            : () =>
                                firstPost.liked
                                  ? dispatch(
                                      discourseActions.unlikePost(firstPost.id)
                                    )
                                  : dispatch(
                                      discourseActions.likePost(firstPost.id)
                                    )
                        }
                        my={0.5}
                      />
                      {firstPost.totalLikes ? (
                        <Text
                          fontSize="xs"
                          color={
                            !firstPost.canLike
                              ? 'text.default'
                              : firstPost.liked
                              ? 'common.notification'
                              : 'common.primary'
                          }
                          ml={1}
                        >
                          {firstPost.totalLikes}
                        </Text>
                      ) : null}
                    </Flex>
                  </Flex>
                </>
              )}
            </Card>
          </Flex>
        )}
        <Flex
          flex={{ base: 'normal', lg: iframeSrc ? 'normal' : 0.5 }}
          pl={{ base: 0, lg: iframeSrc ? 0 : 2 }}
          pt={{ base: 4, lg: iframeSrc ? 4 : 0 }}
          flexDir="column"
        >
          <Card mb={6}>
            <ReplyBox
              id={parseInt(topicId)}
              isDisabled={!Boolean(reply)}
              isLoading={submittingReply}
              value={reply || ''}
              onChange={(e) => setReply(e.target.value)}
              onSubmit={async () => {
                if (!reply) return;

                setSubmittingReply(true);
                await dispatch(
                  discourseActions.replyToPost({
                    topicId: parseInt(topicId),
                    raw: reply,
                    replyToPostNumber: 1,
                  })
                );

                setSubmittingReply(false);
                setReply(null);
              }}
            />
          </Card>
          <SectionTitle title="Comments" />
          <Card w="100%" padding={4}>
            {discourseDataLoading ? (
              <TopicComments isLoading />
            ) : (
              <TopicComments
                posts={replies}
                topicId={parseInt(topicId)}
                onLikeComment={async (id) => {
                  await dispatch(discourseActions.likePost(id));
                }}
                onSubmitComment={async (id, raw) =>
                  await dispatch(
                    discourseActions.replyToPost({
                      topicId: parseInt(topicId),
                      raw,
                      replyToPostNumber: id,
                    })
                  )
                }
                onUnlikeComment={async (id) => {
                  await dispatch(discourseActions.unlikePost(id));
                }}
              />
            )}
          </Card>
        </Flex>
      </Flex>
    </ScreenWrapper>
  );
};

const mapStateToProps = (state: GlobalState, props: OwnProps) => {
  const { topicId } = props.match.params;
  const {
    discourse: { topics },
  } = state;

  return {
    topicId,
    topics,
  };
};

const connector = connect(mapStateToProps);

export default connector(ClassActivity);
