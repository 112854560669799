import React, { useCallback, useEffect } from 'react';
import { useFormContext, useForm, FormContext } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';
import isEmpty from 'lodash/isEmpty';

import { Text, Flex, MdIcon, Box, FlexProps } from '@workshop/ui';

interface FileInputProps {
  id: string;
  backgroundColor?: FlexProps['backgroundColor'];
  hoverBg?: FlexProps['backgroundColor'];
  backgroundSize?: FlexProps['backgroundSize'];
  borderColor?: FlexProps['borderColor'];
  borderRadius?: FlexProps['borderRadius'];
  height: FlexProps['height'];
  image?: FlexProps['backgroundImage'];
  isDisabled?: boolean;
  label?: string;
  name: string;
  styleProps?: Partial<FlexProps>;
  textColor?: string;
  textBgColor?: FlexProps['backgroundColor'];
  width: FlexProps['width'];
  maxWidth?: FlexProps['maxWidth'];
  hideText?: boolean;
  hideAddPhotoIcon?: boolean;
  onDrop?: (name: string, acceptedFiles: File[]) => void;
  showBlur?: boolean;
  placeholder?: string;
}

const FileInput: React.FC<FileInputProps> = ({
  backgroundSize,
  borderRadius,
  name,
  label,
  width,
  maxWidth,
  height,
  textColor = 'text.muted',
  textBgColor = 'transparent',
  backgroundColor = 'background.default',
  hoverBg = 'background.primary',
  borderColor = 'border.default',
  image = '',
  hideText = false,
  hideAddPhotoIcon = false,
  isDisabled = false,
  styleProps = {},
  onDrop = () => {},
  showBlur = false,
  placeholder = 'Select image...',
}) => {
  const { register, unregister } = useFormContext<{
    [key: string]: File;
  }>();

  const innerOnDrop = useCallback(
    (acceptedFiles) => {
      onDrop(name, acceptedFiles);
    },
    [name, onDrop]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: innerOnDrop,
    accept: 'image/*',
  });

  useEffect(() => {
    register(name);
    // Avoiding memory leaks
    return () => {
      unregister(name);
    };
  }, [register, unregister]);

  return (
    <Flex
      position="relative"
      backgroundColor={backgroundColor}
      maxWidth={maxWidth}
      borderRadius={borderRadius !== undefined ? borderRadius : 'md'}
      border={image ? '' : '1px solid'}
      borderColor={borderColor}
      cursor={isDisabled ? 'not-allowed' : 'pointer'}
      _hover={{ backgroundColor: hoverBg }}
      overflow="hidden"
      flex={1}
      alignItems="center"
    >
      {showBlur && image && (
        <Box
          position="absolute"
          top={0}
          right={0}
          bottom={0}
          left={0}
          backgroundImage={`url(${image})`}
          backgroundSize="cover"
          filter="blur(50px)"
        />
      )}
      <Flex
        position="relative"
        height={height}
        width={width}
        backgroundImage={image && `url(${image})`}
        backgroundSize={backgroundSize || 'cover'}
        backgroundRepeat="no-repeat"
        backgroundPosition="center"
        {...styleProps}
      >
        <Flex
          flex="1"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="flex-end"
          position="relative"
          {...getRootProps()}
        >
          {!isDisabled && <input {...getInputProps()} />}
          <Flex
            backgroundColor={textBgColor}
            borderTopRightRadius="sm"
            alignItems="center"
          >
            <Text color={textColor} paddingX={2} paddingY={1} fontSize="sm">
              {label}
            </Text>
            {!isDisabled && label && (
              <Box p={2} pl={0}>
                <MdIcon name="Edit" color={textColor} />
              </Box>
            )}
          </Flex>

          {isEmpty(image) && (
            <Flex
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              position="absolute"
              bottom={0}
              left={0}
              right={0}
              top={0}
              w="100%"
              h="100%"
            >
              {!hideAddPhotoIcon && (
                <MdIcon
                  name="AddPhotoAlternate"
                  color={textColor}
                  boxSize={10}
                />
              )}
              {!hideText && (
                <Text
                  color={textColor}
                  fontSize="sm"
                  mt={1}
                  fontWeight="semibold"
                >
                  {placeholder}
                </Text>
              )}
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export const StandaloneImageUpload: React.FC<FileInputProps> = (props) => {
  const formMethods = useForm();
  return (
    <FormContext {...formMethods}>
      <FileInput {...props} />
    </FormContext>
  );
};

export default FileInput;
