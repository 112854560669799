import React, { useState, useEffect } from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import moment from 'moment';
import { useStopwatch } from 'react-timer-hook';

import { PROGRESS_STATUS, UNIT_TYPE } from 'constants/courses';

import { GlobalState } from 'types';
import { Unit, UnitSchedule, UnitProgress } from 'types/learner';

import navRoutes from 'navigation/Routes';

import { profileActions } from 'redux/actions/common';
import {
  courseActions,
  courseProgressActions,
  courseScheduleActions,
  journalActions,
  unitActions,
} from 'redux/actions/learner';
import {
  getSlugs,
  getModules,
  getUnits,
  getUnitProgress,
  getUnitSchedules,
  getModulesProgress,
  getModuleSchedules,
} from 'redux/selectors/course';
import { hooks, capitalize } from 'utils';
import { useWindowDimensions } from 'utils/hooks/useDimensions';

import {
  Box,
  Button,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  chakra,
  useTheme,
} from '@workshop/ui';

import { ScreenWrapper } from 'screens/common/ScreenWrapper';
import { ModalVideo } from 'components/ModalVideo';
import { Loading } from 'components/Loading';

import {
  UnitDetailsCard,
  getModulesForUnitDetailsCard,
} from 'screens/learner/BrowseCourse/src/UnitDetailsCard';
import {
  DownloadsModalBody,
  ChecklistModalBody,
  NotesModalBody,
} from 'screens/learner/BrowseCourse/src/CourseModals';

import { CourseModel } from 'models/learner';

// Routing Props
interface MatchParams {
  courseSlug: string;
}

// Props passed to our component from parents
interface OwnProps extends RouteComponentProps<MatchParams> {}

// Props passed to our component via redux
type PropsFromRedux = ConnectedProps<typeof connector>;

// Combined props we're passing to our component
interface Props extends OwnProps, PropsFromRedux {}

const Assessment: React.FC<Props> = ({
  courseSlug,
  course,
  courseProgress,
  courseSchedule,
  location,
  modules,
  moduleProgress,
  moduleSchedule,
  units,
  unitProgress,
  unitSchedule,
  journalEntries,
  cohort,
  activeAssessment,
  history,
}) => {
  const [modalVideo, setModalVideo] = useState<{
    summary?: string;
    video?: string;
    orientation?: 'portrait' | 'landscape';
    mediaType?: 'text' | 'image' | 'audio' | 'video';
  } | null>(null);

  const [modalState, setModalState] = useState<{
    visible: boolean;
    modal: 'notes' | 'requirements' | 'resources' | null;
    unit: number | null;
  }>({ visible: false, modal: null, unit: null });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (activeAssessment) {
      if (activeAssessment.course !== courseSlug) {
        history.push(
          navRoutes.learner.assessment.path(activeAssessment.course)
        );
      }
    } else {
      history.push(navRoutes.common.home.path());
    }
  }, [courseSlug, activeAssessment]);

  const dispatch = useDispatch();

  const { courseLoading } = hooks.useLoadingDataState(
    {
      courseLoading: {
        startLoading: Boolean(courseSlug),
        actions: !courseSlug
          ? []
          : [
              () =>
                courseActions.retrieve(courseSlug, location.pathname, false),
            ],
      },
      journalLoading: { actions: [journalActions.retrieveJournalEntries] },
    },
    [courseSlug]
  );

  const { dataLoading } = hooks.useLoadingDataState(
    {
      dataLoading: {
        startLoading: Boolean(courseSlug) && !Boolean(courseLoading),
        actions: !courseSlug
          ? []
          : [
              () => courseProgressActions.retrieve(courseSlug),
              () => courseScheduleActions.retrieve(courseSlug),
            ],
      },
    },
    [courseSlug, courseLoading]
  );

  const theme = useTheme();
  const windowDimensions = useWindowDimensions();
  const isMobile = windowDimensions.width < parseInt(theme.breakpoints.md, 10);

  const courseModel = new CourseModel({
    course,
    courseSchedule,
    courseProgress,
    modules,
    moduleSchedule,
    moduleProgress,
    units,
    unitSchedule,
    unitProgress,
    journalEntries,
    cohort,
  });

  const detailedUnits = courseModel.getDetailedUnits();
  const unit = detailedUnits.find((u) => u.id === activeAssessment?.id);
  const unitModules = unit
    ? getModulesForUnitDetailsCard({
        dataLoading,
        courseSlug,
        setModalVideo,
        unit,
        isMobile,
        context: 'assessment',
      })
    : [];

  const unitForModal = modalState.unit
    ? detailedUnits.find((u) => u.id === modalState.unit)
    : null;

  const dateStarted = unit?.progress?.dateStarted
    ? unit.progress.dateStarted
    : null;

  const stopwatch = useStopwatch({
    autoStart: true,
  });

  useEffect(() => {
    if (dateStarted) {
      const dateOffset = new Date();
      const dateDiff = moment(dateOffset).diff(moment(dateStarted), 'seconds');
      dateOffset.setSeconds(dateOffset.getSeconds() + dateDiff);
      stopwatch.reset(dateOffset);
    }
  }, [dateStarted]);

  if (courseLoading || dataLoading) {
    return (
      <ScreenWrapper>
        <Loading />
      </ScreenWrapper>
    );
  }

  return (
    <>
      <ScreenWrapper>
        <Flex flex={1} flexDirection="column" position="relative">
          {unit && unitModules?.length > 0 && (
            <Box>
              <UnitDetailsCard
                isLoading={dataLoading}
                tag={unit.tag}
                description={unit.description}
                startDate={unit.startDate}
                title={unit.title}
                modules={unitModules.map((m) => ({
                  ...m,
                  schedule: undefined,
                  showScheduleBtn: false,
                }))}
                onOpenChecklist={
                  unit.unitCheckLists?.length
                    ? () =>
                        setModalState({
                          visible: true,
                          modal: 'requirements',
                          unit: unit.id,
                        })
                    : undefined
                }
                onOpenDownloads={
                  unit.downloads?.length
                    ? () =>
                        setModalState({
                          visible: true,
                          modal: 'resources',
                          unit: unit.id,
                        })
                    : undefined
                }
                onOpenNotes={
                  unit.unitNotes?.length
                    ? () =>
                        setModalState({
                          visible: true,
                          modal: 'notes',
                          unit: unit.id,
                        })
                    : undefined
                }
                percentageComplete={unit.percentageComplete}
                locked={false}
                isAssessment={unit.unitType === UNIT_TYPE.assessment}
                assessmentActive
                alwaysExpanded
              />
            </Box>
          )}
          {dateStarted && (
            <Flex
              flexDirection={{ base: 'column', md: 'row' }}
              justifyContent="flex-end"
              alignItems="center"
              mt={6}
              mx={{ base: 'defaultMargin', md: 0 }}
            >
              <Text
                color="text.muted"
                textAlign="right"
              >{`Assessment started ${moment(dateStarted).format(
                'Do MMM [at] LT'
              )}`}</Text>
              <Flex
                ml={{ base: 0, md: 4 }}
                mt={{ base: 4, md: 0 }}
                px={4}
                py={3}
                borderWidth={2}
                borderColor="border.default"
                color="text.muted"
                fontWeight="semibold"
                fontSize="lg"
                borderRadius="md"
                minWidth={stopwatch.days > 0 ? '170px' : '120px'}
                alignItems="center"
                justifyContent="center"
                textAlign="center"
              >
                {stopwatch.days > 0 && (
                  <Text mr={1}>{`${stopwatch.days} day${
                    stopwatch.days === 1 ? '' : 's'
                  },`}</Text>
                )}
                <Text flex={1}>{`${stopwatch.hours < 10 ? '0' : ''}${
                  stopwatch.hours
                }`}</Text>
                <Text>:</Text>
                <Text flex={1}>{`${stopwatch.minutes < 10 ? '0' : ''}${
                  stopwatch.minutes
                }`}</Text>
                <Text>:</Text>
                <Text flex={1}>{`${stopwatch.seconds < 10 ? '0' : ''}${
                  stopwatch.seconds
                }`}</Text>
              </Flex>
            </Flex>
          )}

          {unit ? (
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems={{ base: 'center', md: 'flex-end' }}
              mt={12}
              mx={{ base: 'defaultMargin', md: 0 }}
            >
              <Button
                icon="AssignmentTurnedIn"
                isDisabled={unit?.progress?.status !== PROGRESS_STATUS.complete}
                onClick={async () => {
                  setIsLoading(true);
                  await dispatch(
                    unitActions.updateUserProgress(courseSlug, unit.slug, {
                      assessmentComplete: true,
                    })
                  );
                  await dispatch(profileActions.fetchUserProfile());
                  setIsLoading(false);
                  history.push(navRoutes.learner.course.path(courseSlug));
                }}
                isLoading={isLoading}
              >
                Complete Assessment
              </Button>
              {unit.progress && unit.progress.modulesRemaining > 0 && (
                <Text color="text.muted" textAlign="right" mt={4}>
                  {`${unit.progress.modulesRemaining} Sessions Remaining`}
                </Text>
              )}
            </Flex>
          ) : null}
        </Flex>
      </ScreenWrapper>
      {/* TODO: Move modals to 'screens/learner/BrowseCourse/src/CourseModals' */}
      {/* MODAL FOR NOTES/CHECKLIST/DOWNLOADS */}
      <Modal
        size="lg"
        isOpen={modalState.visible}
        onClose={() =>
          setModalState({ visible: false, modal: null, unit: null })
        }
      >
        <ModalOverlay />
        <ModalContent borderRadius="md" overflow="hidden">
          <ModalHeader padding={4}>
            <Flex alignItems="center">
              <Text fontWeight="bold" pr={8}>
                {`${capitalize(modalState.modal)}`}
                <chakra.span
                  fontWeight="semibold"
                  color="text.muted"
                >{` for ${unitForModal?.title}`}</chakra.span>
              </Text>
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          {unitForModal && (
            <ModalBody padding={0}>
              {modalState.modal === 'resources' ? (
                <DownloadsModalBody
                  downloads={unitForModal.downloads.filter(
                    (d) => !d.teacherOnly
                  )}
                  onClickVideo={(video: string) => {
                    setModalState({ visible: false, modal: null, unit: null });
                    setModalVideo({ video });
                  }}
                />
              ) : modalState.modal === 'requirements' ? (
                <ChecklistModalBody checklists={unitForModal.unitCheckLists} />
              ) : modalState.modal === 'notes' ? (
                <NotesModalBody notes={unitForModal.unitNotes} />
              ) : null}
            </ModalBody>
          )}
        </ModalContent>
      </Modal>
      {/** MODAL FOR COURSE/UNIT INTROS/OUTROS */}
      <ModalVideo
        isOpen={Boolean(modalVideo)}
        onClose={() => setModalVideo(null)}
        autoplay
        {...modalVideo}
      />
    </>
  );
};

const mapStateToProps = (state: GlobalState, props: OwnProps) => {
  const { courseSlug } = props.match.params;
  const { activeAssessment } = state.user.userProfile;
  const {
    courses: {
      courses: courseState,
      units: unitState,
      modules: moduleState,
      cohorts,
    },
    courseSchedule: {
      courses: courseScheduleState,
      units: unitScheduleState,
      modules: moduleScheduleState,
    },
    courseProgress: {
      courses: courseProgressState,
      units: unitProgressState,
      modules: moduleProgressState,
    },
    journal: { journalEntries },
  } = state.learner;

  // Course Data
  const course = courseState.detail[courseSlug];
  const units = course ? getUnits(unitState, course.units) : null;

  type U = Unit;
  type M = 'modules';
  const moduleSlugs = getSlugs<Pick<U, M>, M>(units, 'modules');

  const modules = moduleSlugs ? getModules(moduleState, moduleSlugs) : null;

  // Course Schedule Data
  const courseSchedule = courseScheduleState[courseSlug];
  const unitSchedule = courseSchedule
    ? getUnitSchedules(unitScheduleState, courseSchedule.unitSchedules)
    : null;

  type US = UnitSchedule;
  type MS = 'moduleSchedules';
  const moduleScheduleSlugs = getSlugs<Pick<US, MS>, MS>(
    unitSchedule,
    'moduleSchedules'
  );

  const moduleSchedule = moduleScheduleSlugs
    ? getModuleSchedules(moduleScheduleState, moduleScheduleSlugs)
    : null;

  // Course Progress Data
  const courseProgress = courseProgressState[courseSlug];
  const unitProgress = courseProgress
    ? getUnitProgress(unitProgressState, courseProgress.unitProgress)
    : null;

  type UP = UnitProgress;
  type MP = 'moduleProgress';
  const moduleProgressSlugs = getSlugs<Pick<UP, MP>, MP>(
    unitProgress,
    'moduleProgress'
  );

  const moduleProgress = moduleProgressSlugs
    ? getModulesProgress(moduleProgressState, moduleProgressSlugs)
    : null;

  // Cohort Data
  const cohort = cohorts[courseSlug];

  return {
    courseSlug,
    course,
    courseProgress,
    courseSchedule,
    units,
    unitProgress,
    unitSchedule,
    modules,
    moduleProgress,
    moduleSchedule,
    journalEntries,
    cohort,
    activeAssessment,
  };
};

const connector = connect(mapStateToProps);

export default connector(Assessment);
