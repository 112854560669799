import React, { useState } from 'react';
import { connect, ConnectedProps, useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import moment from 'moment';
import { TopicSummary, UserBase } from 'discourse-js';

import { TopicModel } from 'models/learner';

import {
  Box,
  Button,
  MdIcon,
  Divider,
  Flex,
  Text,
  Skeleton,
  Card,
  useTheme,
} from '@workshop/ui';

import navRoutes from 'navigation/Routes';
import { discourseUrl } from 'constants/env';
import { GlobalState } from 'types';

import { PLATFORM } from 'constants/env';
import { UNIT_TYPE, SESSION_TYPE } from 'constants/courses';

import { CohortModel, DetailedGroupMember } from 'models/learner';
import { discourseActions, uiAction } from 'redux/actions/common';
import {
  cohortActions,
  courseActions,
  courseProgressActions,
} from 'redux/actions/learner';
import { getDiscourseCategory } from 'redux/selectors/course';

import { hooks, simplifyName } from 'utils';
import { getCohortSubcategoryId } from 'utils/learner';
import { useWindowDimensions } from 'utils/hooks/useDimensions';

import { ScreenWrapper } from 'screens/common/ScreenWrapper';
import { ActivityFeedItemCard } from 'components/ActivityFeedItemCard';
import { UserAvatar } from 'components/UserAvatar';
import { CourseMenu } from 'components/SideMenu';
import { SectionTitle } from 'components/Common';
import { NewPostCard } from 'components/NewPostCard';

const MemberRenderer: React.FC<{
  member: DetailedGroupMember;
  mr: number;
  isMentor?: boolean;
  privateChatDisabled?: boolean;
}> = ({ member, mr, isMentor = false, privateChatDisabled }) => {
  const dispatch = useDispatch();
  const discourseUserId = useSelector(
    (state: GlobalState) => state.user?.discourseUser?.user?.id
  );
  const theme = useTheme();
  const windowDimensions = useWindowDimensions();
  const isMobile = windowDimensions.width < parseInt(theme.breakpoints.md, 10);

  // On WS - all users can speak to mentors, but users can only speak to other users if !privateChatDisabled
  // On Steppit - users cannot speak to other users, and can only speak to mentors if !privateChatDisabled
  const canClick =
    discourseUserId !== member.id &&
    ((PLATFORM === 'workshop' && (isMentor || !privateChatDisabled)) ||
      (PLATFORM === 'steppit' && isMentor && !privateChatDisabled));

  return (
    <Card
      key={member.id}
      alignItems="center"
      mr={mr}
      mb={4}
      borderRadius="xl"
      padding={1}
      pr={4}
      borderWidth={3}
      borderColor={isMentor ? 'common.primary' : 'border.default'}
      backgroundColor={isMentor ? 'background.default' : 'background.tint1'}
      cursor={canClick ? 'pointer' : 'initial'}
      _hover={
        canClick
          ? { background: isMentor ? 'background.tint1' : 'background.default' }
          : {}
      }
      {...(canClick
        ? {
            onClick: () =>
              dispatch(
                uiAction.toggleUserMessaging({
                  userId: member.id,
                  title: member.name,
                  username: member.username,
                  isExpanded: true,
                })
              ),
          }
        : {})}
    >
      <UserAvatar
        avatarPicture={`${member.avatarTemplate.replace('{size}', '240')}`}
        canEdit={false}
        name={member.name}
        size={isMobile ? '2xs' : 'xs'}
        mr="defaultMargin"
        userId={member.id}
      />
      <Text
        fontWeight="semibold"
        color={isMentor ? 'text.default' : 'text.muted'}
      >
        {discourseUserId === member.id
          ? 'Me'
          : isMentor
          ? member.name
          : simplifyName(member.name)}
      </Text>
    </Card>
  );
};

interface MembersList {
  cohortName?: string;
  discourseGroupChatTopicId?: number;
  isLoading?: boolean;
  members?: DetailedGroupMember[];
  privateChatDisabled?: boolean;
}

const MembersList: React.FC<MembersList> = ({
  cohortName,
  discourseGroupChatTopicId,
  isLoading,
  members,
  privateChatDisabled,
}) => {
  const [allParticipantsVisible, setAllParticipantsVisible] = useState(false);
  const dispatch = useDispatch();
  const theme = useTheme();
  const windowDimensions = useWindowDimensions();
  const isMobile = windowDimensions.width < parseInt(theme.breakpoints.md, 10);

  if (isLoading) {
    return (
      <Flex flexDir="column">
        <Flex mb={6}>
          {Array(5)
            .fill(1)
            .map((n, idx) => (
              <Skeleton key={idx} isLoaded={false} w="100px" mr={6} flex={0}>
                <Flex
                  alignItems="center"
                  flexDir="column"
                  minH="150px"
                  mr={6}
                  w="100px"
                />
              </Skeleton>
            ))}
        </Flex>
        <Divider mb={4} />
      </Flex>
    );
  }

  if (!members?.length) return null;

  const mentors = members.filter(({ isMentor }) => isMentor);
  const allParticipants = members.filter(({ isMentor }) => !isMentor);

  const maxParticipants = isMobile ? 8 : 12;
  const allowedNumExtras = 2;

  // If class has up to maxParticipants, show them all
  // If class has over maxParticipants, show first batch and
  // "Show N more" button (where N is always > allowedNumExtras)
  const participantsShortlist = allParticipants.slice(
    0,
    maxParticipants - allowedNumExtras
  );
  const numRemainingParticipants =
    allParticipants.length - participantsShortlist.length;

  const visibleParticipants =
    allParticipantsVisible || numRemainingParticipants <= allowedNumExtras
      ? allParticipants
      : participantsShortlist;

  return (
    <Flex flexDir="column" margin={{ base: 'defaultMargin', md: 0 }}>
      <Flex flexWrap="wrap">
        <Card
          alignItems="center"
          mr={4}
          borderRadius="xl"
          padding={1}
          pr={4}
          borderWidth={3}
          borderColor="transparent"
          mb={4}
          cursor="pointer"
          _hover={{ background: 'background.tint1' }}
          onClick={() =>
            discourseGroupChatTopicId &&
            dispatch(
              uiAction.toggleUserMessaging({
                discourseGroupTopicId: discourseGroupChatTopicId,
                title: cohortName || '',
                isExpanded: true,
              })
            )
          }
        >
          <Flex
            backgroundColor="background.tint3"
            justifyContent="center"
            alignItems="center"
            boxSize={{ base: 'image.2xs', md: 'image.xs' }}
            borderRadius="3rem"
            mr="defaultMargin"
          >
            <MdIcon
              name="People"
              color="icon.default"
              boxSize={{ base: 'icon', md: 'image.3xs' }}
            />
          </Flex>
          <Text fontWeight="semibold">Class Discussion</Text>
        </Card>
        {mentors.map((member, idx) => (
          <MemberRenderer
            member={member}
            mr={idx + 1 === mentors.length ? 0 : 4}
            isMentor={true}
            privateChatDisabled={privateChatDisabled}
          />
        ))}
        <Box
          borderRight="3px solid"
          borderRightColor="border.default"
          marginX={4}
          mb={4}
        />
        {visibleParticipants.map((member, idx) => (
          <MemberRenderer
            member={member}
            mr={idx + 1 === visibleParticipants.length ? 0 : 4}
          />
        ))}
      </Flex>
      {numRemainingParticipants > allowedNumExtras ? (
        <Text
          color="common.primary"
          fontSize="sm"
          fontWeight="semibold"
          textAlign="right"
          cursor="pointer"
          onClick={() => setAllParticipantsVisible(!allParticipantsVisible)}
        >
          {allParticipantsVisible
            ? 'Hide'
            : `Show ${numRemainingParticipants} more`}
        </Text>
      ) : null}
      <Divider mt={6} mb={4} />
    </Flex>
  );
};

export type PartialTopic = Pick<
  TopicSummary,
  'createdAt' | 'imageUrl' | 'likeCount' | 'postsCount'
> & {
  id: number | undefined;
  originalPoster?: UserBase | undefined;
  excerpt?: string;
  courseSlug?: string;
};

interface TopicsListProps {
  topics: PartialTopic[];
  title?: string;
  isLoading?: boolean;
  openTopicsInNewTab?: boolean;
  isCms?: boolean;
}

export const TopicsList: React.FC<TopicsListProps> = ({
  topics,
  title,
  isLoading,
  openTopicsInNewTab = false,
  isCms,
}) => (
  <>
    {topics.map((topic, idx) => {
      const { originalPoster, courseSlug } = topic;

      const topicModel = new TopicModel({ topic });
      const cardTitle = title || topicModel.getTitle();

      const excerpt = topic.excerpt;

      const [, iframeSrcRaw] = excerpt
        ? /\[iframe src=(?:"|“)(.*?)("|”)]/.exec(excerpt) ?? []
        : [];

      const [, iframeSrc] = iframeSrcRaw
        ? /<a[^>]*>(.*?)<\/a>/.exec(iframeSrcRaw) ?? [0, iframeSrcRaw]
        : [];

      const excerptStr = excerpt?.replace(/\[iframe[^\]]*\]/g, '') || '';

      // let sessionSlug = '';
      // if (iframeSrc?.includes('steppit.com')) {
      //   const slugMatch = /\/s\/([^\/?]+)/.exec(iframeSrc);
      //   if (slugMatch && slugMatch[1]) {
      //     sessionSlug = slugMatch[1];
      //   }
      // }

      return (
        <Box key={idx} mb="defaultMargin">
          <ActivityFeedItemCard
            isLoading={isLoading}
            createdAt={topic.createdAt}
            imageUrl={topic.imageUrl || undefined}
            likeCount={topic.likeCount}
            linkTo={
              isCms && topic.id
                ? navRoutes.cms.cmsViewPost.path(topic.id)
                : courseSlug && topic.id
                ? navRoutes.learner.viewPost.path(courseSlug, topic.id)
                : topic.id
                ? navRoutes.learner.genericViewPost.path(topic.id)
                : undefined
            }
            openInNewTab={openTopicsInNewTab}
            postsCount={topic.postsCount}
            summary={excerptStr}
            title={cardTitle}
            userName={originalPoster?.name}
            userAvatar={
              originalPoster
                ? originalPoster.avatarTemplate.startsWith('https://')
                  ? originalPoster.avatarTemplate
                  : `${discourseUrl}${originalPoster.avatarTemplate.replace(
                      '{size}',
                      '240'
                    )}`
                : ''
            }
            iframeSrc={
              iframeSrc?.includes('steppit.com')
                ? new URL(iframeSrc).pathname
                : iframeSrc
            }
            // sessionSlug={sessionSlug}
          />
        </Box>
      );
    })}
  </>
);

// Routing Props
interface MatchParams {
  courseSlug: string;
}

// Props passed to our component from parents
interface OwnProps extends RouteComponentProps<MatchParams> {}

// Props passed to our component via redux
type PropsFromRedux = ConnectedProps<typeof connector>;

// Combined props we're passing to our component
interface Props extends OwnProps, PropsFromRedux {}

const ClassActivity: React.FC<Props> = ({
  cohort,
  cohortCategory,
  course,
  courseSlug,
  location,
  history,
  members,
  subCategoryId,
  unitState,
  moduleState,
}) => {
  const [newPostExpanded, setNewPostExpanded] = useState(false);

  // First we need to retrieve the course data - this is necessary in order
  // to get the discourseCategoryId and discourseGroupName
  const { courseLoading } = hooks.useLoadingDataState({
    courseLoading: {
      actions: [
        () => courseActions.retrieve(courseSlug, location.pathname, false),
      ],
    },
  });

  // After the course has successfully been loaded, retrieve the cohort
  // --> this gives us the relevant discourseGroupName
  const { cohortLoading } = hooks.useLoadingDataState(
    {
      cohortLoading: {
        startLoading: !Boolean(courseLoading),
        actions: [() => cohortActions.retrieve(courseSlug)],
      },
    },
    [courseSlug, courseLoading]
  );

  const { userDataLoading } = hooks.useLoadingDataState(
    {
      dataLoading: {
        startLoading: !Boolean(courseLoading),
        actions: [() => courseProgressActions.retrieve(courseSlug)],
      },
    },
    [courseSlug, courseLoading]
  );

  const discourseCategoryId = course && course.discourseCategoryId;
  const discourseGroupName = cohort && cohort.discourseGroupName;
  const discourseGroupChatTopicId = cohort && cohort.discourseGroupChatTopicId;

  // After we've successfully retrieved both course & cohort, we can
  // start fetching data from discourse
  const { discourseDataLoading } = hooks.useLoadingDataState(
    {
      discourseDataLoading: {
        startLoading:
          !Boolean(cohortLoading) &&
          Boolean(subCategoryId) &&
          Boolean(discourseGroupName) &&
          Boolean(discourseGroupChatTopicId),
        actions: subCategoryId
          ? [
              () =>
                discourseActions.getSubcategory(
                  discourseCategoryId,
                  subCategoryId,
                  true
                ),
              () => discourseActions.getGroupMembers(discourseGroupName),
              // TODO : find a better place for this - ideally we want to know about
              // group discussions regardless of which page we're viewing...
              () => discourseActions.getTopic(discourseGroupChatTopicId, true),
            ]
          : [],
      },
    },
    [cohortLoading, subCategoryId, discourseCategoryId, discourseGroupName]
  );

  const cohortModel = new CohortModel({
    cohort,
    category: cohortCategory,
    members: Object.values(members),
  });

  const topics = cohortModel
    .getTopics()
    .sort((a, b) => (moment(a.createdAt).isBefore(b.createdAt) ? 1 : -1))
    .map((t) => ({
      ...t,
      originalPoster: cohortModel.getTopicOriginalPoster(t.id),
      courseSlug,
    }));

  const topicsColumnLeft = topics.filter((t, index) => index % 2 === 0);
  const topicsColumnRight = topics.filter((t, index) => index % 2 !== 0);

  const discourseUser = useSelector(
    (state: GlobalState) => state.user?.discourseUser?.user
  );

  const theme = useTheme();
  const windowDimensions = useWindowDimensions();
  const isMobile = windowDimensions.width < parseInt(theme.breakpoints.md, 10);

  if (!cohort && !cohortLoading && !courseLoading) {
    history.push(navRoutes.common.home.path());
  }

  const moduleOptions = course?.units
    .filter((unitSlug) => {
      const unit = unitState[unitSlug];
      return (
        unit.unitType !== UNIT_TYPE.intro && unit.unitType !== UNIT_TYPE.outro
      );
    })
    .map((unitSlug) => {
      const unit = unitState[unitSlug];
      return {
        value: `${unit.id}`,
        label: unit.title,
        options: unit.modules
          .filter((moduleSlug) => {
            const module = moduleState[moduleSlug];
            return module.moduleType === SESSION_TYPE.normal;
          })
          .map((moduleSlug) => {
            const module = moduleState[moduleSlug];
            return {
              value: module.slug,
              label: module.title,
            };
          }),
      };
    });

  if (course?.courseType === 'mini') {
    history.push(navRoutes.learner.course.path(courseSlug));
    return null;
  }

  return (
    <ScreenWrapper>
      <CourseMenu courseSlug={courseSlug} />
      <Flex flex={1} mb={4} flexDirection="column" position="relative">
        {!cohortLoading &&
        !Boolean(discourseGroupName) &&
        moment(cohort.startDate).isAfter(moment()) ? (
          <Text color="text.muted" textAlign="center" mt={6}>
            Come back here when your class starts to meet your classmates 🙌
          </Text>
        ) : discourseDataLoading ? (
          <Flex w="100%" flexDir="column">
            <MembersList isLoading />
            <Skeleton mb={4}>
              <Button>Loading...</Button>
            </Skeleton>
            <Flex w="100%" flexWrap="wrap">
              <Flex w="50%" flexWrap="wrap" flexDirection="column" pr={1}>
                {Array(2)
                  .fill(1)
                  .map(() => Math.random())
                  .map((n, idx) => (
                    <Box key={`activity-feed-item-1-loading-${n}`} mb={4}>
                      <ActivityFeedItemCard isLoading />
                    </Box>
                  ))}
              </Flex>
              <Flex w="50%" flexWrap="wrap" flexDirection="column" pl={1}>
                {Array(2)
                  .fill(1)
                  .map(() => Math.random())
                  .map((n, idx) => (
                    <Box key={`activity-feed-item-2-loading-${n}`} mb={4}>
                      <ActivityFeedItemCard isLoading />
                    </Box>
                  ))}
              </Flex>
            </Flex>
          </Flex>
        ) : (
          <Flex w="100%" flexDir="column">
            {cohort.socialType === 'private' && (
              <>
                <SectionTitle title="Messages" />
                <MembersList
                  members={cohortModel.getMembers()}
                  discourseGroupChatTopicId={
                    cohortModel.getCohort()?.discourseGroupChatTopicId
                  }
                  cohortName={cohortModel.getCohort()?.courseDetails.title}
                  privateChatDisabled={
                    cohortModel.getCohort()?.privateChatDisabled
                  }
                />
              </>
            )}
            <SectionTitle title="Posts" />
            <Box mb="defaultMargin">
              <Card
                overflow="visible"
                maxHeight={!newPostExpanded ? '56px' : ''} //TODO: Avoid hardcoding these values if possible
                transition="max-height 0.5s"
                {...(!newPostExpanded
                  ? {
                      cursor: 'pointer',
                      _hover: { background: 'background.tint1' },
                      onClick: () => setNewPostExpanded(true),
                      alignItems: 'center',
                      borderRadius: { base: 0, md: 'full' },
                    }
                  : {})}
              >
                {!newPostExpanded ? (
                  <>
                    {discourseUser ? (
                      <UserAvatar
                        avatarPicture={`${discourseUrl}${discourseUser.avatarTemplate.replace(
                          '{size}',
                          '240'
                        )}`}
                        canEdit={false}
                        name={discourseUser.name}
                        size="2xs"
                        mr="defaultMargin"
                        userId={discourseUser.id}
                      />
                    ) : null}
                    <Text flex={1}>Create a new post...</Text>
                    <Button
                      secondary
                      fontSize="sm"
                      onClick={() => setNewPostExpanded(true)}
                    >
                      New Post
                    </Button>
                  </>
                ) : (
                  <NewPostCard
                    onSubmitSuccess={(post) => {
                      if (post) {
                        history.push(
                          `/course/${courseSlug}/activity/${post.topicId}`
                        );
                      }
                    }}
                    onCancel={() => setNewPostExpanded(false)}
                    discourseCategoryId={subCategoryId || undefined}
                    title={cohort?.courseDetails?.title || ''}
                    moduleOptions={moduleOptions}
                  />
                )}
              </Card>
            </Box>
            {topics && topics.length ? (
              <>
                {isMobile ? (
                  <Flex>
                    <Box flex={1} maxW="100vw">
                      <TopicsList topics={topics} />
                    </Box>
                  </Flex>
                ) : (
                  <Flex>
                    <Box flex={1} maxW="calc(50% - 6px)">
                      <TopicsList topics={topicsColumnLeft} />
                    </Box>
                    <Box pr="defaultMargin" />
                    <Box flex={1} maxW="calc(50% - 6px)">
                      <TopicsList topics={topicsColumnRight} />
                    </Box>
                  </Flex>
                )}
              </>
            ) : (
              <Flex padding="defaultPadding" justifyContent="center">
                <Text color="text.muted">Nothing yet! 🧐</Text>
              </Flex>
            )}
          </Flex>
        )}
      </Flex>
    </ScreenWrapper>
  );
};

const mapStateToProps = (state: GlobalState, props: OwnProps) => {
  const { courseSlug } = props.match.params;

  const {
    discourse: { categories, members },
    learner: {
      courses: { cohorts, courses, units: unitState, modules: moduleState },
    },
  } = state;

  // Course Data
  const course = courses.detail[courseSlug];

  // Cohort Data
  const cohort = cohorts[courseSlug];
  const subCategoryId = getCohortSubcategoryId(cohort, course);

  const cohortCategory = subCategoryId
    ? getDiscourseCategory(subCategoryId, categories)
    : null;

  return {
    cohort,
    cohortCategory,
    course,
    courseSlug,
    members,
    subCategoryId,
    unitState,
    moduleState,
  };
};

const connector = connect(mapStateToProps);

export default connector(ClassActivity);
