import React, { useEffect } from 'react';
import { useUser } from 'redux/selectors';

import { Box, useTheme } from '@workshop/ui';

const ZapierEmbed: React.FC<{}> = ({}) => {
  const user = useUser();
  useEffect(() => {
    // Load Zapier JS
    const script = document.createElement('script');
    script.type = 'module';
    script.src =
      'https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.esm.js';
    document.head.appendChild(script);
  }, []);
  const theme = useTheme();
  const defaultBg =
    theme.colors.background.default === 'rgba(255, 255, 255, 1)'
      ? '#fff'
      : theme.colors.background.default;
  const textColor =
    theme.colors.text.default === 'rgba(255, 255, 255, 1)'
      ? '#fff'
      : theme.colors.text.default;

  return (
    <Box
      mx={{ base: 'defaultMargin', md: 0 }}
      sx={{
        '--zapier-brand': theme.colors.common.primary,
        '--zapier-brand-120': theme.colors.common.primaryDark,
        '--zapier-text': textColor,
        '--zapier-text-80': textColor,
        '--zapier-text-60': theme.colors.text.muted,
        '--zapier-bg-5': defaultBg,
        '--zapier-bg-5b': 'rgb(0 0 0 / 3%)',
        '--zapier-bg-0': defaultBg,
        '--zapier-icon-bg': '#fff',
        '--zapier-icon-color': theme.colors.icon.muted,
        '--zapier-icon-border-color': theme.colors.border.default,
        '--zapier-service-icon-border-color': theme.colors.border.default,
        '--zapier-input-border-color': theme.colors.background.primary,
        // '--zapier-border-color': theme.colors.border.muted,
        '--zapier-modal-background-color': defaultBg,
        '--zapier-modal-border-color': theme.colors.border.default,
        '--zapier-zap-state-off-bg': theme.colors.background.tint2,
        '--zapier-zap-state-off-text': theme.colors.text.muted,
        '--zapier-zap-state-on-bg': theme.colors.background.success,
        '--zapier-zap-state-on-text': theme.colors.text.success,
        '--zapier-avatar-bg': '#1a4a4c',
        '--zapier-avatar-text': '#fff',
        '--zapier-button-text': theme.colors.text.primary,
        '--zapier-button-bg': theme.colors.background.primary,
        '--zapier-button-bg-hover': theme.colors.background.primary,
        '--zapier-elevation-5': '0px 1px 2px 0px rgb(0 0 0 / 5%)',
        '--zapier-elevation-6': '0px 5px 15px 0px rgb(0 0 0 / 5%)',
        '--zapier-elevation-30': '0px 10px 30px 0px rgb(0 0 0 / 5%)',
        '--zapier-elevation-zap': '0px 10px 30px 0px rgb(0 0 0 / 5%)',
        '--zapier-grid-unit': '8px',
        '--zapier-button-input-border-radius': theme.radii.full,
        // '--zapier-font-family': '"Inter", Helvetica, arial, sans-serif',
        '--zapier-font-size-26px': 'max(calc(16px * 1.625), 1.625rem)',
        '--zapier-font-size-20px': 'max(calc(16px * 1.25), 1.25rem)',
        '--zapier-font-size-18px': 'max(calc(16px * 1.125), 1.125rem)',
        '--zapier-font-size-16px': 'max(16px, 1rem)',
        '--zapier-font-size-14px': 'max(calc(16px * 0.875), 0.875rem)',
        '--zapier-font-size-12px': 'max(calc(16px * 0.75), 0.75rem)',
      }}
    >
      {/* @ts-ignore */}
      <zapier-full-experience
        sign-up-email={user?.email || 'email_of_your_user@example.com'}
        sign-up-first-name={user?.firstName || 'first_name_of_your_user'}
        sign-up-last-name={user?.lastName || 'last_name_of_your_user'}
        client-id="ENbOhzBmodUS2y020SMv2lMSWCliCWqNtAqG0ezs"
        theme="auto"
        intro-copy-display="show"
        app-search-bar-display="show"
        zap-style="row"
        zap-call-to-action-display="show"
      />
    </Box>
  );
};

export default ZapierEmbed;
