import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  Flex,
  Text,
  Skeleton,
  Button,
  LinkButton,
  AlertDialogIcon,
  Stack,
  useMediaQuery,
} from '@workshop/ui';
import { saveAs } from 'file-saver';

import { baseUrl } from 'constants/env';
import { hooks } from 'utils';
import { IDownload } from 'types/cms';
import { CompleteUploadChunkAction } from 'types/common';

import {
  EditCard,
  DynamicList,
  LabelInput,
  LabelCheckbox,
  LabelWrapper,
  useDynamicList,
  IDynamicTable,
  EmbedModal,
} from 'components/Common';
import { IDynamicInputProps } from 'components/Common/DynamicList';

import isURL from 'validator/lib/isURL';

interface DownloadsCardProps {
  teacherOnly?: boolean;
  helpText?: string;
  items: IDownload[];
  onSave: (formData: IDynamicTable) => Promise<void>;
  onCancel?: () => void;
  isDisabled?: boolean;
  isUpdating?: boolean;
  isLoading?: boolean;
  hideCard?: boolean;
}

const noop = () => null;

const googleDocRegex =
  /^https:\/\/docs\.google\.com\/document\/d\/.*\/export\?format=pdf$/;

const sortItems = (a: IDownload, b: IDownload) => {
  if (a.id && b.id) {
    return a.id - b.id;
  }
  return 0;
};

const linkIsUpload = (link?: string) => {
  if (!link) return false;
  if (
    link.includes(baseUrl) ||
    link.includes('cdn.workshop.ws') ||
    link.includes('cdn.steppit.com') ||
    link.includes('cloudfront.net/media/chunked_uploads/')
  ) {
    return true;
  }
  return false;
};

const DownloadsCardItem: React.FC<
  IDynamicInputProps<{
    name: string;
    link: string;
    readOnly: boolean;
    // file: string | null;
  }> & {
    isDisabled?: boolean;
    isLoading?: boolean;
    setDirty: React.Dispatch<React.SetStateAction<boolean>>;
    onUploadFile: (file: { name: string; url: string }) => void;
  }
> = ({
  inputId,
  inputIndex,
  value,
  itemIsFinal,
  hasInputError,
  handleChangeEvent,
  handleRemove,
  isDisabled,
  isLoading,
  setDirty,
  onUploadFile,
}) => {
  const [itemType, setItemType] = useState<'link' | 'file' | null>(
    value?.link && linkIsUpload(value.link)
      ? 'file'
      : value?.link
      ? 'link'
      : null
  );
  const [isLinkOpen, setIsLinkOpen] = useState(false);

  const createChunkUpload = hooks.useChunkUpload();

  useEffect(() => {
    if (value?.link) {
      if (linkIsUpload(value.link)) {
        setItemType('file');
      } else {
        setItemType('link');
      }
    } else {
      setItemType(null);
    }
  }, [value?.link]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: async (acceptedFiles) => {
      const file = acceptedFiles?.length > 0 ? acceptedFiles[0] : null;
      if (!file) return;

      setItemType('file');
      const chunkUpload = createChunkUpload(file.name, file.size, {
        id: inputId,
      });

      const response = await chunkUpload.startUpload<CompleteUploadChunkAction>(
        file
      );

      // If chunked uploads are disabled (e.g. due to permissions) then calling
      // `startUpload` will result in a void response
      if (!response) {
        setItemType(null);
        return;
      }

      const { payload } = response;

      // Only run the remaining code if the upload was successful. A successful
      // upload will contain the 'file' property which we then use to finalize
      // the upload process
      if (!payload || !('file' in payload)) {
        setItemType(null);
        return;
      }

      const { file: payloadFile, filename } = payload;
      // The `file` included in the successful upload response gives us the full
      // path to the uploaded file. We want to add this to our video clip to 'link'
      // the uploaded file to a video clip object.
      onUploadFile({
        name: filename,
        url: payloadFile,
      });
    },
    accept: '.xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf',
    multiple: false,
    maxSize: 500000000,
  });

  const [isMobile] = useMediaQuery('(max-width: 500px)');
  const [isTablet] = useMediaQuery('(max-width: 767px)');

  const linkIsPdf = Boolean(
    value?.link && linkIsUpload(value.link) && value.link.endsWith('.pdf')
  );

  return (
    <Flex direction="column" borderBottomWidth={1} mb={3}>
      <Flex flexDirection={{ base: 'column', md: 'row' }}>
        <Flex direction="column" flex={1} mr="defaultMargin">
          <Skeleton isLoaded={!isLoading}>
            <LabelInput
              id={`name-${inputId}`}
              name="name"
              value={value?.name || ''}
              label={inputIndex === 0 && !isTablet ? 'Name' : ''}
              labelPosition="top"
              error={hasInputError && hasInputError('name')}
              errorMessage="This field is required"
              placeholder="Name"
              onChange={handleChangeEvent}
              onPaste={handleChangeEvent}
              isDisabled={isDisabled}
            />
          </Skeleton>
        </Flex>
        <Flex flex={1}>
          <Flex direction="column" flex={1} mr="defaultMargin">
            <Skeleton isLoaded={!isLoading}>
              <Flex display={itemType === 'link' ? 'flex' : 'none'}>
                <LabelInput
                  id={`link-${inputId}`}
                  name="link"
                  value={value?.link || ''}
                  label={inputIndex === 0 && !isTablet ? 'Resource' : ''}
                  labelPosition="top"
                  error={hasInputError && hasInputError('link')}
                  errorMessage="Please enter a valid URL"
                  placeholder="https://"
                  onChange={handleChangeEvent}
                  onPaste={handleChangeEvent}
                  isDisabled={isDisabled}
                />
              </Flex>
              {itemType === 'file' ? (
                <LabelWrapper
                  label={inputIndex === 0 && !isTablet ? 'Resource' : ''}
                  labelPosition="top"
                >
                  <Stack direction="row" alignItems="center" flex={1}>
                    <Button
                      flex={1}
                      icon="FileDownload"
                      onClick={() =>
                        saveAs(
                          value?.link || '',
                          value?.name || `Resource ${inputIndex}`
                        )
                      }
                      size={isMobile ? 'sm' : 'md'}
                      isDisabled={!value?.link}
                      isLoading={!value?.link}
                      secondary
                    >
                      Download
                    </Button>
                    {linkIsPdf && (
                      <Button
                        flex={1}
                        icon="Visibility"
                        size={isMobile ? 'sm' : 'md'}
                        isDisabled={!value?.link}
                        onClick={() => setIsLinkOpen(true)}
                        secondary
                      >
                        Open
                      </Button>
                    )}
                  </Stack>
                </LabelWrapper>
              ) : itemType === null ? (
                <LabelWrapper
                  label={inputIndex === 0 && !isTablet ? 'Resource' : ''}
                  labelPosition="top"
                >
                  <Stack direction="row" alignItems="center" flex={1}>
                    <Flex flex={1}>
                      <Button
                        flex={1}
                        variant="outline"
                        icon="AddLink"
                        onClick={() => setItemType('link')}
                        size={isMobile ? 'sm' : 'md'}
                      >
                        Add Link
                      </Button>
                    </Flex>
                    <Flex flex={1} {...getRootProps()}>
                      <input
                        id="file-upload"
                        type="file"
                        style={{ display: 'none' }}
                        {...getInputProps()}
                      />
                      <Button
                        flex={1}
                        variant="outline"
                        icon="FileUpload"
                        size={isMobile ? 'sm' : 'md'}
                      >
                        Upload File
                      </Button>
                    </Flex>
                  </Stack>
                </LabelWrapper>
              ) : null}
            </Skeleton>
          </Flex>
          {itemType === 'link' &&
            value?.link &&
            googleDocRegex.test(value.link) && (
              <Flex
                direction="column"
                justifyContent="center"
                mr="defaultMargin"
                display={{ base: 'none', md: 'flex' }}
                alignSelf="center"
              >
                <LinkButton
                  secondary
                  href={value.link.replace(/\/export\?format=pdf$/, '')}
                  mt={inputIndex === 0 && !isTablet ? 4 : 0}
                  mb={inputIndex === 0 && !isTablet ? 0 : 4}
                  target="_blank"
                  rel="noopener noreferrer"
                  size="sm"
                >
                  Open Google Doc
                </LinkButton>
              </Flex>
            )}
          {itemType === 'link' && value?.link && Boolean(isURL(value.link)) && (
            <Flex
              direction="column"
              justifyContent="center"
              mr="defaultMargin"
              display={{ base: 'none', md: 'flex' }}
              alignSelf="center"
            >
              <LinkButton
                variant="ghost"
                mt={inputIndex === 0 && !isTablet ? 4 : 0}
                mb={inputIndex === 0 && !isTablet ? 0 : 4}
                icon="OpenInNew"
                href={value.link}
                target="_blank"
                rel="noopener noreferrer"
                size="sm"
              />
            </Flex>
          )}
          <Flex direction="column" justifyContent="center" alignSelf="center">
            {!isLoading && !isDisabled && !itemIsFinal ? (
              <AlertDialogIcon
                name="RemoveCircle"
                alertHeader="Remove Download"
                alertBody="Are you sure you would like to remove this download?"
                submitBtnLabel="Remove"
                submitBtnColor="red"
                onSubmit={() => {
                  if (handleRemove) {
                    handleRemove();
                    setDirty(true);
                  }
                }}
                onCancel={() => {}}
                color={isLoading ? 'neutral.300' : 'red.300'}
                cursor={isLoading ? 'default' : 'pointer'}
                mt={inputIndex === 0 && !isTablet ? 4 : 0}
                mb={inputIndex === 0 && !isTablet ? 0 : 4}
                boxSize="18px"
                minW="18px"
                minH="18px"
              />
            ) : (
              <Flex boxSize="18px" />
            )}
          </Flex>
        </Flex>
      </Flex>
      <Flex display={linkIsPdf ? 'flex' : 'none'}>
        <LabelCheckbox
          id={`readOnly-${inputId}`}
          name="readOnly"
          isChecked={value?.readOnly}
          label="Disable downloads"
          isDisabled={isDisabled}
          tooltip="disable_downloads"
          onChange={handleChangeEvent}
        />
      </Flex>
      {linkIsPdf && value ? (
        <EmbedModal
          isOpen={isLinkOpen}
          onClose={() => setIsLinkOpen(false)}
          url={value.link}
          name={value.name || `Resource ${inputIndex}`}
        />
      ) : null}
    </Flex>
  );
};

const DownloadsCard: React.FC<DownloadsCardProps> = ({
  teacherOnly = false,
  items = [],
  helpText,
  onSave,
  onCancel = noop,
  isDisabled = false,
  isUpdating,
  isLoading = false,
  hideCard = false,
}) => {
  const rules = {
    name: (value: string) => !Boolean(value === ''),
    link: (value: string) => !value || Boolean(isURL(value)),
  };

  const { formData, setReset, isValid, isDirty, setDirty, ...dynamicProps } =
    useDynamicList(rules);

  const onSubmit = async (
    index?: number,
    file?: { name: string; url: string }
  ) => {
    const newFormData = Object.values(formData).reduce((acc, c, idx) => {
      const linkIsPdf = Boolean(
        c.link && linkIsUpload(c.link) && c.link.endsWith('.pdf')
      );
      return {
        ...acc,
        [idx]: {
          ...c,
          ...(c.name && c.link ? { teacherOnly: teacherOnly.toString() } : {}),
          ...(idx === index && file
            ? {
                name: c.name || file.name,
                link: file.url,
              }
            : {}),
          ...(!linkIsPdf
            ? {
                readOnly: false,
              }
            : {}),
        },
      };
    }, {} as IDynamicTable);
    await onSave(newFormData);
    setReset(true);
  };

  return (
    <EditCard
      onSave={onSubmit}
      onCancel={() => {
        onCancel();
        setReset(true);
      }}
      isDisabled={isDisabled}
      isUpdating={isUpdating}
      saveDisabled={!isValid || !isDirty}
      hideCard={hideCard}
    >
      <Flex flex={1}>
        <Flex flexDirection="column" flex={1}>
          <DynamicList defaultValues={items.sort(sortItems)} {...dynamicProps}>
            {(childProps) => (
              <DownloadsCardItem
                isDisabled={isDisabled}
                isLoading={isLoading}
                setDirty={setDirty}
                onUploadFile={(file) => {
                  onSubmit(childProps.inputIndex, file);
                }}
                {...childProps}
              />
            )}
          </DynamicList>
        </Flex>
      </Flex>
      {!isLoading && !isDisabled && helpText && (
        <Flex
          flex={1}
          flexDirection="column"
          marginBottom="defaultMargin"
          alignItems="center"
        >
          <Text fontSize="xs" color="text.muted" marginBottom="defaultMargin">
            {helpText}
          </Text>
        </Flex>
      )}
    </EditCard>
  );
};

export default DownloadsCard;
