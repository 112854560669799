import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router';
import { useLottie } from 'lottie-react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormContext } from 'react-hook-form';
import isEmail from 'validator/lib/isEmail';
import ReCAPTCHA from 'react-google-recaptcha';

import { assistantActions } from 'redux/actions/common';
import { CoursePlanInputs, CreateCoursePlanAction } from 'types/common';
import { GlobalState } from 'types';
import {
  useUser,
  useCurrentTeam,
  useCurrentTeamProfile,
  useIsAuthenticated,
} from 'redux/selectors';

import { PLATFORM, aiFeatureUrl } from 'constants/env';
import { PRO_ORGS, PRO_ASSISTANT_UNITS_LIMIT } from 'constants/organisation';
import {
  CURRENCY_SYMBOLS,
  PRICING_MATRIX,
  CURRENCY_POSITIONS,
} from 'constants/settings';

import navRoutes from 'navigation/Routes';

import { analytics, getParamFromUrl } from 'utils';
import { useWindowDimensions } from 'utils/hooks/useDimensions';

import {
  LinkBox,
  LinkOverlay,
  Link as ExternalLink,
  Flex,
  Text,
  Box,
  Stack,
  Container,
  Heading,
  useColorMode,
  useColorModeValue,
  useTheme,
  Button,
  LinkButton,
  Checkbox,
  Image,
  MdIcon,
  chakra,
} from '@workshop/ui';

import Brand from 'components/Brand';
import { Footer } from 'components/Footer';
import { Loading } from 'components/Loading';
import { AppHeader } from 'containers/AppHeader';

import { ScreenWrapper } from 'screens/common/ScreenWrapper';

import assistantAnimation from 'assets/lottie/assistant.json';
import iAi from 'assets/images/illustrations/i-ai.png';

import Message, { MessageProps } from './Message';
import SignupModal from './SignupModal';

interface PlannerScreenProps extends RouteComponentProps {}

const AUDIENCE_OPTIONS = {
  xs: 'Small but engaged (under 1k)',
  sm: 'Growing steadily (1k to 10k)',
  md: 'Sizeable and active (10k to 100k)',
  lg: 'Large and diverse (100k to 1m)',
  xl: 'Established and thriving (1m+)',
};

const AUDIENCE_VALUES = {
  xs: 0.1,
  sm: 0.2,
  md: 0.3,
  lg: 0.4,
  xl: 0.5,
};

const TONE_OF_VOICE_OPTIONS = {
  conversational:
    'Conversational (relaxed and natural, like chatting with a friend)',
  professional: 'Professional (formal and serious, like a business meeting)',
  inspirational:
    'Inspirational (uplifting and motivational, like a presentation)',
  playful: 'Playful (fun and lighthearted, like a game)',
  technical: 'Technical (precise and detailed, like an instruction manual)',
};

export const COURSE_ABOUT_PLACEHOLDERS = [
  'Deep tissue massage',
  'Advanced guitar techniques',
  'Music theory',
  'Music production',
  'Instagram marketing',
  'Street photography',
  'Japanese calligraphy',
  'Building surfboards',
  'Car repairs',
  'Restoring a bathroom',
  'Building a shed',
  'Pencil drawing',
  'Painting with acrylic',
  'Makeup techniques',
  'Crocheting a blanket',
  'Making cocktails',
];

const Steps = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [assistantJson, setAssistantJson] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [interruptedError, setInterruptedError] = useState(false);
  const [marketingConsent, setMarketingConsent] = useState(false);
  const [showSignupModal, setShowSignupModal] = useState('');
  useEffect(() => {
    // @ts-ignore
    setAssistantJson(assistantAnimation);
  }, []);
  const location = useLocation();
  const history = useHistory();
  const theme = useTheme();
  const { colorMode } = useColorMode();
  const windowDimensions = useWindowDimensions();
  const isMobile = Boolean(
    windowDimensions.width &&
      windowDimensions.width < parseInt(theme.breakpoints.md, 10)
  );
  const { View: lottieAssistant } = useLottie({
    animationData: assistantJson,
    style: {
      height: isMobile ? 60 : 120,
      width: isMobile ? 60 : 120,
    },
  });
  const user = useUser();
  const userEmail = user?.email;
  const currentTeam = useCurrentTeam();
  const currentTeamProfile = useCurrentTeamProfile();
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const formMethods = useForm<CoursePlanInputs>({
    mode: 'onChange',
    defaultValues: {
      what: '',
      who: '',
      why: '',
      units: '2',
      tone: 'conversational',
      audience: 'xs',
      email: userEmail || '',
    },
  });
  const dispatch = useDispatch();
  const { handleSubmit, reset, watch } = formMethods;

  const hasAccount = useIsAuthenticated();
  const hasChannel = hasAccount && !!currentTeam;
  const isPro = Boolean(
    currentTeamProfile?.isPro || (currentTeam && PRO_ORGS.includes(currentTeam))
  );

  const popupParam = getParamFromUrl(location, 'p');
  const sourceParam = getParamFromUrl(location, 's');

  useEffect(() => {
    if (popupParam === 'pro') {
      setShowSignupModal('pro');
    }
  }, [popupParam]);

  useEffect(() => {
    if (sourceParam === 'ai' && hasChannel) {
      history.push(navRoutes.cms.assistant.path());
    }
  }, [sourceParam, hasChannel]);

  const steps: MessageProps[] = [
    {
      message: `Hi! I'm your AI assistant, here to help you plan and create your online course. I'll ask you some questions and draft a plan, suggest a price and estimate your potential earnings. Ready to get started?`,
      fields: [],
    },
    {
      message: `Great! Let's start with your course idea. What is your course about?`,
      fields: [
        {
          name: 'what',
          type: 'multiline',
          intro: 'My course is about...',
          placeholders: COURSE_ABOUT_PLACEHOLDERS,
        },
      ],
    },
    {
      message: `Now think about your audience. Who would benefit most from your online course?`,
      fields: [
        {
          name: 'who',
          type: 'multiline',
          intro: 'My course is for...',
        },
      ],
    },
    {
      message: `What new skills or knowledge will your learners have when they complete your course?`,
      fields: [
        {
          name: 'why',
          type: 'multiline',
          intro: 'After taking my course, learners will be able to...',
        },
      ],
    },
    {
      message: `Almost done! Let's just fine-tune your preferences before generating your plan and predicting your income.`,
      fields: [
        {
          name: 'units',
          type: 'select',
          intro: 'The length of my course is...',
          options: isPro
            ? {
                '2': '2 weeks',
                '3': '3 weeks',
                '4': '4 weeks',
                '5': '5 weeks',
                '6': '6 weeks',
                '7': '7 weeks',
                '8': '8 weeks',
              }
            : {
                '2': '2 weeks',
                '3': '3 weeks',
                '4': '4 weeks',
              },
          helpText: isPro
            ? ''
            : hasChannel
            ? `Upgrade your channel to Pro to plan up to ${PRO_ASSISTANT_UNITS_LIMIT} weeks of course content`
            : `Sign up for a Pro channel to plan up to ${PRO_ASSISTANT_UNITS_LIMIT} weeks of course content`,
        },
        {
          name: 'tone',
          type: 'select',
          intro: 'The tone of voice for my course is...',
          options: TONE_OF_VOICE_OPTIONS,
        },
        {
          name: 'audience',
          type: 'select',
          intro: 'My current audience is...',
          options: AUDIENCE_OPTIONS,
        },
      ],
    },
  ];
  if (!userEmail) {
    steps.push({
      message: `Your plan will take a few minutes to generate. Where can I send it?`,
      fields: [
        {
          name: 'email',
          type: 'email',
          intro: 'Email address:',
        },
      ],
    });
  }

  const step1Value = watch('what');
  const step2Value = watch('who');
  const step3Value = watch('why');
  const step4aValue = watch('units');
  const step4bValue = watch('tone');
  const step4cValue = watch('audience');

  const enteredEmail = watch('email');
  const step5Value = userEmail || isEmail(enteredEmail);

  const lastStepNumber = userEmail ? 4 : 5;
  const buttonDisabled =
    (currentStep >= 1 && !step1Value) ||
    (currentStep >= 2 && !step2Value) ||
    (currentStep >= 3 && !step3Value) ||
    (currentStep >= 4 && (!step4aValue || !step4bValue || !step4cValue)) ||
    (currentStep >= lastStepNumber && !step5Value) ||
    (currentStep >= lastStepNumber && !recaptchaValue);

  const geoLocation = useSelector(
    (state: GlobalState) => state.background.location
  );
  const allowedCurrencyCodes = Object.keys(CURRENCY_SYMBOLS);
  const locationCurrency = (geoLocation?.currency as string) || 'USD';
  const currency = allowedCurrencyCodes.includes(locationCurrency)
    ? locationCurrency
    : 'USD';
  // Selected price tier options for AI plans
  const pricingOptions = [2, 4, 8, 12, 15, 17, 21, 25, 29, 33, 37, 44]
    .map(
      (t) =>
        `${
          // @ts-ignore
          CURRENCY_POSITIONS[currency] === 'before'
            ? // @ts-ignore
              CURRENCY_SYMBOLS[currency]
            : ''
          // @ts-ignore
        }${PRICING_MATRIX[t][currency]}${
          // @ts-ignore
          CURRENCY_POSITIONS[currency] === 'after'
            ? // @ts-ignore
              ` ${CURRENCY_SYMBOLS[currency]}`
            : ''
        }`,
      {} as { [key: string]: string }
    )
    .toString();

  const processPlanResponse = (planRes: CreateCoursePlanAction) => {
    if (planRes.error) {
      setRecaptchaValue(null);
      if (planRes.payload && 'normalizedErrors' in planRes.payload) {
        setErrorMessage(planRes.payload.normalizedErrors?.message as string);
      } else if (
        planRes.payload &&
        'name' in planRes.payload &&
        'message' in planRes.payload &&
        planRes.payload.name === 'RequestError' &&
        planRes.payload.message === 'Load failed'
      ) {
        // This error occurs on mobile web browsers when the browser
        // is closed while the plan is generating
        setInterruptedError(true);
      }
    } else if (planRes.payload && 'slug' in planRes.payload) {
      history.push(navRoutes.global.coursePlan.path(planRes.payload.slug));
      return;
    }
    setIsGenerating(false);
  };

  const generatePlan = async () => {
    const submit = handleSubmit(async (data) => {
      setIsGenerating(true);
      setErrorMessage('');
      window.scrollTo(0, 0);
      const planRes = await dispatch(
        assistantActions.createCoursePlan({
          ...data,
          tone: TONE_OF_VOICE_OPTIONS[
            data.tone as keyof typeof TONE_OF_VOICE_OPTIONS
          ],
          audience:
            AUDIENCE_OPTIONS[data.audience as keyof typeof AUDIENCE_OPTIONS],
          email: userEmail || data.email,
          recaptcha: recaptchaValue,
          team: currentTeam ? `${currentTeam}` : '',
          marketing: marketingConsent,
          currency,
          pricingOptions,
        })
      );
      if (planRes.error) {
        reset(data);
      }
      processPlanResponse(planRes);
      analytics.logConversion(dispatch, 'generatedPlan', {
        value: AUDIENCE_VALUES[data.audience as keyof typeof AUDIENCE_VALUES],
        currency: 'GBP',
      });
      if (data.email) {
        localStorage.setItem('planEmail', data.email);
      }
    });
    submit();
  };

  if (interruptedError) {
    return (
      <Container maxW="7xl">
        <Flex mt={{ base: 6, md: 12 }} py={{ base: 20, md: 28 }}>
          <Flex
            bg="background.tint3"
            py={8}
            px={{ base: 6, md: 8 }}
            borderRadius="lg"
            flexDirection="column"
            alignItems="center"
            textAlign="center"
            flex={1}
          >
            <Text mb={2} fontWeight="bold" fontSize="lg">
              Your Plan Is On The Way
            </Text>
            <Text mb={6}>
              Please check your email to find your course plan. It might take a
              few minutes to arrive.
            </Text>
            <Button
              icon="Replay"
              variant="outline"
              onClick={() => window.location.reload()}
            >
              Create New Plan
            </Button>
          </Flex>
        </Flex>
      </Container>
    );
  }

  return (
    <>
      <Container maxW="7xl" overflowX="clip">
        <Stack spacing={{ base: 8, md: 10 }} pt={{ base: 24, md: 28 }}>
          {isGenerating ? (
            <Flex pt={40}>
              <Loading />
            </Flex>
          ) : (
            <>
              <Heading
                lineHeight={1.1}
                fontWeight="extrabold"
                fontSize={{ base: 'xl', sm: '3xl', md: '4xl', lg: '5xl' }}
                alignSelf="center"
                textAlign="center"
              >
                Let's Plan Your Course
              </Heading>

              <Box position="relative" pl={{ base: '40px', md: '80px' }}>
                <Flex
                  position="absolute"
                  bottom={{ base: '-40px', md: '-80px' }}
                  left={0}
                  zIndex={2}
                >
                  {lottieAssistant}
                </Flex>
                <FormContext {...formMethods}>
                  {steps
                    .filter((s, idx) => idx <= currentStep)
                    .map((s, idx) => (
                      <Message
                        key={`message-${idx}`}
                        {...s}
                        isCurrent={idx === currentStep}
                        isMobile={isMobile}
                      />
                    ))}
                </FormContext>
              </Box>
              {Boolean(currentStep === steps.length - 1) && (
                <Flex alignSelf="flex-end">
                  <ReCAPTCHA
                    sitekey={
                      process.env.REACT_APP_RECAPTCHA_KEY ||
                      '6LeglgglAAAAAOvG4xc_ewMKOqtwj3Rmp_9QrdfL'
                    }
                    theme={colorMode}
                    onChange={(value: any) => setRecaptchaValue(value)}
                  />
                </Flex>
              )}
              {currentStep === steps.length - 1 && !userEmail && (
                <Checkbox
                  id="marketingConsent"
                  name="marketingConsent"
                  alignSelf="flex-end"
                  isChecked={marketingConsent}
                  mb="defaultMargin"
                  onChange={(e) => setMarketingConsent(e.target.checked)}
                >
                  <Text fontSize="sm" textAlign="right">
                    I'm happy to receive exclusive early-bird updates, guides
                    and discounts from Steppit.
                  </Text>
                </Checkbox>
              )}
              <Button
                alignSelf="flex-end"
                size="lg"
                px={6}
                icon="ArrowForward"
                iconPosition="right"
                onClick={() => {
                  if (currentStep === steps.length - 1) {
                    generatePlan();
                    if (currentStep === 4) {
                      analytics.track('Course Plan Preferences Entered');
                    }
                    if (enteredEmail && !userEmail) {
                      analytics.identify({ set: { email: enteredEmail } });
                    }
                    analytics.track('Course Plan Generated');
                  } else {
                    const analyticsEvent =
                      currentStep === 0
                        ? 'Course Plan Started'
                        : currentStep === 1
                        ? 'Course Plan Subject Entered'
                        : currentStep === 2
                        ? 'Course Plan Audience Entered'
                        : currentStep === 3
                        ? 'Course Plan Outcome Entered'
                        : currentStep === 4
                        ? 'Course Plan Preferences Entered'
                        : '';
                    if (analyticsEvent) {
                      analytics.track(analyticsEvent);
                      if (currentStep === 0) {
                        analytics.logConversion(dispatch, 'startedPlan');
                      }
                    }
                    setCurrentStep(currentStep + 1);
                  }
                }}
                isDisabled={buttonDisabled}
              >
                {currentStep === 0
                  ? 'Start'
                  : currentStep === steps.length - 1
                  ? 'Generate My Plan'
                  : 'Next'}
              </Button>
              {errorMessage ? (
                <Box
                  alignSelf="flex-end"
                  bg="background.error"
                  p={4}
                  borderRadius="md"
                  maxWidth={600}
                >
                  <Text color="text.error" textAlign="right">
                    {errorMessage}
                  </Text>
                </Box>
              ) : null}
            </>
          )}
        </Stack>
        {currentStep === steps.length - 1 && !isGenerating && (
          <Text color="text.muted" textAlign="center" fontSize="sm">
            By generating your plan, you agree to Steppit's{' '}
            <ExternalLink
              color="text.primary"
              href={`${navRoutes.global.terms.path()}#ai-terms`}
              target="_blank"
              rel="noopener noreferrer"
            >
              AI Terms
            </ExternalLink>
            .
          </Text>
        )}
      </Container>
      {!isPro && (
        <SignupModal
          isOpen={!!showSignupModal}
          onClose={() => setShowSignupModal('')}
          onBuild={() => null}
          // @ts-ignore
          context={showSignupModal}
        />
      )}
      {isPro ? (
        <Stack
          direction={{ base: 'column', lg: 'row' }}
          textAlign={{ base: 'center', lg: 'left' }}
          bg="background.warning"
          color="text.warning"
          borderRadius="lg"
          alignItems="center"
          p={{ base: 4, md: 6 }}
          py={6}
          mt={20}
          mx={{ base: 4, md: 0 }}
          spacing={6}
          maxWidth={{ base: '500px', lg: '700px' }}
          alignSelf="center"
        >
          <Box flex={1}>
            <Text mb={1}>This is the Basic Course Planner</Text>
            <Text fontSize={{ base: 'lg', sm: 'xl' }} fontWeight="bold">
              As a Pro member, you can generate tailored plans with your Pro
              Course Planner
            </Text>
          </Box>
          <Button
            onClick={() =>
              history.push({
                pathname: navRoutes.cms.catalogue.path(),
                search: 'p=course',
              })
            }
            icon="AutoAwesome"
            colorScheme="orange"
          >
            Plan a New Course
          </Button>
        </Stack>
      ) : (
        <Flex
          flexDirection={{ base: 'column', md: 'row' }}
          textAlign={{ base: 'center', md: 'left' }}
          bg="background.success"
          color="text.success"
          borderRadius="lg"
          alignItems="center"
          p={{ base: 4, md: 6 }}
          mt={20}
          mx={{ base: 4, md: 0 }}
          w={{ base: 'auto', md: '100%' }}
          maxWidth={{ base: '500px', md: 'none' }}
          alignSelf="center"
        >
          <Flex
            position="relative"
            width={{
              base: '220px',
              sm: '250px',
              md: '250px',
              lg: '340px',
              xl: '370px',
            }}
            height={{
              base: '100px',
              sm: '120px',
              md: '200px',
              lg: '220px',
              xl: '240px',
            }}
            alignItems="center"
            mt={{ base: 8, md: 0 }}
            display={{ base: 'none', md: 'flex' }}
          >
            <Box
              width={{
                base: '220px',
                sm: '250px',
                md: '250px',
                lg: '350px',
                xl: '400px',
              }}
              position="absolute"
              bottom={{
                base: 'auto',
                md: 'auto',
                lg: '-100px',
                xl: '-120px',
              }}
              left={{ base: 'auto', md: '-40px', lg: '-60px', xl: '-85px' }}
              top={{ base: '-120px', md: 'auto' }}
            >
              <Image alt="AI course creator illustration" src={iAi} />
            </Box>
          </Flex>
          <Flex
            flexDirection="column"
            alignItems={{ base: 'center', md: 'flex-start' }}
            flex={1}
            maxWidth="700px"
            mx="auto"
          >
            <Text
              fontSize="2xl"
              fontWeight="extrabold"
              display={{ base: 'none', md: 'inline' }}
            >
              Want a plan tailored to you?
            </Text>
            <MdIcon
              display={{ base: 'block', md: 'none' }}
              name="AutoAwesome"
              fontSize="2xl"
              mb={2}
            />
            <Text fontSize="xl" fontWeight="bold" mb={2}>
              {currentTeamProfile?.proTrialRedeemed
                ? 'Try the next level of AI with Pro'
                : 'Try our Pro AI free for 7 days'}
            </Text>
            <Text mb={5}>
              Get a taste of Steppit's free AI above, or upgrade to Pro to coach
              your own AI assistant and discover how easy it can be to build
              fully bespoke courses your way.
            </Text>
            <Stack
              direction={{ base: 'column', md: 'row' }}
              alignItems="center"
            >
              <Button
                onClick={() => setShowSignupModal('pro')}
                icon="ArrowForward"
                iconPosition="right"
                variant="outline"
              >
                {currentTeamProfile?.proTrialRedeemed
                  ? 'Upgrade to Pro'
                  : 'Try Pro for Free'}
              </Button>
              <LinkButton
                variant="ghost"
                icon="OpenInNew"
                iconPosition="right"
                href={aiFeatureUrl}
              >
                Learn More
              </LinkButton>
            </Stack>
          </Flex>
        </Flex>
      )}
    </>
  );
};

const PlannerScreen: React.FC<PlannerScreenProps> = ({}) => {
  const bg = useColorModeValue('background.default', 'background.tint3');

  const history = useHistory();
  if (PLATFORM !== 'steppit') {
    history.push(navRoutes.public.login.path());
    return null;
  }

  return (
    <Box bg={bg}>
      <Flex
        position="fixed"
        top={0}
        left={0}
        padding={{ base: 0, md: 0.5 }}
        zIndex={1100}
        width="100%"
        backgroundColor={bg}
      >
        <LinkBox padding={3} zIndex={1101}>
          <LinkOverlay href="/" aria-label="Steppit Homepage">
            <Brand navbarTransparent={false} />
          </LinkOverlay>
        </LinkBox>
        <AppHeader topPosition={0} onBg />
      </Flex>
      <ScreenWrapper>
        <Steps />
      </ScreenWrapper>
      <Footer />
    </Box>
  );
};

export default PlannerScreen;
