import React from 'react';
import isEqual from 'fast-deep-equal';

function checkDeps(deps?: React.DependencyList) {
  if (!deps || !deps.length) {
    console.warn(
      'useDeepEqualEffect should not be used with no dependencies. Use React.useEffect instead.'
    );
    return;
  }
  if (deps?.every(isPrimitive)) {
    console.warn(
      'useDeepEqualEffect should not be used with dependencies that are all primitive values. Use React.useEffect instead.'
    );
  }
}

function isPrimitive(val: React.DependencyList) {
  return val == null || /^[sbn]/.test(typeof val);
}

function useDeepCompareMemoize(value?: React.DependencyList) {
  const ref = React.useRef<React.DependencyList>();

  if (!isEqual(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

function useDeepEqualEffect(
  callback: React.EffectCallback,
  dependencies?: React.DependencyList
) {
  if (process.env.NODE_ENV !== 'production') {
    checkDeps(dependencies);
  }
  React.useEffect(callback, useDeepCompareMemoize(dependencies));
}

export function useDeepEqualEffectNoCheck(
  callback: React.EffectCallback,
  dependencies?: React.DependencyList
) {
  React.useEffect(callback, useDeepCompareMemoize(dependencies));
}

export default useDeepEqualEffect;
