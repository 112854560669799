import React from 'react';
import { Link } from 'react-router-dom';

import { PLATFORM_WEBSITE } from 'constants/common';

import navRoutes from 'navigation/Routes';
import { useCurrentTeamProfile } from 'redux/selectors';

import {
  Flex,
  MdIcon,
  Text,
  Button,
  LinkButton,
  Box,
  Divider,
  useToast,
} from '@workshop/ui';

import { Loading } from 'components/Loading';
import { ProCta } from 'components/Common';
import { EditModal } from 'components/Common/EditModal';

interface ShareSessionModalProps {
  isOpen: boolean;
  onClose: () => void;
  courseSlug: string;
  channelHandle: string;
  isPublic: boolean;
  setIsPublic: (isPublic: boolean) => void;
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
  isLoading: boolean;
}

const ShareSessionModal: React.FC<ShareSessionModalProps> = ({
  isOpen,
  onClose,
  courseSlug,
  channelHandle,
  isPublic,
  setIsPublic,
  isVisible,
  setIsVisible,
  isLoading,
}) => {
  const toast = useToast();
  const currentTeamProfile = useCurrentTeamProfile();

  const relativePath =
    navRoutes.global.publicStandaloneSession.path(courseSlug);
  const path = `${window.location.host}${relativePath}`;
  const fullPath = `${window.location.protocol}//${path}`;

  const embedPath = navRoutes.global.embedStandaloneSession.path(courseSlug);
  const embedCode = `<iframe width="720" height="405" src="${window.location.protocol}//${window.location.host}${embedPath}" title="Steppit session" frameborder="0" allow="camera; microphone; display-capture; fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`;

  return (
    <EditModal
      title="Share Your Session"
      isOpen={isOpen}
      onSave={() => null}
      onClose={onClose}
      onCancel={onClose}
      modalSize="xl"
      saveLabel=""
      cancelLabel="Done"
    >
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Flex
            mb={1}
            alignItems={{ base: 'flex-start', sm: 'center' }}
            flexDirection={{ base: 'column', sm: 'row' }}
            fontSize={{ base: 'sm', md: 'md' }}
          >
            <Flex alignItems="center" flex={1} mb={{ base: 2, sm: 0 }}>
              <Text fontWeight="semibold" mr={3}>
                Session Availability:
              </Text>
              <MdIcon
                name={isPublic ? 'Public' : 'PublicOff'}
                color={isPublic ? 'icon.success' : 'icon.muted'}
                mr={1.5}
              />
              <Text
                fontWeight="semibold"
                color={isPublic ? 'text.success' : 'text.muted'}
                mr={2}
              >
                {isPublic ? 'Public' : 'Hidden'}
              </Text>
            </Flex>
            <Button
              variant="outline"
              size="sm"
              onClick={() => setIsPublic(!isPublic)}
            >
              {isPublic ? 'Hide From Public' : 'Make Public'}
            </Button>
          </Flex>

          {isPublic && (
            <>
              <Text mb={2} fontSize="xs" color="text.muted">
                Here's the public link to your session for you to share:
              </Text>
              <Link to={relativePath}>
                <Box
                  borderRadius="md"
                  backgroundColor="background.success"
                  py={3}
                  px={4}
                  cursor="pointer"
                  _hover={{ opacity: 0.75 }}
                  textAlign="center"
                >
                  <Text
                    color="text.success"
                    fontWeight="semibold"
                    fontSize={{ base: 'md', md: 'lg' }}
                  >
                    {path}
                  </Text>
                </Box>
              </Link>
              <Flex justifyContent="flex-end" mb={6} mt={2}>
                <Button
                  variant="ghost"
                  size="xs"
                  onClick={() => {
                    navigator.clipboard?.writeText(fullPath);
                    toast({
                      title: `Copied link to clipboard`,
                      status: 'info',
                      duration: 3000,
                    });
                  }}
                >
                  Copy Link
                </Button>
              </Flex>
              <Divider mt={6} mb={6} />
              <Box fontSize={{ base: 'sm', md: 'md' }}>
                <Flex
                  alignItems={{ base: 'flex-start', sm: 'center' }}
                  flexDirection={{ base: 'column', sm: 'row' }}
                >
                  <Flex alignItems="center" flex={1} mb={{ base: 2, sm: 0 }}>
                    <Text fontWeight="semibold" mr={3}>
                      Visibility:
                    </Text>
                    <MdIcon
                      name={isVisible ? 'Store' : 'VisibilityOff'}
                      color={isVisible ? 'icon.primary' : 'icon.muted'}
                      mr={1.5}
                    />
                    <Text
                      fontWeight="semibold"
                      color={isVisible ? 'text.primary' : 'text.muted'}
                      mr={2}
                    >
                      {isVisible ? 'On My Channel' : 'Unlisted'}
                    </Text>
                  </Flex>
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() => setIsVisible(!isVisible)}
                  >
                    {isVisible ? 'Hide From Channel' : 'Show On Channel'}
                  </Button>
                </Flex>
                <Text fontSize="xs" color="text.muted" mt={1}>
                  {isVisible
                    ? `This session is accessible from ${`${PLATFORM_WEBSITE}/@${channelHandle}`}`
                    : 'This session is currently not visible on your channel'}
                </Text>
              </Box>
              <Divider mt={6} mb={6} />
              <Box fontSize={{ base: 'sm', md: 'md' }}>
                <Flex
                  alignItems={{ base: 'flex-start', sm: 'center' }}
                  flexDirection={{ base: 'column', sm: 'row' }}
                >
                  <Flex alignItems="center" flex={1} mb={{ base: 2, sm: 0 }}>
                    <Text fontWeight="semibold" mr={3}>
                      Embed Session:
                    </Text>
                  </Flex>
                  {currentTeamProfile?.proPlan?.tier &&
                  currentTeamProfile.proPlan.tier >= 2 ? (
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={() => {
                        navigator.clipboard?.writeText(embedCode);
                        toast({
                          title: `Copied embed code to clipboard`,
                          status: 'info',
                          duration: 3000,
                        });
                      }}
                    >
                      Copy Embed Code
                    </Button>
                  ) : currentTeamProfile?.isPro ? (
                    <LinkButton
                      variant="outline"
                      size="sm"
                      colorScheme="orange"
                      to={`${navRoutes.cms.myOrganisation.path()}?tab=subscriptions`}
                    >
                      Upgrade Plan
                    </LinkButton>
                  ) : (
                    <ProCta
                      hideProIcon
                      label=""
                      ctaProps={{
                        variant: 'outline',
                        width: '100%',
                        size: 'sm',
                      }}
                      ctaText="Upgrade to Pro"
                    />
                  )}
                </Flex>
                <Text fontSize="xs" color="text.muted" mt={1}>
                  Embed this session on another website.
                </Text>
                {(!currentTeamProfile?.isPro ||
                  (currentTeamProfile?.proPlan?.tier &&
                    currentTeamProfile.proPlan.tier < 2)) && (
                  <Text
                    fontSize="xs"
                    color="text.muted"
                    mt={1}
                    fontWeight="semibold"
                  >
                    Upgrade to Steppit Pro Growth or higher to unlock.
                  </Text>
                )}
              </Box>
            </>
          )}
        </>
      )}
    </EditModal>
  );
};

export default ShareSessionModal;
