import React from 'react';
import groupBy from 'lodash/groupBy';
import { Box, Button, Flex, Text, MdIcon } from '@workshop/ui';

import { CAP_CLIP_NAME, CLIP_NAME } from 'constants/common';

import {
  MCQ,
  MultipleChoiceQuestion,
} from 'components/SessionPlayer/MultipleChoiceQuestion';
import { CheckList, CheckListProps } from 'components/SessionPlayer/CheckList';
import { Prompt, IPrompt } from 'components/SessionPlayer/Prompt';
import { Orientation } from 'components/VideoClipsPlayer/types';
import { Step as StepperStep } from 'components/Stepper';

import { tourIds } from 'screens/cms/SessionEdit';

export interface BaseStep {
  id: string | number;
  clipMediaType?: 'video' | 'image' | 'audio' | 'text';
  clipSrc?: string;
  clipSrcHq?: string;
  footer?: React.ReactNode;
  notes?: string;
  orientation?: Orientation;
  onClickNext?: () => Promise<any> | void;
  onClickPrev?: () => void;
  showNextBtn?: boolean;
  showPrevBtn?: boolean;
  nextLabel?: string;
  prevLabel?: string;
}

export interface GuidedStep extends BaseStep {
  subStepType: 'guided';
  summary?: React.ReactNode | React.FC;
  script?: React.ReactNode | React.FC;
}

export interface MCQStep extends BaseStep {
  subStepType: 'mcq';
  multipleChoiceQuestion: MultipleChoiceQuestion;
}

export interface CheckListStep extends BaseStep, Omit<CheckListProps, 'title'> {
  subStepType: 'checklist';
  title: React.ReactNode;
}

export interface PromptStep extends BaseStep, IPrompt {
  subStepType: 'prompt';
}

export type SessionStepperStep =
  | GuidedStep
  | MCQStep
  | CheckListStep
  | PromptStep;

/**
 * Returns a multiple choice question + title
 * (title is based on the total number of questions
 * included in the mcq group)
 * */
export const generateMCQStepContent = (
  mcq: MultipleChoiceQuestion,
  totalQuestions: number,
  questionIdx: number,
  onSubmit: () => void
) => {
  const title =
    totalQuestions > 1
      ? `Quick question ${questionIdx + 1} of ${totalQuestions}`
      : null;

  return (
    <Box p={2}>
      <MCQ
        {...mcq}
        onSubmit={async (answers: number[]) => {
          mcq.onSubmit && (await mcq.onSubmit(answers));
          onSubmit();
        }}
        title={title}
      />
    </Box>
  );
};

/**
 * Concatenate all guided steps summaries into a single piece of text
 * - summaries are separated by br tags
 * - current step summary is highlighted, rest of text is muted
 * */
export const generateGuidedStepContent = (
  steps: SessionStepperStep[],
  stepGroupIdx: number,
  isCurrentStep: boolean,
  currentRef: React.RefObject<HTMLDivElement>,
  onClickClipSummary: (idx: number) => void,
  isEditable: boolean = false,
  onEdit: () => void = () => null
) => {
  return steps.reduce((acc: React.ReactNode[], step, idx) => {
    if (step.subStepType !== 'guided' || (!isEditable && !step.summary)) {
      return acc;
    }

    const highlightSummary = idx === stepGroupIdx && isCurrentStep;

    const summary =
      typeof step.summary === 'function' ? <step.summary /> : step.summary;

    const stepSummaries = [
      ...acc,
      <Box
        key={`box-${idx}`}
        ref={highlightSummary ? currentRef : null}
        position="relative"
        backgroundColor={
          highlightSummary
            ? isEditable
              ? 'background.primary'
              : 'background.tint1'
            : 'transparent'
        }
        p={3}
        mb={2}
        borderRadius="md"
        cursor={highlightSummary && !isEditable ? 'neutral' : 'pointer'}
        onClick={
          highlightSummary && isEditable
            ? onEdit
            : () => onClickClipSummary(idx)
        }
        _hover={{
          backgroundColor:
            highlightSummary && isEditable
              ? 'background.primaryDark'
              : 'background.tint3',
        }}
        // {...(highlightSummary &&
        //   isEditable && { 'data-tour': tourIds.sessionSummaries })}
      >
        {isEditable && !step.summary ? (
          <Box
          // borderWidth="1px"
          // borderColor="border.default"
          // borderRadius="md"
          // p={2}
          // backgroundColor="background.default"
          >
            <Text color={highlightSummary ? 'text.primary' : 'icon.disabled'}>
              {highlightSummary
                ? `Write a summary for this ${CLIP_NAME}...`
                : `${CAP_CLIP_NAME} #${idx + 1}`}
            </Text>
          </Box>
        ) : (
          <Text
            fontSize={highlightSummary ? 'lg' : 'md'}
            fontWeight={
              highlightSummary && step.clipMediaType === 'text'
                ? 'semibold'
                : 'normal'
            }
            color={highlightSummary ? 'text.default' : 'text.muted'}
            transition="font-size 0.3s"
          >
            {summary}
          </Text>
        )}
        {isEditable && step.summary && highlightSummary && (
          <Box position="absolute" bottom={0} right={0} p={1}>
            <MdIcon name="Edit" boxSize="icon" color="icon.muted" />
          </Box>
        )}
      </Box>,
    ];

    return stepSummaries;
  }, []);
};

export const generateCheckListStepContent = (
  step: CheckListStep,
  onLastItemChecked: () => void
) => {
  const {
    items,
    title,
    onLastItemChecked: onLastChecked,
    onItemChecked,
    onItemUnchecked,
  } = step;

  return (
    <Flex flexDirection="column" p={2}>
      <Text fontWeight="semibold" mb={4}>
        {title}
      </Text>
      <CheckList
        items={items}
        onItemChecked={(slug) => onItemChecked && onItemChecked(slug)}
        onItemUnchecked={(slug) => onItemUnchecked && onItemUnchecked(slug)}
        onLastItemChecked={() => {
          onLastChecked && onLastChecked();
          onLastItemChecked();
        }}
      />
    </Flex>
  );
};

export const generatePromptStepContent = (
  { id, help, question, title, promptProps, responseType }: PromptStep,
  onSubmit: () => void
) => {
  return (
    <Box p={2}>
      <Prompt
        id={id}
        help={help}
        question={question}
        title={title}
        promptProps={promptProps}
        responseType={responseType}
      />
    </Box>
  );
};

/**
 * Create groups of steps based on their type and position in the list
  e.g:
  [
    { stepType: 'guided', ...stepData },
    { stepType: 'guided', ...stepData },
    { stepType: 'mcq',    ...stepData },
    { stepType: 'mcq',    ...stepData },
    { stepType: 'mcq',    ...stepData },
    { stepType: 'guided', ...stepData },
  ]
  -->
  [
    { stepType: 'guided', stepGroup: '1_guided',  stepGroupIdx: 0,  ...stepData },
    { stepType: 'guided', stepGroup: '1_guided',  stepGroupIdx: 1   ...stepData },
    { stepType: 'mcq',    stepGroup: '2_mcq',     stepGroupIdx: 0,  ...stepData },
    { stepType: 'mcq',    stepGroup: '2_mcq',     stepGroupIdx: 1,  ...stepData },
    { stepType: 'mcq',    stepGroup: '2_mcq',     stepGroupIdx: 2,  ...stepData },
    { stepType: 'guided', stepGroup: '3_guided',  stepGroupIdx: 0,  ...stepData },
  ]
*/
export const groupSteps = (steps: SessionStepperStep[]) => {
  // string to group steps by step type (e.g 'mcq' or 'guided')
  let group = '';
  // unique Id to keep relevant steps grouped together
  let groupId = 0;
  // idx of the step inside its relevant group
  let stepGroupIdx = 0;

  return steps.map((step, idx) => {
    if (step.subStepType !== group) {
      group = step.subStepType;
      groupId += 1;
      stepGroupIdx = 0;
    }

    const updatedStep = {
      ...step,
      stepGroup: `${groupId}_${group}`,
      stepGroupIdx,
    };

    stepGroupIdx += 1;

    return updatedStep;
  });
};

interface GenerateStepperStepsArgs {
  currentRef: React.RefObject<HTMLDivElement>;
  currentStepIdx: number;
  nextDisabled: boolean;
  onClickNext: () => void;
  onClickPrev: () => void;
  onClickClipSummary: (idx: number) => void;
  isEditable?: boolean;
  onEdit?: () => void;
  setDisplayNotes: (val: boolean) => void;
  setNextDisabled: (val: boolean) => void;
  sessionSubsteps: SessionStepperStep[];
  title: string;
}

/**
 * Generates steps to be passed as a prop to the Stepper component
 * */
export const generateStepperSteps = ({
  currentRef,
  currentStepIdx,
  nextDisabled,
  onClickNext,
  onClickPrev,
  onClickClipSummary,
  isEditable = false,
  onEdit = () => null,
  setDisplayNotes,
  setNextDisabled,
  sessionSubsteps,
  title,
}: GenerateStepperStepsArgs): StepperStep[] => {
  /**
   * This function is used to generate steps to be rendered by the Stepper component
   *
   * Most of the work focuses on the children prop (i.e what to render
   * inside the Stepper container):
   * - guided steps     --> render all the summaries from a group of steps
   * - checklist steps  --> render a CheckList component
   * - mcq steps        --> render a MCQ component
   * - prompt steps     --> render a Prompt component
   * */

  /**
   * Add stepGroup and stepGroupIdx to each step
   * These are used to determine the current status for a guided step
   * or for an MCQ step (e.g, question 2 of 3) */
  const stepsWithGroups = groupSteps(sessionSubsteps);

  /** Group all steps for easier access to a given group */
  const groupedSteps = groupBy(stepsWithGroups, 'stepGroup');

  return stepsWithGroups.map((step, idx) => {
    const isCurrentStep = idx === currentStepIdx;
    const stepsGroup = groupedSteps[step.stepGroup];

    let children = null;

    switch (step.subStepType) {
      case 'guided':
        children = generateGuidedStepContent(
          stepsGroup,
          step.stepGroupIdx,
          isCurrentStep,
          currentRef,
          onClickClipSummary,
          isEditable,
          onEdit
        );
        break;

      case 'mcq':
        children = generateMCQStepContent(
          step.multipleChoiceQuestion,
          stepsGroup.length,
          step.stepGroupIdx,
          () => setNextDisabled(false)
        );
        break;

      case 'checklist':
        children = generateCheckListStepContent(step, () =>
          setNextDisabled(false)
        );
        break;

      case 'prompt':
        children = generatePromptStepContent(step, () =>
          setNextDisabled(false)
        );
        break;
      default:
        break;
    }

    const {
      footer,
      showPrevBtn = true,
      showNextBtn = true,
      clipMediaType,
      nextLabel = 'Next',
      prevLabel = 'Previous',
    } = step;

    return {
      children,
      clipMediaType,
      nextDisabled,
      onClickNext,
      onClickPrev,
      showNextBtn,
      showPrevBtn,
      nextLabel,
      prevLabel,
      title,
      footer: Boolean(step.notes) ? (
        <>
          <Button
            key="openNotesBtn"
            flex={1}
            marginX={1}
            icon="InfoOutline"
            onClick={() => setDisplayNotes(true)}
            colorScheme="neutralDark"
            mb={isEditable ? 3 : 0}
          >
            Notes
          </Button>
          {footer}
        </>
      ) : (
        footer
      ),
    };
  });
};
