import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import moment from 'moment';

import navRoutes from 'navigation/Routes';

import API from 'constants/api';
import { PLATFORM } from 'constants/env';
import {
  COUNTRY_OPTIONS,
  CURRENCY_SYMBOLS,
  CURRENCY_POSITIONS,
} from 'constants/settings';
import { WORKSHOP_ORGS } from 'constants/organisation';

import { cohortActions, courseActions } from 'redux/actions/learner';
import { useUser } from 'redux/selectors';
import { hooks, colorUtils, getFlagEmoji, courseUtils } from 'utils';
import { getCookie } from 'utils/index';

import { GlobalState } from 'types';

import { ScreenWrapper } from 'screens/common/ScreenWrapper';

import { Flex, Card, Text, useColorModeValue } from '@workshop/ui';
import { UserAvatar } from 'components/UserAvatar';
import { Loading } from 'components/Loading';

// @ts-ignore
import visaImage from 'assets/images/payment/visa.png'; // @ts-ignore
import visaElectronImage from 'assets/images/payment/visa-electron.png'; // @ts-ignore
import mastercardImage from 'assets/images/payment/mastercard.png'; // @ts-ignore
import maestroImage from 'assets/images/payment/maestro.png'; // @ts-ignore
import discoverImage from 'assets/images/payment/discover.png'; // @ts-ignore
import amexImage from 'assets/images/payment/amex.png';

import CheckoutSteps from './CheckoutSteps';
import * as api from './CheckoutSteps/api';
import { TaxCalculation } from './CheckoutSteps/types';

// Routing Props
interface MatchParams {
  courseSlug: string;
}

// Props passed to our component from parents
interface OwnProps extends RouteComponentProps<MatchParams> {}

// Props passed to our component via redux
type PropsFromRedux = ConnectedProps<typeof connector>;

// Combined props we're passing to our component
interface Props extends OwnProps, PropsFromRedux {}

const CheckoutScreen: React.FC<Props> = ({
  course,
  courseSlug,
  cohorts,
  location,
}) => {
  const [taxLoading, setTaxLoading] = useState(false);
  const [taxCalculation, setTaxCalculation] = useState<TaxCalculation | null>(
    null
  );
  const [couponCode, setCouponCode] = useState('');

  const history = useHistory();
  const dispatch = useDispatch();

  const user = useUser();
  const isAuthenticated = !!user?.id;

  const geoLocation = useSelector(
    (state: GlobalState) => state.background.location
  );

  const { courseLoading } = hooks.useLoadingDataState(
    {
      courseLoading: {
        actions: isAuthenticated
          ? [
              () =>
                courseActions.retrieveSummary(
                  courseSlug,
                  location.pathname,
                  false
                ),
              () => cohortActions.list({ fetchNextPage: true }),
            ]
          : [
              () =>
                courseActions.retrieveSummary(
                  courseSlug,
                  location.pathname,
                  false
                ),
            ],
      },
    },
    [isAuthenticated]
  );

  // Get colors from team profile id or image
  const baseColor = course?.organisation?.id
    ? colorUtils.getRandomColor(
        course.organisation.id,
        undefined,
        undefined,
        'hex'
      )
    : '';
  const colors = hooks.useColors(course?.organisation?.brandColor || baseColor);

  const bg =
    useColorModeValue(
      colors?.light.primaryContainer,
      colors?.dark.primaryContainer
    ) || 'background.tint1';

  const textColor =
    useColorModeValue(
      colors?.light.onPrimaryContainer,
      colors?.dark.onPrimaryContainer
    ) || 'text.muted';

  const surface =
    useColorModeValue(colors?.light.surface, colors?.dark.surface) ||
    'background.tint3';

  const csrftoken = getCookie('csrftoken');

  if (
    PLATFORM !== 'steppit' ||
    (!course && !courseLoading) ||
    WORKSHOP_ORGS.includes(course?.organisation?.id)
  ) {
    history.push(navRoutes.common.home.path());
  }

  const hasPurchased = !!Object.values(cohorts).find(
    (c) => c.course === course?.slug
  );

  const allowedCurrencyCodes = Object.keys(CURRENCY_SYMBOLS);
  const locationCurrency = geoLocation?.currency || 'USD';

  let currencyCode = allowedCurrencyCodes.includes(locationCurrency)
    ? locationCurrency
    : 'USD';

  const priceTier = course?.priceTier
    ? course?.priceTier
    : course?.price
    ? courseUtils.getTierFromPrice(parseInt(course.price))
    : undefined;

  if (!priceTier && course?.price) {
    // If no price tier is available, then the course
    // price in GBP is being used by default
    currencyCode = 'GBP';
  }

  const currency: {
    code: string;
    position: 'before' | 'after';
    symbol: string;
  } = {
    code: currencyCode,
    // @ts-ignore
    symbol: CURRENCY_SYMBOLS[currencyCode],
    // @ts-ignore
    position: CURRENCY_POSITIONS[currencyCode],
  };

  const coursePrice = priceTier
    ? courseUtils.getPriceFromTier(priceTier, currency.code)
    : course?.price || '0';

  const coursePriceInt = parseInt(coursePrice);

  const storedValue = `${courseSlug}-tax${couponCode ? `-${couponCode}` : ''}`;

  const loadTaxCalculation = async () => {
    if (
      PLATFORM === 'steppit' &&
      course &&
      courseSlug &&
      currencyCode &&
      !courseLoading
    ) {
      const storedCalculation = localStorage.getItem(storedValue);
      if (storedCalculation) {
        const jsonCalculation = JSON.parse(storedCalculation);
        if (
          jsonCalculation?.currency?.toUpperCase() === currencyCode &&
          moment.unix(jsonCalculation?.expiresAt).isAfter(moment())
        ) {
          if ('id' in jsonCalculation) {
            setTaxCalculation(jsonCalculation);
          }
          return;
        } else {
          localStorage.removeItem(storedValue);
        }
      }
      setTaxLoading(true);
      const taxResult = await api.calculateTax(
        {
          slug: courseSlug,
          currency: currencyCode,
          ...(couponCode ? { coupon: couponCode } : {}),
          ...(geoLocation?.ip ? { ip: geoLocation.ip } : {}),
        },
        dispatch
      );
      // @ts-ignore
      if ('id' in taxResult) {
        // @ts-ignore
        setTaxCalculation(taxResult);
        localStorage.setItem(storedValue, JSON.stringify(taxResult));
      }
      setTaxLoading(false);
    }
  };

  useEffect(() => {
    if (courseSlug && currencyCode && !courseLoading) {
      loadTaxCalculation();
    }
  }, [courseSlug, currencyCode, courseLoading, couponCode]);

  return (
    <ScreenWrapper>
      <Flex
        position="absolute"
        top={0}
        right={0}
        bottom={0}
        left={0}
        // backgroundColor={surface}
        backgroundColor="background.tint3"
        zIndex={0}
        transition="background-color 1s"
      >
        {/* <Flex
          flex={1}
          backgroundColor={bg}
          transition="background-color 1s"
          opacity={0.14}
        /> */}
      </Flex>
      <Flex flexDirection="column" alignItems="center" zIndex={5} mt={-6}>
        {!course || courseLoading ? (
          <Flex mt={8}>
            <Loading />
          </Flex>
        ) : (
          <>
            <Flex mb={8}>
              <Link
                to={navRoutes.global.channel.path(course.organisation.handle)}
              >
                <Card
                  alignItems="center"
                  padding={1}
                  borderRadius="full"
                  backgroundColor={bg}
                  transition="background-color 2s"
                  boxShadow="none"
                  _hover={{ opacity: 0.8 }}
                >
                  <UserAvatar
                    name={course.organisation.name}
                    userId={course.organisation.id || 1}
                    avatarPicture={course.organisation.logoDark || ''}
                    size="2xs"
                  />
                  <Text
                    fontWeight="semibold"
                    ml={3}
                    mr={3}
                    color={textColor}
                    transition="color 2s"
                  >
                    {course.organisation.name}
                  </Text>
                </Card>
              </Link>
            </Flex>
            {/* @ts-ignore */}
            <CheckoutSteps
              // No support for payment plans or bundles
              productType="course"
              hasPurchased={hasPurchased}
              course={{
                slug: course.slug,
                title: course.title,
                image: course.imageLandscapeThumbnail,
                category: `${course.category}`,
                price: coursePriceInt,
                // paymentPlanPrice: undefined,
                // paymentPlanTerm: undefined,
                // paymentPlanAvailable: false,
                shareUrl: course.shareUrl,
                classType: course.classType,
                courseType: course.courseType,
                currency,
                taxBreakdown: taxCalculation?.taxBreakdown,
                taxAmountInclusive: taxCalculation?.taxAmountInclusive,
                totalAmount: taxCalculation?.taxAmountExclusive
                  ? coursePriceInt + taxCalculation.taxAmountExclusive / 100
                  : coursePriceInt,
                taxCalculationId: taxCalculation?.id,
              }}
              courseProvider={{
                id: course.organisation.id,
                name: course.organisation.name,
                logoUrl: course.organisation.logoDark || '',
                contactNumber: course.organisation.contactNumber || '',
                contactEmail: course.organisation.contactEmail || '',
                websiteUrl: course.organisation.websiteUrl || '',
                conversionTrackingUrl: '',
                stripeAccountId: course.organisation.stripeAccountId || '',
                stripeAccountStandard:
                  course.organisation.stripeAccountStandard || false,
              }}
              taxLoading={taxLoading}
              countries={Object.keys(COUNTRY_OPTIONS).reduce(
                (acc, key) => ({
                  ...acc,
                  [key]:
                    key === '-'
                      ? '-'
                      : `${getFlagEmoji(key.slice(0, 2))} ${
                          COUNTRY_OPTIONS[key]
                        }`,
                }),
                {}
              )}
              base="courses"
              user={{
                ...user,
                token: '',
                country: '',
              }}
              paymentTypeImages={{
                amex: amexImage,
                discover: discoverImage,
                maestro: maestroImage,
                mastercard: mastercardImage,
                visaElectron: visaElectronImage,
                visa: visaImage,
              }}
              endpoints={{
                accountResetPassword: API.auth.resetPassword(),
              }}
              csrftoken={csrftoken}
              authenticatedOnLoad={isAuthenticated}
              inviteImage=""
              secureImage=""
              onApplyCoupon={(code: string) => setCouponCode(code)}
            />
          </>
        )}
      </Flex>
    </ScreenWrapper>
  );
};

const mapStateToProps = (state: GlobalState, props: OwnProps) => {
  const { courseSlug } = props.match.params;

  const {
    courses: { courses: courseState, cohorts },
  } = state.learner;

  // Course Data
  const course = courseState.detail[courseSlug];
  return {
    course,
    courseSlug,
    cohorts,
  };
};

const connector = connect(mapStateToProps);

export default connector(withRouter(CheckoutScreen));
