import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { useSpring } from 'react-spring';
import equal from 'fast-deep-equal/react';
import { FaGem } from 'react-icons/fa';

import navRoutes from 'navigation/Routes';

import { discourseUrl, PLATFORM } from 'constants/env';
import { PLATFORM_DISPLAY_NAME } from 'constants/common';
import { PRO_ORGS } from 'constants/organisation';

import {
  Box,
  Card,
  Divider,
  Flex,
  Heading,
  MdIcon,
  Text,
  Button,
  Stack,
  Tooltip,
  Spinner,
  chakra,
  useTheme,
} from '@workshop/ui';

import { organisationActions } from 'redux/actions/common';
import { courseActions, enrolmentActions } from 'redux/actions/cms';
import { userLibraryActions } from 'redux/actions/learner';
import { useDismissedInformationCards } from 'redux/selectors/user';

import { hooks } from 'utils';
import { useWindowDimensions } from 'utils/hooks/useDimensions';

import { UserAvatar } from 'components/UserAvatar';
import { ProGem, Pro } from 'components/Brand';
import { ProCta } from 'components/Common';

import {
  usePermissions,
  useUser,
  useDiscourseUser,
  useTeams,
  useRoles,
  useTeamProfiles,
  useCurrentTeam,
  useCurrentTeamProfile,
  useUserLibraryCourses,
} from 'redux/selectors';
import { getManagedCohorts } from 'redux/selectors/course';

import {
  HOME_SCREEN_ROUTES,
  HomeScreenRoute,
  HomeScreenRoutes,
} from 'navigation/Routes';
import { AnimatedFlex, ButtonGrid } from 'components/Common';
import { Loading } from 'components/Loading';
import UnderConstruction from 'components/UnderConstruction';

import { GlobalState } from 'types';
import { CohortStatus } from 'types/common';

import homeCardItemVariants from './variants';

interface HomeCardProps {
  onNewOrgClick: () => void;
}

const getWelcomeMessage = (hours: number, name: string) => {
  if (hours >= 6 && hours < 9) return `Morning, ${name}! ⏰`;
  if (hours >= 9 && hours < 12) return `Good morning, ${name} ☀️`;
  if (hours >= 12 && hours < 15) return `Hey ${name} 👋`;
  if (hours >= 15 && hours < 18) return `Afternoon, ${name} ☕`;
  if (hours >= 18 && hours < 21) return `Evening, ${name} 🌙`;
  if (hours >= 21 && hours < 24) return `Good evening, ${name} 🦉`;

  return `Hi ${name} 😊`;
};

const HomeCardAlt: React.FC<HomeCardProps> = ({ onNewOrgClick }) => {
  const hour = moment().hours();

  const dispatch = useDispatch();
  const userPermissions = usePermissions();
  const user = useUser();
  const discourseUser = useDiscourseUser();
  const userTeams = useTeams();
  const roles = useRoles();
  const teamProfiles = useTeamProfiles();
  const currentTeam = useCurrentTeam();
  const currentTeamProfile = useCurrentTeamProfile();
  const currentTeamOrg = currentTeamProfile?.id;
  const history = useHistory();
  const dismissedInformationCards = useDismissedInformationCards();

  const theme = useTheme();
  const windowDimensions = useWindowDimensions();
  const isMobile = windowDimensions.width < parseInt(theme.breakpoints.md, 10);

  const courses = useSelector(
    (state: GlobalState) => state.cms.course.courseList
  );
  const licenses = useSelector(
    (state: GlobalState) => state.cms.enrolment.license
  );
  const myCohorts = useSelector((state: GlobalState) =>
    getManagedCohorts(state)
  );

  const libraryCourses = useUserLibraryCourses();

  const { myCohorts: myCohortsLoading, courses: coursesLoading } =
    hooks.useLoadingDataState(
      {
        myCohorts: {
          actions: currentTeam
            ? [() => courseActions.fetchMyCohorts({ fetchNextPage: true })]
            : [],
        },
        courses: {
          actions: currentTeam
            ? [
                () => courseActions.list({ fetchNextPage: true }),
                () => enrolmentActions.listLicenses(),
              ]
            : [],
        },
      },
      [currentTeam]
    );

  const { library: libraryLoading } = hooks.useLoadingDataState({
    library: {
      actions: [userLibraryActions.retrieve],
    },
  });

  /**
   * Only keep routes from the HOME_SCREEN_ROUTES
   * for which the user has permission to view/edit
   * */
  const userRoutes = Object.keys(HOME_SCREEN_ROUTES)
    .map((key) => key as HomeScreenRoute)
    .reduce<HomeScreenRoutes>(
      (acc, key) => {
        const routes = HOME_SCREEN_ROUTES[key];

        const filteredRoutes = routes.filter(({ permissions }) => {
          if (!permissions) {
            // No permissions specificed on this route - everyone can view
            return true;
          }

          if (permissions.canView?.find((p) => userPermissions.includes(p))) {
            // User has viewing permissions
            return true;
          }

          if (permissions.canEdit?.find((p) => userPermissions.includes(p))) {
            // User has edit permissions
            return true;
          }

          return false;
        });

        return { ...acc, [key]: filteredRoutes };
      },
      { learner: [], teacher: [] }
    );

  const hasCurrentTeam = typeof currentTeam === 'number' && currentTeam > 0;
  const routes = hasCurrentTeam ? userRoutes.teacher : userRoutes.learner;

  const currentTeamName = currentTeam ? teamProfiles[currentTeam]?.name : '';

  const currentTeamIsPro = Boolean(
    currentTeamProfile?.isPro || (currentTeam && PRO_ORGS.includes(currentTeam))
  );

  const isProWithTeamMembers =
    currentTeamIsPro &&
    currentTeamProfile?.proPlan?.details?.teamMembers &&
    currentTeamProfile.proPlan.details.teamMembers > 1;

  const hasTeams = userTeams.length > 0;

  useEffect(() => {
    if (!libraryLoading && !libraryCourses && !hasCurrentTeam && hasTeams) {
      dispatch(organisationActions.setCurrentTeam(userTeams[0].team));
      localStorage.setItem('defaultTeam', userTeams[0].team.toString());
    }
  }, [hasCurrentTeam, libraryLoading, libraryCourses, hasTeams]);

  routes?.sort((a, b) =>
    a.sidebarLink?.index && b.sidebarLink?.index
      ? a.sidebarLink?.index - b.sidebarLink?.index
      : 0
  );

  // Animation for the Content of the Dropdown
  const animatedContentStyle = useSpring({
    // delay: 150,
    config: { duration: 200 },
    opacity: Boolean(routes) ? 1 : 0,
  });

  const routeOptions = (
    // @ts-ignore
    <AnimatedFlex style={animatedContentStyle} w="100%">
      {routes && (
        <Flex flexDir="column" w="100%">
          {routes.map((r, idx) => (
            <Link
              key={`route-${r.name}`}
              to={equal(r.component, UnderConstruction) ? '#' : r.path()}
            >
              {idx !== 0 && <Divider m={0} />}
              <Flex
                alignItems="center"
                py={4}
                px={6}
                opacity={r.component === UnderConstruction ? 0.5 : 1}
                _hover={{ backgroundColor: 'background.tint1' }}
              >
                <Flex flexDir="column" flex={1}>
                  <Flex alignItems="center">
                    {r.icon && (
                      <MdIcon name={r.icon} color="common.primary" mr={2} />
                    )}
                    <Text color="common.primary" fontWeight="semibold" flex={1}>
                      {r.name}
                    </Text>
                    {r.component === UnderConstruction ? (
                      <Text fontSize="sm" color="text.muted">
                        Coming Soon
                      </Text>
                    ) : null}
                  </Flex>

                  <Text mt={1}>{r.description}</Text>
                </Flex>
                {PLATFORM === 'steppit' &&
                  currentTeamIsPro &&
                  r.proInfoCard &&
                  // Not all Pro plans have > 1 team members
                  ((r.proInfoCard === 'pro_team' && isProWithTeamMembers) ||
                    r.proInfoCard !== 'pro_team') &&
                  !dismissedInformationCards?.includes(r.proInfoCard) && (
                    <Flex
                      fontSize="sm"
                      alignItems="center"
                      color="orange.500"
                      _dark={{ color: 'orange.300' }}
                      ml={2}
                    >
                      <FaGem />
                      <Text
                        display={{ base: 'none', sm: 'inline' }}
                        ml={1.5}
                        fontWeight="semibold"
                      >
                        New
                      </Text>
                    </Flex>
                  )}
              </Flex>
            </Link>
          ))}
        </Flex>
      )}
    </AnimatedFlex>
  );

  const homescreenVariants = userTeams.map((userTeam) => {
    const HomescreenVariant = userTeam && homeCardItemVariants[userTeam.team];
    if (HomescreenVariant) {
      return (
        <HomescreenVariant
          key={`home-variant-${userTeam.team}`}
          variantId={userTeam.team}
          isCurrentTeam={currentTeam === userTeam.team}
          borderTop="none"
        />
      );
    }
    return null;
  });

  const nextStepsLoading = myCohortsLoading || coursesLoading;

  const draftCourses = Object.values(courses).filter(
    (c) =>
      c.courseType !== 'session' &&
      c.status === 'draft' &&
      c.organisation === currentTeamOrg
  );
  const draftSessions = Object.values(courses).filter(
    (c) =>
      c.courseType === 'session' &&
      c.status === 'draft' &&
      c.organisation === currentTeamOrg
  );
  const publishedPrivateCourses = Object.values(courses).filter(
    (c) =>
      c.courseType !== 'session' &&
      c.status === 'published' &&
      !c.isPublic &&
      c.organisation === currentTeamOrg
  );
  const publishedPublicCourses = Object.values(courses).filter(
    (c) =>
      c.courseType !== 'session' &&
      c.status === 'published' &&
      c.isPublic &&
      c.organisation === currentTeamOrg
  );
  const publishedSessions = Object.values(courses).filter(
    (c) =>
      c.courseType === 'session' &&
      c.status === 'published' &&
      c.organisation === currentTeamOrg
  );
  const publishedPublicSessions = publishedSessions.filter((c) => c.isPublic);
  const isMentorOnActiveClass =
    Object.values(myCohorts).filter(
      (mc) =>
        !moment(mc.endDate).isBefore(moment()) &&
        !moment(mc.startDate).isAfter(moment()) &&
        (mc.numParticipants > 0 || mc.numEnrolments > 0) &&
        !mc.isAnonymous &&
        mc.organisation === currentTeamOrg
    ).length > 0;
  const isMentorOnUpcomingPrivateClass =
    Object.values(myCohorts).filter(
      (mc) =>
        !moment(mc.endDate).isBefore(moment()) &&
        moment(mc.startDate).isAfter(moment()) &&
        (mc.numParticipants > 0 || mc.numEnrolments > 0) &&
        mc.organisation === currentTeamOrg &&
        mc.isPrivate &&
        !mc.onSale
    ).length > 0;
  const liveRoomsAvailable = !!Object.values(myCohorts).find(
    (mc) =>
      mc.isAnonymous &&
      mc.numEnrolments > 0 &&
      mc.status === CohortStatus.live &&
      moment(mc.endDate).isSameOrAfter()
  );
  const validLicenses = Object.values(licenses).filter(
    (l) =>
      l.licensee.id === currentTeamOrg &&
      l.courses.length > 0 &&
      (!l.expiryDate ||
        (l.expiryDate && moment(l.expiryDate).isAfter(moment()))) &&
      l.totalEnrolments > l.enrolments.length
  );
  const latestPlan = localStorage.getItem('latestPlan');

  // TODO: "Next step" links to add in future:
  // (All PLUS features?)
  // ---- See Sales Performance (if courses on sale)
  // ---- View Student Insights (if usage over X)
  // ---- Start Selling Class Spaces (if private course published and not on sale)

  const nextSteps: {
    slug: string;
    name: string;
    description: string;
    icon: string;
    onClick: () => void;
    priority: number;
    showTour?: boolean;
    tourText?: string;
  }[] =
    PLATFORM === 'steppit'
      ? [
          {
            slug: 'newSession',
            name: 'Make a Quick Session',
            description: `Create a standalone session to share online.`,
            icon: 'Pending',
            onClick: () =>
              history.push({
                pathname: navRoutes.cms.catalogue.path(),
                search: 'p=session',
              }),
            priority: 3,
          },
        ]
      : [];

  if (latestPlan && PLATFORM === 'steppit') {
    nextSteps.push({
      slug: 'latestPlan',
      name: 'Return to My Plan',
      description: 'Continue working on your last course plan.',
      icon: 'Checklist',
      onClick: () => history.push(navRoutes.global.coursePlan.path(latestPlan)),
      priority: 2,
      showTour: Boolean(
        !nextStepsLoading && Object.values(courses).length === 0
      ),
      tourText: 'Head back to your plan to start building your first course',
    });
  }

  if (liveRoomsAvailable) {
    nextSteps.push({
      slug: 'launchRoom',
      name: 'Launch a New Room',
      description: 'Start a live session in a new room.',
      icon: 'TapAndPlay',
      onClick: () => history.push(navRoutes.cms.createRoom.path()),
      priority: 0,
    });
  }

  if (isMentorOnActiveClass) {
    nextSteps.push({
      slug: 'manageStudents',
      name: 'Manage My Learners',
      description: 'See how my learners are getting on.',
      icon: 'Groups',
      onClick: () => history.push(navRoutes.cms.students.path()),
      priority: 0,
    });
  } else if (isMentorOnUpcomingPrivateClass) {
    nextSteps.push({
      slug: 'enrolStudents',
      name: PLATFORM === 'workshop' ? 'Enroll Students' : 'Enroll Learners',
      description: 'Add learners to my upcoming class.',
      icon: 'PersonAdd',
      onClick: () => history.push(navRoutes.cms.classes.path()),
      priority: 0,
    });
  }

  if (draftCourses.length > 0 || draftSessions.length > 0) {
    nextSteps.push({
      slug: 'continueContent',
      name:
        draftCourses.length > 0 && draftSessions.length > 0
          ? 'Work on My Content'
          : draftCourses.length > 1
          ? 'Work on My Courses'
          : draftCourses.length > 0
          ? 'Continue My Course'
          : draftSessions.length > 1
          ? 'Work on My Sessions'
          : draftSessions.length > 0
          ? 'Continue My Session'
          : 'Work on My Content',
      description:
        draftCourses.length > 0 && draftSessions.length > 0
          ? 'Get back to creating my content.'
          : draftCourses.length > 1
          ? 'Get back to building my courses.'
          : draftCourses.length > 0
          ? 'Jump back into building my course.'
          : draftSessions.length > 1
          ? 'Finish my sessions and get them published.'
          : draftSessions.length > 0
          ? 'Work on my session and get it published.'
          : 'Get back to creating my content.',
      icon: 'DashboardCustomize',
      onClick: () =>
        history.push(
          draftCourses.length > 0 && draftSessions.length > 0
            ? navRoutes.cms.catalogue.path()
            : draftCourses.length > 1
            ? navRoutes.cms.catalogue.path()
            : draftCourses.length > 0
            ? navRoutes.cms.editCourse.path(draftCourses[0].id.toString())
            : draftSessions.length > 1
            ? navRoutes.cms.catalogue.path()
            : draftSessions.length > 0
            ? navRoutes.cms.standaloneSession.path(
                draftSessions[0].standaloneModule?.toString()
              )
            : navRoutes.cms.catalogue.path()
        ),
      priority: 1,
    });
  } else if (
    publishedPublicSessions.length > 0 ||
    publishedPublicCourses.length > 0
  ) {
    nextSteps.push({
      slug: 'shareContent',
      name:
        publishedPublicCourses.length > 0 && publishedPublicSessions.length > 0
          ? 'Share My Content'
          : publishedPublicCourses.length > 1
          ? 'Share My Courses'
          : publishedPublicCourses.length > 0
          ? 'Share My Course'
          : publishedPublicSessions.length > 1
          ? 'Share My Sessions'
          : publishedPublicSessions.length > 0
          ? 'Share My Session'
          : 'Share My Content',
      description:
        publishedPublicCourses.length > 0 && publishedPublicSessions.length > 0
          ? 'Start sharing links to my content.'
          : publishedPublicCourses.length > 1
          ? 'Start sharing links to my courses.'
          : publishedPublicCourses.length > 0
          ? 'Grab a link to share my course.'
          : publishedPublicSessions.length > 1
          ? 'Start sharing links to my sessions.'
          : publishedPublicSessions.length > 0
          ? 'Grab a link to share my session.'
          : 'Start sharing links to my content.',
      icon: 'Share',
      onClick: () =>
        history.push(
          publishedPublicCourses.length > 0 &&
            publishedPublicSessions.length > 0
            ? navRoutes.cms.catalogue.path()
            : publishedPublicCourses.length > 1
            ? navRoutes.cms.catalogue.path()
            : publishedPublicCourses.length > 0
            ? {
                pathname: navRoutes.cms.editCourse.path(
                  publishedPublicCourses[0].id.toString()
                ),
                search: 'p=share',
              }
            : publishedPublicSessions.length > 1
            ? navRoutes.cms.catalogue.path()
            : publishedPublicSessions.length > 0
            ? {
                pathname: navRoutes.cms.standaloneSession.path(
                  publishedPublicSessions[0].standaloneModule?.toString()
                ),
                search: 'p=share',
              }
            : navRoutes.cms.catalogue.path()
        ),
      priority: 3,
    });
  }

  if (
    (PLATFORM === 'steppit' && publishedPrivateCourses.length > 0) ||
    validLicenses.length > 0
  ) {
    nextSteps.push({
      slug: 'newClass',
      name: 'Start a New Class',
      description: 'Run my course with a new group of learners.',
      icon: 'Workspaces',
      onClick: () =>
        history.push({
          pathname: navRoutes.cms.classes.path(),
          search: 'p=new',
        }),
      priority: 2,
    });
  }

  if (draftCourses.length === 0 && PLATFORM === 'steppit') {
    nextSteps.push({
      slug: 'newCourse',
      name: 'Plan a New Course',
      description:
        'Start creating a new course to share online or run in private classes.',
      icon: 'BubbleChart',
      onClick: () =>
        history.push({
          pathname: navRoutes.cms.catalogue.path(),
          search: 'p=course',
        }),
      priority: 4,
    });
  }

  return (
    <>
      <Box mx="auto" width="100%" maxWidth="850px" my={6}>
        <Flex
          flexDirection={{ base: 'column', md: 'row' }}
          alignItems={{ base: 'normal', md: 'flex-start' }}
        >
          <Box
            maxWidth="100vw"
            overflow="scroll"
            sx={{
              '-ms-overflow-style': 'none',
              'scrollbar-width': 'none',
              '::-webkit-scrollbar': { display: 'none' },
            }}
          >
            {userTeams.length > 0 && (
              <Stack
                direction={{ base: 'row', md: 'column' }}
                mr={{ base: 0, md: 6 }}
                mb={{ base: 6, md: 0 }}
                px={{ base: 3, md: 0 }}
                gap={2}
                whiteSpace="nowrap"
              >
                {!libraryLoading && libraryCourses?.length > 0 ? (
                  <>
                    <Tooltip
                      display={{ base: 'none', md: 'block' }}
                      label={user?.name}
                      placement="right"
                    >
                      <Flex
                        flexDir="column"
                        padding={0.5}
                        borderRadius="full"
                        borderWidth={2}
                        borderColor={
                          !hasCurrentTeam
                            ? 'common.primary'
                            : { base: 'border.muted', md: 'transparent' }
                        }
                        {...(hasCurrentTeam
                          ? {
                              _hover: {
                                borderColor: 'common.primaryTransparent',
                              },
                              cursor: 'pointer',
                              onClick: () =>
                                dispatch(
                                  organisationActions.setCurrentTeam(null)
                                ),
                            }
                          : {})}
                      >
                        <Flex alignItems="center">
                          <UserAvatar
                            name={user?.name}
                            userId={user?.id || 1}
                            avatarPicture={
                              discourseUser
                                ? `${discourseUrl}${discourseUser?.avatarTemplate.replace(
                                    '{size}',
                                    '240'
                                  )}`
                                : ''
                            }
                            size={isMobile ? '2xs' : 'xs'}
                          />
                          <Box
                            display={{ base: 'block', md: 'none' }}
                            px={3}
                            flex={1}
                          >
                            <Text fontWeight="semibold" fontSize="sm">
                              {user?.name}
                            </Text>
                          </Box>
                        </Flex>
                      </Flex>
                    </Tooltip>

                    <Flex
                      borderBottomWidth={{ base: 0, md: 1 }}
                      borderRightWidth={{ base: 1, md: 0 }}
                      borderColor="border.default"
                    />
                  </>
                ) : libraryLoading ? (
                  <>
                    <Flex
                      flexDir="column"
                      padding={0.5}
                      borderRadius="full"
                      borderWidth={2}
                      borderColor={{ base: 'border.muted', md: 'transparent' }}
                    >
                      <Flex
                        alignItems="center"
                        justifyContent="center"
                        boxSize={isMobile ? 'image.2xs' : 'image.xs'}
                      >
                        <Spinner size="sm" color="text.muted" thickness="2px" />
                      </Flex>
                    </Flex>
                    <Flex
                      borderBottomWidth={{ base: 0, md: 1 }}
                      borderRightWidth={{ base: 1, md: 0 }}
                      borderColor="border.default"
                    />
                  </>
                ) : null}

                {userTeams.map((userTeam) => {
                  const teamProfile = teamProfiles[userTeam.team];
                  // Determine whether the team we're iterating over is the user's currently
                  // active team
                  const isCurrentTeam = userTeam.team === currentTeam;
                  const isPro = teamProfile?.isPro;

                  return (
                    <Tooltip
                      display={{ base: 'none', md: 'block' }}
                      label={teamProfile?.name}
                      placement="right"
                      key={`team-${userTeam.id}`}
                    >
                      <Flex
                        flexDir="column"
                        mt={2}
                        position="relative"
                        padding={0.5}
                        borderRadius="full"
                        borderWidth={2}
                        borderColor={
                          isCurrentTeam
                            ? 'common.primary'
                            : { base: 'border.muted', md: 'transparent' }
                        }
                        {...(!isCurrentTeam
                          ? {
                              _hover: {
                                borderColor: 'common.primaryTransparent',
                              },
                              cursor: 'pointer',
                              onClick: () => {
                                dispatch(
                                  organisationActions.setCurrentTeam(
                                    userTeam.team
                                  )
                                );
                                localStorage.setItem(
                                  'defaultTeam',
                                  userTeam.team.toString()
                                );
                              },
                            }
                          : {})}
                      >
                        <Flex alignItems="center">
                          <Box position="relative">
                            <UserAvatar
                              name={teamProfile?.name}
                              userId={teamProfile?.id || 1}
                              avatarPicture={teamProfile?.logoDark || ''}
                              size={isMobile ? '2xs' : 'xs'}
                            />
                            {isPro && PLATFORM === 'steppit' && (
                              <Box
                                position="absolute"
                                bottom={-0.5}
                                right={-0.5}
                              >
                                <ProGem
                                  tier={teamProfile?.proPlan?.tier || 0}
                                />
                              </Box>
                            )}
                          </Box>
                          <Box
                            display={{ base: 'block', md: 'none' }}
                            px={3}
                            flex={1}
                          >
                            <Text fontWeight="semibold" fontSize="sm">
                              {teamProfile?.name}
                            </Text>
                          </Box>
                        </Flex>
                      </Flex>
                    </Tooltip>
                  );
                })}
                {PLATFORM === 'steppit' && (
                  <Tooltip
                    display={{ base: 'none', md: 'block' }}
                    label="New Channel"
                    placement="right"
                  >
                    <Flex
                      flexDir="column"
                      mt={2}
                      position="relative"
                      padding={0.5}
                      borderRadius="full"
                      borderWidth={2}
                      borderColor={{ base: 'border.muted', md: 'transparent' }}
                      _hover={{
                        borderColor: 'common.primaryTransparent',
                      }}
                      cursor="pointer"
                      onClick={onNewOrgClick}
                    >
                      <Flex alignItems="center">
                        <Flex
                          boxSize={isMobile ? 'image.2xs' : 'image.xs'}
                          borderRadius="full"
                          backgroundColor="background.primary"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <MdIcon name="AddBusiness" color="icon.primary" />
                        </Flex>
                        <Box
                          display={{ base: 'block', md: 'none' }}
                          px={3}
                          flex={1}
                        >
                          <Text fontWeight="semibold" fontSize="sm">
                            New Channel
                          </Text>
                        </Box>
                      </Flex>
                    </Flex>
                  </Tooltip>
                )}
              </Stack>
            )}
          </Box>

          <Card flexDir="column" flex={1} minHeight="230px" padding={0}>
            <Box p={6} pb={4}>
              <Flex
                width="100%"
                flexDir="column"
                position="relative"
                backgroundColor="background.primary"
                color="text.primary"
                borderRadius="md"
                px={6}
                py={10}
                textAlign="center"
              >
                <Heading as="h1" fontSize="2xl" fontWeight="bold" mb={1}>
                  {user ? getWelcomeMessage(hour, user.name.split(' ')[0]) : ''}
                </Heading>
                <Text>Let's take another step today...</Text>
              </Flex>
            </Box>
            {userTeams.length > 0 && (
              <Flex
                borderBottomWidth={1}
                borderColor="border.muted"
                px={6}
                pb={4}
                alignItems="center"
                flexDirection={{ base: 'column', sm: 'row' }}
              >
                <Text color="text.muted">
                  {hasCurrentTeam ? 'Teaching with ' : 'Learning as '}

                  <chakra.span fontWeight="semibold" color="text.default">
                    {hasCurrentTeam ? currentTeamName : user?.name}
                  </chakra.span>
                </Text>
                {hasCurrentTeam && (
                  <>
                    <Flex
                      flex={1}
                      mr={{ base: 0, sm: 3 }}
                      mb={{ base: 2, sm: 0 }}
                    />
                    {currentTeamProfile?.isPro ? (
                      <Pro
                        tier={currentTeamProfile?.proPlan?.tier || 0}
                        size="xs"
                        onClick={() =>
                          history.push({
                            pathname: navRoutes.cms.myOrganisation.path(),
                            search: 'tab=subscriptions',
                          })
                        }
                      />
                    ) : (
                      <ProCta
                        hideProIcon
                        label=""
                        ctaProps={{
                          variant: 'outline',
                          size: 'xs',
                        }}
                        ctaText={
                          currentTeamProfile?.proTrialRedeemed === false
                            ? 'Try Pro for Free'
                            : 'Upgrade to Pro'
                        }
                      />
                    )}
                  </>
                )}
              </Flex>
            )}
            {homescreenVariants}
            {hasCurrentTeam && nextStepsLoading ? (
              <Flex
                padding={6}
                borderBottomWidth={1}
                borderColor="border.muted"
              >
                <Loading />
              </Flex>
            ) : (
              hasCurrentTeam &&
              nextSteps.length > 0 && (
                <>
                  <Text px={6} py={4} color="text.muted">
                    Next step:
                  </Text>
                  <ButtonGrid
                    px={6}
                    pb={6}
                    borderBottomWidth={1}
                    borderColor="border.muted"
                    items={nextSteps.sort((a, b) => a.priority - b.priority)}
                  />
                </>
              )
            )}
            {routeOptions}
          </Card>
        </Flex>
      </Box>
      {userTeams.length === 0 && PLATFORM === 'steppit' && (
        <Flex
          mt={4}
          py={4}
          alignItems="center"
          maxWidth="850px"
          mx="auto"
          paddingX={{ base: 4, md: 0 }}
          flexDirection={{ base: 'column', sm: 'row' }}
          borderTopWidth="1px"
          borderBottomWidth="1px"
          borderColor="border.default"
          width="100%"
        >
          <Text flex={1} mb={{ base: 2, sm: 0 }}>
            {`Interested in teaching on ${PLATFORM_DISPLAY_NAME}?`}
          </Text>
          <Button variant="outline" onClick={onNewOrgClick}>
            Create a Channel
          </Button>
        </Flex>
      )}
    </>
  );
};

export default HomeCardAlt;
