import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';

import { GlobalState } from 'types';

import { useCurrentTeamProfile, useCurrentTeam } from 'redux/selectors';

import navRoutes from 'navigation/Routes';

import { PLATFORM } from 'constants/env';
import { PRO_ORGS } from 'constants/organisation';
import {
  CURRENCY_SYMBOLS,
  PRICING_MATRIX,
  CURRENCY_POSITIONS,
} from 'constants/settings';

import { Box } from '@workshop/ui';

import {
  LabelInput,
  ButtonGrid,
  ProCta,
  AssistantModal,
} from 'components/Common';
import { EditModal } from 'components/Common/EditModal';
import { Loading } from 'components/Loading';

interface CreateCourseFormData {
  title: string;
  subtitle: string;
  summaryText: string;
}

interface Props {
  title: string;
  isOpen: boolean;
  isUpdating?: boolean;
  onClose: () => void;
  onSave: (formData: CreateCourseFormData) => void;
  onCancel?: () => void;
  error?: boolean;
}

const COURSE_PLACEHOLDERS = [
  'Learn Deep Tissue Massage',
  'Beginner to Advanced Guitar',
  'A Complete Guide to Music Theory',
  'Mastering Music Production',
  'Instagram Marketing for Beginners',
  'Practical Guide to Street Photography',
  'Japanese Calligraphy for Beginners',
  'Build Your Own Surfboard',
  'Car Repairs on the Road: A Practical Guide',
  'Restore Your Bathroom on a Budget',
  'How to Build Your Own Shed',
  'Drawing in Pencil 101',
  'Painting Autumn Landscapes in Acrylic',
  'Become a Makeup Artist: Beginner to Master',
  'Crochet a Winter Blanket',
  'Master Mixology: The Ultimate Guide to Cocktails',
];

const noop = () => null;
const CreateCourseModal = ({
  title = 'Create Course',
  isOpen = false,
  isUpdating = false,
  onClose,
  onSave,
  onCancel = noop,
  error = false,
}: Props) => {
  const [view, setView] = useState(
    PLATFORM === 'workshop' ? 'create' : 'select'
  );
  const [currentPlaceholderIdx, setCurrentPlaceholderIdx] = useState(0);
  const [placeholders, setPlaceholders] = useState(
    COURSE_PLACEHOLDERS.map((p) => '')
  );
  const [triggerOpenProPopup, setTriggerOpenProPopup] = useState<
    boolean | undefined
  >(undefined);
  const [assistantOpen, setAssistantOpen] = useState(false);
  const {
    register,
    handleSubmit,
    errors,
    clearError,
    formState: { dirty },
    reset,
  } = useForm<CreateCourseFormData>();
  const history = useHistory();

  const currentTeamProfile = useCurrentTeamProfile();
  const currentTeam = useCurrentTeam();
  const isPro = Boolean(
    currentTeamProfile?.isPro || (currentTeam && PRO_ORGS.includes(currentTeam))
  );

  const geoLocation = useSelector(
    (state: GlobalState) => state.background.location
  );
  const allowedCurrencyCodes = Object.keys(CURRENCY_SYMBOLS);
  const locationCurrency = (geoLocation?.currency as string) || 'USD';
  const currency = allowedCurrencyCodes.includes(locationCurrency)
    ? locationCurrency
    : 'USD';
  // Selected price tier options for AI plans
  const pricingOptions = [2, 4, 8, 12, 15, 17, 21, 25, 29, 33, 37, 44]
    .map(
      (t) =>
        `${
          // @ts-ignore
          CURRENCY_POSITIONS[currency] === 'before'
            ? // @ts-ignore
              CURRENCY_SYMBOLS[currency]
            : ''
          // @ts-ignore
        }${PRICING_MATRIX[t][currency]}${
          // @ts-ignore
          CURRENCY_POSITIONS[currency] === 'after'
            ? // @ts-ignore
              ` ${CURRENCY_SYMBOLS[currency]}`
            : ''
        }`,
      {} as { [key: string]: string }
    )
    .toString();

  useEffect(() => {
    setPlaceholders(
      COURSE_PLACEHOLDERS.map((value) => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value)
    );
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const nextPlaceholderIdx =
        (currentPlaceholderIdx + 1) % COURSE_PLACEHOLDERS.length;
      setCurrentPlaceholderIdx(nextPlaceholderIdx);
    }, 3000);
    return () => clearTimeout(timeout);
  }, [currentPlaceholderIdx]);

  const onSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    // react-hook-form calls `preventDefault` on the event internally,
    // but we have to call `stopPropagation` to prevent the modal from
    // closing.
    if (e && e.stopPropagation) {
      // prevent any outer forms from receiving the event too
      e.stopPropagation();
    }

    handleSubmit((formData) => {
      onSave(formData);
    })(e);
  };

  let gridItems = [];

  const scratchItem = {
    slug: 'create',
    name: 'Start from Scratch',
    description: `Already have a plan in mind? Start building your course from a blank slate.`,
    icon: 'Create',
    onClick: () => {
      setTriggerOpenProPopup(undefined);
      setView('create');
    },
  };

  const basicPlanItem = {
    slug: 'plan',
    name: 'Generate a Basic Plan',
    description: `Let your AI-powered assistant draft a plan before building out your course.`,
    icon: 'Assistant',
    onClick: () => history.push(navRoutes.global.planner.path()),
  };

  const proPlanItem = {
    slug: 'proPlan',
    name: 'Generate a Pro Plan',
    description: isPro
      ? `Work with your assistant to draft a plan before building out your course.`
      : 'Upgrade your channel to generate a plan uniquely tailored to your tastes.',
    icon: 'AutoAwesome',
    onClick: () => {
      if (isPro) {
        setAssistantOpen(true);
      } else {
        setTriggerOpenProPopup(!triggerOpenProPopup);
      }
    },
    footerElement: isPro ? undefined : (
      <Box mt={4}>
        <ProCta hideCta triggerOpenPopup={triggerOpenProPopup} />
      </Box>
    ),
  };

  if (isPro && PLATFORM === 'steppit') {
    gridItems = [proPlanItem, scratchItem];
  } else if (PLATFORM === 'steppit') {
    gridItems = [scratchItem, basicPlanItem, proPlanItem];
  } else {
    gridItems = [scratchItem];
  }

  return (
    <>
      <EditModal
        title={title}
        isOpen={isOpen && !assistantOpen}
        onClose={() => {
          onClose();
          clearError();
          reset();
          if (PLATFORM === 'steppit') {
            setView('select');
          }
        }}
        onSave={onSubmit}
        saveLabel={view === 'create' ? 'Create' : ''}
        saveDisabled={Boolean(!dirty || errors.title)}
        isUpdating={isUpdating}
        modalSize="xl"
        {...(PLATFORM === 'steppit' && view === 'create'
          ? {
              cancelLabel: 'Back',
              onCancel: () => setView('select'),
              cancelDisabled: false,
            }
          : {})}
      >
        {view === 'create' ? (
          <LabelInput
            id="title"
            name="title"
            label="What do you want to call your course?"
            placeholder={
              PLATFORM === 'steppit' ? placeholders[currentPlaceholderIdx] : ''
            }
            labelPosition="top"
            error={!!errors.title}
            errorMessage={errors.title?.message}
            registerInputRef={register({
              required: {
                value: true,
                message: 'Please give your course a title.',
              },
            })}
          />
        ) : !currentTeamProfile ? (
          <Loading />
        ) : (
          <ButtonGrid items={gridItems} />
        )}
      </EditModal>
      <AssistantModal
        isOpen={assistantOpen}
        onClose={() => setAssistantOpen(false)}
        toolSlug="proCourses"
        context={{ currency, pricingOptions }}
      />
    </>
  );
};

export default CreateCourseModal;
