import { Palette } from '.';

const common = {
  primary: Palette.blue[500],
  primaryDark: Palette.blue[600],
  primaryTransparent: Palette.blueAlpha[500],
  primaryPalette: Palette.blue,
  secondary: Palette.neutral[500],
  secondaryPalette: Palette.neutralLight,
  muted: Palette.neutral[500],
  notification: Palette.red[400],
  progress: Palette.green[500],
};

const text = {
  light: 'rgba(255, 255, 255, 1)',
  muted: Palette.neutral[500],
  default: Palette.neutral[900],
  opposite: 'rgba(255, 255, 255, 1)',
  dark: Palette.neutral[800],
  selected: Palette.blue[500],
  primary: Palette.blue[600],
  primaryDark: Palette.blue[600],
  placeholder: Palette.neutral[400],

  sidebar: Palette.neutral[900],

  // Intent
  none: Palette.blue[600],
  success: Palette.green[600],
  info: Palette.blue[600],
  error: Palette.red[600],
  warning: Palette.orange[600],
};

const background = {
  default: 'rgba(255, 255, 255, 1)',
  defaultTransparent: 'rgba(255, 255, 255, 0.8)',
  defaultXTransparent: 'rgba(255, 255, 255, 0.5)',
  tint1: Palette.neutral[10],
  tint2: Palette.neutral[100],
  tint3: Palette.neutral[50],
  tint4: Palette.neutral[300],
  overlay: Palette.neutralAlpha[600],
  primary: Palette.blue[50],
  primaryDark: Palette.blue[100],

  sidebar: 'rgba(255, 255, 255, 1)',

  // Intent
  none: Palette.blue[50],
  success: Palette.green[50],
  info: Palette.blue[50],
  error: Palette.red[50],
  warning: Palette.orange[50],
};

const icon = {
  default: Palette.neutral[800],
  muted: Palette.neutral[500],
  disabled: Palette.neutralAlpha[400],
  selected: Palette.blue[500],
  primary: Palette.blue[600],

  // Intent.
  none: Palette.blue[600],
  success: Palette.green[600],
  info: Palette.blue[600],
  error: Palette.red[600],
  warning: Palette.orange[600],
};

const border = {
  default: Palette.neutral[200],
  muted: Palette.neutral[100],
};

const intent = {
  none: Palette.blue,
  info: Palette.blue,
  success: Palette.green,
  error: Palette.red,
  warning: Palette.orange,
};

export default {
  common,
  text,
  background,
  icon,
  border,
  intent,
};
