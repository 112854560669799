import { SubscriptionData } from 'types/common';

export const PERMISSION_SLUGS = {
  can_view_content: 'can_view_content',
  can_edit_content: 'can_edit_content',
  can_edit_clip_summaries: 'can_edit_clip_summaries',
  can_view_performance: 'can_view_performance',
  can_view_balance: 'can_view_balance',
  can_view_reports: 'can_view_reports',
  can_view_classes: 'can_view_classes',
  can_edit_classes: 'can_edit_classes',
  can_view_organisation: 'can_view_organisation',
  can_view_members: 'can_view_members',
  can_edit_members: 'can_edit_members',
  can_edit_billing_details: 'can_edit_billing_details',
  can_edit_organisation: 'can_edit_organisation',
  can_manage_students: 'can_manage_students',
  can_view_agreements: 'can_view_agreements',
  can_edit_agreements: 'can_edit_agreements',
  can_create_rooms: 'can_create_rooms',
} as const;

export type PermissionSlug = keyof typeof PERMISSION_SLUGS;

export interface Permission {
  id: number;
  slug: PermissionSlug;
  name: string;
  description: string;
}

export type RoleSlug =
  | 'owner'
  | 'admin'
  | 'mentor'
  | 'team_manager'
  | 'finance'
  | 'marketing'
  | 'content_manager';

export interface Role {
  id: number;
  slug: RoleSlug;
  name: string;
  description: string;
  permissions: number[];
}

export interface ProPlan {
  id: number;
  name: string;
  slug: string;
  details: {
    teamMembers: number;
    monthlyAiCredits: number;
    monthlyEnrolments: number;
    platformRevShare: number;
  };
  tier: 0 | 1 | 2 | 3;
}

export interface Organisation {
  id: number;
  handle: string;
  acceptedTerms: boolean;
  accountName: string | null;
  accountNumber: number | null;
  address: string | null;
  city: string | null;
  contactEmail: string | null;
  contactNumber: string | null;
  dateAcceptedTerms: string | null;
  earlyAccessMentor: boolean;
  facebookUrl: string | null;
  instagramUrl: string | null;
  introduction: string | null;
  logo: string | null;
  logoDark: string | null;
  name: string;
  postCode: string | null;
  sortCode: string | null;
  twitterUrl: string | null;
  vatNumber: string | null;
  websiteUrl: string | null;
  youtubeUrl: string | null;
  tiktokUrl: string | null;
  discordUrl: string | null;
  patreonUrl: string | null;
  stripeAccountId: string | null;
  stripeAccountActive: boolean;
  stripeAccountStandard: boolean;
  isPro: boolean;
  proTrialRedeemed: boolean;
  proStarted: string | null;
  team: number;
  brandColor: string | null;
  imageBanner: string | null;
  imageBannerMobile: string | null;
  traits: {
    [key: string]: {
      count: number;
      training?: {
        index: number;
        title: string;
        value: any;
      };
    };
  } | null;
  proPlan: ProPlan | null;
  proInvoiceUuid: string | null;
  currentMonthlyAiCredits: number;
  currentExtraAiCredits: number;
  persona: string;
}

export interface OrganisationDetails extends Organisation {
  teamMembers: TeamMember[];
}

export interface TeamMemberUser {
  id: number;
  username: string;
  name: string;
  email: string;
}

export interface TeamMember {
  id: number;
  user: TeamMemberUser;
  team: number;
  roles: number[];
  permissions: number[];
  isCohortMentor: boolean;
}

export interface Team {
  id: number;
  organisation: number;
  users: Array<TeamMember>;
}

export interface MemberInvitationFormData {
  email: string;
  name: string;
  permissions: number[];
  roles: number[];
  message?: string;
  redirectUrl?: string;
}

export interface MemberInvitation extends MemberInvitationFormData {
  accepted: boolean;
  created: string;
  sent: string;
  id: number;
  inviter: number;
  key: string;
  name: string;
  team: { id: number; name: string };
  user: number | null;
}

export type OrgSubscriptions = SubscriptionData[];

export interface MembershipLicense {
  action: string;
  activationEmail: string;
  invoiceItemUuid: string | null;
  planId: string;
  slug: string;
  uuid: string;
  isRedeemed: boolean;
}
