import { useEffect, useState, useRef } from 'react';
import sampleSize from 'lodash/sampleSize';
import { useReward } from 'react-rewards';

import {
  Flex,
  Text,
  Box,
  Button,
  Grid,
  GridItem,
  Card,
  Palette,
} from '@workshop/ui';

// @ts-ignore
import card1 from 'assets/images/cards/card-1.png'; // @ts-ignore
import card2 from 'assets/images/cards/card-2.png'; // @ts-ignore
import card3 from 'assets/images/cards/card-3.png'; // @ts-ignore
import card4 from 'assets/images/cards/card-4.png'; // @ts-ignore
import card5 from 'assets/images/cards/card-5.png'; // @ts-ignore
import card6 from 'assets/images/cards/card-6.png'; // @ts-ignore
import card7 from 'assets/images/cards/card-7.png'; // @ts-ignore
import card9 from 'assets/images/cards/card-9.png'; // @ts-ignore
import card10 from 'assets/images/cards/card-10.png'; // @ts-ignore
import card11 from 'assets/images/cards/card-11.png'; // @ts-ignore
import card12 from 'assets/images/cards/card-12.png'; // @ts-ignore
import card13 from 'assets/images/cards/card-13.png'; // @ts-ignore
import card14 from 'assets/images/cards/card-14.png'; // @ts-ignore
import card15 from 'assets/images/cards/card-15.png'; // @ts-ignore
import card16 from 'assets/images/cards/card-16.png'; // @ts-ignore
import card17 from 'assets/images/cards/card-17.png'; // @ts-ignore
import card18 from 'assets/images/cards/card-18.png'; // @ts-ignore
import card19 from 'assets/images/cards/card-19.png'; // @ts-ignore
import card20 from 'assets/images/cards/card-20.png'; // @ts-ignore
import card21 from 'assets/images/cards/card-21.png';

import MiniGameCard from './MiniGameCard';

type GridCard = {
  type: string;
  image: string;
};

const uniqueElementsArray: GridCard[] = [
  {
    type: '1',
    image: card1,
  },
  {
    type: '2',
    image: card2,
  },
  {
    type: '3',
    image: card3,
  },
  {
    type: '4',
    image: card4,
  },
  {
    type: '5',
    image: card5,
  },
  {
    type: '6',
    image: card6,
  },
  {
    type: '7',
    image: card7,
  },
  {
    type: '9',
    image: card9,
  },
  {
    type: '10',
    image: card10,
  },
  {
    type: '11',
    image: card11,
  },
  {
    type: '12',
    image: card12,
  },
  {
    type: '13',
    image: card13,
  },
  {
    type: '14',
    image: card14,
  },
  {
    type: '15',
    image: card15,
  },
  {
    type: '16',
    image: card16,
  },
  {
    type: '17',
    image: card17,
  },
  {
    type: '18',
    image: card18,
  },
  {
    type: '19',
    image: card19,
  },
  {
    type: '20',
    image: card20,
  },
  {
    type: '21',
    image: card21,
  },
];

const NUM_UNIQUE_CARDS = 8;

const shuffleCards = (array: GridCard[]) => {
  const randomSelection = sampleSize(array, NUM_UNIQUE_CARDS);
  const pairs = [...randomSelection, ...randomSelection];
  const length = pairs.length;
  for (let i = length; i > 0; i--) {
    const randomIndex = Math.floor(Math.random() * i);
    const currentIndex = i - 1;
    const temp = pairs[currentIndex];
    pairs[currentIndex] = pairs[randomIndex];
    pairs[randomIndex] = temp;
  }
  return pairs;
};
const MiniGame = () => {
  const [cards, setCards] = useState(
    shuffleCards.bind(null, uniqueElementsArray)
  );
  const [openCards, setOpenCards] = useState<number[]>([]);
  const [clearedCards, setClearedCards] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [shouldDisableAllCards, setShouldDisableAllCards] = useState(false);
  const [moves, setMoves] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const storedScore = localStorage.getItem('bestScore');

  const [bestScore, setBestScore] = useState<number>(
    storedScore
      ? JSON.parse(storedScore) || Number.POSITIVE_INFINITY
      : Number.POSITIVE_INFINITY
  );

  const { reward } = useReward('complete', 'confetti', {
    lifetime: 200,
    startVelocity: 30,
    colors: [
      Palette.blue['300'],
      Palette.blue['400'],
      Palette.green['300'],
      Palette.green['400'],
      Palette.orange['200'],
      Palette.red['300'],
      Palette.neutral['10'],
    ],
  });

  const timeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const disable = () => {
    setShouldDisableAllCards(true);
  };
  const enable = () => {
    setShouldDisableAllCards(false);
  };

  const checkCompletion = () => {
    if (Object.keys(clearedCards).length === NUM_UNIQUE_CARDS) {
      setShowModal(true);
      const highScore = Math.min(moves, bestScore);
      setBestScore(highScore);
      localStorage.setItem('bestScore', `${highScore}`);
      reward();
    }
  };
  const evaluate = () => {
    const [first, second] = openCards;
    enable();
    if (cards[first].type === cards[second].type) {
      setClearedCards((prev) => ({ ...prev, [cards[first].type]: true }));
      setOpenCards([]);
      return;
    }
    // This is to flip the cards back after 500ms duration
    if (timeout) {
      timeout.current = setTimeout(() => {
        setOpenCards([]);
      }, 500);
    }
  };
  const handleCardClick = (index: number) => {
    if (openCards.length === 1) {
      setOpenCards((prev) => [...prev, index]);
      setMoves((moves) => moves + 1);
      disable();
    } else {
      timeout.current && clearTimeout(timeout.current);
      setOpenCards([index]);
    }
  };

  useEffect(() => {
    let to: ReturnType<typeof setTimeout> | null = null;
    if (openCards.length === 2) {
      to = setTimeout(evaluate, 300);
    }
    return () => {
      to && clearTimeout(to);
    };
  }, [openCards]);

  useEffect(() => {
    checkCompletion();
  }, [clearedCards]);
  const checkIsFlipped = (index: number) => {
    return openCards.includes(index);
  };

  const checkIsInactive = (card: GridCard) => {
    return Boolean(clearedCards[card.type]);
  };

  const handleRestart = () => {
    setClearedCards({});
    setOpenCards([]);
    setShowModal(false);
    setMoves(0);
    setShouldDisableAllCards(false);
    // set a shuffled deck of cards
    setCards(shuffleCards(uniqueElementsArray));
  };

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      ml={{ base: -4, sm: 0 }}
      mr={{ base: -4, sm: 0 }}
    >
      <Flex
        flexDirection="column"
        maxWidth="620px"
        width="100%"
        bg="background.tint2"
        px={{ base: 2, sm: 4, md: 6 }}
        py={{ base: 4, sm: 4, md: 6 }}
        borderRadius={{ base: 'none', sm: 'lg' }}
      >
        <Flex flexDirection="column" mb={4} flex={1}>
          <Flex alignItems="center" mb={2}>
            <Text fontWeight="bold" fontSize={{ base: 'md', sm: 'lg' }}>
              Match the Pairs 🧐
            </Text>
            <Flex flex={1} />
            <Button size="sm" variant="outline" onClick={handleRestart}>
              Restart
            </Button>
          </Flex>
          <Flex alignItems="center" color="text.muted">
            <Text mr={6}>{`Moves: ${moves}`}</Text>
            {localStorage.getItem('bestScore') && (
              <Text fontWeight="semibold">{`Best Score: ${bestScore}`}</Text>
            )}
          </Flex>
        </Flex>
        <Box
          width="100%"
          height="100%"
          maxWidth="600px"
          maxHeight="600px"
          sx={{ aspectRatio: '1' }}
          position="relative"
        >
          <Grid
            templateColumns="repeat(4, 1fr)"
            templateRows="repeat(4, 1fr)"
            gap={{ base: 2, sm: 3, md: 4 }}
            width="100%"
            height="100%"
            justifyItems="center"
            alignItems="stretch"
            sx={{
              perspective: '800px',
            }}
          >
            {cards.map((card, index) => {
              return (
                <GridItem w="100%" h="100%" key={`card-${index}`}>
                  <MiniGameCard
                    key={`minigame-card-${index}`}
                    card={card}
                    index={index}
                    isDisabled={shouldDisableAllCards}
                    isInactive={checkIsInactive(card)}
                    isFlipped={checkIsFlipped(index)}
                    onClick={handleCardClick}
                  />
                </GridItem>
              );
            })}
          </Grid>
          <Flex
            id="complete"
            pointerEvents="none"
            position="absolute"
            top={0}
            right={0}
            bottom={0}
            left={0}
            alignItems="center"
            justifyContent="center"
          />
          <Flex
            position="absolute"
            top={0}
            right={0}
            bottom={0}
            left={0}
            alignItems="center"
            justifyContent="center"
            opacity={showModal ? 1 : 0}
            pointerEvents={showModal ? 'auto' : 'none'}
            transform={showModal ? 'scale(1)' : 'scale(0.9)'}
            transition="0.3s"
          >
            <Card
              position="relative"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              padding={6}
              maxWidth="350px"
              textAlign="center"
            >
              <Text
                fontWeight="bold"
                fontSize="xl"
                mb={4}
                opacity={showModal ? 1 : 0}
              >
                Nice One!
              </Text>
              <Text mb={6} opacity={showModal ? 1 : 0}>
                You completed the game in {moves} moves. Your best score is{' '}
                {bestScore} moves.
              </Text>
              <Button onClick={handleRestart}>Restart</Button>
            </Card>
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
};
export default MiniGame;
