import React, { useState, useEffect, useRef } from 'react';
import { useReactMediaRecorder } from 'react-media-recorder';

import { CAP_CLIP_NAME } from 'constants/common';

import {
  Stack,
  Box,
  Flex,
  MdIcon,
  Text,
  Button,
  LightMode,
  Icon,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  useTheme,
} from '@workshop/ui';
import { GiRabbit, GiTortoise } from 'react-icons/gi';

import { EditModal } from 'components/Common/EditModal';
import {
  VideoClipsPlayer,
  StyledVideo,
  orientationStyleMapping,
  containerStyle,
} from 'components/VideoClipsPlayer';
import { AudioVisualizer } from 'components/AudioVisualizer';
import { Loading } from 'components/Loading';

import {
  MediaRecorderProps,
  MediaRecorderContentProps,
  StreamPreviewProps,
  TeleprompterProps,
} from './types';

const StreamPreview: React.FC<StreamPreviewProps> = ({
  videoStream,
  audioStream,
  orientation,
  mediaType,
  muted,
  setMuted,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  const theme = useTheme();

  const orientationStyles = orientationStyleMapping[orientation];

  useEffect(() => {
    if (videoRef.current && videoStream) {
      videoRef.current.srcObject = videoStream;
    }
  }, [videoStream]);

  if (!videoStream && !audioStream) {
    return null;
  }

  return (
    <Box {...orientationStyles} position="relative">
      <Flex
        {...containerStyle}
        alignItems="center"
        backgroundColor={
          mediaType === 'audio' ? 'background.primary' : 'background.tint2'
        }
        justifyContent="center"
        zIndex={0}
        borderRadius="md"
        overflow="hidden"
        // TODO: Only transform if video
        transform={mediaType === 'video' ? ['scale(-1, 1)'] : []}
      >
        {mediaType === 'audio' && audioStream ? (
          <Box paddingBottom={20}>
            <AudioVisualizer
              stream={audioStream}
              options={{
                barColor: theme.colors.common.primary,
                shadowColor: theme.colors.common.primaryTransparent,
              }}
            />
          </Box>
        ) : (
          <StyledVideo>
            <video ref={videoRef} autoPlay />
          </StyledVideo>
        )}
      </Flex>
      {mediaType !== 'audio' && (
        <Flex
          position="absolute"
          top={{ base: 3, md: 6 }}
          left={{ base: 3, md: 6 }}
          width={10}
          height={10}
          borderRadius="full"
          overflow="hidden"
          justifyContent="center"
          alignItems="center"
          zIndex={1}
        >
          <LightMode>
            <Button
              position="absolute"
              icon={muted ? 'MicOff' : 'Mic'}
              borderRadius="full"
              w={10}
              colorScheme="blackAlpha"
              onClick={() => setMuted(!muted)}
            />
          </LightMode>
          <Box pointerEvents="none">
            {!muted && audioStream?.active && (
              <AudioVisualizer
                stream={audioStream}
                options={{
                  barColor: '#fff',
                  shadowColor: '#fff',
                }}
                size="sm"
              />
            )}
          </Box>
        </Flex>
      )}
    </Box>
  );
};

const Teleprompter: React.FC<TeleprompterProps> = ({
  script,
  isRecording,
  orientation,
}) => {
  const [showTeleprompter, setShowTeleprompter] = useState(false);
  const [shouldScroll, setShouldScroll] = useState(true);
  const [speed, setSpeed] = useState(50);
  const [scrollValue, setScrollValue] = useState(0);
  const [scrollStartValue, setScrollStartValue] = useState(0);

  useEffect(() => {
    const speedString = localStorage.getItem('teleprompterSpeed');
    if (speedString) {
      setSpeed(Number(speedString));
    }
  }, []);

  useEffect(() => {
    if (script) {
      setShowTeleprompter(true);
    }
  }, [script]);

  useEffect(() => {
    localStorage.setItem('teleprompterSpeed', `${speed}`);
  }, [speed]);

  useEffect(() => {
    if (isRecording) {
      setShouldScroll(true);
    } else {
      setShouldScroll(false);
    }
  }, [isRecording]);

  const scrollTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const scrollContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (shouldScroll) {
      if (scrollContainer.current) {
        setScrollStartValue(scrollContainer.current.scrollTop);
      }
    }
  }, [shouldScroll]);

  useEffect(() => {
    if (shouldScroll) {
      scrollTimeout.current = setTimeout(() => {
        setScrollValue(scrollValue - speed / 10);
      }, 500);
    }
    if (!shouldScroll) {
      if (scrollValue) {
        if (scrollContainer.current) {
          scrollContainer.current.scrollTo({
            top: scrollStartValue - scrollValue,
            behavior: 'instant',
          });
        }
        setScrollValue(0);
      }
      scrollTimeout.current && clearTimeout(scrollTimeout.current);
    }
    return () => {
      scrollTimeout.current && clearTimeout(scrollTimeout.current);
    };
  }, [shouldScroll, scrollValue]);

  return (
    <>
      <Box
        position="absolute"
        top={{ base: 16, md: 20 }}
        right={{ base: 3, md: 6 }}
        zIndex={1}
      >
        <LightMode>
          <Button
            icon="Subtitles"
            borderRadius="full"
            w={10}
            colorScheme="blackAlpha"
            borderWidth={showTeleprompter ? 2 : 0}
            borderColor={showTeleprompter ? '#fff' : 'transparent'}
            onClick={() => {
              setShowTeleprompter(!showTeleprompter);
            }}
          />
        </LightMode>
      </Box>
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        pt={{ base: orientation === 'landscape' ? 3 : 6, md: 6 }}
        px={
          orientation === 'landscape'
            ? { base: 16, md: 32 }
            : { base: 16, md: 20 }
        }
        pb={10}
        bgGradient="linear(to-b, rgba(0,0,0,0.5), transparent)"
        opacity={showTeleprompter ? 1 : 0}
        transform={`translateY(${showTeleprompter ? 0 : -20}px)`}
        transition="opacity 0.3s, transform 0.3s"
        pointerEvents={showTeleprompter ? 'auto' : 'none'}
      >
        <Box
          ref={scrollContainer}
          height={
            orientation === 'landscape'
              ? { base: '40px', sm: '60px', md: '100px' }
              : { base: '100px', sm: '100px', md: '150px' }
          }
          overflow="scroll"
        >
          <Box
            transform={`translateY(${scrollValue}px)`}
            transition={`transform ${shouldScroll ? '0.5' : '0'}s linear`}
          >
            <Text
              fontWeight="semibold"
              fontSize={{ base: 'sm', sm: 'md', md: 'lg' }}
              color="#fff"
            >
              {script}
            </Text>
          </Box>
        </Box>
        <Flex alignItems="center" mt={{ base: 1, md: 2 }}>
          <Flex flex={1} position="relative" alignItems="center">
            <Flex position="absolute" left={0} alignItems="center">
              <Icon as={GiTortoise} w={4} h={4} color="#fff" />
            </Flex>
            <Flex position="absolute" right={0} alignItems="center">
              <Icon as={GiRabbit} w={4} h={4} color="#fff" />
            </Flex>
            <Slider
              aria-label="teleprompter-speed-slider"
              value={speed}
              min={10}
              max={100}
              step={1}
              onChange={(s) => setSpeed(s)}
              mx={6}
            >
              <SliderTrack bg="rgba(255, 255, 255, 0.6)">
                <SliderFilledTrack bg="rgba(255, 255, 255, 0.6)" />
              </SliderTrack>
              <SliderThumb />
            </Slider>
          </Flex>
          <Box
            _hover={{ opacity: 0.5 }}
            p={2}
            cursor="pointer"
            onClick={() => setShouldScroll(!shouldScroll)}
          >
            <MdIcon
              name={shouldScroll ? 'Pause' : 'PlayArrow'}
              w={4}
              h={4}
              color="#fff"
            />
          </Box>
        </Flex>
      </Box>
    </>
  );
};

const MediaRecorderContent: React.FC<MediaRecorderContentProps> = ({
  mediaType,
  orientation,
  muted,
  isRecording,
  showPlayer,
  mediaUrl,
  closing,
  setOrientation,
  setMuted,
  setIsRecording,
  setShowPlayer,
  setMediaUrl,
  setBlob,
  script,
}) => {
  const videoSize =
    orientation === 'portrait'
      ? {
          aspectRatio: 9 / 16,
        }
      : {
          aspectRatio: 16 / 9,
        };
  const {
    status,
    startRecording,
    stopRecording,
    mediaBlobUrl,
    previewStream,
    previewAudioStream,
    muteAudio,
    unMuteAudio,
  } = useReactMediaRecorder({
    video:
      mediaType === 'video'
        ? videoSize
        : mediaType === 'screen'
        ? {
            ...videoSize,
            // @ts-ignore
            displaySurface: 'window',
          }
        : false,
    audio: true,
    screen: mediaType === 'screen',
    askPermissionOnMount: true,
    stopStreamsOnStop: true,
    onStop: (blobUrl, blob) => setBlob(blob),
    ...(mediaType === 'audio'
      ? {
          blobPropertyBag: {
            type: 'audio/mp3',
          },
        }
      : {}),
  });

  useEffect(() => {
    if (closing && !showPlayer) {
      // Needed for media stream to correctly unmount
      startRecording();
    }
  }, [closing, showPlayer]);

  useEffect(() => {
    return () => {
      stopRecording();
    };
  }, []);

  useEffect(() => {
    if (mediaBlobUrl) {
      setMediaUrl(mediaBlobUrl);
      setShowPlayer(true);
    }
  }, [mediaBlobUrl]);

  useEffect(() => {
    if (muted) {
      muteAudio();
    } else {
      unMuteAudio();
    }
  }, [muted]);

  const handleStartRecording = () => {
    startRecording();
    setIsRecording(true);
  };
  const handleStopRecording = () => {
    stopRecording();
    setIsRecording(false);
  };

  return (
    <Flex
      flexDirection="column"
      maxWidth={orientation === 'portrait' ? '45vh' : '160vh'}
      mx="auto"
    >
      {previewStream || previewAudioStream ? (
        <Flex
          position="relative"
          justifyContent="center"
          bg="background.tint2"
          borderRadius="md"
          overflow="hidden"
        >
          <StreamPreview
            videoStream={previewStream}
            audioStream={previewAudioStream}
            orientation={orientation}
            mediaType={mediaType}
            muted={muted}
            setMuted={setMuted}
          />
          {(mediaType === 'video' || mediaType === 'screen') && (
            <Box
              position="absolute"
              top={{ base: 3, md: 6 }}
              right={{ base: 3, md: 6 }}
              zIndex={1}
            >
              <LightMode>
                <Button
                  icon="ScreenRotation"
                  borderRadius="full"
                  w={10}
                  colorScheme="blackAlpha"
                  transform={[
                    orientation === 'portrait'
                      ? 'rotate(45deg)'
                      : 'rotate(135deg)',
                  ]}
                  onClick={() => {
                    stopRecording();
                    setOrientation(
                      orientation === 'portrait' ? 'landscape' : 'portrait'
                    );
                  }}
                />
              </LightMode>
            </Box>
          )}
          {script ? (
            <Teleprompter
              script={script}
              isRecording={isRecording}
              orientation={orientation}
            />
          ) : null}

          <Box position="absolute" bottom={{ base: 4, sm: 6, md: 12 }}>
            {isRecording ? (
              <Button icon="Cancel" onClick={() => handleStopRecording()}>
                Stop Recording
              </Button>
            ) : (
              <Button
                colorScheme="red"
                icon="FiberManualRecord"
                onClick={() => handleStartRecording()}
              >
                Start Recording
              </Button>
            )}
          </Box>
        </Flex>
      ) : (
        <Flex py={12}>
          <Loading />
        </Flex>
      )}
    </Flex>
  );
};

const MediaRecorder: React.FC<MediaRecorderProps> = ({
  isOpen,
  onClose,
  onSave,
  clipType,
  script,
}) => {
  const [orientation, setOrientation] = useState<'portrait' | 'landscape'>(
    'portrait'
  );
  const [muted, setMuted] = useState<boolean>(false);
  const [mediaType, setMediaType] = useState<'video' | 'audio' | 'screen'>(
    clipType
  );
  const [refreshing, setRefreshing] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [showPlayer, setShowPlayer] = useState(false);
  const [mediaUrl, setMediaUrl] = useState<string | undefined>(undefined);
  const [blob, setBlob] = useState<Blob | undefined>(undefined);
  const [closing, setClosing] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setMediaType(clipType);
    }
  }, [isOpen]);

  useEffect(() => {
    if (!showPlayer) {
      setRefreshing(true);
      // Required to restart stream
      setTimeout(() => setRefreshing(false), 100);
    }
  }, [orientation, mediaType, showPlayer, isOpen, clipType]);

  const mediaTypes: {
    name: string;
    icon: string;
    slug: 'video' | 'audio' | 'screen';
    disabled: boolean;
  }[] =
    clipType === 'video'
      ? [
          {
            name: 'Camera',
            icon: 'Videocam',
            slug: 'video',
            disabled: isRecording || showPlayer,
          },
          {
            name: 'Screen',
            icon: 'Monitor',
            slug: 'screen',
            disabled: isRecording || showPlayer,
          },
        ]
      : [
          {
            name: 'Audio',
            icon: muted ? 'MicOff' : 'Mic',
            slug: 'audio',
            disabled: isRecording || showPlayer || muted,
          },
        ];
  return (
    <EditModal
      title={`Record a ${CAP_CLIP_NAME}`}
      isOpen={isOpen}
      onClose={() => {
        setClosing(true);
        // Timeout needed to allow media stream to correctly unmount
        setTimeout(() => onClose(), 1);
      }}
      onSave={() => {
        onSave(blob);
        setClosing(true);
        // Timeout needed to allow media stream to correctly unmount
        setTimeout(() => onClose(), 1);
      }}
      onCancel={showPlayer ? () => setShowPlayer(false) : undefined}
      saveDisabled={!showPlayer}
      saveLabel={showPlayer ? 'Save' : ''}
      cancelLabel="Try Again"
      modalSize={
        orientation === 'landscape' &&
        (mediaType === 'video' || mediaType === 'screen')
          ? '3xl'
          : 'lg'
      }
    >
      {isOpen ? (
        <>
          {refreshing ? null : (
            <>
              {!showPlayer && (
                <MediaRecorderContent
                  mediaType={mediaType}
                  orientation={
                    mediaType === 'video' || mediaType === 'screen'
                      ? orientation
                      : 'square'
                  }
                  muted={mediaType === 'audio' ? false : muted}
                  isRecording={isRecording}
                  showPlayer={showPlayer}
                  mediaUrl={mediaUrl}
                  closing={closing}
                  setOrientation={setOrientation}
                  setMuted={setMuted}
                  setIsRecording={setIsRecording}
                  setShowPlayer={setShowPlayer}
                  setMediaUrl={setMediaUrl}
                  setBlob={setBlob}
                  script={script}
                />
              )}
              {showPlayer && mediaUrl && (
                <Flex borderRadius="md" overflow="hidden">
                  <VideoClipsPlayer
                    autoplay
                    autoPlayNext
                    isEditable={false}
                    clips={[
                      {
                        type: mediaType === 'audio' ? 'audio' : 'video',
                        id: '',
                        data: {
                          autoplay: true,
                          clip: {
                            src: mediaUrl,
                          },
                        },
                      },
                    ]}
                    orientation={
                      mediaType === 'video' || mediaType === 'screen'
                        ? orientation
                        : 'square'
                    }
                  />
                </Flex>
              )}
              <Stack direction={{ base: 'column', sm: 'row' }} mt={4}>
                {mediaTypes.map((mt) => (
                  <Flex
                    key={`mediaType-${mt.slug}`}
                    flex={1}
                    borderWidth={2}
                    borderColor={
                      mt.slug === mediaType ? 'common.primary' : 'border.muted'
                    }
                    backgroundColor={
                      mt.slug === mediaType
                        ? 'background.default'
                        : 'background.tint1'
                    }
                    borderRadius="md"
                    p={4}
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    cursor="pointer"
                    _hover={{ backgroundColor: 'background.default' }}
                    onClick={() => {
                      if (mt.slug === 'screen') {
                        setClosing(true);
                        setTimeout(() => {
                          setMediaType(mt.slug);
                          setOrientation('landscape');
                          setClosing(false);
                        }, 1);
                      } else {
                        setMediaType(mt.slug);
                      }
                    }}
                    {...(mt.disabled
                      ? {
                          pointerEvents: 'none',
                          opacity: 0.5,
                        }
                      : {})}
                  >
                    <Box mb={1}>
                      <MdIcon
                        name={mt.icon}
                        boxSize={6}
                        color="common.primary"
                      />
                    </Box>
                    <Box>
                      <Text
                        fontSize="md"
                        fontWeight="bold"
                        color="common.primary"
                      >
                        {mt.name}
                      </Text>
                    </Box>
                  </Flex>
                ))}
              </Stack>
            </>
          )}
        </>
      ) : null}
    </EditModal>
  );
};

export default MediaRecorder;
