const MailAT = {
  JOIN_LIST_REQUEST: 'JOIN_LIST_REQUEST',
  JOIN_LIST_SUCCESS: 'JOIN_LIST_SUCCESS',
  JOIN_LIST_FAILURE: 'JOIN_LIST_FAILURE',

  FETCH_LIST_REQUEST: 'FETCH_LIST_REQUEST',
  FETCH_LIST_SUCCESS: 'FETCH_LIST_SUCCESS',
  FETCH_LIST_FAILURE: 'FETCH_LIST_FAILURE',

  UPDATE_LIST_RECIPIENT_REQUEST: 'UPDATE_LIST_RECIPIENT_REQUEST',
  UPDATE_LIST_RECIPIENT_SUCCESS: 'UPDATE_LIST_RECIPIENT_SUCCESS',
  UPDATE_LIST_RECIPIENT_FAILURE: 'UPDATE_LIST_RECIPIENT_FAILURE',
} as const;

export default {
  ...MailAT,
};
