import React from 'react';
import moment from 'moment';
// import { useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { simplifyName, emojifyText, hooks } from 'utils';
// import { courseActions } from 'redux/actions/learner';

import {
  Image,
  Card,
  Divider,
  Box,
  Flex,
  Text,
  MdIcon,
  Skeleton,
  SkeletonCircle,
  SkeletonText,
} from '@workshop/ui';

import { UserAvatar } from 'components/UserAvatar';
import { RenderHtml } from 'components/Common';
// import { Loading } from 'components/Loading';

// import { GlobalState } from 'types';

// import { SessionPreviewScreen } from 'screens/cms/SessionPreview';

type ActivityFeedItemCardProps =
  | { isLoading: true }
  | {
      isLoading?: false;
      createdAt: string;
      imageUrl?: string;
      likeCount?: number;
      linkTo?: string;
      postsCount?: number;
      summary?: string;
      title?: string;
      userAvatar?: string;
      userName?: string;
      openInNewTab?: boolean;
      iframeSrc?: string;
      // sessionSlug?: string;
    };

const defaultProps = {
  createdAt: '',
  imageUrl: undefined,
  likeCount: undefined,
  linkTo: undefined,
  postsCount: undefined,
  summary: 'Loading...',
  title: 'Loading...',
  userAvatar: undefined,
  userName: undefined,
  openInNewTab: false,
  iframeSrc: undefined,
  // sessionSlug: undefined,
};

const ActivityFeedItemCard: React.FC<ActivityFeedItemCardProps> = (props) => {
  const {
    createdAt,
    imageUrl,
    likeCount,
    linkTo,
    postsCount,
    summary,
    title,
    userName,
    userAvatar,
    openInNewTab,
    iframeSrc,
    // sessionSlug,
  } = props.isLoading ? defaultProps : props;

  // const { sessionLoading } = hooks.useLoadingDataState(
  //   {
  //     courseLoading: {
  //       actions: sessionSlug ? [() => courseActions.retrieve(sessionSlug)] : [],
  //     },
  //   },
  //   [sessionSlug]
  // );

  // const location = useLocation();
  // const history = useHistory();
  // const course = useSelector(
  //   (state: GlobalState) => state.learner.courses.courses.detail[sessionSlug]
  // );

  // const module = course?.standaloneModule;

  // const moduleState = useSelector(
  //   (state: GlobalState) => state.learner.courses.modules
  // );
  // const session = Object.values(moduleState).find((m) => m.id === module);

  const content = (
    <Card
      padding={0}
      flexDir="column"
      _hover={linkTo ? { background: 'background.tint1' } : {}}
      overflow={iframeSrc ? 'visible' : 'hidden'}
    >
      {props.isLoading ? (
        <Flex flexDirection="row" padding={2}>
          <SkeletonCircle size="2rem" />
          <Skeleton flex={1} ml={2} />
        </Flex>
      ) : (
        <Flex alignItems="center" m={2}>
          {userName && userAvatar ? (
            <>
              <UserAvatar
                size="2xs"
                userId={0}
                name={userName}
                avatarPicture={userAvatar}
              />
              <Text ml={2} fontWeight="semibold" fontSize="sm">
                {simplifyName(userName)}
              </Text>
            </>
          ) : null}
          {createdAt && (
            <>
              <Flex flex={1} />
              <Text fontSize="sm" color="text.muted" pr={2}>
                {moment(createdAt).year() === moment().year()
                  ? moment(createdAt).fromNow()
                  : moment(createdAt).format('D MMM YYYY')}
              </Text>
            </>
          )}
        </Flex>
      )}
      <Divider />

      <Flex flexDir="column">
        {title ? (
          <Skeleton
            isLoaded={!props.isLoading}
            m="defaultPadding"
            loadingStyle={{ height: 20 }}
          >
            <Text fontWeight="semibold">{emojifyText(title)}</Text>
          </Skeleton>
        ) : null}

        {summary ? (
          <SkeletonText
            isLoaded={!props.isLoading}
            mx="defaultPadding"
            mb="defaultPadding"
            noOfLines={3}
          >
            <RenderHtml html={emojifyText(summary)} />
          </SkeletonText>
        ) : null}
        {
          // sessionSlug ? (
          //   <Box w="100%" minH="350px" position="relative" bg="background.tint1">
          //     <Box w="100%" h={0} pb="56.25%">
          //       <Box position="absolute" w="100%" h="100%">
          //         {sessionLoading || !session ? (
          //           <Loading />
          //         ) : (
          //           <SessionPreviewScreen
          //             session={session}
          //             location={location}
          //             history={history}
          //             asComponent
          //             // isEmbeddedPlayer
          //           />
          //         )}
          //       </Box>
          //     </Box>
          //   </Box>
          // ) :
          iframeSrc ? (
            <Box
              w="100%"
              minH="350px"
              position="relative"
              bg="background.tint1"
            >
              <Box w="100%" h={0} pb="56.25%">
                <Box position="absolute" w="100%" h="100%">
                  <RenderHtml
                    iframeOnly
                    html={`<iframe src="${iframeSrc}" loading="lazy" width="100%" height="100%" title="Embedded Content" frameborder="0" allow="camera; microphone; display-capture; fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" scrolling="no"/>`}
                  />
                </Box>
              </Box>
            </Box>
          ) : imageUrl ? (
            <Image
              src={imageUrl}
              w="100%"
              h="auto"
              maxHeight="image.max"
              objectFit="cover"
            />
          ) : null
        }
      </Flex>

      {!props.isLoading &&
        linkTo &&
        Boolean(likeCount) &&
        Boolean(postsCount && postsCount > 1) && (
          <Flex
            justifyContent="flex-end"
            alignItems="center"
            p="defaultPadding"
          >
            {Boolean(postsCount && postsCount > 1) && postsCount && (
              <Flex alignItems="center">
                <MdIcon color="text.muted" name="ChatBubble" mr={1} />
                <Text color="text.muted">{postsCount - 1}</Text>
              </Flex>
            )}
            <Flex alignItems="center" ml={postsCount ? 6 : 0}>
              {Boolean(likeCount) && (
                <>
                  <MdIcon color="text.muted" name="Favorite" mr={1} />
                  <Text color="text.muted">{likeCount}</Text>
                </>
              )}
            </Flex>
          </Flex>
        )}
    </Card>
  );

  if (linkTo && !openInNewTab) {
    return <Link to={linkTo}>{content}</Link>;
  }
  if (linkTo && openInNewTab) {
    return (
      <Box cursor="pointer" onClick={() => window.open(linkTo, '_blank')}>
        {content}
      </Box>
    );
  }
  return <Box>{content}</Box>;
};

export default ActivityFeedItemCard;
