import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { FaGem } from 'react-icons/fa';
import { useDispatch } from 'react-redux';

import { analytics, hooks } from 'utils';

import { PRO_ORGS } from 'constants/organisation';

import {
  Box,
  Flex,
  Text,
  LinkButton,
  Card,
  MdIcon,
  useColorModeValue,
} from '@workshop/ui';

import { useCurrentTeamProfile, useCurrentTeam } from 'redux/selectors';
import { useDismissedInformationCards } from 'redux/selectors/user';
import { organisationActions, profileActions } from 'redux/actions/common';

import navRoutes from 'navigation/Routes';

import { BrandLg } from 'components/Brand';
import { Loading } from 'components/Loading';

const ProSuccessScreen: React.FC = () => {
  const [error, setError] = useState(false);

  const currentTeamProfile = useCurrentTeamProfile();
  const currentTeam = useCurrentTeam();
  const history = useHistory();
  const dispatch = useDispatch();
  const dismissedInformationCards = useDismissedInformationCards();

  const { organisationProfile: organisationLoading } =
    hooks.useLoadingDataState(
      {
        organisationProfile: {
          actions: currentTeam
            ? [() => organisationActions.fetchOrganisationProfile(currentTeam)]
            : [],
        },
      },
      [currentTeam]
    );

  const isLoading = organisationLoading;

  const isPro = Boolean(
    currentTeamProfile?.isPro || (currentTeam && PRO_ORGS.includes(currentTeam))
  );

  const tier = currentTeamProfile?.proPlan?.tier;

  useEffect(() => {
    if (isPro) {
      analytics.track('Pro Subscription Success');
      analytics.logConversion(
        dispatch,
        'startedTrial',
        {},
        `steppit-pro${tier ? `-${tier}` : ''}`
      );
      dispatch(
        profileActions.updateUserProfile(
          {
            dismissedInformationCards: [
              ...dismissedInformationCards,
              'proTrialStarted',
            ],
          },
          { toast: { success: false, error: false } }
        )
      );
    }
  }, [isPro]);

  const proColor = useColorModeValue('orange.500', 'orange.200');

  if (!isPro && !isLoading) {
    history.push(navRoutes.common.home.path());
  }

  return (
    <Flex
      position="absolute"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width="100vw"
      minHeight="100vh"
      py={12}
      backgroundColor="background.tint3"
    >
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Link to="/">
            <BrandLg mb={10} />
          </Link>
          <Card
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            maxWidth={550}
            width="100%"
            padding={{ base: 6, sm: 10 }}
          >
            {error ? (
              <>
                <MdIcon
                  name="Error"
                  boxSize="image.sm"
                  mb={6}
                  color="icon.muted"
                />
                <Text
                  fontSize="2xl"
                  fontWeight="semibold"
                  mb={6}
                  textAlign="center"
                >
                  Oops, something went wrong
                </Text>
                <Text color="text.muted" mb={8}>
                  It looks like this link is invalid, please return to the
                  homepage.
                </Text>
                <LinkButton to="/">Back to Home</LinkButton>
              </>
            ) : (
              <>
                <Box
                  bg="background.warning"
                  borderRadius="full"
                  p={4}
                  mb={-3}
                  color={proColor}
                  fontSize="4xl"
                >
                  <FaGem />
                </Box>
                <Flex
                  bg="background.success"
                  color="text.success"
                  borderRadius="full"
                  py={2}
                  px={3}
                  mb={6}
                  alignItems="center"
                  fontWeight="semibold"
                >
                  <MdIcon name="CheckCircle" />
                  <Text ml={2}>Success</Text>
                </Flex>

                <Text
                  fontSize="2xl"
                  fontWeight="semibold"
                  mb={6}
                  textAlign="center"
                >
                  Your channel was upgraded to Pro 🙌
                </Text>
                <Box mb={8}>
                  <Text color="text.muted" textAlign="center">
                    Congratulations on upgrading to Pro! You now have the power
                    to build extensive courses, run private classes, expand your
                    team, and get help from your personal AI assistant.
                  </Text>
                </Box>
                <LinkButton
                  to="/"
                  icon="ArrowForward"
                  iconPosition="right"
                  size="lg"
                  mb={{ base: 2, sm: 0 }}
                >
                  Let's Go
                </LinkButton>
              </>
            )}
          </Card>
        </>
      )}
    </Flex>
  );
};

export default ProSuccessScreen;
