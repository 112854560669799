import { CheckList, CheckListItem } from 'types/common';
import {
  ISession,
  RequirementSessionFormData,
  IStepType,
  StepListState,
  StepQuestionState,
  IVideoClipListItem,
  VideoClipListState,
} from 'types/cms';

// TODO: Move to global utils
import { generateSteps } from 'screens/learner/Session/src/utils';

export interface DraggableClip {
  id: string | number;
}

export interface DraggableStep {
  id: string;
  title: string;
  label: string;
  index: number;
  stepType: string;
  clipCount: number;
  onClick: () => void;
  secondaryLabel?: string;
}

type FormattedStepByType = {
  [key in IStepType]: DraggableStep[];
};

interface FormattedVideoClipData {
  [id: string]: { summary: string; videoClip: FileList };
}

/*
 * Return the Steps grouped by StepTypes
 */
export const groupByStepType = (
  steps: StepListState,
  onClick: Function,
  hasIntroStep: boolean
): FormattedStepByType => {
  return Object.keys(steps).reduce<FormattedStepByType>(
    (acc, stepId) => {
      const step = steps[stepId];

      acc[step.stepType] = [
        ...acc[step.stepType],
        {
          id: stepId,
          title: step?.title,
          label:
            step?.stepType === 'intro'
              ? 'Intro'
              : step?.stepType === 'outro'
              ? 'Outro'
              : // If session has an intro step, the `Step x` ;
                // index needs offsetting by 1 to account for the
                // intro having the index of 1.
                `Step ${hasIntroStep ? step?.index - 1 : step?.index}`,
          index: step?.index,
          stepType: step?.stepType,
          clipCount: step?.clipCount,
          onClick: () => onClick(),
        },
      ];
      return acc;
    },
    {
      normal: [],
      intro: [],
      outro: [],
    }
  );
};

export const getExpandedVideoClips = (
  videoClips: VideoClipListState,
  stepId: number
) =>
  Object.values(videoClips)
    .filter((videoClip: IVideoClipListItem) => videoClip.step === stepId)
    .sort((a, b) => a.index - b.index);

export const getExpandedVideoClipIds = (
  videoClips: VideoClipListState,
  stepId: number
) =>
  Object.values<IVideoClipListItem>(videoClips)
    .filter((videoClip) => videoClip.step === stepId)
    .sort((a, b) => a.index - b.index)
    .map((videoClip) => ({ id: videoClip.id }));

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

/**
 * Transform video clip update data received from react-hook-form in
 * to a more readable format.
 *
 * e.g. {summary_{id}: 'summary value', videoClip_{id}: File[], summary_{anotherId}: 'summary value'}
 * to { {id}: {summary: 'summary value', videoClip: File[]}, ...}
 */
export const formatVideoClipData = (data: {
  [key: string]: string | FileList;
}): FormattedVideoClipData => {
  return Object.keys(data).reduce(
    (acc: FormattedVideoClipData, key: string) => {
      const [field, id] = key.split('_');

      acc[id] = {
        ...acc[id],
        [field]: data[key],
      };
      return acc;
    },
    {}
  );
};

type PartialCheckList = Partial<CheckList<Partial<CheckListItem>>>;

export const formattedRequirementData = (
  data: RequirementSessionFormData,
  checkList?: PartialCheckList
): PartialCheckList[] => {
  const { requirementsTitle: title, items: itemsAsTable } = data;

  const items: Partial<CheckListItem>[] = Object.values(itemsAsTable)
    .filter((item) => item.title)
    .map((item) => {
      return {
        title: item.title,
      };
    });

  if (checkList && checkList.id) {
    return [
      {
        id: checkList.id,
        title: title,
        items,
      },
    ];
  }
  return [
    {
      title: title,
      items,
    },
  ];
};

export const generatePlayerChecklist = (session: ISession) => {
  return session?.checkList[0]
    ? {
        ...session.checkList[0],
        items: session.checkList[0].items.map((i) => ({
          ...i,
          content: i.title,
          isChecked: true,
        })),
      }
    : undefined;
};

export const generatePlayerSteps = ({
  session,
  steps,
  questions,
  videoClips,
}: {
  session: ISession;
  steps: StepListState;
  questions: StepQuestionState;
  videoClips: IVideoClipListItem[];
}) => {
  if (!session) return [];

  const checklist = generatePlayerChecklist(session);

  const stepsArr = Object.values(steps)
    .map((s) => {
      const videoClipsArr = videoClips
        .filter((c) => c.step === s.id)
        .sort((a, b) => a.index - b.index);
      // TODO: Add prompts
      return {
        ...s,
        videoClips: videoClipsArr,
        discourseTopicId: 0,
      };
    })
    .sort((a, b) => a.index - b.index);

  return generateSteps({
    checklist,
    exerciseText: session.exerciseText,
    imagePortraitMobile: session.imagePortraitMobile,
    moduleFormat: session.moduleFormat,
    moduleQuestions: questions,
    steps: stepsArr,
    unlocked: true,
    // TODO: Make sure HLS is smooth before removing this - or set it based on internet speed
    highQuality: true,
  });
};
