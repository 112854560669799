import React, { useState, useRef, useEffect } from 'react';
import sanitizeHtml from 'sanitize-html';
import { useParams, useLocation } from 'react-router-dom';

import { CAP_CLIP_NAME } from 'constants/common';

import { getParamFromUrl } from 'utils';

import {
  Box,
  Card,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  MdIcon,
  useMediaQuery,
} from '@workshop/ui';

import { IVideoClip } from 'types/cms';

import { RenderHtml } from 'components/Common';
import { ModalVideo } from 'components/ModalVideo';
import { Stepper } from 'components/Stepper';
import { VideoClipsPlayer } from 'components/VideoClipsPlayer';
import { Orientation } from 'components/VideoClipsPlayer/types';
import { SessionStepType } from 'components/SessionPlayer';
import { AddItem } from 'components/ListItem';

import { tourIds } from 'screens/cms/SessionEdit';

import { generateStepperSteps, SessionStepperStep } from './utils';

interface SessionStepperProps {
  loading?: boolean;
  onSetOrienation?: (o: Orientation) => void;
  sessionSubsteps: SessionStepperStep[];
  currentSessionStepId: string | null;
  currentSessionStepType?: SessionStepType;
  title: string;
  isEditable?: boolean;
  onSaveClip?: (clipId: string, data: Partial<IVideoClip>) => Promise<void>;
  handleVideoUpload?: (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string,
    mediaType?: 'video' | 'audio'
  ) => Promise<void>;
  handleAddClip?: (id: string) => Promise<void>;
  onTouchBlurBackground?: (blur: boolean) => void;
  embed?: boolean;
}

const noop = () => null;

/**
 * The SessionStepper component is rendered by SessionPlayer
 * It is responsible for rendering the sub-steps of a given session
 */
const SessionStepper: React.FC<SessionStepperProps> = ({
  loading = false,
  onSetOrienation = noop,
  sessionSubsteps,
  currentSessionStepId,
  currentSessionStepType,
  title,
  isEditable = false,
  onSaveClip,
  handleVideoUpload,
  handleAddClip,
  onTouchBlurBackground,
  embed,
}) => {
  const params = useParams<{
    courseId: string;
    unitId: string;
    sessionId: string;
  }>();

  const [stepperStepIdx, setStepperStepIdx] = useState(0);
  const [displayNotes, setDisplayNotes] = useState(false);
  const [displayEditClip, setDisplayEditClip] = useState(false);
  const [startScrolling, setStartScrolling] = useState(false);
  const [backgroundBlurred, setBackgroundBlurred] = useState(false);

  const [isTouchDevice] = useMediaQuery('(hover: none)');

  const location = useLocation();
  const isEndOfStep = getParamFromUrl(location, 'e') === '1';

  useEffect(() => {
    if (isTouchDevice && onTouchBlurBackground) {
      onTouchBlurBackground(backgroundBlurred);
    }
  }, [backgroundBlurred, isTouchDevice, onTouchBlurBackground]);

  const currentStepperStepIdx =
    stepperStepIdx + 1 > sessionSubsteps.length ? 0 : stepperStepIdx;

  const currentStepperStep = sessionSubsteps[currentStepperStepIdx];

  const [nextDisabled, setNextDisabled] = useState(
    currentStepperStep && currentStepperStep.subStepType !== 'guided'
  );

  /**
   * The currentRef will be passed to the div containing content of the
   * current step to be highlighted (if any, i.e, in a guided step)
   * --> see generateStepperSteps in utils file
   */
  const currentRef = useRef<HTMLDivElement>(null);

  /**
   * The containerRef will be passed to the parent div responsible for
   * rendering the current step content
   * --> see Stepper/StepWrapper component
   */
  const containerRef = useRef<HTMLDivElement>(null);

  /**
   * Map sessionSubsteps to a list of ids, turn it to a string to be
   * passed as a dependency to the below useEffect hooks
   */
  const stepIds = JSON.stringify(sessionSubsteps.map((s) => s.id));

  /** ------ EFFECTS ------ */

  /**
   * This effect is reponsible for:
   * - enabling/disabling the "next" button
   * - setting the orientation based on the current step
   * - starting the scrolling to the relevant section of the
   * current step (see next effect for details)
   *
   * (this effect triggers when moving on to the next step
   * or when receiving a new list of steps)
   */
  useEffect(() => {
    if (!currentStepperStep) return;

    /**
     * Toggle the startScrolling state to true to enable scrolling
     * to the relevant section inside the Stepper component
     */
    setStartScrolling(true);

    /**
     * Enable or disable the next button when displaying a new step
     * based on the current step type
     */
    switch (currentStepperStep.subStepType) {
      case 'guided':
        if (nextDisabled) setNextDisabled(false);
        return;

      case 'prompt':
        if (currentStepperStep.responseType === 'none') return;
        if (currentStepperStep.promptProps?.isAssessment) {
          if (!nextDisabled) setNextDisabled(true);
          return;
        }
        if (nextDisabled) setNextDisabled(false);
        return;

      case 'mcq':
        if (nextDisabled) setNextDisabled(false);
        return;

      case 'checklist':
        // disabling next buttons if not all items have been checked
        // const allItemsChecked = currentStepperStep.items.every(
        //   (item) => item.isChecked
        // );
        // setNextDisabled(!allItemsChecked);
        if (nextDisabled) setNextDisabled(false);
    }

    onSetOrienation(currentStepperStep.orientation || 'portrait');
  }, [currentStepperStepIdx, stepIds]);

  /**
   * This effect is executed after the scrolling state
   * has been set to true (see previous effect)
   *
   * Uses the containerRef and currentRef to bring
   * the current step into view
   */
  useEffect(() => {
    if (!startScrolling) return;

    setStartScrolling(false);

    if (currentStepperStep?.orientation !== 'portrait') return;

    if (!containerRef?.current) return;
    if (!currentRef?.current) {
      containerRef.current.scrollTop = 0;
      return;
    }

    /**
     * Scroll to the relevant section inside the Stepper component
     * the parentRect & childRect refer to
     * the Stepper container and the relevant guided step (respectively)
     */
    const parentRect = containerRef.current.getBoundingClientRect();
    const childRect = currentRef.current.getBoundingClientRect();

    const parentViewableArea = {
      height: containerRef.current.clientHeight,
      width: containerRef.current.clientWidth,
    };

    if (!parentViewableArea.height) return;

    var isViewable =
      childRect.top >= parentRect.top &&
      childRect.top <= parentRect.top + parentViewableArea.height;

    if (!isViewable) {
      // scroll by offset relative to parent
      containerRef.current.scrollTop =
        childRect.top + containerRef.current.scrollTop - parentRect.top;
    }
  }, [startScrolling]);

  /**
   * Toggle orientation when navigating to a new step
   * (fallback to 'portrait' mode)
   */
  useEffect(() => {
    if (!currentStepperStep) return;

    onSetOrienation(currentStepperStep.orientation || 'portrait');
  }, [currentStepperStep?.id]);

  /**
   * Reset the step index when changing session step
   * (this happens when navigating to a different session step
   * from the SessionPlayer component)
   */
  useEffect(() => {
    if (isEndOfStep) {
      setStepperStepIdx(sessionSubsteps.length - 1);
    } else {
      setStepperStepIdx(0);
    }
  }, [currentSessionStepId]);

  if (loading || (!currentStepperStep && !isEditable)) {
    return (
      <Box
        sx={{
          height: { base: 'auto', lg: embed ? '100vh' : 0 },
          '@supports (height: 100dvh)': {
            height: { base: 'auto', lg: embed ? '100dvh' : 0 },
          },
        }}
        paddingBottom={{ base: 0, lg: embed ? 0 : '70%' }}
        position="relative"
        {...(embed
          ? {
              mx: 'auto',
            }
          : {})}
      >
        <Flex
          position={{
            base: 'relative',
            lg: 'absolute',
          }}
          top={0}
          right={0}
          bottom={0}
          left={0}
          flexDirection={{ base: 'column', lg: 'row' }}
          height={{ base: 'auto', lg: '100%' }}
          alignItems={{ base: 'center', lg: 'normal' }}
        >
          <Card
            {...(embed
              ? {
                  maxWidth: '100vw',
                  sx: {
                    maxHeight: '100vh',
                    width: '56.25vh',
                    '@supports (height: 100dvh)': {
                      maxHeight: '100dvh',
                      width: '56.25dvh',
                    },
                  },
                }
              : {
                  width: { base: '100%', md: '60%', lg: '100%' },
                })}
            flex={embed ? 'none' : { base: 1, md: 3, lg: 2 }}
            padding={0}
            borderRadius={{
              base: 0,
              md: embed ? 0 : '16px !important',
            }}
            overflow="hidden"
            transform="translate3d(0, 0, 0)"
          >
            <VideoClipsPlayer clips={[]} loading />
          </Card>
          <Box
            width={3}
            height={3}
            display={{
              base: embed ? 'none' : 'block',
            }}
          />
          <Card
            flex={{ base: 1, lg: 3 }}
            padding={0}
            borderRadius={{ base: 0, md: embed ? 'none' : '16px !important' }}
            width="100%"
            {...(embed
              ? {
                  display: { base: 'none', lg: 'flex' },
                }
              : {})}
          >
            <Stepper steps={[]} loading />
          </Card>
        </Flex>
      </Box>
    );
  }

  if (!currentStepperStep && isEditable) {
    return (
      <Box
        height={{ base: 'auto', lg: 0 }}
        paddingBottom={{ base: 0, lg: '70%' }}
        position="relative"
      >
        <Flex
          position={{
            base: 'relative',
            lg: 'absolute',
          }}
          top={0}
          right={0}
          bottom={0}
          left={0}
          flexDirection={{ base: 'column', lg: 'row' }}
          height={{ base: 'auto', lg: '100%' }}
          alignItems={{ base: 'center', lg: 'normal' }}
        >
          {/* <Card
            width={{ base: '100%', md: '60%', lg: '100%' }}
            maxHeight="100vh"
            flex={{ base: 1, md: 3, lg: 2 }}
            padding={0}
            borderRadius={{ base: 0, md: '16px !important' }}
            overflow="hidden"
            transform="translate3d(0, 0, 0)"
          >
            <VideoClipsPlayer clips={[]} />
          </Card>
          <Box width={3} height={3} /> */}
          <Card
            flex={{ base: 1, lg: 3 }}
            padding={0}
            borderRadius={{ base: 0, md: '16px !important' }}
            width="100%"
          >
            <AddItem
              label={`Add a ${CAP_CLIP_NAME}`}
              icon="HdrStrong"
              variant="dotted"
              onSave={() => {}}
              onClick={async () => {
                handleAddClip &&
                  (await handleAddClip(currentSessionStepId as string));
                setTimeout(() => setStepperStepIdx(sessionSubsteps.length), 1);
              }}
            />
          </Card>
        </Flex>
      </Box>
    );
  }

  const {
    notes,
    onClickNext: currentStepOnClickNext,
    onClickPrev: currentStepOnClickPrev,
    orientation = 'portrait',
  } = currentStepperStep;

  /** ------ STEPPER PROPS ------ */

  const onClickNext = async () => {
    if (currentStepOnClickNext) await currentStepOnClickNext();

    if (currentStepperStepIdx + 1 >= sessionSubsteps.length) return;
    setStepperStepIdx(currentStepperStepIdx + 1);
  };

  const onClickPrev = () => {
    currentStepOnClickPrev && currentStepOnClickPrev();

    if (currentStepperStepIdx - 1 < 0) return;
    setStepperStepIdx(currentStepperStepIdx - 1);
  };

  const onClickClipSummary = (idx: number) => setStepperStepIdx(idx);

  const stepperProps = {
    currentIdx: currentStepperStepIdx,
    currentType: currentSessionStepType,
    steps: generateStepperSteps({
      currentRef,
      currentStepIdx: currentStepperStepIdx,
      nextDisabled,
      onClickNext,
      onClickPrev,
      onClickClipSummary,
      isEditable,
      onEdit: () => setDisplayEditClip(true),
      setDisplayNotes,
      setNextDisabled,
      sessionSubsteps,
      title,
    }),
  };

  /** ------ VIDEO PLAYER PROPS ------ */

  const videoPlayerSteps = sessionSubsteps
    .filter(
      (step) => isEditable || step.clipSrc || step.clipMediaType === 'text'
    )
    .map((clip) => {
      const {
        clipMediaType,
        clipSrc,
        clipSrcHq,
        id,
        showNextBtn,
        showPrevBtn,
        subStepType,
      } = clip;
      // filtering of undefined clipSrc done above
      // adding type assertion here to keep TS happy
      const src = clipSrc as string;
      const srcHq = clipSrcHq;
      const type = clipMediaType;
      // @ts-ignore
      const summary = clip.summary || '';
      // @ts-ignore
      const script = clip.script || '';

      // return type === 'video'
      //   ? {
      //       id,
      //       type,
      //       data: {
      //         clip: {
      //           src,
      //           onClickNext,
      //           onClickPrev,
      //           showNextBtn:
      //             showNextBtn && currentStepperStepIdx < sessionSubsteps.length,
      //           showPrevBtn: showPrevBtn && currentStepperStepIdx > 0,
      //           summary,
      //         },
      //       },
      //     }
      //   : { id, type, data: { src } };
      return {
        id,
        type,
        hasMedia: subStepType === 'guided',
        data: {
          clip: {
            src,
            srcHq,
            onClickNext,
            onClickPrev,
            showNextBtn:
              showNextBtn && currentStepperStepIdx < sessionSubsteps.length,
            showPrevBtn: showPrevBtn,
            summary,
            script,
          },
        },
      };
    });

  /** Working our way backwards from the current step to find the
   *  relevant video step (since clipSrc is optional)
   *  If the session is editable, also show media steps without clipSrc to allow for uploading
   */
  const currentVideoStep = sessionSubsteps
    .slice(0, currentStepperStepIdx + 1)
    .reverse()
    .find(
      (step) =>
        step && (isEditable || step.clipSrc || step.clipMediaType === 'text')
    );

  const currentVideoStepIdx = currentVideoStep
    ? videoPlayerSteps.findIndex((s) => s.id === currentVideoStep.id)
    : 0;

  // Starting with "steppitapp://" just tells the native iOS camera to link the user to the app, the QR code currently needs to be scanned through
  // the in-app QR scanner to navigate to the specific clip (TODO: Deep link to clip straight from native camera)
  const qrBlob = isEditable
    ? `steppitapp://t/course/${params.courseId}/session/${params.sessionId}/step/${currentSessionStepId}/bit/${currentVideoStep?.id}`
    : '';

  const videoPlayerProps = {
    clips: videoPlayerSteps,
    // TODO: Rename all mentions of "steps" in SessionStepper, VideoClipsPlayer & Stepper files to "clips", and "sessionSteps" to "steps"
    currentStepIdx: currentVideoStepIdx,
    currentSessionStepId,
    orientation,
    isEditable,
    onUpload: handleVideoUpload,
    onSaveClip:
      currentVideoStep && onSaveClip
        ? (data: Partial<IVideoClip>) =>
            onSaveClip(currentVideoStep.id.toString(), data)
        : undefined,
    qrBlob,
    embed: embed,
    onBlurBackground: (blur: boolean) => setBackgroundBlurred(blur),
  };

  const portrait = orientation === 'portrait';

  const videoSummary =
    currentVideoStep &&
    'summary' in currentVideoStep &&
    typeof currentVideoStep.summary === 'string'
      ? currentVideoStep.summary
      : undefined;

  const videoScript =
    currentVideoStep &&
    'script' in currentVideoStep &&
    typeof currentVideoStep.script === 'string'
      ? currentVideoStep.script
      : undefined;

  const currentStepHasMedia =
    currentVideoStep?.subStepType === 'guided' &&
    !(
      !isEditable &&
      (currentVideoStep?.clipMediaType === 'text' || !currentVideoStep?.clipSrc)
    );

  return (
    <>
      <Box
        height={{ base: 'auto', lg: portrait && !embed ? 0 : '100%' }}
        paddingBottom={{ base: 0, lg: portrait && !embed ? '70%' : 0 }}
        position="relative"
        {...(embed
          ? {
              mx: 'auto',
            }
          : {})}
      >
        <Flex
          position={{
            base: 'relative',
            lg: portrait ? 'absolute' : 'relative',
          }}
          top={0}
          right={0}
          bottom={0}
          left={0}
          flexDirection={{ base: 'column', lg: portrait ? 'row' : 'column' }}
          height={{ base: 'auto', lg: '100%' }}
          {...(embed && !portrait
            ? {
                justifyContent: { base: 'normal', lg: 'center' },
                alignItems: 'center',
              }
            : {
                alignItems: { base: 'center', lg: 'normal' },
              })}
        >
          <Card
            {...(embed
              ? {
                  maxWidth: '100vw',
                  sx: {
                    maxHeight: '100vh',
                    width: currentStepHasMedia
                      ? portrait
                        ? '56.25vh'
                        : '177.8vh'
                      : '0%',
                    '@supports (height: 100dvh)': {
                      maxHeight: '100dvh',
                      width: currentStepHasMedia
                        ? portrait
                          ? '56.25dvh'
                          : '177.8dvh'
                        : '0%',
                    },
                  },
                }
              : {
                  maxWidth: '100vw',
                  sx: {
                    maxHeight: '90vh',
                    width: currentStepHasMedia
                      ? {
                          base: portrait ? '56.25vh' : '177.8vh',
                          md: portrait ? '60%' : '100%',
                          lg: '100%',
                        }
                      : '0%',
                    '@supports (height: 100dvh)': {
                      maxHeight: '85dvh',
                      width: currentStepHasMedia
                        ? {
                            base: portrait ? '47.8dvh' : '151.1dvh',
                            md: portrait ? '60%' : '100%',
                            lg: '100%',
                          }
                        : '0%',
                    },
                  },
                })}
            flex={
              embed
                ? 'none'
                : currentStepHasMedia
                ? { base: 1, md: 3, lg: portrait ? 2 : 'none' }
                : 0
            }
            padding={0}
            borderRadius={{
              base: embed ? 0 : '16px !important',
              // lg: '16px !important',
            }}
            cursor="auto"
            // Needed for Safari to respect hidden overflow over video
            transform="translate3d(0, 0, 0)"
            overflow={embed ? 'visible' : 'hidden'}
            transition="all 0.5s"
            data-tour={tourIds.sessionStepperMedia}
          >
            <VideoClipsPlayer {...videoPlayerProps} />
          </Card>
          <Box
            width={currentStepHasMedia ? 3 : 0}
            height={currentStepHasMedia ? 3 : 0}
            transition="width 0.5s, height 0.5s"
            display={{
              base: embed ? 'none' : 'block',
            }}
          />
          <Card
            flex={{ base: 1, lg: portrait ? 3 : 1 }}
            padding={0}
            borderRadius={{ base: 0, md: embed ? 'none' : '16px !important' }}
            width="100%"
            data-tour={tourIds.sessionStepperBits}
            {...(embed
              ? {
                  display: { base: 'none', lg: !portrait ? 'none' : 'flex' },
                  ...(currentStepHasMedia
                    ? {}
                    : {
                        transition: 'padding-left 0.3s',
                        _groupHover: {
                          pl: { base: 0, lg: '70px' },
                        },
                      }),
                }
              : {})}
          >
            <Stepper
              {...stepperProps}
              forceNextBtn={currentStepperStep.showNextBtn}
              stepContainerRef={containerRef}
              isEditable={isEditable}
              handleAddClip={async () => {
                handleAddClip &&
                  (await handleAddClip(currentSessionStepId as string));
                setTimeout(() => setStepperStepIdx(sessionSubsteps.length), 1);
              }}
              canAddClip={currentStepperStep.subStepType === 'guided'}
            />
          </Card>
        </Flex>
        {/* TODO: Get rid if embedded session player's working well */}
        {/* {embed ? (
          <Flex
            position="absolute"
            top={0}
            right={0}
            bottom={0}
            left={0}
            alignItems="center"
            p={6}
            pointerEvents="none"
            opacity={backgroundBlurred ? 1 : 0}
            transition="opacity 0.3s"
            {...(isTouchDevice
              ? {
                  ...(backgroundBlurred
                    ? {
                        opacity: 1,
                      }
                    : {}),
                }
              : {
                  _groupHover: {
                    opacity: 1,
                  },
                })}
            display={{ base: 'flex', lg: portrait ? 'none' : 'flex' }}
          >
            <Button
              pointerEvents={currentVideoStep?.showPrevBtn ? 'auto' : 'none'}
              onClick={onClickPrev}
              icon="ArrowBack"
              size="sm"
              secondary
              w={8}
              opacity={currentVideoStep?.showPrevBtn ? 1 : 0}
            />

            <Flex
              pointerEvents={backgroundBlurred ? 'auto' : 'none'}
              flex={1}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Flex
                position="absolute"
                opacity={backgroundBlurred ? 0 : 1}
                transition="opacity 0.3s"
              >
                <Box
                  backgroundColor="rgba(255, 255, 255, 0.5)"
                  _hover={{ backgroundColor: 'rgba(255, 255, 255, 0.3)' }}
                  borderRadius="50%"
                  cursor="pointer"
                  padding={1}
                  backdropFilter="blur(10px)"
                >
                  <MdIcon color="text.dark" name="Done" boxSize={6} />
                </Box>
              </Flex>
              <Flex
                opacity={backgroundBlurred ? 1 : 0}
                transition="opacity 0.3s"
                flex={1}
                flexDirection="column"
              >
                {currentVideoStep &&
                  'summary' in currentVideoStep &&
                  currentVideoStep.summary && (
                    <Flex
                      px={4}
                      pb={{ base: 2, md: 6 }}
                      mx="auto"
                      display={{
                        base: 'flex',
                        lg: orientation === 'landscape' ? 'flex' : 'none',
                      }}
                    >
                      <Text
                        color="text.light"
                        fontWeight="semibold"
                        textAlign="center"
                      >
                        {currentVideoStep.summary}
                      </Text>
                    </Flex>
                  )}
                <Flex
                  mx="auto"
                  display={{
                    base: 'flex',
                    lg: orientation === 'landscape' ? 'flex' : 'none',
                  }}
                  mb={{ base: 6, md: 20, lg: 6 }}
                >
                  {Boolean(currentVideoStep?.showNextBtn && onClickNext) && (
                    <Button
                      mx={2}
                      mt={2}
                      minWidth="100px"
                      fontSize={{ base: 'sm', md: 'md' }}
                      size="sm"
                      onClick={onClickNext}
                    >
                      Next
                    </Button>
                  )}
                </Flex>
              </Flex>
            </Flex>

            <Button
              onClick={onClickNext}
              icon="ArrowForward"
              size="sm"
              secondary
              w={8}
              opacity={currentVideoStep?.showNextBtn ? 1 : 0}
              pointerEvents={currentVideoStep?.showNextBtn ? 'auto' : 'none'}
            />
          </Flex>
        ) : null} */}
      </Box>

      {/* ------ NOTES MODAL ------ */}
      <Modal
        isOpen={displayNotes}
        onClose={() => setDisplayNotes(false)}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent borderRadius="md">
          <ModalHeader>Notes</ModalHeader>
          <ModalCloseButton />
          <ModalBody maxH="75vh" overflow="scroll" pb={4}>
            {notes && <RenderHtml html={notes} />}
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* ------ EDIT CLIP MODAL ------ */}
      {isEditable && currentVideoStep && onSaveClip && (
        <ModalVideo
          isOpen={displayEditClip}
          onClose={() => setDisplayEditClip(false)}
          onSaveSummary={async (summary: string) => {
            onSaveClip(currentVideoStep.id.toString(), { summary });
          }}
          onSaveScript={async (script: string) => {
            onSaveClip(currentVideoStep.id.toString(), { script });
          }}
          summary={videoSummary}
          script={videoScript}
          // TODO: Support image clips
          video={currentVideoStep.clipSrc}
          videoHq={currentVideoStep.clipSrcHq}
          orientation={currentVideoStep.orientation}
          autoplay={currentVideoStep.clipMediaType === 'image'}
          isEditable={true}
          onUpload={handleVideoUpload}
          onSaveClip={async (data) =>
            await onSaveClip(currentVideoStep.id.toString(), data)
          }
          mediaType={currentVideoStep.clipMediaType}
          clipId={`${currentVideoStep.id}`}
          qrBlob={qrBlob}
        />
      )}
    </>
  );
};

export default SessionStepper;
