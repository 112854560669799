import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Step,
  Steps,
  Stack,
  useSteps,
  Skeleton,
  Button,
  Text,
  ScaleFade,
  Palette,
  useTheme,
} from '@workshop/ui';
import { Transition, animated } from 'react-spring';
import Confetti from 'react-confetti';
import { useWindowDimensions } from 'utils/hooks/useDimensions';

import { PLATFORM } from 'constants/env';

import { Loading } from 'components/Loading';

interface StepsModalStep {
  label: string;
  icon: React.ReactElement | null;
  content: React.ReactElement;
  nextButtonText?: string;
  nextButtonIcon?: string;
  showSkipButton?: boolean;
  hideNextButton?: boolean;
  nextButtonDisabled?: boolean;
  nextButtonVariant?: 'outline' | 'solid' | 'ghost';
}

interface StepsModalProps {
  steps: StepsModalStep[];
  isOpen: boolean;
  onClose: () => void;
  onCompleteStep?: (stepIndex: number) => Promise<void | 'error'>;
  onSkipStep?: (stepIndex: number) => void;
  loading?: boolean;
  submitting?: boolean;
  heading: string;
  subheading?: string;
  stepSize?: string;
  modalSize?: string;
  hideStepLabels?: boolean;
  hideAllStepLabels?: boolean;
  hideStepList?: boolean;
  hideIndicators?: boolean;
  forceHorizontalSteps?: boolean;
  disableClose?: boolean;
  disablePrev?: boolean;
  showConfetti?: boolean;
  triggerNextStep?: boolean;
  bigNext?: boolean;
  verticalCenter?: boolean;
  header?: React.ReactElement | null;
  footer?: React.ReactElement | null;
  minStep?: number;
}

const StepsModal: React.FC<StepsModalProps> = ({
  steps,
  isOpen,
  onClose,
  onCompleteStep = () => null,
  onSkipStep = () => null,
  triggerNextStep,
  loading = false,
  submitting = false,
  heading,
  subheading,
  stepSize = 'sm',
  modalSize = 'lg',
  hideStepLabels = false,
  hideAllStepLabels = false,
  hideStepList = false,
  hideIndicators = false,
  forceHorizontalSteps = false,
  disableClose = false,
  disablePrev = false,
  showConfetti = false,
  bigNext = false,
  verticalCenter = true,
  header = null,
  footer = null,
  minStep,
}) => {
  const { nextStep, prevStep, activeStep, reset, setStep } = useSteps({
    initialStep: 0,
  });
  const [stepLoading, setStepLoading] = useState(false);
  const [confettiVisible, setConfettiVisible] = useState(false);

  const theme = useTheme();
  const windowDimensions = useWindowDimensions();
  const isMobile = windowDimensions.width < parseInt(theme.breakpoints.md, 10);

  useEffect(() => {
    if (minStep && activeStep <= minStep) {
      setStep(minStep);
    }
  }, [minStep, activeStep]);
  useEffect(() => {
    if (isOpen) {
      reset();
    }
  }, [isOpen]);
  useEffect(() => {
    if (triggerNextStep !== undefined) {
      nextStep();
    }
  }, [triggerNextStep]);

  const hideConfetti = () => {
    if (confettiVisible) {
      setTimeout(() => setConfettiVisible(false), 2500);
    }
  };
  useEffect(() => {
    if (showConfetti) {
      setConfettiVisible(true);
    }
    if (!showConfetti) {
      hideConfetti();
    }
  }, [showConfetti]);

  const AnimatedModalContent = animated(ModalContent);
  const AnimatedModalOverlay = animated(ModalOverlay);
  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      motionPreset="none"
      size={modalSize}
      closeOnOverlayClick={!disableClose}
      closeOnEsc={!disableClose}
    >
      <Transition
        items={isOpen}
        from={{ opacity: 0, backdropFilter: 'blur(0px)' }}
        enter={{ opacity: 1, backdropFilter: 'blur(10px)' }}
        leave={{ opacity: 0, backdropFilter: 'blur(0px)' }}
      >
        {(styles, item) => (
          <AnimatedModalOverlay backdropFilter="blur(10px)" style={styles} />
        )}
      </Transition>
      {PLATFORM === 'steppit' && confettiVisible && (
        <Box
          sx={{
            canvas: {
              zIndex: '1400!important',
              opacity: showConfetti ? 1 : 0,
              transition: 'opacity 2s',
            },
          }}
        >
          <Confetti
            width={windowDimensions.width}
            height={windowDimensions.height}
            colors={[
              Palette.blue['400'],
              Palette.blue['500'],
              Palette.blue['600'],
              Palette.green['400'],
              Palette.green['500'],
              Palette.green['600'],
              Palette.orange['200'],
              Palette.red['300'],
              Palette.neutral['10'],
            ]}
          />
        </Box>
      )}
      <Transition
        items={isOpen}
        from={{ scale: 0.98 }}
        enter={{ scale: 1 }}
        leave={{ scale: 0.98 }}
        config={{ tension: 200, friction: 8 }}
      >
        {(styles, item) => (
          <AnimatedModalContent
            marginX={2}
            marginY={verticalCenter ? '20vh' : { base: 8, md: 14 }}
            style={styles}
          >
            {header}
            {heading ? (
              <ModalHeader px={{ base: 4, md: 6 }}>
                <Text fontWeight="bold">{heading}</Text>
                {subheading ? (
                  <Text fontWeight="semibold" color="text.muted" fontSize="lg">
                    {subheading}
                  </Text>
                ) : null}
              </ModalHeader>
            ) : null}

            {!disableClose && <ModalCloseButton zIndex={1} />}
            <ModalBody paddingTop={heading ? 0 : 6} px={{ base: 4, md: 6 }}>
              {submitting ? (
                <Loading />
              ) : (
                <Skeleton
                  isLoaded={!loading}
                  sx={{
                    '.chakra-steps': {
                      display: hideStepList ? 'none' : 'flex',
                    },
                  }}
                >
                  {steps.length === 1 ? (
                    steps[0].content
                  ) : (
                    <Steps
                      size={stepSize}
                      activeStep={activeStep}
                      responsive={!forceHorizontalSteps}
                      textAlign="left"
                      justifyContent="flex-start"
                    >
                      {steps.map(({ label, icon, content }, index) => (
                        <Step
                          label={
                            hideAllStepLabels
                              ? ''
                              : !hideStepLabels
                              ? label
                              : index === activeStep
                              ? label
                              : ''
                          }
                          icon={() => icon}
                          key={label}
                        >
                          <ScaleFade in={index === activeStep}>
                            {content}
                          </ScaleFade>
                        </Step>
                      ))}
                    </Steps>
                  )}
                </Skeleton>
              )}
            </ModalBody>
            <ModalFooter flexDirection="column">
              {activeStep === steps.length ? null : (
                <>
                  {bigNext && !steps[activeStep]?.hideNextButton && (
                    <Button
                      w="100%"
                      maxWidth="370px"
                      mx="auto"
                      mb={4}
                      onClick={async () => {
                        setStepLoading(true);
                        const completeStatus = await onCompleteStep(activeStep);
                        setStepLoading(false);
                        if (completeStatus !== 'error') {
                          nextStep();
                        }
                      }}
                      isLoading={loading || stepLoading}
                      disabled={steps[activeStep]?.nextButtonDisabled}
                      icon={steps[activeStep]?.nextButtonIcon || 'ArrowForward'}
                      iconPosition="right"
                      size={isMobile ? 'md' : 'lg'}
                      variant={steps[activeStep]?.nextButtonVariant || 'solid'}
                    >
                      {steps[activeStep]?.nextButtonText
                        ? steps[activeStep].nextButtonText
                        : activeStep === steps.length - 1
                        ? 'Finish'
                        : 'Next'}
                    </Button>
                  )}
                  <Flex width="100%" justify="flex-end">
                    {!disablePrev && (
                      <Button
                        isDisabled={activeStep === 0 || loading || stepLoading}
                        mr={2}
                        onClick={prevStep}
                        size="sm"
                        secondary
                      >
                        Back
                      </Button>
                    )}
                    <Stack
                      direction="row"
                      flex={1}
                      alignItems="center"
                      justifyContent="center"
                    >
                      {!hideIndicators &&
                        steps.length > 1 &&
                        steps.map((s, idx) => (
                          <Flex
                            key={`step-modal-indicator-${idx}`}
                            height={2}
                            width={idx === activeStep ? 8 : 2}
                            bg={
                              idx > activeStep
                                ? 'background.tint2'
                                : 'common.progress'
                            }
                            borderRadius="full"
                            transition="all 0.3s"
                          />
                        ))}
                    </Stack>
                    {steps[activeStep]?.showSkipButton && (
                      <Button
                        isDisabled={activeStep === 0 || loading || stepLoading}
                        mr={2}
                        onClick={() => {
                          onSkipStep(activeStep);
                          nextStep();
                        }}
                        size="sm"
                        secondary
                      >
                        Skip
                      </Button>
                    )}
                    {!bigNext && !steps[activeStep]?.hideNextButton && (
                      <Button
                        size="sm"
                        onClick={async () => {
                          setStepLoading(true);
                          const completeStatus = await onCompleteStep(
                            activeStep
                          );
                          setStepLoading(false);
                          if (completeStatus !== 'error') {
                            nextStep();
                          }
                        }}
                        isLoading={loading || stepLoading}
                        disabled={steps[activeStep]?.nextButtonDisabled}
                        variant={
                          steps[activeStep]?.nextButtonVariant || 'solid'
                        }
                      >
                        {steps[activeStep]?.nextButtonText
                          ? steps[activeStep].nextButtonText
                          : activeStep === steps.length - 1
                          ? 'Finish'
                          : 'Next'}
                      </Button>
                    )}
                  </Flex>
                </>
              )}
              {footer}
            </ModalFooter>
          </AnimatedModalContent>
        )}
      </Transition>
    </Modal>
  );
};

export default StepsModal;
