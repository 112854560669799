import React, { FC, useState, useEffect } from 'react';
import {
  Button as BaseButton,
  ButtonProps as BaseButtonProps,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react';
import { MdIcon, Flex, Text } from '@workshop/ui';
import { useSpring, animated } from 'react-spring';

import { PLATFORM } from 'constants/env';

export type ButtonProps = BaseButtonProps & {
  icon?: string;
  iconColor?: string;
  iconPosition?: 'left' | 'right';
  secondary?: boolean;
  buttonId?: string;
};

export type ButtonRef =
  | string
  | ((instance: HTMLButtonElement | null) => void)
  | React.RefObject<HTMLButtonElement>
  | null
  | undefined;

export type ButtonPropsWithRef = ButtonProps & {
  ref?: ButtonRef;
};

const Button: FC<ButtonPropsWithRef> = ({
  children,
  icon,
  iconColor,
  iconPosition = 'left',
  secondary = false,
  buttonId,
  ...rest
}) => {
  const [isOver, setIsOver] = useState(false);

  const AnimatedBaseButton = animated(BaseButton);

  const [{ jiggle }, animateJiggle] = useSpring(() => ({
    jiggle: 0,
    config: { tension: 300, friction: 8 },
  }));

  const [{ hover }, animateHover] = useSpring(() => ({
    hover: 0,
    config: { mass: 0.5, tension: 200, friction: 4.5 },
  }));

  useEffect(() => {
    if (isOver) {
      animateJiggle({ jiggle: 1 });
      animateHover({ hover: 1 });
      const timeout = window.setTimeout(() => {
        animateJiggle({ jiggle: 0 });
        window.clearTimeout(timeout);
      }, 300);
      return () => {
        setIsOver(false);
        window.clearTimeout(timeout);
      };
    }
    if (!isOver) {
      animateHover({ hover: 0 });
    }
  }, [isOver]);

  return (
    // @ts-ignore
    <AnimatedBaseButton
      cursor="pointer"
      fontWeight="semibold"
      {...(secondary
        ? {
            colorScheme: 'common.secondaryPalette',
            borderRadius: 'lg',
            color: 'text.dark',
          }
        : {
            colorScheme: 'common.primaryPalette',
            borderRadius: PLATFORM === 'steppit' ? 'full' : 'md',
            boxShadow:
              rest.variant === 'ghost' || rest.variant === 'outline'
                ? 'none'
                : 'lg',
          })}
      onMouseEnter={() => setIsOver(true)}
      onMouseLeave={() => setIsOver(false)}
      // TODO: FIXME: analytics can't be imported here
      // onPointerUp={() => {
      //   if (buttonId || (typeof children === 'string' && children)) {
      //     analytics.track('Button Clicked', {
      //       // @ts-ignore
      //       button_id: buttonId ? buttonId : slugify(children).toLowerCase(),
      //       label: typeof children === 'string' ? children : '',
      //     });
      //   }
      // }}
      transitionProperty="background-color,border-color,color,fill,stroke,opacity,box-shadow"
      style={{
        scale: hover.to({
          range: [0, 1],
          output: [1, 1.02],
        }),
      }}
      {...rest}
    >
      <Flex
        flexDirection={iconPosition === 'left' ? 'row' : 'row-reverse'}
        justifyContent="center"
        alignItems="center"
      >
        {icon && (
          <animated.span
            style={{
              scale: jiggle.to({
                range: [0, 1],
                output: [1, 1.1],
              }),
              rotate: jiggle.to({
                range: [0, 0.55, 1],
                output: ['0deg', '2deg', '-2deg'],
              }),
            }}
          >
            <MdIcon
              mr={children && iconPosition === 'left' ? 1.5 : 0}
              ml={children && iconPosition === 'right' ? 1.5 : 0}
              name={icon}
              color={iconColor ? iconColor : secondary ? 'text.dark' : ''}
              minW={3}
            />
          </animated.span>
        )}
        {children}
      </Flex>
    </AnimatedBaseButton>
  );
};

export default Button;

interface AlertDialogButtonProps extends ButtonProps {
  onCancel: () => void;
  onSubmit?: () => void;
  alertHeader: string;
  alertBody: string | React.ReactElement;
  allowSubmit?: boolean;
  submitBtnLabel?: string;
  submitBtnColor?: string;
  cancelBtnLabel?: string;
}

export const AlertDialogButton: FC<AlertDialogButtonProps> = ({
  onCancel,
  onSubmit,
  alertHeader,
  alertBody,
  allowSubmit = true,
  submitBtnLabel = 'Submit',
  submitBtnColor = 'blue',
  cancelBtnLabel = 'Cancel',
  children,
  ...rest
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const onClose = () => setIsOpen(false);
  const cancelRef = React.useRef<HTMLButtonElement>(null);

  const handleCancel = () => {
    onCancel();
    onClose();
  };

  const handleSubmit = () => {
    onSubmit && onSubmit();
    onClose();
  };

  return (
    <>
      <Button {...rest} onClick={() => setIsOpen(true)}>
        {children}
      </Button>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay />
        <AlertDialogContent borderRadius="md">
          <AlertDialogHeader
            fontSize="lg"
            fontWeight="bold"
            paddingX={6}
            pt={6}
            pb={2}
          >
            {alertHeader}
          </AlertDialogHeader>

          <AlertDialogBody paddingX={6} pt={0} pb={6}>
            {alertBody}
          </AlertDialogBody>

          <AlertDialogFooter paddingX={6} pt={0} pb={6}>
            <Button secondary ref={cancelRef} onClick={handleCancel}>
              {cancelBtnLabel}
            </Button>
            {allowSubmit && (
              <Button
                colorScheme={submitBtnColor}
                onClick={handleSubmit}
                ml="defaultMargin"
              >
                {submitBtnLabel}
              </Button>
            )}
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

interface SwitchButtonProps extends BaseButtonProps {
  active: boolean;
}

export const SwitchButton: FC<SwitchButtonProps> = ({
  active,
  children,
  ...rest
}) => (
  <Button
    secondary
    {...(active
      ? {
          backgroundColor: 'text.dark',
          color: 'text.light',
          borderWidth: 1,
          borderColor: 'text.dark',
          _hover: { backgroundColor: 'text.dark' },
        }
      : {
          backgroundColor: 'text.light',
          color: 'text.muted',
          borderWidth: 1,
          borderColor: 'text.muted',
        })}
    {...rest}
  >
    {children}
  </Button>
);
