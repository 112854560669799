import { Action } from 'types';
import { MailAT } from 'redux/actionTypes/common';
import { MailState } from 'types/common';

const mailInitialState: MailState = {};

export const mailReducer = (
  state = mailInitialState,
  action: Action
): MailState => {
  switch (action.type) {
    case MailAT.FETCH_LIST_SUCCESS:
      return {
        ...state,
        [action.payload.course]: {
          ...action.payload,
        },
      };
    case MailAT.UPDATE_LIST_RECIPIENT_SUCCESS:
      const { course, ...newRecipient } = action.payload;
      return {
        ...state,
        [course]: {
          ...state[course],
          recipients: [
            ...state[course].recipients.map((r) => {
              if (r.id === newRecipient.id) {
                return newRecipient;
              }
              return r;
            }),
          ],
        },
      };
    default:
      return state;
  }
};
