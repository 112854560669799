import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { FaGem } from 'react-icons/fa';

import {
  ASSISTANT_TRAITS,
  ASSISTANT_TRAINING_PROGRESS,
  AssistantTraitSlug,
} from 'constants/assistant';

import { useCurrentTeam, useCurrentTeamProfile } from 'redux/selectors';
import { useWindowDimensions } from 'utils/hooks/useDimensions';

import { organisationActions } from 'redux/actions/common';

import {
  Box,
  Flex,
  Text,
  MdIcon,
  CircularProgress,
  Stack,
  Button,
  Tooltip,
  chakra,
  useTheme,
} from '@workshop/ui';
import {
  StepsModal,
  AssistantAnimation,
  ConfirmModal,
} from 'components/Common';
import { AssistantTraining } from 'components/AssistantTraining';

interface ProWelcomePopupProps {
  isOpen: boolean;
  onClose: () => void;
}

const AssistantTrainingTraits = ({
  onCompleteTraining,
  onBaseTrainingComplete,
}: {
  onCompleteTraining: () => void;
  onBaseTrainingComplete: () => void;
}) => {
  const [traitIdx, setTraitIdx] = useState(0);
  const [baseTrainingComplete, setBaseTrainingComplete] = useState(false);
  const [allTrainingComplete, setAllTrainingComplete] = useState(false);
  const traitSlugs = ASSISTANT_TRAITS.map(
    (t) => t.slug
  ) as AssistantTraitSlug[];
  const theme = useTheme();
  const windowDimensions = useWindowDimensions();
  const isMobile = windowDimensions.width < parseInt(theme.breakpoints.md, 10);

  const channel = useCurrentTeamProfile();

  useEffect(() => {
    if (traitIdx > 3) {
      setAllTrainingComplete(true);
      onCompleteTraining();
      if (baseTrainingComplete) {
        onBaseTrainingComplete();
      }
    }
  }, [traitIdx, baseTrainingComplete]);

  const assistantTraits = ASSISTANT_TRAITS.map((t) => {
    const trainingCount =
      (channel?.traits && channel.traits[t.slug]?.count) || 0;
    // @ts-ignore
    const stepsRepeat = !!t.trainingSteps.find((s) => s.repeat);
    const maxSteps = stepsRepeat
      ? Object.keys(ASSISTANT_TRAINING_PROGRESS).length
      : t.trainingSteps.length;
    const progress =
      maxSteps === Object.keys(ASSISTANT_TRAINING_PROGRESS).length
        ? // @ts-ignore
          ASSISTANT_TRAINING_PROGRESS[trainingCount] >= 0
          ? // @ts-ignore
            ASSISTANT_TRAINING_PROGRESS[trainingCount]
          : 100
        : Math.min((trainingCount / maxSteps) * 100, 100);
    return {
      ...t,
      progress,
    };
  });
  return (
    <>
      <Flex flexDirection="column" mb={allTrainingComplete ? 0 : 3}>
        <AssistantTraining
          trait={traitSlugs[traitIdx]}
          setBaseTrainingComplete={(b) => setBaseTrainingComplete(b)}
          baseTrainingComplete={baseTrainingComplete}
          onResetTraining={() => {
            setBaseTrainingComplete(false);
            setAllTrainingComplete(false);
          }}
          onClickNextTrait={
            traitIdx <= 3
              ? () => {
                  setTraitIdx(traitIdx + 1);
                  setBaseTrainingComplete(false);
                }
              : undefined
          }
        />
      </Flex>
      {!allTrainingComplete && (
        <Stack
          direction="row"
          spacing={1}
          justifyContent="center"
          // bg="background.tint1"
          alignSelf="center"
          p={2}
          borderRadius="full"
          borderWidth={2}
          borderColor="border.muted"
          mt={3}
        >
          {assistantTraits.map((t) => (
            <Flex
              key={`trait-progress-${t.slug}`}
              // @ts-ignore
              {...(t.progress > 0 || traitSlugs.indexOf(t.slug) <= traitIdx
                ? {
                    cursor: 'pointer',
                    // @ts-ignore
                    onClick: () => setTraitIdx(traitSlugs.indexOf(t.slug)),
                    _hover: { opacity: 0.6 },
                  }
                : {})}
            >
              <CircularProgress
                value={t.progress}
                // @ts-ignore
                size={4}
                thickness="20px"
                color={
                  // @ts-ignore
                  t.progress > 0 || traitSlugs.indexOf(t.slug) <= traitIdx
                    ? `${t.colorScheme}.400`
                    : 'text.muted'
                }
                trackColor={
                  // @ts-ignore
                  t.progress > 0 || traitSlugs.indexOf(t.slug) <= traitIdx
                    ? `${t.colorScheme}.50`
                    : 'background.tint2'
                }
                // capIsRound
                sx={{
                  '& > div:first-child': {
                    transitionProperty: 'width',
                  },
                }}
              />
            </Flex>
          ))}
        </Stack>
      )}
    </>
  );
};

export const ProWelcomePopup: React.FC<ProWelcomePopupProps> = ({
  isOpen,
  onClose,
}) => {
  const [showConfetti, setShowConfetti] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [showTraits, setShowTraits] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showCompleteTrainingBtn, setShowCompleteTrainingBtn] = useState(false);
  const [highlightCompleteTrainingBtn, setHighlightCompleteTrainingBtn] =
    useState(false);

  const currentTeam = useCurrentTeam();
  const channel = useCurrentTeamProfile();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isOpen) {
      setCurrentStep(0);
    }
    if (isOpen && currentTeam) {
      dispatch(organisationActions.fetchOrganisationTraining(currentTeam));
    }
  }, [isOpen, currentTeam]);

  const titleProps = {
    fontWeight: 'extrabold',
    fontSize: { base: '3xl', md: '4xl' },
    lineHeight: 1.2,
  };

  const assistantTraits = ASSISTANT_TRAITS.map((trait) => {
    const trainingCount =
      (channel?.traits && channel.traits[trait.slug]?.count) || 0;
    // @ts-ignore
    const stepsRepeat = !!trait.trainingSteps.find((s) => s.repeat);
    const maxSteps = stepsRepeat
      ? Object.keys(ASSISTANT_TRAINING_PROGRESS).length
      : trait.trainingSteps.length;
    const progress =
      maxSteps === Object.keys(ASSISTANT_TRAINING_PROGRESS).length
        ? // @ts-ignore
          ASSISTANT_TRAINING_PROGRESS[trainingCount] >= 0
          ? // @ts-ignore
            ASSISTANT_TRAINING_PROGRESS[trainingCount]
          : 100
        : Math.min((trainingCount / maxSteps) * 100, 100);
    return {
      ...trait,
      progress,
    };
  });

  const steps = [
    {
      label: 'Welcome',
      icon: <MdIcon name="TrendingUp" />,
      content: (
        <Flex
          py={4}
          flexDirection="column"
          alignItems="center"
          textAlign="center"
          mb={3}
        >
          <Flex
            boxSize="image.lg"
            alignItems="center"
            justifyContent="center"
            zIndex={1}
            borderRadius="full"
          >
            <Flex
              boxSize="image.md"
              alignItems="center"
              justifyContent="center"
              borderRadius="full"
              bg="background.warning"
              color="orange.500"
              fontSize="4xl"
            >
              <FaGem />
            </Flex>
          </Flex>

          <Text {...titleProps} mb={4}>
            Welcome to Pro
          </Text>

          <Text
            color="text.muted"
            fontSize="lg"
            mb={6}
            whiteSpace="break-spaces"
          >
            With your Pro channel, you can now extend your courses, expand your
            team and run private classes.
          </Text>
          <Text color="text.muted" fontSize="lg" whiteSpace="break-spaces">
            First though, let's meet someone who's here to help you through your
            Steppit journey...
          </Text>
        </Flex>
      ),
    },
    {
      label: 'Assistant',
      icon: <MdIcon name="TrendingUp" />,
      content: (
        <Flex
          py={4}
          flexDirection="column"
          alignItems="center"
          textAlign="center"
          mb={3}
        >
          <Flex
            boxSize="image.lg"
            alignItems="center"
            justifyContent="center"
            zIndex={1}
            mb={8}
            borderRadius="full"
          >
            <AssistantAnimation boxSize={120} />
          </Flex>

          <Text {...titleProps} mb={2}>
            Meet your new assistant
          </Text>

          <Text color="text.muted" fontSize="lg" whiteSpace="break-spaces">
            Your AI-powered assistant is here to clear away all the obstacles
            you face while creating, selling and running your courses.
          </Text>
        </Flex>
      ),
    },
    {
      label: 'Tailored',
      icon: <MdIcon name="TrendingUp" />,
      content: (
        <Flex
          py={4}
          flexDirection="column"
          alignItems="center"
          textAlign="center"
          mb={3}
        >
          <Flex
            boxSize="image.lg"
            alignItems="center"
            justifyContent="center"
            zIndex={1}
            mb={8}
            borderRadius="full"
          >
            <Flex position="relative">
              <Flex
                position="absolute"
                w="100%"
                h="100%"
                transform={`scale(${showTraits ? 1 : 0})`}
                transition="transform 0.6s"
              >
                {ASSISTANT_TRAITS.map((t, idx) => (
                  <Flex
                    key={`star-assistant-trait-${t.slug}`}
                    position="absolute"
                    transform="translate(-50%, -50%)"
                    top={
                      idx === 0
                        ? 0
                        : idx === 1
                        ? '40%'
                        : idx === 2
                        ? '90%'
                        : idx === 3
                        ? '90%'
                        : '40%'
                    }
                    left={
                      idx === 0
                        ? '50%'
                        : idx === 1
                        ? '100%'
                        : idx === 2
                        ? '80%'
                        : idx === 3
                        ? '20%'
                        : '0%'
                    }
                  >
                    <Tooltip label={t.label}>
                      <Flex
                        position="relative"
                        alignItems="center"
                        justifyContent="center"
                      >
                        {/* <CircularProgress
                      value={t.progress}
                      // @ts-ignore
                      size={{ base: 10, md: 16 }}
                      thickness="8px"
                      color={`${t.colorScheme}.400`}
                      trackColor="background.tint2"
                      capIsRound
                      sx={{
                        '& > div:first-child': {
                          transitionProperty: 'width',
                        },
                      }}
                    /> */}

                        <Flex
                          // position="absolute"
                          bg={`${t.colorScheme}.100`}
                          alignItems="center"
                          justifyContent="center"
                          borderRadius="full"
                          boxSize={8}
                        >
                          <MdIcon
                            name={t.icon}
                            color={`${t.colorScheme}.500`}
                            boxSize={5}
                          />
                        </Flex>
                      </Flex>
                    </Tooltip>
                  </Flex>
                ))}
              </Flex>
              <Box
                transition="transform 0.6s"
                transform={`scale(${showTraits ? 0.85 : 1})`}
              >
                <AssistantAnimation boxSize={120} />
              </Box>
            </Flex>
          </Flex>

          <Text {...titleProps} mb={2}>
            Tailored to you
          </Text>

          <Text
            color="text.muted"
            fontSize="lg"
            whiteSpace="break-spaces"
            mb={6}
          >
            With 5 trainable traits, you can give your assistant a deep
            understanding how it can help you.
          </Text>
          <Text color="text.muted" fontSize="lg" whiteSpace="break-spaces">
            The more you train your assistant, the easier you'll find your
            journey as a course creator.
          </Text>
        </Flex>
      ),
    },
    {
      label: 'Train',
      icon: <MdIcon name="TrendingUp" />,
      nextButtonText: 'Train Now',
      content: (
        <Flex
          py={4}
          flexDirection="column"
          alignItems="center"
          textAlign="center"
          mb={3}
        >
          <Flex
            boxSize="image.lg"
            alignItems="center"
            justifyContent="center"
            zIndex={1}
            borderRadius="full"
          >
            <Flex
              boxSize="image.md"
              alignItems="center"
              justifyContent="center"
              borderRadius="full"
              // bg="background.primary"
              color="common.primary"
              fontSize="6xl"
            >
              <MdIcon name="ModelTraining" />
            </Flex>
          </Flex>

          <Text {...titleProps} mb={4}>
            Let’s get training
          </Text>
          <Flex
            alignItems="center"
            justifyContent="center"
            zIndex={1}
            mt={4}
            mb={8}
          >
            <Stack direction="row">
              {assistantTraits.map((t, idx) => (
                <Tooltip
                  key={`progress-assistant-trait-${t.slug}`}
                  label={t.label}
                >
                  <Flex
                    position="relative"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <CircularProgress
                      // @ts-ignore
                      value={t.progress}
                      // value={0}
                      // @ts-ignore
                      size={{ base: 10, md: 16 }}
                      thickness="8px"
                      color={`${t.colorScheme}.400`}
                      trackColor="background.tint2"
                      capIsRound
                      sx={{
                        '& > div:first-child': {
                          transitionProperty: 'width',
                        },
                      }}
                    />

                    <Flex
                      position="absolute"
                      bg={`${t.colorScheme}.100`}
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="full"
                      boxSize={{ base: 6, md: 10 }}
                    >
                      <MdIcon
                        name={t.icon}
                        color={`${t.colorScheme}.500`}
                        boxSize={{ base: 4, md: 6 }}
                      />
                    </Flex>
                  </Flex>
                </Tooltip>
              ))}
            </Stack>
          </Flex>

          <Text color="text.muted" fontSize="lg" whiteSpace="break-spaces">
            We’ll start by giving your assistant a basic idea of how it can help
            you. Over time, you can come back and train your assistant to give
            it the full picture.
          </Text>
        </Flex>
      ),
    },
    {
      label: 'Traits',
      icon: <MdIcon name="TrendingUp" />,
      hideNextButton: !showCompleteTrainingBtn,
      nextButtonVariant: highlightCompleteTrainingBtn ? 'solid' : 'outline',
      nextButtonText: 'Finish Training',
      content: (
        <Flex flexDirection="column">
          <AssistantTrainingTraits
            onCompleteTraining={() => setShowCompleteTrainingBtn(true)}
            onBaseTrainingComplete={() => setHighlightCompleteTrainingBtn(true)}
          />
        </Flex>
      ),
    },
    {
      label: 'Congrats',
      icon: <MdIcon name="TrendingUp" />,
      nextButtonText: "Let's Go",
      content: (
        <Flex
          py={4}
          flexDirection="column"
          alignItems="center"
          textAlign="center"
          mb={3}
        >
          <Flex
            boxSize="image.lg"
            alignItems="center"
            justifyContent="center"
            zIndex={1}
            borderRadius="full"
          >
            <Text fontSize="6xl">👏</Text>
          </Flex>

          <Text {...titleProps} mb={4}>
            Great work!
          </Text>
          <Flex
            alignItems="center"
            justifyContent="center"
            zIndex={1}
            mt={4}
            mb={8}
          >
            <Stack direction="row">
              {assistantTraits.map((t, idx) => (
                <Tooltip
                  key={`progress-assistant-trait-${t.slug}`}
                  label={t.label}
                >
                  <Flex
                    position="relative"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <CircularProgress
                      value={t.progress}
                      // @ts-ignore
                      size={{ base: 10, md: 16 }}
                      thickness="8px"
                      color={`${t.colorScheme}.400`}
                      trackColor="background.tint2"
                      capIsRound
                      sx={{
                        '& > div:first-child': {
                          transitionProperty: 'width',
                        },
                      }}
                    />

                    <Flex
                      position="absolute"
                      bg={`${t.colorScheme}.100`}
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="full"
                      boxSize={{ base: 6, md: 10 }}
                    >
                      <MdIcon
                        name={t.icon}
                        color={`${t.colorScheme}.500`}
                        boxSize={{ base: 4, md: 6 }}
                      />
                    </Flex>
                  </Flex>
                </Tooltip>
              ))}
            </Stack>
          </Flex>

          <Text
            color="text.muted"
            fontSize="lg"
            whiteSpace="break-spaces"
            mb={6}
          >
            You've given your assistant a good understanding of how it can help
            you. Remember that the more time you invest in training, the more
            helpful your assistant will be.
          </Text>
          <Text color="text.muted" fontSize="lg" whiteSpace="break-spaces">
            Now, let’s put that training into practice…
          </Text>
        </Flex>
      ),
    },
  ];
  const finalStepIndex = 5;

  return (
    <>
      <StepsModal
        heading=""
        isOpen={isOpen}
        onClose={onClose}
        onCompleteStep={async (stepIndex: number) => {
          setCurrentStep(stepIndex + 1);
          if (stepIndex === finalStepIndex) {
            onClose();
          }
          if (stepIndex === 1) {
            setTimeout(() => setShowTraits(true), 300);
          } else if (showTraits) {
            setShowTraits(false);
          }
          if (stepIndex >= 0 && showConfetti) {
            setShowConfetti(false);
          }
        }}
        // @ts-ignore
        steps={isOpen ? steps : []}
        forceHorizontalSteps
        hideStepLabels
        hideAllStepLabels
        hideStepList
        hideIndicators={currentStep === 3 || currentStep === 4}
        bigNext
        disablePrev
        verticalCenter={false}
        showConfetti={showConfetti}
        modalSize="xl"
        disableClose
        footer={
          currentStep === 3 ? (
            <Flex>
              <Button variant="ghost" onClick={() => setShowConfirmModal(true)}>
                Skip training for now
              </Button>
            </Flex>
          ) : null
        }
      />
      <ConfirmModal
        body={
          <>
            To continue training your assistant, head to the{' '}
            <chakra.span display="inline-flex" fontWeight="semibold">
              <Flex alignItems="center" h="1.1rem">
                <MdIcon name="AutoAwesome" />
              </Flex>
              <Text ml={1.5} h="1.1rem">
                Assistant
              </Text>
            </chakra.span>{' '}
            tab in your sidebar.
          </>
        }
        helpText="To get the most out of your assistant, we recommend training every trait to at least 50%."
        btnColor="blue"
        btnLabel="Yes, Skip Training"
        title="Are you sure?"
        isOpen={showConfirmModal}
        isLoading={false}
        onClose={() => setShowConfirmModal(false)}
        onClick={() => {
          setShowConfirmModal(false);
          onClose();
        }}
      />
    </>
  );
};
