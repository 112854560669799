import React, { useEffect, useState } from 'react';
import { useLocation, useHistory, RouteComponentProps } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { analytics, hooks, getParamFromUrl } from 'utils';

import {
  Box,
  Flex,
  Text,
  Button,
  LinkButton,
  Card,
  chakra,
} from '@workshop/ui';

import { SignupData } from 'types/common';
import { GlobalState } from 'types';

import {
  useIsAuthenticated,
  useCurrentTeam,
  useCurrentTeamProfile,
  useTeams,
  useTeamProfiles,
  useUser,
} from 'redux/selectors';
import {
  profileActions,
  organisationActions,
  authActions,
} from 'redux/actions/common';

import navRoutes from 'navigation/Routes';

import { PLATFORM } from 'constants/env';

import { BrandLg } from 'components/Brand';
import { Loading } from 'components/Loading';
import { LabelSelect } from 'components/Common';

import { SignupForm } from 'screens/common/Signup/SignupForm';
import { LoginForm } from 'screens/common/Login/LoginForm';

interface MatchParams {
  licenseSlug: string;
}

interface Props extends RouteComponentProps<MatchParams> {}

const RedeemLicenseScreen: React.FC<Props> = ({ match: { params } }) => {
  const { licenseSlug } = params;

  const [isSigningUp, setIsSigningUp] = useState(false);
  const [isCreatingChannel, setIsCreatingChannel] = useState(false);
  const [isRedeemingLicense, setIsRedeemingLicense] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const currentTeam = useCurrentTeam();
  const userTeams = useTeams();
  const teamProfiles = useTeamProfiles();
  const location = useLocation();
  const dispatch = useDispatch();
  const hasAccount = useIsAuthenticated();
  const user = useUser();

  const history = useHistory();

  const license = useSelector(
    (state: GlobalState) => state.organisation.membershipLicenses[licenseSlug]
  );
  const orgUiLoading = useSelector(
    (state: GlobalState) => state.ui.organisation.organisation.loading
  );
  const licenseUiLoading = useSelector(
    (state: GlobalState) => state.ui.organisation.license.loading
  );

  const [view, setView] = useState<'signup' | 'login' | 'redeem'>(
    hasAccount ? 'redeem' : 'signup'
  );

  useEffect(() => {
    if (hasAccount) {
      setView('redeem');
    }
  }, [hasAccount]);

  const teamOptions = userTeams.reduce((acc, c) => {
    const teamProfile = teamProfiles[c.team];
    if (teamProfile) {
      return {
        ...acc,
        [c.team]: `${teamProfile?.name}`,
      };
    }
    return acc;
  }, {} as { [key: string]: string });

  const sourceParam = getParamFromUrl(location, 'source');

  const { license: licenseLoading } = hooks.useLoadingDataState(
    {
      license: {
        actions: [
          () => organisationActions.retrieveMembershipLicense(licenseSlug),
        ],
      },
    },
    [licenseSlug]
  );

  // const { organisationProfile: organisationLoading } =
  //   hooks.useLoadingDataState(
  //     {
  //       organisationProfile: {
  //         actions: currentTeam
  //           ? [() => organisationActions.fetchOrganisationProfile(currentTeam)]
  //           : [],
  //       },
  //     },
  //     [currentTeam]
  //   );

  const redeem = async (teamId?: number, persona?: string) => {
    setIsRedeemingLicense(true);
    if (teamId) {
      const res = await dispatch(
        organisationActions.redeemMembershipLicense({
          slug: licenseSlug,
          teamId,
        })
      );
      if (res && res.error && res.payload) {
        // @ts-ignore
        setErrorMessage(res.payload.message);
      }
      await dispatch(
        organisationActions.retrieveMembershipLicense(licenseSlug)
      );
      analytics.track('Membership License Redeemed', {
        persona: persona || 'general',
      });
    }
    setIsRedeemingLicense(false);
  };

  const createOrg = async (fName?: string, persona?: string) => {
    setIsCreatingChannel(true);
    // Create channel
    const response = await dispatch(
      organisationActions.createOrganisation({
        name: `${
          !fName
            ? `My`
            : fName.charAt(fName.length - 1) === 's'
            ? `${fName}'`
            : `${fName}'s`
        } Channel`,
        persona,
      })
    );
    if (response.error) {
      setIsCreatingChannel(false);
      return;
    }
    setIsCreatingChannel(true);
    const teamsRes = await dispatch(organisationActions.fetchMyTeams());
    const teams =
      teamsRes?.payload &&
      'results' in teamsRes.payload &&
      teamsRes.payload.results;
    const newTeam =
      teams && teams.length > 0
        ? [...teams].sort((a, b) => b.team - a.team)[0].team
        : null;
    if (newTeam) {
      // @ts-ignore
      dispatch(organisationActions.setCurrentTeam(newTeam));
      localStorage.setItem('defaultTeam', newTeam.toString());
    }
    analytics.track('Channel Created', {
      ...(response.payload && 'id' in response.payload
        ? { org_id: `${response.payload.id}` }
        : {}),
    });
    analytics.logConversion(dispatch, 'createdChannel');
    setIsRedeemingLicense(true);
    setIsCreatingChannel(false);
    return newTeam;
  };

  const signUp = async ({
    email,
    marketingConsent,
    name,
    password1,
    persona,
  }: SignupData) => {
    setIsSigningUp(true);
    // Sign up user
    const response = await dispatch(
      authActions.signupRequest({
        email,
        marketingConsent,
        name,
        password1,
      })
    );
    if (response.error) {
      if (
        // @ts-ignore
        response.payload?.error?.details &&
        // @ts-ignore
        response.payload.error.details[0]?.message ===
          'A user is already registered with this e-mail address.'
      ) {
        setView('login');
        setErrorMessage(
          'Looks like you already have an account! You can log in here.'
        );
        dispatch(authActions.clearAuthError);
      }
      setIsSigningUp(false);
      return;
    }
    await dispatch(
      authActions.tokenRequest({ username: email, password: password1 })
    );
    const fName = name.split(' ')[0];
    const lName =
      name.split(' ').length > 1 ? name.replace(`${fName} `, '') : '';
    analytics.identify({
      set: {
        first_name: fName,
        last_name: lName,
        email: email,
        marketing_consent: Boolean(marketingConsent),
      },
    });
    analytics.track('Signed Up', {
      first_name: fName,
      last_name: lName,
      marketing_consent: Boolean(marketingConsent),
      email: email,
      type: 'redeem-license',
    });
    analytics.logConversion(dispatch, 'signedUp');
    const team = await createOrg(fName, persona);
    // @ts-ignore
    await redeem(team, persona);
    setIsSigningUp(false);
  };

  const onLoginSuccess = async () => {
    setIsSigningUp(true);
    const teamsRes = await dispatch(organisationActions.fetchMyTeams());
    const teams =
      teamsRes?.payload &&
      'results' in teamsRes.payload &&
      teamsRes.payload.results;
    const newestTeam =
      teams && teams.length > 0
        ? [...teams].sort((a, b) => b.team - a.team)[0].team
        : null;
    if (newestTeam) {
      // @ts-ignore
      dispatch(organisationActions.setCurrentTeam(newestTeam));
      localStorage.setItem('defaultTeam', newestTeam.toString());
    }
    if (teams && teams.length === 0) {
      // If no channel, create one based on first name
      const userProfileRes = await dispatch(profileActions.fetchUserProfile());
      // @ts-ignore
      const { entities, result } = userProfileRes;
      const userDetails = entities?.userDetails && entities.userDetails[result];
      const fName = userDetails?.name?.split(' ')[0] || '';
      const team = await createOrg(fName, 'general');
      // @ts-ignore
      await redeem(team || currentTeam, 'general');
      setIsSigningUp(false);
    } else if (teams && teams.length === 1) {
      // If one channel, use that to redeem
      let persona = 'general';
      if (newestTeam || currentTeam) {
        // @ts-ignore
        const teamProfile = teamProfiles[newestTeam || currentTeam];
        if (teamProfile) {
          persona = teamProfile.persona;
        }
      }
      // @ts-ignore
      await redeem(newestTeam || currentTeam, persona || 'general');
      setIsSigningUp(false);
    } else {
      // If more than one channel, select which to redeem on
      setView('redeem');
      setIsSigningUp(false);
    }
  };

  const userEmail = user?.email;
  const activationEmail = license?.activationEmail;

  const isLoading =
    licenseLoading ||
    // organisationLoading ||
    isSigningUp ||
    isCreatingChannel ||
    isRedeemingLicense ||
    orgUiLoading ||
    licenseUiLoading;

  if (PLATFORM !== 'steppit') {
    history.push(navRoutes.public.login.path());
    return null;
  }

  return (
    <Flex
      position="absolute"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width="100vw"
      minHeight="100vh"
      py={12}
      backgroundColor="background.tint3"
    >
      {isLoading ? (
        <>
          <Loading />
          <Text
            mt={6}
            color="common.primary"
            fontSize="lg"
            fontWeight="semibold"
          >
            {isRedeemingLicense || licenseUiLoading
              ? 'Redeeming your license...'
              : isCreatingChannel || orgUiLoading
              ? 'Redeeming your license...'
              : isSigningUp
              ? 'Setting up your account...'
              : ''}
          </Text>
        </>
      ) : license?.isRedeemed ? (
        <>
          <Text
            color="common.primary"
            fontSize="lg"
            fontWeight="semibold"
            textAlign="center"
            px={4}
          >
            {userEmail === activationEmail
              ? 'You have successfully redeemed this license 🙌'
              : 'This license has been redeemed'}
          </Text>
          {userEmail !== activationEmail && (
            <Text color="text.muted" mt={2} textAlign="center" px={4}>
              {'If you think this is a mistake, please '}
              <chakra.a
                href="mailto:support@steppit.com"
                color="common.primary"
                fontWeight="bold"
                textDecoration="underline"
              >
                contact us
              </chakra.a>
            </Text>
          )}
          <Button
            icon="ArrowForward"
            iconPosition="right"
            onClick={() => {
              history.push(navRoutes.common.home.path());
              window.location.reload();
            }}
            mt={6}
          >
            Open Homepage
          </Button>
        </>
      ) : (
        <>
          <Link to="/">
            <BrandLg mb={10} />
          </Link>
          {errorMessage ? (
            <Box bg="background.error" px={4} py={2} borderRadius="md" mb={6}>
              <Text color="text.error" textAlign="center">
                {errorMessage}
              </Text>
            </Box>
          ) : null}
          <Card
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            maxWidth={600}
            width="100%"
            padding={{ base: 6, sm: 10 }}
          >
            <Flex
              boxSize="image.lg"
              alignItems="center"
              justifyContent="center"
              zIndex={1}
              borderRadius="full"
            >
              <Text fontSize="6xl">🫶</Text>
            </Flex>

            <Text
              fontWeight="extrabold"
              fontSize={{ base: '3xl', md: '4xl' }}
              lineHeight="1.2"
              mb={6}
              textAlign="center"
              px={{ base: 4, md: 0 }}
            >
              Welcome to Steppit
            </Text>
            <Box mb={8}>
              <Text color="text.muted" textAlign="center" fontSize="lg">
                {`${
                  sourceParam === 'appsumo' ? 'Hey Sumo-ling, w' : 'W'
                }e're so happy you're here! Let's redeem that license and get
                your channel up and running...`}
              </Text>
            </Box>
            {view === 'signup' ? (
              <>
                <SignupForm
                  buttonLabel="Sign Up & Redeem"
                  buttonIcon="ArrowForward"
                  onSubmit={signUp}
                  hideSocialButtons
                  email={activationEmail}
                  emailBold
                  namePlaceholder="Full Name"
                  pwPlaceholder="Choose a Password"
                  bigButton
                  withPersona
                />
                <Flex
                  mt={3}
                  alignItems="center"
                  justifyContent="center"
                  color="text.muted"
                >
                  <Text mr={1} fontSize="sm">
                    Already have an account?
                  </Text>
                  <Button
                    size="sm"
                    variant="ghost"
                    colorScheme="gray"
                    onClick={() => setView('login')}
                  >
                    Log In
                  </Button>
                </Flex>
              </>
            ) : view === 'login' ? (
              <>
                <LoginForm
                  email={activationEmail}
                  emailBold
                  isLoading={isLoading}
                  onLoginSuccess={onLoginSuccess}
                  hideSocialButtons
                  bigButton
                  buttonIcon="ArrowForward"
                  buttonLabel="Log In & Redeem"
                />
                <Flex
                  mt={3}
                  alignItems="center"
                  justifyContent="center"
                  color="text.muted"
                >
                  <Text mr={1} fontSize="sm">
                    Don't have an account?
                  </Text>
                  <Button
                    size="sm"
                    variant="ghost"
                    colorScheme="gray"
                    onClick={() => setView('signup')}
                  >
                    Sign Up
                  </Button>
                </Flex>
              </>
            ) : (
              <>
                <Text fontSize="lg" color="text.muted" textAlign="center">
                  Logged in as
                </Text>
                <Text fontWeight="bold" fontSize="lg" textAlign="center">
                  {userEmail}
                </Text>
                {userEmail !== activationEmail ? (
                  <>
                    <Text
                      fontSize={{ base: 'md', md: 'lg' }}
                      color="text.muted"
                      textAlign="center"
                      px={{ base: 4, md: 0 }}
                      mt={8}
                    >
                      {`This license was purchased by `}
                      <chakra.b>{`${activationEmail}`}</chakra.b>
                      {`. To redeem this license, please switch accounts or sign up for a new account using this email address.`}
                    </Text>
                    {sourceParam === 'appsumo' ? (
                      <Text
                        fontSize="md"
                        color="text.muted"
                        textAlign="center"
                        px={{ base: 4, md: 0 }}
                        mt={6}
                      >
                        {`If your license email is incorrect, please reach out to `}
                        <chakra.a
                          href="https://help.appsumo.com/article/15-contact-appsumo"
                          target="_blank"
                          rel="noopener noreferrer"
                          color="text.info"
                          fontWeight="bold"
                        >{`AppSumo's customer support team.`}</chakra.a>
                      </Text>
                    ) : null}
                    <Button
                      mt={6}
                      onClick={async () => {
                        await dispatch(authActions.tokenDeleteRequest());
                        analytics.reset();
                      }}
                    >
                      Log Out
                    </Button>
                  </>
                ) : (
                  <>
                    {Object.keys(teamOptions).length > 0 ? (
                      <Flex
                        mt={6}
                        bg="background.tint3"
                        borderRadius="md"
                        p={2}
                        pb={0}
                        w="100%"
                      >
                        <LabelSelect
                          isDisabled={false}
                          label="Selected Channel:"
                          onChange={(e) => {
                            dispatch(
                              organisationActions.setCurrentTeam(
                                parseInt(e.target.value)
                              )
                            );
                            localStorage.setItem('defaultTeam', e.target.value);
                          }}
                          value={currentTeam || undefined}
                          options={teamOptions}
                          labelPosition="top"
                          textAlign="center"
                          labelStyleProps={{
                            textAlign: 'center',
                            fontSize: 'sm',
                          }}
                        />
                      </Flex>
                    ) : null}
                    <Button
                      onClick={() =>
                        redeem(
                          // @ts-ignore
                          currentTeam,
                          currentTeam
                            ? // @ts-ignore
                              teamProfiles[currentTeam]?.persona || 'general'
                            : 'general'
                        )
                      }
                      icon="ArrowForward"
                      iconPosition="right"
                      size="lg"
                      mb={{ base: 2, sm: 0 }}
                      mt={8}
                    >
                      Redeem License
                    </Button>
                  </>
                )}
              </>
            )}
          </Card>
        </>
      )}
    </Flex>
  );
};

export default RedeemLicenseScreen;
