import React, { useState } from 'react';
import groupBy from 'lodash/groupBy';
import moize from 'moize';
import moment from 'moment';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import sanitizeHtml from 'sanitize-html';

import { Box, Flex, Skeleton, Text } from '@workshop/ui';

import { GlobalState } from 'types';
import { Assessment, JournalEntry } from 'types/cms';

import { hooks } from 'utils';
import { getCohortSubcategoryId } from 'utils/learner';

import { assessmentActions, courseActions } from 'redux/actions/cms';
import { discourseActions } from 'redux/actions/common';
import { getManagedCohorts } from 'redux/selectors/course';

import { SectionTitle } from 'components/Common';
import {
  CohortCard,
  CohortMember,
  UserAssessments,
  UserAssessmentsModal,
  UserActivityModal,
} from 'screens/cms/ManageCohorts/src';
import { ClassesMenu } from 'components/SideMenu';
import { ScreenWrapper } from 'screens/common/ScreenWrapper';

interface GetUserAssessmentsParams {
  assessments: Assessment[];
  user: string;
  cohortId: number;
  journalEntries: JournalEntry[];
}

const getJournalEntriesForCohort = moize(
  (journalEntries: JournalEntry[], cohort: number) =>
    journalEntries.filter((entry) => entry.cohort === cohort)
);

const getJournalEntriesForUser = moize(
  (journalEntries: JournalEntry[], user: string) =>
    journalEntries.filter((entry) => entry.username === user)
);

const getUserAssessments = ({
  assessments,
  user,
  cohortId,
  journalEntries,
}: GetUserAssessmentsParams): UserAssessments[] =>
  assessments
    .map((a) => ({
      ...a,
      journalEntries: journalEntries.filter(
        (entry) =>
          entry.cohort === a.cohort &&
          entry.username === user &&
          entry.assessment === a.id &&
          entry.imageMobile
      ),
    }))
    .filter(
      (assessment) =>
        assessment.cohort === cohortId &&
        assessment.username === user &&
        assessment.journalEntries.length
    );

// TODO: In addition to this we need to calculate the 'statusDescription' e.g.
// - 'Discussion now open'
// - 'Now on Unit 4'
// - 'Course complete' etc - see designs
const getCohortStatus = (startDate: string, endDate: string) => {
  if (moment(startDate).isAfter(moment())) return 'upcoming' as const;

  if (moment(endDate).isBefore(moment())) return 'expired' as const;

  return 'active' as const;
};

type PropsFromRedux = ConnectedProps<typeof connector>;

interface ManageCohortsProps extends PropsFromRedux, RouteComponentProps {}

const ManageCohorts: React.FC<ManageCohortsProps> = ({
  assessments,
  cohorts: allCohorts,
  discourseMembers,
  discourseUserSummaries,
  discourseMessages,
  unitSummaries,
  sessionSummary,
  journalEntries,
  currentUserName,
  courses,
}) => {
  const dispatch = useDispatch();
  const [loadedCohort, setLoadedCohort] = useState<string[]>([]);
  // const [loadedMembers, setLoadedMembers] = useState<string[]>([]);
  const [assessmentModalData, setAssessmentModalData] = useState<{
    username: string;
    cohort: number;
  } | null>(null);
  const [activityModalData, setActivityModalData] = useState<{
    cohortId: number;
    username?: string;
    category: number;
    subcategory: number;
  } | null>(null);
  const [assessmentsLoading, setAssessmentsLoading] = useState(false);

  const { cohorts: cohortsLoading, discourse: discourseLoading } =
    hooks.useLoadingDataState(
      {
        cohorts: {
          actions: [
            () => courseActions.fetchMyCohorts({ fetchNextPage: true }),
          ],
        },
        discourse: {
          actions: [() => discourseActions.getPrivateMessages()],
        },
      },
      []
    );

  const currentRoute = hooks.useCurrentRoute();
  const isApp = currentRoute?.isApp;

  const loadAssessmentsForCohort = async (cohortId: number) => {
    setAssessmentsLoading(true);
    await dispatch(assessmentActions.fetchForCohort(cohortId));
    setAssessmentsLoading(false);
  };

  const cohortList = allCohorts.filter((c) => !c.isAnonymous);
  const groupedCohorts = groupBy(cohortList, 'course');
  const discourseMembersList = Object.values(discourseMembers);

  /**
   * Map the cohort participants to the Discourse Group Members
   * to enhance the student data we can show.
   */
  const cohortMembers = cohortList.reduce(
    (cohorts: { [key: number]: { [key: string]: CohortMember } }, cohort) => ({
      ...cohorts,
      [cohort.id]: cohort.participants.reduce(
        (participants: { [key: string]: CohortMember }, participant, idx) => {
          // Memoized list of journal entries for this cohort
          const cohortJournalEntries = getJournalEntriesForCohort(
            journalEntries,
            cohort.id
          );
          // Memoized list of journal entries for this student in this cohort
          const memberJournalEntries = getJournalEntriesForUser(
            cohortJournalEntries,
            participant
          );
          // Extract user assessments
          const userAssessments = getUserAssessments({
            assessments,
            user: participant,
            cohortId: cohort.id,
            journalEntries: memberJournalEntries,
          });

          // TODO: Sometimes the user might not be in the discourse cohort - i.e.
          // if syncing between django & discourse has failed (unlikely). How can
          // we handle this and highlight it to the mentor?
          const discourseMember = discourseMembersList.find(
            (member) => member.username === participant
          );

          // TODO: Ensure that user summaries are reliable for non-admin users ("top"
          // sections can currently come back empty when logged in as cohort mentor)

          // Here we pull out key statistics on the user from their Discourse
          // User Summary.
          // const userSummary = discourseUserSummaries[participant];

          // In the User Summary, find the category related to this cohort.
          // (Workshop Cohort <-> Discourse Category)
          // const userTopCategory = userSummary?.topCategories.find(
          //   ({ id }) => id === cohort.discourseCategoryId
          // );

          // const postsTotal = userTopCategory?.topicCount || 0;

          const discourseDataLoaded = loadedCohort.includes(
            cohort.discourseGroupName
          );

          // There should be just one topic for each private message thread between
          // 2 users – this topic is created when the first message is sent from one
          // user to the other
          const privateMessageTopicId = Object.values(discourseMessages).find(
            (m) =>
              // The first participant object in the array of topic participants is
              // always going to contain details of the user who the private message
              // is with
              m.participants &&
              m.participants[0]?.userId === discourseMember?.id
          )?.id;

          return {
            ...participants,
            [participant]: {
              /**
               * TODO : Fix this - the id is currently being passed to the UserAvatar component
               * in CohortCard, therefore needs to be a unique number (i.e, can't use strings)
               * suggestion : maybe return the user id when fetching the list of participants
               */
              id: discourseMember?.id || idx,
              cohort: cohort,
              assessments: userAssessments,
              assessmentCount: userAssessments.length,
              journalEntries: memberJournalEntries,
              avatar: discourseMember?.avatarTemplate,
              isLoading: !discourseDataLoaded, // || !loadedMembers.includes(participant),
              name: discourseMember?.name || participant,
              // postsTotal,
              username: participant,
              privateMessageTopicId,
            },
          };
        },
        {}
      ),
    }),
    {}
  );

  const userAssessmentsForModal =
    assessmentModalData &&
    getUserAssessments({
      assessments,
      journalEntries,
      cohortId: assessmentModalData.cohort,
      user: assessmentModalData.username,
    }).map((assessment) => {
      const cohort = cohortList.find((c) => c.id === assessment.cohort);

      /**
       * TODO :
       * The UserAssessmentsModal currently takes a list of assessments,
       * and displays an image + description for each of them. However the
       * assessment can contain more than 1 journal entry. We currently pass
       * the first journal entry, but ultimately we would want to show all of
       * the journal entries for each assessment in the modal. The UX/UI needs
       * to be updated to account for this.
       */
      return {
        id: assessment.id,
        submissions: assessment.journalEntries.map((entry) => ({
          body: (
            <Box
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(entry.description),
              }}
            />
          ),
          imageUrl: entry.imageMobile,
        })),
        grade: assessment.grade,
        title: cohort?.courseDetails.title || '',
      };
    });

  const memberForAssessmentModal =
    assessmentModalData &&
    cohortMembers[assessmentModalData.cohort][assessmentModalData.username];

  return (
    <ScreenWrapper>
      {isApp ? <Flex pt="defaultMargin" /> : <ClassesMenu />}
      <Flex direction="column" flex={1}>
        {activityModalData ? (
          <UserActivityModal
            cohortId={activityModalData.cohortId}
            category={activityModalData.category}
            subcategory={activityModalData.subcategory}
            username={activityModalData.username}
            isOpen
            onClose={() => setActivityModalData(null)}
          />
        ) : null}
        {assessmentModalData &&
          memberForAssessmentModal &&
          userAssessmentsForModal && (
            <UserAssessmentsModal
              assessments={userAssessmentsForModal}
              isOpen
              userAvatar={memberForAssessmentModal.avatar || ''}
              userId={memberForAssessmentModal.name.length}
              userName={memberForAssessmentModal.name}
              onCancel={() => setAssessmentModalData(null)}
              onSave={async (grades) => {
                // TODO: Add success toast
                await Promise.all(
                  Object.keys(grades)
                    // The k refers to the assignmentId - cast to an int
                    .map((k) => parseInt(k))
                    // Only set grade if we've set the value to pass/fail
                    .filter((k) => grades[k])
                    // Make one api call per assignment to update its grade
                    .map((k) =>
                      dispatch(assessmentActions.updateGrade(k, grades[k]))
                    )
                );
              }}
            />
          )}
        {(cohortsLoading || discourseLoading) && (
          <>
            <Flex flexDir="column" mb={4}>
              <Skeleton isLoaded={false} mb={2} w="250px">
                <SectionTitle title="Loading..." size="sm" />
              </Skeleton>
              <CohortCard isLoading cohorts={[]} currentUserName="" />
            </Flex>
            <Flex flexDir="column" mb={4}>
              <Skeleton isLoaded={false} mb={2} w="250px">
                <SectionTitle title="Loading..." size="sm" />
              </Skeleton>
              <CohortCard isLoading cohorts={[]} currentUserName="" />
            </Flex>
          </>
        )}
        {!cohortsLoading && cohortList.length === 0 ? (
          <Text color="text.muted" mx={{ base: 'defaultMargin', md: 0 }}>
            You are currently not a mentor on any classes
          </Text>
        ) : (
          Object.keys(groupedCohorts).map((courseSlug, idx) => {
            const courseCohorts = groupedCohorts[courseSlug];
            // Extract the course title from the first cohort for that
            // course
            const courseTitle = courseCohorts[0].courseDetails.title;
            const course = courses[courseCohorts[0].courseDetails.id];

            const mappedCohorts = courseCohorts
              .map((cohort) => {
                // When a cohort is expanded we load data specific to that cohort
                // from discourse. Here we check whether the cohort we're mapping
                // over has been loaded.
                const discourseDataLoaded = loadedCohort.includes(
                  cohort.discourseGroupName
                );

                // Determine the overall status of this cohort.
                const status = getCohortStatus(
                  cohort.startDate,
                  cohort.endDate
                );

                const categoryId = course
                  ? getCohortSubcategoryId(cohort, course)
                  : null;

                // When a cohort is expanded, we'll need to retrieve data via the
                // Discourse API. To avoid rate-limiting we ensure that we restrict
                // aggresively hitting the Discourse API by artificially delaying
                // large numbers of requests.
                const onExpandCohort = async () => {
                  if (discourseDataLoaded) return;

                  // TODO: Ensure that user summaries are reliable for non-admin users ("top"
                  // sections can currently come back empty when logged in as cohort mentor)

                  // Break down the list of participants into chunks to avoid
                  // making too many requests to the Discourse API at once. Each
                  // chunk will be processed at once, with a delay added between
                  // each subsequent chunk.
                  // const participantsGroups = chunk(
                  //   cohort.participants.filter(
                  //     (p) => !loadedMembers.includes(p)
                  //   ),
                  //   MAX_DISCOURSE_REQUESTS
                  // );

                  // Use the list of chunks defined above to build a list of
                  // promises to be resolved one after the other, using `resolveQueue`
                  // to apply our artificial delay between chunks.
                  // const fetchParticipantsQueue = participantsGroups.map(
                  //   (g) => async () =>
                  //     await Promise.all(
                  //       g.map((p) =>
                  //         // TODO: Subscribe to message bus if applicable
                  //         dispatch(
                  //           discourseActions.getUserSummary(p)
                  //         ).then(() => setLoadedMembers((prev) => [...prev, p]))
                  //       )
                  //     )
                  // );

                  // Process the requests and update the loading state to:
                  // a) Indicate to the UI that the data is loaded
                  // b) Prevent us from hitting the Discourse API for this cohort again
                  //
                  // We also retrieve the Group Members from Discourse to allow us to
                  // enrich the student data shown - avatars & names come from discourse
                  // here.
                  await Promise.all([
                    // TODO: Subscribe to message bus if applicable
                    dispatch(
                      discourseActions.getGroupMembers(
                        cohort.discourseGroupName
                      )
                    ),
                    dispatch(courseActions.retrieve(cohort.courseDetails.id)),
                  ]);

                  loadAssessmentsForCohort(cohort.id);

                  setLoadedCohort((prev) => [
                    ...prev,
                    cohort.discourseGroupName,
                  ]);
                };

                // Build cohort specific course data to pass through to the CohortCard.
                // This is used if information on units/sessions are required for display.
                //
                // We only pass through normal session data as that's all students are
                // able to submit uploads against
                const journalUnits = unitSummaries
                  .filter(
                    (unit) =>
                      unit.course === cohort.course &&
                      unit.unitType !== 'assessment'
                  )
                  .map((unit) => ({
                    ...unit,
                    modules: unit.modules
                      .map((moduleId) => sessionSummary[moduleId])
                      .filter((module) => module.moduleType === 'normal'),
                  }));

                return {
                  id: cohort.id,
                  members: Object.values(cohortMembers[cohort.id]),
                  journalUnits,
                  onExpandCohort,
                  startDate: cohort.startDate,
                  status,
                  discourseCategoryId: categoryId,
                  discourseGroupChatTopicId: cohort.discourseGroupChatTopicId,
                  label: cohort.label,
                  isLoading: discourseLoading || !discourseDataLoaded,
                  assessmentsLoading,
                };
              })
              .sort((a, b) => moment(b.startDate).diff(moment(a.startDate)));

            return (
              <Flex flexDir="column" mb={8} key={`course-${courseSlug}-${idx}`}>
                <SectionTitle title={courseTitle} />
                <CohortCard
                  courseTitle={courseTitle}
                  currentUserName={currentUserName}
                  cohorts={mappedCohorts}
                  onClickAssessment={(username, cohort) =>
                    setAssessmentModalData({ username, cohort })
                  }
                  onClickAllActivity={(cohortId, subcategory) =>
                    setActivityModalData({
                      cohortId,
                      category: course?.discourseCategoryId as number,
                      subcategory,
                    })
                  }
                  onClickUserActivity={(cohortId, username, subcategory) =>
                    setActivityModalData({
                      cohortId,
                      category: course?.discourseCategoryId as number,
                      subcategory,
                      username,
                    })
                  }
                />
              </Flex>
            );
          })
        )}
      </Flex>
    </ScreenWrapper>
  );
};

const mapStateToProps = (state: GlobalState) => ({
  assessments: Object.values({ ...state.cms.assessment }),
  cohorts: getManagedCohorts(state),
  discourseMembers: state.discourse.members,
  discourseUserSummaries: state.discourse.userSummaries,
  discourseMessages: state.discourse.messages,
  journalEntries: Object.values({ ...state.cms.journal.journalEntries }),
  unitSummaries: Object.values({ ...state.cms.unit.unitSummary }),
  sessionSummary: state.cms.session.sessionSummary,
  currentUserName: state.user.userDetails.name,
  courses: state.cms.course.course,
});

const connector = connect(mapStateToProps);

export default connector(ManageCohorts);
