import React, { useState } from 'react';
import { Provider } from 'react-redux';
import TagManager from 'react-gtm-module';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { Theme, DarkTheme, ChakraProvider } from '@workshop/ui';

import { FRONT_ENV } from 'constants/env';
import { PLATFORM_EMAIL } from 'constants/common';

import store from 'redux/store';

import AppRouter from 'navigation/AppRouter';
import history from 'navigation/History';

if (process.env.REACT_APP_GMT_ID) {
  const tagManagerArgs = {
    gtmId: `${process.env.REACT_APP_GMT_ID}`,
  };

  TagManager.initialize(tagManagerArgs);
}

if (FRONT_ENV === 'production') {
  Sentry.init({
    dsn: 'https://e355439f6a3e4ad59c7e72ae5d538098@o75090.ingest.sentry.io/5315932',
    environment: process.env.NODE_ENV,
    tracesSampleRate: 1.0, // Be sure to lower this in production
    normalizeDepth: 5,
    integrations: [
      new Integrations.BrowserTracing({
        // Can also use reactRouterV4Instrumentation
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    // beforeSend(event, hint) {
    //   // Check if it is an exception, and if so, show the report dialog
    //   if (event.exception) {
    //     Sentry.showReportDialog({
    //       subtitle:
    //         'There was an error, sorry about that. Please fill out this form to give us further information on how you arrived at this error (which pages you clicked on from where) so we can help.',
    //       subtitle2: `If this is persistent, please also contact support${PLATFORM_EMAIL}.`,
    //     });
    //   }
    //   return event;
    // },
    ignoreErrors: ['TypeError: Failed to fetch'],
  });
}

const App = () => {
  const [colorMode, setColorMode] = useState('light');
  const observer = new MutationObserver((mutationRecords) => {
    const newDocElement = mutationRecords[0].target;
    const newColorMode =
      // @ts-ignore
      newDocElement.style.getPropertyValue('--chakra-ui-color-mode') ||
      // @ts-ignore
      newDocElement.dataset.theme;
    setColorMode(newColorMode);
  });
  observer.observe(document.documentElement, {
    attributes: true,
  });

  // Get default theme from local storage and use system
  // default if there's nothing in storage
  let themeConfig = {};
  if (!localStorage.getItem('chakra-ui-color-mode')) {
    themeConfig = { useSystemColorMode: true };
  }

  return (
    <Provider store={store}>
      <ChakraProvider
        theme={
          colorMode === 'light'
            ? { ...Theme, config: { ...Theme.config, ...themeConfig } }
            : { ...DarkTheme, config: { ...DarkTheme.config, ...themeConfig } }
        }
      >
        <AppRouter history={history} />
      </ChakraProvider>
    </Provider>
  );
};

export default App;
