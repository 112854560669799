import { Palette } from '.';

const common = {
  primary: Palette.blue[300],
  primaryDark: Palette.blue[400],
  primaryTransparent: Palette.blueAlpha[400],
  primaryPalette: Palette.blue,
  secondary: Palette.neutral[400],
  secondaryPalette: Palette.neutral,
  muted: Palette.neutral[400],
  notification: Palette.red[400],
  progress: Palette.green[300],
};

const text = {
  light: 'rgba(255, 255, 255, 1)',
  muted: Palette.neutral[200],
  default: 'rgba(255, 255, 255, 1)',
  opposite: Palette.neutral[900],
  dark: Palette.neutral[800],
  selected: Palette.blue[300],
  primary: Palette.blue[300],
  primaryDark: Palette.blue[200],
  placeholder: Palette.neutral[300],

  sidebar: 'rgba(255, 255, 255, 1)',

  // Intent
  none: Palette.blue[300],
  success: Palette.green[300],
  info: Palette.blue[300],
  error: Palette.red[300],
  warning: Palette.orange[300],
};

const background = {
  default: Palette.neutral[800],
  defaultTransparent: 'rgba(43, 47, 59, 0.3)',
  defaultXTransparent: 'rgba(43, 47, 59, 0.3)',
  tint1: Palette.neutral[700],
  tint2: Palette.neutral[700],
  tint3: Palette.neutral[900],
  tint4: Palette.neutral[600],
  overlay: Palette.neutralAlpha[600],
  primary: Palette.blue[700],
  primaryDark: Palette.blue[600],

  sidebar: Palette.neutral[800],

  // Intent
  none: Palette.blue[700],
  success: Palette.green[700],
  info: Palette.blue[700],
  error: Palette.red[700],
  warning: Palette.orange[700],
};

const icon = {
  default: 'rgba(255, 255, 255, 1)',
  muted: Palette.neutral[200],
  disabled: Palette.neutralLightAlpha[500],
  selected: Palette.blue[300],
  primary: Palette.blue[300],

  // Intent.
  none: Palette.blue[300],
  success: Palette.green[300],
  info: Palette.blue[300],
  error: Palette.red[300],
  warning: Palette.orange[300],
};

const border = {
  default: Palette.neutral[400],
  muted: Palette.neutral[700],
};

const intent = {
  none: Palette.blue,
  info: Palette.blue,
  success: Palette.green,
  error: Palette.red,
  warning: Palette.orange,
};

export default {
  common,
  text,
  background,
  icon,
  border,
  intent,
};
