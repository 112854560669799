import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FaDiscord } from 'react-icons/fa';

import styled, {
  Box,
  Flex,
  LinkBox,
  LinkOverlay,
  Divider,
  useColorModeValue,
} from '@workshop/ui';

import { GlobalState } from 'types';
import { PermissionSlug } from 'types/common';

import { organisationActions } from 'redux/actions/common';
import {
  useHasAnyPermission,
  useIsAuthenticated,
  useIsMentor,
  usePermissions,
  useCurrentTeamProfile,
  useTeams,
} from 'redux/selectors';

import { hooks, colorUtils, routingUtils } from 'utils';
import { helpUrl, discordUrl, PLATFORM } from 'constants/env';
import {
  IMPERSONATED_BANNER_HEIGHT,
  ACTIVE_ROOMS_BANNER_HEIGHT,
} from 'constants/ui';
import navRoutes from 'navigation/Routes';

import { NAV_HEIGHT } from 'containers/AppHeader';
import { SidebarLink } from 'components/AppSidebar';
import Brand, { ProGem } from 'components/Brand';
import { UserAvatar } from 'components/UserAvatar';
import { getCrumbs } from 'components/Breadcrumbs';

interface Props extends RouteComponentProps {
  sidebarWidth: string;
  sidebarMinimised: boolean;
  onSidebarToggle: () => void;
}

const SidebarContainer = styled(Box)`
  position: -webkit-sticky;
  position: sticky;
`;

const AppSidebarContainer: React.FC<Props> = ({
  location,
  sidebarWidth,
  sidebarMinimised,
  onSidebarToggle,
}) => {
  const dispatch = useDispatch();

  const currentRoute = hooks.useCurrentRoute();
  const isMentor = useIsMentor();
  const isAuthenticated = useIsAuthenticated();
  const userPermissions = usePermissions();
  const userTeams = useTeams();
  const currentTeamProfile = useCurrentTeamProfile();

  // Get colors from team profile id or image
  const baseColor = currentTeamProfile?.id
    ? colorUtils.getRandomColor(
        currentTeamProfile.id,
        undefined,
        undefined,
        'hex'
      )
    : '';
  const colors = hooks.useColors(currentTeamProfile?.brandColor || baseColor);

  const bg = useColorModeValue(
    colors?.light.primaryContainer,
    colors?.dark.primaryContainer
  );

  const bgAlt = useColorModeValue(
    colors?.light.secondaryContainer,
    colors?.dark.secondaryContainer
  );

  const textColor = useColorModeValue(
    colors?.light.onPrimaryContainer,
    colors?.dark.onPrimaryContainer
  );

  const isImpersonated = useSelector(
    (state: GlobalState) => state.user.userDetails.isImpersonated
  );
  const currentView = useSelector((state: GlobalState) => state.ui.currentView);

  const activeRooms = useSelector(
    (state: GlobalState) => state.user.userProfile.activeRooms
  );

  // On page load, set the sidebar view based on the current route
  React.useEffect(() => {
    if (currentRoute?.defaultView) {
      dispatch(organisationActions.setCurrentView(currentRoute?.defaultView));
    }
  }, [isAuthenticated, isMentor]);

  const sidebarLinks = Object.values({
    ...navRoutes.cms,
    ...navRoutes.common,
    ...navRoutes.global,
    ...navRoutes.learner,
    ...navRoutes.public,
  }).map((r) => ({
    canEdit: r.permissions?.canEdit || [],
    canView: r.permissions?.canView || [],
    disabled: r.sidebarLink?.viewsDisplay.find((v) => v.view === currentView)
      ?.disabled,
    icon: r.icon || '',
    label: r.name,
    linkIdx: r.sidebarLink?.index,
    path: r.path(),
    viewsDisplay: r.sidebarLink?.viewsDisplay || [],
  }));

  /** Compile list of all view/edit permissions for links in the learner sidebar */
  const learnerLinksPermissions = sidebarLinks
    .filter((l) => l.viewsDisplay.find((v) => v.view === 'learner'))
    .reduce(
      (acc: PermissionSlug[], link) => [
        ...acc,
        ...link.canEdit,
        ...link.canView,
      ],
      []
    );
  const canViewLearnerLinks = useHasAnyPermission(learnerLinksPermissions);

  /** Compile list of all view/edit permissions for links in the teacher sidebar */
  const teacherLinksPermissions = sidebarLinks
    .filter((l) => l.viewsDisplay.find((v) => v.view === 'teacher'))
    .reduce(
      (acc: PermissionSlug[], link) => [
        ...acc,
        ...link.canEdit,
        ...link.canView,
      ],
      []
    );
  const canViewTeacherLinks = useHasAnyPermission(teacherLinksPermissions);

  const sidebarRoles = [
    {
      hidden: !canViewLearnerLinks,
      label: 'Learn',
      onClick: () => dispatch(organisationActions.setCurrentView('learner')),
      selected: currentView === 'learner',
    },
    {
      hidden: !canViewTeacherLinks,
      label: 'Teach',
      onClick: () => dispatch(organisationActions.setCurrentView('teacher')),
      selected: currentView === 'teacher',
    },
  ].filter((r) => !r.hidden);

  const visibleSidebarLinks = sidebarLinks
    .filter((r) =>
      /** Only display links for routes which
       *  have the currentView in their viewsDisplay */
      r?.viewsDisplay?.find((v) => v.view === currentView)
    )
    .filter((r) => {
      /** Only display links if the user has the correct
       * permissions (or if there are no permissions defined
       * for that route)
       */
      if (!r.canEdit.length && !r.canView.length) return true;

      if (r.canEdit && r.canEdit.find((p) => userPermissions.includes(p))) {
        return true;
      }

      if (r.canView && r.canView.find((p) => userPermissions.includes(p))) {
        return true;
      }

      return false;
    })
    .sort((a, b) =>
      /** Sort these links by sidebarLink index (ascending) */
      a.linkIdx !== undefined && b.linkIdx !== undefined
        ? a.linkIdx - b.linkIdx
        : 0
    );

  let offset = 0;
  if (isImpersonated) {
    offset += IMPERSONATED_BANNER_HEIGHT;
  }
  if (activeRooms?.length > 0) {
    offset += ACTIVE_ROOMS_BANNER_HEIGHT;
  }

  // Check whether a banner is showing, if so hide the logo
  const crumbs = getCrumbs(location.pathname, isAuthenticated, isMentor);
  const currentCrumb = crumbs.length > 0 ? crumbs[crumbs.length - 1] : null;
  const banner = useSelector((state: GlobalState) =>
    currentCrumb?.stateBannerPath
      ? routingUtils.getFromPath(state, currentCrumb.stateBannerPath, '')
      : ''
  );
  const logo = useSelector((state: GlobalState) =>
    currentCrumb?.stateLogoPath
      ? routingUtils.getFromPath(state, currentCrumb.stateLogoPath, '')
      : ''
  );
  const showBanner = Boolean(banner && logo);

  const hideLogo = currentCrumb?.route.hideHeading;

  return (
    <>
      <Box width={{ base: 0, md: '48px', lg: 0 }} />
      {sidebarMinimised && !showBanner && !hideLogo ? (
        <Box
          position={{ base: 'fixed', md: 'absolute' }}
          top={{ base: offset ? `${offset}px` : 0, md: 0.5 }}
          left={{ base: '40px', md: '54px' }}
          zIndex={1100}
        >
          <LinkBox flex={1} padding={3}>
            <LinkOverlay href="/">
              <Brand navbarTransparent={false} />
            </LinkOverlay>
          </LinkBox>
        </Box>
      ) : null}
      <Box position={{ base: 'fixed', lg: 'relative' }} zIndex={1100}>
        <SidebarContainer
          backgroundColor="background.sidebar"
          width={sidebarWidth}
          transition="width 0.5s"
          height={{
            base: sidebarMinimised
              ? NAV_HEIGHT - 1
              : `calc(100vh - ${offset}px)`,
            md: `calc(100vh - ${offset}px)`,
          }}
          borderBottomRightRadius={{
            base: sidebarMinimised ? 'lg' : 0,
            md: 0,
          }}
          boxShadow={{ base: sidebarMinimised ? 'none' : 'lg', md: 'lg' }}
          top={`${offset}px`}
          overflow="hidden"
          overflowY={{ base: sidebarMinimised ? 'hidden' : 'auto', md: 'auto' }}
        >
          <Flex flexDir="column" width={sidebarWidth}>
            <Flex flexDir="column" mb={4}>
              <Flex flexDir="column" mt={1} mb={2}>
                <Flex mb={2}>
                  <SidebarLink
                    icon="Menu"
                    labelComponent={
                      <Box flex={1} opacity={{ base: 0, md: 1 }}>
                        <Brand navbarTransparent={false} />
                      </Box>
                    }
                    onClick={onSidebarToggle}
                    minimised={sidebarMinimised}
                    color="text.sidebar"
                    hoverColor="transparent"
                  />
                </Flex>

                {currentView === 'teacher' && currentTeamProfile ? (
                  <Flex backgroundColor={bg || 'background.tint2'}>
                    <SidebarLink
                      linkTo={navRoutes.common.home.path()}
                      iconComponent={
                        <Flex position="relative" justifyContent="center">
                          <UserAvatar
                            name={currentTeamProfile.name}
                            userId={currentTeamProfile.id}
                            avatarPicture={currentTeamProfile.logoDark || ''}
                            size="2xs"
                            m={0.5}
                          />
                          {currentTeamProfile.isPro &&
                            PLATFORM === 'steppit' && (
                              <Box
                                position="absolute"
                                bottom={-0.5}
                                right={-0.5}
                              >
                                <ProGem
                                  tier={currentTeamProfile.proPlan?.tier || 0}
                                />
                              </Box>
                            )}
                        </Flex>
                      }
                      label={currentTeamProfile.name}
                      minimised={sidebarMinimised}
                      color={textColor || 'neutral.800'}
                      hoverColor={bgAlt || 'neutral.300'}
                    />
                  </Flex>
                ) : null}
              </Flex>
              {visibleSidebarLinks.map((btn, key) => (
                <SidebarLink
                  icon={btn.icon}
                  disabled={btn.disabled}
                  key={key}
                  label={btn.label}
                  linkTo={btn.path}
                  selected={
                    btn.path !== '/' && location.pathname.includes(btn.path)
                  }
                  minimised={sidebarMinimised}
                  color={
                    btn.path === '/' || !location.pathname.includes(btn.path)
                      ? 'text.sidebar'
                      : 'common.primary'
                  }
                />
              ))}
            </Flex>

            <Flex
              flexDir="column"
              bottom={0}
              width={sidebarWidth}
              transition="width 0.5s"
              overflow="hidden"
              whiteSpace="nowrap"
            >
              <Divider borderColor="border.default" margin={0} />
              <SidebarLink
                icon="Help"
                label="Help & Support"
                onClick={() =>
                  window.open(helpUrl, '_blank', 'noopener,noreferrer')
                }
                minimised={sidebarMinimised}
                color="text.sidebar"
              />
              {/* <Divider borderColor="border.default" margin={0} />
              <SidebarLink
                icon="Description"
                label="Resources"
                onClick={() =>
                  window.open('/resources', '_blank', 'noopener,noreferrer')
                }
                minimised={sidebarMinimised}
                color="text.sidebar"
              /> */}
              <Divider borderColor="border.default" margin={0} />
              {PLATFORM === 'steppit' && currentView === 'teacher' && (
                <>
                  <SidebarLink
                    iconComponent={
                      <Flex padding={2}>
                        <Flex
                          boxSize="icon"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <FaDiscord />
                        </Flex>
                      </Flex>
                    }
                    label="Join Our Community"
                    onClick={() =>
                      window.open(discordUrl, '_blank', 'noopener,noreferrer')
                    }
                    minimised={sidebarMinimised}
                    color="text.sidebar"
                  />
                  <Divider borderColor="border.default" margin={0} />
                </>
              )}
            </Flex>
          </Flex>
        </SidebarContainer>
      </Box>
    </>
  );
};

export default withRouter(AppSidebarContainer);
