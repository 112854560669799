import React from 'react';
import { useSelector } from 'react-redux';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalProps,
  Flex,
  Text,
  Button,
  chakra,
} from '@workshop/ui';
import { Transition, animated } from 'react-spring';
import { useHistory, useLocation } from 'react-router';

import { GlobalState } from 'types';

import { hooks } from 'utils';
import { courseActions } from 'redux/actions/learner';

import { SessionPreviewScreen } from 'screens/cms/SessionPreview';

import { Loading } from 'components/Loading';

import { PLATFORM_DISPLAY_NAME } from 'constants/common';

interface SessionModalProps {
  /**
   * This enables/prevents the modal closing on clicking the overlay. If using React Hook Form
   * this must be disabled to prevent handleSubmit closing the modal automatically
   */
  closeOnOverlayClick?: boolean;
  isOpen: boolean;
  modalSize?: ModalProps['size'];
  onClose: () => void;
  courseSlug: string;
  moduleId?: number;
  title: string;
  link?: string;
}

export interface Props extends SessionModalProps {}

const SessionModal: React.FC<Props> = ({
  closeOnOverlayClick = true,
  isOpen,
  modalSize = '6xl',
  onClose,
  courseSlug,
  moduleId,
  title,
  link,
}) => {
  const { courseLoading } = hooks.useLoadingDataState(
    {
      courseLoading: {
        actions: [() => courseActions.retrieve(courseSlug)],
      },
    },
    [courseSlug]
  );

  const location = useLocation();
  const history = useHistory();
  const course = useSelector(
    (state: GlobalState) => state.learner.courses.courses.detail[courseSlug]
  );

  const module = moduleId || course?.standaloneModule;

  const moduleState = useSelector(
    (state: GlobalState) => state.learner.courses.modules
  );
  const session = Object.values(moduleState).find((m) => m.id === module);

  const AnimatedModalContent = animated(ModalContent);
  const AnimatedModalOverlay = animated(ModalOverlay);
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={closeOnOverlayClick}
      motionPreset="none"
      size={modalSize}
    >
      <Transition
        items={isOpen}
        from={{ opacity: 0, backdropFilter: 'blur(0px)' }}
        enter={{ opacity: 1, backdropFilter: 'blur(10px)' }}
        leave={{ opacity: 0, backdropFilter: 'blur(0px)' }}
      >
        {(styles, item) => (
          <AnimatedModalOverlay backdropFilter="blur(10px)" style={styles} />
        )}
      </Transition>
      <Transition
        items={isOpen}
        from={{ scale: 0.98 }}
        enter={{ scale: 1 }}
        leave={{ scale: 0.98 }}
        config={{ tension: 200, friction: 8 }}
      >
        {(styles, item) => (
          <AnimatedModalContent
            marginX={{ base: 0, md: 2 }}
            bg="background.tint3"
            style={styles}
          >
            <ModalHeader
              fontWeight="bold"
              letterSpacing="title"
              display={{ base: 'block', md: 'flex' }}
              alignItems="center"
              px={{ base: 4, md: 6 }}
            >
              <Text>{title}</Text>
            </ModalHeader>

            <ModalCloseButton />
            <ModalBody px={{ base: 0, md: 6 }}>
              {courseLoading || !session ? (
                <Loading />
              ) : (
                // @ts-ignore
                <SessionPreviewScreen
                  session={session}
                  location={location}
                  history={history}
                  asComponent
                  onComplete={onClose}
                />
              )}
              <Flex alignItems="center" my={3} mx={{ base: 4, md: 0 }}>
                {!!link && (
                  <Text color="text.muted" pr={2}>
                    {`This is an excerpt from a course powered by ${PLATFORM_DISPLAY_NAME},
                    available `}
                    <chakra.a
                      href={link}
                      target="_blank"
                      rel="noreferrer noopener"
                      color="text.primary"
                    >
                      here
                    </chakra.a>
                    .
                  </Text>
                )}
                <Flex flex={1} />
                <Button
                  onClick={onClose}
                  icon="Done"
                  variant="outline"
                  w={10}
                />
              </Flex>
            </ModalBody>
          </AnimatedModalContent>
        )}
      </Transition>
    </Modal>
  );
};

export default SessionModal;
