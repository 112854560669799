import React from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@workshop/ui';
import { InformationCard } from 'components/Common';

interface ConfirmModalProps {
  body: string | React.ReactNode;
  btnColor?: 'red' | 'green' | 'orange' | 'blue';
  btnColor2?: 'red' | 'green' | 'orange' | 'blue';
  btnLabel: string;
  btnLabel2?: string;
  isLoading: boolean;
  isOpen: boolean;
  onClick: () => void | Promise<void>;
  onClick2?: () => void | Promise<void>;
  onClose: () => void;
  title: string;
  helpText?: string;
  infoTag?: string;
  extra?: React.ReactNode;
  hideCancel?: boolean;
  isDisabled?: boolean;
  noCloseOnClick?: boolean;
  stackColumn?: boolean;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  body,
  btnLabel,
  btnLabel2,
  btnColor = 'red',
  btnColor2 = 'red',
  isLoading,
  isOpen,
  onClick,
  onClick2,
  onClose,
  title,
  helpText,
  infoTag,
  extra,
  hideCancel,
  isDisabled,
  noCloseOnClick,
  stackColumn,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} preserveScrollBarGap>
      <ModalOverlay />
      <ModalContent justifyContent="center">
        <ModalHeader fontWeight="bold" textAlign="center" px={8} pt={6}>
          {title}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {!!infoTag && (
            <InformationCard
              id="preview_assessment_popup"
              information={{ description: infoTag }}
              isDismissable={false}
              mb={6}
            />
          )}
          <Text whiteSpace="pre-wrap" textAlign="center">
            {body}
          </Text>
          {!!extra && extra}
          {!!helpText && (
            <Text
              whiteSpace="pre-wrap"
              textAlign="center"
              color="text.muted"
              fontSize="sm"
              mt={4}
            >
              {helpText}
            </Text>
          )}
        </ModalBody>
        <ModalFooter
          flexDirection={stackColumn ? 'column' : { base: 'column', sm: 'row' }}
          justifyContent="center"
          alignItems={stackColumn ? 'normal' : 'center'}
          mt={2}
          mb={4}
        >
          {!hideCancel && (
            <Button secondary isDisabled={isLoading} onClick={onClose}>
              Cancel
            </Button>
          )}
          {Boolean(btnLabel) && (
            <Button
              isDisabled={isDisabled || isLoading}
              isLoading={isLoading}
              onClick={() => {
                onClick();
                if (!noCloseOnClick) {
                  onClose();
                }
              }}
              colorScheme={btnColor}
              mt={stackColumn ? 4 : { base: 4, sm: 0 }}
              ml={stackColumn ? 0 : { base: 0, sm: 'defaultMargin' }}
            >
              {btnLabel}
            </Button>
          )}
          {Boolean(btnLabel2) && (
            <Button
              isDisabled={isDisabled || isLoading}
              isLoading={isLoading}
              onClick={() => {
                if (onClick2) {
                  onClick2();
                }
                if (!noCloseOnClick) {
                  onClose();
                }
              }}
              colorScheme={btnColor2}
              mt={stackColumn ? 4 : { base: 4, sm: 0 }}
              ml={stackColumn ? 0 : { base: 0, sm: 'defaultMargin' }}
            >
              {btnLabel2}
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmModal;
