import React from 'react';
import { Box } from '@workshop/ui';

import { EditModal } from 'components/Common/EditModal';

import { RenderHtml } from 'components/Common';

interface EmbedModalProps {
  name: string;
  url: string;
  isOpen: boolean;
  onClose: () => void;
}

const EmbedModal: React.FC<EmbedModalProps> = ({
  isOpen,
  onClose,
  name,
  url,
}) => {
  return (
    <EditModal
      title={name}
      isOpen={isOpen}
      onSave={() => null}
      onClose={onClose}
      modalSize="full"
      showFooter={false}
    >
      <Box
        position="absolute"
        w="100%"
        h="100%"
        maxH="calc(100vh - 5em)"
        left={0}
      >
        <RenderHtml
          iframeOnly
          html={`<iframe src="${url}#toolbar=0" loading="lazy" width="100%" height="100%" title="Embedded Content" frameborder="0" allow="camera; microphone; display-capture; fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" />`}
        />
      </Box>
    </EditModal>
  );
};

export default EmbedModal;
