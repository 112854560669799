import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { RouteComponentProps } from 'react-router';

import navRoutes from 'navigation/Routes';

import { mailActions, profileActions } from 'redux/actions/common';

import {
  useIsAuthenticated,
  useUser,
  useUserProfile,
  useCurrentTeamProfile,
  useCurrentTeam,
  useDismissedInformationCards,
} from 'redux/selectors';

import {
  CURRENCY_SYMBOLS,
  CURRENCY_POSITIONS,
  COUNTRY_CURRENCIES,
} from 'constants/settings';
import { PRO_ORGS, ACCELERATOR_LIST_HASH } from 'constants/organisation';
import { NAV_HEIGHT } from 'containers/AppHeader';

import {
  Card,
  Flex,
  Text,
  Box,
  LinkButton,
  Button,
  Stack,
  Image,
  chakra,
} from '@workshop/ui';

import { ScreenWrapper } from 'screens/common/ScreenWrapper';
import { ProCta } from 'components/Common';

interface HomeScreenProps extends RouteComponentProps {}

const AcceleratorProgramScreen: React.FC<HomeScreenProps> = ({ history }) => {
  const [joinedList, setJoinedList] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const dismissedInformationCards = useDismissedInformationCards();
  const user = useUser();
  const userProfile = useUserProfile();
  const currentTeamProfile = useCurrentTeamProfile();
  const currentTeam = useCurrentTeam();
  const hasAccount = useIsAuthenticated();
  const hasChannel = hasAccount && !!currentTeam;

  const isPro = Boolean(
    currentTeamProfile?.isPro || (currentTeam && PRO_ORGS.includes(currentTeam))
  );

  useEffect(() => {
    if (dismissedInformationCards.includes('accelerator_program')) {
      setJoinedList(true);
    }
  }, [dismissedInformationCards]);

  const country = userProfile?.country || 'US';
  // @ts-ignore
  const localCurrency = COUNTRY_CURRENCIES[country];
  let currency = 'USD';
  if (
    localCurrency &&
    Object.keys(CURRENCY_POSITIONS).includes(localCurrency)
  ) {
    currency = localCurrency;
  }

  const priceNum = '150';
  const price = `${
    // @ts-ignore
    CURRENCY_POSITIONS[currency] === 'before'
      ? // @ts-ignore
        CURRENCY_SYMBOLS[currency]
      : ''
    // @ts-ignore
  }${priceNum}${
    // @ts-ignore
    CURRENCY_POSITIONS[currency] === 'after'
      ? // @ts-ignore
        ` ${CURRENCY_SYMBOLS[currency]}`
      : ''
  }`;
  const features = [
    {
      label: (
        <>
          Week by week, we'll take you through the entire course-making process{' '}
          <chakra.b>one step at a time</chakra.b>
        </>
      ),
      emoji: '👣',
    },
    {
      label: (
        <>
          You'll learn how to <chakra.b>make the most of Steppit</chakra.b> with
          action-oriented courses
        </>
      ),
      emoji: '🙌',
    },
    {
      label: (
        <>
          We'll give you heaps of{' '}
          <chakra.b>fool-proof course planning and production tips</chakra.b>
        </>
      ),
      emoji: '🎬',
    },
    {
      label: (
        <>
          Chat with us and a class of other creators to{' '}
          <chakra.b>get advice tailored to you</chakra.b>
        </>
      ),
      emoji: '💬',
    },
    {
      label: (
        <>
          <chakra.b>
            It's currently free and exclusive to Pro channels!
          </chakra.b>
        </>
      ),
      emoji: price,
    },
  ];

  const onSignUp = async () => {
    setIsLoading(true);
    const res = await dispatch(
      mailActions.joinList(ACCELERATOR_LIST_HASH, {
        email: user.email,
      })
    );
    setIsLoading(false);
    // @ts-ignore
    if (res.payload?.success) {
      setJoinedList(true);
    }
    dispatch(
      profileActions.updateUserProfile(
        {
          dismissedInformationCards: [
            ...dismissedInformationCards,
            'accelerator_program',
          ],
        },
        { toast: { success: false, error: false } }
      )
    );
  };

  const cta = (
    <>
      {joinedList ? (
        <>
          <Text
            bg="background.success"
            color="text.success"
            p={4}
            borderRadius="md"
          >
            <chakra.b>You're on the list!</chakra.b> We'll be in touch soon with
            your class details 🚀
          </Text>
          <LinkButton
            to={navRoutes.common.home.path()}
            variant="outline"
            icon="ArrowBack"
            mt={6}
          >
            Back Home
          </LinkButton>
        </>
      ) : !hasAccount || !hasChannel ? (
        <>
          <Text fontSize={{ base: 'md', sm: 'lg' }} mb={4}>
            Create a Steppit Pro channel to sign up to our accelerator program:
          </Text>
          <LinkButton
            size="lg"
            mb={4}
            icon="ArrowForward"
            iconPosition="right"
            to={`${navRoutes.global.planner.path()}?p=pro`}
          >
            Create Channel
          </LinkButton>
        </>
      ) : !isPro ? (
        <Flex justifyContent="center" my={6}>
          <ProCta
            label="Only available with"
            ctaText="Upgrade to Join Program"
          />
        </Flex>
      ) : (
        <Button
          size="lg"
          onClick={onSignUp}
          icon="ArrowForward"
          iconPosition="right"
          mb={4}
          isLoading={isLoading}
        >
          Sign Me Up
        </Button>
      )}
    </>
  );

  return (
    <ScreenWrapper>
      <Card
        padding={0}
        maxW="760px"
        mx="auto"
        mb={12}
        mt={{ base: NAV_HEIGHT, md: 100 }}
      >
        <Box>
          <Box>
            <Image
              src="https://d1bqmh4sljm7sh.cloudfront.net/marketing-assets/steppit-course-banner.png"
              w="100%"
              objectFit="contain"
            />
          </Box>
          <Box py={10} px={{ base: 4, md: 10 }} textAlign="center">
            <Text
              fontSize={{ base: '2xl', md: '4xl' }}
              fontWeight="extrabold"
              mb={{ base: 6, md: 8 }}
              maxWidth="600px"
              mx="auto"
              lineHeight="1.2"
            >
              Let's Build an Online Course People Will{' '}
              <chakra.span color="text.primary">Love</chakra.span>
            </Text>
            <Flex justifyContent="center">
              <Text
                bg="background.primary"
                px={3}
                py={1}
                borderRadius="full"
                fontSize={{ base: 'md', sm: 'lg' }}
                fontWeight="semibold"
                color="text.primary"
              >
                Steppit Pro Accelerator Program
              </Text>
            </Flex>

            <Text
              fontSize={{ base: 'md', sm: 'lg' }}
              mt={{ base: 6, md: 8 }}
              mb={10}
            >
              Join our Pro accelerator programme to get{' '}
              <chakra.b>end-to-end help and guidance</chakra.b> on launching
              your first course. It’s run by us, the Steppit team (hello!) –
              we’re real human beings who just want to help you make your first
              course a huge <chakra.b>success</chakra.b>.
            </Text>
            {cta}
            <Stack
              direction={{ base: 'column' }}
              spacing={6}
              mt={10}
              mb={10}
              alignItems="center"
              // bg="background.tint3"
              borderWidth={1}
              p={8}
              borderRadius="lg"
            >
              <Text fontWeight="bold" fontSize="xl">
                Why join our accelerator program?
              </Text>
              {features.map(({ label, emoji }) => (
                <Flex
                  key={`feature-${label}`}
                  flex={1}
                  alignItems="center"
                  flexDirection="column"
                  maxWidth="450px"
                >
                  <Flex fontSize="4xl" mb={2}>
                    <Text
                      {...(emoji === price
                        ? {
                            color: 'text.muted',
                            textDecoration: 'line-through',
                            fontWeight: 'semibold',
                          }
                        : {})}
                    >
                      {emoji}
                    </Text>
                  </Flex>
                  <Text fontSize={{ base: 'md', sm: 'lg' }}>{label}</Text>
                </Flex>
              ))}
            </Stack>
            {hasChannel && isPro && !joinedList && (
              <Text fontSize={{ base: 'md', sm: 'lg' }} mb={8}>
                Our next class starts soon! To secure your spot, all you have to
                do is <chakra.b>hit the button below</chakra.b>. 👇
              </Text>
            )}
            {cta}
          </Box>
        </Box>
      </Card>
    </ScreenWrapper>
  );
};

export default AcceleratorProgramScreen;
