import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import { hooks, courseUtils } from 'utils';
import { mailActions } from 'redux/actions/common';
import { cohortActions } from 'redux/actions/cms';

import { GlobalState } from 'types';
import { MailRecipient, CohortStatus } from 'types/common';
import { License } from 'types/cms';

import navRoutes from 'navigation/Routes';

import {
  Box,
  Flex,
  Grid,
  GridItem,
  Text,
  MdIcon,
  LinkButton,
  Button,
  Divider,
  Collapse,
  chakra,
} from '@workshop/ui';
import { StepsModal, LabelSelect } from 'components/Common';
import { Loading } from 'components/Loading';
import { EnrolStudentsModal } from 'components/CohortAllocation';

interface EnrolmentRequestsPopupProps {
  isOpen: boolean;
  onClose: (confirm?: boolean) => void;
  courseSlug: string;
  courseTitle: string;
  isLicensed?: boolean;
  validLicenses?: License[];
}

interface RequestProps extends MailRecipient {
  courseSlug: string;
  isLicensed?: boolean;
  validLicenses?: License[];
}

const Request: React.FC<RequestProps> = (r) => {
  const [isDismissing, setIsDismissing] = useState(false);
  const [isEnrolling, setIsEnrolling] = useState(false);
  const [showEnrolModal, setShowEnrolModal] = useState(false);
  const [selectedCohortId, setSelectedCohortId] = useState<
    string | number | null
  >(null);
  const date = moment(r.created);
  const dispatch = useDispatch();

  const allCohorts = useSelector((state: GlobalState) => state.cms.cohort);

  const selectedCohort = selectedCohortId && allCohorts[selectedCohortId];

  // Class transfer options – cohorts must be for the same course,
  // be of the same type and have spaces available
  const classOptions = Object.values(allCohorts)
    .filter(
      (c) =>
        c.course === r.courseSlug &&
        c.isTest === false &&
        c.isPrivate === true &&
        c.isAnonymous === false &&
        (c.spacesAvailable > 0 || !courseUtils.hasClassSizeLimit(c)) &&
        c.status === CohortStatus.live
    )
    .reduce(
      (acc, c) => ({
        ...acc,
        [c.id]: `${moment(c.startDate).format('Do MMMM YYYY')}${
          c.label ? ' - ' : ''
        }${c.label}${c.status === CohortStatus.draft ? ' (Draft)' : ''}`,
      }),
      {} as { [key: string]: string }
    );

  let totalLicensedSpacesAvailable = 0;
  r.validLicenses?.forEach((l) => {
    totalLicensedSpacesAvailable += l.totalEnrolments - l.enrolments.length;
  });

  const dismissRequest = async () => {
    setIsDismissing(true);
    await dispatch(
      mailActions.updateListRecipient(
        r.id,
        r.status === 'active' ? 'inactive' : 'active'
      )
    );
    setIsDismissing(false);
  };

  return (
    <>
      {showEnrolModal && selectedCohort ? (
        <EnrolStudentsModal
          title="Enroll Learner"
          isOpen={showEnrolModal}
          onClose={() => setShowEnrolModal(false)}
          onCancel={() => setShowEnrolModal(false)}
          onSave={() => {
            dismissRequest();
            dispatch(cohortActions.updateCohort(selectedCohort.id, {}));
          }}
          modalSize="lg"
          course={selectedCohort.courseDetails.id}
          cohort={selectedCohort.id}
          cohortIsTest={selectedCohort.isTest}
          cohortIsAnonymous={selectedCohort.isAnonymous}
          cohortSocialType={selectedCohort.socialType}
          courseIsLicensed={r.isLicensed}
          licenses={r.validLicenses}
          cohortSpacesAvailable={selectedCohort.spacesAvailable}
          licensedSpacesAvailable={totalLicensedSpacesAvailable}
          licensorName={selectedCohort.courseDetails.organisation.name}
          learnerEmail={r.contact.email}
        />
      ) : null}
      <Flex
        key={`request-list-${r.id}`}
        flex={1}
        flexDirection="column"
        borderTopWidth={1}
        borderColor="border.muted"
        pt={3}
        pb={4}
      >
        <Flex flex={1}>
          <Box flex={1}>
            <Text fontWeight="bold">{r.contact.name}</Text>
            <Text noOfLines={2}>{r.contact.email}</Text>
          </Box>
          <Text fontSize="xs" color="text.muted">
            {`${date.format('MMM Do [‘]YY')}`}
          </Text>
        </Flex>
        <Grid
          mt={4}
          templateColumns={{ base: 'repeat(2, 1fr)', md: 'repeat(3, 1fr)' }}
          gap={2}
        >
          <GridItem colSpan={{ base: 2, sm: 1 }}>
            <Button
              w="100%"
              icon={r.status === 'active' ? 'Remove' : 'Undo'}
              size="sm"
              secondary
              isLoading={isDismissing}
              onClick={() => dismissRequest()}
              isDisabled={isEnrolling}
            >
              {r.status === 'active' ? 'Dismiss' : 'Undo Dismiss'}
            </Button>
          </GridItem>
          <GridItem
            colSpan={{ base: 2, sm: 1 }}
            display={r.status === 'active' ? 'auto' : 'none'}
          >
            <LinkButton
              w="100%"
              icon="Send"
              size="sm"
              variant="outline"
              href={`mailto:${r.contact.email}`}
              isDisabled={isDismissing || isEnrolling}
              pointerEvents={isDismissing || isEnrolling ? 'none' : 'auto'}
            >
              Send Email
            </LinkButton>
          </GridItem>
          <GridItem
            colSpan={{ base: 2, sm: 2, md: 1 }}
            display={r.status === 'active' ? 'auto' : 'none'}
          >
            <Button
              w="100%"
              icon={isEnrolling ? 'Close' : 'PersonAdd'}
              // variant={isEnrolling ? 'outline' : 'solid'}
              variant="outline"
              size="sm"
              isDisabled={isDismissing}
              onClick={() => setIsEnrolling(!isEnrolling)}
            >
              {isEnrolling ? 'Cancel' : 'Enroll on Class'}
            </Button>
          </GridItem>
        </Grid>
        <Collapse in={isEnrolling}>
          <Box mt={4}>
            {Object.values(classOptions).length > 0 ? (
              <>
                <Text fontSize="sm" color="text.muted" mb={2}>
                  Which class do you want to enroll this learner on?
                </Text>
                <LabelSelect
                  isDisabled={false}
                  label=""
                  // onChange={(e) => setValue('course', parseInt(e.target.value))}
                  // error={!!errors.course}
                  // defaultValue={courseValue}
                  defaultValue={undefined}
                  options={{
                    '': ' Select a private class...',
                    ...classOptions,
                  }}
                  onChange={(e) => setSelectedCohortId(e.target.value)}
                  // labelStyleProps={labelStyleProps}
                  // labelPosition={isMobile ? 'top' : 'inline'}
                  labelPosition="top"
                  color={selectedCohortId ? 'text.default' : 'text.muted'}
                />
                <Flex justifyContent="flex-end">
                  <Button
                    size="sm"
                    onClick={() => setShowEnrolModal(true)}
                    icon="ArrowForward"
                    iconPosition="right"
                    isDisabled={!selectedCohortId}
                  >
                    Select Class
                  </Button>
                </Flex>
              </>
            ) : (
              <>
                <Text>
                  No active private classes available for this course.
                </Text>
                <Text fontSize="sm" color="text.muted">
                  To enroll learners onto this course, first{' '}
                  <Link to={`${navRoutes.cms.classes.path()}?p=new`}>
                    <chakra.span
                      color="common.primary"
                      fontWeight="bold"
                      _hover={{ textDecoration: 'underline' }}
                    >
                      create a private class
                    </chakra.span>
                  </Link>
                  .
                </Text>
              </>
            )}
          </Box>
        </Collapse>
      </Flex>
    </>
  );
};

const EnrolmentRequestsPopup: React.FC<EnrolmentRequestsPopupProps> = ({
  isOpen,
  onClose,
  courseSlug,
  courseTitle,
  isLicensed,
  validLicenses,
}) => {
  const [showDismissedRequests, setShowDismissedRequests] = useState(false);

  const { requests: requestsLoading } = hooks.useLoadingDataState(
    {
      requests: {
        actions: isOpen
          ? [
              () =>
                mailActions.fetchList({
                  slug: courseSlug,
                  fetchNextPage: true,
                }),
            ]
          : [],
      },
    },
    [isOpen]
  );

  const requests = useSelector(
    (state: GlobalState) => state.cms.mail[courseSlug]?.recipients
  );

  const activeRequests = requests?.filter((r) => r.status === 'active');
  const dismissedRequests = requests?.filter((r) => r.status !== 'active');

  const steps = [
    {
      label: 'Requests',
      icon: <MdIcon name="PersonAdd" />,
      hideNextButton: true,
      content: (
        <Box mb={-4}>
          <Text color="text.muted" fontSize="sm" mb={4}>
            Here's a list of people who have requested enrollment on this
            course. You can choose to dismiss their request, reach out to send
            or request more details, or enroll them straight onto a class.
          </Text>
          {requestsLoading || !requests ? (
            <Box mt={6} mb={10}>
              <Loading />
            </Box>
          ) : (
            activeRequests.map((r) => (
              <Request
                {...r}
                courseSlug={courseSlug}
                isLicensed={isLicensed}
                validLicenses={validLicenses}
              />
            ))
          )}
          {dismissedRequests?.length > 0 && (
            <>
              <Divider my={4} />
              <Flex
                justifyContent="flex-end"
                mx={{ base: 'defaultPadding', md: 0 }}
              >
                <Button
                  size="xs"
                  variant="ghost"
                  icon={showDismissedRequests ? 'ExpandLess' : 'ExpandMore'}
                  onClick={() =>
                    setShowDismissedRequests(!showDismissedRequests)
                  }
                  mb={showDismissedRequests ? 4 : 0}
                >
                  {`${
                    showDismissedRequests ? 'Hide' : 'Show'
                  } Dismissed Requests`}
                </Button>
              </Flex>
              {showDismissedRequests &&
                dismissedRequests.map((r) => (
                  <Request
                    {...r}
                    courseSlug={courseSlug}
                    isLicensed={isLicensed}
                    validLicenses={validLicenses}
                  />
                ))}
            </>
          )}
        </Box>
      ),
    },
  ];

  return (
    <StepsModal
      heading="Enrollment Requests"
      subheading={courseTitle}
      isOpen={isOpen}
      onClose={onClose}
      steps={steps}
      forceHorizontalSteps
      hideStepLabels
      hideAllStepLabels
      hideStepList
      hideIndicators
      bigNext
      disablePrev
      verticalCenter={false}
    />
  );
};

export default EnrolmentRequestsPopup;
