import React from 'react';

import { Card } from '@workshop/ui';

import { SignupData } from 'types/common';

import SignupForm from './SignupForm';

interface SignupCard {
  buttonLabel?: string;
  isLoading?: boolean;
  email?: string;
  onSubmit: (data: SignupData) => Promise<void>;
  hideSocialButtons?: boolean;
}

const SignupCard: React.FunctionComponent<SignupCard> = ({
  buttonLabel,
  children,
  email,
  isLoading,
  onSubmit,
  hideSocialButtons = false,
}) => (
  <Card
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    maxWidth={450}
    width="100%"
    padding={{ base: 6, sm: 10 }}
  >
    {children}
    <SignupForm
      buttonLabel={buttonLabel}
      email={email}
      isLoading={isLoading}
      onSubmit={onSubmit}
      hideSocialButtons={hideSocialButtons}
      buttonIcon="ArrowForward"
    />
  </Card>
);

export default SignupCard;
