import moment from 'moment';

import React, { useState, useEffect } from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';

import { PLATFORM } from 'constants/env';
import { PRO_ORGS, PRO_TEAM_MEMBERS_LIMIT } from 'constants/organisation';

import {
  Box,
  Flex,
  Text,
  Button,
  MdIcon,
  SimpleGrid,
  useTheme,
} from '@workshop/ui';

import { GlobalState } from 'types';
import { Organisation, RoleState, PERMISSION_SLUGS } from 'types/common';
import { License } from 'types/cms';

import { hooks, getParamFromUrl, analytics } from 'utils';
import { useWindowDimensions } from 'utils/hooks/useDimensions';

import { organisationActions } from 'redux/actions/common';
import { enrolmentActions } from 'redux/actions/cms';
import { useHasPermission } from 'redux/selectors';
import { useDismissedInformationCards } from 'redux/selectors/user';

import {
  SectionTitle,
  InPageNav,
  InformationCard,
  ProCta,
} from 'components/Common';
import {
  OrganisationDetailsCard,
  TeamMembersCard,
  CreateLicenseModal,
  EditLicenseModal,
  LicensesTable,
  StripeConnectCard,
  PaymentsWelcomeModal,
  ZapierEmbed,
} from '.';

import { ScreenWrapper } from 'screens/common/ScreenWrapper';

import SubscriptionManagement from 'screens/common/Settings/src/SubscriptionManagement';

type PropsFromRedux = ConnectedProps<typeof connector>;

interface MyOrganisationProps extends PropsFromRedux, RouteComponentProps {}

const getMemberRoles = (roles: number[] | undefined, roleState: RoleState) =>
  roles?.map((id) => roleState[id]?.name || '').filter((a) => a) || [];

const MyOrganisation: React.FC<MyOrganisationProps> = ({
  currentTeam,
  invitations,
  organisationProfile,
  permissions,
  roles,
  teamMembers,
  licenses,
  costRecords,
  subscriptions,
  location,
}) => {
  const [currentView, setCurrentView] = useState('account');
  const [showCreateLicense, setShowCreateLicense] = useState(false);
  const [selectedLicense, setSelectedLicense] = useState<License | null>(null);

  const popupParam = getParamFromUrl(location, 'p');
  const [showPaymentsWelcome, setShowPaymentsWelcome] = useState(
    popupParam === 'start'
  );

  const dismissedInformationCards = useDismissedInformationCards();

  useEffect(() => {
    analytics.track(
      `Viewed ${
        currentView === 'account'
          ? 'Account'
          : currentView === 'team'
          ? 'Team'
          : currentView === 'agreements'
          ? 'Agreements'
          : currentView === 'payments'
          ? 'Payments'
          : 'Admin'
      } Tab`
    );
  }, [currentView]);

  const currentTeamOrg = organisationProfile?.id;

  const dispatch = useDispatch();
  // Certain API calls and UI elements should only be available if the user
  // has edit member permissions
  const isViewMembersDisabled = !useHasPermission(
    PERMISSION_SLUGS.can_view_members
  );

  const isEditBillingDisabled =
    !useHasPermission(PERMISSION_SLUGS.can_edit_billing_details) ||
    PLATFORM !== 'steppit';

  // TODO: Enable on Steppit once billing is automated
  const isViewAgreementsDisabled =
    !useHasPermission(PERMISSION_SLUGS.can_view_agreements) ||
    PLATFORM === 'steppit';

  const isEditAgreementsDisabled = !useHasPermission(
    PERMISSION_SLUGS.can_edit_agreements
  );

  const isViewReportsDisabled = !useHasPermission(
    PERMISSION_SLUGS.can_view_reports
  );

  const {
    teamMembership: teamsLoading,
    organisationProfile: organisationLoading,
    invitations: invitationsLoading,
    licenses: licensesLoading,
    costRecords: costRecordsLoading,
    subscriptions: subscriptionsLoading,
  } = hooks.useLoadingDataState(
    {
      teamMembership: {
        actions:
          currentTeam && !isViewMembersDisabled
            ? [() => organisationActions.listTeamMembers()]
            : [],
      },
      organisationProfile: {
        actions: currentTeam
          ? [() => organisationActions.fetchOrganisationProfile(currentTeam)]
          : [],
      },
      invitations: {
        actions: !isViewMembersDisabled
          ? [() => organisationActions.listMemberInvitation()]
          : [],
      },
      licenses: {
        actions:
          currentTeam && !(isViewAgreementsDisabled && isEditBillingDisabled)
            ? [() => enrolmentActions.listLicenses()]
            : [],
      },
      costRecords: {
        actions:
          currentTeam && !isViewReportsDisabled
            ? [() => enrolmentActions.listCostRecords()]
            : [],
      },
      subscriptions: {
        actions:
          PLATFORM === 'steppit'
            ? [() => organisationActions.fetchSubscriptions()]
            : [],
      },
    },
    [currentTeam]
  );

  const theme = useTheme();
  const windowDimensions = useWindowDimensions();
  const isMobile = windowDimensions.width < parseInt(theme.breakpoints.md, 10);

  const isLoading = teamsLoading || organisationLoading || invitationsLoading;

  const {
    accountName = null,
    accountNumber = null,
    address = null,
    city = null,
    contactEmail = null,
    contactNumber = null,
    introduction = null,
    logo = null,
    logoDark = null,
    name = null,
    postCode = null,
    sortCode = null,
    vatNumber = null,
    websiteUrl = null,
    facebookUrl = null,
    instagramUrl = null,
    twitterUrl = null,
    youtubeUrl = null,
    tiktokUrl = null,
    discordUrl = null,
    patreonUrl = null,
    handle = null,
    persona = null,
    isPro: orgIsPro,
    stripeAccountId,
    proPlan,
    proInvoiceUuid,
    currentMonthlyAiCredits,
    currentExtraAiCredits,
  } = organisationProfile || {};

  const members = teamMembers.map((m) => {
    const memberPerm: string[] = [];
    m.permissions?.forEach(
      (id) => permissions[id] && memberPerm.push(permissions[id].name)
    );

    return {
      id: m.id,
      name: m.user.name,
      email: m.user.email,
      roles: getMemberRoles(m.roles, roles),
      status: 'active' as const,
      invitationSentDate: '',
    };
  });

  const invitedMembers = Object.values(invitations)
    .filter((i) => i.team.id === currentTeam)
    .map(({ id, sent, name, email, accepted, roles: invitedRoles }) => ({
      id,
      invitationSentDate: sent,
      name,
      email,
      roles: getMemberRoles(invitedRoles, roles),
      status: accepted ? ('active' as const) : ('invited' as const),
    }));

  const allMembers = uniqBy([...members, ...invitedMembers], 'email');

  const fixUrl = (url: string) => {
    if (url && !/^https?:\/\//i.test(url)) {
      return 'https://' + url;
    } else return url;
  };

  const updateOrganisationData = async (data: Partial<Organisation>) => {
    if (!currentTeam) return;
    analytics.track('Channel Details Edited');

    const fixedData = {
      ...data,
      ...(data.websiteUrl ? { websiteUrl: fixUrl(data.websiteUrl) } : {}),
      ...(data.facebookUrl ? { facebookUrl: fixUrl(data.facebookUrl) } : {}),
      ...(data.instagramUrl ? { instagramUrl: fixUrl(data.instagramUrl) } : {}),
      ...(data.twitterUrl ? { twitterUrl: fixUrl(data.twitterUrl) } : {}),
      ...(data.youtubeUrl ? { youtubeUrl: fixUrl(data.youtubeUrl) } : {}),
      ...(data.tiktokUrl ? { tiktokUrl: fixUrl(data.tiktokUrl) } : {}),
      ...(data.discordUrl ? { discordUrl: fixUrl(data.discordUrl) } : {}),
      ...(data.patreonUrl ? { patreonUrl: fixUrl(data.patreonUrl) } : {}),
    };

    return await dispatch(
      organisationActions.updateOrganisationProfile(currentTeam, {
        ...fixedData,
      })
    );
  };

  const createdLicenses = Object.values(licenses).filter(
    (l) => l.licensor.id === currentTeamOrg
  );

  const receivedLicenses = Object.values(licenses).filter(
    (l) => l.licensee.id === currentTeamOrg
  );

  const monthlyLicenses = receivedLicenses.filter(
    (l) =>
      l.allLicenseeCourses &&
      l.expiryDate &&
      moment(l.expiryDate).isAfter(moment()) &&
      moment(l.expiryDate).isBefore(moment().endOf('month').add(1, 'days'))
  );

  const monthlyLicenseEnrolmentsRemaining = monthlyLicenses.reduce(
    (n, { totalEnrolments, enrolments }) =>
      n + (totalEnrolments - enrolments.length),
    0
  );

  const noExpiryLicenses = receivedLicenses.filter(
    (l) =>
      l.allLicenseeCourses &&
      !l.expiryDate &&
      l.totalEnrolments > l.enrolments.length
  );

  const noExpiryLicensesEnrolmentsRemaining = noExpiryLicenses.reduce(
    (n, { totalEnrolments, enrolments }) =>
      n + (totalEnrolments - enrolments.length),
    0
  );

  const isPro = Boolean(
    orgIsPro ||
      PLATFORM === 'workshop' ||
      (currentTeam && PRO_ORGS.includes(currentTeam))
  );

  const numTeamMembersAllowed =
    organisationProfile?.proPlan?.details?.teamMembers || 1;

  const isProWithTeamMembers = isPro && numTeamMembersAllowed > 1;

  const tabs: {
    slug: string;
    label: string;
    icon: string;
    proNotification?: boolean;
  }[] = [
    {
      slug: 'account',
      label: 'Account',
      icon: 'Work',
    },
  ];

  if (!isViewMembersDisabled) {
    tabs.push({
      slug: 'team',
      label: 'Team',
      icon: 'Group',
      proNotification:
        isPro &&
        isProWithTeamMembers &&
        !dismissedInformationCards?.includes('pro_team'),
    });
  }

  if (!isViewAgreementsDisabled) {
    tabs.push({
      slug: 'agreements',
      label: 'Agreements',
      icon: 'Description',
    });
  }

  if (!isEditBillingDisabled) {
    tabs.push({
      slug: 'payments',
      label: 'Payments',
      icon: 'Payments',
    });
    if (PLATFORM === 'steppit') {
      tabs.push({
        slug: 'subscriptions',
        label: 'Pro Subscription',
        icon: 'CardMembership',
      });
      tabs.push({
        slug: 'integrations',
        label: 'Integrations',
        icon: 'AccountTree',
        proNotification:
          isPro && !dismissedInformationCards?.includes('pro_integrations'),
      });
    }
  }

  // TODO: Make sure schools can always add members – make them pro by default?
  const memberLimitReached =
    PLATFORM === 'steppit'
      ? proPlan?.details
        ? allMembers.length >= proPlan.details.teamMembers
        : allMembers.length >= PRO_TEAM_MEMBERS_LIMIT
      : false;

  return (
    <ScreenWrapper>
      <InPageNav
        tabs={tabs}
        initialTab="account"
        onSwitchTab={(activeTab) => setCurrentView(activeTab)}
        navByParams
      />
      {currentView === 'account' ? (
        <Flex direction="column" flex={1}>
          <Box mx={{ base: 'defaultMargin', md: 0 }}>
            <InformationCard id="account_screen" mb={6} />
          </Box>
          <Box mb="defaultMargin" flex={1}>
            <SectionTitle title="Details" />
            <OrganisationDetailsCard
              isLoading={isLoading}
              onSubmit={updateOrganisationData}
              introduction={introduction || ''}
              contactEmail={contactEmail || ''}
              contactNumber={contactNumber || ''}
              logo={logo || ''}
              logoDark={logoDark || ''}
              name={name || ''}
              websiteUrl={websiteUrl || ''}
              facebookUrl={facebookUrl || ''}
              instagramUrl={instagramUrl || ''}
              twitterUrl={twitterUrl || ''}
              youtubeUrl={youtubeUrl || ''}
              tiktokUrl={tiktokUrl || ''}
              discordUrl={discordUrl || ''}
              patreonUrl={patreonUrl || ''}
              handle={handle || ''}
              persona={persona || 'general'}
            />
          </Box>
        </Flex>
      ) : currentView === 'team' && !isViewMembersDisabled ? (
        <Flex direction="column" flex={1}>
          {isPro && isProWithTeamMembers ? (
            <Box mx={{ base: 'defaultMargin', md: 0 }}>
              <InformationCard
                id="pro_team"
                information={{
                  title: 'Team Members',
                  description: `You can now expand your team to up to ${numTeamMembersAllowed} members by inviting them to join your channel.`,
                  pro: true,
                }}
                mb={6}
              />
            </Box>
          ) : (
            <Box mx={{ base: 'defaultMargin', md: 0 }}>
              <InformationCard id="team_screen" mb={6} />
            </Box>
          )}

          <Box>
            <SectionTitle title="Team Members" />
            <TeamMembersCard
              isLoading={isLoading && isEmpty(members)}
              members={allMembers}
              // TODO: Set limit on org level based on subscription
              canAddNew={isPro && !memberLimitReached}
            />
            {memberLimitReached && (
              <Text
                color="text.muted"
                textAlign="center"
                fontSize="sm"
                pt={6}
                px="defaultMargin"
              >
                Member limit reached
              </Text>
            )}
            {!memberLimitReached && !isPro && (
              <Flex
                mt={7}
                mb={6}
                mx={{ base: 2, md: 0 }}
                justifyContent="center"
              >
                <ProCta label="Invite team members with" />
              </Flex>
            )}
          </Box>
        </Flex>
      ) : currentView === 'agreements' && !isViewAgreementsDisabled ? (
        <>
          <Flex direction="column" flex={1}>
            <Box mx={{ base: 'defaultMargin', md: 0 }}>
              <InformationCard id="agreements_screen" mb={6} />
            </Box>
            <Flex
              mb={4}
              flexDirection={{ base: 'column', sm: 'row' }}
              alignItems={{ base: 'flex-start', sm: 'normal' }}
            >
              <SectionTitle
                flex={1}
                mb={{ base: 3, sm: 0 }}
                title="Bulk Enrollment Agreements"
              />
              {!isEditAgreementsDisabled && (
                <Button
                  size="sm"
                  icon="NoteAdd"
                  mx={{ base: 'defaultMargin', md: 0 }}
                  onClick={() => setShowCreateLicense(true)}
                >
                  New Agreement
                </Button>
              )}
            </Flex>

            <Text
              fontSize="sm"
              fontWeight="semibold"
              marginX={{ base: 'defaultMargin', md: 0 }}
            >
              {`Created By ${name}`}
            </Text>
            <LicensesTable
              licenses={createdLicenses}
              isMobile={isMobile}
              type="created"
              onSelectLicense={(l) => setSelectedLicense(l)}
            />
            <Text
              fontSize="sm"
              fontWeight="semibold"
              marginX={{ base: 'defaultMargin', md: 0 }}
            >
              {`Created For ${name}`}
            </Text>
            <LicensesTable
              licenses={receivedLicenses}
              isMobile={isMobile}
              type="received"
              onSelectLicense={(l) => setSelectedLicense(l)}
            />
          </Flex>
          {showCreateLicense && (
            <CreateLicenseModal
              title="New Bulk Enrollment Agreement"
              isOpen
              onClose={() => setShowCreateLicense(false)}
              onCancel={() => setShowCreateLicense(false)}
              onSave={() => null}
              modalSize="lg"
            />
          )}
          {!!selectedLicense && (
            <EditLicenseModal
              title="Agreement Details"
              isOpen
              onClose={() => setSelectedLicense(null)}
              onCancel={() => setSelectedLicense(null)}
              onSave={() => null}
              license={selectedLicense}
              modalSize="lg"
              isLoading={costRecordsLoading}
              costRecord={Object.values(costRecords).find(
                (record) => record.contentLicense === selectedLicense.id
              )}
              showLicensorInfo={selectedLicense.licensor.id === currentTeamOrg}
              showLicenseeInfo={selectedLicense.licensee.id === currentTeamOrg}
            />
          )}
        </>
      ) : currentView === 'payments' && !isEditBillingDisabled ? (
        <Flex direction="column" flex={1}>
          <Box mx={{ base: 'defaultMargin', md: 0 }}>
            <InformationCard id="payments_screen" mb={6} />
          </Box>
          <StripeConnectCard />
          {/* <Flex justifyContent="flex-end">
            <Button
              size="sm"
              variant="ghost"
              icon="HelpOutline"
              onClick={() => setShowPaymentsWelcome(true)}
            >
              How Payments Work
            </Button>
          </Flex>
          {showPaymentsWelcome && (
            <PaymentsWelcomeModal
              isOpen
              onClose={() => setShowPaymentsWelcome(false)}
              isConnected={!!stripeAccountId}
            />
          )} */}
        </Flex>
      ) : currentView === 'subscriptions' &&
        !isEditBillingDisabled &&
        PLATFORM === 'steppit' ? (
        <Flex direction="column" flex={1}>
          <SubscriptionManagement
            activeSubscriptions={uniqBy(subscriptions, 'id')}
            isLoading={subscriptionsLoading}
            showPortalLink
            showPlan
            proPlan={proPlan}
            proInvoiceUuid={proInvoiceUuid}
            currentMonthlyAiCredits={currentMonthlyAiCredits}
            currentExtraAiCredits={currentExtraAiCredits}
            monthlyLicenseEnrolmentsRemaining={
              monthlyLicenseEnrolmentsRemaining
            }
            noExpiryLicensesEnrolmentsRemaining={
              noExpiryLicensesEnrolmentsRemaining
            }
          />
        </Flex>
      ) : currentView === 'integrations' &&
        !isEditBillingDisabled &&
        PLATFORM === 'steppit' ? (
        <Flex direction="column" flex={1}>
          {isPro ? (
            <>
              <Box mx={{ base: 'defaultMargin', md: 0 }}>
                <InformationCard
                  id="pro_integrations"
                  information={{
                    title: 'Integrations & Automations',
                    description: `You can now use Zapier to create automations and integrate Steppit with all your favorite tools.`,
                    pro: true,
                  }}
                  mb={6}
                />
              </Box>
              <ZapierEmbed />
            </>
          ) : (
            <Box
              mx={{ base: 'defaultMargin', md: 0 }}
              textAlign={{ base: 'center', md: 'left' }}
            >
              <Text mb={6}>
                With Pro, you can create automations and integrate Steppit with
                all the tools and software you rely on via our Zapier
                integration. For example...
              </Text>
              <SimpleGrid
                color="text.muted"
                gap={{ base: 8, md: 4 }}
                columns={{ base: 1, md: 3 }}
                maxW={{ base: '400px', md: 'none' }}
                mx="auto"
              >
                <Flex
                  flexDirection="column"
                  alignItems={{ base: 'center', md: 'flex-start' }}
                >
                  <MdIcon name="School" fontSize="4xl" mb={4} />
                  <Text>
                    Enroll learners after they purchase on another platform like
                    Shopfiy or WooCommerce.
                  </Text>
                </Flex>
                <Flex
                  flexDirection="column"
                  alignItems={{ base: 'center', md: 'flex-start' }}
                >
                  <MdIcon name="Chat" fontSize="4xl" mb={4} />
                  <Text>
                    Get notified on Slack and add a row onto a Google Sheet when
                    someone makes a purchase.
                  </Text>
                </Flex>
                <Flex
                  flexDirection="column"
                  alignItems={{ base: 'center', md: 'flex-start' }}
                >
                  <MdIcon name="Done" fontSize="4xl" mb={4} />
                  <Text>
                    Set rules to automatically create a new class whenever one
                    is running out.
                  </Text>
                </Flex>
              </SimpleGrid>
              <Flex
                mt={12}
                mb={6}
                mx={{ base: 2, md: 0 }}
                justifyContent="center"
              >
                <ProCta label="Integrate Steppit with all your tools with" />
              </Flex>
            </Box>
          )}
        </Flex>
      ) : null}
    </ScreenWrapper>
  );
};

const mapStateToProps = (state: GlobalState) => ({
  currentTeam: state.organisation.currentTeam as number,
  invitations: state.organisation.invitations,
  organisationProfile: state.organisation.currentTeam
    ? state.organisation.teamProfiles[state.organisation.currentTeam]
    : null,
  teamMembers: Object.values(state.organisation.currentTeamMemberList),
  permissions: state.organisation.permissions,
  roles: state.organisation.roles,
  licenses: state.cms.enrolment.license,
  costRecords: state.cms.enrolment.costRecord,
  subscriptions: state.organisation.subscriptions,
});

const connector = connect(mapStateToProps);

export default connector(MyOrganisation);
