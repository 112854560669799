import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { FaGem } from 'react-icons/fa';
import { loadStripe } from '@stripe/stripe-js';

import navRoutes from 'navigation/Routes';

import { analytics, getParamFromUrl } from 'utils';
import { GlobalState } from 'types';

import { organisationActions } from 'redux/actions/common';
import { useUser, useCurrentTeamProfile } from 'redux/selectors';

import {
  Box,
  Button,
  LinkButton,
  Card,
  Badge,
  Text,
  Flex,
  Skeleton,
  Table,
  Thead,
  Grid,
  Tr,
  Th,
  Tbody,
  Td,
  VStack,
  MdIcon,
  Stack,
  Divider,
  chakra,
  useColorModeValue,
  useMediaQuery,
} from '@workshop/ui';

import {
  PRO_TIER_COLORS,
  CREDITS_PRICE,
  CREDITS_PRICE_PER_UNIT,
  ENROLMENTS_PRICE,
  ENROLMENTS_PRICE_PER_UNIT,
} from 'constants/organisation';
import { PLATFORM_EMAIL } from 'constants/common';

import { SubscriptionData, ProPlan } from 'types/common';

import { SectionTitle, StepsModal, InformationCard } from 'components/Common';

// TODO: Move Stripe actions to redux folder
import {
  getCheckoutSession,
  getConfig,
} from 'screens/common/Checkout/src/CheckoutSteps/api';
interface SubscriptionManagementProps {
  activeSubscriptions: SubscriptionData[];
  isLoading?: boolean;
  showPortalLink?: boolean;
  showPlan?: boolean;
  proPlan?: ProPlan | null;
  proInvoiceUuid?: string | null;
  currentMonthlyAiCredits?: number;
  currentExtraAiCredits?: number;
  monthlyLicenseEnrolmentsRemaining?: number;
  noExpiryLicensesEnrolmentsRemaining?: number;
}

const PlanFeature = ({
  infoOnClick,
  children,
}: {
  infoOnClick?: () => void;
  children: React.ReactElement | string;
}) => {
  return (
    <Flex alignItems="center" mt={2}>
      <Flex shrink={0}>
        <MdIcon name="TaskAlt" color="common.progress" />
      </Flex>
      <Box ml={4}>
        <chakra.span>{children}</chakra.span>
      </Box>
      {infoOnClick ? (
        <Box
          ml={1}
          mb={2}
          onClick={infoOnClick}
          cursor="pointer"
          _hover={{ opacity: 0.8 }}
        >
          <MdIcon name="HelpOutline" boxSize={3.5} color="text.muted" />
        </Box>
      ) : null}
    </Flex>
  );
};

interface PackPopupProps {
  isOpen: boolean;
  onClose: (confirm?: boolean) => void;
  product: '' | 'ai' | 'enrollment';
  openCheckout: (id: string) => Promise<void>;
  isDisabled: boolean;
}

const PackPopup: React.FC<PackPopupProps> = ({
  isOpen,
  onClose,
  product,
  openCheckout,
  isDisabled,
}) => {
  const [isLoadingPack, setIsLoadingPack] = useState('');
  const [isMobile] = useMediaQuery('(max-width: 767px)');

  const geoLocation = useSelector(
    (state: GlobalState) => state.background.location
  );
  const currency = geoLocation?.currency || 'USD';

  const options =
    product === 'ai'
      ? [
          {
            id: '1k',
            name: '1,000',
            price: CREDITS_PRICE(currency, '1k'),
            pricePerUnit: CREDITS_PRICE_PER_UNIT(currency, '1k'),
            saving: 0,
            color: 'blue',
          },
          {
            id: '5k',
            name: '5,000',
            price: CREDITS_PRICE(currency, '5k'),
            pricePerUnit: CREDITS_PRICE_PER_UNIT(currency, '5k'),
            saving: 20,
            color: 'green',
          },
          {
            id: '20k',
            name: '20,000',
            price: CREDITS_PRICE(currency, '20k'),
            pricePerUnit: CREDITS_PRICE_PER_UNIT(currency, '20k'),
            saving: 30,
            color: 'orange',
          },
          {
            id: '50k',
            name: '50,000',
            price: CREDITS_PRICE(currency, '50k'),
            pricePerUnit: CREDITS_PRICE_PER_UNIT(currency, '50k'),
            saving: 40,
            color: 'purple',
          },
        ]
      : [
          {
            id: '10',
            name: '10',
            price: ENROLMENTS_PRICE(currency, '10'),
            pricePerUnit: ENROLMENTS_PRICE_PER_UNIT(currency, '10'),
            saving: 0,
            color: 'blue',
          },
          {
            id: '50',
            name: '50',
            price: ENROLMENTS_PRICE(currency, '50'),
            pricePerUnit: ENROLMENTS_PRICE_PER_UNIT(currency, '50'),
            saving: 20,
            color: 'green',
          },
          {
            id: '200',
            name: '200',
            price: ENROLMENTS_PRICE(currency, '200'),
            pricePerUnit: ENROLMENTS_PRICE_PER_UNIT(currency, '200'),
            saving: 30,
            color: 'orange',
          },
          {
            id: '500',
            name: '500',
            price: ENROLMENTS_PRICE(currency, '500'),
            pricePerUnit: ENROLMENTS_PRICE_PER_UNIT(currency, '500'),
            saving: 40,
            color: 'purple',
          },
        ];

  const steps = [
    {
      label: 'Digests',
      icon: <MdIcon name="Done" />,
      hideNextButton: true,
      content: (
        <>
          <Text mb={6}>
            {`Select a pack below to top up your lifetime ${
              product === 'ai' ? 'AI credits' : 'enrollments'
            } (with no expiry date):`}
          </Text>
          <Grid
            w="full"
            gap={3}
            justifyContent="center"
            templateColumns={{
              base: 'repeat(2, 1fr)',
              md: 'repeat(4, 1fr)',
            }}
          >
            {options.map((o) => (
              <Flex
                h="100%"
                flexDirection="column"
                alignItems="center"
                textAlign="center"
                bg={`${o.color}.50`}
                color={`${o.color}.700`}
                borderRadius="md"
                py={6}
                px={3}
              >
                <Flex
                  boxSize={16}
                  alignItems="center"
                  justifyContent="center"
                  bg={`${o.color}.100`}
                  fontSize="3xl"
                  fontWeight="bold"
                  borderRadius="full"
                  // borderRadius={product === 'ai' ? 'full' : 'md'}
                  mb={3}
                >
                  <MdIcon
                    name={product === 'ai' ? 'AutoAwesome' : 'GroupAdd'}
                  />
                </Flex>
                <Text fontSize="xl" fontWeight="bold">
                  {o.name}
                </Text>
                <Text mb={3}>
                  {product === 'ai' ? 'AI credits' : 'enrollments'}
                </Text>
                <Text fontSize="xl" fontWeight="bold">
                  {o.price}
                </Text>
                <Text fontSize="xs" mb={2}>
                  {`${o.pricePerUnit} per ${
                    product === 'ai' ? '100 credits' : 'enrollment'
                  }`}
                </Text>
                <Text fontSize="sm" fontWeight="semibold" mb={6}>
                  {o.saving > 0 ? `Save ${o.saving}%` : ''}
                </Text>
                <Flex flex={1} />
                <Button
                  size="sm"
                  onClick={async () => {
                    setIsLoadingPack(o.id);
                    await openCheckout(o.id);
                    setIsLoadingPack(o.id);
                  }}
                  isLoading={isLoadingPack === o.id}
                  isDisabled={isDisabled}
                >
                  Buy Pack
                </Button>
              </Flex>
            ))}
          </Grid>
          <Text mt={6} fontSize="sm" color="text.muted">
            {`Note: ${
              product === 'ai' ? 'AI credits' : 'Enrollments'
            } can only be redeemed with Pro, and will be lost if your Pro membership ends.`}
          </Text>
        </>
      ),
    },
  ];

  return (
    <StepsModal
      heading={product === 'ai' ? 'AI Credit Packs' : 'Enrollment Packs'}
      isOpen={isOpen}
      onClose={onClose}
      steps={steps}
      forceHorizontalSteps
      hideStepLabels
      hideAllStepLabels
      hideStepList
      hideIndicators
      bigNext
      disablePrev
      verticalCenter={false}
      modalSize={isMobile ? 'md' : '3xl'}
    />
  );
};

const SubscriptionManagement: React.FC<SubscriptionManagementProps> = ({
  activeSubscriptions,
  isLoading = false,
  showPortalLink = false,
  showPlan = false,
  proPlan = null,
  proInvoiceUuid = null,
  currentMonthlyAiCredits = 0,
  currentExtraAiCredits = 0,
  monthlyLicenseEnrolmentsRemaining = 0,
  noExpiryLicensesEnrolmentsRemaining = 0,
}) => {
  const [isLinkLoading, setIsLinkLoading] = useState(false);
  const [stripePromise, setStripePromise] = useState(null);
  const [packPopupProduct, setPackPopupProduct] = useState<
    'ai' | 'enrollment' | ''
  >('');
  const [error, setError] = useState<{
    error: boolean;
    message: string;
  } | null>(null);
  const dispatch = useDispatch();

  const user = useUser();
  const teamProfile = useCurrentTeamProfile();

  const location = useLocation();
  const successParam = getParamFromUrl(location, 'success');
  const popupParam = getParamFromUrl(location, 'p');

  const initData = async () => {
    setIsLinkLoading(true);
    // @ts-ignore
    const configResponse: $Exact<{ error: any }> | StripeConfig =
      await getConfig();
    setIsLinkLoading(false);

    if (configResponse.error) {
      setError({
        error: true,
        message: `An error occurred. Please try refreshing the page, or if the problem persists, contact us on support${PLATFORM_EMAIL}`,
      });
      return;
    }

    const stripeJs = document.createElement('script');
    stripeJs.src = 'https://js.stripe.com/v3/';
    stripeJs.async = true;
    stripeJs.onload = () => {
      const sp = loadStripe(configResponse?.stripePublishableKey);
      // @ts-ignore
      setStripePromise(sp);
    };
    if (document.body) document.body.appendChild(stripeJs);
  };

  useEffect(() => {
    initData();
  }, []);

  useEffect(() => {
    if (popupParam === 'ai') {
      setPackPopupProduct('ai');
    }
    if (popupParam === 'enrollment') {
      setPackPopupProduct('enrollment');
    }
  }, [popupParam]);

  const openCustomerPortal = async () => {
    setIsLinkLoading(true);
    const res = await dispatch(organisationActions.fetchSubscriptions(true));
    if (!res?.error) {
      // @ts-ignore
      if (res?.payload?.redirect) {
        // @ts-ignore
        return window.open(res.payload.redirect, '_self');
      }
    }
    setIsLinkLoading(false);
  };

  const tierColor = proPlan ? PRO_TIER_COLORS[proPlan.tier] : 'blue';
  const mainTierColor = useColorModeValue(
    `${tierColor}.550`,
    `${tierColor}.200`
  );
  const bgTierColor = useColorModeValue(`${tierColor}.50`, `${tierColor}.700`);
  const borderTierColor = useColorModeValue(
    `${tierColor}.100`,
    `${tierColor}.200`
  );

  const openCheckout = async (
    product: 'ai' | 'enrollment' | '',
    id: string
  ) => {
    if (!product) return;
    // Open checkout
    setIsLinkLoading(true);
    const successPath = `${window.location.protocol}//${
      window.location.host
    }${navRoutes.cms.myOrganisation.path()}?tab=subscriptions&success=${product}`;
    const cancelPath = `${window.location.protocol}//${
      window.location.host
    }${navRoutes.cms.myOrganisation.path()}?tab=subscriptions`;
    analytics.track(
      `${product === 'ai' ? 'AI Credit' : 'Enrollment'} Pack Checkout Started`
    );
    if (teamProfile) {
      const sessionResponse = await getCheckoutSession(
        {
          type: 'topup',
          id: `${product}-${id}-pack`,
          email: encodeURIComponent(user.email),
          successUrl: encodeURIComponent(successPath),
          cancelUrl: encodeURIComponent(cancelPath),
          organisation: teamProfile.id,
        },
        dispatch
      );

      // if (sessionResponse.error) {
      //   setError({
      //     hasError: true,
      //     message: `An error occurred. Please try refreshing the page, or if the problem persists, contact us on support${PLATFORM_EMAIL}`,
      //   });
      //   return;
      // }
      const stripe = await stripePromise;
      if (stripe && sessionResponse) {
        // @ts-ignore
        await stripe.redirectToCheckout({
          sessionId: sessionResponse.checkoutSessionId,
        });
      }
    }
    setIsLinkLoading(false);
  };
  return (
    <>
      {showPlan && (
        <>
          {successParam === 'ai' || successParam === 'enrollment' ? (
            <Box mb={6} mx={{ base: 'defaultMargin', md: 0 }}>
              <InformationCard
                id="topup-success"
                information={{
                  title: 'Purchase Successful 🎉',
                  description: `You've successfully topped up your channel's lifetime ${
                    successParam === 'ai' ? 'AI credits' : 'enrollments'
                  }.`,
                  status: 'success',
                }}
                isDismissable={false}
              />
            </Box>
          ) : null}
          {proPlan ? (
            <>
              <SectionTitle title="My Pro Plan" />
              <Card flexDirection="column" mb={6}>
                <Flex
                  direction="column"
                  justify="space-between"
                  p={3}
                  borderBottomWidth="1px"
                  borderColor={borderTierColor}
                >
                  <Flex
                    flexDirection={{ base: 'column', md: 'row' }}
                    alignItems={{ base: 'flex-start', md: 'center' }}
                  >
                    <Box mr={{ base: 0, md: 4 }} mb={{ base: 4, md: 0 }}>
                      <Flex
                        boxSize="image.xs"
                        alignItems="center"
                        justifyContent="center"
                        borderRadius="full"
                        bg={bgTierColor}
                        color={mainTierColor}
                        fontSize="xl"
                      >
                        <FaGem />
                      </Flex>
                    </Box>
                    <Box flex={1} mb={{ base: 3, md: 0 }}>
                      <Text
                        fontSize="md"
                        fontWeight="semibold"
                        color="text.muted"
                      >
                        Steppit Pro
                      </Text>
                      <Text
                        color={mainTierColor}
                        fontSize="2xl"
                        fontWeight="bold"
                        lineHeight="tight"
                      >
                        {proPlan.name.replace('Steppit Pro: ', '')}
                      </Text>
                    </Box>
                    {proPlan.slug.includes('ltd')
                      ? null
                      : // Show for LTD while AppSumo campaign is running
                        // (
                        //   <>
                        //     {proInvoiceUuid ? (
                        //       <LinkButton
                        //         href={`https://appsumo.com/account/redemption/${proInvoiceUuid}#change-plan`}
                        //         rightIcon={
                        //           <MdIcon name="OpenInNew" fontSize="xs" />
                        //         }
                        //         size="sm"
                        //         mb={1}
                        //         target="_blank"
                        //         rel="noopener noreferrer"
                        //       >
                        //         Upgrade AppSumo Tier
                        //       </LinkButton>
                        //     ) : null}
                        //   </>
                        // )
                        showPortalLink && (
                          <Button
                            isLoading={isLoading || isLinkLoading}
                            onClick={() => openCustomerPortal()}
                            rightIcon={
                              <MdIcon name="OpenInNew" fontSize="xs" />
                            }
                            mb={1}
                            size="sm"
                          >
                            Upgrade Plan
                          </Button>
                        )}
                  </Flex>
                </Flex>
                <Stack
                  direction="column"
                  p={3}
                  spacing={3}
                  flexGrow={1}
                  textAlign="left"
                  mb={2}
                >
                  <PlanFeature>{`${proPlan.details.teamMembers} team member${
                    proPlan.details.teamMembers === 1 ? '' : 's'
                  }`}</PlanFeature>
                  <PlanFeature>{`${
                    100 - proPlan.details.platformRevShare
                  }% revenue share of sales via Steppit`}</PlanFeature>
                  <PlanFeature>Unlimited course sales via Steppit</PlanFeature>
                  <PlanFeature>{`${proPlan.details.monthlyEnrolments} course enrolments per month via invites or integrations`}</PlanFeature>
                  <PlanFeature>{`${proPlan.details.monthlyAiCredits?.toLocaleString()} AI credits per month`}</PlanFeature>
                </Stack>
              </Card>
              <SectionTitle title="Current Balance" />
              <Card
                padding={4}
                mb={6}
                flexDirection={{ base: 'column', md: 'row' }}
              >
                <Box flex={1}>
                  <MdIcon fontSize="2xl" name="AutoAwesome" mb={2} />
                  <Text fontSize="xl" fontWeight="bold">
                    {`${currentMonthlyAiCredits?.toLocaleString()} / ${proPlan.details.monthlyAiCredits?.toLocaleString()}`}
                  </Text>
                  <Text>Monthly AI credits</Text>
                  <Text color="text.muted">{`Renews ${moment()
                    .add(1, 'M')
                    .startOf('month')
                    .format('Do MMMM')}`}</Text>
                  {currentExtraAiCredits > 0 && (
                    <Flex flexDirection="column" alignItems="flex-start">
                      <Flex
                        mt={3}
                        alignItems="center"
                        {...(successParam === 'ai'
                          ? {
                              bg: 'background.success',
                              color: 'text.success',
                              borderRadius: 'sm',
                              px: 3,
                              py: 1,
                              mb: 2,
                            }
                          : {})}
                      >
                        {successParam === 'ai' && (
                          <MdIcon name="Done" mr={1.5} />
                        )}
                        <Text fontSize="xl" fontWeight="bold">
                          {`${
                            successParam === 'ai' ? '' : '+ '
                          }${currentExtraAiCredits?.toLocaleString()}`}
                        </Text>
                      </Flex>
                      <Text>Lifetime AI credits</Text>
                      <Text color="text.muted">No expiry date</Text>
                    </Flex>
                  )}
                  <Button
                    mt={4}
                    size="sm"
                    icon="ArrowForward"
                    iconPosition="right"
                    onClick={() => setPackPopupProduct('ai')}
                    isLoading={isLoading || isLinkLoading}
                  >
                    Top Up AI Credits
                  </Button>
                </Box>
                <Divider display={{ base: 'box', md: 'none' }} my={4} />
                <Box flex={1}>
                  <MdIcon fontSize="2xl" name="GroupAdd" mb={2} />
                  <Text fontSize="xl" fontWeight="bold">
                    {`${monthlyLicenseEnrolmentsRemaining} / ${Math.max(
                      proPlan.details.monthlyEnrolments,
                      monthlyLicenseEnrolmentsRemaining
                    )}`}
                  </Text>
                  <Text>Monthly course enrollments</Text>
                  <Text color="text.muted">{`Renews ${moment()
                    .add(1, 'M')
                    .startOf('month')
                    .format('Do MMMM')}`}</Text>
                  {noExpiryLicensesEnrolmentsRemaining > 0 && (
                    <Flex flexDirection="column" alignItems="flex-start">
                      <Flex
                        mt={3}
                        alignItems="center"
                        {...(successParam === 'enrollment'
                          ? {
                              bg: 'background.success',
                              color: 'text.success',
                              borderRadius: 'sm',
                              px: 3,
                              py: 1,
                              mb: 2,
                            }
                          : {})}
                      >
                        {successParam === 'enrollment' && (
                          <MdIcon name="Done" mr={1.5} />
                        )}
                        <Text fontSize="xl" fontWeight="bold">
                          {`${
                            successParam === 'enrollment' ? '' : '+ '
                          }${noExpiryLicensesEnrolmentsRemaining?.toLocaleString()}`}
                        </Text>
                      </Flex>
                      <Text>Lifetime course enrollments</Text>
                      <Text color="text.muted">No expiry date</Text>
                    </Flex>
                  )}

                  <Button
                    mt={4}
                    size="sm"
                    icon="ArrowForward"
                    iconPosition="right"
                    onClick={() => setPackPopupProduct('enrollment')}
                    isLoading={isLoading || isLinkLoading}
                  >
                    Top Up Enrollments
                  </Button>
                </Box>
              </Card>
            </>
          ) : null}
          <SectionTitle title="My Subscriptions" />
        </>
      )}

      <Card>
        <Skeleton
          isLoaded={!isLoading}
          loadingStyle={{ width: '75%', height: 6 }}
          w="100%"
        >
          {activeSubscriptions.length ? (
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th px={3} py={2}>
                    Subscription
                  </Th>
                  <Th px={3} py={2}>
                    Status
                  </Th>
                  <Th></Th>
                </Tr>
              </Thead>
              {activeSubscriptions.map((subscription) => {
                const {
                  plan,
                  status,
                  cancelAtPeriodEnd,
                  cancelAt,
                  currentPeriodEnd,
                  canceledAt,
                } = subscription;
                return (
                  <Tbody>
                    <Tr>
                      <Td px={3} py={2} w={{ base: 'auto', md: '99%' }}>
                        <Flex flexDir="column" flex={1}>
                          <Text fontWeight="semibold">{plan.product.name}</Text>
                          <Text fontSize="sm">{plan.nickname}</Text>
                        </Flex>
                      </Td>
                      <Td px={3} py={2} sx={{ textWrap: 'nowrap' }}>
                        <VStack alignItems="flex-start" spacing={1}>
                          {status ? (
                            <Badge
                              variant="subtle"
                              colorScheme={
                                status === 'active' ? 'green' : 'blackAlpha'
                              }
                            >
                              {status}
                            </Badge>
                          ) : (
                            <Flex flex={1} />
                          )}
                        </VStack>
                      </Td>
                      <Td
                        px={3}
                        py={2}
                        sx={{ textWrap: { base: 'wrap', md: 'nowrap' } }}
                      >
                        <Flex>
                          <Text fontSize="sm" color="text.muted">
                            {cancelAtPeriodEnd && cancelAt
                              ? `Expires ${moment
                                  .unix(cancelAt)
                                  .format(`MMM Do [']YY`)}`
                              : canceledAt
                              ? `Expired ${moment
                                  .unix(canceledAt)
                                  .format(`MMM Do [']YY`)}`
                              : `Renews ${moment
                                  .unix(currentPeriodEnd)
                                  .format(`MMM Do [']YY`)}`}
                          </Text>
                        </Flex>
                      </Td>
                    </Tr>
                  </Tbody>
                );
              })}
            </Table>
          ) : (
            <Flex flexDir="column" p={2}>
              <Text color="text.muted">
                You currently have no active subscriptions
              </Text>
            </Flex>
          )}
        </Skeleton>
      </Card>
      {showPortalLink && (
        <Flex
          justifyContent="flex-start"
          mt={5}
          mx={{ base: 'defaultMargin', md: 0 }}
        >
          <Button
            isLoading={isLoading || isLinkLoading}
            onClick={() => openCustomerPortal()}
            icon="CardMembership"
            rightIcon={<MdIcon name="OpenInNew" fontSize="xs" />}
            colorScheme="green"
            variant="outline"
          >
            Manage Subscriptions
          </Button>
        </Flex>
      )}
      <PackPopup
        product={packPopupProduct}
        isOpen={!!packPopupProduct}
        onClose={() => setPackPopupProduct('')}
        openCheckout={async (id: string) =>
          await openCheckout(packPopupProduct, id)
        }
        isDisabled={isLoading || isLinkLoading}
      />
    </>
  );
};

export default SubscriptionManagement;
