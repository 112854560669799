import React from 'react';
import { Box, Tooltip, MdIcon, BoxProps } from '@workshop/ui';
import { InfoIcon } from '@chakra-ui/icons';

import { PLATFORM_WEBSITE, CLIP_NAME } from 'constants/common';

export const TOOLTIP_MAPPING = {
  cohort_status:
    'Changing a class’ live status from ‘draft’ to ‘live’ publishes the class and makes it visible on public for purchasing on checkout pages.',
  course_title: '',
  course_subtitle: '',
  course_short_summary: '',
  course_description: '',
  course_duration: 'Number of weeks between start and end date of the course',
  course_access_duration: '',
  course_category: '',
  course_class_type: '',
  private_cohort:
    'Make this class private to the public, such as for testing purposes.',
  anonymous_cohort: `Run this class in live "rooms" for students who don't have their own accounts.`,
  private_chat_disabled_cohort:
    'Prevent students from being able to privately message one another',
  unit_prefix: '',
  unit_title: '',
  unit_requirements: '',
  unit_summary: '',
  unit_assessment_message_before: '',
  unit_assessment_message_after: '',
  unit_type: '',
  session_title: '',
  session_summary: '',
  session_requirements_title: '',
  session_requirements_item: '',
  step_item_title: '',
  step_item_support_notes: '',
  session_format: '',
  session_category: '',
  session_duration: '',
  sub_teacher:
    'Substitute teachers are temporarily added to a class for the purpose of covering a teacher who may be away due to annual leave etc. If there is only one teacher on a class, it cannot be the substitute.',
  test_cohort:
    'Include feedback forms in automated weekly emails, such as for testing purposes. To use this feature, open a course in your Catalogue to add a feedback form URL.',
  schedule_sessions: 'Keep track of your scheduled and completed sessions',
  schedule_classes: 'See your past, present and upcoming classes',
  age_consent: `If you are between 13 and 18 years old, you must have consent from your parent or guardian to use this platform. Please see our Terms of Service (${PLATFORM_WEBSITE}/terms) for more info.`,
  create_cohort_price:
    'Head to the Edit Details tab on your course page to edit its price.',
  clip_summary: `Summarize this ${CLIP_NAME} in 1-2 sentences to make it easy for learners to follow along.`,
  clip_script:
    "Your script is only visible while editing your course, and can be used in a teleprompter while recording. Learners won't see this.",
  ai_credits:
    'You can use credits for your personal assistant. Creating a Pro course plan costs 100 credits, session plans cost 50 credits each & most other tools 10 credits per use.',
  disable_downloads:
    'Check this box to prevent learners from downloading this PDF, only allowing them to view it in their browser.',
} as const;

export type Tooltip = keyof typeof TOOLTIP_MAPPING;

interface IconTooltipProps extends BoxProps {
  tooltip?: Tooltip;
  iconName?: string;
  message?: string;
}

const IconTooltip: React.FC<IconTooltipProps> = ({
  tooltip,
  iconName = 'HelpOutline',
  message,
  color = 'common.primary',
  ...rest
}) =>
  (tooltip && TOOLTIP_MAPPING[tooltip]) || message ? (
    <Box position="relative" lineHeight={0} cursor="pointer">
      <Tooltip
        hasArrow
        label={(tooltip && TOOLTIP_MAPPING[tooltip]) || message}
        aria-label={(tooltip && TOOLTIP_MAPPING[tooltip]) || message}
        placement="top"
        textAlign="center"
      >
        <Box mb={2} {...rest}>
          <MdIcon name={iconName} boxSize={3.5} color={color} />
        </Box>
      </Tooltip>
    </Box>
  ) : null;

export default IconTooltip;
