import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';

import {
  UnitListState,
  IUnit,
  IUnitType,
  ISessionListItem,
  ISessionType,
  IDownload,
} from 'types/cms';
import { Download } from 'types/learner';
import { IDynamicTable } from 'components/Common/DynamicList';

import { UNIT_TYPE } from 'constants/courses';
export interface DraggableUnit {
  id: string;
  title: string;
  label: string;
  index: number;
  unitType: string;
  modules: number[];
  downloads: Download[];
  checklist: string;
  description: string;
  dragDisabled: boolean;
  dragHandleProps?: DraggableProvidedDragHandleProps;
  video: string | null;
  audio: string | null;
  image: string | null;
  orientation: 'portrait' | 'landscape';
  unitClipType: 'text' | 'image' | 'audio' | 'video';
}

type FormattedUnitData = {
  [key in IUnitType]: DraggableUnit[];
};

export const formatUnitList = (units: UnitListState): FormattedUnitData => {
  return Object.keys(units).reduce<FormattedUnitData>(
    (acc, unitId) => {
      const unit = units[unitId] as IUnit;

      acc[unit.unitType] = [
        ...acc[unit.unitType],
        {
          id: unitId,
          title: unit?.title,
          label: unit?.prefix,
          index: unit?.index,
          unitType: unit?.unitType,
          modules: unit?.modules,
          downloads: unit?.downloads,
          checklist: unit?.fullKitList,
          description: unit?.detailedSummary,
          dragDisabled: unit?.unitType === UNIT_TYPE.assessment,
          video: unit?.video720 || unit?.videoHls,
          audio: unit?.audio,
          image: unit?.imageMobile,
          orientation: unit?.orientation,
          unitClipType: unit?.unitClipType,
        },
      ];

      return acc;
    },
    {
      normal: [],
      intro: [],
      outro: [],
      transition: [],
      assessment: [],
      practice: [],
    }
  );
};

export interface DraggableSession {
  id: number;
  index: number;
  label: string;
  moduleType: string;
  title: string;
  description: string;
  stepCount: number;
  image: string;
  unit?: number;
  isLoading?: boolean;
  isReady?: boolean;
}

type FormattedSessionData = {
  [key in ISessionType]: DraggableSession[];
};

export const formatSession = (
  session: ISessionListItem,
  hasIntroSession: boolean
) => ({
  id: session.id,
  title: session?.title,
  description: session?.description,
  stepCount: session?.stepCount,
  label:
    session?.moduleType === 'intro'
      ? 'Intro'
      : session?.moduleType === 'outro'
      ? 'Outro'
      : // If unit has an intro stsessionep, the `Session x` ;
        // index needs offsetting by 1 to account for the
        // intro having the index of 1.
        `Session ${hasIntroSession ? session?.index - 1 : session?.index}`,
  index: session?.index,
  moduleType: session?.moduleType,
  image: session?.imageThumbnail,
  isReady: session?.isReady,
});

export const formatSessionList = (
  sessions: ISessionListItem[],
  hasIntroSession: boolean
): FormattedSessionData => {
  return sessions.reduce<FormattedSessionData>(
    (acc, session) => {
      acc[session.moduleType] = [
        ...acc[session.moduleType],
        formatSession(session, hasIntroSession),
      ];
      return acc;
    },
    {
      normal: [],
      intro: [],
      outro: [],
    }
  );
};

/**
 * Map the data from the downloads dynamic list (table) into a valid
 * format for submitting over the API
 */
export const getFormattedDownloadData = (data: IDynamicTable): IDownload[] => {
  return Object.values(data)
    .filter((d) => d.name && d.link)
    .map((d) => {
      return {
        name: d.name || '',
        link: d.link || '',
        teacherOnly: d.teacherOnly === 'true',
        readOnly: d.readOnly || false,
      };
    });
};
