import { FRONT_ENV, BACKEND_ENV } from 'constants/env';

export const ROLES = {
  admin: 'admin',
  owner: 'owner',
  mentor: 'mentor',
  teamManager: 'team_manager',
  finance: 'finance',
  marketing: 'marketing',
  contentManager: 'content_manager',
} as const;

const isRemote = FRONT_ENV === 'production' || BACKEND_ENV === 'dev';

// (Replace IDs after ":" with local values if testing)
export const ORG_IDS = {
  workshop: isRemote ? 14 : 2,
  leiths: isRemote ? 8 : 1,
  lak: isRemote ? 46 : 42,
};

// URL path for each bespoke org landing page
export const ORG_LANDING_PAGES = {
  lak: '/leiths-academy-kitchen',
};

// List of org IDs that are not allowed to manually enroll learners
export const ENROLMENT_BLACKLIST = [0];

// List of org IDs that are allowed to create courses
export const CREATE_COURSE_WHITELIST = [
  ORG_IDS.leiths,
  ORG_IDS.workshop,
  ORG_IDS.lak,
];

// List of org IDs with detailed cohort options
export const ADVANCED_COHORT_OPTIONS_WHITELIST = [
  ORG_IDS.leiths,
  ORG_IDS.workshop,
];

// List of org IDs with detailed course options
export const ADVANCED_COURSE_OPTIONS_WHITELIST = [
  ORG_IDS.leiths,
  ORG_IDS.workshop,
  ORG_IDS.lak,
];

// List of org IDs only available on Workshop
export const WORKSHOP_ORGS = [ORG_IDS.leiths, ORG_IDS.lak];

// List of org IDs that have "pro" status by default
export const PRO_ORGS = [ORG_IDS.leiths, ORG_IDS.workshop, ORG_IDS.lak];

export const BASIC_UNIT_LIMIT = 4;
export const PRO_TEAM_MEMBERS_LIMIT = 5;
export const PRO_MONTHLY_ENROLMENTS_LIMIT = 10;
export const PRO_ASSISTANT_UNITS_LIMIT = 8;
export const PRO_AI_CREDITS_LIMIT = '2,000';
export const PRO_TIER_COLORS = {
  0: 'blue',
  1: 'green',
  2: 'orange',
  3: 'purple',
};

// Pro channel waiting list hash
export const PRO_LIST_HASH = '3547153';
export const ACCELERATOR_LIST_HASH = '16104390';

// Pro channel monthly pricing in specified currency
export const PRO_PRICE = (currency: string | undefined) =>
  currency === 'GBP'
    ? '£25'
    : currency === 'EUR'
    ? '€29'
    : currency === 'USD'
    ? '$25'
    : '$25';

export const PRO_95_DISCOUNT_PRICE = (currency: string | undefined) =>
  currency === 'GBP'
    ? '£1.50'
    : currency === 'EUR'
    ? '€1.75'
    : currency === 'USD'
    ? '$1.50'
    : '$1.50';

export const CREDITS_PRICE = (currency: string | undefined, id: string) => {
  switch (id) {
    case '1k':
      return currency === 'GBP'
        ? '£5'
        : currency === 'EUR'
        ? '€6'
        : currency === 'USD'
        ? '$5'
        : '$5';
    case '5k':
      return currency === 'GBP'
        ? '£20'
        : currency === 'EUR'
        ? '€24'
        : currency === 'USD'
        ? '$20'
        : '$20';
    case '20k':
      return currency === 'GBP'
        ? '£70'
        : currency === 'EUR'
        ? '€84'
        : currency === 'USD'
        ? '$70'
        : '$70';
    case '50k':
      return currency === 'GBP'
        ? '£150'
        : currency === 'EUR'
        ? '€180'
        : currency === 'USD'
        ? '$150'
        : '$150';
    default:
      return '';
  }
};

export const CREDITS_PRICE_PER_UNIT = (
  currency: string | undefined,
  id: string
) => {
  switch (id) {
    case '1k':
      return currency === 'GBP'
        ? '£0.50'
        : currency === 'EUR'
        ? '€0.60'
        : currency === 'USD'
        ? '$0.50'
        : '$0.50';
    case '5k':
      return currency === 'GBP'
        ? '£0.40'
        : currency === 'EUR'
        ? '€0.48'
        : currency === 'USD'
        ? '$0.40'
        : '$0.40';
    case '20k':
      return currency === 'GBP'
        ? '£0.35'
        : currency === 'EUR'
        ? '€0.42'
        : currency === 'USD'
        ? '$0.35'
        : '$0.35';
    case '50k':
      return currency === 'GBP'
        ? '£0.30'
        : currency === 'EUR'
        ? '€0.36'
        : currency === 'USD'
        ? '$0.30'
        : '$0.30';
    default:
      return '';
  }
};

export const ENROLMENTS_PRICE = (currency: string | undefined, id: string) => {
  switch (id) {
    case '10':
      return currency === 'GBP'
        ? '£15'
        : currency === 'EUR'
        ? '€18'
        : currency === 'USD'
        ? '$15'
        : '$15';
    case '50':
      return currency === 'GBP'
        ? '£60'
        : currency === 'EUR'
        ? '€70'
        : currency === 'USD'
        ? '$60'
        : '$60';
    case '200':
      return currency === 'GBP'
        ? '£210'
        : currency === 'EUR'
        ? '€245'
        : currency === 'USD'
        ? '$210'
        : '$210';
    case '500':
      return currency === 'GBP'
        ? '£450'
        : currency === 'EUR'
        ? '€525'
        : currency === 'USD'
        ? '$450'
        : '$450';
    default:
      return '';
  }
};

export const ENROLMENTS_PRICE_PER_UNIT = (
  currency: string | undefined,
  id: string
) => {
  switch (id) {
    case '10':
      return currency === 'GBP'
        ? '£1.50'
        : currency === 'EUR'
        ? '€1.80'
        : currency === 'USD'
        ? '$1.50'
        : '$1.50';
    case '50':
      return currency === 'GBP'
        ? '£1.20'
        : currency === 'EUR'
        ? '€1.40'
        : currency === 'USD'
        ? '$1.20'
        : '$1.20';
    case '200':
      return currency === 'GBP'
        ? '£1.05'
        : currency === 'EUR'
        ? '€1.23'
        : currency === 'USD'
        ? '$1.05'
        : '$1.05';
    case '500':
      return currency === 'GBP'
        ? '£0.90'
        : currency === 'EUR'
        ? '€1.05'
        : currency === 'USD'
        ? '$0.90'
        : '$0.90';
    default:
      return '';
  }
};
