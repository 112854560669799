// @ts-nocheck
import { camelizeKeys as camelize, decamelizeKeys as decamelize } from 'humps';
import * as Sentry from '@sentry/react';

import { callAPI } from 'utils';
import { getCookie } from 'utils/index';

import { authActions } from 'redux/actions/common';

import { api, baseUrl } from 'constants/env';

import { Dispatch } from 'types';
import type { OrderItems, TaxCalculation } from './types';

let apiBase = '';

const getConfig = async (base: string = '') => {
  apiBase = base;
  try {
    const response = await fetch(`${baseUrl}/checkout/config/`);
    const config = await response.json();
    return config;
  } catch (err) {
    try {
      Sentry.captureException(err);
    } catch (e) {
      console.warn('Sentry not installed');
    }
    return { error: err.message };
  }
};

const getCheckoutSession = async (
  params: {
    type: string;
    id: number | string;
    couponCode?: string;
    successUrl: string;
    cancelUrl: string;
    email?: string;
    organisation?: number;
    referral?: string;
    impactId?: string;
  },
  dispatch?: Dispatch
) => {
  // Map the input parameters to a valid set of url parameters
  const decamelizedParams = decamelize(params);
  const urlParams = Object.keys(decamelizedParams).reduce(
    (acc, curr, index) => {
      if (decamelizedParams[curr] != null) {
        return `${acc}${index !== 0 ? '&' : ''}${curr}=${
          decamelizedParams[curr]
        }`;
      }
      return acc;
    },
    '?'
  );

  try {
    const response = await dispatch(
      callAPI({
        endpoint: `${api}/checkout/session/${urlParams}`,
        method: 'GET',
        types: [
          'START_CHECKOUT_REQUEST',
          'START_CHECKOUT_SUCCESS',
          'START_CHECKOUT_FAILURE',
        ],
      })
    );
    return response?.payload;
  } catch (err) {
    try {
      Sentry.captureException(err);
    } catch (e) {
      console.warn('Sentry not installed');
    }
    return { error: err.message };
  }
};

const getUpcomingCohorts = async (slug: string, earlyAccessToken?: string) => {
  // Get a list of upcoming cohorts
  try {
    const response = await fetch(
      `${api}/${apiBase}/${slug}/upcomingCohorts/${
        earlyAccessToken ? `?ea_t=${earlyAccessToken}` : ''
      }`
    );
    const data = await response.json();

    if (data.error) {
      try {
        Sentry.captureMessage('Error retrieving cohorts: ', data.error);
      } catch (e) {
        console.warn('Sentry not installed');
      }
      return { error: data.error };
    }
    return camelize(data);
  } catch (err) {
    try {
      Sentry.captureException(err);
    } catch (e) {
      console.warn('Sentry not installed');
    }
    return { error: err.message };
  }
};

const register = async (
  registrationDetails: {
    name: string;
    email: string;
    password1: string;
    marketing_consent: boolean;
    source: string;
  },
  dispatch: Dispatch
) => {
  try {
    const response = await dispatch(
      authActions.signupRequest({
        email: registrationDetails.email,
        marketingConsent: registrationDetails.marketing_consent,
        name: registrationDetails.name,
        password1: registrationDetails.password1,
        source: registrationDetails.source,
      })
    ).then((res) => {
      if (!res.error) {
        dispatch(
          authActions.tokenRequest({
            username: registrationDetails.email,
            password: registrationDetails.password1,
          })
        );
      }
    });
    return response;
  } catch (err) {
    try {
      Sentry.captureException(err);
    } catch (e) {
      console.warn('Sentry not installed');
    }
    return { error: err.message };
  }
};

const login = async (
  loginDetails: { email: string; password: string },
  dispatch: Dispatch
) => {
  try {
    const response = await dispatch(
      authActions.tokenRequest({
        username: loginDetails.email,
        password: loginDetails.password,
      })
    );
    return response;
  } catch (err) {
    return { error: err.message };
  }
};

const logout = async (dispatch: Dispatch) => {
  try {
    const response = await dispatch(authActions.tokenDeleteRequest());
    return response;
  } catch (err) {
    return { error: err.message };
  }
};

const getUserProfile = async (authToken: string, csrftoken: string) => {
  try {
    const response = await fetch(`${api}/userProfile/`, {
      method: 'GET',
      credentials: 'same-origin',
      headers: {
        'X-CSRFToken': getCookie('csrftoken') || csrftoken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
    });
    const data = await response.json();
    if (data.error) {
      try {
        Sentry.captureMessage(
          'Error updating profile: ',
          JSON.stringify(profileDetails)
        );
      } catch (e) {
        console.warn('Sentry not installed');
      }
      return { error: data.error };
    }

    return camelize(data);
  } catch (err) {
    try {
      Sentry.captureException(err);
    } catch (e) {
      console.warn('Sentry not installed');
    }
    return { error: err.message };
  }
};

const updateProfile = async (
  profileDetails: { country: string },
  csrftoken: string
) => {
  try {
    const response = await fetch(`${api}/user/`, {
      method: 'PATCH',
      credentials: 'same-origin',
      headers: {
        'X-CSRFToken': getCookie('csrftoken') || csrftoken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(profileDetails),
    });
    const data = await response.json();
    if (data.error) {
      try {
        Sentry.captureMessage(
          'Error updating profile: ',
          JSON.stringify(profileDetails)
        );
      } catch (e) {
        console.warn('Sentry not installed');
      }
      return { error: data.error };
    }

    return camelize(data);
  } catch (err) {
    try {
      Sentry.captureException(err);
    } catch (e) {
      console.warn('Sentry not installed');
    }
    return { error: err.message };
  }
};

const confirmPayment = async (
  inputData:
    | {
        paymentMethod: string;
        currency: string;
        items: OrderItems;
        amount: number;
        couponCode: string;
        isGift?: boolean;
        csrftoken: string;
        authToken: string;
        email: string;
        name: string;
      }
    | {
        paymentIntentId: string;
        items: OrderItems;
        csrftoken: string;
        authToken: string;
        email: string;
        name: string;
      },
  dispatch: Dispatch
) => {
  try {
    let body = {};
    // Depending on whether we're creating a payment intent
    // or confirming one following 3DS, we'll have different
    // inputs and send different data over the API
    const decamelizedItems = inputData.items.map((item) => decamelize(item));
    if (inputData.paymentMethod) {
      body = {
        payment_method_id: inputData.paymentMethod,
        currency: inputData.currency,
        items: decamelizedItems,
        amount: inputData.amount,
        coupon_code: inputData.couponCode,
        is_gift: inputData.isGift,
        email: inputData.email,
        name: inputData.name,
      };
    } else if (inputData.paymentIntentId) {
      body = {
        payment_intent_id: inputData.paymentIntentId,
        items: decamelizedItems,
        email: inputData.email,
        name: inputData.name,
      };
    }

    const data = await dispatch(
      callAPI({
        endpoint: `${baseUrl}/checkout/ajax/confirmPayment/`,
        method: 'POST',
        body,
        types: [
          'CONFIRM_PAYMENT_REQUEST',
          'CONFIRM_PAYMENT_SUCCESS',
          'CONFIRM_PAYMENT_FAILURE',
        ],
      })
    );

    if (data.error) {
      try {
        Sentry.captureMessage('Error on confirmPayment: ', data.error);
      } catch (e) {
        console.warn('Sentry not installed');
      }
      return data;
    }

    const camelizedData:
      | {
          success: true;
          paymentIntentId: string;
          customerId: string;
          chargeId: string;
        }
      | { requiresAction: true; paymentIntentClientSecret: string }
      | { error: string } = data.payload;

    return camelizedData;
  } catch (err) {
    try {
      Sentry.captureException(err);
    } catch (e) {
      console.warn('Sentry not installed');
    }
    return { error: err.message };
  }
};

const applyCoupon = async (
  couponCode: string,
  course: string,
  authToken: string,
  csrftoken: string
) => {
  try {
    const response = await fetch(
      `${api}/redeemCoupon/?coupon_code=${couponCode}&course=${course}`,
      {
        method: 'GET',
        headers: {
          'X-CSRFToken': getCookie('csrftoken') || csrftoken,
          Accept: 'application/json',
          'Content-Type': 'application/json',
          ...(authToken ? { Authorization: `Token ${authToken}` } : {}),
        },
      }
    );
    const data = await response.json();

    if (data.error) {
      try {
        Sentry.captureMessage('Invalid coupon code:', couponCode);
      } catch (e) {
        console.warn('Sentry not installed');
      }
      return { error: data.error };
    }

    return camelize(data);
  } catch (err) {
    try {
      Sentry.captureException(err);
    } catch (e) {
      console.warn('Sentry not installed');
    }
    return { error: err.message };
  }
};

const redeemCourse = async (
  couponCode: string,
  cohort: number,
  email: string,
  authToken: string,
  csrftoken: string
) => {
  try {
    const response = await fetch(`${api}/redeemCoupon/`, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'X-CSRFToken': getCookie('csrftoken') || csrftoken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        coupon_code: couponCode,
        cohort_pk: cohort,
        email,
      }),
    });
    const data = await response.json();

    if (data.error) {
      try {
        Sentry.captureMessage(
          'Error redeeming coupon for cohort',
          couponCode,
          cohort
        );
      } catch (e) {
        console.warn('Sentry not installed');
      }
      return { error: data.error };
    }

    return camelize(data);
  } catch (err) {
    try {
      Sentry.captureException(err);
    } catch (e) {
      console.warn('Sentry not installed');
    }
    return { error: err.message };
  }
};

const checkTempEmail = async (
  tempUserDetails: {
    email: string;
  },
  csrftoken: string
) => {
  if (!tempUserDetails.email) {
    return { error: { message: 'No email supplied to API' } };
  }

  try {
    const response = await fetch(
      `${api}/email/?email=${encodeURIComponent(tempUserDetails.email)}`,
      {
        method: 'GET',
        headers: {
          'X-CSRFToken': getCookie('csrftoken') || csrftoken,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );

    const data = await response.json();

    if (data.error) {
      return { error: data.error };
    }

    return camelize(data);
  } catch (err) {
    try {
      Sentry.captureException(err);
    } catch (e) {
      console.warn('Sentry not installed');
    }
    return { error: err.message };
  }
};

const calculateTax = async (
  inputData: {
    currency: string;
    slug: string;
    couponCode?: string;
    ip?: string;
  },
  dispatch: Dispatch
) => {
  try {
    const data = await dispatch(
      callAPI({
        endpoint: `${baseUrl}/checkout/calculateTax/`,
        method: 'POST',
        body: {
          currency: inputData.currency,
          slug: inputData.slug,
          coupon_code: inputData.couponCode,
          ip: inputData.ip,
        },
        types: [
          'CALCULATE_TAX_REQUEST',
          'CALCULATE_TAX_SUCCESS',
          'CALCULATE_TAX_FAILURE',
        ],
        meta: {
          toast: { success: false, error: false },
        },
      })
    );

    if (data.error) {
      try {
        Sentry.captureMessage('Error on calculateTax: ', data.error);
      } catch (e) {
        console.warn('Sentry not installed');
      }
      return data;
    }

    const camelizedData: TaxCalculation = data.payload;

    return camelizedData;
  } catch (err) {
    try {
      Sentry.captureException(err);
    } catch (e) {
      console.warn('Sentry not installed');
    }
    return { error: err.message };
  }
};

export {
  getConfig,
  getCheckoutSession,
  getUpcomingCohorts,
  register,
  login,
  logout,
  updateProfile,
  confirmPayment,
  getUserProfile,
  applyCoupon,
  checkTempEmail,
  redeemCourse,
  calculateTax,
};
