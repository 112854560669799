import React, { useState } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';

import navRoutes from 'navigation/Routes';

import isEmail from 'validator/lib/isEmail';

import { hooks, courseUtils, analytics } from 'utils';

import { PLATFORM } from 'constants/env';

import {
  Box,
  Flex,
  MdIcon,
  Text,
  Button,
  LinkButton,
  Divider,
  chakra,
} from '@workshop/ui';

import { Loading } from 'components/Loading';
import { LabelInput, ConfirmModal } from 'components/Common';

import { enrolmentActions, courseActions } from 'redux/actions/cms';

import { GlobalState } from 'types';
import { License } from 'types/cms';
import { SocialType } from 'types/common';

import {
  EditModal,
  Props as EditModalProps,
} from 'components/Common/EditModal';

export interface EnrolStudentsFormData {
  students: {
    email: string;
  }[];
  numStudents?: string;
}

interface EnrolStudentsModalProps extends EditModalProps {
  course: number;
  cohort: number;
  courseIsLicensed?: boolean;
  cohortIsTest: boolean;
  cohortIsAnonymous: boolean;
  cohortSocialType: SocialType;
  licenses?: License[];
  cohortSpacesAvailable: number;
  licensedSpacesAvailable: number;
  licensorName?: string;
  learnerEmail?: string;
}

const EnrolStudentsModal: React.FC<EnrolStudentsModalProps> = (props) => {
  const {
    isOpen,
    modalSize,
    onClose,
    onSave,
    course,
    cohort,
    licenses,
    courseIsLicensed,
    licensedSpacesAvailable,
    licensorName,
    cohortIsTest,
    cohortIsAnonymous,
    cohortSpacesAvailable,
    cohortSocialType,
    learnerEmail,
  } = props;

  const [isUpdating, setIsUpdating] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const dispatch = useDispatch();

  const { courses: coursesLoading } = hooks.useLoadingDataState(
    {
      courses: {
        actions: [() => courseActions.list({ fetchNextPage: true })],
      },
    },
    []
  );

  const cmsCourseList = useSelector(
    (state: GlobalState) => state.cms.course.courseList
  );
  // This will only be found (and is only needed) for non licensed courses
  const courseListItem = cmsCourseList[course];
  const enrolmentCost = courseListItem?.pricingTier?.amount || 0;

  const { handleSubmit, control, register, watch, reset } =
    useForm<EnrolStudentsFormData>({
      mode: 'onChange',
      defaultValues: {
        students: [{ email: learnerEmail || '' }],
        numStudents: undefined,
      },
    });

  const {
    fields: students,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'students',
  });

  const handleOnClose = () => {
    reset();
    onClose();
  };

  const studentsValues = watch('students');
  const numStudentsValue = watch('numStudents');

  const hasClassSizeLimit = courseUtils.hasClassSizeLimit({
    socialType: cohortSocialType,
    isAnonymous: cohortIsAnonymous,
  });

  // Define all of the modes the enrolments modal can be in based on combinations
  // of test status, license status and anonymous status

  // MODES
  // - 'test' - The cohort has isTest === true. On test classes, enrolments are free up
  // to the class size limit.
  // - 'licensedCourse' - The course is owned by another organisation, which has assigned
  // this org license(s) for X enrolments. Enrolments on this course can only be redeemed
  // from these licenses.
  // - 'freePlacesAvailable' - The course is owned by the current organisation, but there
  // is also a license available for X enrolments. This license may have been provided by
  // Workshop as a form of account credit (X free enrolments) or else is could have been
  // bought by the current organisation to bulk-buy enrolments at a discount. Enrolments
  // are redeemed from these licenses before moving onto paid enrolments.
  // - 'paidEnrolments' - Default mode. Enrolments are charged at a rate based on the
  // course's pricing tier.
  // - 'anonymousLicensedCourse', 'anonymousFreePlacesAvailable' &
  // 'anonymousPaidEnrolments' - Same as above, except the class has isAnonymous === true.
  // On anonymous classes, the class size limit (total spaces available) doesn't apply and
  // a number of enrolments is entered rather than individual learner email addresses.

  // TYPE
  // - If the cohort is a test, all enrolments will be of type 'test'
  // - If the course is licensed, all enrolments will be of type 'license'
  // - If the course is not licensed but there are license spaces available,
  // the org is asked to use up their free license places before paying
  // for more enrolments – this means that the enrollment type is set to 'license'
  // until there are no spaces available
  // - All other enrolments are of type 'paid' and each will generate a cost record

  // MAX NEW ENROLMENTS
  // - If the cohort is anonymous, 'cohortSpacesAvailable' becomes irrelevant as the
  // cohort has no participants. In this case: if we're redeeming from a license then
  // license spaces are the only limiting factor, otherwise there is no limit (null)

  const modes: {
    [key: string]: {
      type: 'test' | 'license' | 'paid';
      maxNewEnrolments: number | null;
      introText: React.ReactElement;
      confirmTitle: string;
      confirmBody: string;
      enrolmentLimitText: string | React.ReactElement;
    };
  } = {
    test: {
      type: 'test',
      maxNewEnrolments: cohortSpacesAvailable,
      introText: (
        <Text>
          {`You can enroll ${cohortSpacesAvailable} more learner${
            cohortSpacesAvailable === 1 ? '' : 's'
          } to this test class for free.`}
        </Text>
      ),
      confirmTitle: `You are about to enroll ${studentsValues.length} learner${
        studentsValues.length === 1 ? '' : 's'
      }`,
      confirmBody: 'These enrollments to this test class are free of charge',
      enrolmentLimitText: 'Class full',
    },
    licensedCourse: {
      type: 'license',
      maxNewEnrolments: hasClassSizeLimit
        ? Math.min(cohortSpacesAvailable, licensedSpacesAvailable)
        : licensedSpacesAvailable,
      introText: (
        <Text>
          {`By enrolling learners in this class, you will redeem enrollments from your agreement with ${licensorName}.`}
          <chakra.span fontWeight="bold">
            {` You have ${licensedSpacesAvailable} enrollment${
              licensedSpacesAvailable === 1 ? '' : 's'
            } remaining${
              hasClassSizeLimit &&
              cohortSpacesAvailable < licensedSpacesAvailable
                ? ` and ${cohortSpacesAvailable} space${
                    cohortSpacesAvailable === 1 ? '' : 's'
                  } available in this class`
                : ''
            }.`}
          </chakra.span>
        </Text>
      ),
      confirmTitle: `You are about to enroll ${studentsValues.length} learner${
        studentsValues.length === 1 ? '' : 's'
      }`,
      confirmBody: `These enrollments will be redeemed from your agreement with ${licensorName}.`,
      enrolmentLimitText:
        students.length >= licensedSpacesAvailable
          ? 'No enrollments remaining'
          : 'Class full',
    },
    freePlacesAvailable: {
      type: 'license',
      maxNewEnrolments: hasClassSizeLimit
        ? Math.min(cohortSpacesAvailable, licensedSpacesAvailable)
        : licensedSpacesAvailable,
      introText: (
        <>
          <Text color="text.muted">
            {'You have '}
            <chakra.span fontWeight="bold">{`${licensedSpacesAvailable}`}</chakra.span>
            {` enrollment${licensedSpacesAvailable === 1 ? '' : 's'} remaining`}
            {hasClassSizeLimit &&
            cohortSpacesAvailable < licensedSpacesAvailable ? (
              <>
                {' and '}
                <chakra.span fontWeight="bold">
                  {cohortSpacesAvailable}
                </chakra.span>
                {` space${
                  cohortSpacesAvailable === 1 ? '' : 's'
                } available in this class`}
              </>
            ) : (
              ''
            )}
            {`. `}
            {/* {`After redeeming these, you can enroll more learners to this course at a cost of £${enrolmentCost} per enrollment.`} */}
          </Text>
          {PLATFORM === 'steppit' && (
            <LinkButton
              to={`${navRoutes.cms.myOrganisation.path()}?tab=subscriptions&p=enrollment`}
              variant="outline"
              size="xs"
              mt={3}
            >
              Top Up Enrollments
            </LinkButton>
          )}
        </>
      ),
      confirmTitle: `You are about to enroll ${studentsValues.length} learner${
        studentsValues.length === 1 ? '' : 's'
      }`,
      confirmBody: `These enrollments will be redeemed from your channel's balance.`,
      enrolmentLimitText:
        students.length >= licensedSpacesAvailable ? (
          <>
            {licensedSpacesAvailable <= 0
              ? 'You have no enrollments remaining. Please '
              : `You only have ${licensedSpacesAvailable} enrollment${
                  licensedSpacesAvailable === 1 ? '' : 's'
                } available. Please `}
            <Link
              to={`${navRoutes.cms.myOrganisation.path()}?tab=subscriptions&p=enrollment`}
            >
              <chakra.span
                color="common.primary"
                fontWeight="bold"
                _hover={{ textDecoration: 'underline' }}
              >
                top up
              </chakra.span>
            </Link>
            {' or '}
            <Link
              to={`${navRoutes.cms.myOrganisation.path()}?tab=subscriptions`}
            >
              <chakra.span
                color="common.primary"
                fontWeight="bold"
                _hover={{ textDecoration: 'underline' }}
              >
                upgrade your plan
              </chakra.span>
            </Link>
            {licensedSpacesAvailable <= 0
              ? ' to continue.'
              : ' to add more learners.'}
          </>
        ) : (
          'Class full'
        ),
    },
    paidEnrolments: {
      type: 'paid',
      maxNewEnrolments: hasClassSizeLimit ? cohortSpacesAvailable : null,
      introText: (
        <Text>
          {`You can enroll learners to this course at a cost of £${enrolmentCost} per enrollment.`}
          {hasClassSizeLimit ? (
            <chakra.span fontWeight="bold">
              {` You have ${cohortSpacesAvailable} space${
                cohortSpacesAvailable === 1 ? '' : 's'
              } available in this class.`}
            </chakra.span>
          ) : (
            ''
          )}
        </Text>
      ),
      confirmTitle: `You are about to enroll ${studentsValues.length} learner${
        studentsValues.length === 1 ? '' : 's'
      }`,
      confirmBody: `Enrolling learners to this course costs £${enrolmentCost} per enrollment.\n\nYou will therefore be billed £${
        enrolmentCost * studentsValues.length
      } for these enrollments.`,
      enrolmentLimitText: 'Class full',
    },
    anonymousLicensedCourse: {
      type: 'license',
      maxNewEnrolments: licensedSpacesAvailable,
      introText: (
        <Text>
          {`By enrolling learners in this class, you will redeem enrollments from your agreement with ${licensorName}.`}
          <chakra.span fontWeight="bold">
            {` You have ${licensedSpacesAvailable} enrollment${
              licensedSpacesAvailable === 1 ? '' : 's'
            } remaining.`}
          </chakra.span>
        </Text>
      ),
      confirmTitle: `You are about to enroll ${numStudentsValue} learner${
        numStudentsValue && parseInt(numStudentsValue) === 1 ? '' : 's'
      }`,
      confirmBody: `These enrollments will be redeemed from your agreement with ${licensorName}.`,
      enrolmentLimitText: '',
    },
    anonymousFreePlacesAvailable: {
      type: 'license',
      maxNewEnrolments: licensedSpacesAvailable,
      introText: (
        <>
          <Text color="text.muted">
            {'You have '}
            <chakra.span fontWeight="bold">{`${licensedSpacesAvailable}`}</chakra.span>
            {` enrollment${
              licensedSpacesAvailable === 1 ? '' : 's'
            } remaining. `}
            {/* {`After redeeming these, you can enroll more learners to this course at a cost of £${enrolmentCost} per enrollment.`} */}
          </Text>
          {PLATFORM === 'steppit' && (
            <LinkButton
              to={`${navRoutes.cms.myOrganisation.path()}?tab=subscriptions&p=enrollment`}
              variant="outline"
              size="xs"
              mt={3}
            >
              Top Up Enrollments
            </LinkButton>
          )}
        </>
      ),
      confirmTitle: `You are about to enroll ${numStudentsValue} learner${
        numStudentsValue && parseInt(numStudentsValue) === 1 ? '' : 's'
      }`,
      confirmBody: `These enrollments will be redeemed from your channel's balance.`,
      enrolmentLimitText: '',
    },
    anonymousPaidEnrolments: {
      type: 'paid',
      maxNewEnrolments: null,
      introText: (
        <Text>
          {`You can enroll learners to this course at a cost of £${enrolmentCost} per enrollment.`}
        </Text>
      ),
      confirmTitle: `You are about to enroll ${numStudentsValue} learner${
        numStudentsValue && parseInt(numStudentsValue) === 1 ? '' : 's'
      }`,
      confirmBody: `Enrolling learners to this course costs £${enrolmentCost} per enrollment.\n\nYou will therefore be billed${
        numStudentsValue
          ? ` £${enrolmentCost * parseInt(numStudentsValue)}`
          : ''
      } for these enrollments.`,
      enrolmentLimitText: '',
    },
  };

  const currentModeSlug = cohortIsTest
    ? 'test'
    : cohortIsAnonymous
    ? courseIsLicensed
      ? 'anonymousLicensedCourse'
      : licensedSpacesAvailable > 0 || PLATFORM === 'steppit'
      ? 'anonymousFreePlacesAvailable'
      : 'anonymousPaidEnrolments'
    : courseIsLicensed
    ? 'licensedCourse'
    : licensedSpacesAvailable > 0 || PLATFORM === 'steppit'
    ? 'freePlacesAvailable'
    : 'paidEnrolments';

  const currentMode = modes[currentModeSlug];

  const {
    type,
    maxNewEnrolments,
    introText,
    confirmTitle,
    confirmBody,
    enrolmentLimitText,
  } = currentMode;

  // Enrollments are assigned to each license by sorting licenses based on expiry date (soonest first)
  // and then enrolling each learner to the first available license.
  // When all enrollments on a license have been used up, the next available license is used
  let maxStudentIdx = 0;
  const licensesByMaxIdx = licenses
    ?.sort((a, b) => moment(b.expiryDate).diff(moment(a.expiryDate)))
    .map((l) => {
      const enrolmentsRemaining = l.totalEnrolments - l.enrolments.length;
      const maxIdxForLicense = enrolmentsRemaining + maxStudentIdx;
      maxStudentIdx += maxIdxForLicense;
      return {
        id: l.id,
        maxIdx: maxIdxForLicense,
      };
    });

  const handleOnSave = async (e?: React.FormEvent<HTMLFormElement>) => {
    // TODO: Check if learner with this email is already on this course,
    // if so error and ask to move learner from class XYZ

    setIsUpdating(true);
    if (e && e.stopPropagation) {
      // prevent any outer forms from receiving the event too
      e.stopPropagation();
    }

    const submit = await handleSubmit(async ({ students, numStudents }) => {
      let studentArray = students;
      // If the cohort is anonymous, create enrollments based on the number
      // of learners entered with no associated email address
      if (cohortIsAnonymous && numStudents) {
        studentArray = new Array(parseInt(numStudents)).fill({ email: '' });
      }
      await Promise.all(
        studentArray.map((s, idx) => {
          // Grab the first available license with a maxIdx greater than the current idx
          const license = licensesByMaxIdx?.find((l) => idx < l.maxIdx);
          analytics.track('Private Student Enrolled');
          return dispatch(
            enrolmentActions.createEnrolment({
              type,
              course,
              currentCohort: cohort,
              ...(s.email ? { email: s.email } : {}),
              ...(type === 'license' && license
                ? { contentLicense: license.id }
                : {}),
            })
          );
        })
      );
      await dispatch(enrolmentActions.listLicenses());
    });
    await submit(e);
    setIsUpdating(false);
    onSave && onSave();
    handleOnClose();
  };

  const isLoading = false;

  const emailInvalid =
    !cohortIsAnonymous &&
    studentsValues.find(({ email }) => email && !isEmail(email));

  const isValid = cohortIsAnonymous
    ? numStudentsValue &&
      parseInt(numStudentsValue) > 0 &&
      (maxNewEnrolments === null ||
        parseInt(numStudentsValue) <= maxNewEnrolments)
    : (licensedSpacesAvailable > 0 || courseIsLicensed) &&
      !studentsValues.find(({ email }) => !email) &&
      !emailInvalid;

  const modalProps = {
    isOpen,
    modalSize,
    onCancel: handleOnClose,
    onClose: handleOnClose,
    onSave: () => setShowConfirmModal(true),
    title: `Enroll ${PLATFORM === 'workshop' ? 'Students' : 'Learners'}${
      cohortIsTest
        ? ' On Test Class'
        : courseIsLicensed
        ? ' From Agreement'
        : licensedSpacesAvailable > 0
        ? ''
        : ''
    }`,
    isUpdating,
    saveDisabled: !isValid || isUpdating,
    saveLabel: 'Enroll',
  };

  return (
    <EditModal {...modalProps}>
      {coursesLoading ? (
        <Loading />
      ) : (
        <>
          <ConfirmModal
            btnColor="green"
            btnLabel="Confirm Enrollment"
            title={confirmTitle}
            body={confirmBody}
            isOpen={showConfirmModal}
            isLoading={isUpdating}
            onClose={() => setShowConfirmModal(false)}
            onClick={handleOnSave}
          />
          {introText}

          {cohortIsAnonymous ? (
            <Box pt={4}>
              <Text>
                This is an anonymous class. Please enter the number of learners
                you would like to enroll on this class.
              </Text>

              <Text color="text.muted" fontSize="sm">
                This will determine how many learners are allowed in a live
                room, as well as the number of certificates you can award at the
                end of the course.
              </Text>
              <Flex
                borderRadius="md"
                background="background.tint3"
                padding="defaultPadding"
                pb={1}
                flexDirection="column"
                mt={6}
                position="relative"
              >
                <LabelInput
                  id="numStudents"
                  name="numStudents"
                  label="Number of enrollments"
                  inputType="number"
                  placeholder={
                    maxNewEnrolments === null
                      ? ''
                      : !courseIsLicensed && licensedSpacesAvailable <= 0
                      ? 'Upgrade or top up your enrollments to continue'
                      : maxNewEnrolments === 1
                      ? `You only have 1 ${
                          licensedSpacesAvailable === 1
                            ? 'enrollment remaining'
                            : 'space available'
                        }`
                      : `Please enter a number between 1 and ${maxNewEnrolments}`
                  }
                  labelPosition="top"
                  registerInputRef={register}
                  isLoading={isLoading}
                  pb={0}
                />
              </Flex>
            </Box>
          ) : (
            <Box pt={8}>
              {students.map(({ id, email }, idx) => {
                return (
                  <Flex
                    borderRadius="md"
                    background="background.tint3"
                    padding="defaultPadding"
                    pb={1}
                    flexDirection="column"
                    mb={2}
                    position="relative"
                    key={id}
                  >
                    <LabelInput
                      id="email"
                      name={`students[${idx}].email` as const}
                      label={`Learner #${idx + 1} Email`}
                      placeholder="name@example.com"
                      labelPosition="top"
                      defaultValue={email}
                      registerInputRef={register()}
                      isLoading={isLoading}
                      pb={0}
                    />
                    {idx > 0 && (
                      <Box
                        position="absolute"
                        top={0}
                        right={0}
                        padding={2}
                        onClick={() => remove(idx)}
                        cursor="pointer"
                      >
                        <MdIcon name="RemoveCircle" color="icon.muted" />
                      </Box>
                    )}
                  </Flex>
                );
              })}

              {maxNewEnrolments === null ||
              students.length < maxNewEnrolments ? (
                <Button
                  onClick={() => append({ email: '' })}
                  variant="ghost"
                  icon="Add"
                  size="sm"
                >
                  Add Learner
                </Button>
              ) : (
                <>
                  <Divider mb={3} mt={4} />
                  <Text color="text.muted">{enrolmentLimitText}</Text>
                </>
              )}
              {emailInvalid &&
              (licensedSpacesAvailable > 0 || courseIsLicensed) ? (
                <Text color="text.error" fontSize="xs" mt={3}>
                  Please enter a valid email address
                </Text>
              ) : null}
            </Box>
          )}
        </>
      )}
    </EditModal>
  );
};

export default EnrolStudentsModal;
