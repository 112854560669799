import { callAPI } from 'utils';
import API from 'constants/api';
import { decamelizeKeys } from 'humps';

import { Dispatch } from 'types';
import { ListCohortsAction, ListUpcomingCohortsAction } from 'types/learner';
import { CourseAT } from 'redux/actionTypes/learner';
import { SubmitQuestionAnswersAction } from 'types/learner';
import {
  CohortSchema,
  UpcomingCohortSchema,
  CourseProgressSchema,
  CourseSchema,
  CourseSummarySchema,
  UserScheduleSchema,
  ModuleScheduleSchema,
  MCQSchema,
} from 'redux/schemas';

export const courseActions = {
  list: () =>
    callAPI({
      types: [
        CourseAT.FETCH_COURSE_LIST_REQUEST,
        CourseAT.FETCH_COURSE_LIST_SUCCESS,
        CourseAT.FETCH_COURSE_LIST_FAILURE,
      ],
      endpoint: API.learner.course(),
      method: 'GET',
    }),

  listForOrg:
    (handle: string, url?: string | undefined) => async (dispatch: Dispatch) =>
      dispatch(
        callAPI({
          types: [
            CourseAT.FETCH_COURSE_LIST_REQUEST,
            CourseAT.FETCH_COURSE_LIST_SUCCESS,
            CourseAT.FETCH_COURSE_LIST_FAILURE,
          ],
          endpoint: url || API.learner.courseListForOrg(handle),
          method: 'GET',
          schema: [CourseSummarySchema],
          path: 'results',
          fetchNextPage: async (url: string): Promise<any> =>
            dispatch(courseActions.listForOrg(handle, url)),
        })
      ),

  retrieve: (
    slug: string | number,
    pathname404?: string,
    showError: boolean = true,
    // queryParams format: a=1 or a=1&b=2
    queryParams?: string
  ) => {
    // TODO: Hacky fix to bypass cache – needs fix on backend?
    let endpoint = `${API.learner.course(slug)}?${Math.floor(
      Math.random() * 100000
    )}`;
    if (queryParams) {
      endpoint = endpoint.includes('?')
        ? `${endpoint}&${queryParams}`
        : `${endpoint}?${queryParams}`;
    }
    return callAPI({
      types: [
        CourseAT.FETCH_COURSE_REQUEST,
        CourseAT.FETCH_COURSE_SUCCESS,
        CourseAT.FETCH_COURSE_FAILURE,
      ],
      endpoint,
      method: 'GET',
      schema: CourseSchema,
      meta: {
        pathname404,
        toast: { error: showError, success: false },
      },
    });
  },

  retrieveSummary: (
    slug: string | number,
    pathname404?: string,
    showError: boolean = true,
    // queryParams format: a=1 or a=1&b=2
    queryParams?: string
  ) => {
    let endpoint = API.learner.courseSummary(slug);
    if (queryParams) {
      endpoint = endpoint.includes('?')
        ? `${endpoint}&${queryParams}`
        : `${endpoint}?${queryParams}`;
    }
    return callAPI({
      types: [
        CourseAT.FETCH_COURSE_REQUEST,
        CourseAT.FETCH_COURSE_SUCCESS,
        CourseAT.FETCH_COURSE_FAILURE,
      ],
      endpoint,
      method: 'GET',
      schema: CourseSummarySchema,
      meta: {
        pathname404,
        toast: { error: showError, success: false },
      },
    });
  },
};

export const courseProgressActions = {
  retrieve: (slug: string) => {
    return callAPI({
      types: [
        CourseAT.FETCH_COURSE_PROGRESS_REQUEST,
        CourseAT.FETCH_COURSE_PROGRESS_SUCCESS,
        CourseAT.FETCH_COURSE_PROGRESS_FAILURE,
      ],
      endpoint: API.learner.courseProgress(slug),
      method: 'GET',
      schema: CourseProgressSchema,
    });
  },

  list: (url?: string | undefined) => async (dispatch: Dispatch) =>
    dispatch(
      callAPI({
        types: [
          CourseAT.FETCH_COURSE_PROGRESS_REQUEST,
          CourseAT.FETCH_COURSE_PROGRESS_SUCCESS,
          CourseAT.FETCH_COURSE_PROGRESS_FAILURE,
        ],
        endpoint: url || API.learner.courseProgress(),
        method: 'GET',
        schema: [CourseProgressSchema],
        path: 'results',
        fetchNextPage: async (url: string): Promise<any> =>
          dispatch(courseProgressActions.list(url)),
      })
    ),
};

export const courseScheduleActions = {
  retrieve: (slug: string) => {
    return callAPI({
      types: [
        CourseAT.FETCH_COURSE_SCHEDULE_REQUEST,
        CourseAT.FETCH_COURSE_SCHEDULE_SUCCESS,
        CourseAT.FETCH_COURSE_SCHEDULE_FAILURE,
      ],
      endpoint: API.learner.courseSchedule(slug),
      method: 'GET',
      credentials: 'include',
      schema: UserScheduleSchema,
    });
  },
};

export const stepQuestionActions = {
  list: (questionIds: number[]) => {
    return callAPI({
      types: [
        CourseAT.FETCH_MODULE_QUESTIONS_LIST_REQUEST,
        CourseAT.FETCH_MODULE_QUESTIONS_LIST_SUCCESS,
        CourseAT.FETCH_MODULE_QUESTIONS_LIST_FAILURE,
      ],
      endpoint: API.learner.questions(questionIds),
      method: 'GET',
      path: 'results',
      schema: [MCQSchema],
    });
  },
  submitAnswers: (question: number, answers: number[]) =>
    callAPI<SubmitQuestionAnswersAction>({
      types: [
        CourseAT.SUBMIT_QUESTION_ANSWERS_REQUEST,
        CourseAT.SUBMIT_QUESTION_ANSWERS_SUCCESS,
        CourseAT.SUBMIT_QUESTION_ANSWERS_FAILURE,
      ],
      endpoint: API.learner.submitAnswers,
      method: 'POST',
      body: {
        question,
        guess: JSON.stringify(answers),
      },
      meta: {
        toast: { success: false },
      },
    }),
};
interface UserModuleProgressData {
  currentStep: number;
  stepsRemaining: number;
  status: string;
}
interface ModuleScheduleData {
  scheduledTime: string;
}

export const moduleActions = {
  updateUserProgress: (
    courseSlug: string,
    moduleSlug: string,
    data: Partial<UserModuleProgressData>
  ) => {
    return callAPI({
      types: [
        CourseAT.UPDATE_USER_MODULE_PROGRESS_REQUEST,
        CourseAT.UPDATE_USER_MODULE_PROGRESS_SUCCESS,
        CourseAT.UPDATE_USER_MODULE_PROGRESS_FAILURE,
      ],
      endpoint: API.learner.updateUserModuleProgress(courseSlug, moduleSlug),
      method: 'PATCH',
      body: decamelizeKeys(data),
      schema: CourseProgressSchema,
    });
  },
  updateSchedule: (
    courseSlug: string,
    moduleScheduleId: number,
    data: ModuleScheduleData
  ) => {
    return callAPI({
      types: [
        CourseAT.UPDATE_COURSE_MODULE_SCHEDULE_REQUEST,
        CourseAT.UPDATE_COURSE_MODULE_SCHEDULE_SUCCESS,
        CourseAT.UPDATE_COURSE_MODULE_SCHEDULE_FAILURE,
      ],
      endpoint: API.learner.updateCourseModuleSchedule(
        courseSlug,
        moduleScheduleId
      ),
      method: 'PATCH',
      body: decamelizeKeys(data),
      schema: ModuleScheduleSchema,
    });
  },
};

interface UserModuleProgressData {
  assessmentComplete?: boolean;
}

export const unitActions = {
  updateUserProgress: (
    courseSlug: string,
    unitSlug: string,
    data: Partial<UserModuleProgressData>
  ) => {
    return callAPI({
      types: [
        CourseAT.UPDATE_USER_UNIT_PROGRESS_REQUEST,
        CourseAT.UPDATE_USER_UNIT_PROGRESS_SUCCESS,
        CourseAT.UPDATE_USER_UNIT_PROGRESS_FAILURE,
      ],
      endpoint: API.learner.updateUserUnitProgress(courseSlug, unitSlug),
      method: 'PATCH',
      body: decamelizeKeys(data),
      schema: CourseProgressSchema,
    });
  },
};

export const cohortActions = {
  retrieve: (slug: string) =>
    callAPI({
      types: [
        CourseAT.FETCH_COHORT_REQUEST,
        CourseAT.FETCH_COHORT_SUCCESS,
        CourseAT.FETCH_COHORT_FAILURE,
      ],
      endpoint: API.learner.cohort(slug),
      method: 'GET',
      schema: CohortSchema,
    }),

  list:
    ({
      fetchNextPage,
      url,
    }: {
      fetchNextPage?: boolean;
      url?: string | undefined;
    }) =>
    async (dispatch: Dispatch) =>
      dispatch(
        callAPI<ListCohortsAction>({
          types: [
            CourseAT.LIST_COHORTS_REQUEST,
            CourseAT.LIST_COHORTS_SUCCESS,
            CourseAT.LIST_COHORTS_FAILURE,
          ],
          endpoint: url || API.learner.myCohorts,
          method: 'GET',
          schema: [CohortSchema],
          path: 'results',
          fetchNextPage: fetchNextPage
            ? async (url) => {
                dispatch(cohortActions.list({ fetchNextPage: true, url }));
              }
            : null,
        })
      ),

  courseList:
    ({
      courseSlug,
      fetchNextPage,
      url,
    }: {
      courseSlug: string;
      fetchNextPage?: boolean;
      url?: string | undefined;
    }) =>
    async (dispatch: Dispatch) =>
      dispatch(
        callAPI<ListUpcomingCohortsAction>({
          types: [
            CourseAT.LIST_UPCOMING_COHORTS_REQUEST,
            CourseAT.LIST_UPCOMING_COHORTS_SUCCESS,
            CourseAT.LIST_UPCOMING_COHORTS_FAILURE,
          ],
          endpoint: url || API.learner.upcomingCohorts(courseSlug),
          method: 'GET',
          schema: [UpcomingCohortSchema],
          path: 'results',
          fetchNextPage: fetchNextPage
            ? async (url) => {
                dispatch(
                  cohortActions.courseList({
                    courseSlug,
                    fetchNextPage: true,
                    url,
                  })
                );
              }
            : null,
        })
      ),
};
