import React, { useEffect } from 'react';
import { useLocation, useHistory, RouteComponentProps } from 'react-router';
import { useDispatch } from 'react-redux';

import { analytics, getParamFromUrl } from 'utils';

import { useIsAuthenticated, useDiscourseUser } from 'redux/selectors';
import { authActions } from 'redux/actions/common';

import navRoutes from 'navigation/Routes';

import { PLATFORM, discourseUrl } from 'constants/env';

import { LoadingScreen } from 'components/Loading';

interface MatchParams {}

interface Props extends RouteComponentProps<MatchParams> {}

const RoadmapScreen: React.FC<Props> = ({}) => {
  const isAuthenticated = useIsAuthenticated();
  const discourseUser = useDiscourseUser();

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const authParam = getParamFromUrl(location, 'a');

  const redirectToRoadmap = (token?: string) => {
    analytics.track('Roadmap Visited');
    window.open(
      `https://roadmap.steppit.com/${token ? `?sso=${token}` : ''}`,
      '_self'
    );
  };

  const loadSsoToken = async () => {
    const avatar = discourseUser?.avatarTemplate
      ? `${discourseUrl}${discourseUser?.avatarTemplate.replace(
          '{size}',
          '240'
        )}`
      : undefined;
    const response = await dispatch(authActions.fetchRoadmapToken({ avatar }));
    const token =
      response?.payload &&
      'token' in response.payload &&
      response.payload.token;
    if (token) {
      return redirectToRoadmap(token);
    }
    return redirectToRoadmap();
  };

  useEffect(() => {
    if (isAuthenticated) {
      loadSsoToken();
    } else {
      if (authParam) {
        history.push({
          pathname: navRoutes.public.login.path(),
          search: 'redirect=/roadmap',
        });
      } else {
        redirectToRoadmap();
      }
    }
  }, [isAuthenticated, authParam]);

  if (PLATFORM !== 'steppit') {
    history.push(navRoutes.public.login.path());
    return null;
  }

  return <LoadingScreen />;
};

export default RoadmapScreen;
